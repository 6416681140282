<template>
  <div>
    <div class="pages-section-title">
      {{ $t('knowledge.lastUpdates') }}
    </div>

    <div class="page-cards" v-if="!isLoading">
      <div
        v-for="item in pages"
        :key="item.code"
        class="page-card"
      >
        <router-link class="page-card__title" :to="{ name: 'pages.detail', params: { project: item.project, code: item.code} }">
          <span class="page-card__icon">{{ item.icon.value }}</span>
          {{ item.name }}
        </router-link>

        <div class="page-card__footer">
          <div class="page-card__member">
            <user-photo :user="getMember(item)" size="xxs" :show-online="false" /> {{ getMember(item).full_name }}
          </div>

          <div class="page-card__date">
            {{ formattedDate(item) }}
          </div>
        </div>
      </div>
    </div>

    <div class="page-cards-empty" v-if="!isLoading && pages.length === 0">
      <empty-task/>

      <div class="page-cards-empty__text">
        {{ $t('knowledge.emptySection') }}
      </div>
    </div>
  </div>
</template>
<script>
  import {mapActions, mapGetters} from 'vuex';
  import UserPhoto from '@/components/ui/UserPhoto'
  import EmptyTask from '@/components/illustrations/EmptyTask'
  import {getPastDate} from '@/services/scoring';

  export default {
    name: 'RecentlyUpdatedPages',

    props: {
      projectCode: {
        type: String
      }
    },

    components: {EmptyTask, UserPhoto },

    data () {
      return {
        isLoading: false,
      }
    },

    computed: {
      ...mapGetters('pages', ['recentlyUpdated']),
      ...mapGetters(['members']),

      isRoot () {
        return !this.projectCode
      },

      pages () {
        return this.recentlyUpdated[this.isRoot ? 'root' : this.projectCode]
      }
    },

    methods: {
      ...mapActions('pages', ['fetchRecentlyUpdated']),

      getMember (item) {
        const memberId = item.updated_by
          ? 'updated_by'
          : 'author_id'

        return this.members.find((member) => member.member_id === item[memberId])
      },

      formattedDate (item) {
        return getPastDate(item.updatedAt).label
      },
    },

    async created () {
      this.isLoading = true
      await this.fetchRecentlyUpdated({
        project: this.isRoot ? null : this.projectCode,
        root: this.isRoot,
      })
      this.isLoading = false
    }
  }
</script>
<style lang="sass">
  .pages-section-title
    font-size: 22px
    margin-bottom: 20px
    font-weight: bold

  .page-cards
    display: grid
    grid-template-columns: 1fr 1fr
    column-gap: 16px
    row-gap: 16px

  .page-card
    padding: 12px
    border-radius: 8px
    box-sizing: border-box
    border: 1px solid var(--grey-light-color-40)
    transition: all .2s ease-in

    &:hover
      box-shadow: var(--popover-shadow)

    &__title
      color: var(--text-primary-color)
      font-size: 14px
      font-weight: 500
      display: flex
      column-gap: 4px
      text-decoration: none

    &__footer
      display: flex
      justify-content: space-between
      align-items: center
      margin-top: 12px
      cursor: default

    &__member
      display: flex
      column-gap: 6px
      align-items: center
      color: var(--text-light-color)
      font-size: 12px

    &__date
      font-size: 12px
      color: var(--text-light-color)

  .page-cards-empty
    display: flex
    flex-flow: column
    align-items: center
    row-gap: 24px

    &__text
      font-size: 16px
</style>

import io from 'socket.io-client'

class SocketService {
  constructor() {
    this.subscribers = []
  }

  startSocket () {
    return this.socket || this.connect()
  }

  connect () {
    this.socket = io({
      transports: ['websocket']
    })

    this.socket.on('connect', () => {
        //console.log('dadadadadada')
        //console.log('Successfully connected!')
      })

    this.socket.on('disconnect', () => {
      //console.log('PIZDA')
    })

    this.subscribers.forEach(subscriber => subscriber(this.socket))

    return this.socket
  }

  addSubscriber (subscriber) {
    this.subscribers.push(subscriber)
  }

  disconnect () {
    if (!this.socket) {
      return
    }

    this.socket.disconnect()
    this.socket = null
  }
}

const socketService = new SocketService()

export default socketService

<template>
  <div class="feed-item">
    <div class="feed-item__line">
      <div class="feed-item__circle">
        <el-skeleton-item variant="circle" style="width: 24px; height: 24px;"/>
      </div>
    </div>

    <div class="feed-item__data">
      <div class="feed-item__head">
        <div class="feed-item__photo">
          <el-skeleton-item variant="text" style="width: 20px; height: 20px;"/>
        </div>

        <div class="feed-item__author">
          <el-skeleton-item variant="text" style="width: 130px; height: 20px;"/>
        </div>
        <div class="feed-item__date">
          <el-skeleton-item variant="text" style="width: 70px; height: 20px;"/>
        </div>
      </div>

      <div class="feed-item__content">
        <div class="feed-item__action">
          <el-skeleton-item variant="text" style="width: 160px; height: 20px;"/>
        </div>
        <div class="feed-item__payload">
          <el-skeleton-item variant="text" style="width: 100px; height: 20px;"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'SkeletonFeedItem'
  }
</script>

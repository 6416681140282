<template>
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" d="M1.66492 0.103516L5.99992 4.43852L10.3349 0.103516L11.6666 1.44463L5.99992 7.11129L0.333252 1.44463L1.66492 0.103516Z" fill="#2F3750"></path>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'ChevronsDown',

    mixins: [iconMixin],

    props: {
      viewBox: {
        type: String,
        default: '0 0 12 12'
      }
    },

    data () {
      return {
        defaultBackground: '#2C3E50',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

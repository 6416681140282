<template>
  <div>
    <el-popover
      placement="bottom"
      trigger="manual"
      :popper-class="popperClass"
      ref="popper"
    >
      <div class="ui-dropdown__option-list" style="min-width: 300px">
        <div
          v-for="option in options"
          :key="option.id"
          :class="{ 'ui-dropdown__option': true, 'ui-dropdown__option_active': value === option }"
          @click="onSelect(option)"
        >
          <slot name="option" :option="option" :showMatches="showMatches"></slot>
        </div>
        <div v-if="options.length === 0" class="ui-select__no-data">
          {{ noDataText }}
        </div>
      </div>
      <div slot="reference">
        <text-field
          prop="data"
          :value="value"
          @input="onInput"
          @keyup.enter="onSelectEnteredData"
          @blur="onSelectEnteredData"
          :label="label"
          autocomplete="off"
          v-if="!selected"
        />
        <slot name="selected-item" :value="selected" :remove-selected="removeSelected" v-else></slot>
      </div>
    </el-popover>
  </div>
</template>
<script>
  import TextField from 'scorework-ui/src/components/TextField'

  export default {
    name: 'UiSelect',

    components: { TextField },

    props: {
      value: {
        required: true
      },

      selected: {
        type: Object
      },

      popoverClass: {
        type: String,
        default: ''
      },

      searchFn: {
        type: Function,
        required: true
      },

      noDataText: {
        type: String,
        default: function () {
          this.$t('ui.nothingFound')
        }
      },

      label: {
        type: String,
        default: function () {
          this.$t('ui.enterName')
        }
      },

      checkEnteredDataFn: {
        type: Function
      }
    },

    data () {
      return {
        options: [],
        timerId: null
      }
    },

    computed: {
      popperClass () {
        return 'ui-dropdown ' + this.popoverClass
      }
    },

    methods: {
      onSelect (option) {
        this.$refs.popper.doClose()

        this.$emit('select', option)
      },

      onInput (val) {
        if (this.timerId) {
          clearTimeout(this.timerId)
        }

        if (!val) {
          return
        }

        this.timerId = setTimeout(() => {
          this.options = []
          this.searchFn(val)
            .then(data => {
              this.options = [...data]

              if (this.options.length) {
                this.$refs.popper.doShow()
              } else {
                this.$refs.popper.doClose()
              }
            })
        }, 600)

        this.$emit('input', val)
      },

      showMatches (val) {
        return val.replace(this.value, `<b>${this.value}</b>`, val)
      },

      onSelectEnteredData () {
        if (this.checkEnteredDataFn && !this.checkEnteredDataFn(this.value)) {
          return
        }

        this.$refs.popper.doClose()
        this.$emit('input', this.value)
      },

      removeSelected () {
        this.$emit('select', null)
      }
    }
  }
</script>

<template>
  <ui-button @click="onClick" :ui-class="uiClass" :disabled="loading">
    <i class="el-icon-loading mr-1" v-if="loading"></i>
    <slot></slot>
  </ui-button>
</template>
<script>
import UiButton from 'scorework-ui/src/components/UiButton'

export default {
  name: 'UiLoadingButton',

  components: { UiButton },

  props: {
    uiClass: {
      type: String
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<template>
  <div class="task-card-container">
    <template v-if="taskError !== 0">
      <div class="task-card-error">
        <div class="task-card-error__illustration">
          <EmptyTask/>
        </div>

        <div class="task-card-error__title">
          {{ $t('tasks.errors.notFound.title') }}
        </div>
        <div class="task-card-error__text">
          {{ $t('tasks.errors.notFound.text') }}
        </div>

        <ui-button ui-class="light" @click="onCancel">
          {{ $t('common.closeWindow') }}
        </ui-button>
      </div>
    </template>
    <el-skeleton :loading="!isTaskLoaded" animated style="height: 100%" v-else>
      <template slot="template">
        <task-card-skeleton :task-id="taskId"/>
      </template>
      <template>
        <div class="sw-task-card" v-if="isTaskLoaded">
          <div class="sw-task-card__head">
            <div class="sw-task-card__head-content sw-task-card__wrapper">
              <div class="sw-task-card__head-info">
                <div class="sw-task-card__number">
                  <div class="sw-task-card__number-project">
                    {{ project.name }}
                  </div>

                  <chevron-right-icon class="sw-task-card__number-chevron"/>

                  <div class="sw-task-card__number-title">
                    {{ taskId ? '#' + showTaskNumber() : $t('tasks.newTask')}}
                  </div>
                </div>
              </div>

              <div class="sw-task-card__head-actions">
                <template v-if="!taskId">
                  <ui-button @click.prevent="onCancel" ui-class="size_md plain">{{ cancelTitle }}</ui-button>
                  <ui-loading-button
                      @click.prevent="onSubmit"
                      ui-class="size_md"
                      class="sw-task-card__save-btn"
                      :loading="isCreating"
                      v-if="!taskId"
                  >
                    {{ $t('common.create') }}
                  </ui-loading-button>
                </template>
                <template v-else>
                  <el-popover
                      placement="bottom-end"
                      trigger="click"
                      ref="popper"
                      :visible-arrow="false"
                      transition="sw-slide-up"
                      popper-class="ui-dropdown-ex ui-dropdown-ex_no-padding"
                      v-if="task.status !== 'finished'"
                  >
                    <div class="ui-dropdown__option-list">
                      <div class="ui-dropdown__option" @click="onDeleteTask">
                        {{ $t('tasks.actions.deleteTask') }}
                      </div>
                    </div>
                    <ui-button class="btn-round-icon" slot="reference">
                      <more-horizontal-icon/>
                    </ui-button>
                  </el-popover>

                  <ui-button ui-class="plain" @click.prevent="onCancel">
                    <close-icon/>
                  </ui-button>
                </template>
              </div>
            </div>
          </div>

          <div class="sw-task-card__body">
            <div class="sw-task-card__section sw-task-card__section_bordered">
              <div class="sw-task-card__wrapper">
                <ui-magic-field
                    :value="task.name"
                    @input="val => onTaskFieldChange('name', val)"
                    :placeholder="$t('tasks.newTaskName')"
                    class="sw-task-card__title"
                    :class="{
                'sw-task-card__title_error': v$.task.name.$error
              }"
                />
                <transition name="input-error-anim">
                  <div class="sw-task-card__error" v-show="v$.task.name.$error">
                    {{ v$.task.name.$errors.length ? v$.task.name.$errors[0].$message : '' }}
                  </div>
                </transition>

                <div class="sw-task-card__state">
                  <div class="sw-task-card__stage-line" v-if="!isFinished">
                    <ui-dropdown
                        :options="formStages"
                        :value="task.state.stage"
                        reference-class="common-dropdown blue"
                        @input="onStageChange"
                        popover-class="ui-dropdown-ex"
                        placement="bottom-start"
                        :width-by-popover="false"
                    >
                      <div class="common-dropdown__title">
                        {{ task.state.stage.name }}
                      </div>

                      <template v-slot:option="{ option }">
                        {{ option.name }}
                      </template>
                    </ui-dropdown>
                  </div>

                  <div class="sw-task-card__badges">
                    <ui-dropdown
                        :options="targetsList"
                        :value="task.target"
                        :reference-class="targetSelectorClasses"
                        @input="onTargetChange"
                        popover-class="ui-dropdown-ex task-target-popover"
                        placement="bottom-start"
                        :width-by-popover="false"
                        :disabled="isFinished"
                    >
                      <target-icon class="common-dropdown__icon"/>
                      <div class="common-dropdown__title">
                        {{ task.target.id ? task.target.name : $t('tasks.noGoal') }}
                      </div>

                      <template v-slot:option="{ option }">
                        <div
                            class="target-dropdown-option"
                            :class="targetOptionClasses(option)"
                        >
                          {{ option.name }}
                        </div>
                      </template>
                    </ui-dropdown>

                    <ui-dropdown
                        :options="sphereList"
                        :value="this.task.sphere"
                        :reference-class="sphereSelectorClasses"
                        @input="onSphereChange"
                        popover-class="ui-dropdown-ex"
                        placement="bottom-start"
                        :width-by-popover="false"
                        :disabled="isFinished"
                    >
                      <sphere-icon class="common-dropdown__icon"/>
                      <div class="common-dropdown__title">
                        {{ task.sphere.id ? task.sphere.name : $t('tasks.noSphere') }}
                      </div>

                      <template v-slot:option="{ option }">
                        <div
                            class="target-dropdown-option"
                            :class="sphereOptionClasses(option)"
                        >
                          {{ option.name }}
                        </div>
                      </template>
                    </ui-dropdown>
                  </div>
                </div>
              </div>
            </div>

            <div class="sw-task-card__section">
              <div class="sw-task-card__wrapper">
                <div class="sw-task-card__params">
                  <ui-dropdown
                    :options="assigneesList"
                    :value="task.state.assignee"
                    key-field="member_id"
                    @input="onAssigneeChange"
                    popover-class="ui-dropdown-ex"
                    placement="bottom-start"
                    :reference-class="commonDropdownClasses"
                    :widthByPopover="false"
                    :filterable="true"
                    :label="$t('archive.AssigneeName')"
                    :compare-field="(item) => `${item.first_name} ${item.last_name}`"
                    :disabled="isFinished"
                  >
                    <div class="common-dropdown__icon">
                      <user-photo :user="task.state.assignee" size="xs" :show-online="false"/>
                    </div>
                    <div class="common-dropdown__title">
                      {{ assigneeName }}
                    </div>
                    <template v-slot:option="{ option }">
                      <task-assignee :user="option"/>
                    </template>
                  </ui-dropdown>

                  <score-dropdown
                      :value="task.score"
                      :sphere="task.sphere"
                      :member="task.state.assignee"
                      :points="task.points"
                      :disabled="isFinished"
                      :is-active="true"
                      reference-class="task-state__drop-element task-state__score-reference"
                      @input="onScoreChange"
                  />

                  <ui-dropdown
                      :options="pointsList"
                      :value="this.task.points"
                      :reference-class="commonDropdownClasses"
                      @input="onPointsChange"
                      popover-class="ui-dropdown-ex"
                      placement="bottom-start"
                      :width-by-popover="false"
                      :disabled="isFinished"
                  >
                    <points-icon class="common-dropdown__icon"/>
                    <div class="common-dropdown__title">
                      {{ pointsList.find(item => item.value === task.points).label }}
                    </div>

                    <template v-slot:option="{ option }">
                      {{ option.label }}
                    </template>
                  </ui-dropdown>
                </div>

                <task-state :task="task" v-if="taskId && task.status !== 'backlog'"/>

                <div class="sw-task-card__description">
                  <xui-editor
                    :value="task.description"
                    :placeholder="$t('tasks.enterTaskDescription')"
                    :with-attaches="true"
                    @input="val => onTaskFieldChange('description', val)"
                    @attach-file="onAttachFile"
                    @init="onEditorInit"
                  />
                </div>

                <div class="sw-task-card__subhead" v-if="task.files.length > 0">
                  <div class="sw-task-card__subhead-content">
                    {{ $t('tasks.attachments') }}
                  </div>

                  <el-tooltip
                    effect="dark"
                    :content="$t('tasks.attachFile')"
                    placement="left"
                    transition="sw-slide-left"
                  >
                    <button class="common-dropdown common-dropdown_icon" @click="onAttachFile">
                      <attach-icon/>
                    </button>
                  </el-tooltip>
                </div>
                <div v-else>
                  <el-tooltip
                    effect="dark"
                    :content="$t('tasks.attachFile')"
                    placement="top"
                    transition="sw-slide-top"
                  >
                    <button class="common-dropdown common-dropdown_icon" @click="onAttachFile">
                      <attach-icon/>
                    </button>
                  </el-tooltip>
                </div>

                <div>
                  <input type="file" ref="file" style="visibility: hidden; display: none;" multiple @change="onFileChange"/>

                  <TaskFiles :task="task" :task-id="taskId"/>
                </div>

                <template v-if="taskId">
                  <task-timeline :items="timeline" :task-id="taskId"/>
                </template>
              </div>
            </div>
          </div>
        </div>

        <confirmation-modal
          :description="$t('tasks.deleteConfirmation.description')"
          :title="$t('tasks.deleteConfirmation.title')"
          :submit-title="$t('tasks.deleteConfirmation.submit')"
          width="400px"
          :is-processing="deleteProcessing"
          v-if="showDeleteConfirmation"
          @close="showDeleteConfirmation = false"
          @confirm="onDeleteConfirm"
        />
      </template>
      <save-draft-modal
        v-if="saveDraftModal.show"
        @cancel="onCancelClose"
        @delete="onDeleteDraft"
        @save="onSaveDraft"
      />
    </el-skeleton>
  </div>
</template>
<script>
import {createNamespacedHelpers, mapActions, mapGetters} from "vuex"
import useVuelidate  from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import UiButton from 'scorework-ui/src/components/UiButton'

import ChevronRightIcon from "@/components/icons/ChevronRightIcon"
import EmptyTask from '@/components/illustrations/EmptyTask'
import UserPhoto from "../ui/UserPhoto"
import CloseIcon from "@/components/icons/CloseIcon"
import TargetIcon from '../icons/TargetIcon'
import AttachIcon from "../icons/AttachIcon"
import PointsIcon from '@/components/icons/PointsIcon'
import MoreHorizontalIcon from '@/components/icons/MoreHorizontalIcon'
import ConfirmationModal from '@/components/ui/ConfirmationModal'
import UiDropdown from "./UiDropdown"
import UiMagicField from "../ui/UiMagicField"
import TaskAssignee from "@/components/tasks/components/TaskAssignee"
import ScoreDropdown from "@/components/tasks/components/ScoreDropdown"
import TaskCardSkeleton from '@/components/tasks/components/TaskCardSkeleton'
import TaskState from "@/components/tasks/components/TaskState"
import SphereIcon from "@/components/icons/SphereIcon"
import TaskTimeline from "@/components/tasks/components/TaskTimeline"
import UiLoadingButton from '@/components/ui/UiLoadingButton';
import debounce from "@/utils/debounce"
import TaskFiles from '@/components/tasks/components/TaskFiles';
import {StatusFinished} from "@/helpers/tasks"
import XuiEditor from "@/components/ui/XuiEditor"
import saveDraft from '@/components/tasks/mixins/saveDraft'

const { mapActions: taskActions } = createNamespacedHelpers('task')
const { mapActions: tasksActions, mapGetters: tasksGetters } = createNamespacedHelpers('tasks')

const pointsList = [1, 2, 3, 5, 8, 13, 21]

export default {
  name: 'TaskCardNew',

  components: {
    TaskFiles, ChevronRightIcon, SphereIcon,
    CloseIcon, UiDropdown, UserPhoto, UiButton, UiMagicField,
    TargetIcon, AttachIcon, TaskTimeline,
    XuiEditor,
    TaskAssignee, ScoreDropdown,
    TaskState, UiLoadingButton,
    MoreHorizontalIcon, ConfirmationModal, TaskCardSkeleton,
    EmptyTask, PointsIcon
  },

  mixins: [saveDraft],

  props: {
    taskId: {
      type: Number,
      default: 0
    },

    afterTaskId: {
      type: Number,
      default: 0
    },

    backlog: {
      type: Number,
      default: 0
    },

    memberId: {
      type: Number,
      default: 0
    },

    stageId: {
      type: Number,
      default: 0
    },

    sphereId: {
      type: Number,
      default: 0,
    },

    targetId: {
      type: Number,
      default: 0,
    },

    isModal: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      editor: null,
      defaultState: {},
      isTaskLoaded: false,
      uploadedFiles: [],
      selectedStateId: null,
      debouncedUpdaters: {},
      isCreating: false,
      showDeleteConfirmation: false,
      deleteProcessing: false,
      taskCreated: false,
    }
  },

  validations () {
    return {
      task: {
        name: {
          required: helpers.withMessage(this.$t('validation.emptyTaskTitle'), required),
        },
      },
    }
  },

  computed: {
    ...mapGetters(['authUser', 'stages', 'activeMembers', 'project', 'tags', 'spheres', 'targetsLoaded', 'targets']),
    ...tasksGetters(['stageTasks', 'currentStage']),
    ...mapGetters('task', ['task', 'comments', 'taskError', 'timeline']),

    isFinished () {
      return this.task.status === StatusFinished
    },

    formStages () {
      const stages = [...this.stages]

      if (!this.taskId) {
        stages.unshift({
          id: 0,
          name: 'Backlog'
        })
      }

      return stages
    },

    assigneesList () {
      return [{
        member_id: null,
        first_name: this.$t('tasks.notSelected')
      }, ...this.activeMembers]
    },

    targetsList () {
      return [{
        id: null,
        name: this.$t('tasks.noGoal')
      }, ...this.targets]
    },

    sphereList () {
      return [{
        id: null,
        name: this.$t('tasks.noSphere')
      }, ...this.spheres]
    },

    cancelTitle () {
      return this.taskId ? this.$t('common.close') : this.$t('common.cancel')
    },

    targetSelectorClasses () {
      const classes = ['common-dropdown']

      if (this.task.target) {
        classes.push(`${this.task.target.color}`)
      }

      if (this.isFinished) {
        classes.push('common-dropdown_disabled')
      }

      return classes.join(' ')
    },

    sphereSelectorClasses () {
      const classes = ['common-dropdown']

      if (this.task.sphere) {
        classes.push(`${this.task.sphere.color}`)
      }

      if (this.isFinished) {
        classes.push('common-dropdown_disabled')
      }

      return classes.join(' ')
    },

    commonDropdownClasses () {
      const classes = ['common-dropdown']

      if (this.isFinished) {
        classes.push('common-dropdown_disabled')
      }

      return classes.join(' ')
    },

    pointsList () {
      const list = pointsList.map(point => ({
        label: this.$tc('tasks.pointsLabel', point, { points: point }),
        value: point
      }))

      list.unshift({
        label: this.$t('tasks.pointsEmpty'),
        value: null
      })

      return list
    },

    assigneeName () {
      if (!this.task.state.assignee.member_id) {
        return this.$t('tasks.noAssignee')
      }

      return `${this.task.state.assignee.first_name} ${this.task.state.assignee.last_name}`;
    }
  },

  methods: {
    ...mapActions(['fetchTargets']),
    ...tasksActions(['changeProgress', 'updateStatusTasks', 'refreshBacklogTask', 'deleteTask']),
    ...taskActions(['createTask', 'fetchTask', 'refreshTask', 'updateTaskState', 'updateTaskField', 'uploadAttachment', 'setTaskField']),

    async onSubmit () {
      this.v$.$reset()
      const isFormCorrect = await this.v$.$validate()

      if (isFormCorrect) {
        this.isCreating = true

        if (typeof this.task.description !== 'string') {
          this.task.description = JSON.stringify({
            json: this.editor.getJSON(),
            html: this.editor.getHTML()
          })
        }

        await this.createTask(this.task)
        this.taskCreated = true
        this.isCreating = false;
        this.closeTaskCard()
      }
    },

    onCancel () {
      if (this.isModal) {
        this.checkDraftBeforeClose(() => this.$emit('close'))
        return
      }

      if (!this.isModal) {
        this.closeTaskCard()
      }
    },

    closeTaskCard () {
      if (this.isModal) {
        this.$emit('close')
        return
      }

      const route = this.$router.currentRoute

      const params = { project: this.project.code }
      if (route.params.stageId) {
        this.$router.push({ name: 'project.tasks.stage', query: route.query, params: {...params, stageId: route.params.stageId } })
      } else {
        this.$router.push({ name: 'project.tasks', query: route.query, params })
      }
    },

    loadTask () {
      this.isTaskLoaded = false

      const targetStage = this.backlog
        ? { id: 0, name: 'Backlog' }
        : { ...this.currentStage }

      this.defaultState = {
        stage: targetStage
      }

      if (this.taskId === 0) {
        if (this.stageId) {
          this.defaultState = {
            ...this.defaultState,
            stage: this.stages.find(stage => stage.id === this.stageId)
          }
        }

        if (this.memberId) {
          const member = this.activeMembers.find(item => item.member_id === this.memberId)
          if (member) {
            this.defaultState = {
              ...this.defaultState,
              assignee: {...member}
            }
          }
        }

        if (this.afterTaskId !== 0) {
          const targetTask = this.stageTasks.find(item => item.id === this.afterTaskId)

          if (targetTask) {
            this.defaultState = {
              ...this.defaultState,
              assignee: targetTask.state.assignee
            }
          }
        }
      }

      this.fetchTask({ taskId: this.taskId, afterTaskId: this.afterTaskId, defaultState: this.defaultState })
        .then(() => {
          this.isTaskLoaded = true
          this.selectedStateId = this.task.state_id

          if (!this.taskId) {
            this.setDefaultTarget()

            if (this.sphereId) {
              const sphere = this.spheres.find(sphere => sphere.id === this.sphereId)
              if (sphere) {
                this.setTaskField({ field: 'sphere', value: sphere })
              }
            }
          }
        })
    },

    async doTaskFieldUpdate (field, value) {
      const data = await this.updateTaskField({ id: this.taskId, field, value })

      if (data) {
        this.onTaskUpdated(data)
      }
    },

    onTaskUpdated (data) {
      const { task } = data
      this.refreshTask({ task })

      if (task.status === 'backlog') {
        const taskState = task.states.find(state => state.id === task.state_id)

        this.refreshBacklogTask({
          id:     task.id,
          name:   task.name,
          sphere: task.sphere,
          target: task.target,
          score:  task.score,
          state: {
            assignee: taskState.assignee
          }
        })
      }
    },

    onTargetChange (target) {
      this.doTaskFieldUpdate('target', target)
    },

    onSphereChange (sphere) {
      this.doTaskFieldUpdate('sphere', sphere)
    },

    targetOptionClasses (option) {
      const classes = []
      if (option.color) {
        classes.push('target-dropdown-option_with-color')
        classes.push(`target-dropdown-option_${option.color}`)
      }

      return classes
    },

    onAttachFile () {
      this.$refs.file.click()
    },


    sphereOptionClasses (sphere) {
      const classes = { 'dropdown-sphere-item': true }

      if (sphere.parent_id) {
        classes['ml-2'] = true
      }

      return classes
    },

    onFileChange () {
      const file = this.$refs.file.files[0]

      const formData = new FormData()
      formData.append('file', file)

      this.uploadedFiles.push(file)

      this.uploadAttachment({ id: this.taskId, formData, progressCallback: e => this.onFileProgress(e) })
    },

    onFileProgress () {

    },

    onTaskFieldChange (field, value) {
      const updateFunc = async (field, value) => {
        let updatingValue = value

        if (field === 'description') {
          const { getJSON, getHTML } = value

          updatingValue = JSON.stringify({
            json: getJSON(),
            html: getHTML()
          })
        }

        const data = await this.updateTaskField({ id: this.taskId, field, value: updatingValue })

        if (data) {
          this.onTaskUpdated(data)
        }
      }

      if (this.taskId) {
        if (!this.debouncedUpdaters[field]) {
          this.debouncedUpdaters[field] = debounce(updateFunc, 1500)
        }

        this.debouncedUpdaters[field](field, value)
      } else {
        updateFunc(field, value)
      }
    },

    showTaskNumber () {
      if (this.project.task_prefix) {
        return `${this.project.task_prefix}-${this.task.number}`
      }

      return this.task.number
    },

    onStageChange (stage) {
      if (!this.taskId) {
        this.doTaskStateUpdate(this.taskId, { stage })

        return
      }

      const assigneeId = this.task.state.assignee.member_id

      this.changeProgress({
        taskId: this.task.id,
        action: 'complete',
        confirmed: true,
        stage_id: stage.id,
        member_id: assigneeId
      }).then(() => {
        this.fetchTask({ taskId: this.task.id })
      })
    },

    onAssigneeChange (assignee) {
      this.doTaskStateUpdate(this.taskId, { assignee })
    },

    onScoreChange (score) {
      this.doTaskFieldUpdate('score', score)
    },

    onPointsChange (points) {
      this.doTaskFieldUpdate('points', points.value)
    },

    doTaskStateUpdate (id, state) {
      this.updateTaskState({ id, state })
        .then((data) => {
          if (!id) {
            return
          }

          this.onTaskUpdated(data)
        })
    },

    onDeleteTask () {
      this.showDeleteConfirmation = true
    },

    async onDeleteConfirm () {
      this.deleteProcessing = true
      try {
        await this.deleteTask({id: this.taskId})
      } catch (e) {
        this.deleteProcessing = false

        this.$notify({
          title: this.$t('common.error'),
          message: this.$t('tasks.deleteConfirmation.error'),
          position: 'bottom-right',
          type: 'error'
        })

        return
      }

      this.showDeleteConfirmation = false
      this.closeTaskCard()
    },

    setDefaultTarget () {
      if (!this.targetId) {
        return
      }

      const target = this.targets.find(item => item.id === this.targetId)
      if (!target) {
        return
      }

      this.setTaskField({ field: 'target', value: target })
    },

    onEditorInit ({ editor }) {
      this.editor = editor
    }
  },

  watch: {
    taskId: function () {
      this.loadTask()
    }
  },

  created () {
    this.loadTask()

    if (!this.targetsLoaded) {
      this.fetchTargets({ status: 'active' })
        .then(() => this.setDefaultTarget())
    }
  }
}
</script>
<style lang="sass">
.task-card-container
  height: 100%

  .el-skeleton
    height: 100%

.sw-task-card
  height: 100%
  display: flex
  flex-flow: column nowrap
  transition: all .3s ease-in
  background: var(--block-bg-color)

  &__head
    border-bottom: 1px solid var(--block-border-bg)
    padding: 16px 24px
    display: flex
    justify-content: center

    &-content
      display: flex
      align-items: center
      justify-content: space-between

  &__wrapper
    width: 100%
    max-width: 800px
    box-sizing: border-box

  &__body
    flex: 1
    overflow: auto

  &__section
    padding: 20px 24px
    display: flex
    justify-content: center

    &_bordered
      border-bottom: 1px solid var(--block-border-bg)

  &__head-actions
    align-items: center
    display: flex
    column-gap: 12px

  &__save-btn
    margin-left: 16px

  &__head-info
    display: flex
    align-items: center

  &__number
    font-size: 13px
    display: flex
    align-items: center

    &-project
      font-weight: 500
      cursor: default

    &-chevron
      margin: 0 6px

    &-title
      color: var(--text-light-color)

  &__title
    font-size: 22px
    font-weight: 500
    line-height: 30px
    color: var(--text-primary-color)
    font-family: Inter, Helvetica, Arial, sans-serif
    margin: 0px 0 16px
    transition: all .2s ease-in
    width: 100%

    &:focus
      outline: none

    &_error
      &.ui-magic-field
        background: var(--color-red-bg)
        box-shadow: 0 0 0 8px var(--color-red-bg)
        border-radius: 4px

      .ProseMirror p.is-editor-empty:first-child::before
        color: var(--color-red-text)

  &__badges
    display: flex
    align-items: center
    column-gap: 12px

  &__state
    display: flex
    align-items: center
    justify-content: space-between

  &__params
    display: flex
    align-items: center
    margin: 0 0 20px
    column-gap: 12px

  &__stage-line
    align-items: center
    display: flex

  &__stage
    margin-right: 12px
    width: 250px

  &__points
    align-items: center
    display: flex

    &-icon
      height: 14px
      margin-right: 6px
      width: 14px

      .path-fill
        fill: var(--grey-color-100)

  &__subhead
    justify-content: space-between
    display: flex
    align-items: center
    margin-top: 16px

  &__subhead-content
    align-items: center
    color: var(--text-primary-color)
    display: flex
    font-size: 14px
    font-weight: 500

  &__subhead-icon
    align-items: center
    display: flex
    justify-content: center
    margin-right: 8px
    width: 20px

    .path-stroke
      stroke: var(--text-light-color)

    .path-fill
      fill:  var(--text-light-color)

  &__description
    padding: 0px

  &__attach-link
    padding: 0 0 24px 28px

  &__transitions
    padding: 16px 0 0 0px

    .task-comment
      background: var(--grey-light-color-24)
      border-radius: 16px
      padding: 16px

  &__error
    color: var(--color-red-dots)

.task-card-error
  align-items: center
  height: 100%
  justify-content: center
  display: flex
  flex-flow: column

  &__illustration
    align-items: center
    display: flex
    justify-content: center
    margin: 0px 0 24px

  &__title
    color: var(--text-primary-color)
    font-size: 16px
    font-weight: 500
    margin-bottom: 12px

  &__text
    color: var(--text-light-color)
    font-size: 14px
    line-height: 1.6
    text-align: center
    width: 400px
    margin-bottom: 24px
</style>

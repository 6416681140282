<template>
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_6783_52264)">
    <path d="M0.75 16.4991V7.31914C0.750121 7.07841 0.808179 6.84125 0.919268 6.62769C1.03036 6.41412 1.19121 6.23043 1.38825 6.09214L8.5695 1.05214C8.69572 0.963547 8.84617 0.916016 9.00037 0.916016C9.15458 0.916016 9.30503 0.963547 9.43125 1.05214L16.6118 6.09214C16.8088 6.23043 16.9696 6.41412 17.0807 6.62769C17.1918 6.84125 17.2499 7.07841 17.25 7.31914V16.4991C17.25 16.6981 17.171 16.8888 17.0303 17.0295C16.8897 17.1701 16.6989 17.2491 16.5 17.2491H12.5002C12.3013 17.2491 12.1106 17.1701 11.9699 17.0295C11.8293 16.8888 11.7502 16.6981 11.7502 16.4991V12.2436C11.7502 12.0447 11.6712 11.854 11.5306 11.7133C11.3899 11.5727 11.1992 11.4936 11.0002 11.4936H6.99975C6.80084 11.4936 6.61007 11.5727 6.46942 11.7133C6.32877 11.854 6.24975 12.0447 6.24975 12.2436V16.4991C6.24975 16.6981 6.17073 16.8888 6.03008 17.0295C5.88943 17.1701 5.69866 17.2491 5.49975 17.2491H1.5C1.30109 17.2491 1.11032 17.1701 0.96967 17.0295C0.829018 16.8888 0.75 16.6981 0.75 16.4991Z" stroke="#5B6372" stroke-width="1.5" class="path-stroke"/>
  </g>
</svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'DashboardIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

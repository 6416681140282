<template>
  <div class="db-active-task">
    <div class="db-active-task__main">
      <div class="db-active-task__line">
        <div>
          <el-skeleton-item variant="text" style="width: 60px; height: 20px;"/>
        </div>

        <div class="db-active-task__params">
          <el-skeleton-item variant="text" style="width: 100px; height: 20px;"/>
        </div>
      </div>


      <div class="db-active-task__title">
        <el-skeleton-item variant="text" style="width: 75%; height: 20px;"/>
      </div>

      <div class="db-active-task__line">
        <div class="db-active-task__params db-active-task__author">
          <el-skeleton-item variant="text" style="width: 140px; height: 20px;"/>
        </div>

        <div class="db-active-task__params">
          <el-skeleton-item variant="text" style="width: 80px; height: 20px;" class="mr-2"/>
          <el-skeleton-item variant="text" style="width: 80px; height: 20px;"/>
        </div>
      </div>
    </div>

    <div class="db-active-task__footer">
      <div class="db-active-task__progress">
        <el-skeleton-item variant="circle" style="width: 54px; height: 54px;"/>

        <div class="db-active-task__progress-data">
          <div class="db-active-task__progress-spent">
            <el-skeleton-item variant="text" style="width: 60px; height: 16px;"/>
          </div>

          <div class="db-active-task__progress-date">
            <el-skeleton-item variant="text" style="width: 90px; height: 16px;"/>
          </div>
        </div>
      </div>

      <div class="db-active-task__actions">
        <el-skeleton-item variant="text" style="width: 80px; height: 32px;" class="mr-2"/>

        <el-skeleton-item variant="text" style="width: 80px; height: 32px;"/>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'SkeletonActiveTask'
  }
</script>

import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
import emojiData from "emoji-mart-vue-fast/data/twitter.json"

const autoEmojis = {
  ')': {
    native: String.fromCodePoint(0x1F642),
    id: 'slightly_smiling_face'
  },
  '(': {
    native: String.fromCodePoint(0x1F641),
    id: 'slightly_frowning_face'
  }
}

export default {
  components: { Picker },

  methods: {
    onEmojiListChange ({ items, query, range, decorationNode }) {
      if (autoEmojis[query]) {
        const item = autoEmojis[query]
        this.suggestionRange.to += 1
        this.insertSuggestion({
          range: this.suggestionRange,
          attrs: {
            id: item.id,
            label: item.native
          },
        })

        this.onSuggestionExit()
        return
      }

      this.onSuggestionChange('emoji', { items, query, range, decorationNode })
    },

    newEmojiIndex (data = null, options = {}) {
      const eData = data || emojiData
      return new EmojiIndex(eData, options)
    }
  }
}
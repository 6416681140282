<template>
  <node-view-wrapper :as="cellTag" :attrs="node.attrs">
    <div class="xui-cell-options" @mousedown="preventMouseDown" v-if="showMenu">
      <ui-button ui-class="size_xs common" class="btn-icon" :class="optionsBtnClasses" ref="reference">
        <chevrons-down class="xui-cell-options-icon"/>
      </ui-button>
    </div>
    <div data-node-view-content class="table-cell"></div>
  </node-view-wrapper>
</template>
<script>
import { nodeViewProps } from '@tiptap/vue-2'
import UiButton from 'scorework-ui/src/components/UiButton'
import ChevronsDown from '@/components/icons/ChevronsDown'
import NodeViewWrapper from '@/components/ui/textEditor/core/NodeViewWrapper'

export default {
  name: 'TableCellView',

  components: { ChevronsDown, NodeViewWrapper, UiButton },

  props: nodeViewProps,

  data() {
    return {
      isOpen: false,
      popover: null,
      cellTag: this.node.type.name === 'tableCell' ? 'td' : 'th',
    }
  },

  watch: {
    showMenu (val) {
      if (!val) {
        this.isOpen = false
        this.popover.hide(this.$refs.reference.$el)
      }
    }
  },

  computed: {
    showMenu () {
      for (const dec of this.decorations) {
        if (dec.type.attrs.class === 'has-focus') {
          return true
        }
      }

      return false
    },

    optionsBtnClasses () {
      const classes = []
      if (this.isOpen) {
        classes.push('ui-button_common_active')
      }

      return classes
    }
  },

  methods: {
    preventMouseDown (e) {
      e.preventDefault()
      e.stopImmediatePropagation()

      this.isOpen = this.popover.toggle(this.$refs.reference.$el)
    },
  },

  mounted () {
    this.popover = this.extension.options.getPopover()
  }
}
</script>
<style lang="sass">
  .xui-cell-options
    position: absolute
    top: 8px
    right: 8px
    z-index: 100

    &-icon
      transition: all ease-in .2s
      margin-top: 1px
      width: 10px

  .ui-button_common_active
    .xui-cell-options-icon
      transform: rotate(-180deg) translate(0px, 1px)
</style>

<template>
  <div class="setup-layout">
    <div class="setup-layout__area setup-layout__area_worked">
      <div class="setup-block">
        <div class="setup-block__logo">
          <logo-icon class="setup-block__logo-icon"/>
          Scorework
        </div>

        <div class="setup-block__body">
          <h1 class="setup-title">{{ $t('setup.members.title') }}</h1>

          <invite-members
            cancel-title="setup.members.cancel"
            @submit="onSubmit"
            @cancel="onCancel"
          />
        </div>
      </div>
    </div>
    <div class="setup-layout__area setup-layout__area_example">
      <members-example :active-hint="activeHint" @change="onHintChange"/>
    </div>
  </div>
</template>
<script>
import LogoIcon from "@/components/icons/LogoIcon"
import InviteMembers from "@/components/settings/InviteMembers"
import MembersExample from "@/components/setup/MembersExample"
import {mapGetters} from "vuex";

export default {
  name: 'SetupMembers',

  components: { LogoIcon, InviteMembers, MembersExample },

  data () {
    return {
      activeHint: 'targets'
    }
  },

  computed: {
    ...mapGetters(['project']),
  },

  methods: {
    onSubmit () {
      this.$router.push({ name: 'setup.spheres', params: { project: this.project.code } })
    },

    onCancel () {
      this.$router.push({ name: 'setup.spheres', params: { project: this.project.code } })
    },

    onHintChange (val) {
      this.activeHint = val
    }
  }
}
</script>

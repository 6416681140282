<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" d="M2.07084 10.815L2.89334 11.6375L3.94334 10.5933L3.11501 9.76497L2.07084 10.815ZM6.41668 13.0958C6.60334 13.0958 7.58334 13.0958 7.58334 13.0958V11.375H6.41668V13.0958ZM7.00001 3.2083C6.07175 3.2083 5.18151 3.57705 4.52514 4.23343C3.86876 4.8898 3.50001 5.78004 3.50001 6.7083C3.50001 7.63656 3.86876 8.5268 4.52514 9.18318C5.18151 9.83955 6.07175 10.2083 7.00001 10.2083C7.92827 10.2083 8.81851 9.83955 9.47488 9.18318C10.1313 8.5268 10.5 7.63656 10.5 6.7083C10.5 4.77163 8.93084 3.2083 7.00001 3.2083ZM11.6667 7.29163H13.4167V6.12497H11.6667V7.29163ZM10.0567 10.5933L11.1067 11.6375L11.9292 10.815L10.885 9.76497L10.0567 10.5933ZM11.9292 2.60163L11.1067 1.77913L10.0567 2.8233L10.885 3.65163L11.9292 2.60163ZM7.58334 0.320801H6.41668V2.04163H7.58334V0.320801ZM2.33334 6.12497H0.583344V7.29163H2.33334V6.12497ZM3.94334 2.8233L2.89334 1.77913L2.07084 2.60163L3.11501 3.65163L3.94334 2.8233Z" fill="#F5F5F5"/>
  </svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'DayIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

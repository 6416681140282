import apiService from "@/services/api";
import {createProjectMemberTransformer} from "@/transformers/members";

export const state = {
    report: null,
}

export const mutations = {
    SET_REPORT (state, report) {
        state.report = report
    }
}

export const actions = {
    async fetchReport ({ commit, rootGetters }, criteria) {
        const { project } = rootGetters
        const { data } = await apiService.get(`projects/${project.code}/report`, criteria)
        const transformMember = createProjectMemberTransformer(rootGetters)
        commit('SET_REPORT', {
            ...data,
            rows: data.rows.map(({ member, ...row }) => ({
                ...row,
                member: transformMember(member)
            }))
        })
        return data
    },

    async downloadReport ({ rootGetters }, criteria) {
        const { project } = rootGetters
        const params = new URLSearchParams(criteria)
        const response = await apiService.downloadFile(`projects/${project.code}/report/download?${params.toString()}`, criteria)
        let blob = new Blob([response.data]),
            url = window.URL.createObjectURL(blob)

        const disposition = response.headers['content-disposition']
        let filename = ''

        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }


        var fileLink = document.createElement('a');

        fileLink.href = url;
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();
    }
}

export const getters = {
    report: ({ report }) => report,
}

export const namespaced = true

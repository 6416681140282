<template>
  <div class="ui-sektor" :style="styles">
    <svg class="ui-sektor__svg" :viewBox="viewBox" :style="svgStyles">
      <path
          class="ui-sektor__sector"
          :fill="color"
          :d="d"
      />
    </svg>
  </div>
</template>
<script>
  export default {
    props: {
      percent: {
        type: Number,
      },

      gap: {
        type: Number,
        default: 2,
      },

      size: {
        type: Number,
        required: true,
      },

      color: {
        type: String,
        default: '#789',
      }
    },

    computed: {
      styles () {
        const size = this.size + this.gap

        return {
          borderRadius: (size / 2) + 'px',
          height: `${size}px`,
          width: `${size}px`,
        }
      },

      svgStyles () {
        return {
          height: `${this.size}px`,
          width: `${this.size}px`,
        }
      },

      center () {
        return this.size / 2
      },

      radius () {
        return this.center
      },

      viewBox () {
        return `0 0 ${this.size} ${this.size}`
      },

      angle () {
        return 360 / 100 * this.percent
      },

      d () {
        // Arc angles
        const firstAngle = this.angle > 180 ? 90 : this.angle - 90
        const secondAngle = -270 + this.angle - 180

        // Arcs
        const firstArc = this.getArc(firstAngle)
        const secondArc = this.angle > 180 ? this.getArc(secondAngle) : ''

        // start -> starting line
        // end -> will path be closed or not
        let end = ''
        let start = null

        start = 'M' + this.center + ',' + this.center + ' L' + this.center + ',' + 0
        end = 'z'

        return start + ' ' + firstArc + ' ' + secondArc + ' ' + end
      }
    },

    methods: {
      getArc (angle) {
        const x = this.center + this.radius * Math.cos(this.radians(angle))
        const y = this.center + this.radius * Math.sin(this.radians(angle))

        return 'A' + this.radius + ',' + this.radius + ' 1 0 1 ' + x + ',' + y
      },

      radians (degrees) {
        return degrees / 180 * Math.PI
      }
    }
  }
</script>
<style lang="sass">
  .ui-sektor
    align-items: center
    display: flex
    justify-content: center
    box-shadow: 0 0 0 1.5px var(--primary-color)
</style>

<template>
  <div class="subscription-wrapper">
    <el-skeleton :loading="isLoading" animated>
      <template slot="template">
        <div class="rate-plan">
          <div class="rate-plan__row">
            <div>
              <div class="rate-plan__title">
                <el-skeleton-item variant="text" style="width: 150px; height: 20px;"/>
              </div>
              <div class="rate-plan__subtitle">
                <el-skeleton-item variant="text" style="width: 60px; height: 12px;"/>
              </div>
            </div>

            <div>
              <div class="rate-plan__price">
                <el-skeleton-item variant="text" style="width: 80px; height: 20px;"/>
              </div>
              <div class="rate-plan__unit">
                <el-skeleton-item variant="text" style="width: 100px; height: 12px;"/>
              </div>
            </div>
          </div>

          <div class="rate-plan__stats">
            <div class="rate-plan__stats-label">
              <el-skeleton-item variant="text" style="width: 150px; height: 18px;"/>
            </div>

            <div class="rate-plan__stats-value">
              <el-skeleton-item variant="text" style="width: 120px; height: 18px;"/>
            </div>
          </div>

          <div class="rate-plan__row rate-plan__row_last">
            <div>
              <div class="rate-plan__date-label">
                <el-skeleton-item variant="text" style="width: 80px; height: 12px;"/>
              </div>

              <div class="rate-plan__date-value">
                <el-skeleton-item variant="text" style="width: 100px; height: 12px;"/>
              </div>
            </div>

            <div>
              <div class="rate-plan__date-label">
                <el-skeleton-item variant="text" style="width: 80px; height: 12px;"/>
              </div>

              <div class="rate-plan__date-value">
                <el-skeleton-item variant="text" style="width: 100px; height: 12px;"/>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template>
        <div class="rate-plan">
          <div class="rate-plan__row">
            <div>
              <div class="rate-plan__title">
                {{ $t(`settings.rate.ratePlans.${subscription.rate_plan_code}`) }}
              </div>
              <div class="rate-plan__subtitle">
                {{ $t(`settings.rate.currentPlan`) }}
              </div>
            </div>

            <div>
              <div class="rate-plan__price">
                {{ formattedPrice }}
              </div>
              <div class="rate-plan__unit">
                {{ $t(`settings.rate.priceTypes.${subscription.price_type}`) }}
              </div>
            </div>
          </div>

          <div class="rate-plan__stats">
            <div class="rate-plan__stats-label">
              <small-users-icon class="rate-plan__users-icon"/> {{ $t('settings.rate.membersCount') }}
            </div>

            <div class="rate-plan__stats-value">
              {{ membersCountFeature.used }} <span class="rate-plan__stats-capacity">{{ formattedCapacity(membersCountFeature.capacity) }}</span>
            </div>
          </div>

          <div class="rate-plan__row rate-plan__row_last">
            <div>
              <div class="rate-plan__date-label">
                {{ $t(`settings.rate.activatedAt`) }}
              </div>

              <div class="rate-plan__date-value">
                {{ activatedAt}}
              </div>
            </div>

            <div>
              <div class="rate-plan__date-label">
                {{ $t(`settings.rate.expiredAt`) }}
              </div>

              <div class="rate-plan__date-value">
                {{ expiredAt }}
              </div>
            </div>
          </div>
        </div>

        <div class="rate-plan-actions">
          <a class="ui-button" :href="`${baseDomain}/account/workspaces/${workspace.global_id}/plan`" target="_blank" style="text-decoration: none;">
            {{ $t('settings.rate.changePlan') }}
          </a>
        </div>
      </template>
    </el-skeleton>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import SmallUsersIcon from "@/components/icons/SmallUsersIcon"
  import { transformDateToObject } from "@/services/scoring"
  import {DateTime} from "luxon";

  export default {
    name: 'RateView',

    components: { SmallUsersIcon },

    data () {
      return {
        isLoading: false,
      }
    },

    computed: {
      ...mapGetters('subscription', ['subscription']),
      ...mapGetters(['workspace']),

      formattedPrice () {
        return (new Intl.NumberFormat('ru-RU', {
          style: 'currency',
          currency: this.subscription.currency,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })).format(this.subscription.price)
      },

      activatedAt () {
        return transformDateToObject(this.subscription.activated_at).toLocaleString(DateTime.DATE_FULL)
      },

      expiredAt () {
        if (!this.subscription.expired_at) {
          return this.$t('settings.rate.unlimited')
        }

        return transformDateToObject(this.subscription.expired_at).toLocaleString(DateTime.DATE_FULL)
      },

      membersCountFeature () {
        return this.subscription.features.find((item) => item.code === 'members_count')
      },

      baseDomain () {
        const parts = window.location.hostname.split('.')
        return location.protocol + '//' +parts.slice(1).join('.')
      },
    },

    methods: {
      ...mapActions('subscription', ['fetchSubscription']),

      formattedCapacity (count) {
        if (!count) {
          return this.$t('settings.rate.unlimitedRounded')
        }

        return this.$t('settings.rate.ofCapacity', { count })
      }
    },

    async created () {
      this.isLoading = true

      try {
        await this.fetchSubscription()
      } finally {
        this.isLoading = false
      }
    }
  }
</script>
<style lang="sass">
  .subscription-wrapper
    max-width: 600px

  .rate-plan
    padding: 20px
    border-radius: 12px
    background: var(--color-grey-bg)

    &__row
      display: flex
      justify-content: space-between
      align-items: center

      &_last
        border-top: 1px solid var(--grey-light-color-60)
        margin-top: 20px
        padding-top: 20px

    &__subtitle
      color: var(--text-light-color)
      margin-top: 4px
      font-size: 12px

    &__title
      font-size: 20px
      font-weight: 500

    &__price
      font-size: 20px
      font-weight: 500

    &__unit
      color: var(--grey-color-100)
      font-size: 12px
      margin-top: 4px

    &__date-label
      color: var(--grey-color-100)
      margin-bottom: 6px
      font-size: 12px

    &__stats
      margin-top: 20px

      &-label
        align-items: center
        display: flex
        margin-bottom: 8px
        color: var(--text-light-color)

      &-value
        font-size: 18px
        font-weight: 600
        display: flex
        align-items: center

      &-capacity
        font-weight: 400
        color: var(--text-light-color)
        font-size: 14px
        margin-left: 4px


    &__users-icon
      margin-right: 6px
      .path-stroke
        stroke: var(--primary-color)

  .rate-plan-actions
    margin-top: 24px
    display: flex
    justify-content: end
    align-items: center
</style>

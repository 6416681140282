<template>
  <div class="nsphere-item" v-draggable-item="{ list: listName, item: sphere, dragSelector: '.nsphere-item__drag' }">
    <div class="nsphere-item__content">
      <div class="nsphere-item__drag">
        <draggable-icon/>
      </div>

      <ui-magic-field
        :value="sphere.name"
        :focused="isFocused"
        :placeholder="$t('setup.newSphere')"
        class="nsphere-item__title"
        @input="onChangeName"
        @blur="onBlur"
        @new-line="onNewLine"
      />

      <div class="nsphere-item__controls">
        <el-tooltip class="item" effect="dark" :content="$t('setup.addNestedSphere')" placement="top" v-if="parentCode === null">
          <div class="nsphere-item__control nsphere-item__control_add" @click="onCreate">
            <plus class="nsphere-item__control-icon"/>
          </div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="$t('common.delete')" placement="top">
          <div class="nsphere-item__control nsphere-item__control_delete" @click="onDelete">
            <close-icon  class="nsphere-item__control-icon"/>
          </div>
        </el-tooltip>
      </div>
    </div>

    <spheres-list v-if="sphere.children && sphere.children.length"
      :spheres="sphere.children"
      :parent="sphere"
      class="nsphere-item__children"
    />
  </div>
</template>
<script>
  import UiMagicField from "../ui/UiMagicField"
  import Plus from "../icons/Plus"
  import CloseIcon from "../icons/CloseIcon"
  import DraggableIcon from "../icons/DraggableIcon"
  import SpheresList from "./SpheresList"
  import { createNamespacedHelpers } from "vuex"

  const { mapActions: spheresActions } = createNamespacedHelpers('spheres')


  export default {
    name: 'SphereItem',

    components: { UiMagicField, Plus, CloseIcon, DraggableIcon, SpheresList },

    props: {
      sphere: {
        type: Object,
        required: true
      },

      parent: {
        default: null
      }
    },

    data () {
      return {
        isFocused: this.sphere.name === '' && !this.sphere.id
      }
    },

    computed: {
      listName () {
        return this.parentCode
          ? `spheres-${this.parentCode}`
          : `spheres`
      },

      parentCode () {
        return this.parent
          ? this.parent.id || this.parent.code
          : null
      }
    },

    methods: {
      ...spheresActions(['createSphere', 'deleteSphere', 'updateSphere']),

      onDelete () {
        this.deleteSphere({ sphere: this.sphere })
      },

      onCreate () {
        this.createSphere({ parentSphere: this.sphere })
      },

      onChangeName (name) {
        this.updateSphere({ sphere: this.sphere, name })
      },

      onNewLine () {
        this.createSphere({ parentSphere: this.parent, after: this.sphere })
      },

      onBlur () {
        if (this.sphere.name === '' && (!this.sphere.children || !this.sphere.children.length)) {
          this.deleteSphere({ sphere: this.sphere })
        }
      }
    },

    beforeCreate () {
      this.$options.components.SpheresList = require('./SpheresList').default
    }
  }
</script>

<template>
  <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" d="M2.4 4.8H7.2V1.2C7.2 0.88174 7.32643 0.576516 7.55147 0.351472C7.77652 0.126428 8.08174 0 8.4 0C8.71826 0 9.02348 0.126428 9.24853 0.351472C9.47357 0.576516 9.6 0.88174 9.6 1.2V10.8C9.6 11.1183 9.47357 11.4235 9.24853 11.6485C9.02348 11.8736 8.71826 12 8.4 12C8.08174 12 7.77652 11.8736 7.55147 11.6485C7.32643 11.4235 7.2 11.1183 7.2 10.8V7.2H2.4V10.8C2.4 11.1183 2.27357 11.4235 2.04853 11.6485C1.82348 11.8736 1.51826 12 1.2 12C0.88174 12 0.576516 11.8736 0.351472 11.6485C0.126428 11.4235 0 11.1183 0 10.8V1.2C0 0.88174 0.126428 0.576516 0.351472 0.351472C0.576516 0.126428 0.88174 0 1.2 0C1.51826 0 1.82348 0.126428 2.04853 0.351472C2.27357 0.576516 2.4 0.88174 2.4 1.2V4.8ZM13.824 5.7348H12V3.6H16.032V12H13.824V5.7348Z" fill="#333840"/>
  </svg>
</template>
<script>
import iconMixin from '../iconMixin'

export default {
  name: 'H1Icon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

import socketService from "@/services/socket"
import apiService from "@/services/api"
import { getMessageDateAnchor } from "@/services/scoring"
import { sortByField } from '@/utils/arrays'
import { debounce } from 'throttle-debounce'

import {DateTime} from "luxon";

const soundMessage = new Audio(require('../../assets/sounds/message.mp3'));
const soundSent = new Audio(require('../../assets/sounds/sent.mp3'));

export const state = {
  channels: [],
  isChannelsLoaded: false,
  activeChannelId: null,
  messages: {},
  hasMoreMessages: {},
  typingDebouncers: {},
  typingMembers: [],
  onLoadCallback: () => ({}),
  debounceMessageTyping: undefined,
  fetching: {},
  isWindowActive: true,
  helpdeskAuthor: null
}

export const mutations = {
  SET_CHANNELS (state, channels) {
    state.channels = channels.map(channel => performChannel(channel))
  },

  SET_CHANNELS_LOADED (state, value) {
    state.isChannelsLoaded = value
  },

  APPEND_CHANNEL (state, channel) {
    state.channels.unshift(channel)
  },

  ADD_CHANNEL (state, channel) {
    const index = state.channels.findIndex(item => item.id === channel.id)

    if (index === -1) {
      state.channels.push(performChannel(channel))
      state.channels.sort(sortByField('updated_at', 'desc'))
    }
  },

  SET_MESSAGES (state, { messages, channelId }) {
    const channel = state.channels.find(channel => channel.id === channelId)

    state.messages = {
      ...state.messages,
      [channelId]: buildMessages(channel, messages.reverse())
    }
  },

  PREPEND_MESSAGES (state, { messages, channelId }) {
    const channel = state.channels.find(channel => channel.id === channelId)
    const messageGroups = buildMessages(channel, messages.reverse())

    if (messageGroups.length
      && state.messages[channelId].length
      && messageGroups[messageGroups.length - 1].date === state.messages[channelId][0].date
    ) {
      const firstStateMessages = state.messages[channelId][0].messages
      const lastNewGroupMessages = messageGroups[messageGroups.length - 1].messages
      if (firstStateMessages[0].member_id === lastNewGroupMessages[lastNewGroupMessages.length - 1].member_id) {
        const mergedMessages = [
          ...lastNewGroupMessages[lastNewGroupMessages.length - 1].messages,
          ...JSON.parse(JSON.stringify(firstStateMessages[0].messages)),
        ]

        state.messages[channelId][0].messages[0].messages = mergedMessages
        messageGroups[messageGroups.length - 1].messages.pop()
      }

      state.messages[channelId][0].messages = [
        ...messageGroups[messageGroups.length - 1].messages,
        ...state.messages[channelId][0].messages,
      ]

      messageGroups.pop()
    }

    state.messages[channelId][0].firstId = state.messages[channelId][0].messages[0].messages[0].id

    state.messages[channelId] = [
      ...messageGroups,
      ...state.messages[channelId],
    ]
  },

  SET_FETCHING_MESSAGES(state,  { channelId, value }) {
    state.fetching[channelId] = value
  },

  SET_HAS_MORE_MESSAGES (state, { channelId, value }) {
    state.hasMoreMessages[channelId] = value
  },

  SET_ACTIVE_CHANNEL (state, channelId) {
    if (state.activeChannelId !== channelId) {
      state.typingMembers = []
    }
    state.activeChannelId = channelId
  },

  SET_ONLOAD_CALLBACK (state, cb) {
    state.onLoadCallback = cb
  },

  SET_IS_WINDOW_ACTIVE (state, value) {
    state.isWindowActive = value
  },

  APPEND_MESSAGE (state, message) {

    const channelIndex = state.channels.findIndex(channel => channel.id === message.channel_id)
    let channel = state.channels[channelIndex]

    const unseenCount = message.member_id !== channel.member_id
      ? channel.unseen_count + 1
      : channel.unseen_count

    state.channels.splice(channelIndex, 1, performChannel({
      ...channel,
      unseen_count: unseenCount,
      updated_at: message.date,
      last_message: {
        member_id: message.member_id,
        message: message.message
      }
    }))

    state.channels.sort(sortByField('updated_at', 'desc'))

    if (state.activeChannelId !== message.channel_id || !state.isWindowActive) {
      soundMessage.play()

      if (!state.messages[message.channel_id]) {
        return
      }
    }

    message.new = true

    const groups = state.messages[message.channel_id]

    const { member_id, date, channel_id, ...messageData } = message
    channel = state.channels.find(channel => channel.id === channel_id)

    const dateObj = DateTime.fromISO(date, { zone: 'UTC' }).setZone('local')
    const itemDate = dateObj.toFormat('yyyy-LL-dd')

    messageData.time = dateObj.toFormat('T')

    let groupItem = groups ? groups[groups.length - 1] : null

    if (!groupItem || groupItem.date !== itemDate) {
      state.messages[message.channel_id].push({
        date: itemDate,
        dateLabel: getMessageDateAnchor(dateObj),
        messages: [{
          member_id,
          id: messageData.id,
          messages: [ messageData ]
        }]
      })

      return
    }

    const lastMessage = groupItem.messages[groupItem.messages.length - 1]

    messageData.unseen = channel.member_id !== member_id

    if (lastMessage.member_id !== member_id) {
      const memberBlock = {
        member_id,
        id: messageData.id,
        messages: [ messageData ]
      }

      if (member_id === -1 && messageData.message.author) {
        memberBlock.author = {
          member_id: -1,
          ...messageData.message.author
        }
      }

      groupItem.messages.push(memberBlock)
    } else {
      lastMessage.messages.push(messageData)
    }
  },

  ADD_TYPING_MEMBER (state, { memberId, author }) {
    state.helpdeskAuthor = author
    const set = new Set(state.typingMembers)
    set.add(memberId)

    state.typingMembers = Array.from(set)
  },

  REMOVE_TYPING_MEMBER (state, memberId) {
    const set = new Set(state.typingMembers)
    set.delete(memberId)

    state.typingMembers = Array.from(set)
  },

  MARK_MESSAGES_SEEN (state, { channelId, messageIds }) {
    const channel       = state.channels.find(channel => channel.id === channelId)
    const seenCount     = messageIds.length
    const unSeenCount   = channel.unseen_count - seenCount

    state.channels = state.channels.map(channel => {
      if (channel.id !== channelId) {
        return channel
      }

      return {
        ...channel,
        unseen_count: unSeenCount < 0 ? 0 : unSeenCount
      }
    })

    const channelMessages = state.messages[channelId]
    const seenMessageIds = [...messageIds]
    const messageId = seenMessageIds.shift()

    const updatedChannelMessages = channelMessages.map(messagesByGroup => {
      if (messagesByGroup.firstId > messageId || messagesByGroup.lastId < messageId) {
        return messagesByGroup
      }

      const { messages, ...groupData } = messagesByGroup

      return {
        ...groupData,
        messages: messages.map(messagesByMember => {
          return {
            ...messagesByMember,
            messages: messagesByMember.messages.map(message => {
              if (message.id === messageId) {
                message.unseen = false
              } else {
                const index = seenMessageIds.indexOf(message.id)

                if (index !== -1) {
                  message.unseen = false
                  seenMessageIds.splice(index, 1)
                }
              }

              return message
            })
          }
        })

      }

    })

    state.messages = {
      ...state.messages,
      [channelId]: updatedChannelMessages
    }
  }
}

export const actions = {
  fetchChannels ({ commit }) {
    return apiService.get('messenger/channels')
      .then(({ data }) => {
        commit('SET_CHANNELS_LOADED', true)
        commit('SET_CHANNELS', data)
      })
  },

  createChannel ({ commit }, channel) {
    return apiService.post('messenger/channels', channel)
      .then(({ data }) => {
        commit('ADD_CHANNEL', data)

        return data
      })
  },

  fetchChannelMessages ({ commit, state }, { channelId }) {
    if ((channelId in state.hasMoreMessages && !state.hasMoreMessages[channelId]) || state.fetching[channelId] === true) {
      return
    }

    commit('SET_FETCHING_MESSAGES', { channelId, value: true })

    let query = `messenger/channels/${channelId}/messages`

    if (channelId in state.messages && state.messages[channelId].length) {
      query += `?first_id=${state.messages[channelId][0].firstId}`
    }

    return apiService.get(query)
      .then(({ data }) => {
        try {
          if (!data.length) {
            commit('SET_HAS_MORE_MESSAGES', { channelId, value: false })
          }

          if (channelId in state.messages) {
            commit('PREPEND_MESSAGES', { channelId, messages: data })
          } else {
            commit('SET_MESSAGES', { channelId, messages: data })
          }
        } finally {
          commit('SET_FETCHING_MESSAGES', { channelId, value: false })
        }
      })
  },

  switchChannel ({ commit }, { channelId, loadMessagesCb }) {
    commit('SET_ACTIVE_CHANNEL', channelId)
    commit('SET_ONLOAD_CALLBACK', loadMessagesCb)
  },

  setIsWindowActive ({ commit }, value) {
    commit('SET_IS_WINDOW_ACTIVE', value)
  },

  createMessage ({ rootGetters }, { channel, message }) {
    message.channel_id = channel.id
    message.member_id  = rootGetters.authUser.member_id

    soundSent.play()

    socketService.socket.emit('messenger.messages.create', {
      workspaceId: channel.workspace_id,
      members: channel.members,
      message
    })
  },

  appendMessage ({ commit }, message) {
    commit('APPEND_MESSAGE', message)

    /**if (getters.activeChannelId === message.channel_id) {
      state.onLoadCallback()
    }*/
  },

  typeMessage (ctx, { channel, memberId }) {
    socketService.socket.emit('messenger.messages.typing', {
      workspaceId: channel.workspace_id,
      channelId: channel.id,
      memberId,
      members: channel.members,
    })
  },

  setMessageTyping ({ state, commit, rootGetters }, { channelId, memberId, author }) {
    if (state.activeChannelId === channelId && rootGetters.authUser.member_id != memberId) {
      commit('ADD_TYPING_MEMBER', { memberId, author })

      if (!(memberId in state.typingDebouncers)) {
        state.typingDebouncers[memberId] = debounce(1500, ({ commit, memberId }) => {
          commit('REMOVE_TYPING_MEMBER', memberId)
        })
      }

      state.typingDebouncers[memberId]({ commit, memberId })
    }
  },

  markSeenMessages ({ commit }, { channel, seenMessages }) {
    if (seenMessages.length === 0) {
      return;
    }

    seenMessages.sort(sortByField('date'))

    commit('MARK_MESSAGES_SEEN', { channelId: channel.id, messageIds: seenMessages.map(item => item.id) })

    const lastSeenMessage = seenMessages[seenMessages.length - 1]

    socketService.socket.emit('messenger.channels.seen', {
      workspaceId: channel.workspace_id,
      memberId: channel.member_id,
      channelId: channel.id,
      lastSeenId: lastSeenMessage.id
    })
  },

  appendChannel ({ commit }, { channel }) {
    commit('APPEND_CHANNEL', channel)
  },

  fetchHelpdeskChannel ({ commit, dispatch }) {
    return apiService.get(`messenger/channels/helpdesk`)
      .then(({ data }) => {
        commit('ADD_CHANNEL', data)
        dispatch('refreshUser', { helpdesk_channel_id: data.id }, { root: true })

        return data
      })
  }

}

export const getters = {
  channels: ({ channels }) => channels,

  activeChannels: ({ channels }) => channels.filter(channel => channel.last_message),

  dialogChannels: (state , { activeChannels }, rootState, { authUser }) => activeChannels.filter(channel => channel.id !== authUser.helpdesk_channel_id),

  sidebarChannels: (state, { activeChannels }) => activeChannels.slice(0, 4),

  activeChannelId: ({ activeChannelId }) => activeChannelId,

  typingMembers: ({ typingMembers }) => typingMembers,

  channelMessages: ({ activeChannelId, messages }) => messages[activeChannelId] || [],

  activeChannel: ({ channels, activeChannelId }) => channels.find(channel => channel.id === activeChannelId) || {},

  isChannelsLoaded: ({ isChannelsLoaded }) => isChannelsLoaded,

  helpdeskChannel: ({ channels }, getters, rootState, { authUser }) => channels.find(channel => channel.id === authUser.helpdesk_channel_id),

  helpdeskAuthor: ({ helpdeskAuthor }) => helpdeskAuthor
}

function buildMessages (channel, messages) {
  const lastSeenTs = channel.last_seen_ts

  let currentMemberId = null
  let currentDate = ''

  let groupItem = null
  let lastMessageId = null

  const results = messages.reduce((results, item) => {
    // eslint-disable-next-line
    const { member_id, date, channel_id, ...messageData } = item

    const dateObj = DateTime.fromISO(date, { zone: 'UTC' }).setZone('local')
    const itemDate = dateObj.toFormat('yyyy-LL-dd')

    if (itemDate !== currentDate) {
      if (groupItem !== null) {
        groupItem.lastId = lastMessageId
        results.push(groupItem)
      }

      currentDate = itemDate
      currentMemberId = null

      groupItem = {
        date: itemDate,
        dateLabel: getMessageDateAnchor(dateObj),
        messages: [],
        firstId: item.id
      }
    }

    messageData.date    = date
    messageData.time    = dateObj.toFormat('T')
    messageData.unseen  = lastSeenTs < date

    if (member_id !== currentMemberId) {
      currentMemberId = member_id

      const memberBlock = {
        member_id,
        id: messageData.id,
        messages: [ messageData ]
      }

      if (member_id === -1 && messageData.message.author) {
        memberBlock.author = {
          member_id: -1,
          ...messageData.message.author
        }
      }

      groupItem.messages.push(memberBlock)
    } else {
      groupItem.messages[groupItem.messages.length - 1].messages.push(messageData)
    }

    lastMessageId = item.id

    return results
  }, [])

  if (groupItem) {
    groupItem.lastId = lastMessageId
    results.push(groupItem)
  }

  return results
}

function performChannel (channel) {
  channel.updatedAt = DateTime.fromISO(channel.updated_at, { zone: 'UTC' }).setZone('local')

  return channel
}

export const namespaced = true

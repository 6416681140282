<template>
  <div class="sprint-grid">
    <div class="sprint-grid__header">
      <div class="sprint-grid__header-content">
        <div class="sprint-grid__title">
          <div class="sprint-grid__title-content">
            {{ $t('layout.sprints') }}

            <ui-dropdown
              :options="sprints"
              :value="currentSprint"
              :multiple="false"
              reference-class="selected-sprint"
              popover-class="ui-dropdown_no-border"
              :width-by-popover="true"
              @input="onSprintChange"
            >
              <template v-slot:default>
                <div class="task-spheres__selected">
                  {{ currentSprint.name || $t('sprints.noSprintsShort') }}
                  <chevron-down background="#2F3750" class="task-spheres__chevron-icon"/>
                </div>
              </template>

              <template v-slot:option="{ option }">
                <div class="dropdown-sphere-item">
                  {{ option.name }}
                </div>
              </template>
            </ui-dropdown>
          </div>
        </div>

        <div class="sprint-date" v-if="currentSprint.id">
          <sprints-icon class="sprint-date__icon"/>
          {{ sprintDateLabel }}
        </div>
      </div>
    </div>

    <div class="sprint-stages">
      <sprint-detail :sprint="sprint" v-if="sprint"/>
      <div v-if="!currentSprint.id" class="sprints-empty">
        <empty-sprint class="sprints-empty__illu"/>
        <div class="sprints-empty__content">
          <div class="sprints-empty__title">
            {{ $t('sprints.noSprints') }}
          </div>
          <p>{{ $t('sprints.noSprintsText') }}</p>
          <p>
            <ui-button ui-class="light" @click="goToTasks">
              {{ $t('sprints.goToTasks') }}
            </ui-button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'

import ChevronDown from "@/components/icons/ChevronDown"
import SprintsIcon from "@/components/icons/SprintsIcon"
import UiDropdown from "@/components/tasks/UiDropdown"
import SprintDetail from "@/components/sprints/SprintDetail"

import { transformDateToObject } from '@/services/scoring'
import pageTitleMixin from "@/views/mixins/pageTitleMixin"
import EmptySprint from "@/components/illustrations/EmptySprint"
import UiButton from 'scorework-ui/src/components/UiButton'

const { mapActions: sprintsActions, mapGetters: sprintsGetters } = createNamespacedHelpers('sprints')

export default {
  name: 'SprintsPage',

  components: {EmptySprint, ChevronDown, SprintDetail, SprintsIcon, UiButton, UiDropdown },

  mixins: [pageTitleMixin],

  props: {
    sprintId: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      isLoaded: false,
      currentStage: {},
      currentStageId: null,
      currentSprint: {}
    }
  },

  computed: {
    ...sprintsGetters(['sprint', 'sprintMembers', 'sprints']),
    ...mapGetters(['members', 'stages', 'workspace', 'project']),

    pageTitle () {
      return {
        message: 'pageTitles.sprints', params: { workspace: this.workspace.name }
      }
    },

    memberTasks () {
      if (!this.sprint.stat) {
        return []
      }

      return this.members.reduce((res, member) => {
        const item = {
          member,
          stats: {
            finished_task_count: 0,
            total_task_count: 0,
            total_time: 0,
            working_time: 0
          }
        }

        const stats = this.sprint.stat.members.find(item => item.assignee.id === member.member_id)
        if (stats) {
          item.stats = stats
        }

        res.push(item)
        return res
      }, [])
    },

    sprintDateLabel () {
      const startedAt = transformDateToObject(this.sprint.data.started_at)
      const finishedAt = transformDateToObject(this.sprint.data.finished_at)

      return `${startedAt.toFormat('dd LLLL')} - ${finishedAt.toFormat('dd LLLL')}`
    }
  },

  methods: {
    ...sprintsActions(['fetchSprint', 'fetchSprints']),

    onSprintChange (sprint) {
      this.loadSprint(sprint.id)
        .then(() => {
          this.$router.push({ name: 'sprints.show', params: { sprintId: sprint.id } })
        })
    },

    async loadSprint (id) {
      try {
        await this.fetchSprint({ id })
        this.currentSprint = this.sprint.data
      } catch (err) {
        if (err.response && err.response !== 404) {
          // todo
        }
      }
    },

    goToTasks () {
      this.$router.push({ name: 'project.tasks', params: { project: this.project.code } })
    }
  },

  created () {
    this.isLoaded = false
    Promise.all([
        this.loadSprint(this.sprintId),
        this.fetchSprints()
    ]).then(() => {
      this.isLoaded = true
    })
  }
}
</script>
<style lang="sass">
.sprints-empty
  display: flex
  width: 100%
  font-size: 16px
  color: var(--text-light-color)
  padding-top: 32px
  column-gap: 20px

  &__illu
    margin-bottom: 24px
    height: 180px
    width: auto

  &__content
    padding-top: 12px

    p
      margin: 0
      padding: 0 0 16px

  &__title
    font-size: 18px
    font-weight: 500
    color: var(--text-primary-color)
    margin-bottom: 16px
</style>

<template>
  <div
    ref="side"
    class="page-view__side"
    @mousemove="onMouseMove"
    @mouseleave="onMouseLeave"
  >
    <editor-popover :editor="editor" :tippy-options="{ placement: 'left-start', zIndex: 1000 }" :append-to-parent="true" @created="onPopoverCreated">
      <div class="" id="editor-side-menu">
        <ui-button
            ui-class="size_sm transparent"
            class="btn-icon"
            @click="onAddNode"
        >
          <plus/>
        </ui-button>
      </div>
    </editor-popover>
  </div>
</template>
<script>
  import UiButton from 'scorework-ui/src/components/UiButton'
  import EditorPopover from "@/components/ui/textEditor/popovers/EditorPopover"
  import Plus from '@/components/icons/Plus'
  import {floatingMenuPluginKey} from '@/components/ui/textEditor/floatingMenu/plugin';
  import {defineNodeByCursor} from '@/components/ui/textEditor/helpers';
  import {EventBus} from '@/services/eventBus';

  export default {
    name: 'EditorSideMenu',

    components: {UiButton, EditorPopover, Plus },

    props: {
      editor: {
        type: Object,
        required: true,
      }
    },

    data () {
      return {
        popover: null,
        currentPos: null,
      }
    },

    methods: {
      onMouseMove (e) {
        const { view } = this.editor
        //console.log(e)
        const { x, width } = e.target.getBoundingClientRect()
        const posAtCoords = view.posAtCoords({ left: x + width + 15, top: e.clientY })

        //console.log('pos at coords', posAtCoords)

        if (!posAtCoords) {
          return
        }

        const res = defineNodeByCursor(view, posAtCoords, this.currentPos)
        this.redrawPopover(res)
      },

      redrawPopover({ changed, pos, node }) {
        if (!changed) {
          return
        }

        if (node === null) {
          this.currentPos = null
          this.popover.hide()
          return
        }

        this.currentPos = pos
        this.popover.show(node)
      },

      onMouseLeave (e) {
        if (!e.toElement.closest('#editor-side-menu')) {
          this.popover.hide()
        }
      },

      onAddNode () {
        const { view } = this.editor

        // const oldState = floatingMenuPluginKey.getState(view.state)
        // view.dispatch(view.state.tr.setMeta(floatingMenuPluginKey, {
        //   ...oldState,
        //   forceShow: !oldState.forceShow,
        // }))
        //console.log(this.currentPos, view.docView.node.nodeAt)

        console.log('view_size', view.docView.size, 'currentPos', this.currentPos)
        const currentNode = view.docView.node.nodeAt(this.currentPos === 1 ? 0 : this.currentPos)
        //console.log('currentNode', currentNode, currentNode.textContent)
        const pos = this.currentPos + (currentNode?.nodeSize || 0) // это позиция окончания содержимого текущего тега

        console.log('view', view, 'currentNode', currentNode, 'origin_pos', this.currentPos, 'pos', pos)


        //console.log(view.docView.node.nodeSize, pos)

        let chain = this.editor.chain()
        let focusPos = pos

        if (pos > this.currentPos) {
          const nodePos = view.docView.node.nodeSize - 1 <= pos
            ? view.docView.node.nodeSize - 2
            : pos - 1

          //console.log('nodePos', nodePos, 'pos', pos)

          chain = chain.insertContentAt(nodePos, {type: "paragraph"})
          focusPos++
        }

        chain
          .focus(focusPos)
          .command(() => {
            const oldState = floatingMenuPluginKey.getState(view.state)
            view.dispatch(view.state.tr.setMeta(floatingMenuPluginKey, {
              ...oldState,
              forceShow: !oldState.forceShow,
            }))
            return true
          })
          .run()
      },

      onPopoverCreated (popover) {
        this.popover = popover
      }
    },

    mounted () {
      EventBus.$on('editorNodeActive', (res) => {
        this.redrawPopover(res)
      })
    }
  }
</script>

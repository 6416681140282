export const StatusBacklog = 'backlog'
export const StatusOpen = 'open'
export const StatusPaused = 'paused'
export const StatusInProgress = 'in_progress'
export const StatusFinished = 'finished'

export const ProgressStatusOverdue = 'overdue'

export const TimeStatusEstimation = 'estimation_time'
export const TimeStatusFinished = 'finished_time'
export const TimeStatusOverdue = 'overdue_time'
export const TimeStatusProcessing = 'processing_time'

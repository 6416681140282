import UiLoadingButton from '@/components/ui/UiLoadingButton'

export default {
  components: { UiLoadingButton },

  props: {
    isProcessing: {
      type: Boolean,
      default: false
    }
  }
}

<template>
  <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.4 6.546V9.8184H4.2C5.1936 9.8184 6 9.0864 6 8.1816C6 7.278 5.1936 6.546 4.2 6.546H2.4ZM6.6168 5.058C7.6956 5.7516 8.4 6.8928 8.4 8.1828C8.4 10.29 6.5196 12 4.2 12H0V1.092C0 0.4872 0.5376 0 1.2 0H3.6C5.5884 0 7.2 1.4652 7.2 3.2724C7.2 3.9324 6.9852 4.5444 6.6168 5.0592V5.058ZM2.4 2.1828V4.3644H3.6C4.2624 4.3644 4.8 3.876 4.8 3.2736C4.8 2.6712 4.2624 2.1828 3.6 2.1828H2.4V2.1828Z" fill="#333840"/>
  </svg>
</template>
<script>
import iconMixin from '../iconMixin'

export default {
  name: 'BoldIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

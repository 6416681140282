<template>
  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.7276 9.6L5.0676 2.4H3.6C3.28174 2.4 2.97652 2.27357 2.75147 2.04853C2.52643 1.82348 2.4 1.51826 2.4 1.2C2.4 0.88174 2.52643 0.576516 2.75147 0.351472C2.97652 0.126428 3.28174 0 3.6 0H9.6C9.91826 0 10.2235 0.126428 10.4485 0.351472C10.6736 0.576516 10.8 0.88174 10.8 1.2C10.8 1.51826 10.6736 1.82348 10.4485 2.04853C10.2235 2.27357 9.91826 2.4 9.6 2.4H7.5912L5.2512 9.6H7.2C7.51826 9.6 7.82348 9.72643 8.04853 9.95147C8.27357 10.1765 8.4 10.4817 8.4 10.8C8.4 11.1183 8.27357 11.4235 8.04853 11.6485C7.82348 11.8736 7.51826 12 7.2 12H1.2C0.88174 12 0.576516 11.8736 0.351472 11.6485C0.126428 11.4235 0 11.1183 0 10.8C0 10.4817 0.126428 10.1765 0.351472 9.95147C0.576516 9.72643 0.88174 9.6 1.2 9.6H2.7276Z" fill="#333840"/>
  </svg>
</template>
<script>
import iconMixin from '../iconMixin'

export default {
  name: 'ItalicIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

<template>
  <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-stroke" fill-rule="evenodd" clip-rule="evenodd" d="M19.3058 11.002H2.75997C2.10726 11.002 1.57812 11.5392 1.57812 12.202V15.802C1.57812 16.4647 2.10726 17.002 2.75997 17.002H19.3058C19.9585 17.002 20.4876 16.4647 20.4876 15.802V12.202C20.4876 11.5392 19.9585 11.002 19.3058 11.002Z" stroke="white" stroke-width="1.4"/>
    <path class="path-stroke" fill-rule="evenodd" clip-rule="evenodd" d="M19.3058 1.00195H2.75997C2.10726 1.00195 1.57812 1.53921 1.57812 2.20195V5.80195C1.57812 6.4647 2.10726 7.00195 2.75997 7.00195H19.3058C19.9585 7.00195 20.4877 6.4647 20.4877 5.80195V2.20195C20.4877 1.53921 19.9585 1.00195 19.3058 1.00195Z" stroke="white" stroke-width="1.4"/>
  </svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'TasksIcon',

  mixins: [iconMixin],

  props: {
    viewBox: {
      type: String,
      default: '0 0 12 12'
    }
  },

  data () {
    return {
      defaultBackground: '#416AF7',
      defaultWidth: 12,
      defaultHeight: 12
    }
  }
}
</script>

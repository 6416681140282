<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.0563 8L5 12.2338L5 3.76619L12.0563 8Z" stroke-linejoin="round" stroke="#27272E" stroke-width="2" class="path-stroke"/>
</svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'PlayOutlineIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

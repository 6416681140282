<template>
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>
<script>
  export default {
    name: 'UiSpinner'
  }
</script>
<style lang="sass">
.lds-ellipsis
  display: inline-block
  position: relative
  width: 40px
  height: 20px

  div
    position: absolute
    top: 9px
    width: 7px
    height: 7px
    border-radius: 50%
    background: var(--text-light-color)
    animation-timing-function: cubic-bezier(0, 1, 1, 0)

    &:nth-child(1)
      left: 4px
      animation: lds-ellipsis1 0.6s infinite

    &:nth-child(2)
      left: 4px
      animation: lds-ellipsis2 0.6s infinite

    &:nth-child(3)
      left: 16px
      animation: lds-ellipsis2 0.6s infinite

    &:nth-child(4)
      left: 28px
      animation: lds-ellipsis3 0.6s infinite

@keyframes lds-ellipsis1
  0%
    transform: scale(0)

  100%
    transform: scale(1)

@keyframes lds-ellipsis3
  0%
    transform: scale(1)

  100%
    transform: scale(0)

@keyframes lds-ellipsis2
  0%
    transform: translate(0, 0)

  100%
    transform: translate(12px, 0)
</style>

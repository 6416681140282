import { nullOrNumber } from '@/utils/dataTypes'
import ModalTaskCard from '@/components/tasks/ModalTaskCard'

const mapFields = {
  id: 'task_id',
  memberId: 'member_id',
  stageId: 'stage_id',
  afterTaskId: 'after_task_id',
  sphereId: 'sphere_id',
  targetId: 'target_id',
}

export default {
  components: { ModalTaskCard },

  data () {
    return {
      modalTask: {
        id: null,
        memberId: null,
        stageId: null,
        afterTaskId: null,
        sphereId: null,
        targetId: null,
      }
    }
  },

  methods: {
    changeRoutePath () {
      if (this.modalTask.id === null) {
        this.$router.push({ hash: '', query: {} })
        return
      }

      const query = Object.keys(this.modalTask).reduce((query, field) => {
        if (this.modalTask[field] === null) {
          return query
        }

        const queryField = mapFields[field]
        query[queryField] = this.modalTask[field]
        return query
      }, {})

      this.$router.push({ query })
    },

    onCloseTask () {
      this.modalTask.id = null
      this.$router.push({ query: {} })
    },

    onOpenTask (id) {
      this.modalTask = {
        ...this.modalTask,
        id,
        stageId: null,
        memberId: null,
        afterTaskId: null,
        sphereId: null,
        targetId: null,
      }
      this.changeRoutePath()
    },
  },

  mounted () {
    const {
      task_id = null,
      member_id = null,
      stage_id = null,
      after_task_id = null,
      sphere_id = null,
      target_id = null,
    } = this.$router.currentRoute.query

    this.modalTask = {
      ...this.modalTask,
      id: nullOrNumber(task_id),
      memberId: nullOrNumber(member_id),
      stageId: nullOrNumber(stage_id),
      afterTaskId: nullOrNumber(after_task_id),
      targetId: nullOrNumber(target_id),
      sphereId: nullOrNumber(sphere_id),
    }
  }
}

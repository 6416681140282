<template>
  <ui-form :model="form" :rules="rules" :submit-fn="submitFn" ref="form">
    <div class="workspace-form">
      <div class="workspace-form__data">
        <div class="form-group">
          <div class="form-control">
            <text-field
              prop="name"
              :value="form.name"
              :label="$t('projects.form.name')"
              class="ui-text-field"
              :focused="true"
              @input="onNameChange"
              @focus="e => onFocusInput('name', e)"
            />
          </div>

          <div class="form-control" v-if="withPrefix">
            <text-field
              prop="task_prefix"
              v-model="form.task_prefix"
              :label="$t('settings.general.taskPrefix')"
              :max-length="4"
              class="ui-text-field"
            />
          </div>
        </div>

        <div class="form-group">
          <div class="workspace-form__sub-title">
            {{ $t('settings.general.workSchedule') }}
          </div>

          <div class="form-control">
            <work-schedule-selector v-model="form.work_schedule[0]" @focus="e => onFocusInput('schedule', e)"/>
          </div>

          <div class="holidays-switcher">
            <ui-switch :value="form.holidays" @input="onHolidaysChange"/>
            {{ $t('settings.general.considerHolidays') }}
          </div>
        </div>

        <div class="form-control" v-if="form.holidays && countriesLoaded">
          <form-dropdown
              :options="countryList"
              :value="form.country_id"
              :multiple="false"
              prop="country_id"
              popover-class="ui-dropdown_no-border"
              :label="$t('settings.general.country')"
              @input="onCountryChange"
          >
            <template v-slot:default>
              <div class="form-dropdown__selected">
                {{ selectedCountry }}
                <chevron-down class="form-dropdown__chevron"/>
              </div>
            </template>

            <template v-slot:option="{ option }">
              <div class="dropdown-sphere-item">
                {{ getCountryName(option.alfa2) }}
              </div>
            </template>
          </form-dropdown>
        </div>

        <div class="workspace-form__sub-title">
          {{ $t('layout.sprints') }}
        </div>

        <div class="form-group">
          <sprint-duration-dropdown :value="form.weeks_sprint_duration" @input="onSprintDurationChange"></sprint-duration-dropdown>
        </div>

        <div class="workspace-form__submit">
          <ui-loading-button @click.prevent="onSubmit" ui-class="block" :loading="formProcessing">
            {{ $t(submitTitle) }}
          </ui-loading-button>
        </div>
      </div>

      <div class="workspace-form__photo" v-if="withLogo">
        <logo-uploader :model="project" @change="onLogoChanged"/>
      </div>
    </div>
  </ui-form>
</template>
<script>
  import {mapActions, mapGetters} from "vuex"
  import { required } from "vuelidate/lib/validators"

  import UiForm from 'scorework-ui/src/components/form/UiForm'
  import TextField from 'scorework-ui/src/components/TextField'

  import UiSwitch from "@/components/ui/UiSwitch"
  import ChevronDown from "@/components/icons/ChevronDown"
  import FormDropdown from "@/components/ui/FormDropdown"
  import SprintDurationDropdown from "@/components/sprints/SprintDurationDropdown"
  import LogoUploader from "@/components/settings/LogoUploader"
  import UiLoadingButton from "@/components/ui/UiLoadingButton"
  import WorkScheduleSelector from "@/components/ui/WorkScheduleSelector"
  import {handleAsyncErrors} from "scorework-ui/src/components/form/helpers";

  export default {
    name: 'SettingsForm',

    components: {
      UiLoadingButton,
      WorkScheduleSelector, LogoUploader, SprintDurationDropdown,
      FormDropdown, ChevronDown, UiSwitch, UiForm, TextField,
    },

    props: {
      withPrefix: {
        type: Boolean,
        default: true,
      },

      submitTitle: {
        type: String,
        default: 'common.save'
      },

      project: {
        type: Object,
        default: null,
      },

      withLogo: {
        type: Boolean,
        default: true
      }
    },

    data () {
      return {
        form: {
          name: '',
          task_prefix: '',
          work_schedule: [
            {
              days: [1, 2, 3, 4, 5],
              work_hours: {
                start: '10:00',
                end: '19:00'
              }
            }
          ],
          holidays: false,
          country_id: null,
          weeks_sprint_duration: 2,
        },

        formProcessing: false,

        rules: {
          name: {
            rules: { required },
            messages: {
              required: this.$t('settings.general.errors.name.required')
            }
          },

          country_id: {
            rules: { },
            messages: {
              required: this.$t('settings.general.errors.countryId.required')
            }
          },
        },
        countriesLoaded: false,
        currencies: [
          { id: 'RUB' },
          { id: 'USD' },
          { id: 'EUR' },
          { id: 'GBP' },
        ]
      }
    },

    computed: {
      ...mapGetters(['countries']),

      selectedCountry () {
        const country = this.countryList.find(item => item.id === this.form.country_id)

        return this.getCountryName(country.alfa2)
      },

      countryList () {
        return [
          { id: null, alfa2: '' },
          ...this.countries
        ]
      }
    },

    methods: {
      ...mapActions(['fetchCountries', 'createProject', 'updateProject', 'updateProjectLogo']),

      generatePrefix (name) {
        const nameParts = name.split(' ').filter(w => w)

        if (nameParts.length === 1) {
          return nameParts[0].substring(0, nameParts[0].length > 3 ? 4 : nameParts[0].length).toLowerCase()
        }

        return nameParts.slice(0, 3).map(word => word.substring(0, 1)).join('').toLowerCase()
      },

      onNameChange (val) {
        this.form.name = val

        if (!this.withPrefix) {
          this.form.task_prefix = this.generatePrefix(val)
          //this.refreshWorkspace({ task_prefix: this.form.task_prefix })
        }
      },

      onFocusInput (name) {
        this.$emit('focus-field', name)
      },

      onCountryChange (country) {
        this.form.country_id = country.id
      },

      onSprintDurationChange (sprintDurationWeeks) {
        this.form.weeks_sprint_duration = sprintDurationWeeks.value
      },

      getCountryName (code) {
        if (!code) {
          return this.$t(`countries.notSelected`)
        }

        return this.$t(`countries.${code}`)
      },

      onHolidaysChange (val) {
        this.form.holidays = val

        if (!val) {
          this.form.country_id = null
          delete this.rules.country_id.rules.required
        } else {
          this.rules.country_id.rules = {
            ...this.rules.country_id.rules,
            required
          }

          const countryCode = this.$i18n.locale === 'ru' ? 'RU' : 'US'
          const country = this.countries.find(item => item.alfa2 === countryCode)
          if (country) {
            this.form.country_id = country.id
          }
        }
      },

      submitFn () {
        this.formProcessing = true
        if (this.project) {
          this.updateProject({ code: this.project.code, data: this.form })
            .catch(handleAsyncErrors(this.rules, () => this.$refs.form.validate()))
            .finally(() => {
              this.formProcessing = false
              this.$emit('submit')
            })
        } else {
          this.createProject(this.form)
            .catch(handleAsyncErrors(this.rules, () => this.$refs.form.validate()))
            .finally(() => {
              this.formProcessing = false
              this.$emit('submit')
            })
        }
      },

      onSubmit () {
        this.$refs.form.submit()
      },

      async onLogoChanged (file) {
        const formData = new FormData()
        formData.append('image', file)

        try {
          await this.updateProjectLogo(formData)
          this.$notify({
            title: this.$t('common.changesSaved'),
            message: this.$t('settings.project.notifications.logo'),
            position: 'bottom-right',
            type: 'success'
          })
        } catch (e) {
          this.$notify({
            title: this.$t('common.error'),
            message: this.$t('settings.members.notifications.failed'),
            position: 'bottom-right',
            type: 'error'
          })
        }
      }
    },

    created() {
      this.fetchCountries()
        .finally(() => {
          this.countriesLoaded = true
        })

      if (!this.project) {
        return
      }

      const { name, task_prefix, holidays, work_schedule, country_id, weeks_sprint_duration } = this.project
      this.form = {
        ...this.form,
        name, task_prefix, holidays, work_schedule, country_id, weeks_sprint_duration
      }
    }
  }
</script>

<template>
  <div class="stage-sphere-item" :class="itemClasses">
    <div class="stage-sphere-item__head">
      <div class="stage-sphere-item__head-content">
        <div class="stage-sphere-item__dot"></div>

        <div class="stage-sphere-item__title">
          {{ stageSphere.name }}
        </div>
      </div>

      <div class="stage-sphere-item__controls">
        <el-tooltip class="item" effect="dark" :content="$t('stages.addStage')" placement="right">
          <ui-button ui-class="light size_md icon" @click="onAddStage">
            <bold-plus-icon class="stage-sphere-item__plus-btn"/>
          </ui-button>
        </el-tooltip>
      </div>
    </div>

    <div class="stage-sphere-item__list" v-draggable-list="{ name: 'stages-' + stageSphere.sphereId, callbacks }">
      <stage-item
        v-for="stage in stageSphere.stages"
        :key="stage.id"
        :stage="stage"
        :sphere-id="stageSphere.sphereId"
        @update="onUpdateStage"
        @delete="onDeleteStage"
      />
    </div>
  </div>
</template>
<script>
import UiButton from 'scorework-ui/src/components/UiButton'

import BoldPlusIcon from "@/components/icons/BoldPlusIcon"
import StageItem from "./StageItem"

import {createNamespacedHelpers} from "vuex"

const { mapActions: stagesActions } = createNamespacedHelpers('stages')
const stageStyles = [
    'grey', 'blue', 'yellow', 'purple', 'fuchsia', 'salmon', 'red'
]

export default {
  name: 'StageSphereItem',

  props: {
    stageSphere: {
      type: Object,
      required: true
    },

    initStage: {
      type: Object,
      required: true
    },

    number: {
      type: Number,
      default: 0
    }
  },

  components: { StageItem, BoldPlusIcon, UiButton },

  data () {
    return {
      callbacks: {
        onDragEnd: this.onDragFinish
      }
    }
  },

  computed: {
    itemClasses () {
      return [`stage-sphere-item_${stageStyles[this.number]}`]
    }
  },

  methods: {
    ...stagesActions(['addStage', 'reSortStages']),

    onAddStage () {
      const stageLength  = this.stageSphere.stages.length

      const previousStageId = stageLength
        ? this.stageSphere.stages[stageLength - 1].id
        : this.initStage.id

      this.$emit('create', { sphere_id: this.stageSphere.sphereId, previous_stage_id: previousStageId })
    },

    onUpdateStage (stage) {
      this.$emit('update', stage)
    },

    onDeleteStage (stage) {
      this.$emit('delete', stage)
    },

    onDragFinish (listEl, source, target, position) {
      this.reSortStages({ source, targetId: target.id, position })
    }
  }
}
</script>
<style lang="sass">
.stage-sphere-item
  padding: 24px 12px
  box-shadow: var(--block-light-shadow)
  border-radius: 16px
  background: var(--block-bg-color2)
  box-sizing: border-box

  &_blue
    .stage-sphere-item__dot
      background: var(--color-blue-dots)

    .stage-item
      background: var(--color-blue-bg)
      &:hover
        background: var(--color-blue-hover)

  &_yellow
    .stage-sphere-item__dot
      background: var(--color-yellow-dots)

    .stage-item
      background: var(--color-yellow-bg)
      &:hover
        background: var(--color-yellow-hover)

  &_purple
    .stage-sphere-item__dot
      background: var(--color-lavender-dots)

    .stage-item
      background: var(--color-lavender-bg)
      &:hover
        background: var(--color-lavender-hover)

  &_fuchsia
    .stage-sphere-item__dot
      background: var(--color-purple-dots)

    .stage-item
      background: var(--color-purple-bg)
      &:hover
        background: var(--color-purple-hover)

  &_salmon
    .stage-sphere-item__dot
      background: var(--color-fuchsia-dots)

    .stage-item
      background: var(--color-fuchsia-bg)
      &:hover
        background: var(--color-fuchsia-hover)

  &_red
    .stage-sphere-item__dot
      background: var(--color-red-dots)

    .stage-item
      background: var(--color-red-bg)
      &:hover
        background: var(--color-red-hover)

  &__head
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 16px

  &__head-content
    display: flex
    align-items: center

  &__dot
    width: 8px
    height: 8px
    border-radius: 4px
    margin: 0 12px
    background: var(--color-grey-dots)

  &__title
    font-size: 16px
    color: var(--text-primary-color)
    font-weight: 500

  &__plus-btn
    .path-fill
      fill: var(--primary-color)

  &__list
    .stage-item:last-child
      margin-bottom: 0

  .ui-button
    border-radius: 12px
</style>

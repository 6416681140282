<template>
  <el-drawer
    :title="$t('ui.gallery.title')"
    custom-class="drawer-gallery"
    :append-to-body="true"
    size="100%"
    :visible.sync="show"
    direction="ttb"
    @closed="onClosed"
  >
    <div class="overlay-gallery">
      <div class="overlay-gallery__control" v-if="list.length" @click="showPrev">
        <div class="overlay-gallery__control-icon">
          <ChevronLeft/>
        </div>
      </div>
      <div class="overlay-gallery__main">
        <img :src="currentPictureUrl"/>
        <div class="overlay-gallery__title" v-if="currentPicture">
          {{ currentPicture.name }}
        </div>
      </div>
      <div class="overlay-gallery__control" v-if="list.length" @click="showNext">
        <div class="overlay-gallery__control-icon overlay-gallery__control-icon_next">
          <ChevronLeft/>
        </div>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import ChevronLeft from '@/components/icons/ChevronLeft'

export default {
  name: 'OverlayGallery',

  components: { ChevronLeft },

  props: {
    src: {
      type: String,
    },

    list: {
      type: Array,
      default: () => []
    },

    activePic: {
      type: String,
    }
  },

  data () {
    return {
      show: true,
      currentUuid: this.activePic,
    }
  },

  computed: {
    currentPictureUrl () {
      if (this.list.length === 0) {
        return this.src
      }

      const image = this.currentPicture
      if (!image) {
        return ''
      }

      return image.big_url
    },

    currentPicture () {
      return this.list.find(img => img.uuid === this.currentUuid)
    }
  },

  watch: {
    activePic (val) {
      if (this.list.length === 0) {
        return
      }

      this.currentUuid = val
    }
  },

  methods: {
    onClosed () {
      this.$emit('close')
    },

    showPrev () {
      const currentIdx = this.list.findIndex(img => img.uuid === this.currentUuid)
      let newIdx = currentIdx - 1
      if (newIdx < 0) {
        newIdx = this.list.length - 1
      }

      this.currentUuid = this.list[newIdx].uuid
    },

    showNext () {
      const currentIdx = this.list.findIndex(img => img.uuid === this.currentUuid)
      let newIdx = currentIdx + 1
      if (newIdx >= this.list.length) {
        newIdx = 0
      }

      this.currentUuid = this.list[newIdx].uuid
    }
  }
}
</script>
<style lang="sass">
.el-drawer.drawer-gallery
  background: #000000

.overlay-gallery
  display: flex
  height: 100%

  &__control
    height: 100%
    width: 48px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    transition: all .2s ease-in

    &:hover
      background: var(--light-grey-color-24)

  &__control-icon
    &_next
      svg
        transform: rotate(180deg)

  &__main
    flex: 1
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    flex-flow: column

    img
      max-width: 80%
      max-height: 80%

  &__title
    margin-top: 24px
    display: flex
    align-items: center
    justify-content: center
    color: #ffffff
</style>

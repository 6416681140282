<template>
<div class="sprint-member">
  <div class="sprint-member__head">
    <div class="sprint-member__title">
      <user-photo :user="sprintMember.member" size="md"/>

      <div class="sprint-member__info">
        <div class="sprint-member__name">
          {{ memberName }}
        </div>

        <div class="sprint-member__spheres" v-if="isMember && sprintMember.member.project.spheres.length > 0">
          <sphere-badge
            v-for="sphere in sprintMember.member.project.spheres"
            :key="sphere.id"
            :sphere="sphere"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="sprint-member__stats">
    <div class="sprint-member__task-progress">
      <div class="sprint-member__task-progress-bar">
        <div class="sprint-member__task-progress-fill" :style="fillStyles">
        </div>
      </div>

      <div class="sprint-member__task-progress-label">
        {{ sprintMember.tasks_finished_count}} {{ $t('goals.outOf') }} {{ sprintMember.tasks_planned_count }}
      </div>
    </div>

    <div class="sprint-member__time-progress" v-if="false">
      <div class="sprint-member__progress-bar">
        <el-tooltip
            effect="dark"
            :content="$t('sprints.completedByTasks') + ': ' + formattedWorkingTime"
            placement="top"
            transition="sw-slide-top"
            :hide-after="0"
            :open-delay="100"
        >
          <div class="sprint-member__progress-fill sprint-member__progress-fill_green" :style="workingTimeFillStyles"></div>
        </el-tooltip>
        <!--
        <el-tooltip
            effect="dark"
            content="Неучтенное время: 2ч. 30мин."
            placement="top"
            transition="sw-slide-top"
            :hide-after="0"
            :open-delay="100"
        >
          <div class="sprint-member__progress-fill sprint-member__progress-fill_grey" style=" left: 65%; width: 35%;"></div>
        </el-tooltip>-->
      </div>

      <div class="sprint-member__time-progress-label">
        <clock-icon class="sprint-member__task-progress-icon"/>
      </div>
    </div>

  </div>
</div>
</template>
<script>
import ClockIcon from "@/components/icons/ClockIcon"
import UserPhoto from "@/components/ui/UserPhoto"
import SphereBadge from "@/components/ui/SphereBadge"
import { getScoreObject, getScoreLabel } from '@/services/scoring'

export default {
  name: 'SprintMember',

  components: { ClockIcon, SphereBadge, UserPhoto },

  props: {
    sprintMember: {
      type: Object,
      required: true
    }
  },

  computed: {
    percent () {
      return Math.round(this.sprintMember.tasks_finished_count / this.sprintMember.tasks_planned_count * 100)
    },

    fillStyles () {
      return {
        width: this.percent + '%'
      }
    },

    workingTimePercent () {
      return Math.round(this.sprintMember.working_time / this.sprintMember.total_time * 100)
    },

    workingTimeFillStyles () {
      return {
        width: this.workingTimePercent + '%'
      }
    },

    formattedWorkingTime () {
      const score = getScoreObject(this.sprintMember.working_time)

      return getScoreLabel(score)
    },

    memberName () {
      if (!this.isMember) {
        return this.$t('tasks.noAssignee')
      }

      return `${this.sprintMember.member.first_name} ${this.sprintMember.member.last_name}`
    },

    isMember () {
      return this.sprintMember.member.member_id !== null
    }
  }
}
</script>
<style lang="sass">

.sprint-member
  background: var(--block-bg-color)
  border-radius: 16px
  box-sizing: border-box
  padding: 16px 20px

  &__stats
    margin-top: 12px

  &__block
    background: var(--block-bg-color)
    border-radius: 16px
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)
    padding: 16px
    box-sizing: border-box
    width: 340px

  &__head
    display: flex
    justify-content: space-between

  &__badges
    margin-top: 8px

  &__title
    display: flex
    align-items: center
    margin-right: 20px

  &__info
    margin-left: 12px

  &__name
    font-weight: 500

  &__spheres
    align-items: center
    display: flex
    margin-top: 4px

    .sphere-badge
      margin-right: 4px

      &:last-child
        margin-right: 0

  &__circle
    position: relative
    width: 350px

  &__task-progress
    display: flex
    align-items: center
    margin-bottom: 12px

  &__task-progress-label
    color: var(--primary-color)
    margin-left: 12px
    font-size: 14px
    font-weight: 500

  &__task-progress-icon
    .path-stroke
      stroke: var(--text-light-color)

  &__task-progress-bar
    flex: 1
    position: relative
    height: 6px
    border-radius: 3px
    background: var(--primary-light-color)

  &__task-progress-fill
    position: absolute
    border-radius: 3px
    height: 6px
    top: 0
    left: 0
    transition: all .1s ease-in
    background: var(--primary-color)

  &__tasks-count
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    display: flex
    align-items: center
    justify-content: center
    color: var(--primary-color)
    font-weight: 500

  &__time-progress
    display: flex
    align-items: center

  &__time-progress-label
    margin-left: 12px

  &__progress-bar
    flex: 1
    position: relative
    height: 4px
    border-radius: 2px
    background: var(--success-light-color)

  &__progress
    margin: 12px 0

  &__progress-fill
    position: absolute
    border-radius: 2px
    height: 4px
    top: 0
    left: 0
    transition: all .1s ease-in

    &:hover
      height: 8px
      top: -2px
      border-radius: 4px
      box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)

    &_green
      z-index: 100
      background: var(--success-color)
      border-radius: 2px 0 0 2px

      &:hover
        border-radius: 4px 0 0 4px

    &_light-green
      z-index: 90
      background: var(--success-light-color)

    &_grey
      z-index: 90
      background: var(--primary-color)
      border-radius: 0 2px 2px 0

      &:hover
        border-radius: 0 4px 4px 0

  &__progress-label
    margin-bottom: 4px
    font-size: 13px
</style>

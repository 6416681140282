<template>
  <div class="pages-menu-btn page-menu-item__icon_arrow" :class="uiClasses" @click="onClick">
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: 'PagesMenuBtn',

    props: {
      uiClass: {
        type: String,
        default: '',
      }
    },

    computed: {
      uiClasses () {
        if (!this.uiClass) {
          return []
        }

        return this.uiClass.split(' ').map((modifier) => `pages-menu-btn_${modifier}`)
      }
    },

    methods: {
      onClick (e) {
        this.$emit('click', e)
      }
    }
  }
</script>
<style lang="sass">
.pages-menu-btn
  align-items: center
  display: flex
  justify-content: center
  width: 22px
  height: 22px
  border-radius: 4px
  cursor: pointer
  transition: all .2s ease-in

  svg
    transition: all .2s ease-in

  &:hover
    background: var(--grey-light-color-60)

  &_arrow
    &_extended
      svg
        transform: rotate(90deg)

  &_plus
    display: none

    svg
      width: 10px
      height: 10px
</style>

<template>
  <div class="setup-example-layout">
    <div class="setup-example__tasks">
      <target-item
          :target="target"
          v-if="activeHint === 'targets'"
      />
    </div>

    <div class="setup-example__hints">
      <transition name="slide-left2" appear>
        <div class="setup-example-hint" :key="activeHint">
          <div class="setup-example-hint__title">
            {{ currentHint.title }}
          </div>

          <div class="setup-example-hint__content" v-html="currentHint.content"></div>
        </div>
      </transition>
    </div>

    <div class="setup-example__hint-circles" v-if="hints.length > 1">
      <div class="hint-circles">
        <div
            v-for="hint in hints"
            :key="hint.name"
            class="hint-circle"
            :class="{'hint-circle_active': hint.name === activeHint}"
            @click="onChangeHint(hint.name)"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import TargetItem from "@/components/targets/TargetItem"
import { transformTargetItem } from "@/transformers/targets"

export default {
  name: 'MembersExample',

  components: { TargetItem },

  props: {
    activeHint: {
      type: String,
      required: true
    }
  },

  data () {
    const target = {
      id: 1,
      name: this.$t('setup.samples.targets.name1'),
      color: 'fuchsia',
      finished_tasks: 2,
      total_tasks: 7,
      status: 'active',
      target: null,
      estimated_finish_at: "2021-04-23 00:10:00",
      members: [
        {
          member_id: 123,
          first_name: 'Boris',
          last_name: 'Bezb'
        },
        {
          member_id: 124,
          first_name: 'Vitaly',
          last_name: 'Gulyaev'
        }
      ]
    }

    return {
      target: transformTargetItem(target),
      hints: [
        {
          name: 'targets',
          title: this.$t('setup.hints.targets.title'),
          content: this.$t('setup.hints.targets.content')
        }
      ]
    }
  },

  computed: {
    currentHint () {
      return this.hints.find(item => item.name === this.activeHint)
    },

    taskItemClass () {
      const classes = []

      if (this.activeHint === 'task_prefix') {
        classes.push(`task-item_number-highlighted`)
      } else if (this.activeHint === 'schedule') {
        classes.push(`task-item_schedule-highlighted`)
      }

      return classes
    }
  },

  methods: {
    onChangeHint (val) {
      this.$emit('change', val)
    }
  }
}
</script>
<template>
  <div class="task-file">

    <div class="task-file__preview" @click="onPreviewClick">
      <img v-if="file.preview_url" :src="file.preview_url" class="task-file__img">
      <div class="task-file__format" v-else>
        {{ showFileFormat(file.name) }}
      </div>
    </div>

    <div class="task-file__info">
      <div class="task-file__title" @click="onDownload">
        {{ file.name }}
      </div>

      <div class="task-file__size">{{ file.size }} Kb</div>
    </div>

    <div class="task-file__controls">
      <div class="task-file__control" @click.stop="onDelete">
        <close-icon class="task-file__control-icon"/>
      </div>
    </div>
  </div>
</template>
<script>
  import CloseIcon from "../../icons/CloseIcon"
  import {createNamespacedHelpers} from "vuex";

  const { mapActions: taskActions } = createNamespacedHelpers('task')

  export default {
    name: 'FileItem',

    components: { CloseIcon },

    props: {
      file: {
        type: Object,
        required: true
      },

      taskId: {
        type: Number,
        default: 0
      }
    },

    methods: {
      ...taskActions(['downloadFile', 'deleteAttachment']),

      onPreviewClick () {
        if (this.file.preview_url) {
          this.$emit('show', this.file)
          return
        }

        this.onDownload();
      },

      onDownload () {
        //this.$router.push({ name: 'download', params: { uuid: this.file.uuid } })

        this.downloadFile({ uuid: this.file.uuid })
      },

      onDelete () {
        this.deleteAttachment({ uuid: this.file.uuid })
      },

      showFileFormat (name) {
        return name.split('.').pop()
      }
    }
  }
</script>
<style lang="sass">
.task-file
  box-sizing: border-box
  border-radius: 8px
  background: var(--grey-light-color-40)
  padding: 8px
  cursor: pointer
  display: flex
  column-gap: 8px
  position: relative

  &:hover
    .task-file__block
      box-shadow: 0px 5px 10px -1px rgba(50, 50, 71, 0.05), 0px 0px 1px rgba(12, 26, 75, 0.24)

    .task-file__controls
      opacity: 1

  &__preview
    border-radius: 8px
    height: 32px
    width: 32px
    overflow: hidden

  &__img
    height: 100%

  &__info
    flex: 1

  &__format
    align-items: center
    border-radius: 8px 0 0 8px
    background: var(--text-light-color)
    color: var(--block-bg-color2)
    display: flex
    font-size: 10px
    font-weight: 600
    height: 100%
    justify-content: center
    text-transform: uppercase

  &__title
    font-size: 12px
    font-weight: 500
    overflow-wrap: anywhere
    max-height: 28px
    overflow: hidden
    cursor: pointer

  &__size
    font-size: 11px
    color: var(--text-light-color)
    margin-top: 4px

  &__controls
    opacity: 0
    transition: all ease-in .1s

  &__control
    width: 20px
    height: 20px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer

  &__control-icon
    .path-stroke
      stroke: var(--text-primary-color)
</style>

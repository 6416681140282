<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="path-fill" d="M12 9.05838V6.49938C11.9997 6.30176 11.9409 6.10865 11.831 5.9444C11.7211 5.78014 11.565 5.65209 11.3825 5.57638C11.2 5.50066 10.9991 5.48068 10.8052 5.51894C10.6113 5.5572 10.4331 5.652 10.293 5.79138L4 11.9994L10.293 18.2064C10.3857 18.2996 10.4958 18.3735 10.6171 18.424C10.7385 18.4744 10.8686 18.5004 11 18.5004C11.1314 18.5004 11.2615 18.4744 11.3829 18.424C11.5042 18.3735 11.6143 18.2996 11.707 18.2064C11.7999 18.1136 11.8736 18.0034 11.9239 17.882C11.9742 17.7607 12 17.6307 12 17.4994V15.0104C14.75 15.0784 17.755 15.5764 20 18.9994V17.9994C20 13.3664 16.5 9.55638 12 9.05838Z" fill="black"/>
</svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'ReturnIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 10,
        defaultHeight: 10
      }
    }
  }
</script>

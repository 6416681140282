<template>
  <div>
    <el-popover
        placement="right-start"
        trigger="hover"
        :visible-arrow="false"
        popper-class="plan-popover"
        transition="sw-slide-right"
        v-if="isDisplayed"
    >
      <div>
        <div class="plan-popover__header">
          {{ $t('settings.rate.badge.title', { ratePlan: this.ratePlanName }) }}
        </div>

        <div class="plan-popover__content">
          <template v-if="workspace.rate_plan_code === 'free'">
            <div class="plan-popover__members">
              <small-users-icon class="plan-popover__members-icon"/>
              {{ $tc('settings.rate.badge.membersLimit', membersLimit, { count: membersLimit }) }}
            </div>

            <div class="plan-popover__free" v-html="$t('settings.rate.badge.freeText')"></div>

            <div class="plan-popover__action">
              <a class="ui-button ui-button_size_sm ui-button_block ui-button_link" :href="changePlanUrl" target="_blank" style="text-decoration: none;">
                {{ $t('settings.rate.changePlan') }}
              </a>
            </div>
          </template>

          <template v-if="workspace.subscription_status === 'trial'">
            <div class="plan-popover__members">
              {{ $t('settings.rate.badge.trial') }}
            </div>
            <div class="plan-popover__days">
              {{ $tc('settings.rate.badge.trialDaysRemain', daysLeft, { days: daysLeft }) }}
            </div>
            <div class="plan-popover__action">
              <ui-loading-button ui-class="size_sm block" :loading="isSubmitted" @click="onPayClick">
                {{ $t('settings.rate.badge.goToPay') }}
              </ui-loading-button>
            </div>
          </template>
        </div>
      </div>
      <div class="rate-badge" slot="reference">
        <div class="rate-badge__status">
          {{ status }}
        </div>
      </div>
    </el-popover>

    <el-dialog
        :title="$t('settings.rate.badge.dialog.title')"
        :visible="true"
        width="600px"
        :append-to-body="true"
        :close-on-click-modal="false"
        @close="onClose"
        v-if="showPaymentModal"
    >
      <div class="payment-success-text" v-html="$t('settings.rate.badge.dialog.description')">
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

  import SmallUsersIcon from "@/components/icons/SmallUsersIcon"
  import authUserMixin from "@/mixins/authUserMixin"
  import UiLoadingButton from "@/components/ui/UiLoadingButton"
  import {getBaseDomain} from "@/helpers/domain";
  import {transformDateToObject} from "@/services/scoring";

  export default {
    name: 'RatePlanBadge',

    components: { SmallUsersIcon, UiLoadingButton },

    mixins: [authUserMixin],

    data () {
      return {
        isSubmitted : false,
        showPaymentModal: false,
      }
    },

    computed: {
      ...mapGetters(['workspace', 'features']),

      status () {
        if (this.workspace.rate_plan_code === 'free') {
          return 'free'
        }

        if (this.workspace.subscription_status === 'trial') {
          return this.$t('settings.rate.badge.trialBadge')
        }

        return '';
      },

      isDisplayed () {
        return this.isOwner && (this.workspace.rate_plan_code === 'free' || this.workspace.subscription_status === 'trial')
      },

      membersLimit () {
        const feature = this.features.find((feature) => feature.code === 'members_count')
        return feature.capacity
      },

      changePlanUrl () {
        return `${getBaseDomain()}/account/workspaces/${this.workspace.global_id}/plan`
      },

      ratePlanName () {
        return this.$t(`settings.rate.ratePlans.${this.workspace.rate_plan_code}`)
      },

      daysLeft () {
        const expiredAt = transformDateToObject(this.workspace.subscription_expired_at)

        const diff = expiredAt.diffNow(['years', 'months', 'days'])

        return Math.round(diff.days)
      }
    },

    methods: {
      ...mapActions(['sendPaymentRequest']),

      async onPayClick () {
        this.isSubmitted = true
        try {
          await this.sendPaymentRequest()
          this.showPaymentModal = true
        } catch (e) {
          console.log(e)
        } finally {
          this.isSubmitted = false
        }
      },

      onClose () {
        this.showPaymentModal = false
      }
    }
  }
</script>
<style lang="sass">
  .rate-badge
    display: flex
    justify-content: center
    margin-top: 8px

    &__status
      display: inline-flex
      background: var(--color-blue-bg)
      color: var(--color-blue-text)
      padding: 2px 6px
      border-radius: 4px
      font-size: 12px
      cursor: default

  .plan-popover
    background: var(--block-bg-color)
    border: 0
    border-radius: 12px
    box-sizing: border-box
    padding: 0
    text-align: left
    width: 280px
    word-break: break-word
    color: var(--text-primary-color)

    &.el-popper[x-placement^=right]
      margin-left: -4px
      margin-top: -12px

    &__header
      background: var(--primary-light-color)
      border-radius: 12px 12px 0 0
      font-size: 14px
      font-weight: 500
      padding: 10px 12px

    &__content
      padding: 12px

    &__members
      display: flex
      align-items: center

      &-icon
        margin-right: 10px

        .path-stroke
          stroke: var(--primary-color)

    &__trial
      font-weight: 500
      margin-bottom: 4px

    &__days
      margin-bottom: 12px
      color: var(--text-light-color)
      font-size: 12px

    &__free
      color: var(--text-light-color)
      margin-top: 12px
      font-size: 13px

  .payment-success-text
    color: var(--text-light-color)

    p
      padding: 0
      margin: 0 0 12px
</style>

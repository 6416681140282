import SaveDraftModal from '@/components/tasks/components/SaveDraftModal'
import draftTask from '@/services/draftTask'

export default {
  components: { SaveDraftModal },

  beforeRouteLeave (to, from, next) {
    this.checkDraftBeforeClose(next)
  },

  data () {
    return {
      saveDraftModal: {
        show: false,
        nextCb: null,
      }
    }
  },

  methods: {
    checkDraftBeforeClose (next) {
      if (this.taskId || this.taskCreated) {
        return next()
      }

      if (this.isTaskChanged() === false) {
        return next()
      }

      this.saveDraftModal.show = true
      this.saveDraftModal.nextCb = next
    },

    isTaskChanged () {
      if (this.task.name) {
        return true
      }

      if (this.task.description) {
        const descr = JSON.parse(this.task.description)
        if (descr.html) {
          const plain = descr.html.replace(/(<([^>]+)>)/gi, '')
          if (plain) {
            return true
          }
        }
      }

      return false
    },

    onCancelClose () {
      this.saveDraftModal.nextCb = null
      this.saveDraftModal.show = false
    },

    showDraftModal (next = null) {
      this.saveDraftModal.show = true
      this.saveDraftModal.nextCb = next
    },

    onSaveDraft () {
      draftTask.save(this.task)
      this.saveDraftModal.show = false

      if (this.saveDraftModal.nextCb) {
        const next = this.saveDraftModal.nextCb
        this.saveDraftModal.nextCb = null

        return next()
      }
    },

    onDeleteDraft () {
      draftTask.delete()
      this.saveDraftModal.show = false

      if (this.saveDraftModal.nextCb) {
        const next = this.saveDraftModal.nextCb
        this.saveDraftModal.nextCb = null

        return next()
      }
    }
  }
}

<template>
  <el-dialog
    custom-class="modal-task-card"
    :visible="true"
    width="780"
    top="3vh"
    :close-on-click-modal="false"
    :show-close="false"
    :append-to-body="true"
    @close="closeModal"
  >
    <TaskCard
      :task-id="taskId"
      :member-id="memberId"
      :stage-id="stageId"
      :after-task-id="afterTaskId"
      :sphere-id="sphereId"
      :target-id="targetId"
      :is-modal="true"
      @close="closeModal"
    />
  </el-dialog>
</template>
<script>
  import TaskCard from '@/components/tasks/TaskCard'

  export default {
    name: 'ModalTaskCard',

    components: { TaskCard },

    props: {
      taskId: {
        type: Number,
        required: true
      },

      stageId: {
        type: Number,
        default: 0
      },

      memberId: {
        type: Number,
        default: 0
      },

      sphereId: {
        type: Number,
        default: 0,
      },

      targetId: {
        type: Number,
        default: 0,
      },

      afterTaskId: {
        type: Number,
        default: 0
      }
    },

    methods: {
      closeModal () {
        this.$emit('close')
      }
    }
  }
</script>
<style lang="sass">
  .el-dialog.modal-task-card
    overflow: hidden
    border: 0
    box-shadow: var(--popover-shadow)

    .el-dialog__header
      display: none

    .el-dialog__body
      padding: 0
</style>

<template>
  <div class="target-item-wrap">
    <div class="target-item" :class="targetItemClasses">
      <div class="target-item__header">
        <div class="target-item__title">
          <target-icon class="target-item__icon"/>
          <div class="target-item__title-text">
            {{ target.name }}
          </div>
        </div>

        <el-popover
          placement="bottom-end"
          trigger="click"
          ref="popper"
          :visible-arrow="false"
          :width="200"
          transition="sw-slide-up"
          popper-class="ui-dropdown-ex ui-dropdown-ex_no-padding"
        >
          <div class="ui-dropdown__option-list">
            <div class="ui-dropdown__option" @click="onEmitEvent('filter')">
              {{ $t('goals.showTasks') }}
            </div>
            <div class="ui-dropdown__option" @click="onEmitEvent('edit')">
              {{ $t('common.edit') }}
            </div>
            <div
              v-if="target.status === 'active'"
              class="ui-dropdown__option"
              @click="onEmitEvent('finish')"
            >
              {{ $t('goals.closeTheGoal') }}
            </div>
            <div class="ui-dropdown__option" @click="onEmitEvent('delete')">
              {{ $t('common.delete') }}
            </div>
          </div>
          <div class="target-item__opt" slot="reference">
            <more-icon/>
          </div>
        </el-popover>

      </div>

      <div class="target-item__content">
        <template v-if="target.total_tasks > 0">
          <div class="target-item__content-col">
            <div class="target-item__content-label">
              {{ $t('goals.progress') }}
            </div>

            <div class="target-item__content-val target-item__progress">
              <div class="target-item__bar" v-if="target.total_tasks">
                <div class="target-item__bar-fill" :style="'width:' + progressPercent + '%'"></div>
              </div>

              <div class="target-item__tasks">
                {{ target.finished_tasks }} {{ $t('goals.outOf') }} {{ target.total_tasks }}
              </div>
            </div>

          </div>

          <div class="target-item__content-col" v-if="target.status === 'active'">
            <div class="target-item__content-label">
              {{ $t('goals.plannedCompletion') }}
            </div>

            <div class="target-item__content-val">
              {{ estimatedFinishAt }}
            </div>
          </div>

          <div class="target-item__content-col">
            <div class="target-item__content-label">
              {{ $t('goals.assignees') }}
            </div>

            <div class="target-item__content-val target-item__members">
              <user-photo
                v-for="member in members"
                :user="member"
                :key="member.member_id"
                size="sm"
                class="member-short-item__photo"
              />
              <user-photo
                v-if="notShownMembersCount > 0"
                :name="`+${notShownMembersCount}`"
                size="sm"
              />
            </div>
          </div>

          <div class="target-item__content-col" v-if="target.status === 'finished'">
            <div class="target-item__content-label">
              {{ $t('common.status') }}
            </div>

            <div class="target-item__content-val">
              {{ $t('goals.finished') }}
            </div>
          </div>
        </template>
        <div v-else class="target-item__content-empty">
          {{ $t('goals.noTasks') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TargetIcon from "@/components/icons/TargetIcon"
import UserPhoto from "@/components/ui/UserPhoto"
import MoreIcon from "@/components/icons/MoreIcon"

import { getEstimatedTime } from "@/services/scoring"
import { targetColors} from "../helpers/targets"

const maxMembersShown = 5

export default {
  name: 'TargetItem',

  components: { TargetIcon, UserPhoto, MoreIcon },

  props: {
    target: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      showFinishModal: false
    }
  },

  computed: {
    targetItemClasses () {
      return `target-item_${this.target.color}`
    },

    members () {
      return this.target.members.slice(0, maxMembersShown)
    },

    notShownMembersCount () {
      return this.target.members.length - maxMembersShown
    },

    estimatedFinishAt () {
      if (!this.target.estimatedFinishAt) {
        return this.$t('goals.noEstimatedData')
      }

      return getEstimatedTime(this.target.estimatedFinishAt)
    },

    progressPercent () {
      if (!this.target.total_tasks) {
        return 0
      }

      const percent = Math.round((this.target.finished_tasks / this.target.total_tasks) * 100)
      return percent > 100
          ? 100
          : percent
    }
  },

  methods: {
    backgroundColor (code) {
      return targetColors[code]
    },

    onEmitEvent (event) {
      this.$refs.popper.doClose()
      this.$emit(event, this.target)
    }
  }
}
</script>

<template>
  <svg :width="iconWidth" :height="iconHeight" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3333 14.166H5.83333V8.74935C5.83333 6.66602 7.5 4.99935 9.58333 4.99935C11.6667 4.99935 13.3333 6.66602 13.3333 8.74935V14.166ZM15 13.3327V8.74935C15 6.19102 13.2167 4.04935 10.8333 3.48268V2.91602C10.8333 2.5845 10.7016 2.26655 10.4672 2.03213C10.2328 1.79771 9.91485 1.66602 9.58333 1.66602C9.25181 1.66602 8.93387 1.79771 8.69945 2.03213C8.46503 2.26655 8.33333 2.5845 8.33333 2.91602V3.48268C5.94167 4.04935 4.16667 6.19102 4.16667 8.74935V13.3327L2.5 14.9993V15.8327H16.6667V14.9993L15 13.3327ZM9.58333 18.3327C10.0254 18.3327 10.4493 18.1571 10.7618 17.8445C11.0744 17.532 11.25 17.108 11.25 16.666H7.91667C7.91667 17.108 8.09226 17.532 8.40482 17.8445C8.71738 18.1571 9.14131 18.3327 9.58333 18.3327Z" class="path-fill" :fill="backgroundColor"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'Bell',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 24,
        defaultHeight: 24
      }
    }
  }
</script>

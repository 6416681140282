<template>
  <div class="timeline-item">
    <div class="timeline-item__line">
      <div class="timeline-item__circle">
        <component :is="iconComponent" class="timeline-item__circle-icon" :class="iconClasses"/>
      </div>
    </div>

    <div class="timeline-item__data">
      <template v-if="item.type !== 'task.comment.added'">
        <div class="timeline-item__head">
          <user-photo :user="item.member" size="xs" :show-online="false"/>

          <div class="timeline-item__action">
          <span class="timeline-item__author">
            {{ item.member.first_name }} {{ item.member.last_name }}
          </span>

            {{ actionTitle }}

            <div class="timeline-item__assigned" v-if="item.assignee">
              <user-photo :user="item.assignee" size="xs" :show-online="false"/>
              {{ item.assignee.full_name }}
            </div>

            <div class="timeline-item__stage" v-if="newStage">
              {{ newStage }}
            </div>
          </div>

          <div class="timeline-item__date">
            {{ formattedDate.label }}
          </div>
        </div>

        <div class="timeline-item__content">
          <div class="timeline-item__payload">
            <template v-if="item.payload.score">
              <clock-icon class="timeline-item__score-icon"/>
              {{ formattedScore(item.payload.score) }}
            </template>
          </div>

          <div class="timeline-item__payload" v-if="item.type === 'task.score_changed'">
            <clock-icon class="timeline-item__score-icon"/>
            <template v-if="item.payload.old">
              {{ formattedScore(item.payload.old) }}
              <right-arrow-icon class="timeline-item__arrow"/>
            </template>
            {{ formattedScore(item.payload.new) }}
          </div>
        </div>
      </template>

      <task-comment-item :comment="comment" v-if="comment"/>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ClockIcon from '@/components/icons/ClockIcon.vue'
import CommentsIcon from '@/components/icons/CommentsIcon.vue'
import FireIcon from '@/components/icons/FireIcon.vue'
import Flag from '@/components/icons/Flag.vue'
import ForwardIcon from '@/components/icons/ForwardIcon.vue'
import ReturnIcon from "@/components/icons/ReturnIcon"
import PauseNarrowIcon from '@/components/icons/PauseNarrowIcon.vue'
import PlayOutlineIcon from '@/components/icons/PlayOutlineIcon.vue'
import RightArrowIcon from '@/components/icons/RightArrowIcon.vue'
import TargetIcon from '@/components/icons/TargetIcon.vue'
import TaskCommentItem from "@/components/tasks/components/TaskCommentItem"
import UserPhoto from '@/components/ui/UserPhoto.vue'
import { getPastDate, scoreToLabel } from '@/services/scoring'

export default {
  name: 'TimelineItem',

  components: {
    ClockIcon, CommentsIcon, FireIcon, Flag, ForwardIcon, PauseNarrowIcon, PlayOutlineIcon,
    ReturnIcon, RightArrowIcon, TaskCommentItem, TargetIcon, UserPhoto
  },

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  computed: {
    ...mapGetters(['workspace', 'stages', 'members']),
    ...mapGetters('task', ['comments']),

    taskNumber () {
      return this.workspace.task_prefix
          ? `#${this.workspace.task_prefix}-${this.item.task.number}`
          : `#${this.item.task.number}`
    },

    iconComponent () {
      switch (this.item.type) {
        case 'task.comment.added':
          return 'comments-icon'

        case 'task.progress.started':
          return 'play-outline-icon'

        case 'task.progress.paused':
          return 'pause-narrow-icon'

        case 'task.score_changed':
          return 'clock-icon'

        case 'task.assigned':
          return 'target-icon'

        case 'task.progress.overdue':
          return 'fire-icon'

        case 'task.stage_changed':
          return 'forward-icon'

        case 'task.stage_returned':
          return 'return-icon'
      }

      return 'flag'
    },

    iconClasses () {
      const classes = []

      if (this.iconComponent === 'forward-icon') {
        classes.push('timeline-item__circle-icon_small')
      }

      return classes
    },

    formattedDate () {
      return getPastDate(this.item.createdAt)
    },

    newStage () {
      if (['task.stage_changed', 'task.stage_returned'].indexOf(this.item.type) !== -1) {
        const stage = this.stages.find(item => item.id === this.item.payload.new)

        return stage ? stage.name : ''
      }

      return ''
    },

    actionTitle () {
      if (this.item.type === 'task.assigned' && !this.item.payload.new) {
        return this.$t(`feed.types.task.assignedNull`)
      }

      return this.$t(`feed.types.${this.item.type}`)
    },

    comment () {
      if (this.item.type !== 'task.comment.added') {
        return null
      }

      return this.comments.find((item) => item.id === this.item.payload.comment_id)
    }
  },

  methods: {
    openTask () {
      this.$emit('open-task', this.item.task.id)
    },

    formattedScore (score) {
      return scoreToLabel(score)
    }
  }
}
</script>
<style lang="sass">
.timeline-item
  display: flex
  column-gap: 12px

  &__line
    position: relative
    width: 24px

    &:after
      content: ""
      position: absolute
      top: 0
      bottom: 0
      width: 2px
      background: var(--color-grey-hover)
      left: 50%
      transform: translateX(-50%)

  &__circle
    width: 24px
    height: 24px
    border-radius: 12px
    background: var(--color-grey-hover)
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    z-index: 100

    &-icon
      width: 16px
      height: 16px

      &_small
        width: 10px
        height: 10px

    .path-fill
      fill: var(--text-primary-color)

    .path-stroke
      stroke: var(--text-primary-color)

  &__data
    flex: 1
    padding-bottom: 20px

  &__head
    align-items: center
    display: flex
    font-size: 13px
    column-gap: 10px
    padding-top: 2px

  &__author
    font-weight: 500
    color: var(--text-primary-color)

  &__assigned
    box-sizing: border-box
    display: flex
    align-items: center
    column-gap: 6px
    color: var(--text-primary-color)
    border-radius: 4px
    font-size: 13px
    cursor: default

  &__stage
    height: 20px
    box-sizing: border-box
    align-items: center
    display: flex
    color: var(--text-primary-color)
    background: var(--color-blue-bg)
    border: 1px solid var(--color-blue-hover)
    padding: 0 4px
    border-radius: 4px
    cursor: default

  &__action
    display: flex
    align-items: center
    color: var(--text-light-color)
    column-gap: 6px

  &__date
    color: var(--grey-color-100)

  &__content
    font-size: 13px
    margin-left: 32px

  &__task
    cursor: pointer
    align-items: center
    display: inline-flex
    margin-top: 8px
    border: 1px solid var(--color-blue-hover)
    background: var(--color-grey-bg)
    border-radius: 4px
    padding: 4px 8px
    color: var(--primary-color)

    &:hover
      color: var(--primary-color-hover)

      .timeline-item__task-number
        color: var(--text-primary-color)

    &-number
      color: var(--text-light-color)
      margin-right: 4px

    &-name
      font-weight: 500

  &__payload
    align-items: center
    display: flex
    margin-top: 8px
    color: var(--text-light-color)

  &__arrow
    width: 16px
    height: 16px
    margin: 0 6px

  &__score-icon
    width: 14px
    height: 14px
    margin-right: 6px

    .path-stroke
      stroke: var(--text-light-color)
</style>

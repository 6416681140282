<template>
<div class="backlog-list">
  <transition-group name="zoom" tag="div" v-if="!isLoading" appear>
    <StatusTaskItem
      v-for="(task, index) in backlogTasks"
      :key="task.id"
      :task="task"
      status="backlog"
      :index="index"
    />
    <div v-if="backlogTasks.length === 0" class="backlog-empty" :class="emptyClasses" key="no-items">
      <div class="backlog-empty__title">
        {{ $t('tasks.emptyBacklog') }}
      </div>
    </div>
  </transition-group>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import StatusTaskItem from "@/components/tasks/StatusTaskItem"
import {DateTime} from "luxon"

export default {
  name: 'Backlog',

  components: { StatusTaskItem },

  data () {
    return {
      backlogTasks: [],
      isLoading: false
    }
  },

  computed: {
    ...mapGetters(['authUser']),

    emptyClasses () {
      const classes = []

      if (this.isActiveDropZone) {
        classes.push(`backlog-empty_dropped`)
      }

      return classes
    }
  },

  created () {
    this.backlogTasks.push({
      id: 1,
      name: this.$t('tour.fakeTasks.backlog1'),
      number: 11,
      status: 'open',
      state: {
        assignee_id: this.authUser.member_id,
        assignee: this.authUser,
        score: 360,
        score_status: 'estimated',
        estimatedFinishAt: DateTime.local().plus({ minutes: 60 }),
        processing: {
          percent: 75,
          processed_minutes: 180,
          state: 'in_progress'
        }
      }
    }, {
      id: 2,
      name: this.$t('tour.fakeTasks.flowName2'),
      number: 13,
      status: 'open',
      state: {
        assignee_id: this.authUser.member_id,
        assignee: this.authUser,
        score: 60,
        score_status: 'estimated',
        estimatedFinishAt: DateTime.local().plus({ hours: -1 }),
        processing: {
          percent: 100,
          processed_minutes: 100,
          state: 'overdue'
        }
      }
    })
  }
}
</script>
<template>
<el-popover
  :placement="placement"
  trigger="manual"
  ref="popper"
  popper-class="tour-popover"
  :value="active"
  transition="tour-slide-top"
  :visible-arrow="false"
  :open-delay="400"
  :close-delay="400"
  width="400"
>
  <div class="tour-hint">
    <div class="tour-hint__head">
      <component :is="step.icon" class="tour-hint__icon"/>

      <div class="tour-hint__steps">
        {{ currentStepNumber }}/{{ stepsCount }}
      </div>
    </div>
    <div class="tour-hint__title">
      {{ $t(step.title) }}
    </div>
    <div class="tour-hint__descr" v-html="$t(step.description[0])" v-if="step.name !== 'tasksDate'"></div>
    <div class="tour-hint__descr tour-date-status-list" v-else>
      <div class="tour-date-status">
        <div class="task-item__status task-item__status_in_progress">
          <play-outline-icon/>
        </div>
        {{ $t('tour.steps.tasksDate.inProgress') }}
      </div>

      <div class="tour-date-status">
        <div class="task-item__status task-item__status_overdue">
          <fire-icon/>
        </div>
        {{ $t('tour.steps.tasksDate.overdue') }}
      </div>

      <div class="tour-date-status">
        <div class="task-item__status task-item__status_open">
          <sandclock-icon/>
        </div>
        {{ $t('tour.steps.tasksDate.open') }}
      </div>

      <div class="tour-date-status">
        <div class="task-item__status task-item__status_paused">
          <pause-narrow-icon/>
        </div>
         {{ $t('tour.steps.tasksDate.paused') }}
      </div>
    </div>
    <div class="tour-hint__actions">
      <div class="tour-hint__action-left">
        <ui-button ui-class="size_md plain" class="tour-hint__skip-btn" @click="onSkipTour" v-if="currentStepNumber !== stepsCount">
          {{ $t('tour.skip') }}
        </ui-button>
      </div>

      <div class="tour-hint__action-right">
        <ui-button ui-class="size_md plain" class="tour-hint__back-btn" @click="onPreviousStep" v-if="currentStepNumber > 1">
          {{ $t('tour.prevStep') }}
        </ui-button>
        <ui-button ui-class="size_md" @click="onNextStep">
          {{ $t(confirmBtn) }}
        </ui-button>
      </div>
    </div>
  </div>
</el-popover>
</template>
<script>
import FireIcon from "@/components/icons/FireIcon"
import SandclockIcon from "@/components/icons/SandclockIcon"
import MembersIcon from "@/components/icons/MembersIcon"
import PlayOutlineIcon from '@/components/icons/PlayOutlineIcon'
import PauseNarrowIcon from '@/components/icons/PauseNarrowIcon'
import SpheresIcon from "@/components/icons/SpheresIcon"
import StagesIcon from "@/components/icons/StagesIcon"
import TargetsIcon from "@/components/icons/TargetsIcon"
import TasksDateIcon from "@/components/icons/TasksDateIcon"
import TasksFlowIcon from "@/components/icons/TasksFlowIcon"
import TasksProgressIcon from "@/components/icons/TasksProgressIcon"
import UiButton from 'scorework-ui/src/components/UiButton'
import {createNamespacedHelpers} from "vuex";

const { mapGetters: tourGetters } = createNamespacedHelpers('tour')


export default {
  name: 'TourStep',

  components: {
    FireIcon, PauseNarrowIcon, PlayOutlineIcon, SandclockIcon,
    MembersIcon, SpheresIcon, StagesIcon, TargetsIcon, TasksDateIcon, TasksFlowIcon, TasksProgressIcon, UiButton
  },

  props: {
    active: {
      type: Boolean,
      required: true
    },

    placement: {
      type: String,
      default: 'bottom-start'
    },

    step: {
      type: Object,
      required: true
    },

    reference: {}
  },

  computed: {
    ...tourGetters(['currentStepNumber', 'stepsCount']),

    confirmBtn () {
      return this.stepsCount === this.currentStepNumber
        ? 'tour.complete'
        : 'tour.nextStep'
    }
  },

  watch: {
    reference (val) {
      this.$refs.popper.referenceElm = val
    },

    placement (val) {
      this.$refs.popper.currentPlacement = val
    }
  },

  methods: {
    onNextStep () {
      this.$emit('next')
    },

    onPreviousStep () {
      this.$emit('previous')
    },

    onSkipTour () {
      this.$emit('skip')
    }
  }
}
</script>
<style lang="sass">
.tour-popover
  border-radius: 16px
  background: var(--block-bg-color)
  padding: 20px
  word-break: break-word
  text-align: left

.tour-hint
  &__head
    display: flex
    justify-content: space-between
    align-items: start

  &__icon
    margin-bottom: 12px

  &__steps
    border-radius: 4px
    font-weight: 600
    padding: 4px 8px
    background: var(--primary-light-color)
    color: var(--primary-color)

  &__title
    font-size: 18px
    color: var(--text-primary-color)
    margin-bottom: 12px
    font-weight: 600

  &__descr
    font-size: 15px
    line-height: 1.6
    color: var(--text-primary-color)

    p
      margin: 0 0 12px
      padding: 0

    ul
      margin: 12px 0
      padding-left: 28px

  &__actions
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 20px

  &__action-right
    display: flex
    align-items: center

  &__skip-btn.ui-button
    padding: 0

  &__back-btn.ui-button
    color: var(--primary-color)
    font-weight: 500
    margin-right: 12px

    &:focus, &:hover
      color: var(--primary-color)

.tour-date-status
  color: var(--text-primary-color)
  align-items: center
  display: flex
  padding-bottom: 18px
  font-size: 14px

  .task-item__status
    margin-right: 12px

.tour-date-status-list
  .tour-date-status:last-child
    padding-bottom: 0
</style>

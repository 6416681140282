<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-stroke" d="M8.99972 10.7146L12.4283 14.2349L15.8569 10.7146M8.99972 7.28599L5.57115 3.85742L2.14258 7.28342M5.57115 3.85742V14.1431M12.4283 3.85742V14.1431" stroke="#5B6372" stroke-width="1.14286" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'MoreIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 10,
        defaultHeight: 10
      }
    }
  }
</script>

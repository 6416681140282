<template>
  <div class="sw-popover" v-show="showSuggestions" style="z-index:2000;">
    <template v-if="hasResults">
      <div class="ui-dropdown__option-list">
        <div
            v-for="(item, index) in suggestion.list"
            :key="index"
            class="ui-dropdown__option"
            :class="{ 'is-selected': suggestion.navigatedIndex === index }"
            @click="onSelect(item)"
        >
          <template v-if="suggestion.type === 'emoji'">
            {{ item.native }} {{ item.colons }}
          </template>
          <template v-if="suggestion.type === 'member'">
            {{ item.first_name + ' ' + item.last_name }}
          </template>
        </div>
      </div>
    </template>
    <div v-else class="suggestion-list__item is-empty">
      {{ $t('ui.noUsersFound') }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'SuggestionPopover',

  props: {
    suggestion: {
      type: Object,
      required: true
    }
  },

  computed: {
    hasResults() {
      return this.suggestion.list.length
    },

    showSuggestions() {
      return this.suggestion.query || this.hasResults
    }
  },

  methods: {
    onSelect (item) {
      const { type } = this.suggestion
      let attrs = {}

      switch (type) {
        case 'member':
          attrs = {
            id: item.member_id,
            label: item.first_name + ' ' + item.last_name
          }
          break;

        case 'emoji':
          attrs = {
            id: item.id,
            label: item.native
          }
          break;
      }

      this.$emit('select', attrs)
    }
  }
}
</script>

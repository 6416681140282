<template>
<div class="sw-task-progress" :class="progressClasses" v-if="task.status !== 'backlog'">
  <div class="sw-task-progress__content">
    <div class="sw-task-progress__status">
      <task-state-status :task="task"/>
    </div>

    <div class="sw-task-progress__time">
      <div class="task-time-status" v-if="isOverdue">
        <component :is="'fire-icon'" class="task-time-status__icon overdue"/>

        <div class="task-time-status__title">
          {{ overdueLabel}}
        </div>
      </div>

      <el-tooltip
          effect="dark"
          :content="$t(timeLabelTooltip)"
          placement="top"
          transition="sw-slide-top"
          v-if="progressStatus !== 'overdue'"
      >
        <div class="task-time-status" >
          <component :is="timeStatusIcon" class="task-time-status__icon" :class="timeIconClasses"/>

          <div class="task-time-status__title">
            {{ timeLabel }}
          </div>
        </div>
      </el-tooltip>
    </div>
  </div>
  <el-popover
    placement="top"
    trigger="hover"
    ref="popper"
    popper-class="sw-popover"
    transition="sw-slide-top"
  >
    <div class="task-stats">
      <div class="task-stats__row">
        <div class="task-stats__item" v-if="!task.startedAt">
          <div class="task-stats__label">
            {{ $t('tasks.progress.beProcessedAt') }}
          </div>

          <div class="task-stats__value">
            {{ getEstimatedFormattedDate(task.state.beProcessedAt) }}
          </div>
        </div>

        <div class="task-stats__item" v-else>
          <div class="task-stats__label">
            {{ $t('tasks.progress.startedAt') }}
          </div>

          <div class="task-stats__value">
            {{ getPastDate(task.startedAt) }}
          </div>
        </div>

        <div class="task-stats__item" v-if="task.status === 'finished'">
          <div class="task-stats__label">
            {{ $t('tasks.progress.finishedAt') }}
          </div>

          <div class="task-stats__value">
            {{ getPastDate(task.finishedAt) }}
          </div>
        </div>

        <div class="task-stats__item" v-else>
          <div class="task-stats__label">
            {{ $t('tasks.progress.estimatedFinishAt') }}
          </div>

          <div class="task-stats__value">
            {{ task.score ? getEstimatedFormattedDate(task.estimatedFinishAt) : $t('tasks.unknownTime') }}
          </div>
        </div>
      </div>

      <div class="task-stats__subtitle">
        <clock-icon class="mr-2"/>
        {{ $t('tasks.progress.stageBreakdown') }}
      </div>

      <div
        v-for="state in task.states"
        :key="state.id"
        class="task-stats__row"
      >
        <div class="task-stats__item">
          {{ state.stage.name }}
        </div>

        <div class="task-stats__item">
          {{ getStateActualScore(state) }}
        </div>
      </div>
    </div>
    <div class="sw-task-progress__bar" :class="barClasses" slot="reference">
      <div class="sw-task-progress__bar-filler" :style="`width: ${progressPercent}%`"></div>
    </div>
  </el-popover>

  <div class="sw-task-progress__stats">
    <div class="sw-task-progress__stats-time">
      <div class="task-processed-percent" :class="progressStatus" v-if="task.status !=='finished'">
        <ui-sektor :size="18" :percent="progressPercent" class="task-processed-percent__sector"/>

        <div class="task-processed-percent__title">
          {{ progressPercent }}%
        </div>
      </div>

      <el-tooltip
          effect="dark"
          :content="$t('tasks.processedTime')"
          placement="top"
          transition="sw-slide-top"
          v-if="processedTime"
      >
        <div class="task-processed-time">
          <clock-icon/>
          {{ formattedProcessedTime }}
        </div>
      </el-tooltip>
    </div>
  </div>
</div>
</template>
<script>
import ClockIcon from "@/components/icons/ClockIcon"
import FireIcon from "@/components/icons/FireIcon"
import Flag from "@/components/icons/Flag"
import TaskStateStatus from "@/components/tasks/components/TaskStateStatus"
import { getEstimatedTime, getPastDate, getScoreLabel, getScoreObject } from "@/services/scoring"
import taskStateMixin from "@/components/tasks/mixins/taskStateMixin";
import {ProgressStatusOverdue, StatusFinished, StatusInProgress, StatusOpen, StatusPaused} from "@/helpers/tasks";
import UiSektor from "@/components/ui/UiSektor"

export default {
  name: 'TaskProgress',

  components: {UiSektor, ClockIcon, FireIcon, Flag, TaskStateStatus },

  mixins: [taskStateMixin],

  props: {
    task: {
      type: Object,
      required: true
    }
  },

  computed: {
    progressPercent() {
      if (!this.task.estimatedFinishAt || !this.task.score) {
        return 0
      }

      const actualScore = this.task.processing
          ? this.task.processing.processed_minutes
          : this.task.actual_progress

      const percent = actualScore > this.task.score
          ? 100
          : actualScore / this.task.score * 100

      return Math.round(percent)
    },

    timeLabel() {
      switch (this.progressStatus) {
        case StatusOpen:
        case StatusPaused:
          return getEstimatedTime(this.task.state.beProcessedAt)

        case StatusInProgress:
          if (!this.task.score) {
            return this.$t('tasks.statuses.unknownFinish')
          }

          return getEstimatedTime(this.task.estimatedFinishAt)

        case ProgressStatusOverdue:
          return this.overdueLabel

        case StatusFinished:
          return getPastDate(this.task.finishedAt).label

        default:
          return ''
      }
    },

    overdueLabel () {
      return this.$t('tasks.statuses.overdueBy', {date: this.getFormattedScore(this.processedTime - this.task.score)})
    },

    timeIconClasses() {
      return this.progressStatus
    },

    progressClasses() {
      const classes = []
      classes.push('sw-task-progress_' + this.progressStatus)

      return classes
    },

    barClasses () {
      return `sw-task-progress__bar_${this.progressStatus}`
    },

    formattedProcessedTime() {
      return getScoreLabel(getScoreObject(this.processedTime)) || this.$t('tasks.unknown')
    },
  },

  methods: {
    getPastDate (date) {
      return getPastDate(date).label
    },

    getEstimatedFormattedDate (date) {
      return getEstimatedTime(date)
    },

    getStateActualScore (state) {
      if (state.id === this.task.state_id && this.task.processing) {
        return this.getFormattedScore(this.task.processing.processed_minutes)
      }

      if (!state.actual_progress) {
        return this.$t('tasks.progress.noScoreShort')
      }

      return this.getFormattedScore(state.actual_progress)
    },

    getFormattedScore (score) {
      return getScoreLabel(getScoreObject(score))
    }
  }
}
</script>
<style lang="sass">
@mixin colored-time-icon($status, $color)
  &.#{$status}
    .path-fill
      fill: var(--color-#{$color}-dots)

    .path-stroke
      stroke: var(--color-#{$color}-dots)

@mixin colored-percent($state, $color)
  &.#{$state}
    .task-processed-percent__sector
      box-shadow: 0 0 0 1.5px var(--color-#{$color})
      margin-left: 2px

      .ui-sektor__sector
        fill: var(--color-#{$color})

    .task-processed-percent__title
      font-size: 13px
      font-weight: 600
      color: var(--color-#{$color})

.sw-task-progress
  padding: 16px 20px

  &__content
    display: flex
    justify-content: space-between
    margin-bottom: 16px
    align-items: center

  &__time
    display: flex
    align-items: center
    column-gap: 16px

  &__stats
    margin-top: 16px
    display: flex
    align-items: center
    justify-content: space-between

  &__bar
    background: var(--primary-light-color)
    border-radius: 3px
    height: 6px
    position: relative
    width: 100%

    &_overdue
      background: var(--alert-light-color)

      .sw-task-progress__bar-filler
        background: var(--color-red-dots)

    &_in_progress
      background: var(--light-grey-color-24)

      .sw-task-progress__bar-filler
        background: var(--color-aquamarine-text)

    &-filler
      background: var(--primary-color)
      border-radius: 3px
      height: 6px
      left: 0
      position: absolute
      top: 0

  &__stats-time
    display: flex
    align-items: center
    column-gap: 16px

.task-stats
  padding: 16px 20px
  width: 350px

  &__row
    align-items: center
    display: flex
    justify-content: space-between
    margin-bottom: 8px

    &:last-child
      margin-bottom: 0

  &__label
    color: var(--grey-color-100)
    margin-bottom: 4px

  &__subtitle
    align-items: center
    color: var(--text-light-color)
    display: flex
    font-weight: 500
    margin-bottom: 12px
    padding-top: 12px

.task-time-status
  display: flex
  align-items: center
  column-gap: 6px

  &__icon
    @include colored-time-icon(open, grey)
    @include colored-time-icon(paused, grey)
    @include colored-time-icon(in_progress, blue)
    @include colored-time-icon(overdue, red)
    @include colored-time-icon(finished, purple)

  &__title
    font-size: 13px
    cursor: default

.task-processed-time
  display: flex
  align-items: center
  column-gap: 6px
  font-size: 13px
  cursor: default

.task-processed-percent
  display: flex
  align-items: center
  column-gap: 8px

  @include colored-percent(in_progress, aquamarine-text)
  @include colored-percent(overdue, red-dots)

  &__sector
    box-shadow: 0 0 0 1.5px var(--primary-color)
    margin-left: 2px

    .ui-sektor__sector
      fill: var(--primary-color)

  &__title
    font-size: 13px
    font-weight: 600
    color: var(--primary-color)

</style>

<template>
  <div>
    <div>
      <div class="ui-dropdown ui-dropdown-ex ui-dropdown_round">
        <div class="xui-list editor-options-list">
          <div
            v-for="(item, i) in menuItems"
            :key="item.label"
            class="xui-list-item"
            :class="itemClasses(i)"
            @click="onItemClick(item.action)"
          >
            <component :is="item.icon" class="xui-list-item-icon"/>
            {{ t(`editor.menuItems.${item.label}`) }}
          </div>
        </div>
      </div>
    </div>

    <input type="file" ref="file" style="visibility: hidden; display: none;" accept="image/*" @change="onImageChange"/>
  </div>
</template>
<script>
  import H1Icon from "@/components/icons/editor/H1Icon"
  import CodeIcon from "@/components/icons/editor/CodeIcon"
  import H2Icon from "@/components/icons/editor/H2Icon"
  import UlIcon from "@/components/icons/editor/UlIcon"
  import H3Icon from "@/components/icons/editor/H3Icon"
  import ImageIcon from "@/components/icons/editor/ImageIcon"
  import TableIcon from "@/components/icons/editor/TableIcon"
  import languageService from "@/services/language"
  import store from "@/store"
  import {floatingMenuPluginKey} from '@/components/ui/textEditor/floatingMenu/plugin';

  export default {
    name: 'FloatingNodeMenu',

    components: { TableIcon, ImageIcon, H3Icon, UlIcon, H2Icon, CodeIcon, H1Icon },

    props: {
      editor: {
        type: Object,
        required: true,
      },

      forceShow: {
        type: Boolean,
        required: true,
      }
    },

    data () {
      return {
        activeIndex: 0,
        menuItems: [
          {
            label: 'heading1',
            icon: 'h1-icon',
            action: ({ baseFocus }) => baseFocus().toggleHeading({ level: 1 }).run()
          },

          {
            label: 'heading2',
            icon: 'h2-icon',
            action: ({ baseFocus }) => baseFocus().toggleHeading({ level: 2 }).run()
          },

          {
            label: 'heading3',
            icon: 'h3-icon',
            action: ({ baseFocus }) => baseFocus().toggleHeading({ level: 3 }).run()
          },

          {
            label: 'image',
            icon: 'image-icon',
            action: () => this.onUploadImage()
          },

          {
            label: 'list',
            icon: 'ul-icon',
            action: ({ baseFocus }) => baseFocus().toggleBulletList().run()
          },

          {
            label: 'table',
            icon: 'table-icon',
            action: ({ baseFocus }) => baseFocus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
          },

          {
            label: 'codeBlock',
            icon: 'code-icon',
            action: ({ baseFocus }) => baseFocus().toggleCodeBlock().run()
          },
        ]
      }
    },

    methods: {
      shouldShow ({ view, state }) {
        const { selection } = state
        const { $anchor, empty } = selection

        const isParagraph = $anchor.parent.type.name === 'paragraph'
        //const isRootDepth = $anchor.depth === 1

        if (this.forceShow) {
          this.$emit('forceHide')
          return true
        }

        const isEmptyTextBlock = $anchor.parent.isTextblock && !$anchor.parent.type.spec.code && $anchor.parent.textContent === '/'

        if (
            !view.hasFocus()
            || !empty
            || !isParagraph
            || !isEmptyTextBlock
            || !this.editor.isEditable
        ) {
          return false
        }

        return true
      },

      baseFocus() {
        return this.editor.chain().focus().command(({ tr }) => {
          const { $from } = tr.selection
          tr.insertText('', $from.pos - $from.parentOffset, $from.pos - $from.parentOffset + 1)

          return true
        })
      },

      onUploadImage () {
        this.$refs.file.click()
      },

      onImageChange () {
        const file = this.$refs.file.files[0]

        const formData = new FormData()
        formData.append('image', file)


        store.dispatch('uploadImage', { formData, progressCallback: e => this.onFileProgress(e) })
          .then(image => {
            const { url, thumbnails = {} } = image
            const previewUrl = thumbnails.sm || url

            this.baseFocus().insertContent({
              type: 'image',
              attrs: {
                src: previewUrl,
                fullSrc: url,
              }
            }).run()
          })
      },

      onFileProgress () {

      },

      onKeyDown (e) {
        if (e.code === 'ArrowDown') {
          e.preventDefault()
          this.activeIndex = this.menuItems.length - 1 === this.activeIndex ? 0 : this.activeIndex + 1
          return true
        }

        if (e.code === 'ArrowUp') {
          e.preventDefault()
          this.activeIndex = this.activeIndex === 0 ? this.menuItems.length - 1 : this.activeIndex - 1
          return true
        }

        if (e.code === 'Enter' || e.code === 'Space') {
          e.preventDefault()
          e.stopPropagation()
          const activeItem = this.menuItems[this.activeIndex]
          this.onItemClick(activeItem.action)
          return true
        }

        return false
      },

      itemClasses (i) {
        const classes = []
        if (i === this.activeIndex) {
          classes.push('xui-list-item_selected')
        }

        return classes
      },

      onItemClick (action) {
        this.activeIndex = 0
        action({ baseFocus: this.baseFocus })
        this.editor.view.dispatch(this.editor.view.state.tr.setMeta(floatingMenuPluginKey, {
          forceShow: false,
        }))
      },

      t (name) {
        return languageService.i18n.t(name)
      }
    }
  }
</script>
<style lang="sass">
  .editor-options-list
    min-width: 176px
</style>

<template>
  <div class="work-schedule-selector">
    <div class="work-schedule-selector__week-days">
      <div
        v-for="(weekDay, number) in weekDays"
        :key="number"
        class="work-schedule-selector__week-day-item"
        :class="{'work-schedule-selector__week-day-item_active': isDaySelected(number)}"
        @click="onDayClick(Number(number))"
      >
        {{ $t(weekDay.short) }}
        <div class="work-schedule-selector__week-day-selected" v-if="isDaySelected(number)">
          <check-icon background="#ffffff" width="12" height="12" view-box="0 -2 12 16"/>
        </div>
      </div>
    </div>

    <div class="work-schedule-selector__work-hours">
      <div class="work-schedule-selector__time-label">{{ $t('workSchedule.timeFrom') }}</div>
      <el-time-picker
          :value="startTime"
          @input="val => onTimeChange('start', val)"
          @focus="onFocus"
          placeholder="Arbitrary time"
          format="HH:mm"
          class="work-schedule-selector__time-picker"
      >
      </el-time-picker>

      <div class="work-schedule-selector__time-label">{{ $t('workSchedule.timeTo') }}</div>
      <el-time-picker
          :value="endTime"
          @input="val => onTimeChange('end', val)"
          @focus="onFocus"
          placeholder="Arbitrary time"
          format="HH:mm"
          class="work-schedule-selector__time-picker"
      >
      </el-time-picker>
    </div>
  </div>
</template>
<script>
  import CheckIcon from "../icons/CheckIcon"
  import { weekDays} from "@/services/scoring"

  export default {
    name: 'WorkScheduleSelector',

    components: { CheckIcon },

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        weekDays
      }
    },

    computed: {
      startTime () {
        const startTime = this.value.work_hours.start.split(':')

        return new Date(2016, 9, 10, Number(startTime[0]), Number(startTime[1]))
      },

      endTime () {
        const endTime   = this.value.work_hours.end.split(':')

        return new Date(2016, 9, 10, Number(endTime[0]), Number(endTime[1]))
      }
    },

    methods: {
      isDaySelected (day) {
        return this.value.days.indexOf(Number(day)) !== -1
      },

      onDayClick (day) {
        const days = [...this.value.days]
        const foundIndex = days.indexOf(day)

        if (foundIndex === -1) {
          days.push(day)
          days.sort()
        } else {
          if (days.length === 1) {
            return
          }

          days.splice(foundIndex, 1)
        }

        this.$emit('input', { ...this.value, days })
      },

      onTimeChange (field, val) {
        let hours = val.getHours()
        if (hours < 10) {
          hours = '0' + hours
        }

        let minutes = val.getMinutes()
        if (minutes < 10) {
          minutes = '0' + minutes
        }

        console.log({
          days: this.value.days,
          work_hours: {
            ...this.value.work_hours,
            [field]: `${hours}:${minutes}`
          }
        })

        this.$emit('input', {
          days: this.value.days,
          work_hours: {
            ...this.value.work_hours,
            [field]: `${hours}:${minutes}`
          }
        })
      },

      onFocus (e) {
        this.$emit('focus', e)
      }
    }
  }
</script>

import SandclockIcon from '@/components/icons/SandclockIcon'
import FireIcon from "@/components/icons/FireIcon"
import PauseNarrowIcon from '@/components/icons/PauseNarrowIcon'
import PlayOutlineIcon from '@/components/icons/PlayOutlineIcon'
import {
  getEstimatedTime,
  getPastDate,
  getScoreLabel,
  getScoreObject,
  scoreToLabel,
  toUtcWorkTime
} from "@/services/scoring"
import {DateTime} from "luxon";

const statusIcons = {
  open: 'sandclock-icon',
  not_scored: 'sandclock-icon',
  paused: 'pause-narrow-icon',
  in_progress: 'play-outline-icon',
  overdue: 'fire-icon'
}

export default {
  components: { SandclockIcon, FireIcon, PauseNarrowIcon, PlayOutlineIcon },

  data () {
    const processing = this.task.state.processing
      ? { ...this.task.state.processing }
      : {}

    return {
      processing,
      remainMinutes: 0,
      intervalId: null,
      timeoutId: null,
    }
  },

  computed: {
    taskStatus () {
      if (this.task.status === 'in_progress' && this.processing.state === 'overdue' && this.task.score) {
        return 'overdue'
      }

      if (this.task.status === 'open' && this.task.startedAt) {
        return 'paused'
      }

      if (this.taskState.score_status === 'undefined') {
        return 'not_scored'
      }

      return this.task.status
    },

    statusIcon () {
      return statusIcons[this.taskStatus]
    },

    getEstimatedTimeLabel () {
      if (this.task.status === 'open') {
        if (this.taskState.score_status === 'undefined') {
          return this.$t('tasks.unknown')
        }

        return getEstimatedTime(this.taskState.beProcessedAt)
      }

      if (this.task.status === 'finished') {
        const { label } = getPastDate(this.task.finishedAt)

        return label
      }

      const estimatedFinishAt = this.taskState.estimatedFinishAt || this.task.estimatedFinishAt

      if (estimatedFinishAt) {
        if (!this.task.score) {
          return scoreToLabel(this.processing.processed_minutes)
        }

        if (this.taskStatus === 'overdue') {
          const overdueMinutes = this.processing.processed_minutes - this.task.score
          const scoreObject = getScoreObject(overdueMinutes)

          return `${this.$t('tasks.statuses.overdueByItem', { date: getScoreLabel(scoreObject) })}`
        }

        return getEstimatedTime(estimatedFinishAt)
      }

      if (this.task.status === 'in_progress') {
        return this.$t('tasks.inProgress')
      }

      return ''
    },

    progressPercent () {
      if (this.processing.percent) {
        return this.processing.percent > 100 ? 100 : this.processing.percent
      }

      if (!this.task.score) {
        return 0
      }

      const percent = Math.round(this.task.actual_progress / this.task.score * 100)

      return percent > 100 ? 100 : percent
    },

    statusClasses () {
      const classes = [`task-item__status_${this.taskStatus}`]

      return classes
    },

    estimatedLabelTooltip () {
      switch (this.taskStatus) {
        case 'open':
        case 'paused':
          return 'tasks.progress.beProcessedAt'

        case 'in_progress':
          if (!this.task.estimatedFinishAt) {
            return 'tasks.progress.estimatedFinishAtUnknown'
          }

          if (!this.task.score) {
            return 'tasks.progress.spentTime'
          }

          return 'tasks.progress.estimatedFinishAt'

        case 'overdue':
          return 'tasks.progress.overdueBy'
      }

      return 'tasks.progress.noScore'
    },

    statusLabelClasses () {
      const classes = []

      if (this.task.status === 'in_progress' || this.task.actual_progress || this.task.finished_at) {
        classes.push('task-item__estimated-date_progressed')
      }

      return classes
    },

    progressBarClasses () {
      const classes = []

      if (this.processing && this.processing.state === 'overdue') {
        classes.push('task-item__progress-bar_overdue')
      }

      if (this.taskState.finished_at) {
        classes.push('task-item__progress-bar_finished')
      } else if (this.task.status !== 'in_progress') {
        classes.push('task-item__progress-bar_paused')
      }

      return classes
    }
  },

  methods: {
    runProgressInterval () {
      if (this.remainMinutes < 1) {
        clearInterval(this.intervalId)
        return
      }

      this.intervalId = setInterval(() => this.calculateProgress(), 60 * 1000)
    },

    calculateProgress () {
      //console.log('calculating progress')
      this.processing.processed_minutes += 1

      if (this.task.score) {
        const percent = Math.round(this.processing.processed_minutes / this.task.score * 100)
        this.processing.percent = percent <= 100 ? percent : 100

        if (this.processing.percent === 100) {
          clearInterval(this.intervalId)
          return
        }
      }

      //console.log('minutes', this.processing.processed_minutes)
      //console.log('percent', percent)
      this.remainMinutes -= 1

      if (this.remainMinutes < 1) {
        clearInterval(this.intervalId)
      }
    },

    initProgressProcessing () {
      const { days, work_hours: workHours } = this.taskState.assignee.work_schedule[0]

      const now = DateTime.utc()

      if (days.indexOf(now.weekday) === -1) {
        return
      }

      const [sH, sM] = toUtcWorkTime(workHours.start).split(':')
      let startDate = DateTime.utc().set({ hour: sH, minute: sM })

      const [eH, eM] = toUtcWorkTime(workHours.end).split(':')
      let endDate = DateTime.utc().set({ hour: eH, minute: eM })

      // человек работает в ночь
      if (startDate > endDate) {
        endDate = endDate.plus({ days: 1 })
      }

      // console.log(now.toLocaleString(DATETIME_FULL_WITH_SECONDS))
      // console.log(startDate.toLocaleString(DATETIME_FULL_WITH_SECONDS))
      // console.log(endDate.toLocaleString(DATETIME_FULL_WITH_SECONDS))
      // console.log(startDate > endDate)

      if (now >= startDate && now <= endDate) {
        const diff = endDate.diff(now, ['minutes'])
        this.remainMinutes = diff.minutes

        this.runProgressInterval()
      } else {
        if (now > endDate) {
          startDate = startDate.plus({ days: 1 })
        }

        const diff = startDate.diff(now, ['minutes'])
        this.timeoutId = setTimeout(() => this.initProgressProcessing(), 1000 * 60 * Math.abs(diff.minutes))
      }
    }
  },

  watch: {
    task: function (newVal) {
      const processing = newVal.state.processing || {}
      this.processing = { ...processing }

      if (this.intervalId) {
        clearInterval(this.intervalId)
      }

      if (this.taskStatus === 'in_progress') {
        this.initProgressProcessing()
      }
    }
  },

  mounted () {
    if (this.taskStatus === 'in_progress') {
      this.initProgressProcessing()
    }
  },

  beforeDestroy () {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }
}

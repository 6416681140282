<template>
  <div class="">
    <div class="settings-title">
      {{ $t('settings.team') }}
    </div>

    <add-member-modal
      v-if="showInviteModal"
      :multi-project="true"
      @close="showInviteModal = false"
      @submit="onMembersInvited"
    />

    <div class="fx fx_space-between mb-4">
      <ui-tabs :tabs="tabs" :value="selectedTab" @input="onTabChange" variant="medium">
        <template v-slot:default="{ tab }">
          {{ $t(`settings.members.tabs.${tab.name}`) }} {{ getMembersCount(tab.id) }}
        </template>
      </ui-tabs>

      <ui-button ui-class="light size_md" class="ml-4" @click="showInviteModal = true">
        <plus class="mr-2"/>
        {{ $t('settings.inviteMembers') }}
      </ui-button>
    </div>

    <div v-if="displayedMembers.length">
      <div class="team-grid team-grid-head" :style="{'grid-template-columns': gridSize}">
        <div
          v-for="col in columns"
          :key="col.name"
          class="team-grid-head__cell"
          :class="col.class || ''"
        >
          {{ $t(col.name) }}
        </div>
      </div>

      <div class="team-grid-body">
        <div
          v-for="member in displayedMembers"
          :key="member.member_id"
          class="team-grid team-grid-row"
          :style="{'grid-template-columns': gridSize}"
        >
          <div class="team-grid-row__cell team-member">
            <div class="team-member__photo">
              <user-photo size="xmd" :user="member"/>
            </div>
            <div class="team-member__content" v-if="member.status === 'active'">
              {{ member.first_name }} {{ member.last_name }}

              <div class="team-member__email">
                {{ member.email }}
              </div>
            </div>
            <div class="team-member__content" v-else>
              {{ member.email }}

              <div class="team-member__email">
                {{ member.status === 'inactive' ? $t('settings.excluded') : $t('settings.invited') }} {{ invitedDateLabel(member.updated_at) }}
              </div>
            </div>
          </div>

          <div class="team-grid-row__cell team-grid-row__cell_fx">
            <template v-if="member.status === 'active'">
              <ui-dropdown
                :options="availableRoles"
                :value="member.role"
                :multiple="false"
                :width-by-reference="false"
                key-field=""
                popover-class="ui-dropdown_no-border"
                @input="role => onRoleChange(member, role)"
                placement="bottom"
                v-if="member.role !== roleOwner"
              >
                <template v-slot:default>
                  <div class="">
                    {{ $t(`members.roles.${member.role}`) }}
                    <chevron-down background="#2F3750" class="task-spheres__chevron-icon"/>
                  </div>
                </template>

                <template v-slot:option="{ option }">
                  <div class="team-grid-row__cell-owner">
                    {{ $t(`members.roles.${option}`) }}
                  </div>
                </template>
              </ui-dropdown>
              <div v-else class="team-grid-row__cell-owner">
                {{ $t(`members.roles.${member.role}`) }}
              </div>
            </template>
            <template v-else>
              {{ member.status === 'invited' ? $t('settings.invited') : $t('settings.excluded') }}
            </template>
          </div>

          <div class="team-grid-row__cell team-grid-row__cell_fx">
            <div class="member-projects-dp__items">
              <div
                v-for="memberProject in member.projects"
                :key="memberProject.project"
                class="sphere-badge"
              >
                {{ projectName(memberProject.project) }}
              </div>
            </div>
          </div>

          <div class="team-grid-row__cell team-grid-row__cell_fx" v-if="isAdmin">
            <hourly-rate-dropdown
              :value="member.hourly_rate"
              @submit="(val) => onHourlyRateChange(member, val)"
              v-if="member.status !== 'invited'"
            />
            <template v-else>
              {{ $t('members.emptyHourlyRate') }}
            </template>
          </div>

          <div class="team-grid-row__cell team-grid-row__cell_center">
            <el-popover
              placement="bottom-end"
              trigger="click"
              ref="popper"
              :visible-arrow="false"
              transition="sw-slide-up"
              popper-class="ui-dropdown-ex ui-dropdown-ex_no-padding"
            >
              <div class="ui-dropdown__option-list">
                <div class="ui-dropdown__option" v-if="member.status === 'invited'" @click="onResendMemberInvite(member)">
                  {{ $t('settings.resendMemberInvite') }}
                </div>
                <div class="ui-dropdown__option" @click="onDeleteMember(member)">
                  {{ $t('settings.removeFromTeam') }}
                </div>
              </div>

              <ui-button ui-class="plain size_md" class="dots-btn" slot="reference">
                <more-horizontal-icon/>
              </ui-button>
            </el-popover>
          </div>
        </div>
      </div>
    </div>

    <div class="empty-members" v-else>
      {{ $t('settings.members.noInvitedMembers')}}
    </div>

    <confirmation-modal
      :title="$t('settings.members.removeConfirmation.title')"
      :description="$t('settings.members.removeConfirmation.description')"
      :submit-title="$t('settings.members.removeConfirmation.submit')"
      width="400px"
      :is-processing="deleteModal.isProcessing"
      v-if="deleteModal.show"
      @close="deleteModal.show = false"
      @confirm="onDeleteConfirm"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import ChevronDown from "@/components/icons/ChevronDown"
import HourlyRateDropdown from '@/components/settings/HourlyRateDropdown'
import MoreHorizontalIcon from "@/components/icons/MoreHorizontalIcon"
import UserPhoto from "@/components/ui/UserPhoto"
import UiButton from 'scorework-ui/src/components/UiButton'
import UiDropdown from "@/components/tasks/UiDropdown"
import AddMemberModal from "@/components/settings/AddMemberModal"
import Plus from '@/components/icons/Plus'
import UiTabs from '@/components/ui/UiTabs'
import { getPastDate, transformDateToObject } from "@/services/scoring"
import pageTitleMixin from "@/views/mixins/pageTitleMixin"
import authUserMixin from "@/mixins/authUserMixin"
import ConfirmationModal from "@/components/ui/ConfirmationModal";

const tableCols = [
  {
    name: 'members.roles.member',
    width: 'minmax(250px, auto)',
  },
  {
    name: 'settings.role',
    width: '160px',
  },
  {
    name: 'settings.members.projects',
    width: 'minmax(200px, 300px)',
  },
  {
    name: 'members.hourlyRate',
    width: 'minmax(100px, 180px)',
    role: 'admin',
  },
  {
    name: 'settings.actions',
    width: '85px',
    class: 'team-grid-head__cell_center',
  }
]

export default {
  name: 'TeamSettings',

  components: {
    ConfirmationModal,
    AddMemberModal, ChevronDown, MoreHorizontalIcon, UiButton, UiDropdown, UserPhoto, UiTabs,
    HourlyRateDropdown, Plus,
  },

  mixins: [pageTitleMixin, authUserMixin],

  data () {
    return {
      showInviteModal: false,
      availableRoles: [
          'admin', 'member'
      ],

      tabs: [
        {
          id: 'active',
          name: 'active',
        },
        {
          id: 'invited',
          name: 'invited',
        },
      ],

      selectedTab: 'active',

      deleteModal: {
        show: false,
        isProcessing: false,
        memberId: null,
      },
    }
  },

  computed: {
    ...mapGetters(['workspace', 'allProjects', 'project']),
    ...mapGetters('workspaceMembers', ['activeMembers', 'invitedMembers']),

    pageTitle () {
      return {
        message: 'pageTitles.settings.team',
        params: { workspace: this.workspace.name }
      }
    },

    displayedMembers () {
      return this.selectedTab === 'invited'
        ? this.invitedMembers
        : this.activeMembers
    },

    columns () {
      if (!this.isAdmin) {
        return tableCols.filter(col => col.role !== 'admin')
      }

      return [...tableCols]
    },

    gridSize () {
      return this.columns.map(col => col.width).join(' ')
    }
  },

  methods: {
    ...mapActions(['resendInvite', 'fetchAllProjects']),
    ...mapActions('workspaceMembers', ['fetchWorkspaceMembers', 'updateMember', 'removeMember']),

    invitedDateLabel(date) {
      const dt = transformDateToObject(date)
      const {label} = getPastDate(dt)

      return label
    },

    onMembersInvited() {
      this.showInviteModal = false
    },

    async onResendMemberInvite(member) {
      try {
        await this.resendInvite({memberId: member.member_id});

        this.$notify({
          title: this.$t('settings.memberInvite'),
          message: this.$t('settings.memberReinviteSent'),
          position: 'bottom-right',
          type: 'success'
        })
      } catch (e) {
        this.$notify({
          title: this.$t('settings.memberInvite'),
          message: this.$t('settings.memberReinviteSentFailed'),
          position: 'bottom-right',
          type: 'error'
        })
      }
    },

    onDeleteMember(member) {
      this.deleteModal.show = true
      this.deleteModal.memberId = member.member_id
    },

    async onDeleteConfirm () {
      this.deleteModal.isProcessing = true
      try {
        await this.removeMember({memberId: this.deleteModal.memberId})
        this.$notify({
          title: this.$t('common.changesSaved'),
          message: this.$t(`settings.members.notifications.removed`),
          position: 'bottom-right',
          type: 'success'
        })

        this.fetchWorkspaceMembers()
      } catch (e) {
        this.$notify({
          title: this.$t('common.error'),
          message: this.$t('settings.members.notifications.failed'),
          position: 'bottom-right',
          type: 'error'
        })
      } finally {
        this.deleteModal.isProcessing = false
        this.deleteModal.show = false
      }
    },

    onRoleChange(member, role) {
      this.updateMemberData(member.member_id, { role }, 'role')
    },

    onHourlyRateChange (member, hourlyRate) {
      this.updateMemberData(member.member_id, { hourly_rate: hourlyRate || 0 }, 'hourlyRate')
    },

    async updateMemberData(memberId, data, field) {
      try {
        await this.updateMember({ memberId, data })

        this.$notify({
          title: this.$t('common.changesSaved'),
          message: this.$t(`settings.members.notifications.${field}`),
          position: 'bottom-right',
          type: 'success'
        })
      } catch (e) {
        this.$notify({
          title: this.$t('common.error'),
          message: this.$t('settings.members.notifications.failed'),
          position: 'bottom-right',
          type: 'error'
        })
      }
    },

    onTabChange (tab) {
      this.selectedTab = tab
    },

    getMembersCount (status) {
      if (status === 'invited') {
        return this.invitedMembers.length
      }

      return this.activeMembers.length
    },

    projectName (code) {
      const project = this.allProjects.find((project) => project.code === code)

      return project?.name || ''
    }
  },

  async created () {
    if (!this.isAdmin) {
      this.$router.push({ name: 'workspace.member.settings', params: { project: this.project.code} })
    }

    await Promise.all([
      this.fetchWorkspaceMembers(),
      this.fetchAllProjects(),
    ])
  }
}
</script>
<style lang="sass">
  .empty-members
    margin-top: 32px
    display: flex
    justify-content: center
    color: var(--text-light-color)

  .ui-button.dots-btn
    height: 32px

  .member-projects-dp
    display: flex
    justify-content: space-between
    align-items: center
    column-gap: 16px

    &__items
      display: flex
      flex-flow: row wrap
      column-gap: 8px
      row-gap: 8px
</style>

<template>
  <div class="sidebar-chats" v-if="sidebarChannels.length > 0">
    <el-tooltip
      v-for="channel in sidebarChannels"
      :key="channel.id"
      popper-class="sidebar-menu__tooltip"
      effect="dark"
      :content="channelTitle(channel)"
      placement="right"
      transition="sw-slide-right"
      :hide-after="0"
      :open-delay="200"
    >
    <div
      class="sidebar-chat"
      @click="goToChannel(channel.id)"
    >
      <channel-photo :channel="channel" :show-unseen="true" photo-size="chat" class="sidebar-chat__photo"/>
    </div>
    </el-tooltip>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex"

import ChannelPhoto from "@/components/messenger/ChannelPhoto"
import channelHelpers from "@/components/messenger/channelHelpers"

const { mapActions: messengerActions, mapGetters: messengerGetters } = createNamespacedHelpers('messenger')

export default {
  name: 'SidebarChats',

  components: { ChannelPhoto },

  mixins: [channelHelpers],

  props: {
  },

  data: function () {
    return {
    }
  },

  computed: {
    ...messengerGetters(['sidebarChannels'])
  },

  methods: {
    ...messengerActions(['fetchChannels']),

    goToChannel (channelId) {
      const targetId = channelId === this.authUser.helpdesk_channel_id
        ? 'helpdesk'
        : channelId
      this.$router.push({ name : 'messenger.channel', params: { channelId: targetId }})
    },

    multiChannelClasses (channel) {
      const styleId = channel.member_id % 6 + 1

      return [`channel-item__multi-photo_style-${styleId}`]
    }
  },

  created () {
    this.fetchChannels()
  }
}
</script>
<template>
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" d="M15.75 7.44013V2.625C15.75 2.39294 15.6578 2.17038 15.4937 2.00628C15.3296 1.84219 15.1071 1.75 14.875 1.75H2.625C2.39294 1.75 2.17038 1.84219 2.00628 2.00628C1.84219 2.17038 1.75 2.39294 1.75 2.625V11.375C1.75 11.6071 1.84219 11.8296 2.00628 11.9937C2.17038 12.1578 2.39294 12.25 2.625 12.25H5.691L9.53225 5.84325C9.73726 5.5015 10.0174 5.21091 10.3514 4.99353C10.6854 4.77614 11.0645 4.63766 11.46 4.58858C11.8555 4.5395 12.257 4.58112 12.634 4.71027C13.011 4.83942 13.3537 5.05272 13.636 5.334L15.75 7.44013ZM15.75 9.91025L12.4005 6.573C12.3063 6.47934 12.1921 6.40834 12.0664 6.36539C11.9407 6.32244 11.8069 6.30866 11.6751 6.32511C11.5433 6.34155 11.417 6.38778 11.3057 6.46029C11.1944 6.5328 11.1011 6.6297 11.0329 6.74363L7.7315 12.25H14.875C15.1071 12.25 15.3296 12.1578 15.4937 11.9937C15.6578 11.8296 15.75 11.6071 15.75 11.375V9.91025ZM2.625 0H14.875C15.5712 0 16.2389 0.276562 16.7312 0.768845C17.2234 1.26113 17.5 1.92881 17.5 2.625V11.375C17.5 12.0712 17.2234 12.7389 16.7312 13.2312C16.2389 13.7234 15.5712 14 14.875 14H2.625C1.92881 14 1.26113 13.7234 0.768845 13.2312C0.276562 12.7389 0 12.0712 0 11.375V2.625C0 1.92881 0.276562 1.26113 0.768845 0.768845C1.26113 0.276562 1.92881 0 2.625 0V0ZM5.25 7.875C4.55381 7.875 3.88613 7.59844 3.39384 7.10616C2.90156 6.61387 2.625 5.94619 2.625 5.25C2.625 4.55381 2.90156 3.88613 3.39384 3.39384C3.88613 2.90156 4.55381 2.625 5.25 2.625C5.94619 2.625 6.61387 2.90156 7.10616 3.39384C7.59844 3.88613 7.875 4.55381 7.875 5.25C7.875 5.94619 7.59844 6.61387 7.10616 7.10616C6.61387 7.59844 5.94619 7.875 5.25 7.875ZM5.25 6.125C5.48206 6.125 5.70462 6.03281 5.86872 5.86872C6.03281 5.70462 6.125 5.48206 6.125 5.25C6.125 5.01794 6.03281 4.79538 5.86872 4.63128C5.70462 4.46719 5.48206 4.375 5.25 4.375C5.01794 4.375 4.79538 4.46719 4.63128 4.63128C4.46719 4.79538 4.375 5.01794 4.375 5.25C4.375 5.48206 4.46719 5.70462 4.63128 5.86872C4.79538 6.03281 5.01794 6.125 5.25 6.125Z" fill="#333840"/>
  </svg>
</template>
<script>
import iconMixin from '../iconMixin'

export default {
  name: 'ImageIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

<template>
  <div>
    <el-popover
      placement="bottom"
      trigger="manual"
      popper-class="ui-dropdown-ex ui-dropdown-ex_no-padding"
      :value="isOpen"
      ref="popper"
    >
      <div class="parent-selector-list">
        <div class="parent-selector-section">
          Проекты
        </div>

        <div
          v-for="project in projects"
          :key="project.code"
        >
          <parent-selector-item :project="project" @select="(parents) => onSelect({ project, parents })"/>
        </div>
      </div>

      <div class="pages-crumbs pages-crumbs_selector" :class="referenceClasses" slot="reference" ref="reference" @click="onClickReference">
        <div class="pages-crumbs-item">
          <dashboard-icon class="pages-crumbs-item__home"/>

          <div class="pages-crumbs-item__label">
            {{ projectName }}
          </div>
        </div>

        <template v-if="innerParents.length > 0">
          <div class="pages-crumbs-div">
            /
          </div>

          <div class="pages-crumbs-item">
            <div class="pages-crumbs-item__label">
              ...
            </div>
          </div>
        </template>

        <template v-if="firstParent">
          <div class="pages-crumbs-div">
            /
          </div>

          <div class="pages-crumbs-item">
            <div class="pages-crumbs-item__label">
              {{ firstParent.icon.value }} {{ firstParent.name }}
            </div>
          </div>
        </template>
      </div>
    </el-popover>
  </div>
</template>
<script>
  import DashboardIcon from '@/components/icons/DashboardIcon'
  import breadcrumbs from '@/components/pages/mixins/breadcrumbs'
  import ParentSelectorItem from '@/components/pages/ParentSelectorItem'
  import manualDropdown from '@/mixins/manualDropdown'
  import {mapActions} from 'vuex';

  export default {
    name: 'ParentSelector',

    components: {ParentSelectorItem, DashboardIcon},

    mixins: [breadcrumbs, manualDropdown],

    props: {
      page: {
        type: Object,
        required: true,
      }
    },

    computed: {
      referenceClasses () {
        const classes = []

        if (this.isOpen) {
          classes.push('pages-crumbs_selector_active')
        }

        return classes
      }
    },

    methods: {
      ...mapActions('pages', ['updatePage']),

      onSelect ({ project, parents }) {
        const parent = parents.length > 0
          ? parents[parents.length - 1].code
          : null

        this.closeDropdown()
        this.updatePage({
          code: this.page.code,
          formData: {
            project: project?.code,
            parent,
            parents,
          }
        })
      }
    },
  }
</script>
<style lang="sass">
  .parent-selector-list
    padding: 8px 4px 4px
    width: 260px
    box-sizing: border-box

  .parent-selector-section
    color: var(--grey-color-100)
    font-size: 12px
    font-weight: 500
    text-transform: uppercase
    margin-bottom: 8px
    padding: 0 12px
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import socketService from "@/services/socket"
import languageService from "@/services/language"

import * as authModule from './modules/auth'
import * as workspaceModule from './modules/workspace'
import * as tasksModule from './modules/tasks'
import createSpheresModule from './modules/spheres'
import * as taskModule from './modules/task'
import * as taskStatsModule from './modules/taskStats'
import createStagesModule from './modules/stages'
import * as messengerModule from './modules/messenger'
import * as notificationsModule from './modules/notifications'
import createSprintsModule from '@/store/modules/sprints'
import * as workloadModule from '@/store/modules/workload'
import * as goalsProgressModule from '@/store/modules/goalsProgress'
import * as archiveModule from '@/store/modules/archive'
import * as dashboardModule from '@/store/modules/dashboard'
import * as projectModule from '@/store/modules/project'
import * as workspaceMembersModule from '@/store/modules/members'
import * as reportModule from '@/store/modules/report'
import * as pagesModule from '@/store/modules/pages'
import createSubscriptionModule from '@/store/modules/subscription'
import createTourModule from './modules/tour'

import messengerSocket from "@/store/socket/messenger"

Vue.use(Vuex)

function createStore(languageService) {
  const store = new Vuex.Store({ strict: true })

  store.registerModule('auth', authModule)
  store.registerModule('workspace', workspaceModule)
  store.registerModule('projects', projectModule)
  store.registerModule('tasks', tasksModule)
  store.registerModule('task', taskModule)
  store.registerModule('spheres', createSpheresModule(languageService))
  store.registerModule('stages', createStagesModule(languageService))
  store.registerModule('messenger', messengerModule)
  store.registerModule('notifications', notificationsModule)
  store.registerModule('sprints', createSprintsModule(languageService))
  store.registerModule('tour', createTourModule())
  store.registerModule('workload', workloadModule)
  store.registerModule('goalsProgress', goalsProgressModule)
  store.registerModule('archive', archiveModule)
  store.registerModule('taskStats', taskStatsModule)
  store.registerModule('dashboard', dashboardModule)
  store.registerModule('workspaceMembers', workspaceMembersModule)
  store.registerModule('report', reportModule)
  store.registerModule('subscription', createSubscriptionModule())
  store.registerModule('pages', pagesModule)

  socketService.addSubscriber(messengerSocket(store))

  return store
}

const store = createStore(languageService)

export default store

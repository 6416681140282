<template>
  <div v-if="photoSrc" :class="photoClass" @click="$emit('click')">
    <img :src="photoSrc" class="user-photo__img">
    <slot name="hover_content"></slot>
    <div class="user-photo__online" v-if="isOnline"></div>
  </div>
  <div v-else :class="photoClass" @click="$emit('click')">
    {{ shortName }}
    <slot name="hover_content"></slot>
    <div class="user-photo__online" v-if="isOnline"></div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  const photoStylesCount = 6

  export default {
    name: 'UserPhoto',

    props: {
      user: {
        type: Object,
        required: true
      },

      name: {
        type: String
      },

      size: {
        type: String,
        default: 'sm'
      },

      showOnline: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      ...mapGetters(['authUser']),

      isOnline () {
        if (!this.user) {
          return false
        }

        return this.showOnline && this.user.isOnline
      },

      shortName () {
        if (this.name !== undefined) {
          return this.name
        }

        if (!this.user.member_id) {
          return '?'
        }

        if (!this.user.first_name && !this.user.last_name) {
          const upperEmail = this.user.email.toUpperCase()

          return `${upperEmail[0]}${upperEmail[1]}`
        }

        const upperName = this.user.first_name.toUpperCase()
        const upperLastName = this.user.last_name
          ? this.user.last_name.toUpperCase()
          : upperName

        return `${upperName[0]}${upperLastName[0]}`
      },

      photoClass () {
        const classes = ['user-photo', 'user-photo_size_' + this.size]

        if (!this.user || !this.photoSrc) {
          classes.push('user-photo_no-photo')

          let styleNumber = 2
          if (this.user) {
            const memberId = this.user.member_id || this.authUser.member_id
            styleNumber = Math.abs(memberId) % photoStylesCount + 1
          }

          classes.push(`user-photo_style-${styleNumber}`)
        }

        return classes
      },

      photoSrc () {
        if (!this.user) {
          return null
        }

        switch (this.size) {
          case 'xs':
          case 'sm':
          case 'md':
          case 'chat':
            return this.user.photo_sm

          case 'lg':
          case 'big':
          case 'xmd':
            return this.user.photo_lg
        }

        return ''
      }
    }
  }
</script>
<style lang="sass">
.user-photo
  position: relative
  cursor: default

  &_size_xs
    width: 20px
    height: 20px
    border-radius: 6px

    .user-photo__img
      width: 20px
      height: 20px
      border-radius: 6px

  &_size_xxs
    width: 18px
    height: 18px
    border-radius: 6px

    .user-photo__img
      width: 18px
      height: 18px
      border-radius: 6px

  &_size_sm
    width: 24px
    height: 24px
    border-radius: 8px

    .user-photo__img
      width: 24px
      height: 24px
      border-radius: 8px

  &_size_md
    width: 32px
    height: 32px
    border-radius: 12px

    .user-photo__img
      width: 32px
      height: 32px
      border-radius: 12px

  &_size_chat
    width: 36px
    height: 36px
    border-radius: 12px

    .user-photo__img
      width: 36px
      height: 36px
      border-radius: 12px

  &_size_xmd
    width: 44px
    height: 44px
    border-radius: 10px

    .user-photo__img
      width: 44px
      height: 44px
      border-radius: 10px

  &_size_big, &__img
    width: 64px
    height: 64px
    border-radius: 20px

    .user-photo__img
      width: 64px
      height: 64px
      border-radius: 20px

    .user-photo__online
      border-radius: 6px
      height: 11px
      width: 11px

  &_size_lg, &__img
    width: 100px
    height: 100px
    border-radius: 34px

  &_no-photo
    color: #ffffff
    display: flex
    justify-content: center
    align-items: center
    font-weight: 500

  &_style-1
    color: var(--avatar-blue-color)
    background: var(--avatar-blue-bg)

  &_style-2
    color: var(--avatar-azure-color)
    background: var(--avatar-azure-bg)

  &_style-3
    color: var(--avatar-green-color)
    background: var(--avatar-green-bg)

  &_style-4
    color: var(--avatar-orange-color)
    background: var(--avatar-orange-bg)

  &_style-5
    color: var(--avatar-coral-color)
    background: var(--avatar-coral-bg)

  &_style-6
    color: var(--avatar-salmon-color)
    background: var(--avatar-salmon-bg)

  &__online
    width: 7px
    height: 7px
    border: 1px solid #FFFFFF
    background: #46C93A
    border-radius: 4px
    position: absolute
    right: 0
    bottom: 0

.user-photo_no-photo.user-photo_size_xxs
  font-size: 10px
  font-weight: 600

.user-photo_no-photo.user-photo_size_xs
  font-size: 10px
  font-weight: 600

.user-photo_no-photo.user-photo_size_sm
  font-size: 11px

.user-photo_no-photo.user-photo_size_md
  font-size: 13px

.user-photo_no-photo.user-photo_size_big
  font-size: 20px

@media (max-width: 1280px)
  .user-photo
    &_size_chat
      width: 36px
      height: 36px
      border-radius: 10px

      .user-photo__img
        width: 36px
        height: 36px
        border-radius: 10px
</style>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.1067 4.20728L14.493 21.1266C13.8519 22.5513 11.8071 22.4857 11.2586 21.0229L9.2793 15.7448C9.10184 15.2716 8.72843 14.8982 8.25519 14.7207L2.97708 12.7414C1.51428 12.1929 1.44875 10.1481 2.87341 9.50696L19.7927 1.89327C21.2614 1.23238 22.7676 2.73864 22.1067 4.20728Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'SendIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 10,
        defaultHeight: 10
      }
    }
  }
</script>

<template>
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" d="M0.777778 12.4444C0.571498 12.4444 0.373668 12.5264 0.227806 12.6722C0.0819444 12.8181 0 13.0159 0 13.2222C0 13.4285 0.0819444 13.6263 0.227806 13.7722C0.373668 13.9181 0.571498 14 0.777778 14H11.6667C11.8729 14 12.0708 13.9181 12.2166 13.7722C12.3625 13.6263 12.4444 13.4285 12.4444 13.2222C12.4444 13.0159 12.3625 12.8181 12.2166 12.6722C12.0708 12.5264 11.8729 12.4444 11.6667 12.4444H0.777778ZM10.0722 6.45322C9.99999 6.38091 9.91421 6.32354 9.81979 6.2844C9.72537 6.24526 9.62416 6.22511 9.52194 6.22511C9.41973 6.22511 9.31852 6.24526 9.2241 6.2844C9.12968 6.32354 9.0439 6.38091 8.97167 6.45322L7 8.42567V0.777778C7 0.571498 6.91806 0.373667 6.77219 0.227806C6.62633 0.0819442 6.4285 0 6.22222 0C6.01594 0 5.81811 0.0819442 5.67225 0.227806C5.52639 0.373667 5.44444 0.571498 5.44444 0.777778V8.42567L3.472 6.45322C3.32606 6.30738 3.12816 6.22549 2.92184 6.22556C2.71552 6.22564 2.51767 6.30767 2.37183 6.45361C2.22599 6.59955 2.1441 6.79745 2.14418 7.00377C2.14425 7.2101 2.22628 7.40794 2.37222 7.55378L5.67233 10.8531C5.81819 10.9989 6.01598 11.0808 6.22222 11.0808C6.42846 11.0808 6.62626 10.9989 6.77211 10.8531L10.0722 7.55378C10.1445 7.48154 10.2019 7.39576 10.241 7.30134C10.2802 7.20692 10.3003 7.10571 10.3003 7.0035C10.3003 6.90129 10.2802 6.80008 10.241 6.70566C10.2019 6.61124 10.1445 6.52546 10.0722 6.45322Z" fill="#27272E"/>
  </svg>
</template>
<script>
import iconMixin from '../iconMixin'

export default {
  name: 'ArrowDownLineIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

<template>
  <div class="goal-column" :style="columnStyles">
    <div class="goal-column__header" :class="goalClasses">
      <div class="goal-column__header-title">
        <div class="goal-column__title">
          {{ goal.name }}
        </div>

        <div class="goal-column__more">
          <el-popover
            placement="bottom-start"
            trigger="click"
            ref="popper"
            :visible-arrow="false"
            :width="200"
            transition="sw-slide-up"
            popper-class="ui-dropdown-ex ui-dropdown-ex_no-padding"
          >
            <div class="ui-dropdown__option-list">
              <div class="ui-dropdown__option" @click="onEditGoal">
                {{ $t('common.edit') }}
              </div>
              <div class="ui-dropdown__option" @click="onGoalComplete">
                {{ $t('goals.closeTheGoal') }}
              </div>
            </div>
            <div class="target-item__opt" slot="reference">
              <more-icon/>
            </div>
          </el-popover>
        </div>
      </div>

      <div class="goal-column__info">

        <progress-data
          class="goal-column__progress"
          :total-count="totalTasksCount"
          :finished-count="totalFinishedCount"
          :estimated-finish-at="estimatedFinishAt"
        />
        <div class="goal-column__members">
        </div>
      </div>
    </div>

    <div class="goal-column__actions">
      <div class="goal-column__display-option">
        <template v-if="!isGoalComplete">
          <ui-dropdown
              class="workload-filters__item"
              :options="displayOptions"
              key-field="id"
              :value="displayOption"
              reference-class="workload-filters__selector goal-column__filter-selector"
              popover-class="ui-dropdown-ex"
              placement="bottom-start"
              :width-by-popover="false"
              @input="onDisplayChange"
          >
            {{ $t(`goalsProgress.displayOptions.${displayOption}`) }}
            <template v-slot:option="{ option }">
              {{ $t(`goalsProgress.displayOptions.${option.id}`) }}
            </template>
          </ui-dropdown>

          <el-tooltip
              effect="dark"
              :content="eyeTooltipMessage"
              placement="top"
              transition="sw-slide-top"
              v-if="displayOption === 'bySpheres'"
          >
            <span @click="showActiveSpheres = !showActiveSpheres" class="goal-column__eye">
              <component :is="eyeIconComponent"/>
            </span>
          </el-tooltip>
        </template>
      </div>

      <div>
        <ui-button ui-class="light size_sm" @click="onCreateTask">
          <plus class="icon-round-plus"/> {{ $t('tasks.newTaskBtn') }}
        </ui-button>
      </div>
    </div>

    <div class="goal-column__spheres">
      <div
        v-if="isGoalComplete"
        class="goal-column__tasks-done"
      >
        {{ $t('goalsProgress.allTasksCompleted') }}

        <div class="goal-column__tasks-done-btn">
          <ui-button ui-class="light size_md" @click="onGoalComplete">
            {{ $t('goals.closeTheGoal') }}
          </ui-button>
        </div>
      </div>

      <div
        v-if="!isGoalComplete && tasks.length === 0"
        class="goal-column__tasks-done"
      >
        {{ $t('goalsProgress.noTasksCreated') }}

        <div class="goal-column__tasks-done-btn">
          <ui-button ui-class="light size_md" @click="onCreateTask">
            <plus class="mr-2"/> {{ $t('workload.createNewTask') }}
          </ui-button>
        </div>
      </div>

      <template v-if="displayOption === 'bySpheres'">
        <goal-sphere-column
          v-for="sphere in goalSpheres"
          :key="sphere.id"
          :tasks="getSphereTasks(sphere.id)"
          :sphere="sphere"
          :finished-count="getFinishedCount(sphere.id)"
          @create-task="onCreateTask"
          @open-task="onOpenTask"
        />
      </template>
      <template v-else>
        <goal-sphere-column
          :key="0"
          v-if="tasks.length"
          :tasks="tasks"
          :sphere="allTasksSphere"
          :finished-count="totalFinishedCount"
          @create-task="onCreateTask"
          @open-task="onOpenTask"
        />
      </template>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  import UiButton from 'scorework-ui/src/components/UiButton'
  import EyeIcon from '@/components/icons/EyeIcon'
  import HideEyeIcon from '@/components/icons/HideEyeIcon'
  import MoreIcon from '@/components/icons/MoreIcon'
  import Plus from '@/components/icons/Plus'
  import ProgressData from '@/components/goalsProgress/ProgressData'
  import GoalSphereColumn from '@/components/goalsProgress/GoalSphereColumn'
  import UiDropdown from '@/components/tasks/UiDropdown'

  import { lcFirst } from '@/utils/dataTypes';
  import { getEstimatedTime } from '@/services/scoring';

  const goalUnitWidth = 324

  export default {
    name: 'GoalColumn',

    components: {
      EyeIcon, HideEyeIcon, GoalSphereColumn, ProgressData, UiDropdown,
      UiButton, Plus, MoreIcon
    },

    props: {
      goal: {
        type: Object,
        required: true,
      },

      tasks: {
        type: Array,
        required: true,
      },

      finished: {
        type: Array,
        required: true,
      }
    },

    data () {
      return {
        displayOptions: [
          { id: 'bySpheres' },
          { id: 'showAll' },
        ],

        displayOption: 'bySpheres',
        showActiveSpheres: true,

        allTasksSphere: {
          id: 0,
          name: 'Все задачи'
        }
      }
    },

    computed: {
      ...mapGetters(['spheres']),

      goalSpheres () {
        const res = []
        const finishedSpheres = this.finished.map(item => item.sphere_id)

        let emptySphereCondition = this.tasks.findIndex(item => !item.sphere) !== -1
        if (!this.showActiveSpheres) {
          emptySphereCondition = emptySphereCondition || finishedSpheres.indexOf(0) !== -1
        }

        if (emptySphereCondition) {
          res.push({
            id: 0,
            name: 'Без сферы',
          })
        }

        const otherSpheres = this.spheres.filter(sphere => {
          let condition = this.tasks.findIndex(item => item.sphere?.id === sphere.id) !== -1
          if (!this.showActiveSpheres) {
            condition = condition || finishedSpheres.indexOf(sphere.id) !== -1
          }

          return condition
        })

        res.push(...otherSpheres)

        return res
      },

      columnStyles () {
        let count = this.goalSpheres.length
        if (count < 1 || this.displayOption !== 'bySpheres') {
          count = 1
        }

        const width = 24 + goalUnitWidth * count

        return {
          flex: `0 0 ${width}px`,
          minWidth: `${width}px`
        }
      },

      totalFinishedCount () {
        return this.finished.reduce((sum, item) => sum + item.tasks_count, 0)
      },

      totalTasksCount () {
        return this.tasks.length + this.totalFinishedCount
      },

      estimatedFinishAt () {
        let label = ''
        if (this.tasks.length === 0) {
          label = this.$t('tasks.unknown')
        } else {
          const lastTask = this.tasks[this.tasks.length - 1]
          label = getEstimatedTime(lastTask.estimatedFinishAt)
        }

        return lcFirst(label)
      },

      eyeIconComponent () {
        return this.showActiveSpheres ? 'eye-icon' : 'hide-eye-icon'
      },

      eyeTooltipMessage () {
        return this.showActiveSpheres ? this.$t('goalsProgress.eye.showAll') : this.$t('goalsProgress.eye.showActive')
      },

      goalClasses () {
        return `target-item_${this.goal.color}`
      },

      isGoalComplete () {
        return this.tasks.length === 0 && this.totalFinishedCount > 0
      }
    },

    methods: {
      getSphereTasks (sphereId) {
        return this.tasks.filter(task => {
          if (sphereId === 0) {
            return !task.sphere
          }

          return task.sphere?.id === sphereId
        })
      },

      getFinishedCount (sphereId) {
        const item = this.finished.find(item => item.sphere_id === sphereId)
        if (!item) {
          return 0
        }

        return item.tasks_count
      },

      onDisplayChange ({ id }) {
        this.displayOption = id
      },

      onCreateTask (fields) {
        this.$emit('create-task', {
          ...fields,
          targetId: this.goal.id,
        })
      },

      onOpenTask (id) {
        this.$emit('open-task', id)
      },

      onEditGoal () {
        this.$emit('goal-edit', this.goal.id)
      },

      onGoalComplete () {
        this.$emit('goal-complete', this.goal)
      }
    }
  }
</script>
<style lang="sass">
  .goal-column
    display: flex
    flex-flow: column nowrap

    &__header
      background: var(--block-bg-color)
      box-sizing: border-box
      padding: 18px 24px
      margin: 12px 12px 12px
      border-radius: 8px
      box-shadow: var(--block-shadow)

      &.target-item_blue
        .ui-progress-bar__fill
          background: var(--color-blue-dots)

      &.target-item_lavender
        .ui-progress-bar__fill
          background: var(--color-lavender-dots)

      &.target-item_purple
        .ui-progress-bar__fill
          background: var(--color-purple-dots)

      &.target-item_fuchsia
        .ui-progress-bar__fill
          background: var(--color-fuchsia-dots)

      &.target-item_yellow
        .ui-progress-bar__fill
          background: var(--color-yellow-dots)

      &.target-item_aquamarine
        .ui-progress-bar__fill
          background: var(--color-aquamarine-dots)

      &.target-item_green
        .ui-progress-bar__fill
          background: var(--color-green-dots)

      &.target-item_orange
        .ui-progress-bar__fill
          background: var(--color-orange-dots)

      &.target-item_red
        .ui-progress-bar__fill
          background: var(--color-red-dots)

      &-title
        align-items: baseline
        display: flex
        justify-content: space-between

    &__title
      font-size: 16px
      font-weight: 500
      margin-bottom: 20px
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
      flex: 1

    &__more
      margin-left: 6px

    &__info
      display: flex
      justify-content: space-between

    &__members
      align-items: center
      display: flex

    &__progress
      width: 300px

      .ui-progress-bar
        background: var(--block-bg-color)

    &__actions
      align-items: center
      display: flex
      justify-content: space-between
      margin: 0 12px 24px

    &__display-option
      align-items: center
      display: flex

    &__filter-selector
      &.ui-dropdown__reference, &.ui-dropdown__reference:hover
        font-size: 13px
        padding: 6px 12px

    &__eye
      cursor: pointer
      height: 18px

      .path-fill
        fill: var(--text-light-color)

    &__spheres
      display: flex
      flex: 1
      border-right: 1px solid var(--block-border-bg)
      padding: 0 12px
      min-height: 0

    &__tasks-done
      text-align: center
      color: var(--text-light-color)
      line-height: 1.5
      padding: 24px

      &-btn
        margin-top: 16px
        display: flex
        justify-content: center
</style>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5953 10.2003C12.7389 9.81659 12.8118 9.41002 12.8103 9.00031C12.8103 8.09062 12.4489 7.21819 11.8057 6.57494C11.1624 5.93169 10.29 5.57031 9.38031 5.57031C8.97566 5.57079 8.57434 5.6436 8.19531 5.78531L9.00031 6.61531C9.12268 6.59573 9.24639 6.5857 9.37031 6.58531C10.0135 6.58397 10.6312 6.83718 11.0883 7.28966C11.5455 7.74214 11.805 8.35712 11.8103 9.00031C11.8099 9.12424 11.7999 9.24794 11.7803 9.37031L12.5953 10.2003Z" fill="black" class="path-fill"/>
    <path d="M17.1452 8.76562C15.4602 5.65062 12.5052 3.76562 9.23523 3.76562C8.34491 3.76772 7.46057 3.91117 6.61523 4.19062L7.42023 5.00062C8.01316 4.84739 8.62283 4.76845 9.23523 4.76562C12.0502 4.76562 14.6102 6.33562 16.1352 8.98062C15.5758 9.96193 14.8342 10.8274 13.9502 11.5306L14.6602 12.2406C15.6833 11.4153 16.5329 10.3957 17.1602 9.24062L17.2902 9.00062L17.1452 8.76562Z" fill="black" class="path-fill"/>
    <path d="M2.43445 2.88953L4.66445 5.11953C3.25491 6.02709 2.1029 7.28242 1.31945 8.76453L1.18945 8.99953L1.31945 9.23953C3.00445 12.3545 5.95945 14.2395 9.22945 14.2395C10.5058 14.2393 11.7655 13.9504 12.9145 13.3945L15.4145 15.8945L16.2895 15.1445L3.28945 2.14453L2.43445 2.88953ZM7.30945 7.76453L10.6345 11.0895C10.2588 11.3221 9.82628 11.4466 9.38445 11.4495C9.06342 11.4495 8.74555 11.3861 8.44914 11.2628C8.15273 11.1394 7.88363 10.9587 7.65732 10.731C7.43102 10.5033 7.25197 10.2331 7.13048 9.93597C7.009 9.63881 6.94748 9.32056 6.94945 8.99953C6.95509 8.56281 7.07954 8.13587 7.30945 7.76453V7.76453ZM6.58445 7.03953C6.11667 7.69923 5.89742 8.50318 5.96551 9.30903C6.03361 10.1149 6.38466 10.8706 6.95651 11.4425C7.52836 12.0143 8.28411 12.3654 9.08996 12.4335C9.89581 12.5016 10.6998 12.2823 11.3595 11.8145L12.1595 12.6145C11.2333 13.0104 10.2366 13.2145 9.22945 13.2145C6.41445 13.2145 3.85445 11.6445 2.32945 8.99953C3.06131 7.70364 4.11532 6.61863 5.38945 5.84953L6.58445 7.03953Z" fill="black" class="path-fill"/>
  </svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'HideEyeIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>


<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2933 10.2925C22.4808 10.1052 22.735 10 23 10C23.265 10 23.5192 10.1052 23.7067 10.2925L28.7067 15.2925C28.8939 15.48 28.9991 15.7341 28.9991 15.9991C28.9991 16.2641 28.8939 16.5183 28.7067 16.7058L23.7067 21.7058C23.6151 21.804 23.5047 21.8828 23.3821 21.9375C23.2594 21.9922 23.127 22.0215 22.9927 22.0239C22.8584 22.0263 22.7251 22.0016 22.6005 21.9513C22.476 21.901 22.3629 21.8261 22.2679 21.7312C22.173 21.6362 22.0981 21.5231 22.0478 21.3986C21.9975 21.2741 21.9728 21.1407 21.9752 21.0064C21.9776 20.8722 22.007 20.7397 22.0616 20.6171C22.1163 20.4944 22.1951 20.384 22.2933 20.2925L25.5867 16.9991H4C3.73478 16.9991 3.48043 16.8938 3.29289 16.7062C3.10536 16.5187 3 16.2643 3 15.9991C3 15.7339 3.10536 15.4796 3.29289 15.292C3.48043 15.1045 3.73478 14.9991 4 14.9991H25.5867L22.2933 11.7058C22.1061 11.5183 22.0009 11.2641 22.0009 10.9991C22.0009 10.7341 22.1061 10.48 22.2933 10.2925V10.2925Z" fill="#27272E" class="path-fill"/>
  </svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'RoundPlus',

  mixins: [iconMixin],

  props: {
    circleBackground: {
      type: String,
      default: '#416AF7'
    },

    circleOpacity: {
      type: Number,
      default: 0.1
    }
  },

  data () {
    return {
      defaultBackground: '#416AF7',
      defaultWidth: 22,
      defaultHeight: 22
    }
  },

  computed: {
    plusWidth () {
      return this.iconWidth / 2
    },

    plusHeight () {
      return this.iconHeight / 2
    }
  }
}
</script>

<template>
  <ui-form :model="form" :rules="rules" :submit-fn="submitFn" ref="form">
    <div class="form-control">
      <work-schedule-selector v-model="form.work_schedule[0]"/>
    </div>

    <div style="margin-top: 32px;">
      <ui-button @click.prevent="onSubmit">{{ $t('common.save') }}</ui-button>
    </div>
  </ui-form>
</template>
<script>
import UiForm from 'scorework-ui/src/components/form/UiForm'
import UiButton from 'scorework-ui/src/components/UiButton'
import {mapActions, mapGetters} from "vuex";
import { handleAsyncErrors } from 'scorework-ui/src/components/form/helpers'
import WorkScheduleSelector from "@/components/ui/WorkScheduleSelector"

export default {
  name: 'WorkScheduleForm',

  components: { WorkScheduleSelector, UiButton, UiForm },

  data () {
    return {
      form: {
        work_schedule: [],
      },

      rules: {}
    }
  },

  computed: {
    ...mapGetters(['authUser'])
  },

  methods: {
    ...mapActions(['updateMemberProfile']),

    submitFn () {
      this.updateMemberProfile({ ...this.form })
          .then(() => {
            this.$notify({
              title: this.$t('common.saved'),
              message: this.$t('pages.profileSaved'),
              position: 'bottom-right',
              type: 'success'
            })
          })
          .catch(handleAsyncErrors(this.rules, () => this.$refs.form.validate()))
    },

    onSubmit () {
      this.$refs.form.submit()
    },

    onSphereChange (val) {
      this.form.spheres = val
    },
  },

  created () {
    const { work_schedule: workSchedule } = this.authUser

    this.form = {
      work_schedule: [
        {
          days: [...workSchedule[0].days],
          work_hours: {...workSchedule[0].work_hours}
        }
      ],
    }
  }
}
</script>

<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="path-stroke" d="M31 20V6C31 4.34315 29.6569 3 28 3H4C2.34315 3 1 4.34315 1 6V26C1 27.6569 2.34315 29 4 29H20" stroke="#6283FF" stroke-width="2"/>
  <path class="path-stroke" d="M7 5L7 1" stroke="#6283FF" stroke-width="2" stroke-linecap="round"/>
  <path class="path-stroke" d="M2 8L31 8" stroke="#6283FF" stroke-width="2" stroke-linecap="round"/>
  <path class="path-stroke" d="M25 5L25 1" stroke="#6283FF" stroke-width="2" stroke-linecap="round"/>
  <circle class="path-stroke" cx="29.5" cy="29.5" r="9.5" stroke="#6283FF" stroke-width="2"/>
  <path class="path-stroke" d="M26 25H33" stroke="#6283FF" stroke-width="2" stroke-linecap="round"/>
  <path class="path-stroke" d="M26 34H33" stroke="#6283FF" stroke-width="2" stroke-linecap="round"/>
  <path class="path-stroke" d="M27 27.2929V25.5C27 25.2239 27.2239 25 27.5 25H31.5C31.7761 25 32 25.2239 32 25.5V27.2929C32 27.4255 31.9473 27.5527 31.8536 27.6464L30.3536 29.1464C30.1583 29.3417 30.1583 29.6583 30.3536 29.8536L31.8536 31.3536C31.9473 31.4473 32 31.5745 32 31.7071V33.5C32 33.7761 31.7761 34 31.5 34H27.5C27.2239 34 27 33.7761 27 33.5V31.7071C27 31.5745 27.0527 31.4473 27.1464 31.3536L28.6464 29.8536C28.8417 29.6583 28.8417 29.3417 28.6464 29.1464L27.1464 27.6464C27.0527 27.5527 27 27.4255 27 27.2929Z" stroke="#6283FF" stroke-width="2" stroke-linecap="round"/>
  <circle class="path-fill" cx="8.5" cy="13.5" r="1.5" fill="#6283FF"/>
  <circle class="path-fill" cx="8.5" cy="18.5" r="1.5" fill="#6283FF"/>
  <circle class="path-fill" cx="8.5" cy="23.5" r="1.5" fill="#6283FF"/>
  <circle class="path-fill" cx="13.5" cy="13.5" r="1.5" fill="#6283FF"/>
  <circle class="path-fill" cx="13.5" cy="18.5" r="1.5" fill="#6283FF"/>
  <circle class="path-fill" cx="13.5" cy="23.5" r="1.5" fill="#6283FF"/>
  <circle class="path-fill" cx="18.5" cy="23.5" r="1.5" fill="#6283FF"/>
  <circle class="path-fill" cx="18.5" cy="13.5" r="1.5" fill="#6283FF"/>
  <circle class="path-fill" cx="18.5" cy="18.5" r="1.5" fill="#6283FF"/>
  <circle class="path-fill" cx="23.5" cy="18.5" r="1.5" fill="#6283FF"/>
  <circle class="path-fill" cx="23.5" cy="13.5" r="1.5" fill="#6283FF"/>
</svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'TasksDateIcon',

  mixins: [iconMixin],

  props: {
    viewBox: {
      type: String,
      default: '0 0 12 12'
    }
  },

  data () {
    return {
      defaultBackground: '#416AF7',
      defaultWidth: 12,
      defaultHeight: 12
    }
  }
}
</script>

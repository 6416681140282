<template>
  <el-dialog
    :title="$t('tasks.anotherTaskInWork')"
    :visible="true"
    width="50%"
    :close-on-click-modal="false"
    :append-to-body="true"
    @close="onClose"
  >
    <div>
      <div class="common-desc mb-4">
        {{ $t('tasks.youAlreadyHaveATaskInWork') }}
      </div>

      <task-item :task="task" :index="0" @open="onOpenTask"/>
      <div class="fx fx_end mt-4">
        <ui-button @click.prevent="onClose" class="btn-light-grey mr-2" ui-class="size_md">{{ $t('tasks.leaveAsIs') }}</ui-button>
        <ui-loading-button
            @click.prevent="onSubmit"
            ui-class="size_md"
            :loading="isProcessing"
        >
          {{ $t('tasks.pause') }}
        </ui-loading-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import UiButton from 'scorework-ui/src/components/UiButton'
import UiLoadingButton from '@/components/ui/UiLoadingButton'
import TaskItem from "@/components/tasks/TaskItem"

export default {
  name: 'ConfirmTaskStartModal',

  components: { UiButton, UiLoadingButton, TaskItem },

  props: {
    task: {
      type: Object,
      required: true
    },

    isProcessing: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClose () {
      this.$emit('close')
    },

    onOpenTask (task) {
      this.onClose()
      this.$router.push({ name: 'tasks.detail', params: { taskId: task.id, stageId: task.stage_id }})
    },

    onSubmit () {
      this.$emit('submit')
    }
  }
}
</script>

import { render, staticRenderFns } from "./FileItem.vue?vue&type=template&id=789babe4"
import script from "./FileItem.vue?vue&type=script&lang=js"
export * from "./FileItem.vue?vue&type=script&lang=js"
import style0 from "./FileItem.vue?vue&type=style&index=0&id=789babe4&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <editor-popover :editor="editor" :tippy-options="{ placement: 'left-start' }" @created="popoverCreated">
    <div>
      <el-tooltip
        effect="dark"
        :content="$t('editor.table.deleteTable')"
        placement="top"
        transition="sw-slide-top"
      >
        <ui-button ui-class="common size_sm" class="btn-icon" @click="editor.chain().focus().deleteTable().run()">
          <close-icon class="xui-list-item-icon"/>
        </ui-button>
      </el-tooltip>
    </div>
  </editor-popover>
</template>
<script>
  import EditorPopover from '@/components/ui/textEditor/popovers/EditorPopover'
  import CloseIcon from '@/components/icons/CloseIcon'
  import UiButton from 'scorework-ui/src/components/UiButton'

  export default {
    name: 'TablePopover',

    components: {
      CloseIcon, EditorPopover, UiButton
    },

    props: {
      editor: {
        type: Object,
        required: true,
      }
    },

    methods: {
      popoverCreated (popover) {
        this.$emit('created', popover)
      }
    }
  }
</script>

<template>
  <div class="goal-sphere-tasks">
    <transition-group name="zoom" tag="div" style="position: relative" appear>
      <board-task-item
        v-for="(task, index) in tasks"
        :key="task.id"
        :task="task"
        :editable="true"
        :index="index"
        :draggable="false"
        :with-fields="['assignee']"
        @create="onCreateTask"
        @open="onOpenTask"
      />
    </transition-group>

    <div class="workload-member-tasks__empty" v-if="tasks.length === 0">
      <ui-button ui-class="light">
        <plus class="mr-2"/> {{ $t('workload.createNewTask') }}
      </ui-button>
    </div>
  </div>
</template>
<script>
  import UiButton from 'scorework-ui/src/components/UiButton'
  import Plus from '@/components/icons/Plus'
  import BoardTaskItem from '@/components/tasks/BoardTaskItem'

  export default {
    name: 'GoalSphereTasks',

    components: { BoardTaskItem, UiButton, Plus },

    props: {
      tasks: {
        type: Array,
        required: true,
      }
    },

    methods: {
      onCreateTask (task) {
        this.$emit('create-task', {
          afterTaskId: task.id,
          memberId: task.state.assignee_id,
          stageId: task.stage_id,
        })
      },

      onOpenTask (task) {
        this.$emit('open-task', task.id)
      }
    }
  }
</script>

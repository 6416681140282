<template>
  <ui-form :model="form" :rules="rules" :submit-fn="submitFn" ref="form">
    <div class="form-control" style="width: 480px;">
      <ui-spheres-dropdown
          :spheres="spheres"
          :value="form.spheres"
          :nullable-title="$t('pages.setYourSpheres')"
          :parents-active="false"
          :accept-null="false"
          :multiple="true"
          @input="onSphereChange"
      />
    </div>

    <div style="margin-top: 32px;">
      <ui-button @click.prevent="onSubmit">{{ $t('common.save') }}</ui-button>
    </div>
  </ui-form>
</template>
<script>
  import UiForm from 'scorework-ui/src/components/form/UiForm'
  import UiButton from 'scorework-ui/src/components/UiButton'
  import {mapActions, mapGetters} from "vuex";
  import { handleAsyncErrors } from 'scorework-ui/src/components/form/helpers'
  import UiSpheresDropdown from "@/components/ui/UiSpheresDropdown"

  export default {
    name: 'UserProfileForm',
    components: {  UiButton, UiForm, UiSpheresDropdown },


    data () {
      return {
        form: {
          spheres: [],
        },

        rules: {}
      }
    },

    computed: {
      ...mapGetters(['authUser', 'projectAuthMember', 'spheres'])
    },

    methods: {
      ...mapActions(['updateProjectMemberProfile']),

      submitFn () {
        const { spheres, ...form } = this.form

        this.updateProjectMemberProfile({ ...form, spheres: spheres.map((item) => item.id) })
          .then(() => {
            this.$notify({
              title: this.$t('common.saved'),
              message: this.$t('pages.profileSaved'),
              position: 'bottom-right',
              type: 'success'
            })
          })
          .catch(handleAsyncErrors(this.rules, () => this.$refs.form.validate()))
      },

      onSubmit () {
        this.$refs.form.submit()
      },

      onSphereChange (val) {
        this.form.spheres = val
      },
    },

    created () {
      this.form = {
        spheres: [...this.projectAuthMember.project.spheres]
      }
    }
  }
</script>

<template>
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" d="M11.1441 0.0558236C11.5991 0.230828 11.8328 0.760217 11.6674 1.23973L7.47777 13.392C7.31151 13.8715 6.81013 14.1183 6.35599 13.9442C5.90098 13.7692 5.66735 13.2398 5.83273 12.7603L10.0223 0.607963C10.1886 0.12845 10.69 -0.118306 11.1441 0.0558236V0.0558236ZM2.11213 7.22401L5.20621 10.4913C5.54747 10.8518 5.54747 11.4364 5.20621 11.7969C5.12732 11.8822 5.03163 11.9502 4.92517 11.9968C4.81871 12.0434 4.70377 12.0674 4.58757 12.0674C4.47137 12.0674 4.35643 12.0434 4.24997 11.9968C4.14351 11.9502 4.04782 11.8822 3.96893 11.7969L0.256209 7.87765C0.0915112 7.7 0 7.46669 0 7.22444C0 6.98219 0.0915112 6.74889 0.256209 6.57124L3.96893 2.65114C4.04782 2.56583 4.14351 2.49776 4.24997 2.45121C4.35643 2.40466 4.47137 2.38063 4.58757 2.38063C4.70377 2.38063 4.81871 2.40466 4.92517 2.45121C5.03163 2.49776 5.12732 2.56583 5.20621 2.65114C5.54747 3.01252 5.54747 3.59791 5.20621 3.95842L2.11213 7.22401ZM15.388 7.22401L12.2939 3.95842C12.1292 3.78077 12.0377 3.54747 12.0377 3.30522C12.0377 3.06297 12.1292 2.82966 12.2939 2.65201C12.3728 2.56671 12.4685 2.49864 12.5749 2.45209C12.6814 2.40553 12.7963 2.3815 12.9125 2.3815C13.0287 2.3815 13.1437 2.40553 13.2501 2.45209C13.3566 2.49864 13.4523 2.56671 13.5312 2.65201L17.2439 6.57124C17.5852 6.93175 17.5852 7.51626 17.2439 7.87765L13.5312 11.796C13.4523 11.8813 13.3566 11.9494 13.2501 11.9959C13.1437 12.0425 13.0287 12.0665 12.9125 12.0665C12.7963 12.0665 12.6814 12.0425 12.5749 11.9959C12.4685 11.9494 12.3728 11.8813 12.2939 11.796C12.1294 11.6184 12.0381 11.3853 12.0381 11.1432C12.0381 10.9012 12.1294 10.6681 12.2939 10.4905L15.388 7.22401Z" fill="#333840"/>
  </svg>
</template>
<script>
import iconMixin from '../iconMixin'

export default {
  name: 'CodeIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

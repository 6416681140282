<template>
  <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" d="M3.25548 17.7002C3.12758 17.6462 3.02129 17.5512 2.95339 17.4301C2.88549 17.309 2.85984 17.1688 2.88048 17.0315L4.01798 9.62522H0.999231C0.903528 9.6278 0.808512 9.60835 0.721515 9.56839C0.634518 9.52842 0.557866 9.469 0.497471 9.39472C0.437077 9.32043 0.394555 9.23327 0.373188 9.13994C0.35182 9.04662 0.352177 8.94964 0.374231 8.85647L2.24923 0.731473C2.28222 0.591642 2.36238 0.467447 2.47622 0.379794C2.59005 0.292142 2.73061 0.246384 2.87423 0.250223H9.12423C9.2176 0.249906 9.30986 0.270513 9.39422 0.310531C9.47858 0.350549 9.55291 0.40896 9.61173 0.481473C9.67139 0.554805 9.71363 0.640714 9.73528 0.732734C9.75693 0.824753 9.75743 0.920485 9.73673 1.01272L8.65548 5.87522H11.6242C11.7414 5.87499 11.8562 5.90768 11.9557 5.96956C12.0551 6.03145 12.1352 6.12003 12.1867 6.22522C12.2315 6.32619 12.2488 6.43721 12.2367 6.547C12.2246 6.65679 12.1836 6.76141 12.118 6.85022L3.99298 17.4752C3.93807 17.5566 3.86475 17.6239 3.77897 17.6717C3.69318 17.7195 3.59735 17.7464 3.49923 17.7502C3.4156 17.7487 3.33297 17.7317 3.25548 17.7002ZM7.09298 7.12522L8.34298 1.50022H3.37423L1.78673 8.37522H5.48048L4.48673 14.8002L10.3742 7.12522H7.09298Z" fill="#A3A9BD"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'PointsIcon',

    mixins: [iconMixin],

    props: {
      viewBox: {
        type: String,
        default: '0 0 12 12'
      }
    },

    data () {
      return {
        defaultBackground: '#416AF7',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

<template>
  <el-dialog
    :title="$t('tasks.returningTaskToAPreviousStage')"
    :visible="true"
    width="40%"
    :close-on-click-modal="false"
    @close="onClose"
  >
    <div>
      <div class="common-desc mb-4">
        {{ $t('tasks.pleaseIndicateTheReasonForReturningTheTask') }}
      </div>

      <div class="task-card__subhead mb-2">
        <description-icon class="task-card__subhead-icon task-card__subhead-icon_description"/>
        {{ $t('tasks.description') }}
      </div>

      <text-editor
        :placeholder="$t('tasks.returningReasonDescription')"
        :value="comment"
        @input="onCommentInput"
      />

      <div class="fx fx_end mt-4">
        <ui-button @click.prevent="onClose" class="btn-light-grey mr-2" ui-class="size_md">{{ $t('tasks.leaveAsIs') }}</ui-button>
        <ui-loading-button
            @click.prevent="onSubmit"
            ui-class="size_md"
            :loading="isProcessing"
        >
          {{ $t('tasks.returnTask') }}
        </ui-loading-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import DescriptionIcon from "@/components/icons/DescriptionIcon"
import UiButton from 'scorework-ui/src/components/UiButton'
import TextEditor from "@/components/ui/TextEditor"
import confirmationMixin from '@/components/tasks/mixins/confirmationMixin';

export default {
  name: 'ConfirmTaskReturnModal',

  components: { DescriptionIcon, UiButton, TextEditor },

  mixins: [confirmationMixin],

  data () {
    return {
      comment: '',
      commentGetters: {}
    }
  },

  methods: {
    onClose () {
      this.$emit('close')
    },

    onOpenTask (task) {
      this.onClose()
      this.$router.push({ name: 'tasks.detail', params: { taskId: task.id, stageId: task.stage_id }})
    },

    onSubmit () {
      const { getJSON = () => null, getHTML = () => '' } = this.commentGetters

      const comment = JSON.stringify({
        json: getJSON(),
        html: getHTML()
      })

      this.$emit('submit', { comment })
    },

    onCommentInput (getters) {
      this.commentGetters = getters
    }
  }
}
</script>

import { transformDateToObject } from '@/services/scoring'

export const transformPage = (page) => {
  return {
    ...page,
    createdAt: transformDateToObject(page.created_at),
  }
}

export const transformPageCard = (page) => {
  return {
    ...page,
    updatedAt: transformDateToObject(page.updated_at),
  }
}

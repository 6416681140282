class DraggableList {
  constructor (name) {
    this.el = null
    this.listItem = null
    this.name = name
    this.items = []
    this.dragState = emptyDragState()
    this.activeList = this
    this.options = {}

    this.doScroll = this.doScroll.bind(this)
  }

  addItem (el, item, dragSelector) {
    this.items.push({
      item, el
    })

    this.setupClasses(el)
    const mouseDownHandler = this.mouseDownHandler.bind(this, { item, el })

    const dragEl = dragSelector ? el.querySelector(dragSelector) : el
    dragEl.addEventListener('mousedown', mouseDownHandler)

    el.ondragstart = function() {
      return false
    }
  }

  updateItem (el, item) {
    const listItem = this.items.find(lItem => lItem.el === el)

    if (!listItem) {
      return
    }

    listItem.item = item
  }

  setupClasses (el) {
    el.classList.add(`draggable-item`, `drag-item-${this.name}`)
  }

  removeItem (el, item) {
    const index = this.items.findIndex(dItem => dItem.item === item)
    this.items.splice(index, 1)
  }

  setCallbacks (callbacks) {
    const {
      onDragStart   = () => {},
      onDragEnd     = () => {},
      onAfterItem   = () => {},
      onBeforeItem  = () => {},
      onAboveItem   = () => {},
      onLeaveList   = () => {}
    } = callbacks

    this.onDragStart    = onDragStart
    this.onDragEnd      = onDragEnd
    this.onAfterItem    = onAfterItem
    this.onBeforeItem   = onBeforeItem
    this.onAboveItem    = onAboveItem
    this.onLeaveList    = onLeaveList
  }

  setParams (params) {
    const {
      hideElement = false,
      canMovedInto = false,
      unsetTargetWhenLeaveList = false,
      edgeWeight = 7,
      findUnderlyingEl = null,
      externalDropLists = [],
      dragManager = null
    } = params

    this.options = {
      hideElement,
      canMovedInto,
      unsetTargetWhenLeaveList,
      edgeWeight,
      findUnderlyingEl,
      externalDropLists,
      dragManager
    }
  }

  mouseDownHandler ({ el, item }, e) {
    if (e.which !== 1) {
      return
    }

    this.dragState.downX = e.pageX
    this.dragState.downY = e.pageY
    this.dragState.el = el

    document.onmousemove = this.mouseMoveHandler.bind(this, { el, item });
    el.onmouseup = this.mouseUpHandler.bind(this, {el, item})

    e.stopPropagation()
  }

  mouseUpHandler ({ el, item }, e) {
    if (this.dragState.inProgress) {
      if (this.activeList !== this) {
        this.activeList.mouseUpHandler({ el, item }, e)
      } else {
        this.removeGhost(el)

        const targetItem = this.dragState.targetItem
        this.onDragEnd(this.el, item, targetItem ? targetItem.item : null, this.dragState.position, this.listItem)

        this.activeList.el.classList.remove(`draggable-list_active`)

        if (this.dragState.targetItem) {

          //(this.dragState.targetItem.item.id)
          this.dragState.targetItem.el.classList.remove('draggable-item_before', 'draggable-item_after', 'draggable-item_inside')

          this.dragState.targetItem = null
        }
      }
    }

    document.onmousemove = null
    document.onmouseup = null
    el.onmouseup = null
    this.dragState = emptyDragState()
    this.activeList = this
  }

  enterFromInternalList (el, item, dragState) {
    this.dragState = dragState
    //console.log(this.items[0])

    // Бесмысленно
    this.onDragStart(this.activeList.el, null, item)
  }

  mouseMoveHandler ({ el, item }, e) {
    e.cancelBubble = true

    if (!this.dragState.inProgress) {
      const moveX = e.pageX - this.dragState.downX;
      const moveY = e.pageY - this.dragState.downY;

      // если мышь передвинулась в нажатом состоянии недостаточно далеко
      if (Math.abs(moveX) < 3 && Math.abs(moveY) < 3) {
        return
      }

      // аватар создан успешно
      // создать вспомогательные свойства shiftX/shiftY
      const coords = this.getCoords(el)
      this.dragState.shiftX = this.dragState.downX - coords.left
      this.dragState.shiftY = this.dragState.downY - coords.top

      // начинаем перенос
      this.onDragStart(this.activeList.el, el, item)

      this.startDrag({ el, item })
    }

    e.preventDefault()

    const ghost = this.dragState.ghost

    ghost.style.left  = e.pageX - this.dragState.shiftX + 'px'
    ghost.style.top   = e.pageY - this.dragState.shiftY + 'px'

    const elem = document.elementFromPoint(e.clientX, e.clientY)
    if (!elem) {
      return
    }

    let foundList = null
    for (let name of this.options.externalDropLists) {
      const list = this.options.dragManager.getList(name)

      if (!list) {
        continue
      }


      if (list.el.contains(elem)) {
        //console.log(list)
        foundList = list
        break
      }
    }

    if (!foundList && !this.activeList.el.contains(elem)) {
      if (this.activeList !== this && this.el.contains(elem)) {
        this.leaveList(true)
        this.activeList = this
        return
      }

      // todo: добавить состояние, когда мы вне списка
      this.leaveList()
      return
    }

    foundList = foundList || this

    if (foundList !== this.activeList) {
      this.leaveList(true)
      this.activeList = foundList
      this.activeList.enterFromInternalList(el, item, this.dragState)

      return
    }

    if (this.activeList !== this) {
      this.activeList.mouseMoveHandler({ el, item }, e)
      // todo add return?
      return
    }

    const underlyingItem = elem.closest(`.drag-item-${this.name}`)

    if (underlyingItem) {
      this.activeList.el.classList.remove(`draggable-list_active`)

      const offset = underlyingItem.getBoundingClientRect()
      let position = ''

      if (this.options.canMovedInto) {
        position = 'inside'

        if (e.pageY <= offset.y + this.options.edgeWeight) {
          position = 'before'
        } else if(e.pageY >= offset.y + offset.height - this.options.edgeWeight) {
          position = 'after'
        }
      } else {
        const middleHeight = offset.y + offset.height / 2

        position = 'after'
        if (e.pageY >= offset.y && e.pageY < middleHeight) {
          position = 'before'
        } else if (e.pageY >= middleHeight && e.pageY < offset.y + offset.height) {
          position = 'after'
        }
      }

      const { item: foundItem, position: newPosition } = this.findItemByEl(underlyingItem, position)
      position = newPosition

      if (foundItem !== this.dragState.targetItem && this.dragState.targetItem) {
        this.dragState.targetItem.el.classList.remove('draggable-item_inside', 'draggable-item_before', 'draggable-item_after')
      }

      if (!foundItem) {
        this.dragState.targetItem = null
        return
      }

      if (position === 'before') {
        if ((this.options.hideElement || foundItem.el.previousSibling !== el) && foundItem.el.classList.contains(`draggable-item_before`) === false) {
          this.dragState.targetItem = foundItem
          this.dragState.position = position

          foundItem.el.classList.remove('draggable-item_after', 'draggable-item_inside')
          foundItem.el.classList.add(`draggable-item_before`)

          this.onBeforeItem(this.el, foundItem.el)
        }
      } else if(position === 'after') {
        if ((this.options.hideElement || foundItem.el.nextSibling !== el) && foundItem.el.classList.contains(`draggable-item_after`) === false) {
          this.dragState.targetItem = foundItem
          this.dragState.position = position

          foundItem.el.classList.remove('draggable-item_before', 'draggable-item_inside')
          foundItem.el.classList.add(`draggable-item_after`)

          this.onAfterItem(this.el, foundItem.el)
        }
      } else {
        if ((this.options.hideElement || foundItem.el !== el) && foundItem.el.classList.contains(`draggable-item_inside`) === false) {
          this.dragState.targetItem = foundItem
          this.dragState.position = position

          foundItem.el.classList.remove('draggable-item_before', 'draggable-item_after')
          foundItem.el.classList.add(`draggable-item_inside`)

          this.onAboveItem(this.el, foundItem.el)
        }
      }
    } else {
      this.activeList.el.classList.add(`draggable-list_active`)

      // TODO check tasks
      if (this.options.unsetTargetWhenLeaveList && this.dragState.targetItem) {
        this.dragState.targetItem.el.classList.remove('draggable-item_before', 'draggable-item_after', 'draggable-item_inside')
        this.dragState.targetItem = null
      }
    }

    const scrollableContainer = elem.closest(`.scrollable`)
    if (!scrollableContainer) {
      return
    }

    this.dragState.scrollableEl = scrollableContainer
    this.dragState.cursor = e
    this.dragState.scrollId = requestAnimationFrame(this.doScroll)
  }

  leaveList (toNewList = false) {
    this.activeList.el.classList.remove(`draggable-list_active`)

    // todo для тасок вынес под условие
    if (this.options.unsetTargetWhenLeaveList || toNewList) {
      if (this.dragState.targetItem) {
        this.dragState.targetItem.el.classList.remove('draggable-item_before', 'draggable-item_after', 'draggable-item_inside')
        this.dragState.targetItem = null
      }

      this.activeList.onLeaveList(this.activeList.el)
    }
  }

  doScroll () {
    if (!this.dragState.scrollableEl || !this.dragState.cursor) {
      return
    }

    const container = this.dragState.scrollableEl
    const cursor = this.dragState.cursor
    const scrollRect = this.dragState.scrollableEl.getBoundingClientRect()

    if (container.scrollTop && cursor.pageY > scrollRect.top && cursor.pageY <= scrollRect.top + 40) {
      if (container.scrollTop === 0) {
        cancelAnimationFrame(this.dragState.scrollId)
        return
      }

      const step = 1 > container.scrollTop
        ? container.scrollTop
        : 1

      container.scrollTop -= step

      this.dragState.scrollId = requestAnimationFrame(this.doScroll)

    } else if (container.scrollTop < container.scrollHeight && cursor.pageY < scrollRect.bottom && cursor.pageY >= scrollRect.bottom - 40) {
      if (container.scrollTop === container.scrollHeight) {
        cancelAnimationFrame(this.dragState.scrollId)
        return
      }

      const step = container.scrollHeight - 1 < container.scrollTop
        ? container.scrollHeight - container.scrollTop
        : 1

      container.scrollTop += step

      this.dragState.scrollId = requestAnimationFrame(this.doScroll)
    } else {
      cancelAnimationFrame(this.dragState.scrollId)
    }
  }

  findItemByEl (el, position) {
    const underlyingItem = this.items.find(item => item.el === el)

    if (!underlyingItem || !this.options.findUnderlyingEl) {
      return {
        item: underlyingItem,
        position
      }
    }

    return this.options.findUnderlyingEl(underlyingItem, this.items, position)
  }

  createGhost (el) {
    const ghost = el.cloneNode(true)

    ghost.style.width = el.offsetWidth + 'px'
    ghost.style.position = 'absolute'
    ghost.style['pointer-events'] = 'none'
    ghost.style.zIndex = '100'
    ghost.classList.add(`draggable-item__ghost`)

    return ghost
  }

  removeGhost (el) {
    document.body.removeChild(this.dragState.ghost)
    this.dragState.ghost = null

    if (this.options.hideElement) {
      el.style.display = null
    }
  }

  startDrag ({ el, item }) {
    const ghost = this.createGhost(el) // создать аватар

    if (this.options.hideElement) {
      el.style.display = 'none'
    }

    this.dragState.ghost = ghost
    this.dragState.inProgress = true
    this.dragState.targetItem = { el, item }

    document.body.appendChild(ghost)

    const mouseUpHandler = this.mouseUpHandler.bind(this, {el, item})
    document.onmouseup = mouseUpHandler
  }

  getCoords (el) {
    const box = el.getBoundingClientRect()

    return {
      top: box.top + pageYOffset,
      left: box.left + pageXOffset
    }
  }
}

function emptyDragState () {
  return {
    inProgress: false,
    ghost: null,
    startIndex: 0,
    downX: 0,
    downY: 0,
    sourceItem: null,
    targetItem: null,
    position: '',
    scrollId: 0,
    scrollableEl: null,
    cursor: null
  }
}

export default DraggableList

<template>
  <svg width="420" height="374" viewBox="0 0 420 374" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.67" d="M264.844 370.615H214.428C204.681 370.615 196.708 362.642 196.708 352.895V262.68C196.708 252.933 204.681 244.96 214.428 244.96H264.841C274.587 244.96 282.56 252.933 282.56 262.68V352.895C282.564 362.642 274.591 370.615 264.844 370.615Z" fill="#A7ADFF"/>
    <path d="M355.73 370.615H305.318C295.571 370.615 287.598 362.642 287.598 352.895V191.185C287.598 181.439 295.571 173.465 305.318 173.465H355.73C365.477 173.465 373.45 181.439 373.45 191.185V352.895C373.454 362.642 365.477 370.615 355.73 370.615Z" fill="#A7ADFF"/>
    <path d="M174.121 370.615H123.708C113.961 370.615 105.988 362.642 105.988 352.895V298.1C105.988 288.353 113.961 280.38 123.708 280.38H174.121C183.868 280.38 191.841 288.353 191.841 298.1V352.895C191.845 362.642 183.868 370.615 174.121 370.615Z" fill="#E3E5FC"/>
    <path d="M402.281 125.733H309.586C299.839 125.733 291.866 117.76 291.866 108.013V88.2239C291.866 78.4772 299.839 70.5041 309.586 70.5041H402.281C412.027 70.5041 420 78.4772 420 88.2239V108.013C420 117.76 412.027 125.733 402.281 125.733Z" fill="#E3E5FC"/>
    <path opacity="0.67" d="M379.795 107.367H287.101C277.354 107.367 269.381 99.3935 269.381 89.6468V46.2575C269.381 36.5107 277.354 28.5376 287.101 28.5376H379.795C389.542 28.5376 397.515 36.5107 397.515 46.2575V89.6468C397.519 99.3935 389.542 107.367 379.795 107.367Z" fill="#A7ADFF"/>
    <path d="M294.535 84.9843C303.895 97.8682 321.927 100.726 334.807 91.3651L300.916 44.7125C288.032 54.069 285.174 72.1003 294.535 84.9843Z" fill="white"/>
    <path d="M303.946 42.5093L319.475 63.8828L341.262 48.0547C332.426 36.3807 315.845 33.8622 303.946 42.5093Z" fill="white"/>
    <path d="M343.804 51.5033C343.698 51.3575 343.575 51.2274 343.465 51.0816L321.678 66.9097L337.841 89.1581C349.885 80.4085 352.557 63.5517 343.804 51.5033Z" fill="white"/>
    <path d="M383.685 60.4066H359.726C358.5 60.4066 357.499 59.4055 357.499 58.1798V58.1798C357.499 56.954 358.5 55.9529 359.726 55.9529H383.685C384.91 55.9529 385.912 56.954 385.912 58.1798V58.1798C385.912 59.4055 384.91 60.4066 383.685 60.4066Z" fill="white"/>
    <path d="M383.685 67.9698H359.726C358.5 67.9698 357.499 66.9687 357.499 65.743V65.743C357.499 64.5173 358.5 63.5162 359.726 63.5162H383.685C384.91 63.5162 385.912 64.5173 385.912 65.743V65.743C385.912 66.9648 384.91 67.9698 383.685 67.9698Z" fill="white"/>
    <path d="M383.685 75.5292H359.726C358.5 75.5292 357.499 74.5281 357.499 73.3023V73.3023C357.499 72.0766 358.5 71.0755 359.726 71.0755H383.685C384.91 71.0755 385.912 72.0766 385.912 73.3023V73.3023C385.912 74.5281 384.91 75.5292 383.685 75.5292Z" fill="white"/>
    <path d="M383.685 83.0885H359.726C358.5 83.0885 357.499 82.0874 357.499 80.8617V80.8617C357.499 79.6359 358.5 78.6349 359.726 78.6349H383.685C384.91 78.6349 385.912 79.6359 385.912 80.8617V80.8617C385.912 82.0874 384.91 83.0885 383.685 83.0885Z" fill="white"/>
    <path d="M97.8969 193.932H11.6661C5.24975 193.932 0 188.683 0 182.266V179.582C0 173.166 5.24975 167.916 11.6661 167.916H97.8969C104.313 167.916 109.563 173.166 109.563 179.582V182.266C109.563 188.683 104.313 193.932 97.8969 193.932Z" fill="#E3E5FC"/>
    <path d="M96.931 181.722L96.8443 181.829C96.6393 182.081 96.261 182.12 96.0087 181.915L88.4612 175.767C88.209 175.562 88.1696 175.184 88.3745 174.932L88.4612 174.825C88.6662 174.573 89.0445 174.534 89.2968 174.738L96.8443 180.887C97.1004 181.096 97.1399 181.47 96.931 181.722Z" fill="white"/>
    <path d="M96.931 181.072L96.8443 180.966C96.6393 180.713 96.261 180.674 96.0087 180.879L88.4612 187.027C88.209 187.232 88.1696 187.611 88.3745 187.863L88.4612 187.969C88.6662 188.222 89.0445 188.261 89.2968 188.056L96.8443 181.908C97.1004 181.699 97.1399 181.324 96.931 181.072Z" fill="white"/>
    <path d="M111.25 222.877H53.5859C49.2939 222.877 45.7861 219.365 45.7861 215.077V213.284C45.7861 208.992 49.2978 205.484 53.5859 205.484H111.25C115.542 205.484 119.05 208.996 119.05 213.284V215.077C119.05 219.365 115.538 222.877 111.25 222.877Z" fill="#A7ADFF"/>
    <path d="M110.604 214.715L110.545 214.786C110.407 214.955 110.155 214.979 109.986 214.841L104.937 210.73C104.767 210.592 104.744 210.34 104.882 210.17L104.941 210.099C105.079 209.93 105.331 209.906 105.5 210.044L110.549 214.155C110.715 214.293 110.742 214.545 110.604 214.715Z" fill="white"/>
    <path d="M110.604 214.277L110.545 214.206C110.407 214.037 110.155 214.013 109.986 214.151L104.937 218.262C104.767 218.4 104.744 218.652 104.882 218.821L104.941 218.892C105.079 219.062 105.331 219.086 105.5 218.948L110.549 214.837C110.715 214.695 110.742 214.447 110.604 214.277Z" fill="white"/>
    <path d="M99.9699 345.202C98.1924 344.201 95.5597 344.752 94.7123 346.609C92.3475 342.222 93.5338 336.886 94.1211 331.939C94.33 330.166 94.4364 328.286 93.6679 326.674C92.8954 325.062 90.9602 323.876 89.297 324.53C85.8957 325.866 86.9835 332.633 83.3536 333.043C81.2923 333.276 79.9602 331.037 78.8054 329.315C77.217 326.946 74.9784 325.023 72.3969 323.809C71.4865 323.383 70.2686 323.095 69.5907 323.84C68.9601 324.53 69.2478 325.622 69.5907 326.489C70.7573 329.433 72.1722 332.278 73.8118 334.982C74.0404 335.36 74.2848 335.786 74.1902 336.22C73.9773 337.205 72.5309 337.059 71.6008 336.673C68.2428 335.274 64.8849 333.879 61.5269 332.479C60.5731 333.366 60.9633 335.002 61.787 336.007C62.6108 337.012 63.7734 337.733 64.4553 338.841C65.48 340.512 65.1805 342.64 65.4051 344.587C65.6298 346.538 66.9855 348.765 68.9325 348.512C67.3363 349.245 67.2851 351.673 68.3689 353.053C69.4528 354.432 71.2066 355.059 72.8344 355.721C74.4582 356.387 76.1687 357.278 76.8702 358.886C77.4693 360.253 77.1973 361.838 77.4929 363.3C79.1128 371.313 90.3887 374.43 95.0631 368.057C98.3264 363.623 106.985 349.159 99.9699 345.202Z" fill="#978CFF"/>
    <path d="M57.5974 368.069C56.9865 367.399 56.3362 366.622 56.4032 365.72C56.4899 364.541 57.8772 363.785 59.032 364.029C60.1868 364.273 61.0657 365.235 61.6214 366.276C61.7003 364.873 62.0471 363.485 62.6383 362.208C62.8669 361.712 63.2925 361.156 63.8246 361.274C64.136 361.345 64.3448 361.629 64.4986 361.909C65.1213 363.048 65.2592 364.447 64.869 365.684C65.5666 363.217 67.6555 361.191 70.1424 360.569C70.4538 360.49 70.8006 360.439 71.0883 360.584C71.7426 360.923 71.5455 361.901 71.2499 362.579C69.8587 365.779 68.3295 369.665 65.5469 371.947C62.8078 374.186 59.3158 369.953 57.5974 368.069Z" fill="#FDC510"/>
    <path d="M90.5699 372.207C90.2428 372.207 89.9472 371.99 89.8566 371.659L81.6114 340.776C81.505 340.382 81.7415 339.976 82.1356 339.873C82.5258 339.767 82.9357 340.003 83.0382 340.397L91.2833 371.281C91.3897 371.675 91.1532 372.081 90.7591 372.184C90.696 372.199 90.633 372.207 90.5699 372.207Z" fill="white"/>
    <path d="M85.4104 352.891C85.3001 352.891 85.1858 352.868 85.0833 352.812L74.2409 347.401C73.8744 347.22 73.7285 346.774 73.9098 346.412C74.0911 346.045 74.5365 345.899 74.8991 346.081L85.7415 351.492C86.108 351.673 86.2538 352.119 86.0725 352.481C85.9425 352.741 85.6824 352.891 85.4104 352.891Z" fill="white"/>
    <path d="M88.2918 363.682C88.1775 363.682 88.0632 363.655 87.9529 363.6C87.5903 363.41 87.4484 362.969 87.6376 362.606L92.1306 353.908C92.3198 353.545 92.7612 353.404 93.1238 353.593C93.4864 353.782 93.6283 354.223 93.4391 354.586L88.9461 363.284C88.8199 363.536 88.5598 363.682 88.2918 363.682Z" fill="white"/>
    <path d="M352.361 343.168C352.27 342.853 352.192 342.534 352.121 342.21C351.269 338.238 352.357 333.646 355.613 331.214C358.805 328.826 363.937 328.353 365.162 324.557C366.033 321.87 364.303 319.122 363.101 316.565C361.899 314.007 361.513 310.318 363.972 308.926C365.986 307.787 368.646 309.013 370.779 308.118C374.409 306.597 373.869 300.421 377.447 298.786C379.792 297.714 382.595 299.259 385.089 298.612C388.7 297.674 389.645 292.862 388.767 289.24C387.888 285.614 385.909 282.11 386.343 278.405C387.517 268.32 411.346 260.965 411.776 274.417C411.894 278.11 409.423 281.31 408.012 284.727C406.601 288.144 406.869 293.087 410.345 294.344C412.698 295.195 415.957 294.099 417.4 296.149C420.06 299.929 410.897 302.865 411.441 307.452C411.815 310.601 416.213 311.185 418.361 313.522C421.187 316.596 419.13 321.85 415.721 324.258C412.312 326.666 407.996 327.364 404.248 329.2C402.746 329.933 401.225 330.997 400.783 332.605C399.79 336.212 404.5 338.569 408.197 339.124C411.894 339.68 416.698 341.091 416.694 344.831C416.69 347.827 413.364 349.624 410.443 350.31C407.523 350.995 404.149 351.476 402.498 353.979C400.921 356.375 401.654 359.595 400.799 362.33C398.868 368.502 378.586 372.302 372.3 370.769C362.427 368.365 365.115 360.045 358.21 352.592C355.668 349.86 353.378 346.735 352.361 343.168Z" fill="#FDC510"/>
    <path d="M369.615 362.48C371.452 358.113 370.92 353.159 370.356 348.453C370.167 346.881 369.962 345.253 369.142 343.901C368.323 342.545 366.707 341.536 365.17 341.93C363.183 342.439 362.088 344.997 360.042 345.135C358.466 345.241 357.189 343.779 356.716 342.273C355.789 339.317 356.932 336.145 356.952 333.051C356.972 329.957 354.773 326.236 351.738 326.863C348.604 327.509 348.159 331.805 348.774 334.943C349.054 336.377 349.247 338.206 348.001 338.971C346.681 339.779 345.077 338.612 343.934 337.568C341.723 335.55 339.335 333.729 336.804 332.133C335.642 331.399 334.049 330.737 333.005 331.628C331.996 332.487 332.335 334.111 332.863 335.325C333.943 337.804 335.472 340.086 337.356 342.025C338.306 343.003 339.402 344.496 338.523 345.537C337.813 346.38 336.465 346.029 335.445 345.612C333.241 344.717 327.602 335.774 328.705 345.564C329.809 355.354 340.281 354.409 346.374 358.397C343.832 360.179 343.765 364.179 345.503 366.749C347.241 369.318 350.228 370.733 353.165 371.742C362.131 374.828 365.926 371.261 369.615 362.48Z" fill="#F44E4E"/>
    <path d="M363.9 371.356C363.656 371.356 363.415 371.234 363.274 371.009L347.769 346.148C347.552 345.801 347.658 345.348 348.005 345.131C348.348 344.918 348.805 345.02 349.022 345.367L364.527 370.229C364.744 370.576 364.637 371.029 364.29 371.246C364.172 371.316 364.034 371.356 363.9 371.356Z" fill="white"/>
    <path d="M358.856 363.265C358.793 363.265 358.73 363.257 358.667 363.241C358.273 363.138 358.036 362.732 358.143 362.338L360.85 352.091C360.953 351.697 361.355 351.46 361.753 351.567C362.147 351.669 362.383 352.075 362.277 352.469L359.569 362.717C359.483 363.048 359.183 363.265 358.856 363.265Z" fill="white"/>
    <path d="M352.463 353.017C352.424 353.017 352.384 353.013 352.345 353.009L343.847 351.658C343.445 351.594 343.17 351.216 343.233 350.814C343.296 350.412 343.67 350.132 344.08 350.199L352.577 351.551C352.979 351.614 353.255 351.993 353.192 352.395C353.137 352.757 352.822 353.017 352.463 353.017Z" fill="white"/>
    <path d="M371.322 373.937C371.243 373.937 371.16 373.926 371.081 373.898C370.695 373.764 370.49 373.346 370.624 372.96L395.198 301.706C395.332 301.32 395.754 301.119 396.136 301.249C396.522 301.383 396.727 301.801 396.593 302.187L372.019 373.441C371.917 373.748 371.629 373.937 371.322 373.937Z" fill="white"/>
    <path d="M381.376 346.495C381.001 346.495 380.682 346.211 380.643 345.828C380.599 345.422 380.899 345.06 381.305 345.02L397.303 343.42C397.697 343.381 398.071 343.677 398.11 344.082C398.154 344.488 397.854 344.851 397.448 344.89L381.451 346.491C381.427 346.495 381.4 346.495 381.376 346.495Z" fill="white"/>
    <path d="M387.994 325.488C387.643 325.488 387.335 325.239 387.268 324.885C387.194 324.483 387.462 324.096 387.86 324.025L401.421 321.527C401.831 321.452 402.206 321.716 402.281 322.118C402.356 322.52 402.088 322.906 401.689 322.977L388.128 325.476C388.084 325.484 388.041 325.488 387.994 325.488Z" fill="white"/>
    <path d="M384.699 335.002C384.502 335.002 384.305 334.923 384.159 334.769L372.915 322.756C372.635 322.457 372.651 321.992 372.95 321.712C373.25 321.432 373.715 321.452 373.995 321.747L385.239 333.76C385.519 334.06 385.503 334.525 385.204 334.805C385.062 334.935 384.88 335.002 384.699 335.002Z" fill="white"/>
    <path d="M174.455 125.816L141.376 135.35C138.318 136.232 135.098 134.451 134.215 131.393L127.436 107.875C126.553 104.817 128.335 101.597 131.393 100.714L164.472 91.1799C167.531 90.2971 170.751 92.0785 171.633 95.1369L178.412 118.654C179.291 121.713 177.51 124.933 174.455 125.816Z" fill="#FDC510"/>
    <path d="M129.229 103.839L165.146 60.4066L169.753 70.5001L162.746 71.0716L134.318 103.839H129.229Z" fill="#FDC510"/>
    <path d="M210.064 216.354L204.361 236.124L197.094 235.02L204.357 214.727C204.357 214.727 208.417 214.794 210.064 216.354Z" fill="#EAC2AB"/>
    <path d="M233.55 240.814C233.55 240.814 228.399 242.69 218.412 243.785C208.425 244.881 195.032 244.286 192.636 243.557C188.99 242.453 191.221 231.3 194.559 231.166C197.898 231.031 199.403 235.497 205.11 231.233C209.997 227.579 214.325 233.928 223.189 235.312C232.053 236.699 235.643 237.251 233.55 240.814Z" fill="#FDC510"/>
    <path d="M218.889 217.347C220.414 201.094 226.015 178.014 222.527 161.657C218.98 145.029 207.109 139.33 195.308 127.459C183.118 115.19 183.173 126.919 183.173 126.919L182.089 130.431C187.848 135.239 171.113 138.384 176.725 143.342C182.771 148.683 187.505 151.339 193.346 156.881C200.791 163.943 204.795 160.905 203.979 171.282C203.747 174.226 198.505 201.062 196.964 212.354C195.182 225.423 186.208 221.438 210.4 225.6C225.443 228.182 217.939 227.52 218.889 217.347Z" fill="#4B369D"/>
    <path d="M159.976 247.088L159.684 267.291L152.448 267.894L154.092 246.832C154.092 246.832 157.99 245.973 159.976 247.088Z" fill="#EAC2AB"/>
    <path d="M188.538 274.72C188.538 274.72 183.343 276.281 173.435 276.797C163.526 277.314 152.479 277.471 150.496 276.064C148.514 274.657 147.454 262.971 150.753 263.026C154.052 263.082 155.246 267.567 161.138 263.685C166.187 260.358 170.041 266.857 178.684 268.722C187.328 270.586 190.827 271.327 188.538 274.72Z" fill="#FDC510"/>
    <path d="M175.575 256.058C175.378 255.294 174.613 254.624 173.971 253.954C168.071 247.805 171.129 240.325 173.435 233.731C178.909 218.088 179.051 201.472 180.911 185.258C182.724 169.43 187.233 153.968 193.815 139.299C196.207 133.97 199.672 125.69 190.193 125.871C185.704 125.958 181.436 125.13 177.226 123.739C175.011 123.006 177.463 122.678 175.011 123.006C166.652 124.125 161.166 137.671 161.915 142.81C163.408 153.042 162.786 162.536 161.457 172.918C159.577 187.634 159.546 202.654 155.08 216.847C152.893 223.803 150.564 230.716 148.278 237.641C146.942 241.681 141.617 250.154 143.422 254.285C145.901 259.956 161.863 257.99 166.952 258.668C170.775 259.168 176.249 258.656 175.575 256.058Z" fill="#5A54FF"/>
    <path d="M192.302 49.1858L190.107 64.2099C186.229 65.278 182.161 67.3117 178.07 67.3077C178.256 64.5213 181.176 61.3328 182.516 58.704C184.144 55.5155 185.291 52.2127 185.109 48.6341C184.636 39.2814 192.302 49.1858 192.302 49.1858Z" fill="#D4A79A"/>
    <path d="M199.112 20.0639C199.104 20.0796 205.422 24.0839 202.072 33.6651C198.489 43.9163 193.322 52.5004 190.008 51.6136C180.84 49.1582 178.113 42.7497 175.346 37.2202C171.953 30.4372 181.211 15.2398 181.211 15.2398L188.975 12.0631L199.112 20.0639Z" fill="#EAC2AB"/>
    <path d="M210.826 19.4215C211.748 17.2853 210.716 13.2731 209.131 11.7912C208.52 11.2197 207.72 10.8216 206.885 10.8335C206.258 10.8413 205.62 11.0778 205.013 10.932C203.184 10.4985 203.188 9.04413 201.509 8.39383C200.26 7.90905 198.931 8.89831 197.599 9.08749C196.59 9.22937 195.593 8.90225 194.616 8.6303C191.865 7.8657 189.386 6.41137 186.544 5.86748C184.148 5.41029 181.602 5.46941 179.316 6.39955C176.931 7.3691 175.106 9.34761 174.019 11.7163C173.368 13.1312 173.057 14.7392 173.345 16.2606C173.108 15.0112 170.633 16.1423 170.207 16.5956C168.54 18.3731 164.343 23.5755 164.946 25.8378C165.423 27.6311 163.941 29.2549 164.363 31.0679C164.863 33.2277 170.913 34.4771 171.997 36.4438C173.242 38.6981 175.989 40.0106 178.488 39.6046C177.905 37.6064 177.329 35.5648 177.373 33.4681C177.42 31.3714 175.296 21.731 186.453 20.5408C190.887 20.0678 195.849 23.8751 200.208 24.3756C202.277 24.6121 204.39 24.3953 206.404 23.8081C207.961 23.3548 209.608 21.3684 210.574 19.8984C210.672 19.7446 210.755 19.587 210.826 19.4215Z" fill="#4B369D"/>
    <path d="M203.775 18.4519C206.518 16.9858 207.314 13.6712 207.594 10.727C207.897 7.52674 207.838 6.95525 204.957 4.70085C203.846 3.82984 201.095 2.8997 201.091 2.8997C194.123 0.566476 186.244 -1.1125 178.941 0.8936C172.229 2.73811 167.594 8.11004 165.292 14.0889C164.122 17.1276 163.471 20.3949 163.456 23.6268C163.44 27.1739 164.914 31.1112 162.423 34.2327C163.341 34.2997 164.275 34.213 165.158 33.9726C161.335 34.5283 155.577 35.4742 155.439 40.0263C155.38 42.0206 156.767 43.7587 157.938 45.4259C159.108 47.093 160.141 49.174 159.329 51.0185C158.304 53.3478 154.745 54.2661 154.288 56.753C153.744 59.709 156.728 61.0608 157.934 63.3625C159.451 66.2633 157.997 69.637 157.65 72.6442C157.339 75.3124 159.972 76.5381 161.69 78.1304C166.672 82.7456 166.609 87.9914 161.158 93.0914C161.047 93.1939 161.091 93.3949 161.17 93.521C161.252 93.655 161.43 93.7102 161.591 93.7496C168.634 95.4719 177.786 89.1107 179.41 82.7693C180.143 79.9118 178.275 77.2397 179.094 74.4926C179.973 71.5406 183.489 70.4331 185.83 68.7187C190.138 65.5696 194.745 60.1189 194.111 54.6681C193.724 51.3496 192.609 47.6093 190.162 44.9056C188.021 42.5369 181.629 41.2442 181.696 37.7049C181.723 36.1403 183.071 34.9303 184.088 33.6849C186.662 30.5279 181.727 27.7769 184.163 26.1295C185.732 25.0693 187.588 24.3244 189.495 24.0051C191.517 23.6662 193.579 23.3469 195.151 22.0148C196.621 20.7694 198.651 17.9553 200.925 18.1445C203.763 18.381 207.022 17.7188 208.492 15.4408" fill="#4B369D"/>
    <path d="M195.648 93.3673C194.635 84.3063 199.321 69.8853 193.67 62.527C193.09 61.7742 192.468 61.0214 191.628 60.5287C180.998 54.2937 173.64 63.9813 170.243 72.5062C167.299 79.8961 167.661 86.6987 167.441 94.4078C167.074 107.402 163.838 120.046 159.708 132.398C158.234 136.804 157.844 141.935 162.01 142.605C167.642 143.508 168.855 137.025 174.231 135.35C179.142 133.82 183.107 134.758 188.471 135.034C191.131 135.172 199.822 133.537 199.294 128.91C198.037 117.949 196.153 108.494 196.09 100.86C196.07 98.3924 195.928 95.8818 195.648 93.3673Z" fill="#D7384E"/>
    <path d="M181.258 69.6094C181.258 69.6094 178.192 82.9742 174.964 99.2634C171.736 115.557 176.524 127.845 176.524 127.845C177.222 129.643 177.939 131.436 178.72 133.174C180.249 136.599 180.753 138.877 180.158 142.586C179.658 145.711 180.714 148.466 183.008 150.823C184.647 152.506 186.705 153.81 188.124 155.679C188.352 155.978 188.73 156.317 189.073 156.144C189.365 155.998 189.353 155.612 189.302 155.304C188.837 152.533 188.372 149.763 187.903 146.992C187.812 146.452 187.722 145.892 187.856 145.36C187.99 144.828 188.411 144.324 188.991 144.233C189.684 144.127 190.311 144.619 190.819 145.073C191.592 145.766 192.128 148.293 193.555 147.335C194.154 144.907 191.746 140.327 190.256 138.349C188.79 136.11 185.983 136.56 185.112 134.171C184.174 131.594 183.24 129.02 182.334 126.434C175.91 100.777 184.967 90.2419 184.967 90.2419C184.967 90.2419 182.98 88.5826 184.399 75.8326C185.826 63.0906 181.258 69.6094 181.258 69.6094Z" fill="#EAC2AB"/>
    <path d="M188.455 89.1935C188.455 89.1935 195.817 65.6327 185.483 66.0662C175.149 66.4998 171.787 93.7102 171.787 93.7102L188.735 92.7643L188.455 89.1935Z" fill="#F44E4E"/>
  </svg>
</template>
<script>
  import iconMixin from '@/components/icons/iconMixin'

  export default {
    name: 'PlanUpgrade',

    mixins: [iconMixin],

    props: {
      viewBox: {
        type: String,
        default: '0 0 12 12'
      }
    },

    data () {
      return {
        defaultBackground: '#2C3E50',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="path-stroke" d="M18 6L6 18" stroke="#5B6372" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path class="path-stroke" d="M6 6L18 18" stroke="#5B6372" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'CloseIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 10,
        defaultHeight: 10
      }
    }
  }
</script>

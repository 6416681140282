<template>
  <div class="setup-example-layout">
    <div class="setup-example__tasks">
      <transition-group name="zoom" tag="div" style="position: relative" appear>
        <task-item
          v-for="(task, i) in tasks"
          :key="task.number"
          :index="i"
          :task="task"
          :class="taskItemClass"
        />
      </transition-group>
    </div>

    <div class="setup-example__hints">
      <transition name="slide-left2" appear>
        <div class="setup-example-hint" :key="activeHint">
          <div class="setup-example-hint__title">
            {{ currentHint.title }}
          </div>

          <div class="setup-example-hint__content" v-html="currentHint.content"></div>
        </div>
      </transition>
    </div>

    <div class="setup-example__hint-circles">
      <div class="hint-circles">
        <div
            v-for="hint in hints"
            :key="hint.name"
            class="hint-circle"
            :class="{'hint-circle_active': hint.name === activeHint}"
            @click="onChangeHint(hint.name)"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import TaskItem from "@/components/tasks/TaskItem"
import { TransformTaskItemDates } from "@/transformers/tasks"

export default {
  name: 'GeneralExample',

  components: { TaskItem },

  props: {
    activeHint: {
      type: String,
      required: true
    }
  },

  data () {
    const tasks = [
      {
        name: this.$t('setup.samples.tasks.name1'),
        number: '181',
        sphere: null,
        stage_id: 123,
        state: {
          stage: {
            id: 123,
            name: this.$t('setup.samples.tasks.sphere2')
          },
          actual_progress: 0,
          assignee: {
            member_id: 123,
            first_name: "Boris",
            last_name: "Bezborodov"
          },
          be_processed_at: "2021-03-14 21:56:49",
          estimated_finish_at: "2021-03-18 16:20:00",
          processing: null,
          score: 240,
          score_status: "estimated",
          started_at: "2021-03-14 21:56:49"
        },
        status: 'open',
        target: null,
        task_date: '2021-03-14 21:56:49'
      },
      {
        name: this.$t('setup.samples.tasks.name2'),
        number: '198',
        sphere: null,
        stage_id: 123,
        state: {
          stage: {
            id: 123,
            name: this.$t('setup.samples.tasks.sphere1')
          },
          actual_progress: 0,
          assignee: {
            member_id: 124,
            first_name: "Mike",
            last_name: "Korsakov"
          },
          be_processed_at: "2021-03-14 21:56:49",
          estimated_finish_at: "2021-03-18 16:20:00",
          processing: null,
          score: 360,
          score_status: "estimated",
          started_at: "2021-03-14 21:56:49"
        },
        status: 'open',
        target: null,
        task_date: '2021-03-14 21:56:49'
      },
      {
        name: this.$t('setup.samples.tasks.name2'),
        number: '202',
        sphere: null,
        stage_id: 123,
        state: {
          stage: {
            id: 123,
            name: this.$t('setup.samples.tasks.sphere1')
          },
          actual_progress: 0,
          assignee: {
            member_id: 125,
            first_name: "Vitaly",
            last_name: "Gulyaev"
          },
          be_processed_at: "2021-03-14 21:56:49",
          estimated_finish_at: "2021-03-18 16:20:00",
          processing: null,
          score: 180,
          score_status: "estimated",
          started_at: "2021-03-14 21:56:49"
        },
        status: 'open',
        target: null,
        task_date: '2021-03-14 21:56:49'
      }
    ]

    return {
      tasks: tasks.map(TransformTaskItemDates),
      hints: [
        {
          name: 'name',
          title: this.$t('setup.hints.tasks.name.title'),
          content: this.$t('setup.hints.tasks.name.content')
        },
        {
          name: 'task_prefix',
          title: this.$t('setup.hints.tasks.taskPrefix.title'),
          content: this.$t('setup.hints.tasks.taskPrefix.content')
        },
        {
          name: 'schedule',
          title: this.$t('setup.hints.tasks.schedule.title'),
          content: this.$t('setup.hints.tasks.schedule.content')
        }
      ]
    }
  },

  computed: {
    currentHint () {
      return this.hints.find(item => item.name === this.activeHint)
    },

    taskItemClass () {
      const classes = []

      if (this.activeHint === 'task_prefix') {
        classes.push(`task-item_number-highlighted`)
      } else if (this.activeHint === 'schedule') {
        classes.push(`task-item_schedule-highlighted`)
      }

      return classes
    }
  },

  methods: {
    onChangeHint (val) {
      this.$emit('change', val)
    }
  }
}
</script>
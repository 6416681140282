<template>
  <div class="goal-filters">
    <div class="goal-filters__controls">
      <ui-dropdown
          class="goal-filters__item"
          :options="sortOptions"
          key-field="id"
          :value="sort"
          reference-class="goal-filters__selector"
          popover-class="ui-dropdown-ex"
          placement="bottom-start"
          :width-by-popover="false"
          @input="onSortChange"
      >
        <sort-icon class="goal-filters__selector-icon"/> {{ $t(`goalsProgress.sort.${sort}`) }}
        <template v-slot:option="{ option }">
          {{ $t(`goalsProgress.sort.${option.id}`) }}
        </template>
      </ui-dropdown>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import SortIcon from '@/components/icons/SortIcon'
import UiDropdown from '@/components/tasks/UiDropdown'

const { mapActions: goalsProgressActions, mapGetters: goalsProgressGetters } = createNamespacedHelpers('goalsProgress')

export default {
  name: 'GoalFilters',

  components: { SortIcon, UiDropdown },

  data () {
    return {
      sortOptions: [
        { id: 'tasks_desc' },
        { id: 'tasks_asc' },
      ],
    }
  },

  computed: {
    ...mapGetters(['spheres', 'parentSpheres']),
    ...goalsProgressGetters(['sort'])
  },

  methods: {
    ...goalsProgressActions(['setSort']),

    onSortChange ({ id }) {
      this.setSort(id)
    }
  }
}
</script>
<style lang="sass">
.goal-filters
  &__controls
    align-items: center
    display: flex

  &__selector
    align-items: center
    display: flex
    transition: all .2s ease-in

    &.ui-dropdown__reference
      padding: 8px 16px
      border-radius: 6px
      background: var(--grey-light-color-24)

    &.ui-dropdown__reference:hover,
    &.ui-dropdown__reference_opened,
    &.ui-dropdown__reference_opened:hover
      background: var(--grey-light-color-40)
      padding: 8px 16px
      box-shadow: none

    &-icon
      margin-right: 6px

      .path-stroke
        stroke: var(--text-primary-color)
        transition: all .2s ease-in

  &__item
    margin-right: 0px
</style>

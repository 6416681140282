<template>
  <div class="sw-popover">
    <template v-if="items.length">
      <div class="member-suggestions ui-dropdown__option-list">
        <div
          :class="{ 'is-selected': index === selectedIndex }"
          v-for="(item, index) in items"
          :key="item[idField]"
          @click="selectItem(index)"
          class="ui-dropdown__option"
        >
          <task-assignee :user="item"/>
        </div>
      </div>
    </template>
    <div v-else class="suggestion-list__item is-empty">
      {{ $t('ui.noUsersFound') }}
    </div>
  </div>
</template>

<script>
  import TaskAssignee from '@/components/tasks/components/TaskAssignee'
  import mentionList from '@/components/ui/textEditor/mentionList'

  export default {
    name: 'MemberList',

    components: { TaskAssignee },

    mixins: [mentionList]
  }
</script>
<style lang="sass">
  .member-suggestions
    min-width: 200px
</style>
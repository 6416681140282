<template>
  <el-popover
      placement="bottom"
      trigger="click"
      ref="popper"
      popper-class="sw-popover"
  >
    <div class="color-list">
      <div
        class="color-item"
        v-for="color in colorCodes"
        :key="color"
        :class="'color-item_' + color"
        @click="onSelectColor(color)"
      >
        <div class="color-item__is-current fx fx_center" v-if="color === value">
          <check-icon background="#ffffff" width="12" height="12" view-box="0 -1 12 14"/>
        </div>
      </div>
    </div>

    <div class="color-picker" slot="reference">
      <div class="color-picker__label">
        {{ $t('goals.form.color') }}
      </div>
      <div class="color-picker__value">
        <div class="color-item color-item_no-margins" :class="colorItemClasses"></div>
        <chevron-down class="color-picker-button__icon"/>
      </div>
    </div>
  </el-popover>
</template>
<script>
  import ChevronDown from "../icons/ChevronDown"
  import CheckIcon from "../icons/CheckIcon"
  import { targetColors } from "../helpers/targets"

  export default {
    name: 'ColorPicker',

    components: { ChevronDown, CheckIcon },

    props: {
      value: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        colorCodes: targetColors
      }
    },

    computed: {
      colorItemClasses () {
        return `color-item_${this.value}`
      }
    },

    methods: {
      onSelectColor (color) {
        if (color !== this.value) {
          this.$emit('input', color)
        }
      },

      backgroundColor (code) {
        return targetColors[code]
      }
    }
  }
</script>
<style lang="sass">
.color-picker
  border: 1px solid var(--grey-light-color-100)
  border-radius: 8px
  padding: 6px 16px
  box-sizing: border-box
  height: 60px
  transition: all .2s ease-in

  &:hover
    box-shadow: 0 0 0px 3px rgba(101, 158, 255, 0.16)

  &__label
    color: var(--text-light-color)
    font-size: 12px
    padding-bottom: 4px

  &__value
    display: flex
    align-items: center

.color-item
  width: 24px
  height: 24px
  border-radius: 6px
  cursor: pointer
  position: relative
  margin: 6px

  &_no-margins
    margin: 0

  &_blue
    background: var(--color-blue-dots)

  &_lavender
    background: var(--color-lavender-dots)

  &_purple
    background: var(--color-purple-dots)

  &_fuchsia
    background: var(--color-fuchsia-dots)

  &_red
    background: var(--color-red-dots)

  &_orange
    background: var(--color-orange-dots)

  &_yellow
    background: var(--color-yellow-dots)

  &_green
    background: var(--color-green-dots)

  &_aquamarine
    background: var(--color-aquamarine-dots)

  &__is-current
    position: absolute
    bottom: -4px
    right: -4px
    box-sizing: border-box
    height: 16px
    width: 16px
    border-radius: 8px
    background: #0BC273
    border: 1px solid #ffffff
</style>

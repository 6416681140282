<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" d="M23 8C23 9.1 22.1 10 21 10C20.8274 10.0027 20.6555 9.97909 20.49 9.93L16.93 13.48C16.98 13.64 17 13.82 17 14C17 15.1 16.1 16 15 16C13.9 16 13 15.1 13 14C13 13.82 13.02 13.64 13.07 13.48L10.52 10.93C10.36 10.98 10.18 11 10 11C9.82 11 9.64 10.98 9.48 10.93L4.93 15.49C4.98 15.65 5 15.82 5 16C5 17.1 4.1 18 3 18C1.9 18 1 17.1 1 16C1 14.9 1.9 14 3 14C3.18 14 3.35 14.02 3.51 14.07L8.07 9.52C8.02 9.36 8 9.18 8 9C8 7.9 8.9 7 10 7C11.1 7 12 7.9 12 9C12 9.18 11.98 9.36 11.93 9.52L14.48 12.07C14.64 12.02 14.82 12 15 12C15.18 12 15.36 12.02 15.52 12.07L19.07 8.51C19.0209 8.34454 18.9973 8.17257 19 8C19 6.9 19.9 6 21 6C22.1 6 23 6.9 23 8ZM23 8C23 9.1 22.1 10 21 10C20.8274 10.0027 20.6555 9.97909 20.49 9.93L16.93 13.48C16.98 13.64 17 13.82 17 14C17 15.1 16.1 16 15 16C13.9 16 13 15.1 13 14C13 13.82 13.02 13.64 13.07 13.48L10.52 10.93C10.36 10.98 10.18 11 10 11C9.82 11 9.64 10.98 9.48 10.93L4.93 15.49C4.98 15.65 5 15.82 5 16C5 17.1 4.1 18 3 18C1.9 18 1 17.1 1 16C1 14.9 1.9 14 3 14C3.18 14 3.35 14.02 3.51 14.07L8.07 9.52C8.02 9.36 8 9.18 8 9C8 7.9 8.9 7 10 7C11.1 7 12 7.9 12 9C12 9.18 11.98 9.36 11.93 9.52L14.48 12.07C14.64 12.02 14.82 12 15 12C15.18 12 15.36 12.02 15.52 12.07L19.07 8.51C19.0209 8.34454 18.9973 8.17257 19 8C19 6.9 19.9 6 21 6C22.1 6 23 6.9 23 8Z" fill="#5B6372"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'TimelineIcon',

    mixins: [iconMixin],

    props: {
      viewBox: {
        type: String,
        default: '0 0 12 12'
      }
    },

    data () {
      return {
        defaultBackground: '#2C3E50',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

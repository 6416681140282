<template>
  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" d="M2.4 4.8H7.2V1.2C7.2 0.88174 7.32643 0.576516 7.55147 0.351472C7.77652 0.126428 8.08174 0 8.4 0C8.71826 0 9.02348 0.126428 9.24853 0.351472C9.47357 0.576516 9.6 0.88174 9.6 1.2V10.8C9.6 11.1183 9.47357 11.4235 9.24853 11.6485C9.02348 11.8736 8.71826 12 8.4 12C8.08174 12 7.77652 11.8736 7.55147 11.6485C7.32643 11.4235 7.2 11.1183 7.2 10.8V7.2H2.4V10.8C2.4 11.1183 2.27357 11.4235 2.04853 11.6485C1.82348 11.8736 1.51826 12 1.2 12C0.88174 12 0.576516 11.8736 0.351472 11.6485C0.126428 11.4235 0 11.1183 0 10.8V1.2C0 0.88174 0.126428 0.576516 0.351472 0.351472C0.576516 0.126428 0.88174 0 1.2 0C1.51826 0 1.82348 0.126428 2.04853 0.351472C2.27357 0.576516 2.4 0.88174 2.4 1.2V4.8ZM17.856 10.0224V12H12V10.7832L12.12 10.488L14.262 8.1924C14.778 7.6704 15.2136 7.2684 15.3696 6.9792C15.5184 6.7032 15.588 6.4668 15.588 6.2748C15.588 6.1068 15.54 5.9844 15.4356 5.8824C15.3796 5.82952 15.3133 5.78871 15.2408 5.76249C15.1684 5.73628 15.0913 5.72522 15.0144 5.73C14.9337 5.72715 14.8533 5.74275 14.7795 5.7756C14.7057 5.80845 14.6403 5.8577 14.5884 5.9196C14.4624 6.06 14.3904 6.2652 14.3808 6.5496L14.3664 6.9552H12.1776L12.1968 6.516C12.2376 5.634 12.5232 4.92 13.0584 4.392C13.5936 3.864 14.2824 3.6 15.102 3.6C15.6108 3.6 16.068 3.7092 16.47 3.93C16.8744 4.1551 17.2091 4.48725 17.4372 4.89C17.6748 5.3016 17.7972 5.73 17.7972 6.1656C17.7972 6.6648 17.5212 7.1844 17.25 7.734C16.9836 8.274 16.6092 8.7852 15.9732 9.6L15.834 9.7476L15.5292 10.0224H17.8548H17.856Z" fill="#333840"/>
  </svg>
</template>
<script>
import iconMixin from '../iconMixin'

export default {
  name: 'H2Icon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

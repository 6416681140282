import apiService from "../../services/api"

const newCode = function () {
  return Math.floor(Math.random() * (999999 - 100000) + 100000)
}

const newSphere = function ({ isChildren }) {
  const sphere = {
    id: null,
    code: newCode(),
    sort: 0,
    name: ''
  }

  if (isChildren === false) {
    sphere.children = []
  }

  return sphere
}

const recount = function (item, index) {
  item.sort = (index + 1) * 10
  return item
}

function createSpheresModule(languageService) {
  const getDefaultSpheres = function () {
    return [
      {
        id: null,
        code: newCode(),
        sort: 10,
        name: languageService.i18n.t('setup.spheres.defaultList.development'),
        children: [
          {
            id: null,
            code: newCode(),
            sort: 10,
            name: languageService.i18n.t('setup.spheres.defaultList.backend')
          },
          {
            id: null,
            code: newCode(),
            sort: 20,
            name: languageService.i18n.t('setup.spheres.defaultList.frontend')
          }
        ]
      },
      {
        id: null, code: newCode(), sort: 20, name: languageService.i18n.t('setup.spheres.defaultList.design'), children: []
      }
    ]
  }

  const namespaced = true

  const state = {
    spheres: []
  }

  const mutations = {
    SET_SPHERES(state, spheres) {
      state.spheres = spheres
    },

    UPDATE_SPHERE(state, {sphere, data}) {
      state.spheres = state.spheres.map(item => {
        if (item === sphere) {
          return {...item, ...data}
        }

        item.children = item.children.map(child => {
          if (child === sphere) {
            return {...child, ...data}
          }

          return child
        })

        return item
      })
    },

    ADD_SPHERE(state, {parentSphere, after}) {
      const sphere = newSphere({isChildren: parentSphere !== null})

      if (parentSphere) {
        const foundSphere = state.spheres.find(item => item === parentSphere)
        foundSphere.children = addSphere(foundSphere.children)
      } else {
        state.spheres = addSphere(state.spheres)
      }

      function addSphere(sphereList) {
        const insertIndex = sphereList.findIndex(item => item === after)

        if (insertIndex === -1 || insertIndex + 1 === sphereList.length) {
          sphereList.push(sphere)
        } else {
          sphereList.splice(insertIndex + 1, 0, sphere)
        }

        return sphereList.map(recount)
      }
    },

    DELETE_SPHERE(state, sphere) {
      for (let i = 0; i < state.spheres.length; i++) {
        const item = state.spheres[i]

        if (item === sphere) {
          state.spheres.splice(i, 1)
          state.spheres = state.spheres.map(recount)
          return
        }

        if (item.children) {
          const childIndex = item.children.findIndex(item => item === sphere)

          if (childIndex !== -1) {
            item.children.splice(childIndex, 1)
            state.spheres = state.spheres.map(recount)
            return
          }
        }
      }
    },

    RE_SORT_SPHERES(state, {parent, source, target, position}) {
      if (parent) {
        const parentSphere = state.spheres.find(item => item === parent)
        parentSphere.children = reSort(parentSphere.children)
      } else {
        state.spheres = reSort(state.spheres)
      }

      function reSort(sphereList) {
        const sourceIndex = sphereList.findIndex(item => item === source)
        const targetIndex = sphereList.findIndex(item => item === target)
        const indexDiff = position === 'before'
          ? 0
          : 1

        //console.log(sourceIndex, targetIndex, position)
        sphereList.splice(sourceIndex, 1)
        sphereList.splice(targetIndex + indexDiff, 0, source)

        return sphereList.map(recount)
      }

    }
  }

  const actions = {
    fetchSpheres({ commit, rootGetters }, { useDefault = false }) {
      if (useDefault) {
        commit('SET_SPHERES', getDefaultSpheres())

        return Promise.resolve()
      }

      const { project } = rootGetters
      return apiService.get(`projects/${project.code}/spheres`)
        .then(({data}) => {
          commit('SET_SPHERES', data)

          return data
        })
    },

    createSphere({commit}, {parentSphere, after = null}) {
      commit('ADD_SPHERE', {parentSphere, after})
    },

    updateSphere({commit}, {sphere, ...data}) {
      commit('UPDATE_SPHERE', {sphere, data})
    },

    deleteSphere({commit}, {sphere}) {
      commit('DELETE_SPHERE', sphere)
    },

    reSortSpheres({commit}, {parent, source, target, position}) {
      commit('RE_SORT_SPHERES', {parent, source, target, position})
    },

    saveSpheres({ commit, rootGetters }, { spheres }) {
      const spheresData = spheres.map(item => {
        // eslint-disable-next-line
        const { children, code, ...data} = item

        return {
          ...data,
          // eslint-disable-next-line
          children: children.map(({ code, ...data }) => data)
        }
      })

      const { project } = rootGetters
      return apiService.put(`projects/${project.code}/spheres`, {
        spheres: spheresData
      }).then(({data}) => {
        commit('SET_SPHERES', data)

        return data
      })
    },

    updateMembers({dispatch}, {sphere, members}) {
      dispatch('updateSphere', {sphere, members})

      apiService.post(`spheres/${sphere.id}/members`, {members})
    }
  }

  const getters = {
    spheres: ({spheres}) => spheres,

    teams: ({spheres}) => spheres.reduce((teams, sphere) => {
      if (sphere.children.length) {
        teams.push(...sphere.children.map(item => {
          return {sphere: item, parent: sphere.name}
        }))
      } else {
        teams.push({sphere})
      }

      return teams
    }, [])
  }

  return {
    namespaced,
    state,
    mutations,
    actions,
    getters
  }
}

export default createSpheresModule

<template>
  <div class="board-task-item">
    <div class="board-task-item__block">
      <div class="board-task-item__line">
        <el-skeleton-item variant="text" style="width: 60px; height: 20px;"/>

        <div class="board-task-item__info">
          <el-skeleton-item variant="text" style="width: 80px; height: 20px;"/>

          <el-skeleton-item
            variant="text"
            style="width: 180px; height: 20px;"
            class="ml-2"
            v-if="withFields.includes('sphere')"
          />

          <el-skeleton-item
              variant="text"
              style="width: 20px; height: 20px;"
              class="ml-2"
              v-if="withFields.includes('assignee')"
          />
        </div>
      </div>

      <div class="board-task-item__title">
        <el-skeleton-item variant="text" style="width: 75%; height: 20px;"/>
      </div>

      <div class="board-task-item__line">
        <div class="board-task-item__status">
          <el-skeleton-item variant="text" style="width: 20px; height: 20px;"/>

          <div class="board-task-item__status-details">
            <el-skeleton-item variant="text" style="width: 80px; height: 20px;"/>
          </div>
        </div>

        <div class="board-task-item__scores">
          <el-skeleton-item variant="text" style="width: 60px; height: 20px;"/>

          <el-skeleton-item variant="text" style="width: 60px; height: 20px;" class="ml-3"/>
        </div>
      </div>
    </div>

    <div class="board-task-item__gap"></div>
  </div>
</template>
<script>
  export default {
    name: 'SkeletonBoardTaskItem',

    props: {
      withFields: {
        type: Array,
        default: () => [],
      }
    }
  }
</script>

<template>
  <div class="workload-member">
    <div class="workload-member__header">
      <div class="workload-member__profile">
        <div class="workload-member__photo">
          <member-popover :member="member" :show-task-action="false">
            <user-photo :user="member" size="xmd"/>
          </member-popover>
        </div>

        <div class="workload-member__info">
          <div class="workload-member__title">
            {{ member.first_name }} {{ member.last_name }}
          </div>

          <div class="workload-member__spheres">
            <sphere-badge
              v-for="sphere in member.project.spheres.slice(0, 2)"
              :key="sphere.id"
              :sphere="sphere"
            />
          </div>
        </div>
      </div>

      <div class="workload-member__metrics">
        <div class="workload-member__metrics-value">
          {{ tasks.length > 0
            ? $tc('workload.tasksCount', tasks.length, { tasks: tasks.length })
            : $t('workload.noTasks')
          }}
        </div>

        <el-tooltip
          effect="dark"
          :content="$t('workload.tasksUntil')"
          placement="top"
          transition="sw-slide-top"
        >
          <div class="workload-member__metrics-value">
            <clock-icon class="workload-member__metrics-icon"/>
            {{ estimatedFinishAt }}
          </div>
        </el-tooltip>
      </div>
    </div>

    <workload-member-tasks
      :member="member"
      :tasks="tasks"
      @open-task="onOpenTask"
      @create-after="onCreateAfterTask"
      @create="onCreateTask"
    />
  </div>
</template>
<script>
  import UserPhoto from '@/components/ui/UserPhoto'
  import ClockIcon from '@/components/icons/ClockIcon'
  import MemberPopover from '@/components/members/MemberPopover'
  import WorkloadMemberTasks from '@/components/workload/WorkloadMemberTasks'
  import { getEstimatedTime } from '@/services/scoring'
  import { lcFirst } from '@/utils/dataTypes'
  import SphereBadge from "@/components/ui/SphereBadge"

  export default {
    name: 'WorkloadMemberItem',

    components: { SphereBadge, ClockIcon, MemberPopover, UserPhoto, WorkloadMemberTasks },

    props: {
      member: {
        type: Object,
        required: true
      },

      tasks: {
        type: Array,
        required: true
      }
    },

    computed: {
      estimatedFinishAt () {
        if (this.tasks.length === 0) {
          return 'неизвестно'
        }

        const lastTask = this.tasks[this.tasks.length - 1]
        return lcFirst(getEstimatedTime(lastTask.estimatedFinishAt))
      }
    },

    methods: {
      onOpenTask (id) {
        this.$emit('open-task', id)
      },

      onCreateAfterTask (task) {
        this.$emit('create-task', { task, member: this.member })
      },

      onCreateTask () {
        this.$emit('create-task', { member: this.member })
      }
    }
  }
</script>
<style lang="sass">
  .workload-member
    box-sizing: border-box
    flex: 0 0 348px
    border-right: 1px solid var(--block-border-bg)
    display: flex
    flex-flow: column nowrap
    overflow: hidden

    &__header
      background: var(--block-bg-color)
      box-sizing: border-box
      padding: 12px 24px
      width: 100%
      height: 100px
      border-bottom: 1px solid var(--block-border-bg)

    &__profile
      display: flex

    &__photo
      margin-right: 12px

    &__title
      font-weight: 500
      margin-bottom: 6px

    &__spheres
      display: flex
      align-items: center
      gap: 6px

      &-more
        font-size: 13px
        font-weight: 500

    &__metrics
      margin-top: 16px
      align-items: center
      display: flex
      justify-content: space-between

      &-icon
        margin-right: 6px
        .path-stroke
          stroke: var(--text-primary-color)

      &-value
        align-items: center
        color: var(--text-light-color)
        display: flex
        font-size: 12px
</style>

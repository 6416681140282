<template>
  <div class="ui-spheres-dropdown">
    <ui-dropdown
        :options="sphereList"
        :value="value"
        :multiple="multiple"
        :width-by-reference="widthByReference"
        :width-by-popover="false"
        popover-class="ui-dropdown_round"
        :reference-class="dropdownClass"
        @input="onSphereChange"
        :placement="placement"
        :click-fn="onChangeOption"
    >
      <template v-slot:default>
        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
          <div>
            <div class="ui-spheres-dropdown__label">
              {{ $t('pages.mySpheres') }}
            </div>

            <div class="ui-spheres-dropdown__badges" v-if="tempValue.length > 0">
              <div
                  class="ui-spheres-dropdown__badge"
                  v-for="sphere in tempValue"
                  :key="sphere.id"
              >
                {{ sphere.name }}
              </div>
            </div>
            <div v-else class="selected-spheres-empty">
              {{ $t('pages.noSpheresSelected') }}
            </div>
          </div>


          <chevron-down class="task-spheres__chevron-icon"/>
        </div>
      </template>

      <template v-slot:option="{ option }">
        <div :class="optionClasses(option)">
          {{ option.name }}
        </div>
      </template>
    </ui-dropdown>
  </div>
</template>
<script>
import UiDropdown from "@/components/tasks/UiDropdown"
import ChevronDown from "../icons/ChevronDown"

export default {
  name: "UiSpheresDropdown",

  components: { UiDropdown, ChevronDown },

  props: {
    spheres: {
      type: Array,
      required: true
    },

    value: {
      required: true
    },

    nullableTitle: {
      type: String,
      default: 'tasks.allTasks'
    },

    parentsActive: {
      type: Boolean,
      default: true
    },

    acceptNull: {
      type: Boolean,
      default: true
    },

    multiple: {
      type: Boolean,
      default: false
    },

    widthByReference: {
      type: Boolean,
      default: true
    },

    popperClass: {
      type: String,
      default: ''
    },

    dropdownClass: {
      type: String,
      default: ''
    },

    placement: {
      type: String,
      default: 'bottom'
    },

    uiStyle: {
      type: String,
      default: 'slide'
    }
  },

  data () {
    const sphereList = [...this.spheres]

    if (this.acceptNull) {
      sphereList.unshift({
        id: null,
        name: this.$t(this.nullableTitle)
      })
    }

    const tempValue = this.multiple
        ? [...this.value]
        : []

    return {
      sphereList,
      tempValue
    }
  },

  computed: {
    selectedSphere () {
      if (!this.acceptNull && this.value === null) {
        return {
          id: null,
          name: this.$t(this.nullableTitle)
        }
      }

      if (this.multiple) {
        return this.sphereList.filter(item => this.tempValue.find(sphere => sphere.id === item.id))
      }

      return this.sphereList.find(item => item.id === this.value)
    }
  },

  methods: {
    optionClasses (option) {
      const classes = { 'dropdown-sphere-item': true }

      if (option.parent_id) {
        classes['ml-2'] = true
      }

      if (false === this.isAvailable(option)) {
        classes['dropdown-sphere-item_inactive'] = true
      }

      return classes
    },

    isAvailable (sphere) {
      return this.parentsActive || !sphere.is_parent
    },

    onSphereChange (val) {
      if (false === this.isAvailable(val)) {
        return
      }

      if (this.multiple) {
        this.tempValue = val
      }

      this.$emit('input', val)
    },

    onChangeOption (sphere) {
      return this.isAvailable(sphere)
    }
  }
}
</script>
<style lang="sass">
.ui-spheres-dropdown
  .ui-dropdown__reference
    border: 1px solid var(--grey-light-color-100)
    padding: 6px 16px
    background: var(--block-bg-color)

    &:hover
      padding: 6px 16px
      box-shadow: 0 0 0px 3px rgba(101, 158, 255, 0.16)
      background: var(--block-bg-color)

  &__label
    font-size: 12px
    color: var(--text-light-color)
    margin-bottom: 6px

  &__badges
    display: flex
    column-gap: 8px
    row-gap: 8px
    min-height: 26px
    align-items: center
    flex-flow: row wrap

  &__badge
    font-size: 12px
    background: var(--primary-light-color)
    color: var(--primary-color)
    padding: 4px 8px
    border-radius: 4px

  &__selected
    font-size: 22px
    color: var(--text-primary-color)
    cursor: pointer
    font-weight: 500
    display: flex
    align-items: center


  &__reference-bordered
    border: 1px solid transparent

    &.ui-dropdown__reference:hover
      border: 1px solid var(--block-border-bg)
      background: var(--block-bg-color)

  &__chevron-icon
    margin-left: 10px
    transition: all .2s ease-in
    transform: translate(0px, 2px)

    path
      fill: var(--text-primary-color)

  &__item
    padding: 6px 12px
    font-size: 16px

.ui-dropdown__reference_opened
  .task-spheres__chevron-icon
    transform: rotate(-180deg) translate(0px, 0px)

.ui-dropdown__option
  .dropdown-sphere-item_inactive
    color: var(--grey-color-100)
    cursor: default


@media (max-width: 1280px)
  .task-spheres
    &__selected
      font-size: 20px

</style>

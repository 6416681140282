export default {
  methods: {
    onDocumentClickHandler (e) {
      if (!this.isOpen) {
        return
      }

      const reference = this.$refs.reference
      const popper = this.$refs.popper.$el

      if (!this.$el || !reference || this.$el.contains(e.target) || reference.contains(e.target) || !popper || popper.contains(e.target)) {
        return;
      }

      this.isOpen = false
    },
  },

  created() {
    document.addEventListener('click', this.onDocumentClickHandler)
  },

  destroyed () {
    document.removeEventListener('click', this.onDocumentClickHandler)
  },
}

<template>
  <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-stroke" d="M1 9H10.625" stroke="#2C3E50" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path class="path-stroke" d="M1 5H15" stroke="#2C3E50" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path class="path-stroke" d="M1 1H15" stroke="#2C3E50" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'DescriptionIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 10,
        defaultHeight: 10
      }
    }
  }
</script>

<template>
  <div class="setup-layout">
    <div class="setup-layout__area setup-layout__area_worked">
      <div class="setup-block">
        <div class="setup-block__logo">
          <logo-icon class="setup-block__logo-icon"/>
          Scorework
        </div>

        <div class="setup-block__body">
          <h1 class="setup-title">{{ $t('setup.general.title') }}</h1>
          <settings-form
            submit-title="setup.general.submit"
            :with-prefix="false"
            @submit="onSubmit"
            @focus-field="onFocusField"
          />
        </div>
      </div>
    </div>
    <div class="setup-layout__area setup-layout__area_example">
      <general-example :active-hint="activeHint" @change="onHintChange"/>
    </div>
  </div>
</template>

<script>
import LogoIcon from "@/components/icons/LogoIcon"
import SettingsForm from "@/components/projects/SettingsForm"
import GeneralExample from "@/components/setup/GeneralExample"
import {mapGetters} from "vuex";

export default {
  name: 'CreateGeneral',

  components: { LogoIcon, GeneralExample, SettingsForm },

  data () {
    return {
      workspace: {
        name: '',
        task_prefix: '',
        work_schedule: [
          {
            days: [1,2,3,4,5],
            work_hours: {
              start: '10:00',
              end: '19:00'
            }
          }
        ],
        currency_id: 'RUB',
        weeks_sprint_duration: 2,
      },

      activeHint: 'name'
    }
  },

  computed:{
    ...mapGetters(['project']),
  },

  methods: {
    onSubmit () {
      this.$router.push({ name: 'setup.members', params: { project: this.project.code } })
    },

    onHintChange (val) {
      this.activeHint = val
    },

    onFocusField (name) {
      this.activeHint = name
    }
  }
}
</script>

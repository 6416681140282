<template>
  <div class="member-short-item" :class="{ 'member-short-item_active': active }" @click="onClick">
    <user-photo :user="member" size="md" class="member-short-item__photo"/>
  </div>
</template>

<script>
  import UserPhoto from "../ui/UserPhoto"

  export default {
    name: "MemberShortItem",
    components: {UserPhoto},
    props: {
      member: {
        type: Object,
        required: true
      },

      active: {
        type: Boolean,
        required: true
      }
    },

    methods: {
      onClick () {
        this.$emit('click')
      }
    }
  }
</script>
import apiService from '../../services/api'

export const state = {
  taskPrediction: {}
}

export const mutations = {
  SET_PREDICTION_DATA (state, data) {
    state.taskPrediction = data
  }
}

export const actions = {
  fetchTaskPrediction ({ commit }, { memberId = null, sphereId = null, points = null }) {
    return apiService.get('stats/prediction', {
      member_id: memberId,
      sphere_id: sphereId,
      points,
    }).then(({ data }) => {
      commit('SET_PREDICTION_DATA', data)
    })
  }
}

export const getters = {
  taskPrediction: ({ taskPrediction }) => taskPrediction,
}

export const namespaced = true

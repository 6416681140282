<template>
<el-popover
  placement="bottom"
  trigger="hover"
  popper-class="member-popover"
  transition="sw-slide-top"
  :visible-arrow="false"
  :open-delay="150"
  :close-delay="100"
  width="356"
  @show="onShow"
>
  <div class="member-card">
    <div class="member-card__header">
      <div class="member-card__photo">
        <user-photo :user="member" size="big"/>
      </div>
      <div class="member-card__personal">
        <div class="member-card__title">
          {{ member.first_name }} {{ member.last_name }}
          <span class="member-card__you-label" v-if="isYou">({{ $t('members.you') }})</span>
        </div>
        <div class="member-card__email">
          {{ member.email }}
        </div>
        <div class="member-card__spheres">
          <sphere-badge
            v-for="sphere in member.project.spheres"
            :key="sphere.id"
            :sphere="sphere"
          />
        </div>
      </div>
    </div>
    <div class="member-card__info">
      <div class="member-card__schedule-head">
        <div class="member-card__schedule-title">
          <schedule-icon class="member-card__schedule-icon"/>
          {{ $t('members.schedule') }}
        </div>

        <div class="member-work-status" v-if="isWorkingNow">
          {{ $t('members.workNow') }}
        </div>
      </div>
      <member-schedule :schedule="workSchedule"/>
      <div class="member-card__actions">
        <div class="member-card__action" v-if="showTaskAction">
          <ui-button ui-class="block size_md" @click="onCreateTask">
            {{ $t('members.assignTask') }}
          </ui-button>
        </div>
        <div class="member-card__action">
          <ui-button ui-class="block size_md light" v-if="!isYou" @click="onCreateChannel">
            {{ $t('members.sendMessage') }}
          </ui-button>
        </div>
      </div>
    </div>
  </div>
  <slot slot="reference"></slot>
</el-popover>
</template>
<script>
import { DateTime } from 'luxon'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import UiButton from 'scorework-ui/src/components/UiButton'

import MemberSchedule from "@/components/members/MemberSchedule"
import ScheduleIcon from "@/components/icons/ScheduleIcon"
import SphereBadge from "@/components/ui/SphereBadge"
import UserPhoto from "@/components/ui/UserPhoto"

const { mapActions: messengerActions } = createNamespacedHelpers('messenger')

export default {
  name: 'MemberPopover',

  components: { MemberSchedule, ScheduleIcon, SphereBadge, UiButton, UserPhoto },

  props: {
    member: {
      type: Object,
      required: true
    },

    mockChatAction: {
      type: Boolean,
      default: false
    },

    showTaskAction: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      isWorkingNow: false
    }
  },

  computed: {
    ...mapGetters(['authUser']),

    workSchedule () {
      return this.member.work_schedule[0]
    },

    isYou () {
      return this.authUser.member_id === this.member.member_id
    }
  },

  methods: {
    ...messengerActions(['createChannel']),

    onShow () {
      const now = DateTime.local()
      const { start, end } = this.workSchedule.work_hours

      const startTime = start.split(':')
      const endTime   = end.split(':')

      let startDate = DateTime.fromObject({
        year: now.year,
        month: now.month,
        day: now.day,
        hour: Number(startTime[0]),
        minute: Number(startTime[1]),
        zone: 'local'
      })

      let endDate = DateTime.fromObject({
        year: now.year,
        month: now.month,
        day: now.day,
        hour: Number(endTime[0]),
        minute: Number(endTime[1]),
        zone: 'local'
      })

      if (start > end) {
        const nowTime = now.hour * 100 + now.minute
        if (nowTime > Number(endTime)) {
          endDate = endDate.plus({ days: 1 })
        } else {
          startDate = startDate.plus({ days: -1 })
        }
      }

      if (this.workSchedule.days.indexOf(startDate.weekday) === -1) {
        this.isWorkingNow = false
      } else {
        this.isWorkingNow = now >= startDate && now <= endDate
      }
    },

    onCreateChannel () {
      if (this.mockChatAction) {
        return
      }

      this.createChannel({
        type: 'direct',
        members: [this.authUser.member_id, this.member.member_id],
        name: ''
      }).then(channel => {
        this.$router.push({ name: 'messenger.channel', params: { channelId: channel.id } })
      })
    },

    onCreateTask () {
      this.$emit('assign', { memberId: this.member.member_id })
    }
  }
}
</script>
<style lang="sass">
.member-popover
  box-shadow: var(--popover-shadow)
  border-radius: 16px
  background: transparent
  padding: 0
  border: 0

.member-card
  border-radius: 16px
  background: var(--block-bg-color)

  &__header
    padding: 20px 20px 16px
    display: flex
    background-color: var(--grey-light-color-24)
    border-radius: 16px 16px 0 0
    background-image: url("../../assets/images/bg.png")
    background-size: cover
    background-position: center

  &__photo
    flex: 0 0 64px
    margin-right: 16px

  &__personal
    flex: 1

  &__title
    font-size: 16px
    font-weight: 500
    color: var(--text-primary-color)
    margin-bottom: 4px

  &__you-label
    color: var(--grey-color-100)

  &__email
    font-size: 12px
    color: var(--primary-color)

  &__spheres
    display: flex
    flex-flow: row wrap
    margin-top: 8px
    gap: 6px

  &__info
    border-radius: 0 0 16px 16px
    padding: 16px 20px 20px

  &__schedule-head
    align-items: center
    display: flex
    justify-content: space-between
    margin-bottom: 8px

  &__schedule-title
    font-size: 13px
    font-weight: 500
    color: var(--text-light-color)
    display: flex
    align-items: center

  &__schedule-icon
    margin-right: 6px

  &__actions
    align-items: center
    display: flex
    margin-top: 20px

    .member-card__action
      margin-right: 16px

    .member-card__action:last-child
      margin-right: 0

  &__action
    flex: 1

.member-work-status
  align-items: center
  border: 1px solid var(--success-color)
  border-radius: 4px
  color: var(--success-color)
  display: flex
  font-size: 10px
  height: 22px
  padding: 0 8px

  &::before
    background: var(--success-color)
    border-radius: 2px
    content: ""
    height: 4px
    margin-right: 4px
    width: 4px

  &_disabled
    border-color: var(--grey-color-100)
    color: var(--grey-color-100)

    &::before
      background: var(--grey-color-100)

.dark
  .member-card__header
    background: var(--bg-color)
    background-image: none
</style>

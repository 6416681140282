import apiService from "@/services/api"
import { transformTaskItem } from "@/transformers/tasks";
import {transformDateToObject} from "@/services/scoring";

const createSprintsModule = (languageService) => {
  const state = {
    sprint: null,

    sprintMembers: [],

    sprints: [],

    pastSprints: []
  }

  const mutations = {
    SET_SPRINT (state, sprint) {
      const { data, members, ...sprintData } = sprint

      state.sprint = {
        ...sprintData,
        members: members.map(item => {
          if (item.member) {
            return item
          }

          return {
            ...item,
            member: { member_id: null }
          }
        }),
        data: transformSprint(data)
      }
    },

    SET_SPRINT_MEMBERS (state, sprintMembers) {
      state.sprintMembers = sprintMembers
    },

    SET_SPRINTS (state, sprints) {
      state.sprints = sprints
    },

    SET_PAST_SPRINTS (state, sprints) {
      state.pastSprints = sprints
    }
  }

  const actions = {
    async fetchSprint ({ commit, rootGetters }, { id = null }) {
      const { project } = rootGetters

      let sprintsUrl = `projects/${project.code}/sprints`

      if (id) {
        sprintsUrl += `/${id}`
      } else {
        sprintsUrl += '/active'
      }

      const { data } = await apiService.get(sprintsUrl)
      const { tasks, ...sprintData } = data

      const transformFunc = transformTaskItem(rootGetters)
      commit('SET_SPRINT', {...sprintData, tasks: tasks.map(transformFunc)})
      return data
    },

    fetchSprints ({ commit, rootGetters }) {
      const { project } = rootGetters

      return apiService.get(`projects/${project.code}/sprints/last`)
        .then(({ data }) => {
          commit('SET_SPRINTS', data.map(transformSprint))

          return data
        })
    },

    fetchPastSprints ({ commit, rootGetters }) {
      const { project } = rootGetters

      return apiService.get(`projects/${project.code}/sprints/past`)
        .then(({ data }) => {
          commit('SET_PAST_SPRINTS', data.map(transformSprint))

          return data
        })
    },
  }

  const getters = {
    sprint: ({ sprint }) => sprint,

    sprints: ({ sprints }) => sprints,

    sprintMembers: ({ sprintMembers }) => sprintMembers,

    pastSprints: ({ pastSprints }) => pastSprints,
  }

  const transformSprint = (sprint) => {
    const startDate = transformDateToObject(sprint.started_at, 'yyyy-MM-dd')
    const finishedDate = transformDateToObject(sprint.finished_at, 'yyyy-MM-dd')

    return {
      ...sprint,
      name: languageService.i18n.t('sprints.sprintNumber', { number: sprint.number }),
      duration: `${startDate.day} ${startDate.monthShort} - ${finishedDate.day} ${finishedDate.monthShort}`,
      startedAt: `${startDate.day} ${startDate.monthShort} ${startDate.year}`,
      finishedAt: `${finishedDate.day} ${finishedDate.monthShort} ${finishedDate.year}`
    }
  }

  return {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
  }
}

export default createSprintsModule

<template>
  <div class="setup-layout">
    <div class="setup-layout__area setup-layout__area_worked">
      <div class="setup-block">
        <div class="setup-block__logo">
          <logo-icon class="setup-block__logo-icon"/>
          Scorework
        </div>

        <div class="setup-block__body">
          <div class="setup-spheres">
            <div class="setup-spheres__header">
              <h1 class="setup-title">{{ $t('setup.spheres.title') }}</h1>
              <div class="setup-description" v-html="$t('setup.spheres.description')"></div>
            </div>

            <div class="spheres-title">
              {{ $t('spheres.title') }}
              <el-tooltip class="item" effect="dark" :content="$t('spheres.addSphere')" placement="top">
                <div class="nsphere-item__control nsphere-item__control_add" @click="onCreateSphere">
                  <plus class="nsphere-item__control-icon"/>
                </div>
              </el-tooltip>
            </div>

            <spheres-list :spheres="spheres"/>

            <div class="mt-4">
              <ui-button ui-class="block" @click="onSave">{{ $t('common.save') }}</ui-button>
              <ui-button ui-class="block plain" class="mt-2" @click="onSkip">{{ $t('setup.spheres.skipText') }}</ui-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="setup-layout__area setup-layout__area_example">
      <spheres-example :active-hint="activeHint" @change="onHintChange"/>
    </div>
  </div>
</template>
<script>
import LogoIcon from "@/components/icons/LogoIcon"
import UiButton from 'scorework-ui/src/components/UiButton'
import Plus from "../../components/icons/Plus"
import SpheresList from "../../components/setup/SpheresList";
import SpheresExample from "@/components/setup/SpheresExample"
import {createNamespacedHelpers, mapActions, mapGetters} from "vuex"

const { mapGetters: spheresGetters, mapActions: spheresActions } = createNamespacedHelpers('spheres')

export default {
  name: 'SetupSpheres',

  components: { LogoIcon, UiButton, SpheresExample, SpheresList, Plus },

  data () {
    return {
      activeHint: 'tasks'
    }
  },

  computed: {
    ...mapGetters(['project']),
    ...spheresGetters(['spheres'])
  },

  methods: {
    ...mapActions(['fetchInit']),
    ...spheresActions(['fetchSpheres', 'createSphere', 'saveSpheres']),

    loadSpheres () {
      return this.fetchSpheres({ useDefault: true })
    },

    onCreateSphere () {
      this.createSphere({ parentSphere: null })
    },

    onSave () {
      this.saveSpheres({ spheres: this.spheres })
      this.goToWorkspace()
    },

    onSkip () {
      this.goToWorkspace()
    },

    goToWorkspace () {
      this.fetchInit({ project: this.project.code }).then(() => {
        this.$router.push({ name: 'tour.tasks', params: { project: this.project.code } })
      })
    },

    onHintChange (val) {
      this.activeHint = val
    }
  },

  created () {
    Promise.all([
      this.loadSpheres()
    ])
  }
}
</script>

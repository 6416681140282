<template>
<ui-form :model="target" :rules="rules" :submit-fn="submitFn" ref="form">
  <div class="form-control target-form__title">
    <color-picker class="target-card__color-selector" v-model="target.color"/>

    <text-field
      prop="name"
      v-model="target.name"
      :label="$t('goals.form.name')"
      class="ui-text-field"
      :focused="focusOnName"
    />
  </div>

  <div class="form-control">
    <text-editor
      :value="target.description"
      :placeholder="$t('goals.form.descriptionPlaceholder')"
      @input="val => onDescriptionChange(val)"
      @init="onEditorInit"
      class="target-form__description"
    />
  </div>

  <div>
    <ui-button class="target-form__btn" ui-class="block" @click="onSubmit">{{ $t(btnTitle) }}</ui-button>
  </div>
</ui-form>
</template>
<script>
import { required } from "vuelidate/lib/validators"
import { mapActions } from "vuex"

import UiForm from 'scorework-ui/src/components/form/UiForm'
import TextField from 'scorework-ui/src/components/TextField'
import UiButton from 'scorework-ui/src/components/UiButton'

import ColorPicker from "@/components/targets/ColorPicker"
import TextEditor from "@/components/ui/TextEditor"
import {handleAsyncErrors} from "scorework-ui/src/components/form/helpers"

export default {
  name: 'TargetForm',

  components: { ColorPicker, UiForm, TextField, TextEditor, UiButton },

  props: {
    form: {
      type: Object,
      default: () => ({})
    },

    focusOnName: {
      type: Boolean,
      default: true,
    }
  },

  data () {
    return {
      target: {
        id: 0,
        name: '',
        color: 'red',
        description: ''
      },

      descriptionValue: null,

      rules: {
        name: {
          rules: { required },
          messages: {
            required: this.$t('goals.form.errors.requiredName')
          }
        }
      }
    }
  },

  computed: {
    btnTitle () {
      return this.target.id
          ? 'goals.form.btnUpdate'
          : 'goals.form.btnCreate'
    }
  },

  methods: {
    ...mapActions(['createTarget', 'updateTarget']),

    submitFn () {
      const saveFunc = this.target.id === 0
          ? this.createTarget
          : this.updateTarget

      let description = null
      if (this.descriptionValue) {
        const { getJSON, getHTML } = this.descriptionValue

        description = JSON.stringify({
          json: getJSON(),
          html: getHTML()
        })
      }

      saveFunc({ ...this.target, description })
        .then(() => {
          const saveMessage = this.target.id === 0
              ? 'createdTitle'
              : 'updatedTitle'

          this.$notify({
            title: this.$t('common.saved'),
            message: this.$t(`goals.form.${saveMessage}`),
            position: 'bottom-right',
            type: 'success'
          })

          this.$emit('submit')
        })
        .catch(handleAsyncErrors(this.rules, () => this.$refs.form.validate()))
    },

    onDescriptionChange (value) {
      this.descriptionValue = value
    },

    onSubmit () {
      this.$refs.form.submit()
    },

    onEditorInit ({ editor }) {
      this.descriptionValue = { getHTML: () => editor.getHTML(), getJSON: () => editor.getJSON() }
    }
  },

  created () {
    if (this.form.id) {
      this.target = this.form
      this.descriptionValue = this.form.description
    }
  }
}
</script>

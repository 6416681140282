<template>
  <div class="task-comment-form">

    <user-photo :user="authUser" class="task-comment-form__author"/>

    <div class="task-comment-form__editor">
      <text-editor
        value=""
        :placeholder="$t('tasks.enterTheComment')"
        @init="onEditorInit"
      >
        <template v-slot:right-bar>
          <el-tooltip :content="$t('common.send')" placement="top" transition="sw-slide-top">
            <button class="sw-editor__menu-item sw-editor__menu-item_primary" @click="onSubmit">
              <send-letter-icon class="sw-editor__menu-icon" style="width: 18px; height: 18px"/>
            </button>
          </el-tooltip>
        </template>
      </text-editor>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from "vuex"

import TextEditor from "@/components/ui/TextEditor"
import SendLetterIcon from "@/components/icons/SendLetterIcon"
import UserPhoto from "@/components/ui/UserPhoto";

const { mapActions: taskActions } = createNamespacedHelpers('task')

export default {
  name: 'TaskCommentForm',

  components: {UserPhoto, SendLetterIcon, TextEditor },

  props: {
    taskId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      text: '',
      editor: null
    }
  },

  computed: {
    ...mapGetters(['authUser']),
  },

  methods: {
    ...taskActions(['addComment']),

    onSubmit () {
      const div = document.createElement("div")
      div.innerHTML = this.editor.getHTML()

      const message = div.innerText || ""

      if (!message.trim()) {
        return
      }

      const text = JSON.stringify({
        json: this.editor.getJSON(),
        html: this.editor.getHTML()
      })
      this.editor.commands.clearContent(true)

      this.addComment({ taskId: this.taskId, text })
        .then(() => {
          this.$notify({
            title: this.$t('common.added'),
            message: this.$t('tasks.commentWasSuccessfullyAdded'),
            position: 'bottom-right',
            type: 'success'
          })
        })
    },

    onEditorInit ({ editor }) {
      this.editor = editor
    }
  }
}
</script>

<template>
  <div>
    <transition-group name="fade-slide" tag="div" class="target-list" appear>
      <target-item
        v-for="target in targets"
        :key="target.id"
        :target="target"
        @delete="onDelete"
        @filter="onFilter"
        @finish="onFinish"
        @edit="onEdit"
      />
    </transition-group>

    <confirm-finish-target-modal
      v-if="showFinishModal"
      :target="selectedTarget"
      @submit="doFinishTarget"
      @close="showFinishModal = false"
    />
  </div>
</template>
<script>
import ConfirmFinishTargetModal from "@/components/targets/ConfirmFinishTargetModal"
import TargetItem from "./TargetItem"
import { mapActions } from "vuex"

export default {
  name: 'TargetList',

  components: { ConfirmFinishTargetModal, TargetItem },

  props: {
    targets: {
      type: Array,
      required: true
    },

    targetsLoaded: {
      type: Boolean,
      required: true
    },

    isTour: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selectedTarget: {},
      showFinishModal: false
    }
  },

  methods: {
    ...mapActions(['deleteTarget', 'finishTarget']),
    ...mapActions('tasks', ['changeTaskFilter']),

    onDelete (target) {
      if (this.isTour) {
        return
      }
      this.deleteTarget({ targetId: target.id })
    },

    onFilter (target) {
      if (this.isTour) {
        return
      }

      const { query } = this.$route
      this.$router.push({
        query: {
          ...query,
          target_id: target.id,
        },
      })

      this.changeTaskFilter({
        filter: { target }
      })
    },

    onFinish (target) {
      if (this.isTour) {
        return
      }

      if (target.total_tasks > target.finished_tasks) {
        this.selectedTarget = target
        this.showFinishModal = true

        return
      }

      this.doFinishTarget(target)
    },

    onEdit (target) {
      this.$emit('edit', target)
    },

    doFinishTarget (target) {
      this.finishTarget({ id: target.id })
        .then(() => {
          this.showFinishModal = false
          this.$notify({
            title: this.$t('goals.goalClosed'),
            message: this.$t('goals.goalWasSuccessfullyClosed'),
            position: 'bottom-right',
            type: 'success'
          })
        })
    }
  },

  created () {
  }
}
</script>
<style lang="sass">
.targets-empty
  padding: 14px 12px 0

  &__image
    margin-right: 24px

  &__icon
    width: 72px
    height: auto

    .path-fill
      fill: var(--text-primary-color)

  &__content
    font-size: 14px
    color: var(--text-light-color)
    padding-bottom: 24px
    line-height: 1.5

    ol
      padding-left: 20px

    li
      margin-bottom: 12px
      line-height: 1.5

  &__title
    margin-bottom: 18px
    font-weight: 500
    font-size: 22px
    color: var(--text-primary-color)
</style>

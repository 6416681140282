import apiService from "../../services/api"
import { sortByField } from "@/utils/arrays"
import { sortTasks, transformTask, transformTaskItem } from "@/transformers/tasks"

export const namespaced = true

export const state = {
  tasks: {},

  stageCounts: {},

  backlogTasks: [],

  taskFilter: {
    stage_id: 0,
    sphere_id: null,
    target_id: null,
    target: {},
    assignee_ids: []
  },

  searchResults: []
}

export const mutations = {
  SET_TASKS (state, { stageId, tasks }) {
    sortTasks(tasks)

    state.tasks = {
      ...state.tasks,
      [stageId]: tasks
    }
  },

  SET_STAGE_TASKS_COUNT (state, { stageId, count }) {
    state.stageCounts[stageId] = count
  },

  SET_STAGE_COUNTS (state, data) {
    state.stageCounts = data
  },

  SET_BACKLOG_TASKS (state, { tasks }) {
    tasks.sort(sortByField('sort_order'))
    state.backlogTasks = tasks
  },

  INCREMENT_COMMENTS_COUNT (state, { taskId }) {
    const { tasks, taskFilter } = state
    const stageId = taskFilter.stage_id || null
    if (!stageId || !tasks[stageId]) {
      return
    }

    state.tasks = {
      ...tasks,
      [stageId]: tasks[stageId].map(task => {
        if (task.id !== taskId) {
          return task;
        }

        return { ...task, comments_count: task.comments_count + 1 }
      })
    }
  },

  REFRESH_BACKLOG_TASK (state, { id, data }) {
    state.backlogTasks = state.backlogTasks.map(item => {
      if (item.id !== id) {
        return item
      }

      const { state: taskState = {}, ...restData } = data
      return { ...item, ...restData, state: { ...item.state, ...taskState } }
    })
  },

  REFRESH_TASK (state, task) {
    const stageId = task.stage_id || null

    if (!state.tasks[stageId]) {
      return
    }

    const { comments_count, name, target, points, score, sphere, state: taskState } = task
    const assignee = taskState.assignee.member_id ? taskState.assignee : {}

    const stageTasks = state.tasks[stageId].map(item => {
      if (item.id !== task.id) {
        return item
      }

      return {
        ...item,
        name,
        target,
        points,
        comments_count,
        score: assignee.member_id ? item.score : score,
        state: {
          ...item.state,
          assignee_id: assignee.member_id || null,
          assignee,
        },
        sphere: sphere.id ? sphere: null,
      }
    })

    if (!assignee.id) {
      sortTasks(stageTasks)
    }

    state.tasks = {
      ...state.tasks,
      [stageId]: stageTasks,
    }
  },

  SET_TASKS_MUTED (state, assigneeId) {
    const { stage_id: stageId } = state.taskFilter

    state.tasks = { ...state.tasks, [stageId]: state.tasks[stageId].map(task => {
        task.muted = task.state.assignee_id !== assigneeId

        return task
      })}
  },

  SET_TASK_DRAGGED (state, { taskId, assigneeId }) {
    const { stage_id: stageId } = state.taskFilter

    state.tasks = { ...state.tasks, [stageId]: state.tasks[stageId].map(task => {
        task.dragged  = taskId === task.id
        task.muted    = task.state.assignee_id !== assigneeId

        return task
      })}
  },

  SET_TASK_UNDRAGGED (state) {
    const { stage_id: stageId } = state.taskFilter

    state.tasks = { ...state.tasks, [stageId]: state.tasks[stageId].map(task => {
        task.dragged  = false
        task.muted    = false

        return task
      })}
  },

  SET_TASKS_UN_MUTED (state) {
    const { stage_id: stageId } = state.taskFilter

    state.tasks[stageId] = state.tasks[stageId].map(task => {
      task.muted = false

      return task
    })
  },

  CHANGE_TASK_FILTER (state, data) {
    const filter = state.taskFilter

    if (data.assignee_ids) {
      state.taskFilter = { ...filter, assignee_ids: data.assignee_ids }
    }

    if (data.assigneeId) {
      const index = filter.assignee_ids.indexOf(data.assigneeId)

      if (index === -1) {
        filter.assignee_ids.push(data.assigneeId)
      } else {
        filter.assignee_ids.splice(index, 1)
      }

      delete data.assigneeId
    }

    state.taskFilter = { ...filter, ...data }
  },

  SET_SEARCH_RESULTS (state, results) {
    state.searchResults = results
  },

  REMOVE_TASK (state, { taskId }) {
    let isFound = false
    state.tasks = Object.keys(state.tasks).reduce((stageTasks, stageId) => {
      if (isFound) {
        stageTasks[stageId] = state.tasks[stageId]
        return stageTasks
      }

      stageTasks[stageId] = state.tasks[stageId].filter(task => {
        const isMatch = task.id === taskId
        if (isMatch) {
          isFound = true
        }

        return !isMatch
      })

      return stageTasks
    }, {})
  },

  REMOVE_TASK_FROM_STAGE (state, { stageId, taskId }) {
    if (!state.tasks[stageId]) {
      return
    }

    state.tasks = {
      ...state.tasks,
      [stageId]: state.tasks[stageId].filter(item => item.id !== taskId)
    }

    state.stageCounts[stageId] = state.tasks[stageId].length
  },

  REMOVE_TASK_FROM_STATUS (state, { status, taskId }) {
    if (status === 'backlog') {
      state.backlogTasks = state.backlogTasks.filter(item => item.id !== taskId)
    }

    if (status === 'finished') {
      state.finishedTasks.data = state.finishedTasks.data.filter(item => item.id !== taskId)
    }
  },

  DELETE_TASK (state, id) {
    state.tasks = Object.keys(state.tasks).reduce((tasks, stageId) => {
      tasks[stageId] = state.tasks[stageId].filter(task => task.id !== id)

      return tasks
    }, {})

    state.backlogTasks = state.backlogTasks.filter(task => task.id !== id)
  }
}

export const actions = {
  sortTask ({ rootGetters }, { taskId, targetTaskId, position }) {
    const { project } = rootGetters

    return apiService.post(`projects/${project.code}/tasks/${taskId}/sort`, {
      target_task_id: targetTaskId,
      position,
    })
  },

  moveTaskToStage ({ rootGetters }, { taskId, stageId }) {
    const { project } = rootGetters
    return apiService.put(`projects/${project.code}/tasks/${taskId}/stage`, {
      stage_id: stageId
    })
  },

  moveTaskToStatus ({ rootGetters }, { taskId, status }) {
    const { project } = rootGetters
    return apiService.put(`projects/${project.code}/tasks/${taskId}/status`, {
      status
    })
  },

  fetchTasks ({ commit, state, rootGetters }) {
    const { stage_id, sphere_id, target_id, target, assignee_ids } = state.taskFilter
    const { project } = rootGetters

    return apiService.get(`projects/${project.code}/tasks`, {
      stage_id,
      sphere_id,
      target_id: target_id || target.id,
      assignee_ids,
    })
      .then(({ data }) => {
        const { tasks, stage_counts } = data
        const performFunc = transformTaskItem(rootGetters)

        commit('SET_STAGE_COUNTS', stage_counts)
        commit('SET_TASKS', { stageId: stage_id, tasks: tasks.map(performFunc) })
      })
  },

  fetchBacklogTasks ({ dispatch, rootGetters }) {
    const { project } = rootGetters
    return apiService.get(`projects/${project.code}/tasks/backlog`)
      .then(({ data }) => {
        dispatch('setBacklogTasks', { tasks: data })
      })
  },

  setBacklogTasks ({ commit, rootGetters, state }, { tasks, partial = false }) {
    const perform = transformTaskItem(rootGetters)

    if (!partial) {
      commit('SET_BACKLOG_TASKS', {tasks: tasks.map(perform)})
      return
    }

    const updatedTasks = [...state.backlogTasks, ...tasks.map(perform)]
    updatedTasks.sort(sortByField('sort', 'asc'))

    commit('SET_BACKLOG_TASKS', { tasks: updatedTasks })
  },

  dragTask ({ commit }, { taskId, assigneeId }) {
    commit('SET_TASK_DRAGGED', { taskId, assigneeId })
  },

  endDragTask ({ commit }) {
    commit('SET_TASK_UNDRAGGED')
  },

  unMuteTasks ({ commit }) {
    commit('SET_TASKS_UN_MUTED')
  },

  incrementCommentsCount ({ commit }, { taskId }) {
    commit('INCREMENT_COMMENTS_COUNT', { taskId })
  },

  refreshTask ({ commit, rootGetters }, { task }) {
    commit('REFRESH_TASK', transformTask(task, rootGetters))
  },

  changeTaskFilter ({ commit, dispatch }, { filter, updateTasks = true }) {
    commit('CHANGE_TASK_FILTER', filter)

    return updateTasks
      ? dispatch('fetchTasks')
      : Promise.resolve()
  },

  updateStageTasks ({ commit, rootGetters, dispatch, state }, { tasks, assigneeId = null, full = true }) {
    const { taskFilter } = state
    const filteredTasks = filterTasks(tasks, taskFilter)

    const memberIds = new Set()

    if (assigneeId !== null) {
      memberIds.add(assigneeId || null)
    }

    filteredTasks.forEach(task => memberIds.add(task.state.assignee_id))
    const tasksByStage = filteredTasks.reduce((res, task) => {
      if (!res[task.stage_id]) {
        res[task.stage_id] = []
      }

      res[task.stage_id].push(task)
      return res
    }, {})

    const performTask = transformTaskItem(rootGetters)
    Object.keys(tasksByStage).forEach(stageId => {
      if (!state.tasks[stageId]) {
        return
      }

      const stageTasks = tasksByStage[stageId]

      const updatedTaskIds = stageTasks.map(task => task.id)
      const deletedIndexes = []

      const updatedTasks = state.tasks[stageId].map((task, index) => {
        const taskIndex = updatedTaskIds.indexOf(task.id)

        if (taskIndex === -1) {
          if (full && memberIds.has(task.state.assignee_id)) {
            deletedIndexes.push(index)
          }

          return task
        }

        const updatedTask = stageTasks[taskIndex]
        stageTasks.splice(taskIndex, 1)
        updatedTaskIds.splice(taskIndex, 1)

        return performTask(updatedTask)
      })

      deletedIndexes.forEach(deletedIndex => updatedTasks.splice(deletedIndex, 1))
      stageTasks.forEach(task => updatedTasks.push(performTask(task)))

      commit('SET_TASKS', { stageId, tasks: updatedTasks })
      commit('SET_STAGE_TASKS_COUNT', { stageId, count: updatedTasks.length })
    })

    if (!rootGetters.activeTask && memberIds.has(rootGetters.authUser.member_id)) {
      dispatch('fetchActiveTask', {}, { root: true })
    }
  },

  updateStatusTasks ({ dispatch }, { status, tasks, partial = false }) {
    if (status === 'backlog') {
      dispatch('setBacklogTasks', { tasks, partial })
    }
  },

  refreshBacklogTask ({ commit }, { id, ...data }) {
    commit('REFRESH_BACKLOG_TASK', { id, data })
  },

  searchTask ({ commit, rootGetters }, { term }) {
    if (term.length === 0) {
      commit('SET_SEARCH_RESULTS', [])
      return
    }

    const { project } = rootGetters

    return apiService.get(`projects/${project.code}/tasks/search?query=${term}`)
      .then(({ data }) => {
        commit('SET_SEARCH_RESULTS', data)
      })
  },

  changeProgress ({ commit, dispatch, rootGetters }, { taskId, action, confirmed = false, ...params }) {
    const { project } = rootGetters
    return apiService.post(`projects/${project.code}/tasks/${taskId}/progress`, { action, confirmed, ...params })
      .then(({ data }) => {
        const { task } = data

        if (action === 'complete') {
          const sound = new Audio(require('../../assets/sounds/finished.mp3'))
          sound.play()

          if (task.status === 'finished') {
            commit('REMOVE_TASK', {taskId})
          }
        }

        dispatch('setActiveTask', { task }, { root: true })
        return data
      }).catch(err => {
        if (!err.response || err.response.status !== 400 || err.response.data.code !== 'NEED_CONFIRMATION') {
          return
        }

        return err.response.data
      })
  },

  removeTask ({ commit }, { taskId, stageId, status }) {
    if (stageId) {
      commit('REMOVE_TASK_FROM_STAGE', { stageId, taskId })
    }

    if (status) {
      commit('REMOVE_TASK_FROM_STATUS', { status, taskId })
    }
  },

  async deleteTask ({ commit, rootGetters }, { id }) {
    const { project } = rootGetters
    console.log(`projects/${project.code}/tasks/${id}`)
    return apiService.delete(`projects/${project.code}/tasks/${id}`)
      .then(({ data }) => {
        commit('DELETE_TASK', id)
        return data
      })
  }
}

export const getters = {
  currentStage: ({ taskFilter }, getters, rState, { stages }) => stages.find(stage => stage.id === taskFilter.stage_id),

  tasks: ({ tasks }) => tasks,

  stageTasks: ({ tasks, taskFilter }) => tasks[taskFilter.stage_id] || [],

  stageCounts: ({ stageCounts }) => stageCounts,

  taskFilter: ({ taskFilter }) => taskFilter,

  backlogTasks: ({ backlogTasks }) => backlogTasks,

  searchResults: ({ searchResults }) => searchResults,
}


const filterTasks = (tasks, filter) => {
  const filterHandlers = []
  if (filter.sphere_id) {
    filterHandlers.push((item) => item.sphere?.id === filter.sphere_id)
  }

  if (filter.target_id) {
    filterHandlers.push((item) => item.target?.id === filter.target_id)
  }

  if (filter.assignee_ids.length) {
    filterHandlers.push((item) => filter.assignee_ids.includes(item.state.assignee_id))
  }

  return tasks.filter((item) => {
    for (const handler of filterHandlers) {
      if (handler(item) === false) {
        return false
      }
    }

    return true
  })
}

<template>
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C6.55228 0 7 0.447715 7 1V5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H7V11C7 11.5523 6.55228 12 6 12C5.44772 12 5 11.5523 5 11V7H1C0.447715 7 0 6.55228 0 6C0 5.44772 0.447715 5 1 5H5V1C5 0.447715 5.44772 0 6 0Z" fill="#6283FF" class="path-fill"/>
</svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'BoldPlusIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

import Vue from 'vue'
import dragManager from '../services/drag/dragManager'

Vue.directive('draggableList', {
  bind (el, { value }) {
    const { name, listItem = null, callbacks = {}, options = {} } = value

    dragManager.createList(el, name, listItem)
    dragManager.setListParams(name, callbacks, options)
  }
})

Vue.directive('draggableItem', {
  bind (el, { value }) {
    const { list, item, dragSelector, skip = false } = value
    if (skip) {
      return
    }

    dragManager.addItem(list, el, item, dragSelector)
  },

  update (el, { value }) {
    const { list, item, skip } = value

    if (skip) {
      return
    }
    dragManager.setupClasses(list, el)
    dragManager.updateItem(list, el, item)
  },

  unbind (el, { value }) {
    const { list, item } = value

    dragManager.removeItem(list, el, item)
  }
})

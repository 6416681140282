import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters(['authUser', 'members'])
  },

  methods: {
    channelTitle (channel) {
      if (this.isHelpdesk(channel)) {
        return this.$t('messenger.helpdeskTitle')
      }

      if (channel.type === 'direct') {
        const member = this.getMember(channel.target_member_id)

        return `${member.first_name} ${member.last_name}`
      }

      if (channel.name) {
        return channel.name
      }

      const otherMembers = channel.members.filter(memberId => memberId !== channel.member_id)
      const memberNames = this.getMembers(otherMembers).map(member => `${member.first_name} ${member.last_name}`)

      return memberNames.join(', ')
    },

    getMember (memberId) {
      return this.members.find(member => member.member_id === memberId)
    },

    getMembers (memberIds) {
      return this.members.filter(member => memberIds.indexOf(member.member_id) !== -1)
    },

    isHelpdesk (channel) {
      return channel.id === this.authUser.helpdesk_channel_id
    }
  }
}
<template>
  <div class="settings-page">
    <div class="settings-title">
      {{ $t('settings.project.title') }}
    </div>

    <settings-form v-if="isLoaded" :project="project" @submit="onSave"/>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex"

import SettingsForm from "@/components/projects/SettingsForm"
import pageTitleMixin from "@/views/mixins/pageTitleMixin"
import authUserMixin from "@/mixins/authUserMixin"

export default {
  name: 'ProjectSettings',

  components: { SettingsForm },

  mixins: [pageTitleMixin, authUserMixin],

  data () {
    return {
      activeTab: null,
      isLoaded: false
    }
  },

  computed: {
    ...mapGetters(['project']),

    pageTitle () {
      return {
        message: 'pageTitles.settings.project',
        params: { project: this.project.name }
      }
    }
  },

  methods: {
    ...mapActions(['fetchProjectSettings']),

    onSave () {
      this.$notify({
        title: this.$t('common.saved'),
        message: this.$t('settings.project.notifications.updated'),
        position: 'bottom-right',
        type: 'success'
      })
    }
  },

  async created () {
    if (!this.isProjectAdmin) {
      this.$router.push({ name: 'workspace.member.settings', params: { project: this.project.code} })
      return
    }

    try {
      await this.fetchProjectSettings({ code: this.project.code })
    } finally {
      this.isLoaded = true
    }
  }
}
</script>

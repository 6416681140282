<template>
  <div class="sidebar-menu">
    <el-tooltip
      v-for="item in menuItems"
      :key="item.name"
      popper-class="sidebar-menu__tooltip"
      effect="dark"
      :content="$t(item.title)"
      placement="right"
      transition="sw-slide-right"
      :hide-after="0"
      :open-delay="200"
    >
      <div
          class="sidebar-menu__item"
          :class="{ 'sidebar-menu__item_active': isActiveItem(item) }"
          @click="onItemClick(item)"
      >
        <div class="sidebar-menu__item-icon">
          <component :is="item.icon"></component>
        </div>
      </div>
    </el-tooltip>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import DashboardIcon from '@/components/icons/DashboardIcon.vue'
import GlobeIcon from '@/components/icons/GlobeIcon'
import TasksIcon from "@/components/icons/TasksIcon"
import SprintsIcon from "@/components/icons/SprintsIcon"
import MessagerIcon from "@/components/icons/MessagerIcon"
import ReportIcon from "@/components/icons/ReportIcon"
import SettingsIcon from "@/components/icons/SettingsIcon"
import TimelineIcon from '@/components/icons/TimelineIcon'
import WorkLoadIcon from '@/components/icons/WorkLoadIcon'

export default {
  name: 'SidebarMenu',

  components: { DashboardIcon, GlobeIcon, TasksIcon, SprintsIcon, MessagerIcon, ReportIcon, SettingsIcon, TimelineIcon, WorkLoadIcon },

  props: {
    currentRoute: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {

    }
  },

  computed: {
    ...mapGetters(['project']),

    menuItems () {
      const params = {
        project: this.project.code,
      }

      return [
        { title: 'layout.dashboard', name: 'dashboard', icon: 'dashboard-icon', params },
        {
          title: 'layout.tasks',
          name: 'project.tasks',
          icon: 'tasks-icon',
          routeAliases: ['project.tasks.stage', 'project.tasks.create', 'project.tasks.detail'],
          params,
        },
        { title: 'layout.sprints', name: 'sprints.index', icon: 'sprints-icon', params },
        { title: 'layout.workload', name: 'workload.index', icon: 'work-load-icon', params },
        { title: 'layout.goals', name: 'goalsProgress.index', icon: 'timeline-icon', params },
        { title: 'layout.pages', name: 'pages.dashboard', icon: 'globe-icon', params },
        { title: 'layout.reports', name: 'report.index', icon: 'report-icon', params },
        { title: 'layout.messenger', name: 'messenger.index', icon: 'messager-icon', routeAliases: ['messenger.channel'], params },
        { title: 'layout.settings', name: 'project.settings.general', icon: 'settings-icon',
          routeAliases: ['settings.general', 'settings.rate', 'settings.stages', 'settings.spheres', 'settings.team', 'settings.profile'],
          params,
        }
      ]
    }
  },

  methods: {
    onItemClick (item) {
      this.$router.push({ name: item.name })

      this.$emit('change')
    },

    isActiveItem (item) {
      const isActive = this.currentRoute === item.name

      if (!isActive && item.routeAliases) {
        return item.routeAliases.indexOf(this.currentRoute) !== -1
      }

      return isActive
    }
  }
}
</script>

import apiService from "../../services/api"
import {sortByField} from "@/utils/arrays";

const nullableStage = function (defaultValues) {
  return {
    name: '',
    id: null,
    previous_stage_id: null,
    sphere_id: null,
    with_time_tracking: false,
    is_base_stage: false,
    default_score: 0,
    notified_members: [],
    ...defaultValues
  }
}

export default function(languageService) {
  const namespaced = true

  const state = {
    intermediateStages: [],

    initStage: {},

    stage: {},

    activeSpheres: [],

    deletingStage: null
  }

  const mutations = {
    SET_STAGES (state, stages) {
      state.initStage = stages.find(item => item.is_base_stage === true)

      state.intermediateStages = stages.reduce((memo, item) => {
        if (item.is_base_stage) {
          return memo
        }

        const sphereId = item.sphere ? item.sphere.id : 0
        let sphereStages = memo.find(stagesItem => stagesItem.sphereId === sphereId)

        if (!sphereStages) {
          sphereStages = {
            sphereId: sphereId,
            name: item.sphere ? item.sphere.name : languageService.i18n.t('stages.defaultSphere'),
            stages: []
          }

          memo.push(sphereStages)
        }

        sphereStages.stages.push(item)

        return memo
      }, [])

      state.intermediateStages.sort(sortByField('sphereId'))
    },

    SET_SPHERE_STAGES (state, { sphereId, stages }) {
      let idx = state.intermediateStages.findIndex(item => item.sphereId === sphereId)

      if (stages.length === 0) {
        if (idx !== -1) {
          state.intermediateStages.splice(idx, 1)
        }

        return
      }

      if (idx === -1) {
        const firstStage = stages[0]
        state.intermediateStages.push({
          sphereId: sphereId,
          name: firstStage.sphere ? firstStage.sphere.name : 'Все задачи',
          stages
        })

        return
      }

      const sphereStages = state.intermediateStages[idx]
      state.intermediateStages.splice(idx, 1, {
        ...sphereStages,
        stages
      })
    },

    ADD_STAGE (state, { sphereId }) {
      const idx = state.intermediateStages.findIndex(item => item.sphereId === sphereId)

      if (idx === -1) {
        return
      }

      const curVal = this.intermediateStages[idx]
      const sphereStages = {
        ...curVal,
        stages: [...curVal.stages, nullableStage()]
      }

      state.intermediateStages.splice(idx, 1, sphereStages)
    },

    SET_DELETING_STAGE (state, stage) {
      state.deletingStage = stage
    }
  }

  const actions = {
    fetchStages ({ commit, rootGetters }, { sphereId = null }) {
      const params = {}

      if (sphereId !== null) {
        params.sphere_id = sphereId
      }

      const { project } = rootGetters
      return apiService.get(`projects/${project.code}/stages`, params)
        .then(({ data }) => {
          if (sphereId === null) {
            commit('SET_STAGES', data)
          } else {
            commit('SET_SPHERE_STAGES', { sphereId, stages: data })
          }
        })
    },

    addStage ({ commit }, { sphereId }) {
      commit('ADD_STAGE', { sphereId })
    },

    fetchStage ({ rootGetters }, { id, defaultValues = {} }) {
      if (!id) {
        return Promise.resolve(nullableStage(defaultValues))
      }

      const { project } = rootGetters
      return apiService.get(`projects/${project.code}/stages/${id}`)
        .then(({ data }) => {
          return data
        })
    },

    createStage ({ dispatch, rootGetters }, data) {
      const sendData = getFormData(data)

      if (data.sphere_id) {
        sendData.sphere_id = data.sphere_id
      }

      const { project } = rootGetters
      return apiService.post(`projects/${project.code}/stages`, sendData)
        .then(({ data }) => {
          const { sphere } = data

          return dispatch('fetchStages', { sphereId: sphere ? sphere.id : 0 })
        })
    },

    updateStage ({ dispatch, rootGetters }, data) {
      const sendData = getFormData(data)

      const { project } = rootGetters
      return apiService.put(`projects/${project.code}/stages/${data.id}`, sendData)
        .then(({ data }) => {
          const { sphere } = data

          return dispatch('fetchStages', { sphereId: sphere ? sphere.id : 0 })
        })
    },

    deleteStage ({ dispatch, rootGetters }, { id, sphereId, newStageId }) {
      const { project } = rootGetters
      return apiService.post(`projects/${project.code}/stages/${id}`, {
        new_stage_id: newStageId
      })
        .then(() => {
          return dispatch('fetchStages', { sphereId })
        })
    },

    setDeletingStage ({ commit }, stage) {
      commit('SET_DELETING_STAGE', stage)
    },

    reSortStages ({ dispatch, rootGetters }, { source, targetId, position }) {
      const { project } = rootGetters
      apiService.post(`projects/${project.code}/stages/${source.id}/sort`, {
        target_id: targetId,
        position
      })
        .then(() => {
          return dispatch('fetchStages', { sphereId: source.sphere ? source.sphere.id : null })
        })
    }
  }

  const getters = {
    initStage: ({ initStage }) => initStage,

    intermediateStages: ({ intermediateStages }) => intermediateStages,

    stage: ({ stage }) => stage,

    activeSpheres: ({ intermediateStages }) => intermediateStages.map(item => item.sphereId),

    movingStages: ({ deletingStage, intermediateStages }) => {
      if (!deletingStage) {
        return []
      }

      const { sphere } = deletingStage
      const sphereId = sphere ? sphere.id : 0
      const sphereStages = intermediateStages.find(item => item.sphereId === sphereId)
      const movingStages = sphereStages.stages.filter(item => item.id !== deletingStage.id)

      return movingStages
    }
  }

  return {
    namespaced,
    state,
    mutations,
    actions,
    getters
  }
}

function getFormData (data) {
  const { name, with_time_tracking, default_score, notified_members } = data

  return { name, with_time_tracking, default_score: default_score || null, notified_members }
}

<template>
  <div class="join-block">
    <div class="join-workspace">
      <workspace-logo :workspace="workspace" size="mdx"/>
    </div>

    <div class="join-block__title" v-html="$t('auth.joinSuccessTitle', { workspace: workspace.name })"></div>

    <div class="block_auth">
      <div class="onboarding-subtitle">
        {{ $t('onboarding.spheres.description') }}
      </div>

      <div v-if="isLoaded">
        <ui-form :model="form" :rules="rules" ref="form">
          <div class="form-control">
            <form-dropdown
                :options="spheres"
                key-field="id"
                :value="form.my_spheres"
                :multiple="true"
                prop="my_spheres"
                popover-class="ui-dropdown_no-border"
                :width-by-popover="true"
                @input="onSphereChange"
            >
              <div class="selected-spheres" v-if="form.my_spheres.length > 0">
                <div
                    class="selected-spheres__item"
                    v-for="sphere in form.my_spheres"
                    :key="sphere.id"
                >
                  {{ sphere.name }}
                </div>
              </div>
              <div v-else class="selected-spheres-empty">
                {{ $t('user.noSpheresSelected') }}
              </div>

              <template v-slot:option="{ option }">
                <div :class="optionClasses(option)">
                  {{ option.name }}
                </div>
              </template>
            </form-dropdown>
            <div class="workspace-form__schedule">
              <div class="workspace-form__sub-title">
                {{ $t('settings.general.workSchedule') }}
              </div>

              <div class="form-control">
                <work-schedule-selector v-model="work_schedule[0]" @focus="e => onFocusInput('schedule', e)"/>
              </div>
            </div>
          </div>

          <div class="form-control mt-4">
            <ui-button @click.prevent="onSubmit" ui-class="block" class="auth-submit-btn">{{ $t('common.save') }}</ui-button>
          </div>
          <div class="form-control mt-2">
            <ui-button @click.prevent="goToTasks" ui-class="light block" class="auth-submit-btn">{{ $t('user.skipAndGoToTasks') }}</ui-button>
          </div>
        </ui-form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import UiButton from 'scorework-ui/src/components/UiButton'
import WorkspaceLogo from "@/components/ui/WorkspaceLogo"
import WorkScheduleSelector from "@/components/ui/WorkScheduleSelector"
import FormDropdown from "@/components/ui/FormDropdown"
import UiForm from 'scorework-ui/src/components/form/UiForm'

export default {
  name: 'OnBoardingSpheres',

  components: { UiButton, WorkspaceLogo, WorkScheduleSelector, FormDropdown, UiForm },

  data () {
    return {
      form: {my_spheres: []},
      rules: {
        my_spheres: {
          rules: {},
          messages: {}
        }
      },
      work_schedule: [
        {
          days: [],
          work_hours: {
            start: '10:00',
            end: '19:00'
          }
        }
      ],
      isLoaded: true,
    }
  },

  computed: {
    ...mapGetters(['spheres', 'workspace', 'project'])
  },

  methods: {
    ...mapActions(['updateMemberProfile', 'updateProjectMemberProfile']),

    optionClasses (option) {
      const classes = { 'dropdown-sphere-item': true }

      if (option.parent_id) {
        classes['ml-2'] = true
      }

      if (false === this.isAvailable(option)) {
        classes['dropdown-sphere-item_inactive'] = true
      }

      return classes
    },

    isAvailable (sphere) {
      return this.parentsActive || !sphere.is_parent
    },

    onSphereChange (val) {
      if (false === this.isAvailable(val)) {
        return
      }

      if (this.multiple) {
        this.tempValue = val
      }

      this.form.my_spheres = val
    },

    async onSubmit () {
      await Promise.all([
          this.updateMemberProfile({ work_schedule: this.work_schedule }),
          this.updateProjectMemberProfile({ spheres: this.form.my_spheres.map(item => item.id) }),
      ])

      this.goToTasks()
    },

    goToTasks () {
      this.$router.push({ name: 'project.tasks', params: { project: this.project.code } })
    }
  },

  mounted () {
    console.log(this.spheres)
  },

  created () {
    this.work_schedule = this.project.work_schedule
  }
}
</script>
<style lang="sass">
.onboarding-subtitle
  font-size: 16px
  line-height: 1.5
  margin-bottom: 24px

.selected-spheres
  display: flex
  flex-flow: row wrap
  padding-top: 6px

  &__item
    color: #ffffff
    background: var(--primary-color)
    padding: 8px 12px
    border-radius: 6px
    margin: 0px 6px 6px 0

.selected-spheres-empty
  color: var(--text-light-color)
  padding: 6px
</style>

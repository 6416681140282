<template>
  <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" d="M0.324889 0.914766C0.270812 0.968732 0.227909 1.03283 0.198637 1.1034C0.169364 1.17397 0.154297 1.24962 0.154297 1.32602C0.154297 1.40241 0.169364 1.47806 0.198637 1.54863C0.227909 1.6192 0.270812 1.6833 0.324889 1.73727L2.58822 4.0006L0.324889 6.26393C0.270883 6.31794 0.228043 6.38205 0.198815 6.45261C0.169587 6.52318 0.154544 6.59881 0.154544 6.67518C0.154544 6.75156 0.169587 6.82719 0.198815 6.89775C0.228043 6.96831 0.270883 7.03243 0.324889 7.08643C0.378895 7.14044 0.44301 7.18328 0.513572 7.21251C0.584135 7.24173 0.659763 7.25678 0.736139 7.25678C0.812515 7.25678 0.888144 7.24173 0.958706 7.21251C1.02927 7.18328 1.09338 7.14044 1.14739 7.08643L3.82489 4.40893C3.87897 4.35497 3.92187 4.29086 3.95114 4.2203C3.98041 4.14973 3.99548 4.07408 3.99548 3.99768C3.99548 3.92128 3.98041 3.84564 3.95114 3.77507C3.92187 3.7045 3.87897 3.6404 3.82489 3.58643L1.15322 0.914766C0.925722 0.687266 0.552389 0.687266 0.324889 0.914766Z" fill="#A3A9BD"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'ChevronRightIcon',

    mixins: [iconMixin],

    props: {
      viewBox: {
        type: String,
        default: '0 0 12 12'
      }
    },

    data () {
      return {
        defaultBackground: '#2C3E50',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

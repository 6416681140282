<template>
  <div class="round-text-field">
    <label :for="controlId" v-if="labelPosition === 'top'" class="round-text-field__label">{{ label }}</label>
    <input
      class="round-text-field__input"
      type="text"
      :id="controlId"
      :value="value"
      :placeholder="placeholder"
      @input="onInput"
      v-bind:class="{ 'is-error': !valid }"
      ref="input"
    />
    <label :for="controlId" v-if="labelPosition === 'bottom'" class="round-text-field__label">{{ label }}</label>
  </div>
</template>
<script>
  import FormControlMixin from 'scorework-ui/src/components/mixins/FormControlMixin'

  export default {
    name: 'RoundTextField',

    mixins: [FormControlMixin],

    props: {
      value: {
        required: true
      },

      valid: {
        type: Boolean,
        default: true
      },

      label: {
        type: String,
        required: true
      },

      placeholder: {
        type: String,
        default: ''
      },

      labelPosition: {
        type: String,
        default: 'top'
      },

      focused: {
        type: Boolean,
        default: false
      }
    },

    mounted () {
      if (this.focused) {
        this.$refs.input.focus()
      }
    }
  }
</script>
<style lang="sass">
  .is-error
    background: #FFF3F5 !important
    border: 1px solid #FF5555 !important
</style>

import apiService from '@/services/api';
import { sortTasks, transformTask, transformTaskItem } from '@/transformers/tasks';

export const namespaced = true

export const state = {
  goals: [],

  goalTasks: {},

  finishedTasks: {},

  sort: 'tasks_desc'
}

export const mutations = {
  ADD_GOAL (state, goal) {
    const idx = state.goals.findIndex(item => item.id === goal.id)
    if (idx !== -1) {
      state.goals.splice(idx, 1, goal)
      return
    }

    state.goals.push(goal)
  },

  SET_GOAL_TASKS (state, { tasks, goalId }) {
    sortTasks(tasks)
    state.goalTasks[goalId] = tasks
  },

  ADD_FINISHED_TASKS (state, { goalId, finishedTasks }) {
    state.finishedTasks[goalId] = finishedTasks.map(item => {
      if (item.sphere_id) {
        return item
      }

      return {...item, sphere_id: 0 }
    })
  },

  REFRESH_TASKS (state, { tasks }) {
    console.log(tasks)
    const goalTasks = tasks.reduce((res, task) => {
      const { target } = task
      console.log(task.name)

      if (!target) {
        return res
      }

      if (!res[target.id]) {
        res[target.id] = [...state.goalTasks[target.id]] || []
      }

      const idx = res[target.id].findIndex(item => item.id === task.id)
      if (idx !== -1) {
        res[target.id].splice(idx, 1, task)
      } else {
        console.log('add', task.name)
        res[target.id].push(task)
      }

      console.log(res[target.id])
      return res
    }, {})

    Object.keys(goalTasks).forEach(goalId => {
      sortTasks(goalTasks[goalId])
    })

    console.log(goalTasks)

    state.goalTasks = {
      ...state.goalTasks,
      ...goalTasks,
    }

    console.log(state.goalTasks[13].map(item => item.name))
  },

  REFRESH_TASK (state, { task }) {
    const { name, target, sphere, } = task
    if (!target || !state.goalTasks[target.id]) {
      return
    }

    let taskItem = state.goalTasks[target.id].findIndex(item => item.id === task.id)

    // Значит цель задачи не изменилась, просто обновляем ее
    if (taskItem) {
      state.goalTasks = {
        ...state.goalTasks,
        [target.id]: state.goalTasks[target.id].map(item => {
          if (item.id !== task.id) {
            return item
          }

          return {
            ...item,
            name, target, sphere,
          }
        })
      }

      return
    }

    // У задачи изменилась цель, значит нужно перенести ее в другую
    taskItem = Object.keys(state.goalTasks).find(key => state.goalTasks[key].find(item => item.id === task.id))
    if (!taskItem) {
      return
    }

    const oldGoal = taskItem.target
    const goalTasks = [
      ...state.goalTasks[target.id],
      {
        ...taskItem,
        name, target, sphere,
      }
    ]

    sortTasks(goalTasks)

    state.goalTasks = {
      ...state.goalTasks,
      [target.id]: goalTasks,
      [oldGoal.id]: state.goalTasks[oldGoal.id].filter(item => item.id !== task.id),
    }
  },

  REFRESH_GOAL (state, goal) {
    state.goals = state.goals.map(item => {
      if (item.id !== goal.id) {
        return item
      }

      return goal
    })
  },

  COMPLETE_GOAL (state, goal) {
    state.goals = state.goals.filter(item => item.id !== goal.id)
    state.goalTasks = Object.keys(state.goalTasks).reduce((goalTasks, goalId) => {
      const numberId = Number(goalId)
      if (numberId !== goal.id) {
        goalTasks[goalId] = state.goalTasks[goalId]
      }

      return goalTasks
    }, {})
  },

  REMOVE_TASK (state, taskId) {
    state.goalTasks = Object.keys(state.goalTasks).reduce((goalTasks, goalId) => {
      goalTasks[goalId] = state.goalTasks[goalId].filter(task => task.id !== taskId)
      return goalTasks
    }, {})
  },

  DELETE_TASK (state, taskId) {
    state.goalTasks = Object.keys(state.goalTasks).reduce((goalTasks, goalId) => {
      goalTasks[goalId] = state.goalTasks[goalId].filter(task => task.id !== taskId)
      return goalTasks
    }, {})
  },

  SET_SORT (state, sort) {
    state.sort = sort
  },
}

export const actions = {
  async fetchGoalsProgress ({ commit, rootGetters }) {
    const { project } = rootGetters
    const { data } = await apiService.get(`projects/${project.code}/goals-progress`)

    const performFunc = transformTaskItem(rootGetters)
    data.forEach(({ goal, finished, tasks }) => {
      commit('ADD_GOAL', goal)
      commit('SET_GOAL_TASKS', { tasks: tasks.map(performFunc), goalId: goal.id })
      commit('ADD_FINISHED_TASKS', { goalId: goal.id, finishedTasks: finished })
    })
  },

  refreshTasks ({ commit, rootGetters }, { tasks }) {
    const performFunc = transformTaskItem(rootGetters)

    commit('REFRESH_TASKS', { tasks: tasks.map(performFunc) })
  },

  refreshTask ({ commit, rootGetters }, { task }) {
    if (!task.target) {
      return
    }

    if (task.status === 'finished') {
      commit('DELETE_TASK', task.id)
      return
    }

    commit('REFRESH_TASK', { task: transformTask(task, rootGetters) })
  },

  refreshGoal ({ commit }, { target }) {
    if (target.status === 'finished') {
      commit('COMPLETE_GOAL', target)
      return
    }

    commit('REFRESH_GOAL', target)
  },

  removeTask ({ commit }, { taskId }) {
    commit('REMOVE_TASK', taskId)
  },

  deleteTask ({ commit }, { taskId }) {
    commit('DELETE_TASK', taskId)
  },

  setSort ({ commit }, sort) {
    commit('SET_SORT', sort)
  }
}

export const getters = {
  goals: ({ goals, goalTasks, sort }) => {
    const sortedGoals = [...goals]
    sortGoals(sortedGoals, sort, goalTasks)
    return sortedGoals
  },

  goalTasks: ({ goalTasks }) => goalTasks,

  finishedTasks: ({ finishedTasks }) => finishedTasks,

  sort: ({ sort }) => sort,
}

const sortGoals = (goals, sort, goalTasks) => {
  // eslint-disable-next-line
  const [field, dir] = sort.split('_')
  const sortDirection = dir === 'desc' ? 1 : -1

  goals.sort((a, b) => {
    const aTasks = (goalTasks[a.id] || []).length
    const bTasks = (goalTasks[b.id] || []).length

    if (aTasks < bTasks) {
      return sortDirection
    }

    if (bTasks < aTasks) {
      return sortDirection * -1
    }

    return 0
  })
}

<template>
  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.63636 10.9091H9.27273C9.41739 10.9091 9.55613 10.9666 9.65842 11.0689C9.76072 11.1711 9.81818 11.3099 9.81818 11.4545C9.81818 11.5992 9.76072 11.7379 9.65842 11.8402C9.55613 11.9425 9.41739 12 9.27273 12H1.63636C1.4917 12 1.35296 11.9425 1.25067 11.8402C1.14838 11.7379 1.09091 11.5992 1.09091 11.4545C1.09091 11.3099 1.14838 11.1711 1.25067 11.0689C1.35296 10.9666 1.4917 10.9091 1.63636 10.9091ZM7.63636 2.18182C7.34704 2.18182 7.06956 2.06688 6.86497 1.8623C6.66039 1.65771 6.54545 1.38024 6.54545 1.09091C6.54545 0.801582 6.66039 0.524105 6.86497 0.31952C7.06956 0.114935 7.34704 0 7.63636 0H9.81818C10.1075 0 10.385 0.114935 10.5896 0.31952C10.7942 0.524105 10.9091 0.801582 10.9091 1.09091C10.9091 1.38024 10.7942 1.65771 10.5896 1.8623C10.385 2.06688 10.1075 2.18182 9.81818 2.18182V5.45455C9.81818 6.61185 9.35844 7.72176 8.5401 8.5401C7.72176 9.35844 6.61185 9.81818 5.45455 9.81818C4.29724 9.81818 3.18733 9.35844 2.36899 8.5401C1.55065 7.72176 1.09091 6.61185 1.09091 5.45455V2.18182C0.801582 2.18182 0.524105 2.06688 0.31952 1.8623C0.114935 1.65771 0 1.38024 0 1.09091C0 0.801582 0.114935 0.524105 0.31952 0.31952C0.524105 0.114935 0.801582 0 1.09091 0H3.27273C3.56205 0 3.83953 0.114935 4.04412 0.31952C4.2487 0.524105 4.36364 0.801582 4.36364 1.09091C4.36364 1.38024 4.2487 1.65771 4.04412 1.8623C3.83953 2.06688 3.56205 2.18182 3.27273 2.18182V5.45455C3.27273 6.0332 3.5026 6.58815 3.91177 6.99732C4.32094 7.40649 4.87589 7.63636 5.45455 7.63636C6.0332 7.63636 6.58815 7.40649 6.99732 6.99732C7.40649 6.58815 7.63636 6.0332 7.63636 5.45455V2.18182Z" fill="#333840"/>
  </svg>
</template>
<script>
import iconMixin from '../iconMixin'

export default {
  name: 'UnderlineIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

<template>
  <div class="auth-block">
    <h1 class="auth-block__title">{{ $t('auth.welcomeBack') }}</h1>
    <div class="auth-block__hint">
      {{ $t('auth.loginHint') }}
    </div>
    <login-form @login="onLogin"/>
  </div>
</template>
<script>
import {mapActions, mapGetters } from 'vuex'
import LoginForm from "@/components/auth/LoginForm"
import pageTitleMixin from "@/views/mixins/pageTitleMixin"

export default {
  name: 'Login',

  components: { LoginForm },

  mixins: [pageTitleMixin],

  computed: {
    ...mapGetters(['project', 'isAuth']),

    pageTitle () {
      return {
        message: 'pageTitles.login'
      }
    }
  },

  methods: {
    ...mapActions(['fetchInit']),

    async onLogin () {
      await this.fetchInit({})
      this.$router.push({ name: 'project.tasks', params: { project: this.project.code } })
    }
  },

  created () {
    if (this.isAuth) {
      this.$router.push({ name: 'project.tasks', params: { project: this.project.code } })
    }
  }
}
</script>
<style lang="sass">

.auth-block
  width: 480px
  margin: 96px 0

  &__title
    font-size: 28px
    font-weight: 600
    color: var(--text-primary-color)
    margin: 0
    padding-bottom: 12px

  &__hint
    color: var(--text-light-color)
    font-size: 15px
    padding-bottom: 28px
</style>

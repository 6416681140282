import apiService from '@/services/api'
import { sortTasks, transformTaskItem } from '@/transformers/tasks'
import { createFeedTransformer } from '@/transformers/feeds'

export const namespaced = true

export const state = {
  myTasks: [],
  activeSprint: null,
  memberFeed: []
}

export const mutations = {
  SET_MY_TASKS (state, tasks) {
    sortTasks(tasks)
    state.myTasks = tasks
  },

  SET_ACTIVE_SPRINT (state, sprintData) {
    state.activeSprint = sprintData
  },

  REFRESH_TASKS (state, tasks) {
    const updatedIds = []
    const updatedTasks = state.myTasks.map(item => {
      const updatedTask = tasks.find(task => task.id === item.id)
      if (!updatedTask) {
        return item
      }

      updatedIds.push(updatedTask.id)
      return updatedTask
    })

    tasks.forEach(item => {
      if (updatedIds.includes(item.id)) {
        return
      }

      updatedTasks.push(item)
    })

    sortTasks(updatedTasks)

    state.myTasks = updatedTasks
  },

  SET_MEMBER_FEED (state, feed) {
    state.memberFeed = feed
  },

  REMOVE_FINISHED_TASK (state, id) {
    state.myTasks = state.myTasks.filter(item => item.id !== id)
  },

  PREPEND_FEED_ITEM (state, feedItem) {
    state.memberFeed = [
      feedItem,
      ...state.memberFeed,
    ]
  }
}

export const actions = {
  async fetchMyTasks ({ commit, rootGetters }) {
    const { project } = rootGetters

    const { data } = await apiService.get(`projects/${project.code}/tasks/my`, { limit: 3 })
    const performFunc = transformTaskItem(rootGetters)
    commit('SET_MY_TASKS', data.map(performFunc))

    return data
  },

  async fetchActiveSprint ({ commit, rootGetters }) {
    const { project } = rootGetters

    const { data } = await apiService.get(`projects/${project.code}/sprints/active`, { my: 1 })
    commit('SET_ACTIVE_SPRINT', data)
  },

  refreshMyTasks ({ commit, rootGetters }, { tasks }) {
    const performFunc = transformTaskItem(rootGetters)
    commit('REFRESH_TASKS', tasks.map(performFunc))
  },

  async fetchMemberFeed ({ commit, rootGetters }) {
    const { project } = rootGetters
    const { data } = await apiService.get(`projects/${project.code}/feed/my`)
    const performFunc = createFeedTransformer(rootGetters)
    commit('SET_MEMBER_FEED', data.map(performFunc))
  },

  prependFeedItem ({ commit, rootGetters }, { feed }) {
    const performFunc = createFeedTransformer(rootGetters)

    commit('PREPEND_FEED_ITEM', performFunc(feed))
  },

  removeFinishedTask ({ commit }, id) {
    commit('REMOVE_FINISHED_TASK', id)
  }
}

export const getters = {
  myTasks: ({ myTasks }) => myTasks,

  activeSprint: ({ activeSprint }) => activeSprint,

  sprintData: ({ activeSprint }) => activeSprint ? activeSprint.data : {},

  memberFeed: ({ memberFeed }) => memberFeed,
}

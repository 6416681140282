<template>
  <el-dialog
    :title="title"
    :visible="true"
    :width="width"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="onClose"
  >
    <div>
      <div class="common-desc mb-4" v-html="description"></div>

      <div class="fx fx_end fx_gap-x-2 mt-4">
        <ui-button @click.prevent="onClose" class="btn-light-grey" ui-class="size_md">
          {{ $t('tasks.deleteConfirmation.cancel') }}
        </ui-button>

        <ui-loading-button
            @click.prevent="onSubmit"
            ui-class="size_md"
            :loading="isProcessing"
        >
          {{ submitTitle }}
        </ui-loading-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import UiButton from 'scorework-ui/src/components/UiButton'
import UiLoadingButton from '@/components/ui/UiLoadingButton'

export default {
  name: 'ConfirmationModal',

  components: { UiButton, UiLoadingButton },

  props: {
    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      required: true
    },

    submitTitle: {
      type: String,
      required: true
    },

    width: {
      type: String,
      default: '45%'
    },

    isProcessing: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClose () {
      this.$emit('close')

    },

    async onSubmit () {
      this.$emit('confirm')
    }
  }
}
</script>

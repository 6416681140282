<template>
  <div class="pages-menu">
    <pages-menu-section/>

    <pages-menu-section
      v-for="project in projects"
      :key="project.code"
      :project="project"
      @delete="onDeletePage"
    />

    <confirmation-modal
      v-if="showDeleteConfirmation"
      :description="$t('knowledge.confirmation.delete.description')"
      :title="$t('knowledge.confirmation.delete.title')"
      :submit-title="$t('knowledge.confirmation.delete.submit')"
      width="400px"
      :is-processing="deleteProcessing"
      @close="showDeleteConfirmation = false"
      @confirm="onDeleteConfirm"
    />
  </div>
</template>
<script>
  import {mapActions, mapGetters} from 'vuex'
  import PagesMenuSection from '@/components/pages/PagesMenuSection';
  import ConfirmationModal from '@/components/ui/ConfirmationModal.vue';

  export default {
    name: 'PagesMenu',

    components: {ConfirmationModal, PagesMenuSection },

    data () {
      return {
        showDeleteConfirmation: false,
        deletingPageCode: null,
        deleteProcessing: false,
      }
    },

    computed: {
      ...mapGetters(['projects']),
      ...mapGetters('pages', ['mainPagesByProject', 'mainPages']),
    },

    methods: {
      ...mapActions('pages', ['fetchMainPages', 'setPagePreData', 'deletePage']),

      onCreatePage (query) {
        this.$router.push({ name: 'pages.create', query })
      },

      onDeletePage (code) {
        this.deletingPageCode = code
        this.showDeleteConfirmation = true
      },

      async onDeleteConfirm () {
        this.deleteProcessing = true
        try {
          this.deletePage({ code: this.deletingPageCode })
        } finally {
          this.deleteProcessing = false
          this.showDeleteConfirmation = false
          this.deletingPageCode = null
        }
      }
    },

    async created () {
      await this.fetchMainPages()
    }
  }
</script>
<style lang="sass">
.pages-menu
  padding: 16px 4px
  flex: 1
  overflow: auto
</style>

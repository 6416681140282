import { setupWorkHours } from "@/services/scoring";

export const memberTransformer = (member) => {
  const { work_schedule: workSchedule } = member

  if (workSchedule) {
    workSchedule[0].work_hours = setupWorkHours(workSchedule[0].work_hours)
  }

  return member
}

export const createProjectMemberTransformer = ({ spheres }) => ({ project = null, ...member }) => {
  const res = {
    ...memberTransformer(member),
  }

  if (project) {
    res.project = {
      ...project,
      spheres: project.spheres.map(item => {
        const found = spheres.find(sphere => sphere.id === item.id)
        if (!found) {
          return item
        }

        return {
          ...item,
          color: found.color
        }
      })
    }
  }

  return res
}

<template>
  <div class="channels-list">
    <ui-tabs :tabs="tabs" key-field="name" :value="activeTab" @input="onChangeTab" class="channel-tabs">
      <template v-slot:default="{ tab }">
        {{ tab.label }}
      </template>
    </ui-tabs>

    <template v-if="activeTab === 'dialogs'">
      <channel-item
        v-for="channel in dialogChannels"
        :key="channel.id"
        :channel="channel"
        :is-active="activeChannelId === channel.id"
        @select="onChannelClick"
      />

      <div class="channels-list-empty" v-if="dialogChannels.length === 0">
        {{ $t('messenger.noChannels') }}
      </div>
    </template>

    <template v-if="activeTab === 'helpdesk'">
      <channel-item :channel="helpdeskChannel" :is-active="true"/>
    </template>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex"
import channelHelpers from "@/components/messenger/channelHelpers"
import ChannelItem from "@/components/messenger/ChannelItem"
import UiTabs from "@/components/ui/UiTabs"

const { mapGetters: messengerGetters } = createNamespacedHelpers('messenger')

export default {
  name: 'ChannelList',

  components: { ChannelItem, UiTabs },

  mixins: [channelHelpers],

  data () {
    const activeTab = this.$route.name === 'messenger.channel' && this.$route.params.channelId === 'helpdesk'
      ? 'helpdesk'
      : 'dialogs'

    return {
      activeTab,
      tabs: [
        { label: this.$t('messenger.dialogs'), name: 'dialogs' },
        { label: this.$t('messenger.helpdesk'), name: 'helpdesk' }
      ]
    }
  },

  computed: {
    ...messengerGetters(['dialogChannels', 'activeChannelId', 'helpdeskChannel'])
  },

  watch: {
    $route: function () {
      this.activeTab = this.$route.name === 'messenger.channel' && this.$route.params.channelId === 'helpdesk'
        ? 'helpdesk'
        : 'dialogs'
    }
  },

  methods: {
    onChannelClick (channel) {
      this.$router.push({ name: 'messenger.channel', params: { channelId: channel.id } })
    },

    onChangeTab (tab) {
      this.activeTab = tab
      if (tab === 'helpdesk') {
        this.$router.push({ name: 'messenger.channel', params: { channelId: 'helpdesk' } })
      } else {
        this.$router.push({ name: 'messenger.index' })
      }
    }
  }
}
</script>
<style lang="sass">
.channels-list-empty
  padding: 24px 16px
  text-align: center
  color: var(--text-light-color)

.channel-tabs
  padding: 0 8px
  margin-bottom: 12px

  .ui-tab
    margin: 0 8px
    padding: 8px 0px
    text-transform: uppercase
    font-weight: 500
    font-size: 13px

    &:after
      background: var(--text-primary-color)
      left: 50%
      width: 0

    &_active
      color: var(--text-primary-color)

      &:after
        left: 0
        width: 100%

      & ~ .ui-tab::after
        left: 50%
        width: 0

</style>
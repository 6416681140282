<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" fill-rule="evenodd" clip-rule="evenodd" d="M21.4741 10.3783C21.353 11.0342 21.1469 11.6669 20.8654 12.2681C21.1156 12.8182 21.2501 13.4031 21.2501 14C21.2501 14.8027 21.0171 15.5683 20.5906 16.2592C20.4218 16.5327 20.3729 16.865 20.3535 17.1101C20.332 17.383 20.339 17.6868 20.3593 17.9854C20.391 18.4533 20.4591 18.9564 20.5296 19.3942C20.0492 19.2473 19.4821 19.0862 18.9511 18.9664C18.6201 18.8917 18.2872 18.8294 17.9897 18.7987C17.7221 18.7711 17.3751 18.7554 17.0777 18.8516C16.2855 19.1076 15.4155 19.25 14.5001 19.25C12.7796 19.25 11.2614 18.762 10.1027 17.9799C9.19436 17.9316 8.3165 17.7898 7.48291 17.5658C8.9659 19.5121 11.6182 20.75 14.5001 20.75C15.5706 20.75 16.5964 20.5836 17.5391 20.2788C17.5328 20.2809 17.5339 20.2808 17.5436 20.2801C17.5697 20.2784 17.6579 20.2724 17.8356 20.2908C18.0501 20.3129 18.3187 20.3614 18.621 20.4296C19.2236 20.5656 19.8971 20.7652 20.4184 20.9307C21.3371 21.2223 22.2305 20.4191 22.0661 19.4851C21.9809 19.0013 21.8912 18.4048 21.8558 17.8837C21.838 17.6221 21.8354 17.399 21.8489 17.2282C21.858 17.1124 21.8723 17.0545 21.877 17.0354C21.878 17.0314 21.8786 17.0291 21.8786 17.0283C22.4325 16.1239 22.7501 15.0947 22.7501 14C22.7501 12.6433 22.2772 11.4092 21.4741 10.3783Z" fill="#636478"/>
    <path class="path-stroke" d="M19.5 9.5C19.5 13.0899 15.6944 16 11 16C9.75228 16 8.56736 15.7944 7.49999 15.4251C6.81544 15.1883 4.46846 16.0232 3.07125 16.567C2.66312 16.7258 2.23933 16.3247 2.37633 15.9088C2.80885 14.5954 3.42593 12.5033 3.15144 12C2.73175 11.2304 2.49999 10.3859 2.49999 9.5C2.49999 5.91015 6.30557 3 11 3C15.6944 3 19.5 5.91015 19.5 9.5Z" stroke="#636478" stroke-width="1.5"/>
  </svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'MessagerIcon',

  mixins: [iconMixin],

  props: {
    viewBox: {
      type: String,
      default: '0 0 12 12'
    }
  },

  data () {
    return {
      defaultBackground: '#416AF7',
      defaultWidth: 12,
      defaultHeight: 12
    }
  }
}
</script>

import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['projects']),

    projectName () {
      if (!this.page.project) {
        return this.$t('knowledge.commonPages')
      }

      const project = this.projects.find((item) => item.code === this.page.project)

      return project ? project.name : ''
    },

    rootUrl () {
      return { name: 'pages.dashboard.project', params: { code: this.page.project || 'common' } }
    },

    firstParent () {
      return this.page.parents.length > 0 ? this.page.parents[this.page.parents.length - 1] : null
    },

    innerParents () {
      return this.page.parents.length > 1 ? this.page.parents.slice(0, this.page.parents.length - 1) : []
    }
  }
}

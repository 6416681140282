<template>
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect class="path-fill" x="0.583496" y="0.200012" width="4" height="13" rx="1.5" fill="white"/>
    <rect class="path-fill" x="8.1665" y="0.200012" width="4" height="13" rx="1.5" fill="white"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'PauseIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 10,
        defaultHeight: 10
      }
    }
  }
</script>

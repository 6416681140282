<template>
  <ui-form :model="form" :rules="rules" :submit-fn="submitFn" ref="form">
    <div class="profile-form mb-2">
      <div class="profile-form__data">
        <div class="form-control">
          <div style="display: flex; flex-flow: row nowrap;">
            <div style="flex: 1;">
              <text-field
                  prop="first_name"
                  v-model="form.first_name"
                  :label="$t('auth.yourFirstName')"
              />
            </div>
            <div style="flex: 1; margin-left: 24px;">
              <text-field
                  prop="last_name"
                  v-model="form.last_name"
                  :label="$t('auth.yourLastName')"
              />
            </div>
          </div>
        </div>

        <div class="form-control">
          <form-dropdown
              prop="lang"
              :options="languages"
              :value="form.lang"
              :multiple="false"
              reference-class="settings-dropdown"
              popover-class="ui-dropdown_no-border"
              :width-by-popover="true"
              :label="$t('settings.profile.language')"
              @input="onLangChange"
          >
            <template v-slot:default>
              <div class="form-dropdown__selected">
                {{ selectedLanguage }}
                <chevron-down class="form-dropdown__chevron"/>
              </div>
            </template>

            <template v-slot:option="{ option }">
              <div class="dropdown-sphere-item">
                {{ option.name }}
              </div>
            </template>
          </form-dropdown>
        </div>
      </div>

      <div class="profile-form__photo">
        <photo-uploader :user="authUser"/>
      </div>
    </div>

    <ui-loading-button @click.prevent="onSubmit" :loading="isProcessing">
      {{ $t('common.save') }}
    </ui-loading-button>
  </ui-form>
</template>
<script>
  import UiForm from 'scorework-ui/src/components/form/UiForm'
  import TextField from 'scorework-ui/src/components/TextField'
  import UiLoadingButton from "@/components/ui/UiLoadingButton"
  import ChevronDown from "@/components/icons/ChevronDown";
  import FormDropdown from "@/components/ui/FormDropdown";
  import PhotoUploader from "@/components/user/PhotoUploader";
  import {required} from "vuelidate/lib/validators";
  import {mapActions, mapGetters} from "vuex";
  import languageService from "@/services/language";
  import { handleAsyncErrors } from 'scorework-ui/src/components/form/helpers'

  export default {
    name: 'UserProfileForm',
    components: { PhotoUploader, FormDropdown, ChevronDown, TextField, UiLoadingButton, UiForm },


    data () {
      return {
        form: {
          first_name: '',
          last_name: '',
          lang: 'ru',
        },

        isProcessing: false,

        rules: {
          first_name: {
            rules: { required },
            messages: {
              required: this.$t('auth.firstNameIsRequired')
            }
          },

          last_name: {
            rules: { required },
            messages: {
              required: this.$t('auth.lastNameIsRequired')
            }
          },
        },

        languages: [
          {
            id: 'ru',
            name: 'Русский'
          },
          {
            id: 'en',
            name: 'English'
          }
        ]
      }
    },

    computed: {
      ...mapGetters(['authUser']),

      selectedLanguage () {
        const lang = this.languages.find(item => item.id === this.form.lang)

        return lang.name
      }
    },

    methods: {
      ...mapActions(['updateProfile']),

      submitFn () {
        this.isProcessing = true
        this.updateProfile({ ...this.form })
          .then(user => {
            if (user.lang !== languageService.getLanguage()) {
              languageService.setLanguage(user.lang)
              this.$i18n.locale = user.lang
            }

            this.$notify({
              title: this.$t('common.saved'),
              message: this.$t('pages.profileSaved'),
              position: 'bottom-right',
              type: 'success'
            })
          })
          .catch(handleAsyncErrors(this.rules, () => this.$refs.form.validate()))
          .finally(() => this.isProcessing = false)
      },

      onSubmit () {
        this.$refs.form.submit()
      },

      onLangChange ({ id }) {
        this.form.lang = id
      }
    },

    created () {
      this.form = {
        first_name: this.authUser.first_name,
        last_name: this.authUser.last_name,
        lang: this.authUser.lang,
      }
    }
  }
</script>

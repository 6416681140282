<template>
<div class="messenger-index">
  <div class="channel-view-empty">
    <div class="channel-view-empty__image">
      <no-channels-icon class="channel-view-empty__svg"/>
    </div>
    <div class="channel-view-empty__text">
      {{ $t('messenger.indexDescription')}}
    </div>
    <div class="channel-view-empty__btn">
      <ui-button @click="isNewChannelOpened = true">{{ $t('messenger.startNewDialog')}}</ui-button>
    </div>

    <new-channel-modal
        v-if="isNewChannelOpened"
        @close="isNewChannelOpened = false"
    />
  </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import NoChannelsIcon from "@/components/icons/NoChannelsIcon"
import NewChannelModal from "@/components/messenger/NewChannelModal"
import UiButton from "scorework-ui/src/components/UiButton"
import pageTitleMixin from "@/views/mixins/pageTitleMixin"

export default {
  name: 'Messenger',

  components: { NoChannelsIcon, NewChannelModal, UiButton },

  mixins: [pageTitleMixin],

  data () {
    return {
      isNewChannelOpened: false
    }
  },

  computed: {
    ...mapGetters(['workspace']),

    pageTitle () {
      return {
        message: 'pageTitles.messenger', params: { workspace: this.workspace.name }
      }
    },
  }
}
</script>
<style lang="sass">
.messenger-index
  display: flex
  flex-flow: column nowrap
  flex: 1
  align-content: stretch
  align-items: center
  overflow: auto

.channel-view-empty
  display: flex
  flex-flow: column nowrap
  align-items: center
  padding: 80px 0

  &__image
    padding-bottom: 24px

  &__svg
    width: 300px
    height: auto

  &__text
    padding-bottom: 24px
    font-size: 16px
    color: var(--text-light-color)
    text-align: center
    line-height: 1.75
    width: 425px

  &__btn
    .ui-button
      height: 48px
      font-size: 16px
      width: 250px
      justify-content: center
</style>
<template>
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-stroke" d="M4.77958 11.4668C-0.551693 12.0592 0.0406703 5.54319 4.77958 6.13555C3.00249 -0.380448 13.0727 -0.380447 12.4803 4.35846C18.4039 2.58137 18.4039 12.0592 13.0727 11.4668M5.96431 13.8363L8.92612 16.2057M8.92612 16.2057L11.8879 13.8363M8.92612 16.2057V7.91264" stroke="#27272E" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'DownloadIcon',

    mixins: [iconMixin],

    props: {
      viewBox: {
        type: String,
        default: '0 0 12 12'
      }
    },

    data () {
      return {
        defaultBackground: '#416AF7',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

import axios from 'axios'
import authService from './auth'

class Api {
  constructor () {
    this.responseErrorHandler = null
    this.newClient()
  }

  newClient () {
    const headers = {}

    const authToken = authService.getToken()

    if (authToken !== null) {
      headers['Authorization'] = `Bearer ${authToken}`
    }

    this.client = axios.create({
      baseURL: '/api/',
      timeout: 20000,
      headers
    })

    if (authService.isAuth()) {
      if (this.responseErrorHandler) {
        this.client.interceptors.response.use(response => response, this.responseErrorHandler)
      }
    }
  }

  setToken (token) {
    const { access_token } = token
    authService.setToken(access_token)

    this.newClient()
  }

  removeToken () {
    authService.removeToken()
    this.newClient()
  }

  get (url, params) {
    return this.client.get(url, { params })
  }

  post (url, data) {
    return this.client.post(url, data)
  }

  put (url, data) {
    return this.client.put(url, data)
  }

  delete (url) {
    return this.client.delete(url)
  }

  patch (url, data) {
    return this.client.patch(url, data)
  }

  uploadFile (url, formData, onUploadProgress) {
    return this.client.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },

      onUploadProgress
    })
  }

  downloadFile (url) {
    return this.client.get(url, { responseType: 'blob'} )
  }

  setResponseErrorHandler (handler) {
    this.client.interceptors.response.use(response => response, handler)
    this.responseErrorHandler = handler
  }
}

const apiService = new Api()

export default apiService

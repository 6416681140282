<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 20V18.3333C17 17.4493 16.6049 16.6014 15.9017 15.9763C15.1984 15.3512 14.2446 15 13.25 15H5.75C4.75544 15 3.80161 15.3512 3.09835 15.9763C2.39509 16.6014 2 17.4493 2 18.3333V20" stroke="#6952DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.5 11C11.433 11 13 9.433 13 7.5C13 5.567 11.433 4 9.5 4C7.567 4 6 5.567 6 7.5C6 9.433 7.567 11 9.5 11Z" stroke="#6952DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22 20V18.2964C21.9993 17.5415 21.7044 16.8082 21.1614 16.2115C20.6184 15.6149 19.8581 15.1887 19 15" stroke="#6952DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 4C16.5721 4.19898 17.0792 4.65096 17.4413 5.28467C17.8034 5.91838 18 6.69778 18 7.5C18 8.30222 17.8034 9.08162 17.4413 9.71533C17.0792 10.349 16.5721 10.801 16 11" stroke="#6952DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'UsersIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#FFFFFF',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

<template>
  <div class="pages-content">
    <div class="pages-content__header">
      <div class="pages-content__header-main">
        <breadcrumbs :page="page" v-if="page && page.code" :is-loading="isLoading"/>
        <parent-selector
          v-if="page && !page.code && !isLoading"
          :page="page"
        />
      </div>

      <div class="pages-content__header-additional">
        <div class="pages-content__header-last-edited" v-if="!code">
          <ui-loading-button
            ui-class="size_md"
            @click="onSavePage"
            :loading="isPublishing"
          >
            {{ $t('knowledge.publishPage') }}
          </ui-loading-button>
        </div>
      </div>
    </div>

    <div class="pages-content__wrapper">
      <page-view :page="page" :errors="errors" v-if="!isLoading"/>
    </div>
  </div>
</template>
<script>
  import {mapActions, mapGetters} from 'vuex'
  import PageView from '@/components/pages/PageView'
  import Breadcrumbs from '@/components/pages/Breadcrumbs'
  import ParentSelector from '@/components/pages/ParentSelector'
  import UiLoadingButton from '@/components/ui/UiLoadingButton'

  export default {
    name: 'PageContent',

    components: {UiLoadingButton, Breadcrumbs, PageView, ParentSelector },

    props: {
      code: {
        type: String,
      },

      projectCode: {
        type: String,
      },

      parentCode: {
        type: String,
      },
    },

    data () {
      return {
        isLoading: false,
        title: '',
        description: '',
        editor: null,
        currentPos: null,
        errors: {
          name: ''
        },
        isPublishing: false,
      }
    },

    computed: {
      ...mapGetters(['authUser', 'project']),
      ...mapGetters('pages', ['page']),
    },

    watch: {
      page () {
        if (Object.keys(this.errors).length === 0) {
          return
        }

        if (this.page.name !== '') {
          this.errors = {}
        }
      }
    },

    methods: {
      ...mapActions('pages', ['fetchPage', 'createPage', 'updatePage']),
      ...mapActions(['setUpgradePlanModal']),

      onAttachFile () {

      },

      onEditorInit ({ editor }) {
        this.editor = editor
      },

      onContentChange ({ getJSON, getHTML }) {
        console.log(JSON.stringify(getJSON()))
        console.log(getHTML())
      },

      async onSavePage () {
        if (this.page.name === '') {
          this.errors = {
            name: this.$t('knowledge.notifications.fillPageName')
          }

          this.$notify({
            title: this.$t('common.error'),
            message: this.$t('knowledge.notifications.fillPageName'),
            position: 'bottom-right',
            type: 'error'
          })

          return
        }

        try {
          this.isPublishing = true
          const page = await this.createPage(this.page)
          this.$notify({
            title: this.$t('knowledge.notifications.published'),
            message: this.$t('knowledge.notifications.publishedMore'),
            position: 'bottom-right',
            type: 'success'
          })

          this.$router.push({ name: 'pages.detail', params: { project: this.project.code, code: page.code }})
        } catch (e) {
          if (!e.response) {
            this.$notify({
              title: this.$t('common.error'),
              message: this.$t('knowledge.notifications.publishedFailed'),
              position: 'bottom-right',
              type: 'error'
            })

            throw e
          }

          const { data } = e.response
          if (e.response.status === 400 && data.code === 'features.limit_exceeded') {
            this.setUpgradePlanModal({
              show: true,
              feature: 'pages_count',
            })
          }
        } finally {
          this.isPublishing = false
        }
      }
    },

    async created () {
      this.isLoading = true
      await this.fetchPage({
        code: this.code,
        defaultValues: {
          project: this.projectCode,
          parent: this.parentCode,
        }
      })
      this.isLoading = false
    }
  }
</script>
<style lang="sass">
.page-content
  flex: 1
  height: 100%
  display: flex
  flex-flow: column

  &__header
    display: flex
    justify-content: space-between
    padding: 20px 20px

    &-title
      font-size: 20px
      font-weight: 500

  &__wrapper
    flex: 1
    overflow: scroll

</style>

<template>
  <div class="setup-example-layout">
    <div class="setup-example__ui-elements">
      <template v-if="activeHint === 'tasks'">
        <div class="setup-example__spheres">
          <task-spheres
            :spheres="spheres"
            :value="filter.sphereId"
            nullable-title="setup.hints.spheres.allSpheres"
            @input="onSphereChange"
            popper-class="ui-dropdown_no-border"
          />
        </div>

        <transition-group name="zoom" tag="div" style="position: relative" appear>
          <task-item
            v-for="(task, i) in filteredTasks"
            :key="task.number"
            :index="i"
            :task="task"
            :class="taskItemClass"
            :display-stage="false"
          />
        </transition-group>
      </template>

      <template v-if="activeHint === 'members'">
        <div class="setup-example__sphere-members setup-example__ui-elements">
          <div
            class="sphere-members-block"
            v-for="sphere in sphereMembers"
            :key="sphere.id"
            :class="'sphere-members-block_' + sphere.id"
          >
            <div class="sphere-members-block__title">{{ sphere.name }}</div>

            <div class="sphere-members-block__list">
              <div
                v-for="member in sphere.members"
                :key="member.member_id"
                class="sphere-members-block__item"
              >
                <user-photo :user="member" size="md" class="sphere-members-block__photo"/>
                {{ member.first_name }} {{ member.last_name }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="setup-example__hints">
      <transition name="slide-left2" appear>
        <div class="setup-example-hint" :key="activeHint">
          <div class="setup-example-hint__title">
            {{ currentHint.title }}
          </div>

          <div class="setup-example-hint__content" v-html="currentHint.content"></div>
        </div>
      </transition>
    </div>

    <div class="setup-example__hint-circles">
      <div class="hint-circles">
        <div
            v-for="hint in hints"
            :key="hint.name"
            class="hint-circle"
            :class="{'hint-circle_active': hint.name === activeHint}"
            @click="onChangeHint(hint.name)"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import TaskItem from "@/components/tasks/TaskItem"
import TaskSpheres from "@/components/tasks/TaskSpheres"
import UserPhoto from "@/components/ui/UserPhoto"
import { TransformTaskItemDates } from "@/transformers/tasks"

export default {
  name: 'SpheresExample',

  components: { TaskItem, TaskSpheres, UserPhoto },

  props: {
    activeHint: {
      type: String,
      required: true
    }
  },

  data () {
    const tasks = [
      {
        name: this.$t('setup.samples.tasks.name1'),
        number: '181',
        sphere: {
          id: 3,
          name: this.$t('setup.samples.tasks.sphere1')
        },
        stage_id: 123,
        state: {
          stage: {
            id: 3,
            name: 'Frontend'
          },
          actual_progress: 0,
          assignee: {
            member_id: 123,
            first_name: "Boris",
            last_name: "Bezborodov"
          },
          be_processed_at: "2021-03-14 21:56:49",
          estimated_finish_at: "2021-03-18 16:20:00",
          processing: null,
          score: 360,
          score_status: "estimated",
          started_at: "2021-03-14 21:56:49"
        },
        status: 'open',
        target: null,
        task_date: '2021-03-14 21:56:49'
      },
      {
        name: this.$t('setup.samples.tasks.name4'),
        number: '198',
        sphere: {
          id: 4,
          name: this.$t('setup.samples.tasks.sphere3')
        },
        stage_id: 123,
        state: {
          stage: {
            id: 4,
            name: 'Дизайн'
          },
          actual_progress: 0,
          assignee: {
            member_id: 126,
            first_name: "Kirill",
            last_name: "Kulagin"
          },
          be_processed_at: "2021-03-14 21:56:49",
          estimated_finish_at: "2021-03-18 16:20:00",
          processing: null,
          score: 360,
          score_status: "estimated",
          started_at: "2021-03-14 21:56:49"
        },
        status: 'open',
        target: null,
        task_date: '2021-03-14 21:56:49'
      },
      {
        name: this.$t('setup.samples.tasks.name3'),
        number: '202',
        sphere: {
          id: 2,
          name: this.$t('setup.samples.tasks.sphere4')
        },
        stage_id: 123,
        state: {
          stage: {
            id: 2,
            name: 'Backend'
          },
          actual_progress: 0,
          assignee: {
            member_id: 125,
            first_name: "Mike",
            last_name: "Korsakov"
          },
          be_processed_at: "2021-03-14 21:56:49",
          estimated_finish_at: "2021-03-18 16:20:00",
          processing: null,
          score: 360,
          score_status: "estimated",
          started_at: "2021-03-14 21:56:49"
        },
        status: 'open',
        target: null,
        task_date: '2021-03-14 21:56:49'
      }
    ]

    return {
      tasks: tasks.map(TransformTaskItemDates),
      spheres: [
        {
          id: 1,
          name: this.$t('setup.samples.tasks.sphere1'),
          is_parent: true,
          is_child: false
        },
        {
          id: 2,
          name: this.$t('setup.samples.tasks.sphere2'),
          is_parent: false,
          is_child: true,
          parent_id: 1
        },
        {
          id: 3,
          name: this.$t('setup.samples.tasks.sphere4'),
          is_parent: false,
          is_child: true,
          parent_id: 1
        },
        {
          id: 4,
          name: this.$t('setup.samples.tasks.sphere3'),
          is_parent: true,
          is_child: false
        }
      ],
      sphereMembers: [
        {
          id: 2,
          name: this.$t('setup.samples.tasks.sphere4'),
          members: [
            {
              member_id: 1,
              first_name: 'Boris',
              last_name: 'Bezborodov'
            },
            {
              member_id: 2,
              first_name: 'Max',
              last_name: 'Serpantinov'
            }
          ]
        },
        {
          id: 3,
          name: this.$t('setup.samples.tasks.sphere2'),
          members: [
            {
              member_id: 3,
              first_name: 'Alexey',
              last_name: 'Marabu'
            },
            {
              member_id: 4,
              first_name: 'Ilya',
              last_name: 'Shaman'
            }
          ]
        },
        {
          id: 4,
          name: this.$t('setup.samples.tasks.sphere3'),
          members: [
            {
              member_id: 5,
              first_name: 'Kirill',
              last_name: 'Samsonov'
            },
            {
              member_id: 6,
              first_name: 'Nikolay',
              last_name: 'Pimenov'
            }
          ]
        }
      ],
      filter: {
        sphereId: null
      },
      hints: [
        {
          name: 'tasks',
          title: this.$t('setup.hints.spheres.tasks.title'),
          content: this.$t('setup.hints.spheres.tasks.content')
        },
        {
          name: 'members',
          title: this.$t('setup.hints.spheres.members.title'),
          content: this.$t('setup.hints.spheres.members.content')
        }
      ]
    }
  },

  computed: {
    currentHint () {
      return this.hints.find(item => item.name === this.activeHint)
    },

    taskItemClass () {
      const classes = []

      if (this.activeHint === 'task_prefix') {
        classes.push(`task-item_number-highlighted`)
      } else if (this.activeHint === 'schedule') {
        classes.push(`task-item_schedule-highlighted`)
      }

      return classes
    },

    filteredTasks () {
      return this.tasks.filter(item => {
        const sphere = this.spheres.find(sphere => sphere.id === item.sphere.id)
        return !this.filter.sphereId || this.filter.sphereId === sphere.id || this.filter.sphereId === sphere.parent_id
      })
    }
  },

  methods: {
    onChangeHint (val) {
      this.$emit('change', val)
    },

    onSphereChange (sphere) {
      this.filter.sphereId = sphere.id
    }
  }
}
</script>
import { createPopoverInstance } from '@/components/ui/textEditor/popovers/popoverInstance'

const EditorPopover = {
  name: 'EditorPopover',

  props: {
    editor: {
      type: Object,
      required: true,
    },

    tippyOptions: {
      type: Object,
      default: () => ({}),
    },

    appendToParent: {
      type: Boolean,
      default: false,
    }
  },

  data () {
    return {
      popover: null,
    }
  },

  render (createElement) {
    return createElement('div', {
      style: {
        visibility: 'hidden',
      },
      on: {
        mousedown: (e) => {
          e.preventDefault()
          e.stopImmediatePropagation()
        },
      },
    }, this.$slots.default)
  },

  mounted () {
    this.popover = createPopoverInstance({
      editor: this.editor,
      element: this.$el,
      tippyOptions: this.tippyOptions,
      container: this.appendToParent ? this.$el.parentNode : null,
    })

    this.$emit('created', this.popover)
  }
}

export default EditorPopover

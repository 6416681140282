const messengerSocket = (store) => socket => {
  socket.on('channels.created', () => {

  })

  socket.on('messenger.messages.typing', ({ channelId, memberId, author = null }) => {
    store.dispatch('messenger/setMessageTyping', { channelId, memberId, author })
  })

  socket.on('messenger.messages.new', message => {
    store.dispatch('messenger/appendMessage', message)
  })

  socket.on('messenger.channels.created', ({ channel }) => {
    store.dispatch('messenger/appendChannel', { channel })
  })
}

export default messengerSocket

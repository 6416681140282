<template>
  <div class="settings-page">
    <div class="settings-block">
      <div class="settings-block__title">
        {{ $t('settings.user.personal') }}
      </div>

      <user-profile-form/>
    </div>

    <div class="settings-block">
      <div class="settings-block__title">
        {{ $t('pages.workingShift') }}
      </div>

      <work-schedule-form/>
    </div>

    <div class="settings-block">
      <div class="settings-block__title">
        {{ $t('settings.user.projectSettings') }} - {{ project.name }}
      </div>

      <member-profile-form/>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import MemberProfileForm from "@/components/settings/MemberProfileForm"
import UserProfileForm from "@/components/settings/UserProfileForm"

import pageTitleMixin from "@/views/mixins/pageTitleMixin";
import WorkScheduleForm from "@/components/settings/WorkScheduleForm"

export default {
  name: 'UserSettings',

  components: {WorkScheduleForm, UserProfileForm, MemberProfileForm },

  mixins: [pageTitleMixin],

  data () {
    return {

    }
  },

  computed: {
    ...mapGetters(['workspace', 'project']),

    pageTitle () {
      return {
        message: 'pageTitles.settings.profile',
        params: { workspace: this.workspace.name }
      }
    }
  }
}
</script>

<template>
  <div class="parent-selector-item">
    <div class="parent-selector-item__row" :style="rowStyles" @click="onSelect">
      <pages-menu-btn :ui-class="arrowClasses" @click.stop="toggleExtend">
        <chevron-right-icon/>
      </pages-menu-btn>

      <div class="parent-selector-item__name">
        <div class="parent-selector-item__icon">
          <template v-if="icon">
            {{ icon }}
          </template>
          <dashboard-icon class="parent-selector-item__icon-home" v-else/>
        </div>

        <div class="parent-selector-item__name-label">
          {{ title }}
        </div>
      </div>
    </div>

    <div class="parent-selector-item__children" v-if="showChild && !isLoadingChild">
      <parent-selector-item
        v-for="childItem in childrenPages"
        :key="childItem.code"
        :project="project"
        :page="childItem"
        :level="level + 1"
        @select="onSelectChildren"
      />

      <div class="parent-selector-item__row parent-selector-item__row_empty" :style="noChildrenStyles" v-if="childrenPages.length === 0">
        Нет страниц
      </div>
    </div>
  </div>
</template>
<script>
  import ChevronRightIcon from '@/components/icons/ChevronRightIcon'
  import DashboardIcon from '@/components/icons/DashboardIcon'
  import PagesMenuBtn from '@/components/pages/PagesMenuBtn'
  import {mapActions, mapGetters} from 'vuex';

  export default {
    name: 'ParentSelectorItem',

    components: { DashboardIcon, PagesMenuBtn, ChevronRightIcon },

    props: {
      project: {
        type: Object,
      },

      page: {
        type: Object,
      },

      level: {
        type: Number,
        default: 0,
      }
    },

    data () {
      return {
        showChild: false,
        isLoadingChild: false,
      }
    },

    computed: {
      ...mapGetters('pages', ['parentSelectorPagesByParent']),

      title () {
        if (this.page) {
          return this.page.name
        }

        return this.project ? this.project.name : 'Общие страницы'
      },

      icon () {
        return this.page?.icon.value
      },

      arrowClasses () {
        const classes = ['arrow']
        if (this.showChild) {
          classes.push('arrow_extended')
        }

        return classes.join(' ')
      },

      childrenPages () {
        return this.parentSelectorPagesByParent(this.page?.code || this.project?.code || 'common')
      },

      noChildrenStyles () {
        return {
          paddingLeft: (8 + (this.level + 1) * 12) + 'px',
        }
      },

      rowStyles () {
        return {
          paddingLeft: (8 + this.level * 12) + 'px',
        }
      }
    },

    methods: {
      ...mapActions('pages', ['fetchParentSelectorChild']),

      async toggleExtend () {
        await this.fetchParentSelectorChild({ project: this.project?.code, parent: this.page?.code })
        this.showChild = !this.showChild
      },

      onSelect () {
        const pages = []
        if (this.page) {
          pages.push(this.page)
        }

        this.$emit('select', pages)
      },

      onSelectChildren (parents) {
        if (this.page) {
          this.$emit('select', [ this.page, ...parents ])
          return
        }

        this.$emit('select', parents)
      }
    }
  }
</script>
<style lang="sass">
  .parent-selector-item
    width: 100%

    &__row
      display: flex
      column-gap: 6px
      align-items: center
      padding: 4px 8px
      border-radius: 4px
      transition: all .2s ease-in
      cursor: pointer
      width: 100%
      box-sizing: border-box

      &:hover
        background: var(--grey-light-color-40)

      .pages-menu-btn
        display: flex

      &_empty
        color: var(--grey-color-100)
        font-size: 12px

    &__name
      display: flex
      column-gap: 6px
      align-items: center
      font-size: 12px
      overflow: hidden

      &-label
        flex: 1
        display: inline-block
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

    &__icon
      display: flex
      align-items: center

    &__icon-home
      width: 12px
      height: 12px
</style>

<template>
  <div id="app">
    <router-view v-if="!isDataFetching"></router-view>
    <div v-else class="app-loading">
      <loading-logo class="app-loading__logo"/>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, createNamespacedHelpers } from "vuex"
import languageService from "@/services/language"
import themeService from "@/services/theme"
import LoadingLogo from "@/components/layouts/components/LoadingLogo"

const { mapActions: messengerActions } = createNamespacedHelpers('messenger')

export default {
  name: 'app',

  components: { LoadingLogo },

  data () {
    return {
      isDataFetching: false
    }
  },

  computed: {
    ...mapGetters(['isAuth', 'authUser', 'isInitInProgress', 'workspace'])
  },

  methods: {
    ...mapActions(['fetchInit']),
    ...messengerActions(['setIsWindowActive']),
  },

  watch: {
    isInitInProgress (val) {
      this.isDataFetching = val

      if (val === true || !this.isAuth) {
        return
      }

      if (languageService.getLanguage() !== this.authUser.lang) {
        languageService.setLanguage(this.authUser.lang)
        this.$i18n.locale = this.authUser.lang
      }

      if (themeService.getTheme() !== this.authUser.theme) {
        themeService.setTheme(this.authUser.theme)
        themeService.applyTheme()
      }

      if (this.workspace.status === 'new') {
        this.$router.push({ name: 'setup.general' })
      }
    }
  },

  created () {
    if (!this.isAuth) {
      return
    }

    window.addEventListener('focus', () => this.setIsWindowActive(true));
    window.addEventListener('blur', () => this.setIsWindowActive(false));

    this.isDataFetching = true
    const { project } = this.$route.params
    this.fetchInit({ project })
  },

  mounted () {
    //document.body.className = 'dark'
  },
}
</script>
<style lang="sass">
.app-loading
  background: var(--bg-color)
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center

  &__logo
    width: 150px
    height: 150px
</style>

<template>
  <div class="report-box">
    <el-skeleton :loading="isLoading" animated>
      <template slot="template">
        <div class="report-grid">
          <div class="report-grid__header">
            <div class="report-grid__th report-grid__th_member">
              <el-skeleton-item variant="text" style="height: 16px; width: 45px;"/>
            </div>

            <div class="report-grid__th report-grid__th_tasks">
              <el-skeleton-item variant="text" style="height: 16px; width: 45px;"/>
            </div>

            <div class="report-grid__th report-grid__th_score">
              <el-skeleton-item variant="text" style="height: 16px; width: 45px;"/>

            </div>

            <div class="report-grid__th report-grid__th_points">
              <el-skeleton-item variant="text" style="height: 16px; width: 45px;"/>
            </div>

            <div
              v-for="j in 9"
              :key="j"
              class="report-grid__th"
            >
              <el-skeleton-item variant="text" style="height: 16px; width: 45px;"/>
            </div>
          </div>

          <div
              v-for="i in 3"
              :key="i"
              class="report-grid__row"
          >
            <div class="report-grid__row-cell">
              <div class="report-grid__member">
                <el-skeleton-item variant="text" style="height: 44px; width: 44px;"/>

                <div>
                  <div class="report-grid__member-name">
                    <el-skeleton-item variant="text" style="height: 16px; width: 85px;"/>
                  </div>
                  <div class="report-grid__spheres">
                    <el-skeleton-item variant="text" style="height: 16px; width: 40px;"/>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-for="j in 9"
              :key="j"
              class="report-grid__row-cell report-grid__row-cell-metric"
            >
              <el-skeleton-item variant="text" style="height: 16px; width: 40px;"/>
            </div>
          </div>

          <div class="report-grid__total">
            <div
                v-for="j in 10"
                :key="j"
                class="report-grid__row-cell">
              <el-skeleton-item variant="text" style="height: 16px; width: 50px;"/>
            </div>
          </div>
        </div>
      </template>
      <div class="report-grid">
        <div class="report-grid__header">
          <div class="report-grid__th report-grid__th_member">
            {{ $t('report.header.member') }}
          </div>

          <div class="report-grid__th report-grid__th_tasks">
            {{ $t('report.header.tasks') }}
          </div>

          <div class="report-grid__th report-grid__th_score">
            {{ $t('report.header.time') }}
          </div>

          <div class="report-grid__th report-grid__th_points">
            {{ $t('report.header.points') }}
          </div>

          <div class="report-grid__th">
            {{ $t('report.header.totalTasks') }}
          </div>

          <div class="report-grid__th">
            {{ $t('report.header.completedTasks') }}
          </div>

          <div class="report-grid__th">
            {{ $t('report.header.delta') }}
          </div>

          <div class="report-grid__th">
            {{ $t('report.header.createdTasks') }}
          </div>

          <div class="report-grid__th">
            {{ $t('report.header.totalScore') }}
          </div>

          <div class="report-grid__th">
            {{ $t('report.header.expectedScore') }}
          </div>

          <div class="report-grid__th">
            {{ $t('report.header.delta') }}
          </div>

          <div class="report-grid__th">
            {{ $t('report.header.totalPoints') }}
          </div>

          <div class="report-grid__th">
            {{ $t('report.header.avgPoints') }}
          </div>
        </div>

        <div
            v-for="item in report.rows"
            class="report-grid__row"
            :key="item.member_id"
        >
          <div
              class="report-grid__row-cell"
          >

            <div class="report-grid__member">
              <user-photo :user="item.member" size="xmd"/>

              <div>
                <div class="report-grid__member-name">
                  {{ item.member.full_name }}
                </div>
                <div class="report-grid__spheres">
                  <sphere-badge
                      v-for="sphere in item.member.project.spheres"
                      :key="sphere.id"
                      :sphere="sphere"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="report-grid__row-cell report-grid__row-cell-metric">
            {{ item.total_tasks }}
          </div>

          <div class="report-grid__row-cell report-grid__row-cell-metric">
            {{ item.completed_tasks }}
          </div>

          <div class="report-grid__row-cell report-grid__row-cell-metric">
            {{ item.tasks_delta }}
          </div>

          <div class="report-grid__row-cell report-grid__row-cell-metric">
            {{ item.created_tasks }}
          </div>

          <div class="report-grid__row-cell report-grid__row-cell-metric">
            {{ formattedScore(item.total_score) }}
          </div>

          <div class="report-grid__row-cell report-grid__row-cell-metric">
            {{ formattedScore(item.expected_score) }}
          </div>

          <div class="report-grid__row-cell report-grid__row-cell-metric">
            {{ formattedScore(item.score_delta) }}
          </div>

          <div class="report-grid__row-cell report-grid__row-cell-metric">
            {{ item.total_points }}
          </div>

          <div class="report-grid__row-cell report-grid__row-cell-metric">
            {{ item.avg_points }}
          </div>
        </div>

        <div class="report-grid__total">
          <div class="report-grid__row-cell">
            {{ $t('report.total') }}:
          </div>

          <div class="report-grid__row-cell">
            {{ report.total.total_tasks }}
          </div>

          <div class="report-grid__row-cell">
            {{ report.total.completed_tasks }}
          </div>

          <div class="report-grid__row-cell">
            {{ report.total.tasks_delta }}
          </div>

          <div class="report-grid__row-cell">
            {{ report.total.created_tasks }}
          </div>


          <div class="report-grid__row-cell">
            {{ formattedScore(report.total.total_score) }}
          </div>

          <div class="report-grid__row-cell">
            {{ formattedScore(report.total.expected_score) }}
          </div>

          <div class="report-grid__row-cell" :class="deltaClasses(report.total.score_delta)">
            {{ formattedScore(report.total.score_delta) }}
          </div>

          <div class="report-grid__row-cell">
            {{ report.total.total_points }}
          </div>

          <div class="report-grid__row-cell">
            {{ report.total.avg_points }}
          </div>
        </div>
      </div>
    </el-skeleton>
  </div>
</template>
<script>
import SphereBadge from "@/components/ui/SphereBadge";
import UserPhoto from "@/components/ui/UserPhoto";
import {scoreToLabel} from "@/services/scoring";

export default {
  components: {UserPhoto, SphereBadge},

  props: {
    report: {},
    isLoading: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    formattedScore (score) {
      if (!score) {
        return '-'
      }

      const result = scoreToLabel(Math.abs(score))
      return `${score < 0 ? '-' : ''}${result}`
    },

    deltaClasses (delta) {
      return [
        delta > 0 ? 'negative-delta' : 'positive-delta'
      ]
    },
  }
}
</script>
<style lang="sass">
.report-box
  margin-top: 24px
  border: 1px solid var(--grey-light-color-60)
  border-radius: 8px

.report-grid
  display: grid
  grid-template-columns: 2fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr 1fr 1fr 1fr 1fr
  justify-items: stretch

  &__header
    display: contents
    font-size: 13px
    color: var(--text-light-color)
    border-radius: 8px
    margin-bottom: 8px

    .report-grid__th:last-child
      border-right: 0

  &__th
    font-weight: 500
    background: var(--grey-light-color-24)
    padding: 6px 12px
    border-right: 1px solid var(--grey-light-color-60)
    border-bottom: 1px solid var(--grey-light-color-60)

    &_member
      grid-row-start: 1
      grid-row-end: 3
      display: flex
      align-items: center

    &_tasks
      grid-column-start: 2
      grid-column-end: 6
      border-bottom: 1px solid var(--grey-light-color-60)

    &_score
      grid-column-start: 6
      grid-column-end: 9
      border-bottom: 1px solid var(--grey-light-color-60)

    &_points
      grid-column-start: 9
      grid-column-end: 11
      border-bottom: 1px solid var(--grey-light-color-60)
      border-right: 0

  &__row
    display: contents

    &:hover
      .report-grid__row-cell
        background: var(--grey-light-color-24)

    .report-grid__row-cell
      border-bottom: 1px solid var(--grey-light-color-40)

    &-cell
      padding: 8px 12px
      box-sizing: border-box

      &-metric
        width: 100%
        display: flex
        justify-items: center
        align-items: center

  &__total
    display: contents
    border-bottom: 0

    .report-grid__row-cell
      border-top: 1px solid var(--grey-light-color-40)
      padding: 16px 12px

  &__member
    display: flex
    column-gap: 12px
    align-items: center

    &-name
      margin-top: 4px

  &__spheres
    display: flex
    align-items: center
    column-gap: 4px
    flex-flow: row wrap
    margin: 8px 0 0 0
</style>

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M7.99996 3.55558C8.98181 3.55558 9.77775 2.75964 9.77775 1.77779C9.77775 0.795944 8.98181 0 7.99996 0C7.01811 0 6.22217 0.795944 6.22217 1.77779C6.22217 2.75964 7.01811 3.55558 7.99996 3.55558Z" fill="white" class="path-fill"/>
      <path d="M7.99996 9.77824C8.98181 9.77824 9.77775 8.9823 9.77775 8.00045C9.77775 7.0186 8.98181 6.22266 7.99996 6.22266C7.01811 6.22266 6.22217 7.0186 6.22217 8.00045C6.22217 8.9823 7.01811 9.77824 7.99996 9.77824Z" fill="white" class="path-fill"/>
      <path d="M7.99996 16.0009C8.98181 16.0009 9.77775 15.2049 9.77775 14.2231C9.77775 13.2413 8.98181 12.4453 7.99996 12.4453C7.01811 12.4453 6.22217 13.2413 6.22217 14.2231C6.22217 15.2049 7.01811 16.0009 7.99996 16.0009Z" fill="white" class="path-fill"/>
    </g>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'MoreIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 10,
        defaultHeight: 10
      }
    }
  }
</script>

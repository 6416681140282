import { mapActions, mapGetters } from "vuex"
import ClockIcon from "@/components/icons/ClockIcon"
import UserPhoto from "@/components/ui/UserPhoto"
import RoundPlus from "@/components/icons/RoundPlus"
import { scoreToLabel } from '@/services/scoring';

export default {
  components: { ClockIcon, UserPhoto, RoundPlus},

  props: {
    task: {
      type: Object,
      required: true
    },

    index: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      isDragging: false,
      showAddButton: false
    }
  },

  computed: {
    ...mapGetters(['project']),

    tags () {
      return this.task.tags ? this.task.tags.map(item => item.name).join(', ') : ''
    },

    formattedScore () {
      if (!this.task.score) {
        return this.$t('tasks.noScore')
      }

      return scoreToLabel(this.task.score)
    },

    parsedScore () {
      const minutes = this.task.score % 60
      const hours = (this.task.score - minutes) / 60

      return { hours, minutes }
    },

    taskNumber () {
      return this.project && this.project.task_prefix
        ? `#${this.project.task_prefix}-${this.task.number}`
        : `#${this.task.number}`
    },

    taskState () {
      return this.task.state
    }
  },

  methods: {
    ...mapActions(['sortTask', 'muteTasks', 'unMuteTasks']),

    onOpenTask () {
      this.$emit('open', this.task)
    },

    onAddTask () {
      this.$emit('create', this.task)
    }
  }
}

<template>
  <div class="workload">
    <div class="workload__header">
      <div class="workload__title">
        {{ $t('layout.workload') }}
      </div>

      <WorkloadFilters class="workload__filters"/>
    </div>
    <div class="workload__content">
      <WorkloadMembers
        :members="activeMembers"
      />
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import pageTitleMixin from "@/views/mixins/pageTitleMixin"
import WorkloadMembers from '@/components/workload/WorkloadMembers'
import socketService from '@/services/socket'
import workloadSocket from '@/store/socket/workload'
import WorkloadFilters from '@/components/workload/WorkloadFilters';

const { mapActions: workloadActions, mapGetters: workloadGetters } = createNamespacedHelpers('workload')

export default {
  name: 'WorkLoad',

  components: {WorkloadFilters, WorkloadMembers },

  mixins: [pageTitleMixin],

  props: {
  },

  data () {
    return {
      socket: null,
    }
  },

  computed: {
    ...mapGetters(['activeMembers', 'workspace']),
    ...workloadGetters(['membersTasks']),

    pageTitle () {
      return {
        message: 'pageTitles.sprints', params: { workspace: this.workspace.name }
      }
    }
  },

  methods: {
    ...workloadActions(['fetchWorkload'])
  },

  created () {
    this.fetchWorkload()
    this.socket = workloadSocket(this.$store, this.$router, () => this.fetchWorkload())
    this.socket.subscribe(socketService.socket)
  },

  beforeDestroy () {
    this.socket.unsubscribe(socketService.socket)
  }
}
</script>
<style lang="sass">
  .workload
    box-sizing: border-box
    display: flex
    flex-flow: column nowrap
    width: 100%

    &__header
      background: var(--block-bg-color)
      padding: 18px 20px

    &__title
      font-size: 22px
      font-weight: 500

    &__filters
      margin-top: 24px

    &__content
      flex: 1
      overflow: auto
      overscroll-behavior-x: contain
</style>

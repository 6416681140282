import apiService from "../../services/api"

const stepKey = 'tourStep'

export default function () {
  const state = {
    currentStep: localStorage.getItem(stepKey) || '',
    stepList: [
      'spheres', 'members', 'stages', 'tasks', 'tasksDate', 'tasksProgress', 'activeTask', 'targets', 'backlog'
    ],
    steps: {
      spheres: {
        name: 'spheres',
        placement: 'right-start',
        el: {
          ref: 'tasksSpheres'
        },
        title: 'tour.steps.spheres.title',
        icon: 'spheres-icon',
        description: ['tour.steps.spheres.description']
      },
      members: {
        name: 'members',
        placement: 'bottom-start',
        el: {
          ref: 'tasksMembers'
        },
        title: 'tour.steps.members.title',
        icon: 'members-icon',
        description: ['tour.steps.members.description']
      },
      stages: {
        name: 'stages',
        placement: 'bottom-start',
        el: {
          ref: 'tasksStages'
        },
        title: 'tour.steps.stages.title',
        icon: 'stages-icon',
        description: ['tour.steps.stages.description', 'tour.steps.stages.description2']
      },
      tasks: {
        name: 'tasks',
        placement: 'right-start',
        el: {
          ref: 'tasksFlow'
        },
        title: 'tour.steps.tasksFlow.title',
        icon: 'tasks-flow-icon',
        description: ['tour.steps.tasksFlow.description']
      },
      tasksDate: {
        name: 'tasksDate',
        placement: 'right-start',
        el: {
          ref: 'tasksFlow'
        },
        title: 'tour.steps.tasksDate.title',
        icon: 'tasks-date-icon',
        description: ['tour.steps.taskDate.description']
      },

      tasksProgress: {
        name: 'tasksProgress',
        placement: 'right-start',
        el: {
          ref: 'tasksFlow'
        },
        title: 'tour.steps.tasksProgress.title',
        icon: 'tasks-progress-icon',
        description: ['tour.steps.tasksProgress.description']
      },

      activeTask: {
        name: 'activeTask',
        placement: 'right-start',
        el: {
          ref: 'sidebar',
          selector: '.sb-active-task'
        },
        title: 'tour.steps.activeTask.title',
        icon: 'tasks-progress-icon',
        description: ['tour.steps.activeTask.description']
      },

      targets: {
        name: 'targets',
        placement: 'left-start',
        el: {
          ref: 'targets'
        },
        title: 'tour.steps.targets.title',
        icon: 'targets-icon',
        description: ['tour.steps.targets.description']
      },

      backlog: {
        name: 'backlog',
        placement: 'left-start',
        el: {
          ref: 'backlog'
        },
        title: 'tour.steps.backlog.title',
        icon: 'tasks-flow-icon',
        description: ['tour.steps.backlog.description']
      }
    }
  }

  const mutations = {
    SET_CURRENT_STEP (state, step) {
      state.currentStep = step
    },

    START_TOUR(state) {
      state.currentStep = state.stepList[0]
    }
  }

  const actions = {
    setCurrentStep ({ commit }, step) {
      localStorage.setItem(stepKey, step)
      commit('SET_CURRENT_STEP', step)
    },

    startTour ({ commit }) {
      commit('START_TOUR')
    },

    skipTour ({ dispatch }, { step }) {
      return apiService.post(`user/tour`, {
        status: 'skipped',
        last_step: step
      }).then(() => {
        localStorage.removeItem(stepKey)
        dispatch('refreshUser', { tour_status: 'skipped' }, { root: true })
      })
    },

    completeTour ({ dispatch }, { step, score, feedback }) {
      return apiService.post(`user/tour`, {
        status: 'passed',
        last_step: step,
        score,
        feedback
      }).then(() => {
        localStorage.removeItem(stepKey)
        dispatch('refreshUser', { tour_status: 'skipped' }, { root: true })
      })
    }
  }

  const getters = {
    stepsCount: ({ stepList }) => stepList.length,

    currentStepNumber: ({ stepList, currentStep }) => stepList.indexOf(currentStep) + 1,

    stepList: ({ stepList }) => stepList,

    currentStep: ({ currentStep }) => currentStep,

    currentStepData: ({ currentStep, steps }) => steps[currentStep] || {}
  }

  return {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  }
}
<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-stroke" d="M8.94408 2.72084C9.39227 0.423497 12.6792 0.423497 13.1273 2.72084C13.4001 4.11881 14.941 4.8609 16.2041 4.20251C18.2796 3.12054 20.329 5.69034 18.8123 7.47311C17.8893 8.55796 18.2699 10.2254 19.5722 10.8024C21.7122 11.7505 20.9808 14.955 18.6413 14.8808C17.2177 14.8356 16.1513 16.1728 16.5121 17.5506C17.1051 19.8149 14.1437 21.2411 12.7432 19.3657C11.8909 18.2245 10.1805 18.2245 9.32827 19.3657C7.92768 21.2411 4.9663 19.8149 5.55928 17.5506C5.92011 16.1728 4.85373 14.8356 3.43013 14.8808C1.09065 14.955 0.359248 11.7505 2.49926 10.8024C3.80149 10.2254 4.18208 8.55796 3.25915 7.47311C1.74245 5.69034 3.79179 3.12054 5.86737 4.20251C7.13038 4.8609 8.67135 4.11881 8.94408 2.72084Z" stroke="#636478" stroke-width="1.4"/>
    <path class="path-stroke" d="M14.0357 10.9994C14.0357 12.6563 12.6926 13.9994 11.0357 13.9994C9.37886 13.9994 8.03571 12.6563 8.03571 10.9994C8.03571 9.34256 9.37886 7.99942 11.0357 7.99942C12.6926 7.99942 14.0357 9.34256 14.0357 10.9994Z" stroke="#636478" stroke-width="1.4"/>
  </svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'SettingsIcon',

  mixins: [iconMixin],

  props: {
    viewBox: {
      type: String,
      default: '0 0 12 12'
    }
  },

  data () {
    return {
      defaultBackground: '#416AF7',
      defaultWidth: 12,
      defaultHeight: 12
    }
  }
}
</script>

const TOKEN_KEY = 'sw_token'
const COOKIE_LIFETIME = 86400 * 30

class Auth {
  getToken () {
    let token = localStorage.getItem(TOKEN_KEY)
    const cookieToken = getCookie(TOKEN_KEY)

    if (token !== cookieToken) {
      token = cookieToken
      localStorage.setItem(TOKEN_KEY, token)
    }

    return token
  }

  setToken (token) {
    const domain = getDomain()
    localStorage.setItem(TOKEN_KEY, token)
    document.cookie = `${TOKEN_KEY}=${token};path=/;domain=${domain}; max-age=${COOKIE_LIFETIME}`
  }

  removeToken () {
    const domain = getDomain()
    localStorage.removeItem(TOKEN_KEY)
    document.cookie = `${TOKEN_KEY}=;path=/;domain=${domain}; max-age=-1`
  }

  isAuth () {
    return this.getToken() !== null
  }
}

const authService = new Auth()

export default authService

function getCookie (name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([.$?*|{}()[]\\\/+^])/g, '\\$1') + "=([^;]*)"
  ))
  return matches ? decodeURIComponent(matches[1]) : null
}

function getDomain () {
  const domainParts = window.location.hostname.split('.')
  domainParts.shift()

  return domainParts.join('.')
}

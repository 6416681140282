<template>
  <el-dialog
      :title="$t('stages.deleteStage')"
      :visible="true"
      width="30%"
      @close="onClose"
  >
    <div>
      <div class="mb-2">
        {{ $t('stages.selectStageToMoveTasksFrom') }} <b>{{ stage.name }}</b>:
      </div>
      <div>
        <task-spheres
          class="stage-form__sphere-dropdown"
          :spheres="movingStagesList"
          :value="stageId"
          :nullable-title="$t('stages.selectStage')"
          :parents-active="false"
          :accept-null="false"
          @input="onStageChange"
        >
          <template v-slot:default="{ selectedSphere }">
            <div class="stage-form__sphere-selected">
              {{ selectedSphere.name }}
            </div>
          </template>
        </task-spheres>
      </div>
    </div>
    <div class="mt-4">
      <ui-button @click.prevent="onDelete">{{ $t('stages.deleteAndMove') }}</ui-button>
      <ui-button @click.prevent="onClose" ui-class="plain">{{ $t('common.cancel') }}</ui-button>
    </div>
  </el-dialog>
</template>
<script>
  import UiButton from 'scorework-ui/src/components/UiButton'
  import TaskSpheres from "../tasks/TaskSpheres"

  import { createNamespacedHelpers } from "vuex"
  const { mapActions: stagesActions, mapGetters: stagesGetters } = createNamespacedHelpers('stages')

  export default {
    name: 'StageDeleteModal',

    components: { TaskSpheres, UiButton },

    props: {
      stage: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        stageId: null
      }
    },

    computed: {
      ...stagesGetters(['movingStages']),

      movingStagesList () {
        return [...this.movingStages, { id: 0, name: this.$t('stages.completed') } ]
      }
    },

    methods: {
      ...stagesActions(['deleteStage', 'setDeletingStage']),

      onDelete () {
        this.deleteStage({
          id: this.stage.id,
          sphereId: this.stage.sphere ? this.stage.sphere.id : null,
          newStageId: this.stageId
        }).then(() => {
          this.$notify({
            title: this.$t('common.saved'),
            message: this.$t('stages.stageDeleted'),
            position: 'bottom-right',
            type: 'success'
          })
          this.$emit('close')
        })
      },

      onClose () {
        this.$emit('close')
      },

      onStageChange (stage) {
        this.stageId = stage.id
      }
    },

    created () {
      this.setDeletingStage(this.stage)
      this.stageId = this.movingStagesList[0].id
    }
  }
</script>

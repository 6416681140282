<template>
  <div class="notification-item">
    <div class="notification-item__photo">
      <div class="notification-item__new" v-if="notification.unseen"></div>
      <user-photo :user="member" size="xmd" :show-online="false"/>
      <div class="notification-item__badge" :class="'notification-item__badge_' + eventType.icon" v-if="eventType.icon">
        <component :is="eventType.icon" class="notification-item__badge-icon" :class="'notification-item__badge-icon_' + eventType.icon"></component>
      </div>
    </div>

    <div class="notification-item__content">
      <div class="notification-item__descr">
        <div class="notification-item__text">
          <span class="notification-item__member" v-if="notification.event !== 'tasks.overdue'">{{ memberName }}</span> {{ description }}
        </div>

        <div class="notification-item__task">
          <router-link :to="taskLink" class="notification-item__task-link">
            <span class="notification-item__task-number">{{ taskNumber }}</span>
            {{ notification.task.name }}
          </router-link>
        </div>
      </div>

      <div class="notification-item__right">
        <div class="notification-item__date">
          {{ formattedDate }}
        </div>
        <div class="notification-item__options">
          <el-tooltip
              popper-class="sidebar-menu__tooltip"
              effect="dark"
              content="Пометить, как просмотренное"
              placement="top"
              transition="sw-slide-top"
              :hide-after="0"
              :open-delay="200"
          >
            <button class="notification-item__action" @click="onMarkRead" v-if="notification.unseen">
              <eye-icon/>
            </button>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from "vuex"

import { getPastDate } from "@/services/scoring"
import CommentsIcon from "@/components/icons/CommentsIcon"
import EyeIcon from "@/components/icons/EyeIcon"
import ClockIcon from "@/components/icons/ClockIcon"
import Flag from "@/components/icons/Flag"
import FireIcon from "@/components/icons/FireIcon"
import UserPhoto from "@/components/ui/UserPhoto"

const { mapActions: notificationsActions } = createNamespacedHelpers('notifications')

export default {
  name: 'NotificationItem',

  components: { ClockIcon, CommentsIcon, EyeIcon, Flag, FireIcon, UserPhoto },

  props: {
    notification: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['members', 'workspace']),

    member () {
      return this.members.find(item => item.member_id === this.notification.member_id)
    },

    memberName () {
      return `${this.member.first_name} ${this.member.last_name}`
    },

    taskNumber () {
      return this.workspace.task_prefix
        ? `#${this.workspace.task_prefix}-${this.notification.task.number}`
        : `#${this.notification.task.number}`
    },

    description () {
      switch (this.notification.event) {
        case 'tasks.assigned':
          return this.$t('notifications.assignedATaskToYou')

        case 'tasks.score_changed':
          return this.$t('notifications.changedATaskScore')

        case 'tasks.taken':
          return this.$t('notifications.takenTheTask')

        case 'tasks.stage.updated':
          return this.$t('notifications.movedTaskToStage', { stage: this.notification.task.stage.name })

        case 'tasks.finished':
          return this.$t('notifications.finishedTheTask')

        case 'taskComments.created':
          return this.$t('notifications.addedCommentToTask')

        case 'tasks.overdue':
          return this.$t('notifications.taskOverdue')
      }

      return ''
    },

    eventType () {
      switch (this.notification.event) {
        case 'tasks.assigned':
          return {
            icon: 'flag'
          }

        case 'tasks.score_changed':
          return {
            icon: 'clock-icon'
          }

        case 'tasks.taken':
          return {
            icon: 'flag'
          }

        case 'tasks.overdue':
          return {
            icon: 'fire-icon'
          }

        case 'tasks.stage.updated':
          return {
            icon: 'flag'
          }

        case 'tasks.finished':
          return {
            icon: 'flag'
          }

        case 'taskComments.created':
          return {
            icon: 'comments-icon'
          }
      }

      return {}
    },

    formattedDate () {
      const { label } = getPastDate(this.notification.dateObj)

      return label
    },

    taskLink () {
      return {
        name: 'tasks.detail',
        params: {
          stageId: this.notification.task.stage.id,
          taskId: this.notification.task.id
        }
      }
    }
  },

  methods: {
    ...notificationsActions(['markAsRead']),

    onMarkRead () {
      this.markAsRead({ id: this.notification.id })
    }
  }
}
</script>

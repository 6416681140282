<template>
  <div class="notification-modal">
    <div class="notifications-block">
      <div class="notifications-head">
        <div class="notifications-head__title">
          <button class="notifications-head__close" @click="onClose">
            <chevron-left/>
          </button>

          {{ $t('layout.notifications') }}
        </div>

        <div class="notifications-head__options">
          <a v-if="unseenNotifications > 0" class="notifications-head__action" @click="onClickSeen">{{ $t('notifications.markAsRead') }}</a>
        </div>
      </div>

      <div class="notifications-list">
        <notification-item
          v-for="notification in notifications"
          :key="notification.id"
          :notification="notification"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from "vuex"

import ChevronLeft from "@/components/icons/ChevronLeft"
import NotificationItem from "@/components/notifications/NotificationItem"

const { mapActions: notificationsActions, mapGetters: notificationsGetters } = createNamespacedHelpers('notifications')

export default {
  name: 'NotificationModal',

  components: { ChevronLeft, NotificationItem },

  computed: {
    ...notificationsGetters(['notifications']),
    ...mapGetters(['unseenNotifications'])
  },

  methods: {
    ...notificationsActions(['fetchNotifications', 'markAllAsSeen']),

    onClose () {
      this.$emit('close')
    },

    onClickSeen () {
      this.markAllAsSeen()
    }
  },

  created () {
    this.fetchNotifications()
  }
}
</script>

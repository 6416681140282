export const TASK_CAN_CREATE                = 1 << 0
export const TASK_CAN_SORT_FOREIGN          = 1 << 1
export const TASK_CAN_UPDATE_FOREIGN        = 1 << 2
export const TASK_CAN_CHANGE_SCORE_FOREIGN  = 1 << 4
export const WORKSPACE_CAN_UPDATE           = 1 << 5
export const STAGE_CAN_UPDATE               = 1 << 6
export const TAG_CAN_UPDATE                 = 1 << 7
export const TAG_GROUP_CAN_UPDATE           = 1 << 8
export const TARGET_CAN_UPDATE              = 1 << 9
export const ROLE_CAN_UPDATE                = 1 << 10
export const MEMBER_CAN_UPDATE              = 1 << 11

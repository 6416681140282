<template>
  <node-view-wrapper class="code-block">
    <ui-dropdown
      :options="languages"
      :value="selectedLanguage"
      key-field=""
      :width-by-popover="false"
      @input="onChange"
      class="code-lang-selector"
      reference-class="code-lang-selector__label"
      popover-class="ui-dropdown-ex"
    >
      <template v-slot:default>
        <div>
          {{ selectedLanguage || 'auto' }}
          <chevron-down background="#2F3750" class="task-spheres__chevron-icon"/>
        </div>
      </template>

      <template v-slot:option="{ option }">
        <div>
          {{ option || 'auto' }}
        </div>
      </template>
    </ui-dropdown>

    <pre><code><node-view-content /></code></pre>
  </node-view-wrapper>
</template>

<script>
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2'
import ChevronDown from '@/components/icons/ChevronDown'
import UiDropdown from '@/components/tasks/UiDropdown'

export default {
  name: 'CodeBlock',

  components: {
    NodeViewWrapper,
    NodeViewContent,
    ChevronDown,
    UiDropdown,
  },

  props: nodeViewProps,

  data() {
    return {
      languages: [null, ...this.extension.options.lowlight.listLanguages()],
      selectedLanguage: this.node.attrs.language,
    }
  },

  methods: {
    onChange (language) {
      this.selectedLanguage = language
      this.updateAttributes({ language })
    }
  }
}
</script>

<style lang="sass">
.code-block
  position: relative

  .code-lang-selector
    position: absolute
    right: 8px
    top: 8px

    &__label
      background: var(--block-bg-color)
      padding: 4px 8px

    &__label:hover, .ui-dropdown__reference_opened
      padding: 4px 8px
      background: var(--grey-light-color-40)

.dark
  .code-block

    .code-lang-selector
      &__label:hover, .ui-dropdown__reference_opened
        background: var(--bg-color)

</style>
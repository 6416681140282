<template>
  <el-popover
    placement="bottom"
    trigger="click"
    popper-class="sw-popover sw-popover_no-border"
    :visible-arrow="false"
    @show="onOpen"
    @after-leave="onClose"
    :close-delay="200"
    ref="popper"
    v-if="isActive"
  >
    <div class="score-dropdown">
      <div class="score-dropdown__suggest score-suggest" :class="{'score-dropdown__suggest_empty': isEmptyCriteria || !avgTeamSpentTime }">
        <template v-if="isEmptyCriteria">
          <div class="score-suggest__title">
            {{ $t('taskStats.prediction.scoreRecommendation') }}
          </div>

          <div class="score-suggest__no-data">
            {{ $t('taskStats.prediction.emptyCriteriaTitle') }}
            <ul class="score-suggest__field-list">
              <li>{{ $t('taskStats.prediction.memberField') }}</li>
              <li>{{ $t('taskStats.prediction.sphereField') }}</li>
              <li>{{ $t('taskStats.prediction.pointsField') }}</li>
            </ul>
          </div>
        </template>
        <template v-else>
          <template v-if="taskPrediction.member_deviation && (suggestedScore || isSuggestedScoreProcessing)">
            <div class="score-suggest__title">
              {{ $t('taskStats.suggestedScore') }}
            </div>

            <div class="score-suggest__score">
              <ui-spinner v-if="isSuggestedScoreProcessing"/>
              <template v-else>
                {{ suggestedScore ? formatScore(suggestedScore) : '' }}

                <span class="score-suggest__diff">
                {{ deviationPercent }}%
              </span>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="score-suggest__title">
              {{ $t('taskStats.prediction.averageSpentTime') }}
            </div>

            <ui-spinner v-if="isPredictionFetching"/>

            <template v-else>
              <div class="score-suggest__avg-list" v-if="avgTeamSpentTime">
                <div class="score-suggest__avg-item">
                  <div class="score-suggest__avg-item-value">
                    {{ avgTeamSpentTime }}
                  </div>

                  <div class="score-suggest__avg-item-label">
                    {{ $t('taskStats.prediction.spentTimeByTeam') }}
                  </div>
                </div>

                <div class="score-suggest__avg-item" v-if="avgMemberSpentTime">
                  <div class="score-suggest__avg-item-value">
                    {{ avgMemberSpentTime }}
                  </div>

                  <div class="score-suggest__avg-item-label">
                    {{ $t('taskStats.prediction.spentTimeByMember') }}
                  </div>
                </div>
              </div>
              <div class="score-suggest__no-data" v-else>
                {{ $t('taskStats.prediction.notEnoughData') }}
              </div>
            </template>
          </template>

          <div class="score-suggest__member score-suggest__row">
            <template v-if="member && member.member_id">
              <user-photo :user="member" size="sm"/>
              <div class="score-suggest__member-name">
                {{ member.first_name }} {{ member.last_name }}
              </div>
            </template>
            <div class="score-suggest__member-name score-suggest__member-name_empty" v-else>
              {{ $t('taskStats.memberNotSelected') }}
            </div>
          </div>

          <div class="score-suggest__params score-suggest__row">
            <div class="score-suggest__points">
              <points-icon class="score-suggest__points-icon"/>
              {{
                points
                    ? $tc('tasks.pointsLabel', points, { points })
                    : $t('tasks.unknown')
              }}
            </div>

            <sphere-badge
                v-if="sphere && sphere.id"
                class="score-suggest__sphere"
                :sphere="sphere"
            />
          </div>

          <div class="score-suggest__stats-link" v-if="taskPrediction.member_deviation && (suggestedScore || isSuggestedScoreProcessing)">
            <task-stats-dropdown
              :avg-member-spent-time="avgMemberSpentTime"
              :avg-team-spent-time="avgTeamSpentTime"
            />
          </div>
        </template>
      </div>
      <div class="score-dropdown__form">
        <score-selector
          :value="value"
          @change="onScoreChanged"
          @input="onChange"
          v-if="isOpen"
        />
      </div>
    </div>
    <div class="common-dropdown" :class="commonDropdownClasses" slot="reference" ref="reference">
      <clock-icon class="common-dropdown__icon"/>
      <div class="common-dropdown__title">
        {{ formattedValue }}
      </div>
    </div>
  </el-popover>
  <score-item
    v-else
    :value="value"
    class="task-score_inactive"
  />
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

import ClockIcon from "@/components/icons/ClockIcon"
import PointsIcon from '@/components/icons/PointsIcon'
import ScoreItem from "@/components/tasks/components/ScoreItem"
import ScoreSelector from "@/components/tasks/components/ScoreSelector"
import SphereBadge from '@/components/ui/SphereBadge'
import TaskStatsDropdown from '@/components/stats/TaskStatsDropdown'
import UserPhoto from '@/components/ui/UserPhoto'
import UiSpinner from '@/components/ui/UiSpinner'
import { getScoreObject, getScoreLabel, MaxScoreValue, MinScoreValue } from '@/services/scoring'
import debounce from '@/utils/debounce';

const { mapActions: taskStatsActions, mapGetters: taskStatsGetters } = createNamespacedHelpers('taskStats')

export default {
  name: 'ScoreDropdown',

  components: { ClockIcon, PointsIcon, ScoreItem, ScoreSelector, SphereBadge, TaskStatsDropdown, UserPhoto, UiSpinner },

  props: {
    value: {
      required: true,
    },

    referenceClass: {
      type: String,
      default: '',
    },

    isActive: {
      type: Boolean,
      required: true,
    },

    member: {
      required: true,
    },

    points: {
      required: true,
    },

    sphere: {
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    }
  },

  data () {
    return {
      isOpen: false,
      width: 100,
      isPredictionReady: false,
      isPredictionFetching: false,
      memberScore: 0,
      suggestedScore: 0,
      isSuggestedScoreProcessing: false,
      changeMemberScore: debounce((score) => {
        this.isSuggestedScoreProcessing = false
        this.memberScore = score

        if (score >= this.suggestedScore * 0.9 && score <= this.suggestedScore * 1.1) {
          return
        }

        this.calcSuggestedScore()
      }, 1000)
    }
  },

  computed: {
    ...taskStatsGetters(['taskPrediction']),

    referenceClasses () {
      const classes = ['ui-dropdown__reference']

      if (this.isOpen) {
        classes.push(' ui-dropdown__reference_opened')
      }

      if (this.referenceClass) {
        classes.push(this.referenceClass)
      }

      return classes
    },

    deviationPercent () {
      if (!this.suggestedScore) {
        return 0
      }

      const percent = Math.round(this.suggestedScore / this.memberScore * 100) - 100
      return percent > 0
        ? `+${percent}`
        : percent
    },

    isEmptyMember () {
      return !this.member || !this.member.member_id
    },

    isEmptyCriteria () {
      return this.isEmptyMember && (!this.sphere || !this.sphere.id) && !this.points
    },

    avgTeamSpentTime () {
      if (this.isPredictionFetching || !this.taskPrediction.avg_team_spent_time) {
        return ''
      }

      return this.formatScore(Math.round(this.taskPrediction.avg_team_spent_time))
    },

    avgMemberSpentTime () {
      if (this.isPredictionFetching || !this.taskPrediction.avg_member_spent_time) {
        return ''
      }

      return this.formatScore(Math.round(this.taskPrediction.avg_member_spent_time))
    },

    formattedValue() {
      if (!this.value) {
        return this.$t('tasks.noScore')
      }

      return getScoreLabel(this.parsedValue)
    },

    parsedValue() {
      return getScoreObject(this.value)
    },

    commonDropdownClasses () {
      const classes = []

      if (this.disabled) {
        classes.push('common-dropdown_disabled')
      }

      return classes
    }
  },

  watch: {
    member () {
      this.isPredictionReady = false
    },

    sphere () {
      this.isPredictionReady = false
    },

    points () {
      this.isPredictionReady = false
    },
  },

  methods: {
    ...taskStatsActions(['fetchTaskPrediction']),

    onScoreChanged (score) {
      this.isSuggestedScoreProcessing = true
      this.changeMemberScore(score)
    },

    onChange (value) {
      this.$emit('input', value)
      this.$refs.popper.doClose()
    },

    onOpen () {
      this.width = this.$refs.reference.offsetWidth
      this.isOpen = true
      this.memberScore = 0
      this.suggestedScore = 0

      if (!this.isPredictionReady && !this.isPredictionFetching) {
        if (this.isEmptyCriteria) {
          this.isPredictionReady = true
          return
        }

        this.isPredictionFetching = true

        this.fetchTaskPrediction({
          memberId: this.member ? this.member.member_id : null,
          sphereId: this.sphere ? this.sphere.id : null,
          points: this.points,
        }).finally(() => {
          this.isPredictionReady = true
          this.isPredictionFetching = false
        })
      }
    },

    onClose () {
      this.isOpen = false
    },

    formatScore (value) {
      if (!value) {
        return 'empty'
      }

      return getScoreLabel(getScoreObject(value))
    },

    calcSuggestedScore () {
      if (!this.taskPrediction.member_deviation || !this.memberScore) {
        this.suggestedScore = 0
        return
      }

      let resScore = this.taskPrediction.member_deviation * this.memberScore
      const periods = Math.ceil(resScore / 30)
      resScore = periods * 30

      if (resScore > MaxScoreValue) {
        this.suggestedScore = MaxScoreValue
        return
      }

      if (resScore < MinScoreValue) {
        this.suggestedScore = MinScoreValue
        return
      }

      this.suggestedScore = resScore
    },
  }
}
</script>
<style lang="sass">
.ui-dropdown.score-selector
  padding: 0

.score-dropdown
  display: flex

  &__suggest
    box-sizing: border-box
    padding: 18px
    background-image: url("../../../assets/images/score_bg.png")
    background-size: cover
    background-position: center
    border-radius: 8px 0 0 8px
    min-width: 240px

    &_empty
      min-width: auto
      max-width: 240px

  &__form
    padding: 18px

.score-suggest
  &__title
    font-weight: 500
    font-size: 13px
    color: var(--text-primary-color)

  &__no-data
    color: var(--text-light-color)
    font-size: 13px
    margin-top: 8px
    word-break: break-word
    text-align: left

  &__field-list
    padding: 0 0 0 1rem
    color: var(--text-primary-color)
    margin: 8px 0 0 0

  &__avg
    &-list
      display: flex
      margin: 12px 0 24px

      .score-suggest__avg-item:last-child
        margin-right: 0

    &-item
      margin-right: 20px

      &-value
        font-size: 14px
        font-weight: 500
        color: var(--text-primary-color)

      &-label
        font-size: 12px
        color: var(--text-light-color)
        margin-top: 2px

  &__score
    align-items: center
    display: flex
    font-size: 20px
    font-weight: 500
    color: var(--text-primary-color)
    margin-top: 8px

  &__diff
    color: var(--text-light-color)
    font-size: 18px
    margin-left: 18px

  &__row
    margin-top: 12px

  &__member
    cursor: default
    align-items: center
    display: flex

    &-name
      color: var(--text-primary-color)
      font-size: 12px
      margin-left: 10px

      &_empty
        margin: 0

  &__params
    cursor: default
    align-items: center
    display: flex

  &__points
    align-items: center
    display: flex
    font-size: 12px
    color: var(--text-primary-color)

    &-icon
      margin-right: 6px

  &__sphere
    margin-left: 12px

  &__stats-link
    display: flex
    margin-top: 16px
</style>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" fill-rule="evenodd" clip-rule="evenodd" d="M17.9236 8.48218C17.8197 9.1433 17.6124 9.77732 17.3158 10.3726C17.4905 10.7876 17.5835 11.2241 17.5835 11.6667C17.5835 12.3107 17.3968 12.9261 17.0526 13.4837C16.9031 13.7259 16.8587 14.0087 16.8404 14.2064C16.8198 14.4282 16.8225 14.6712 16.8359 14.907C16.8558 15.26 16.9023 15.6401 16.954 15.9848C16.5698 15.8705 16.1361 15.7516 15.7315 15.6638C15.4694 15.6069 15.2038 15.5596 14.9635 15.5373C14.7499 15.5174 14.4559 15.5058 14.193 15.5907C13.5455 15.8001 12.8334 15.9167 12.0835 15.9167C10.7547 15.9167 9.58174 15.5637 8.66403 14.9935C7.74722 14.9655 6.86544 14.8231 6.03857 14.5831C7.28165 16.3164 9.59136 17.4167 12.0835 17.4167C12.9815 17.4167 13.8429 17.2782 14.6359 17.024C14.636 17.0243 14.6386 17.0242 14.6439 17.0239C14.6634 17.0232 14.7191 17.021 14.8246 17.0308C14.9808 17.0453 15.1812 17.0793 15.4134 17.1297C15.876 17.23 16.4027 17.3819 16.8381 17.5175C17.7547 17.8031 18.6438 17.0042 18.4871 16.0736C18.4198 15.6745 18.3557 15.2149 18.3335 14.8223C18.3223 14.625 18.323 14.4638 18.334 14.345C18.3394 14.2866 18.3461 14.253 18.3495 14.2381C18.8156 13.4705 19.0835 12.5967 19.0835 11.6667C19.0835 10.4635 18.651 9.37774 17.9236 8.48218Z" fill="#5B6372"/>
    <path class="path-stroke" d="M16.2498 7.91667C16.2498 10.9082 13.0785 13.3333 9.1665 13.3333C8.12674 13.3333 7.13931 13.162 6.24983 12.8543C5.70777 12.6667 3.91484 13.2856 2.73871 13.7365C2.32978 13.8932 1.90526 13.4907 2.03991 13.074C2.39409 11.9778 2.84238 10.3967 2.62604 10C2.2763 9.35867 2.08317 8.65492 2.08317 7.91667C2.08317 4.92512 5.25448 2.5 9.1665 2.5C13.0785 2.5 16.2498 4.92512 16.2498 7.91667Z" stroke="#5B6372" stroke-width="1.5"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'CommentsIcon',

    mixins: [iconMixin],

    props: {
      viewBox: {
        type: String,
        default: '0 0 12 12'
      }
    },

    data () {
      return {
        defaultBackground: '#416AF7',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

<template>
  <el-dialog
      custom-class="plan-upgrade"
      :visible="true"
      :show-close="false"
      width="400px"
  >
    <div class="plan-upgrade">
      <plan-upgrade class="plan-upgrade__pic"/>

      <div class="plan-upgrade__title">
        {{ $t('settings.rate.upgrade.title') }}
      </div>
      <div class="plan-upgrade__description" v-html="description"></div>
      <div class="plan-upgrade__actions">
        <a class="ui-button plan-upgrade__btn" :href="`${baseDomain}/account/workspaces/${workspace.global_id}/plan`" target="_blank">
          {{ $t('settings.rate.upgrade.submit') }}
        </a>
        <ui-button ui-class="block plain" class="plan-upgrade__skip" @click="onClose">
          {{ $t('settings.rate.upgrade.cancel') }}
        </ui-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import UiButton from 'scorework-ui/src/components/UiButton'
import {mapActions, mapGetters} from "vuex";
import PlanUpgrade from "@/components/illustrations/PlanUpgrade";

export default {
  name: 'UpgradePlanModal',

  components: {PlanUpgrade, UiButton },

  computed: {
    ...mapGetters(['workspace', 'upgradePlanModal']),

    baseDomain () {
      const parts = window.location.hostname.split('.')
      return location.protocol + '//' +parts.slice(1).join('.')
    },

    description () {
      return this.$t('settings.rate.upgrade.description', {
        feature: this.$t(`settings.rate.upgrade.features.${this.upgradePlanModal.feature}`),
        plan: this.$t(`settings.rate.ratePlans.${this.workspace.rate_plan_code}`)
      })
    }
  },

  methods: {
    ...mapActions(['setUpgradePlanModal']),

    onConfirm () {
      this.$emit('start')
    },

    onClose () {
      this.setUpgradePlanModal({
        show: false,
        feature: '',
      })
    }
  }
}
</script>
<style lang="sass">
.el-dialog.plan-upgrade
  .el-dialog__header
    padding: 0

  .el-dialog__body
    padding: 32px

.plan-upgrade
  display: flex
  flex-flow: column
  align-items: center

  &__pic
    width: 220px
    height: 187px

  &__title
    font-size: 20px
    color: var(--text-primary-color)
    font-weight: 500
    margin: 24px 0px
    text-align: center

  &__description
    font-size: 16px
    line-height: 24px
    color: var(--text-light-color)
    text-align: center
    width: 320px

    p
      margin: 0
      padding: 0 0 12px

  &__actions
    padding-top: 12px
    width: 200px

    .ui-button
      height: 44px
      font-size: 16px

  &__btn
    text-decoration: none
    width: 100%
    justify-content: center
    font-weight: 400

  &__skip
    margin-top: 8px
</style>

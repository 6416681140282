const dashboardSocket = (store, router, onConnect) => {
  let wasDisconnected = false

  const listeners = {
    'connect': () => {
      if (wasDisconnected) {
        onConnect()
      }
    },

    'disconnect': () => {
      wasDisconnected = true
    },

    'tasks.recalculated': ({ tasks, status = null }) => {
      if (status) {
        return
      }

      const authMemberId = store.getters.authUser.member_id
      const myTasks = tasks.filter(item => item.state.assignee_id === authMemberId)

      if (myTasks.length === 0) {
        return
      }

      store.dispatch('dashboard/refreshMyTasks', { tasks: myTasks })
    },

    'tasks.finished': ({ task_id }) => {
      store.dispatch('dashboard/removeFinishedTask', task_id)
    },

    'member_feed.created': ({ feed }) => {
      store.dispatch('dashboard/prependFeedItem', { feed })
    }
  }

  return {
    subscribe (socket) {
      Object.keys(listeners).forEach(eventName => {
        socket.on(eventName, listeners[eventName])
      })
    },

    unsubscribe (socket) {
      Object.keys(listeners).forEach(eventName => {
        socket.off(eventName, listeners[eventName])
      })
    }
  }
}

export default dashboardSocket

import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueApexCharts from 'vue-apexcharts'
import VueWaypoint from 'vue-waypoint'

import {
  Drawer,
  Popover,
  TimePicker,
  Tooltip,
  Dialog,
  Switch,
  Notification,
  Pagination,
  Checkbox,
  Skeleton,
  SkeletonItem,
  DatePicker,
} from 'element-ui';
//import lang from 'element-ui/lib/locale/lang/en'
import langRu from 'element-ui/lib/locale/lang/ru-RU'
import locale from 'element-ui/lib/locale'

import socketService from "@/services/socket"
import apiService from "@/services/api"
import languageService from "@/services/language"
import themeService from "@/services/theme"
import createRouter from './router'
import App from './App.vue'
import store from '@/store'

import workspaceSocket from "@/store/socket/workspace"

import 'scorework-ui/src/sass/styles.sass'
import './sass/styles.sass'
import 'emoji-mart-vue-fast/css/emoji-mart.css'

import PageMenuItem from "@/components/pages/PageMenuItem"

const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
const platform = window.navigator.platform
if (macosPlatforms.indexOf(platform) === -1) {
  require('./sass/modules/scollbar.sass')
}

const router = createRouter(store)
socketService.addSubscriber(workspaceSocket(store, router))

// set api service error handler
apiService.setResponseErrorHandler(error => {
  if (error.response && error.response.status === 401) {
    store.dispatch('logout')
    router.push({ name: 'login' })
  }

  if (error.response && error.response.status === 403) {
    vue.$notify({
      title: vue.$t('common.error'),
      message: vue.$t('common.notEnoughPermissions'),
      position: 'bottom-right',
      type: 'error'
    })
  }

  return Promise.reject(error);
})

// configure language
locale.use(langRu)

Vue.component(Drawer.name, Drawer)
Vue.component(Popover.name, Popover)
Vue.component(TimePicker.name, TimePicker)
Vue.component(Tooltip.name, Tooltip)
Vue.component(Dialog.name, Dialog)
Vue.component(Switch.name, Switch)
Vue.component(Pagination.name, Pagination)
Vue.component(Checkbox.name, Checkbox)
Vue.component(Skeleton.name, Skeleton)
Vue.component(SkeletonItem.name, SkeletonItem)
Vue.component(DatePicker.name, DatePicker)

Vue.component('page-menu-item', PageMenuItem)
Vue.component('parent-selector-item', ParentSelectorItem)
Vue.prototype.$notify = Notification

// Charts
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

import './directives/drag'
import ParentSelectorItem from '@/components/pages/ParentSelectorItem';

// Подключаем валидатор
Vue.use(Vuelidate)

Vue.config.productionTip = false

themeService.applyTheme()

Vue.use(VueWaypoint)

const vue = new Vue({
  render: h => h(App),
  i18n: languageService.i18n,
  router,
  store
}).$mount('#app')

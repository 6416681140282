<template>
  <div></div>
</template>
<script>

  import {createNamespacedHelpers} from "vuex";

  const { mapActions: taskActions } = createNamespacedHelpers('task')

  export default {
    name: 'FileDownload',

    props: {
      uuid: {
        type: String,
        required: true
      }
    },

    methods: {
      ...taskActions(['downloadFile'])
    },

    created () {
      this.downloadFile({ uuid: this.uuid })
    }
  }
</script>
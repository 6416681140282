export default {
  methods: {
    memberFullName (member) {
      let fullName = member.first_name

      if (member.last_name) {
        fullName += ' ' + member.last_name
      }

      return fullName
    }
  }
}
const tasksSocket = (store, router, onConnect) => {
  let wasDisconnected = false

  const listeners = {
    'connect': () => {
      if (wasDisconnected) {
        onConnect()
      }
    },

    'disconnect': () => {
      wasDisconnected = true
    },

    'tasks.status.order.updated': ({ status, tasks }) => {
      store.dispatch('tasks/updateStatusTasks', { status, tasks })
    },

    'tasks.updated': ({ task }) => {
      store.dispatch('tasks/refreshTask', { task })
      store.dispatch('task/refreshTask', { task })

      if (task.status === 'backlog') {
        const taskState = task.states.find(state => state.id === task.state_id)

        store.dispatch('tasks/refreshBacklogTask', {
          id:     task.id,
          name:   task.name,
          sphere: task.sphere,
          target: task.target,
          score:  task.score,
          points: task.points,
          state: {
            assignee: taskState.assignee
          }
        })
      }
    },

    'taskComments.created': ({ task_comment }) => {
      store.dispatch('tasks/incrementCommentsCount', { taskId: task_comment.task_id })

      const task = store.getters['task/task']
      if (task.id !== task_comment.task_id) {
        return
      }

      store.dispatch('task/prependComment', task_comment)
    },

    'member_feed.created': ({ feed }) => {
      if (!feed.task) {
        return
      }

      const task = store.getters['task/task']
      if (task.id !== feed.task.id) {
        return
      }

      store.dispatch('task/prependTimeline', feed)
    },

    'tasks.recalculated': ({ tasks, status = null, partial = false }) => {
      if (!status) {
        store.dispatch('tasks/updateStageTasks', { tasks, full: false })
      } else {
        store.dispatch('tasks/updateStatusTasks', { tasks, status, partial })
      }
    },

    'tasks.removed': ({ task_id, stage_id = null, status = null }) => {
      store.dispatch('tasks/removeTask', { taskId: task_id, stageId: stage_id, status })
    }
  }

  return {
    subscribe (socket) {
      Object.keys(listeners).forEach(eventName => {
        socket.on(eventName, listeners[eventName])
      })
    },

    unsubscribe (socket) {
      Object.keys(listeners).forEach(eventName => {
        socket.off(eventName, listeners[eventName])
      })
    }
  }
}

export default tasksSocket

<template>
  <div class="settings-page">
    <div class="settings-title">
      {{ $t('settings.general.title') }}
    </div>

    <general-settings :workspace="workspace" v-if="isLoaded" @submit="onSave"/>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import GeneralSettings from "@/components/settings/GeneralSettings"
import pageTitleMixin from "@/views/mixins/pageTitleMixin"
import authUserMixin from "@/mixins/authUserMixin"

export default {
  name: 'WorkspaceSettings',

  components: { GeneralSettings },

  mixins: [pageTitleMixin, authUserMixin],

  data () {
    return {
      activeTab: null,
      isLoaded: false
    }
  },

  computed: {
    ...mapGetters(['workspace', 'project']),

    pageTitle () {
      return {
        message: 'pageTitles.settings.workspace',
        params: { workspace: this.workspace.name }
      }
    }
  },

  methods: {
    ...mapActions(['fetchWorkspace']),

    onSave () {
      this.$notify({
        title: this.$t('common.saved'),
        message: this.$t('workspaces.workspaceSettingsSaved'),
        position: 'bottom-right',
        type: 'success'
      })
    }
  },

  created () {
    if (!this.isAdmin) {
      this.$router.push({ name: 'workspace.member.settings' , params: { project: this.project.code} })
    }

    this.fetchWorkspace()
      .finally(() => {
        this.isLoaded = true
      })
  }
}
</script>

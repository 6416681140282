<template>
<div class="sprint-graph">
  <div class="sprint-graph__title">
    {{ $t('sprints.detail.progressTitle') }}
  </div>

  <div class="sprint-graph__members-title">
    {{ $t('sprints.displayingMembers') }}:
  </div>

  <div class="sprint-graph__members-list">
    <user-photo
      v-for="sprintMember in sprint.members"
      :key="sprintMember.member.member_id"
      :user="sprintMember.member"
      class="sprint-stage__member-filter-item"
      :class="{ 'sprint-stage__member-filter-item_active': isActiveMember(sprintMember.member.member_id) }"
      @click="onClickMember(sprintMember.member.member_id)"
      size="sm"
    />
  </div>

  <sprint-task-popover :task="displayedTask" :show="showPopover" ref="popover" v-if="showPopover"/>
  <div ref="firstPopover" v-if="showFirstPopover" class="chart-first-popover">
    Начало спринта
  </div>
  <apexchart type="line" :options="graphOptions" :series="series" class="sprint-chart"/>
</div>
</template>
<script>
import UserPhoto from "@/components/ui/UserPhoto"
import SprintTaskPopover from "@/components/sprints/SprintTaskPopover"
import languageService from "@/services/language"
import {createPopper} from "@popperjs/core"
import {sortByField} from "@/utils/arrays";

export default {
  name: 'SprintGraph',

  components: { SprintTaskPopover, UserPhoto },

  props: {
    sprint: {
      type: Object,
      required: true
    }
  },

  data () {
    const memberIds = this.sprint.members.map(item => item.member.member_id)

    return {
      filter: {
        memberIds
      },

      displayedTask: {},
      showPopover: false,
      taskPopover: null,

      showFirstPopover: false
    }
  },

  computed: {
    graphOptions () {
      const startedAt  = new Date(this.sprint.data.started_at).getTime()
      const finishedAt = new Date(this.sprint.data.finished_at).getTime()
      const now        = new Date().getTime()

      console.log("total task", this.totalTasks)

      const yAxis = this.totalTasks <= 10
          ? {
            tickAmount: 'dataPoints'
          } : {
            tickAmount: Math.round(this.totalTasks / 2),
            max: this.totalTasks
          }

      const annotations = [
        {
          x: finishedAt,
          borderColor: '#999FAE',
          strokeDashArray: 4,
          label: {
            borderWidth: 0,
            orientation: 'vertical',
            text: this.$t('sprints.graph.endOfSprint'),
            style: {
              background: 'transparent',
              cssClass: 'chart-sprint-end-line'
            }
          }
        }
      ]

      if (now >= startedAt && now <= finishedAt) {
        annotations.push({
          x: now,
          borderColor: '#00E396',
          strokeDashArray: 0,
          label: {
            borderColor: '#25C685',
            orientation: 'horizontal',
            text: this.$t('sprints.graph.today'),
            style: {
              color: "#fff",
              background: "#25C685",
              fontSize: '14px'
            }
          }
        })
      }

      return {
        width: '100%',
        chart: {
          locales: [
            {
              "name": "en",
              "options": {
                "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
              }
            },
            {
              "name": "ru",
              "options": {
                "months": ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
                "shortMonths": ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
                "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
              }
            }
          ],
          defaultLocale: languageService.getLanguage(),
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          events: {
            dataPointMouseEnter: (event, chartContext, config) => {
              const { seriesIndex, dataPointIndex } = config

              if (dataPointIndex === 0) {
                this.showFirstPopover = true

                this.$nextTick(() => {
                  this.taskPopover = createPopper(event.target, this.$refs.firstPopover, {
                    placement: 'top'
                  })
                })

                return
              }

              switch (seriesIndex) {
                case 0:
                  this.displayedTask = this.plannedTasks[dataPointIndex - 1]
                  break

                case 1:
                  this.displayedTask = this.remainTasks[dataPointIndex - 1]
                  break

                case 2:
                  this.displayedTask = this.finishedTasks[dataPointIndex - 1]
                  break
              }

              this.showPopover = true

              this.$nextTick(() => {
                this.taskPopover = createPopper(event.target, this.$refs.popover.$el, {
                  placement: 'top'
                })
              })
            },

            dataPointMouseLeave: (e) => {
              if (this.showPopover === false && this.showFirstPopover === false) {
                return
              }

              const el = this.showPopover
                  ? this.$refs.popover.$el
                  : this.$refs.firstPopover

              const isOnPopover = el.contains(e.toElement)

              el.addEventListener('mouseleave', this.closePopover)
              if (!isOnPopover) {
                this.closePopover()
              }
            }
          }
        },
        colors: ['#999FAE', 'rgba(99, 143, 255, 0.48)', '#638FFF'],
        xaxis: {
          type: "datetime",
          min: startedAt,
          max: finishedAt + 86400000 // +1 day
        },
        yaxis: {
          ...yAxis,
          labels: {
            formatter: function (val) {
              return Math.round(val)
            }
          }
        },
        markers: {
          size: 4,
          colors: ['#999FAE', "#638FFF", "#638FFF"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 10,
          }
        },
        stroke: {
          width: [4, 4, 4],
          curve: 'straight',
          dashArray: [0, 10, 0],
          lineCap: 'round'
        },
        tooltip: {
          enabled: false,
          intersect: true,
          shared: false
        },
        annotations: {
          xaxis: annotations
        },
        legend: {
          horizontalAlign: 'left',
          markers: {
            width: '8px',
            height: '8px',
            strokeWidth: 2
          }
        }
      }
    },


    displayedTasks () {
      return this.sprint.tasks.filter(item => this.filter.memberIds.indexOf(item.state.assignee.member_id) !== -1)
    },

    totalTasks () {
      return this.displayedTasks.length
    },

    plannedTasks () {
      const tasks = [...this.displayedTasks]
      tasks.sort(sortByField('planned_date'))

      return tasks
    },

    finishedTasks () {
      const tasks = this.displayedTasks.filter(task => task.finished_at)
      tasks.sort(sortByField('finished_at'))

      return tasks
    },

    remainTasks () {
      const tasks = this.displayedTasks.filter(task => !task.finished_at)
      tasks.sort(sortByField('estimated_finish_at'))

      return tasks
    },

    plannedSeries () {
      let remainTasks = this.totalTasks

      const debug = []

      const data = this.plannedTasks.map(task => {
        remainTasks--

        debug.push({
          x: task.planned_date,
          y: remainTasks,
          taskName: task.name,
          taskId: task.id
        })
        return {
          x: task.planned_date,
          y: remainTasks
        }
      })

      data.unshift({
        x: this.sprint.data.started_at,
        y: this.totalTasks
      })
      console.log('planned series')
      console.log(debug)
      return { name: this.$t('sprints.planned'), data }
    },

    finishedSeries () {
      let remainTasks = this.totalTasks

      const debug = []
      const data = this.finishedTasks
          .map(task => {
            remainTasks--

            debug.push({
              x: task.finished_at,
              y: remainTasks,
              taskName: task.name,
              taskId: task.id
            })
            return {
              x: task.finished_at,
              y: remainTasks
            }
          })

      data.unshift({
        x: this.sprint.data.started_at,
        y: this.totalTasks
      })

      console.log('realSeries')
      console.log(debug)

      return { name: this.$t('sprints.fact'), data }
    },

    remainSeries () {
      let remainTasks = this.remainTasks.length

      const debug = []
      const data = this.remainTasks.reduce((res, task) => {
        remainTasks--

        debug.push({
          x: task.estimated_finish_at,
          y: remainTasks,
          taskName: task.name,
          taskId: task.id
        })
        res.push({
          x: task.estimated_finish_at,
          y: remainTasks
        })

        return res
      }, [])

      if (this.finishedTasks.length > 0) {
        data.unshift({
          x: this.finishedTasks[this.finishedTasks.length - 1].finished_at,
          y: this.remainTasks.length
        })
      } else {
        data.unshift({
          x: this.sprint.data.started_at,
          y: this.remainTasks.length
        })
      }

      console.log('remain series')
      console.log(debug)
      return { name: this.$t('sprints.remainingTasks'), data }
    },

    series () {
      return [
        this.plannedSeries, this.remainSeries, this.finishedSeries
      ]
    }
  },

  methods: {
    closePopover () {
      this.taskPopover.destroy()
      this.taskPopover = null
      this.showPopover = false
      this.showFirstPopover = false
    },

    isActiveMember (memberId) {
      return this.filter.memberIds.indexOf(memberId) !== -1
    },

    onClickMember (memberId) {
      const index = this.filter.memberIds.indexOf(memberId)

      if (index === -1) {
        this.filter.memberIds.push(memberId)
      } else {
        this.filter.memberIds.splice(index, 1)
      }
    }
  }
}
</script>
<style lang="sass">
.sprint-graph
  &__title
    font-size: 20px
    font-weight: 600
    margin-bottom: 16px

  &__members-title
    margin-bottom: 8px

  &__members-list
    align-items: center
    display: flex

.sprint-chart
  .apexcharts-legend
    padding-top: 20px

    &.apexcharts-align-left
      .apexcharts-legend-series
        display: flex

    .apexcharts-legend-series
      align-items: center
      background: var(--grey-light-color-24)
      border-radius: 8px
      display: flex
      padding: 8px

      .apexcharts-legend-marker
        margin-right: 6px

  .apexcharts-legend-text
    color: var(--text-primary-color) !important
</style>
export const nullOrNumber = (val) => {
  if (val === null) {
    return null
  }

  const numVal = Number(val)
  return isNaN(numVal) ? null : numVal
}

export const lcFirst = (val) => {
  return val[0].toLowerCase() + val.substring(1)
}

import Vue from 'vue'
import VueRouter from 'vue-router'

import authService from "./services/auth"

import MainLayout from '@/components/layouts/MainLayout'
import AuthLayout from "@/components/layouts/AuthLayout"
import MessengerLayout from "@/components/layouts/MessengerLayout"
import SettingsLayout from "@/components/layouts/SettingsLayout"

import Login from "@/views/Login"
import JoinWorkspace from "@/views/JoinWorkspace"

import SetupGeneral from "@/views/setup/SetupGeneral"
import SetupMembers from "@/views/setup/SetupMembers"
import SetupSpheres from "@/views/setup/SetupSpheres"

import OnBoardingSpheres from "@/views/onboarding/OnBoardingSpheres"

import Messenger from "@/views/messenger/Messenger"
import Channel from "@/views/messenger/Channel"

import NotificationSettings from "@/views/settings/NotificationSettings"
import RateSettings from "@/views/settings/RateSettings"
import SphereSettings from "@/views/settings/SphereSettings"
import StageSettings from "@/views/settings/StageSettings"
import TeamSettings from "@/views/settings/TeamSettings"
import UserSettings from "@/views/settings/UserSettings"
//import WorkspaceSettings from "@/views/settings/WorkspaceSettings"

import TourTasks from "@/views/tour/TourTasks"
import TaskListPage from '@/views/TaskListPage'
import Sprints from "@/views/Sprints"
import WorkLoad from '@/views/WorkLoad'
import GoalsProgress from '@/views/GoalsProgress'
import MemberDashboard from '@/views/dashboard/MemberDashboard.vue'

import TaskCard from "@/components/tasks/TaskCard"

import FileDownload from "./components/pages/FileDownload"
import ProjectSettings from "@/views/settings/ProjectSettings";
import ProjectMembers from "@/views/settings/ProjectMembers";

import CreateGeneral from "@/views/projects/CreateGeneral"
import WorkspaceSettings from "@/views/settings/WorkspaceSettings"
import ReportPage from "@/views/ReportPage"
import Pages from '@/views/pages/Pages'
import PageContent from '@/components/pages/PageContent';
import PageDashboard from '@/components/pages/PageDashboard';

Vue.use(VueRouter)

export default function createRouter () {
  const routes = [
    {
      path: '/', component: AuthLayout,
      children: [
        {
          path: '', component: Login, name: 'login', meta: { onlyForGuests: true }
        },

        {
          path: 'join/:code', component: JoinWorkspace, name: 'join', props: true
        }
      ]
    },

    {
      path: '/setup/general', component: SetupGeneral, meta: { requiresAuth: true }, name: 'setup.general'
    },

    {
      path: '/setup/:project/spheres', component: SetupSpheres, meta: { requiresAuth: true }, name: 'setup.spheres'
    },

    {
      path: '/setup/:project/members', component: SetupMembers, meta: { requiresAuth: true }, name: 'setup.members'
    },

    {
      path: '/', component: AuthLayout,
      children: [
        {
          path: '/onboarding/', component: OnBoardingSpheres, meta: { requiresAuth: true }, name: 'onboarding.spheres'
        }
      ]
    },

    {
      path: '/projects/create', component: CreateGeneral, meta: { requiresAuth: true }, name: 'projects.create'
    },

    {
      path: '/', component: MainLayout, meta: { requiresAuth: true },
      children: [
        {
          path: '/:project/dashboard', component: MemberDashboard, name: 'dashboard',
        },
        {
          path: '/:project/messenger', component: MessengerLayout, name: 'messenger',
          children: [
            {
              path: 'channels/:channelId', component: Channel, name: 'messenger.channel', props: true
            },
            {
              path: '', component: Messenger, name: 'messenger.index', props: true
            }
          ]
        },

        {
          path: '/:project/settings', component: SettingsLayout, name: 'settings',
          children: [
            {
              path: 'workspace', component: WorkspaceSettings, name: 'workspace.settings.general'
            },

            {
              path: 'workspace/members', component: TeamSettings, name: 'workspace.settings.members'
            },

            {
              path: 'workspace/rate', component: RateSettings, name: 'workspace.settings.rate'
            },

            {
              path: '', component: ProjectSettings, name: 'project.settings.general'
            },

            {
              path: 'spheres', component: SphereSettings, name: 'project.settings.spheres'
            },

            {
              path: 'stages', component: StageSettings, name: 'project.settings.stages'
            },

            {
              path: 'members', component: ProjectMembers, name: 'project.settings.members'
            },

            {
              path: 'profile', component: UserSettings, name: 'workspace.member.settings'
            },

            {
              path: 'notifications', component: NotificationSettings, name: 'project.settings.notifications',
            }
          ]
        },

        {
          path: 'tasks/stage/:stageId(\\d+)', component: TaskListPage, name: 'tasks.stage', props: route => {
            return {
              stageId: Number(route.params.stageId),
              assigneeIds: route.query.assignee_ids ? route.query.assignee_ids.split(',').map(v => Number(v)) : [],
              sphereId: Number(route.query.sphere_id) || 0,
              targetId: Number(route.query.target_id) || 0,
            }
          },
          children: [
            {
              path: 'create', component: TaskCard, name: 'tasks.create', props: (route) => ({
                taskId: 0,
                afterTaskId: Number(route.query.after_id) || 0,
                backlog: route.query.backlog || 0,
                memberId: route.query.member_id || 0
              })
            },
            {
              path: ':taskId(\\d+)', component: TaskCard, name: 'tasks.detail', props: route => {
                return {
                  taskId: Number(route.params.taskId)
                }
              },
            }
          ]
        },

        {
          path: '/:project/tasks', component: TaskListPage, name: 'project.tasks', props: route => {
            return {
              assigneeIds: route.query.assignee_ids ? route.query.assignee_ids.split(',').map(v => Number(v)) : [],
              sphereId: Number(route.query.sphere_id) || 0,
              targetId: Number(route.query.target_id) || 0,
            }
          }
        },

        {
          path: '/:project/tasks/stage/:stageId(\\d+)', component: TaskListPage, name: 'project.tasks.stage', props: route => {
            return {
              stageId: Number(route.params.stageId),
              assigneeIds: route.query.assignee_ids ? route.query.assignee_ids.split(',').map(v => Number(v)) : [],
              sphereId: Number(route.query.sphere_id) || 0,
              targetId: Number(route.query.target_id) || 0,
            }
          },
          children: [
            {
              path: 'create', component: TaskCard, name: 'project.tasks.create', props: (route) => ({
                taskId: 0,
                afterTaskId: Number(route.query.after_id) || 0,
                backlog: route.query.backlog || 0,
                memberId: route.query.member_id || 0
              })
            },
            {
              path: ':taskId(\\d+)', component: TaskCard, name: 'project.tasks.detail', props: route => {
                return {
                  taskId: Number(route.params.taskId)
                }
              },
            }
          ]
        },

        {
          path: 'tasks', component: TaskListPage, name: 'tasks', props: route => {
            return {
              assigneeIds: route.query.assignee_ids ? route.query.assignee_ids.split(',').map(v => Number(v)) : [],
              sphereId: Number(route.query.sphere_id) || 0,
              targetId: Number(route.query.target_id) || 0,
            }
          }
        },

        {
          path: '/:project/sprints', component: Sprints, name: 'sprints.index'
        },

        {
          path: '/:project/sprints/:sprintId(\\d+)', component: Sprints, name: 'sprints.show', props: (route) => ({
            sprintId: Number(route.params.sprintId)
          })
        },

        {
          path: '/:project/workload', component: WorkLoad, name: 'workload.index'
        },

        {
          path: '/:project/goals', component: GoalsProgress, name: 'goalsProgress.index'
        },

        {
          path: '/:project/report', component: ReportPage, name: 'report.index'
        },

        {
          path: '/:project/pages', component: Pages, name: 'pages',
          children: [
            {
              path: '', component: PageDashboard, name: 'pages.dashboard', props: () => ({
                displayType: 'root',
              })
            },
            {
              path: 'projects/:code', component: PageDashboard, name: 'pages.dashboard.project', props: (route) => ({
                projectCode: route.params.code,
              })
            },
            {
              path: 'create', component: PageContent, name: 'pages.create', props: (route) => ({
                projectCode: route.query.project || null,
                parentCode: route.query.parent || null
              })
            },
            {
              path: ':code', component: PageContent, name: 'pages.detail', props: route => {
                return {
                  code: route.params.code,
                }
              },
            }
          ]
        }
      ]
    },

    {
      path: '/:project/tour', component: TourTasks, meta: { requiresAuth: true }, name: 'tour.tasks'
    },

    {
      path: '/files/:uuid', component: FileDownload, props: true, name: 'download'
    }
  ]

  const router = new VueRouter({
    mode: 'history',
    routes
  })

  router.beforeEach((to, from, next) => {
    const isAuth = authService.isAuth()
    const isPrivate = to.matched.some(record => record.meta.requiresAuth)
    //const isForGuests = to.matched.some(record => record.meta.onlyForGuests)

    if (isPrivate && !isAuth) {
      return next({
        name: 'login',
        query: { redirect: to.fullPath }
      })
    }

    // if (isForGuests && isAuth) {
    //   console.log(store.getters.project)
    //   return next({
    //     name: 'project.tasks',
    //     params: {
    //       project: store.getters.project.code,
    //     }
    //   })
    // }

    next()
  })


  return router
}

<template>
  <div class="workload-members">
    <workload-member-item
      v-for="memberId in testMembers"
      :key="memberId"
      :member="getMember(memberId)"
      :tasks="memberTasks(memberId)"
      @open-task="onOpenTask"
      @create-task="onCreateAfterTask"
    />

    <ModalTaskCard
      v-if="modalTask.id !== null"
      :task-id="modalTask.id"
      :member-id="modalTask.memberId"
      :stage-id="modalTask.stageId"
      :target-id="modalTask.targetId"
      :after-task-id="modalTask.afterTaskId"
      @close="onCloseTask"
    />
  </div>
</template>
<script>
  import { createNamespacedHelpers, mapGetters } from 'vuex'

  import WorkloadMemberItem from '@/components/workload/WorkloadMemberItem'
  import withModalTask from '@/components/tasks/mixins/withModalTask'

  const { mapGetters: workloadGetters } = createNamespacedHelpers('workload')

  export default {
    name: 'WorkloadMembers',

    components: { WorkloadMemberItem },

    mixins: [withModalTask],

    props: {
      members: {
        type: Array,
        required: true
      }
    },

    computed: {
      ...mapGetters(['baseStage', 'activeMembers']),
      ...workloadGetters(['filteredMembers', 'membersTasks']),

      testMembers () {
        console.log(this.filteredMembers)

        return this.filteredMembers
      }
    },

    methods: {
      memberTasks (memberId) {
        return this.membersTasks[memberId] || []
      },

      onCreateAfterTask ({ task = null, member }) {
        this.modalTask = {
          ...this.modalTask,
          id: 0,
          stageId: this.baseStage.id,
          memberId: member.member_id,
          afterTaskId: task?.id,
        }

        this.changeRoutePath()
      },

      getMember (memberId) {
        return this.activeMembers.find(item => item.member_id === memberId)
      }
    }
  }
</script>
<style lang="sass">
  .workload-members
    display: flex
    height: 100%
</style>

<template>
  <div class="sphere-list" v-draggable-list="{ name: listName, callbacks }">
    <sphere-item
        v-for="sphere in spheres"
        :key="sphere.id || sphere.code"
        :sphere="sphere"
        :parent="parent"
    />
  </div>
</template>
<script>
import SphereItem from "./SphereItem"
import {createNamespacedHelpers} from "vuex";

const { mapActions: spheresActions } = createNamespacedHelpers('spheres')

export default {
  name: 'SpheresList',

  components: { SphereItem },

  props: {
    spheres: {
      type: Array,
      required: true
    },

    parent: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      callbacks: {
        onDragEnd: this.onDragFinish
      }
    }
  },

  computed: {
    listName () {
      return this.parentCode
        ? `spheres-${this.parentCode}`
        : `spheres`
    },

    parentCode () {
      return this.parent
        ? this.parent.id || this.parent.code
        : null
    }
  },

  methods: {
    ...spheresActions(['reSortSpheres']),

    onDragFinish (listEl, source, target, position) {
      //console.log(source, target, position)

      this.reSortSpheres({ parent: this.parent, source, target, position })
    }
  },

  beforeCreate () {
    this.$options.components.SphereItem = require('./SphereItem').default
  }
}
</script>
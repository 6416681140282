<template>
  <div class="channel-view" v-if="isLoaded">
    <div class="channel-view__header">
      <div class="direct-channel-header">
        <div class="direct-channel-header__subject">
          <channel-photo :channel="activeChannel" class="direct-channel-header__photo"/>

          <div class="direct-channel-header__title">
            {{ channelTitle(activeChannel) }}
          </div>
        </div>
      </div>
    </div>

    <div class="channel-view__messages" ref="messages">
      <message-list
        :channel-id="activeChannelId"
        :on-load-cb="onMessagesLoaded"
      />
    </div>

    <div class="channel-view__form">
      <message-form :channel="activeChannel"/>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from "vuex"

import channelHelpers from "@/components/messenger/channelHelpers"
import ChannelPhoto from "@/components/messenger/ChannelPhoto"
import MessageList from "@/components/messenger/MessageList"
import MessageForm from "@/components/messenger/MessageForm"
import pageTitleMixin from "@/views/mixins/pageTitleMixin"

const { mapActions: messengerActions, mapGetters: messengerGetters } = createNamespacedHelpers('messenger')

export default {
  name: 'Channel',

  components: { ChannelPhoto, MessageList, MessageForm },

  mixins: [channelHelpers, pageTitleMixin],

  props: {
    channelId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      isLoaded: false
    }
  },

  computed: {
    ...messengerGetters(['activeChannel', 'activeChannelId', 'channelMessages', 'helpdeskChannel']),
    ...mapGetters(['workspace']),

    multiChannelClasses () {
      const styleId = this.activeChannel.member_id % 6 + 1

      return [`channel-item__multi-photo_style-${styleId}`]
    },

    pageTitle () {
      return {
        message: 'pageTitles.messenger', params: { workspace: this.workspace.name }
      }
    },
  },

  watch: {
    channelId: function () {
      this.changeChannel()
    }
  },

  methods: {
    ...messengerActions(['switchChannel', 'fetchHelpdeskChannel']),

    onMessagesLoaded () {
      this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight
    },

    changeChannel () {
      let cPromise = Promise.resolve()
      let channelId = this.channelId

      if (channelId === 'helpdesk') {
        console.log(this.helpdeskChannel)
        if (!this.helpdeskChannel) {
          cPromise = this.fetchHelpdeskChannel().then(channel => channelId = channel.id)
        } else {
          channelId = this.helpdeskChannel.id
        }
      }

      cPromise.then(() => {
        this.switchChannel({ channelId, loadMessagesCb: this.onMessagesLoaded })
        this.isLoaded = true
      })
    }
  },

  created () {
    this.changeChannel()
  },

  beforeDestroy() {
    this.switchChannel({ channelId: null })
  }
}
</script>
<template>
  <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-1.13637e-06 7.83523C-5.0877e-07 3.50795 3.50795 5.0877e-07 7.83523 1.13637e-06L27 3.9159e-06C27 4.41828 23.4183 8 19 8L-1.16027e-06 8L-1.13637e-06 7.83523Z" fill="#6EADFF"/>
    <path d="M7.20206e-07 10C3.22447e-07 13.866 3.13401 17 7 17L28 17C28 13.134 24.866 10 21 10L7.20206e-07 10Z" fill="#638FFF"/>
    <path d="M28 19.1648C28 23.492 24.492 27 20.1648 27L1 27C1 22.5817 4.58172 19 9 19L28 19L28 19.1648Z" fill="#5A6AD7"/>
  </svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'LogoIcon',

  mixins: [iconMixin],

  props: {
    viewBox: {
      type: String,
      default: '0 0 12 12'
    }
  },

  data () {
    return {
      defaultBackground: '#416AF7',
      defaultWidth: 12,
      defaultHeight: 12
    }
  }
}
</script>

<template>
  <div class="message-list messages-wrapper" ref="messages">
    <div style="height: 10px" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"></div>
    <div
      v-for="group in channelMessages"
      :key="group.date"
      class="message-group"
    >
      <div class="message-group__title">
        <div class="message-group__label">
          {{ group.dateLabel }}
        </div>
      </div>
      <div class="message-group__messages">
        <div
            v-for="item in group.messages"
            :key="item.id"
            class="message-item"
            :class="{'message-item_own': isOwn(item.member_id) }"
        >
          <div
            class="message-item__author"
            v-if="!isOwn(item.member_id)"
            :class="{'message-item__first_unread': isFirstUnread(item), 'message-item__author_labeled': item.author }"
          >
            <user-photo class="message-item__photo" :user="getMessageMember(item)" size="chat"/>
          </div>

          <div class="message-item__messages">
            <div v-if="item.author" class="message-item__messages-author">
              {{ item.author.first_name }} {{ item.author.last_name }}
            </div>
            <template v-for="message in item.messages">
              <div class="new_messages" v-if="firstUnseen === message.id" :key="message.id">
                <div class="new_messages__bar" />
                <span class="new_messages__text">{{ $t('messenger.unreadMessages') }}</span>
                <div class="new_messages__bar" />
              </div>

              <message-bubble
                :key="message.id"
                :channel="activeChannel"
                :message="message"
                :is-own="isOwn(item.member_id)"
                @unseen="onUnseenMessage"
                @scroll-to="onLastSeenMessage"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from "vuex"
import channelHelpers from "@/components/messenger/channelHelpers"
import UserPhoto from "@/components/ui/UserPhoto"
import MessageBubble from "@/components/messenger/MessageBubble"
import { channelLastMessageDate } from "@/services/scoring"
import { throttle } from 'throttle-debounce'

const { mapActions: messengerActions, mapGetters: messengerGetters } = createNamespacedHelpers('messenger')

export default {
  name: 'MessageList',

  components: { UserPhoto, MessageBubble },

  mixins: [channelHelpers],

  props: {
    channelId: {
      type: String,
      required: true
    },

    onLoadCb: {
      type: Function,
      required: true
    }
  },

  data () {
    return {
      firstUnseen: null,
      previousScrollValue: 0,
      unseenCallbacks: {},
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0, 1],
      },
      lastScrollPos: null,
      throttleMessagesScroll: throttle(500, () => {
        this.$nextTick(() => {
          this.onMessagesScroll()
        })
      }),
    }
  },

  computed: {
    ...mapGetters(['authUser', 'members']),
    ...messengerGetters(['channelMessages', 'activeChannel']),
  },

  watch: {
    channelId: function () {
      this.fetchChannelMessages({ channelId: this.channelId })
        .then(() => {
          this.firstUnseen = null
          this.unseenCallbacks = {}
          this.onLoadCb()
        })
    }
  },

  methods: {
    ...messengerActions(['fetchChannelMessages', 'markSeenMessages']),

    isOwn (memberId) {
        return memberId === this.authUser.member_id
    },

    isFirstUnread(item) {
      return !this.isOwn(item.member_id) && item.messages[0].id === this.firstUnseen
    },

    formattedDate (channel) {
      return channelLastMessageDate(channel.updatedAt)
    },

    onMessagesFetched () {

    },

    onMessagesScroll () {
      const unseenMessageUuids = Object.keys(this.unseenCallbacks)

      if (unseenMessageUuids.length === 0) {
        return
      }

      this.firstUnseen = unseenMessageUuids[0];

      const scrollTop = this.$parent.$refs.messages.scrollTop
      const boxOffset = this.$parent.$refs.messages.getBoundingClientRect()

      const seenMessages = unseenMessageUuids
        .reduce((seenMessages, id) => {
          const isVisible = this.unseenCallbacks[id].seenCallback({ scrollTop, boxOffset})

          if (isVisible) {
            seenMessages.push({ id, date: this.unseenCallbacks[id].date })
          }

          return seenMessages
        }, [])

      seenMessages.forEach(id => {
        // eslint-disable-next-line
        const { [id]: deletedId, ...rest } = this.unseenCallbacks
        this.unseenCallbacks = rest
      })

      this.markSeenMessages({ channel: this.activeChannel, seenMessages })
    },

    onUnseenMessage ({ id, date, seenCallback }) {
      this.unseenCallbacks[id] = { date, seenCallback }
    },

    onLastSeenMessage ({ offsetTop }) {
      const boxOffset = this.$parent.$refs.messages.getBoundingClientRect()
      this.$parent.$refs.messages.scrollTop = offsetTop - boxOffset.top - 4
    },

    async fetchMessages() {
      await this.fetchChannelMessages({ channelId: this.channelId })
      this.$nextTick(() => {
        this.onMessagesScroll()
      })
    },

    onWaypoint ({ going, direction }) {
      // going: in, out
      // direction: top, right, bottom, left
      if (going === this.$waypointMap.GOING_IN && direction !== this.$waypointMap.DIRECTION_TOP) {
        this.$nextTick(function () {
          this.fetchMessages()
        })
      }
    },

    handleScroll () {
      this.lastScrollPos = this.$parent.$refs.messages.scrollHeight - this.$parent.$refs.messages.scrollTop
      this.throttleMessagesScroll()
    },

    getMessageMember (item) {
      if (item.member_id !== -1) {
        return this.getMember(item.member_id)
      }

      return item.author || {}
    }
  },

  mounted () {
    this.$parent.$refs.messages.addEventListener('scroll', this.handleScroll);
  },

  updated () {
    this.$parent.$refs.messages.scrollTop = this.$parent.$refs.messages.scrollHeight - this.lastScrollPos
  },

  beforeDestroy() {
    if (this.$parent.$refs.messages) {
      this.$parent.$refs.messages.removeEventListener('scroll', this.handleScroll);
    }
  },

  destroyed () {
    this.firstUnseen = null
    this.unseenCallbacks = {}
  }
}
</script>

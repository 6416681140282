import apiService from "@/services/api"
import socketService from "@/services/socket"
import {setupWorkHours, workHoursToUtc} from "@/services/scoring"
import {colors} from "@/utils/colors";
import {createProjectMemberTransformer} from "@/transformers/members";
import projectSocket from "@/store/socket/project";
import {sortByField, sortByMultipleFields, sortNullables} from "@/utils/arrays";
import {transformTask} from "@/transformers/tasks";
import {transformTargetItem} from "@/transformers/targets";

let socketHandler = null;

export const state = {
  project: null,

  stages: [],

  spheres: [],

  targets: [],

  targetsLoaded: false,

  targetsStatus: 'active',

  members: [],

  invitationNominees: [],

  activeTask: {},

  oldActiveTask: {},
}

export const mutations = {
  SET_PROJECT_DATA (state, project) {
    const { work_schedule: workSchedule = null } = project

    if (workSchedule) {
      workSchedule[0].work_hours = setupWorkHours(workSchedule[0].work_hours)
    }

    state.project = project
  },

  SET_ACTIVE_TASK (state, task) {
    state.activeTask = task
  },

  SET_OLD_ACTIVE_TASK (state, task) {
    state.oldActiveTask = task;
  },

  SET_STAGES (state, stages) {
    state.stages = stages
  },

  SET_MEMBERS (state, members) {
    state.members = members
  },

  SET_MEMBER (state, member) {
    const idx = state.members.findIndex((item) => item.member_id === member.member_id)
    if (idx === -1) {
      state.members = [...state.members, member]
    } else {
      state.members.splice(idx, 1, { ...state.members[idx], ...member })
    }
  },

  SET_PARTIAL_MEMBERS (state, members) {
    const updatedMemberIds = []

    const updatedMembers = state.members.map((member) => {
      const item = members.find((item) => item.member_id === member.member_id)
      if (item) {
        updatedMemberIds.push(item.member_id)
        return item
      }

      return member
    })

    updatedMembers.push(...members.filter((item) => !updatedMemberIds.includes(item.member_id)))

    state.members = updatedMembers
  },

  SET_SPHERES (state, data) {
    const childSpheres = []
    const spheres = data.filter(sphere => {

      if (sphere.parent_id) {
        childSpheres.unshift(sphere)
      }

      return !sphere.parent_id
    })

    childSpheres.forEach(sphere => {
      const parentIndex = spheres.findIndex(item => item.id === sphere.parent_id)
      spheres.splice(parentIndex + 1, 0, sphere)
    })

    state.spheres = spheres.map((item, index) => {
      const colorIndex = index < colors.length
        ? index
        : (colors.length - 1) % index

      return {
        ...item,
        color: colors[colorIndex]
      }
    })
  },

  SET_MEMBERS_ONLINE (state, { memberIds }) {
    state.members = state.members.map(member => {
      return {
        ...member,
        isOnline: memberIds.indexOf(member.member_id) !== -1
      }
    })
  },

  SET_MEMBER_ONLINE (state, { memberId, isOnline }) {
    state.members = state.members.map(member => {
      if (member.member_id !== memberId) {
        return member
      }

      return {
        ...member,
        isOnline
      }
    })
  },

  REFRESH_STAGE (state, stage) {
    state.stages = state.stages.map(item => {
      if (item.id === stage.id) {
        return stage
      }

      return item
    })
  },

  DELETE_STAGE (state, id) {
    state.stages = state.stages.filter(stage => stage.id !== id)
  },

  ADD_STAGE(state, stage) {
    state.stages.push(stage)
    state.stages.sort(sortByField('sort', 'asc'))
  },

  REFRESH_STAGES (state, stages) {
    state.stages = state.stages.map(stage => {
      const updatedStage = stages.find(item => item.id === stage.id)
      return updatedStage || stage
    })

    state.stages.sort(sortByField('sort', 'asc'))
  },

  SET_TARGETS_STATUS (state, status) {
    state.targetsStatus = status
  },

  SET_TARGETS (state, targets) {
    state.targetsLoaded = true
    state.targets = targets.map(transformTargetItem)
  },

  SET_TARGET (state, target) {
    const index = state.targets.findIndex(item => item.id === target.id)

    if (state.targetsStatus !== target.status) {
      if (index !== -1) {
        state.targets.splice(index, 1)
      }

      return
    }

    if (index !== -1) {
      state.targets.splice(index, 1, transformTargetItem(target))
    } else {
      state.targets.push(transformTargetItem(target))
    }

    sortTargets(state.targets)
  },

  DELETE_TARGET (state, targetId) {
    state.targets = state.targets.filter(item => item.id !== targetId)

    // Object.keys(state.tasks).forEach(stageId => {
    //   const updatedTasks = state.tasks[stageId].map(item => {
    //     if (item.target && item.target.id === targetId) {
    //       item.target = null
    //     }
    //
    //     return item
    //   })
    //
    //   state.tasks[stageId] = updatedTasks
    // })
  },

  SET_NOMINEES (state, data) {
    state.invitationNominees = data
  },

  REFRESH_USER_DATA (state, { memberId, data }) {
    state.members = state.members.map((member) => {
      if (member.member_id !== memberId) {
        return member
      }

      return {
        ...member,
        ...data,
      }
    })
  }
}

export const actions = {
  async initProject ({ dispatch }, { projectCode }) {
    const { data } = await apiService.get(`projects/${projectCode}`)
    const { project, active_task: activeTask } = data
    dispatch('setProjectData', project)
    dispatch('setActiveTask', { task: activeTask })

    return data
  },

  createProject ({ commit, dispatch }, projectData) {
    return apiService.post('projects', transformFormPayload(projectData))
      .then(({ data }) => {
        const { project, projects } = data

        commit('SET_PROJECT_DATA', project)
        dispatch('setProjects', projects, { root: true })

        return data
      })
  },

  setProjectData (store, data) {
    const { commit, getters, rootGetters } = store
    const { spheres, stages, members, isInit = false, ...project } = data

    commit('SET_PROJECT_DATA', project)
    commit('SET_SPHERES', spheres)
    commit('SET_STAGES', stages)

    const transformMember = createProjectMemberTransformer(getters)
    commit('SET_MEMBERS', members.map(transformMember))

    if (isInit) {
      const projectSocketCreator = projectSocket(store)
      socketHandler = projectSocketCreator(socketService.socket)
    } else {
      const { workspace, authUser } = rootGetters
      socketHandler.switchProject({ workspaceId: workspace.global_id, projectCode: project.code, memberId: authUser.member_id })
    }
  },

  async updateProject ({ commit }, { code, data: projectData }) {
    const { data } = await apiService.put(`projects/${code}`, transformFormPayload(projectData))
    commit('SET_PROJECT_DATA', data)

    return data
  },

  async updateProjectLogo ({ commit, getters }, formData) {
    const { project } = getters
    const { data } = await apiService.uploadFile(`projects/${project.code}/logo`, formData)
    commit('SET_PROJECT_DATA', data)

    return data
  },

  async fetchProjectSettings ({ commit }, { code }) {
    const { data } = await apiService.get(`projects/${code}/settings`)
    commit('SET_PROJECT_DATA', data)

    return data
  },

  async fetchProjectMembers({ commit, getters }) {
    const { project } = getters
    const { data } = await apiService.get(`projects/${project.code}/members`)
    // eslint-disable-next-line no-unused-vars
    const transformMember = createProjectMemberTransformer(getters)
    commit('SET_MEMBERS', data.map(transformMember))

    return data
  },

  async updateMember ({ dispatch, rootGetters }, { memberId, data: memberData }) {
    const { project } = rootGetters
    const { data } = await apiService.patch(`projects/${project.code}/members/${memberId}`, memberData)
    dispatch('setProjectMember', data)

    return data
  },

  async removeMember ({ rootGetters }, { memberId }) {
    const { project } = rootGetters
    const { data } = await apiService.delete(`projects/${project.code}/members/${memberId}`)
    return data
  },

  async fetchInvitationNominees ({ commit, getters }) {
    const { project } = getters
    const { data } = await apiService.get(`projects/${project.code}/nominees`)
    commit('SET_NOMINEES', data)
    return data
  },

  async inviteMembers ({ getters, commit, rootGetters }, { members, new_members = [] }) {
    const { project } = getters
    const { data } = await apiService.post(`projects/${project.code}/members`, { members, new_members })

    const memberTransformer = createProjectMemberTransformer(rootGetters)
    commit('SET_PARTIAL_MEMBERS', data.map(memberTransformer))

    return data
  },

  async fetchActiveTask ({ dispatch, getters }) {
    const { project } = getters
    const { data } = await apiService.get(`projects/${project.code}/tasks/active`)
    dispatch('setActiveTask', { task: data })

    return data
  },

  async fetchTargets ({ commit, rootGetters }, { status }) {
    const { project } = rootGetters

    commit('SET_TARGETS_STATUS', status)

    const { data } = await apiService.get(`projects/${project.code}/targets`, { status })
    commit('SET_TARGETS', data)

    return data
  },

  async createTarget ({ commit, getters }, projectData) {
    const { project } = getters
    const { data } = await apiService.post(`projects/${project.code}/targets`, projectData)
    commit('SET_TARGET', data)

    return data
  },

  async updateTarget ({ commit, getters }, { id, ...projectData }) {
    const { project } = getters
    const { data } = await apiService.patch(`projects/${project.code}/targets/${id}`, projectData)
    commit('SET_TARGET', data)

    return data
  },

  async deleteTarget ({ dispatch, getters }, { targetId }) {
    const { project } = getters
    await apiService.delete(`projects/${project.code}/targets/${targetId}`)
    dispatch('refreshDeletedTarget', { targetId })
  },

  refreshDeletedTarget ({ commit }, { targetId }) {
    commit('DELETE_TARGET', targetId)
  },

  fetchTarget ({ getters }, { id }) {
    if (id === 0) {
      return Promise.resolve(nullableTarget())
    }

    const { project } = getters
    return apiService.get(`projects/${project.code}/targets/${id}`)
        .then(({ data }) => data)
  },

  async finishTarget ({ commit, getters }, { id }) {
    const { project } = getters
    const { data } = await apiService.post(`projects/${project.code}/targets/${id}/finish`)
    commit('SET_TARGET', data)
    return data
  },

  refreshTarget ({ commit }, { target }) {
    commit('SET_TARGET', target)
  },

  appendTarget ({ commit }, { target }) {
    commit('SET_TARGET', target)
  },

  setActiveTask ({ commit, rootGetters }, { task }) {
    commit('SET_ACTIVE_TASK', task ? transformTask(task, rootGetters) : {})
  },

  setOldActiveTask ({ commit, rootGetters }, task) {
    commit('SET_OLD_ACTIVE_TASK', task ? transformTask(task, rootGetters) : null)
  },

  setProjectMember ({ commit, rootGetters }, member) {
    const memberTransformer = createProjectMemberTransformer(rootGetters)
    commit('SET_MEMBER', memberTransformer(member))
  },

  setMembersOnline ({ commit }, { memberIds }) {
    commit('SET_MEMBERS_ONLINE', { memberIds })
  },

  setMemberOnline ({ commit }, { memberId, isOnline }) {
    commit('SET_MEMBER_ONLINE', { memberId, isOnline })
  },

  refreshStage ({ commit }, { stage }) {
    commit('REFRESH_STAGE', stage)
  },

  deleteStage ({ commit }, { id }) {
    commit('DELETE_STAGE', id)
  },

  addStage ({ commit }, stage) {
    commit('ADD_STAGE', stage)
  },

  refreshStages ({ commit }, stages) {
    commit('REFRESH_STAGES', stages)
  },

  addMember({ commit }, data) {
    commit('ADD_MEMBER', data)
  },

  removeProjectMember ({ commit }, member) {
    commit('SET_MEMBER', member)
  },

  refreshProjectData ({ commit }, { project }) {
    commit('SET_PROJECT_DATA', project)
  },

  refreshUserData ({ commit }, { member_id, ...data }) {
    commit('REFRESH_USER_DATA', { memberId: member_id, data });
  }
}

export const getters = {
  project: ({ project }) => project,

  activeTask: ({ activeTask }) => activeTask,

  oldActiveTask: ({ oldActiveTask }) => oldActiveTask,

  stages: ({ stages }) => stages,

  spheres: ({ spheres }) => spheres,

  parentSpheres: ({ spheres }) => spheres.filter(item => item.is_parent),

  baseStage: ({ stages }) => stages.find(item => item.is_base_stage),

  targets: ({ targets }) => targets,

  targetsLoaded: ({ targetsLoaded }) => targetsLoaded,

  members: ({ members }) => members,

  activeMembers: ({ members }) => members.filter(member => member.project.status === 'active'),

  invitedMembers: ({ members }) => members.filter(member => member.status === 'invited'),

  memberIds: ({ members }) => members.map(item => item.member_id),

  projectAuthMember: ({ members }, { authUser }) => members.find((item) => item.member_id === authUser.member_id),

  invitationNominees: ({ invitationNominees }) => invitationNominees,
}

const transformFormPayload = (projectData) => {
  const { work_schedule: workSchedule } = projectData

  return {
    ...projectData,
    work_schedule: [
      {
        days: [...workSchedule[0].days],
        work_hours: workHoursToUtc(workSchedule[0].work_hours)
      }
    ]
  }
}

function sortTargets(targets) {
  targets.sort(sortByMultipleFields(sortNullables('estimated_finish_at', 'desc'), sortByField('estimated_finish_at', 'asc')))
}

function nullableTarget() {
  return {
    id: 0,
    name: '',
    color: 'azure',
    description: ''
  }
}


export const namespaced = false

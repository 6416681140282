<template>
  <div class="fx fx_center">
    <user-photo :user="user" size="lg" class="upload-photo">
      <template slot="hover_content">
        <label class="upload-hover">
          <upload-icon class="upload-hover__icon"/>
          <div>{{ $t('settings.upload') }}</div>
          <input type="file" accept="image/*" ref="file" @change="onFileChange" class="upload-hover__input"/>
        </label>
      </template>
    </user-photo>
  </div>
</template>
<script>
  import { mapActions } from 'vuex'
  import UserPhoto from "../ui/UserPhoto"

  import UploadIcon from "../icons/UploadIcon"

  export default {
    name: 'PhotoUploader',

    components: { UserPhoto, UploadIcon },

    props: {
      user: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        file: null
      }
    },

    methods: {
      ...mapActions(['updateProfilePhoto']),

      onFileChange () {
        this.file = this.$refs.file.files[0]

        const formData = new FormData()
        formData.append('image', this.file)

        this.updateProfilePhoto(formData)
      }
    }
  }
</script>
<style lang="sass">
  .upload-hover
    background: rgba(0,0,0,0.5)
    color: #fff
    border-radius: 34px
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    visibility: hidden
    cursor: pointer
    display: flex
    flex-flow: column nowrap
    align-items: center
    justify-content: center
    font-size: 14px

    &__icon
      margin-bottom: 6px

    &__input
      display: none

  .upload-photo
    position: relative
    width: 100px
    height: 100px

    &:hover
      .upload-hover
        visibility: visible
</style>

<template>
  <div class="workload-filters">
    <div class="workload-filters__controls">
      <ui-dropdown
          class="workload-filters__item"
          :options="sortOptions"
          key-field="id"
          :value="filters.sort"
          reference-class="workload-filters__selector"
          popover-class="ui-dropdown-ex"
          placement="bottom-start"
          :width-by-popover="false"
          @input="onSortChange"
      >
        <sort-icon class="workload-filters__selector-icon"/> {{ $t(`workload.sortOptions.${filters.sort}`) }}
        <template v-slot:option="{ option }">
          {{ $t(`workload.sortOptions.${option.id}`) }}
        </template>
      </ui-dropdown>

      <ui-dropdown
        class="workload-filters__item"
        reference-class="workload-filters__selector"
        :options="spheres"
        :value="filters.spheres"
        :multiple="true"
        :width-by-popover="false"
        popover-class="ui-dropdown-ex"
        placement="bottom-start"
        @input="onSphereChange"
      >
        <div class="workload-filters__spheres">
          <template v-if="filters.spheres.length > 0">
            {{ $tc('workload.filters.spheresCount', filters.spheres.length, { count: filters.spheres.length }) }}
          </template>
          <template v-else>
            {{ $t('workload.filters.allSpheres') }}
          </template>
        </div>

        <template v-slot:option="{ option, selected }">
          <el-checkbox :value="selected" :class="sphereClasses(option)">
            {{ option.name }}
          </el-checkbox>
        </template>
      </ui-dropdown>
    </div>
    <transition name="slow-jump" appear>
      <div class="workload-filters__selected" v-if="filters.spheres.length > 0">
        <div class="workload-filters__spheres">
          <div class="workload-filters__selected-title">
            {{ $t('workload.filters.selectedSpheres') }}
          </div>
          <transition-group name="input-error-anim" tag="div" class="workload-filters__spheres" appear>
            <sphere-badge
              v-for="(sphere, idx) in filteredSpheres"
              :key="sphere.id"
              :sphere="sphere"
              :closeable="true"
              @close="onRemoveSphere(idx)"
            />
          </transition-group>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
  import { createNamespacedHelpers, mapGetters } from 'vuex'
  import SphereBadge from '@/components/ui/SphereBadge'
  import SortIcon from '@/components/icons/SortIcon'
  import UiDropdown from '@/components/tasks/UiDropdown'

  const { mapActions: workloadActions, mapGetters: workloadGetters } = createNamespacedHelpers('workload')

  export default {
    name: 'WorkloadFilters',

    components: { SortIcon, SphereBadge, UiDropdown },

    data () {
      return {
        sortOptions: [
          { id: 'estimatedFinishAt_desc' },
          { id: 'estimatedFinishAt_asc' },
          { id: 'tasksCount_desc' },
          { id: 'tasksCount_asc' },
        ],

        sort: 'workload_desc'
      }
    },

    computed: {
      ...mapGetters(['spheres', 'parentSpheres']),
      ...workloadGetters(['filters']),

      filteredSpheres () {
        return this.filters.spheres.map(sphereId => this.spheres.find(item => item.id === sphereId))
      }
    },

    methods: {
      ...workloadActions(['setFilters']),

      onSortChange ({ id }) {
        this.setFilters({
          ...this.filters,
          sort: id
        })
      },

      sphereClasses (sphere) {
        const classes = []
        if (sphere.parent_id) {
          classes.push('ml-2')
        }

        return classes
      },

      onSphereChange (selectedSpheres) {
        this.setFilters({
          ...this.filters,
          spheres: this.buildSelectedSpheres(selectedSpheres),
        })
      },

      buildSelectedSpheres (selectedSpheres) {
        const selectedChilds = {}
        selectedSpheres.forEach(({ id, parent_id }) => {
          const parentId = parent_id || id
          if (!selectedChilds[parentId]) {
            selectedChilds[parentId] = []
          }

          if (!parent_id) {
            return
          }

          selectedChilds[parent_id].push(id)
        })

        return Object.keys(selectedChilds).reduce((res, parentId) => {
          const numParentId = Number(parentId)
          const childSpheres = this.spheres.filter(item => item.parent_id === numParentId)

          if (selectedChilds[parentId].length === 0 || selectedChilds[parentId].length === childSpheres.length) {
            res.push(numParentId)
            res.push(...childSpheres.map(item => item.id))
            return res
          }

          res.push(...selectedChilds[parentId])
          return res
        }, [])
      },

      onRemoveSphere (idx) {
        const spheres = [...this.filteredSpheres]
        spheres.splice(idx, 1)

        this.setFilters({
          ...this.filters,
          spheres: this.buildSelectedSpheres(spheres)
        })
      }
    }
  }
</script>
<style lang="sass">
  .workload-filters
    &__controls
      align-items: center
      display: flex

    &__selector
      align-items: center
      display: flex
      transition: all .2s ease-in

      &.ui-dropdown__reference
        padding: 8px 16px
        border-radius: 6px
        background: var(--grey-light-color-24)

      &.ui-dropdown__reference:hover,
      &.ui-dropdown__reference_opened,
      &.ui-dropdown__reference_opened:hover
        background: var(--grey-light-color-40)
        padding: 8px 16px
        box-shadow: none

      &-icon
        margin-right: 6px

        .path-stroke
          stroke: var(--text-primary-color)
          transition: all .2s ease-in

    &__item
      margin-right: 12px

    &__selected
      align-items: center
      display: flex
      margin-top: 18px

      &-title
        color: var(--text-light-color)
        margin-right: 12px

    &__spheres
      align-items: center
      display: flex

      .sphere-badge
        margin-right: 6px

        &:last-child
          margin-right: 0
</style>

<template>
  <div class="join-block">
    <div v-if="isLoaded && isFound">
      <div class="join-workspace">
        <workspace-logo :workspace="invitation.workspace" size="mdx"/>
      </div>
      <div class="join-block__title">
        {{ memberFullName(invitation.inviter) }} <br/>{{ $t('auth.inviteToJoin', { workspace: invitation.workspace.name }) }}
      </div>
      <div class="block_auth" v-if="!isDeclined">
        <template v-if="!isAuth && !invitation.has_account">
          <div class="join-block__form-title">{{ $t('auth.createAccount') }}</div>
          <register-form
            :code="invitation.code"
            :preset-values="invitation.member"
            :submit-title="$t('auth.join')"
          />
          <div>
            <ui-button @click.prevent="onDecline" ui-class="block inverted">{{ $t('common.decline') }}</ui-button>
          </div>
        </template>
        <template v-else>
          <template v-if="!isAuth">
            <div class="join-block__form-title">{{ $t('auth.singInToAccount') }}</div>
            <login-form :email="invitation.member.email" @login="onLogin"/>
          </template>
          <template v-else>
            <div class="join-controls">
              <div class="join-controls__accept">
                <ui-button @click.prevent="onAccept" ui-class="block">{{ $t('common.accept') }}</ui-button>
              </div>

              <div class="join-controls__decline">
                <ui-button @click.prevent="onDecline" ui-class="inverted block">{{ $t('common.decline') }}</ui-button>
              </div>
            </div>
          </template>
        </template>
      </div>
      <div class="block_declined" v-if="isDeclined">
        {{ $t('auth.declined') }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import UiButton from 'scorework-ui/src/components/UiButton'

import RegisterForm from "@/components/auth/RegisterForm"
import LoginForm from "@/components/auth/LoginForm"
import WorkspaceLogo from "@/components/ui/WorkspaceLogo"
import memberHelpers from "@/mixins/memberHelpers"
import languageService from "@/services/language"

export default {
  name: 'JoinWorkspace',

  components: { LoginForm, RegisterForm, WorkspaceLogo, UiButton },

  mixins: [memberHelpers],

  props: {
    code: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      isDeclined: false,
      isLoaded: false,
      isFound: true
    }
  },

  computed: {
    ...mapGetters(['authUser', 'invitation', 'isAuth'])
  },

  methods: {
    ...mapActions(['fetchInvitation', 'fetchUserMe', 'updateInvitation', 'fetchInit']),

    loadInvitation () {
      this.fetchInvitation({ code: this.code })
          .then((data) => {
            if (languageService.getLanguage() !== data.member.lang) {
              languageService.setLanguage(data.member.lang)
            }

            languageService.setPageTitle('pageTitles.join', { workspace: data.workspace.name })
          })
          .catch(err => {
            if (err.response.status === 404) {
              this.isFound = false
            }
          })
          .finally(() => {
            this.isLoaded = true
          })
    },

    onLogin () {
      this.fetchUserMe()
        .then(() => {

        })
    },

    async onAccept () {
      try {
        await this.updateInvitation({ code: this.code, action: 'accept' })
        await this.fetchInit()
        this.$router.push({ name: 'onboarding.spheres' })
      } catch (e) {
        this.$notify({
          title: this.$t('common.error'),
          message: this.$t('auth.failedToUpdateInviteStatus'),
          position: 'bottom-right',
          type: 'error'
        })
      }
    },

    async onDecline () {
      try {
        await this.updateInvitation({ code: this.code, action: 'decline' })
        this.isDeclined = true
      } catch (e) {
        this.$notify({
          title: this.$t('common.error'),
          message: this.$t('auth.failedToUpdateInviteStatus'),
          position: 'bottom-right',
          type: 'error'
        })
      }
    }
  },

  created () {
    if (this.isAuth) {
      this.fetchUserMe()
        .then(() => {
          this.loadInvitation()
        })
    } else {
     this.loadInvitation()
    }
  }
}
</script>
<style lang="sass">
.join-block
  width: 640px

  &__title
    font-size: 24px
    font-weight: 500
    text-align: center
    margin-bottom: 50px
    line-height: 1.5

  &__form-title
    font-weight: 500
    font-size: 22px
    margin-bottom: 24px

.join-workspace
  display: flex
  flex-flow: row nowrap
  justify-content: center
  margin-bottom: 24px

.join-controls
  display: flex

  &__accept
    margin-right: 12px
    flex: 1

  &__decline
    margin-left: 12px
    flex: 1

.block_declined
  text-align: center
  font-size: 20px
  font-weight: 300
</style>

import ConfirmTaskCompleteModal from '@/components/tasks/components/ConfirmTaskCompleteModal.vue'

export default {
  components: { ConfirmTaskCompleteModal },

  data () {
    return {
      taskComplete: {
        showConfirmation: false,
        isProcessing: false,
        stages: [],
        assignee: {}
      },
    }
  },

  methods: {
    checkNeedConfirmation (data, nextAssignee = null) {
      if (data.code === "NEED_CONFIRMATION") {
        const { stages = null, assignee = null } = data

        if (stages) {
          this.taskComplete.assignee = assignee || nextAssignee || {}
          this.taskComplete.stages = stages
          this.taskComplete.showConfirmation = true

          return true
        }
      }

      return false
    },

    onConfirmComplete ({ stageId, memberId, comment }) {
      this.taskComplete.isProcessing = true

      this.onChangeProgress('complete', true, { stage_id: stageId, member_id: memberId, comment })
        .finally(() => {
          this.taskComplete.isProcessing = false
          this.taskComplete.showConfirmation = false
        })
    },
  }
}

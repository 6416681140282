<template>
  <div class="report-page">
    <div class="report-page__header">
      <div class="report-page__title">
        {{ $t('report.title')}}
        <el-tooltip
            effect="dark"
            :content="$t('report.beta')"
            placement="right"
            transition="sw-slide-right"
        >
          <span class="beta">β</span>
        </el-tooltip>
      </div>
    </div>

    <div class="report-page__content">
      <div class="report-header">
        <div class="report-filters">
          <period-sprints-selector
            :sprints="pastSprints"
            :value="selectedSprints"
            @input="onSprintsSelected"
          />
        </div>

        <div class="report-actions">
          <ui-loading-button
            :loading="false"
            @click="onDownload"
            ui-class="light size_md"
            class="report-download"
            v-if="!noSprints"
          >
            <download-icon/>
            {{ $t('report.download') }}
          </ui-loading-button>
        </div>
      </div>

      <report-grid :report="report" :is-loading="isLoading" v-if="!noSprints"/>
      <div class="empty-report" v-else>
        <empty-report class="empty-report__illu"/>
        <div class="empty-report__content">
          <div class="empty-report__title">
            {{ $t('report.emptyTitle') }}
          </div>
          <p v-html="$t('report.emptyText')"></p>

          <ui-button ui-class="light" @click="goToTasks">
            {{ $t('sprints.goToTasks') }}
          </ui-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex"
import UiLoadingButton from '@/components/ui/UiLoadingButton'
import PeriodSprintsSelector from "@/components/report/PeriodSprintsSelector"
import DownloadIcon from "@/components/icons/DownloadIcon"
import ReportGrid from "@/components/report/ReportGrid"
import EmptyReport from "@/components/illustrations/EmptyReport"
import UiButton from 'scorework-ui/src/components/UiButton'


export default {
  name: 'ReportPage',
  components: {EmptyReport, ReportGrid, DownloadIcon, PeriodSprintsSelector, UiButton, UiLoadingButton},

  data () {
    return {
      selectedSprints: [],
      isLoading: false,
      noSprints: false,
    }
  },

  computed: {
    ...mapGetters(['project']),
    ...mapGetters('sprints', ['pastSprints']),
    ...mapGetters('report', ['report'])
  },

  methods: {
    ...mapActions('sprints', ['fetchPastSprints']),
    ...mapActions('report', ['fetchReport', 'downloadReport']),

    onSprintsSelected (sprints) {
      this.selectedSprints = sprints
      this.loadReport()
    },

    async loadReport () {
      this.isLoading = true
      try {
        await this.fetchReport({
          start_sprint_id: this.selectedSprints[0],
          end_sprint_id: this.selectedSprints[1] || this.selectedSprints[0]
        })
      } finally {
        this.isLoading = false
      }
    },

    async onDownload () {
      await this.downloadReport({
        start_sprint_id: this.selectedSprints[0],
        end_sprint_id: this.selectedSprints[1] || this.selectedSprints[0]
      })
    },

    goToTasks () {
      this.$router.push({ name: 'project.tasks', params: { project: this.project.code } })
    }
  },

  async created () {
    this.isLoading = true
    const sprints = await this.fetchPastSprints()
    if (sprints.length > 0) {
      this.selectedSprints = [sprints[sprints.length > 1 ? 1 : 0].id]
      this.loadReport()
      return
    }

    this.noSprints = true
    this.isLoading = false
  }
}
</script>
<style lang="sass">
  .report-page
    box-sizing: border-box
    display: flex
    flex-flow: column nowrap
    width: 100%

    &__header
      align-items: center
      display: flex
      justify-content: space-between
      background: var(--block-bg-color)
      padding: 18px 24px
      border-bottom: 1px solid var(--block-border-bg)

    &__title
      font-size: 22px
      font-weight: 500
      display: flex
      column-gap: 4px

      .beta
        font-size: 12px
        color: var(--color-yellow-text)
        background: var(--color-yellow-bg)
        padding: 2px 4px
        border-radius: 3px
        height: 16px
        cursor: default

    &__content
      flex: 1
      width: 100%
      box-sizing: border-box
      background: var(--block-bg-color)
      padding: 18px 24px

    &__empty
      display: flex
      justify-content: center
      align-items: center
      height: 200px
      text-align: center
      line-height: 1.7
      font-size: 16px

  .report-header
    display: flex
    align-items: center
    column-gap: 20px

  .report-download
    column-gap: 8px

    .path-stroke
      stroke: var(--primary-color)

  .empty-report
    display: flex
    align-items: center
    padding-top: 32px
    column-gap: 20px

    &__illu
      height: 150px

    &__title
      font-weight: 500
      font-size: 18px
      color: var(--text-primary-color)
      margin-bottom: 16px

    &__content
      font-size: 16px
      color: var(--text-light-color)

      p
        line-height: 1.7
</style>

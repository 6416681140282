<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" fill-rule="evenodd" clip-rule="evenodd" d="M13.2673 3.11667L13.4173 2.91667V2.66667V2V1.25H12.6673H3.33398H2.58398V2V8.66667V14.6667H4.08398V9.41667H12.6673H13.4173V8.66667V8V7.75L13.2673 7.55L11.6048 5.33333L13.2673 3.11667ZM10.0673 5.78333L11.6673 7.91667H4.08398V2.75H11.6673L10.0673 4.88333L9.72982 5.33333L10.0673 5.78333Z" fill="white"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'Flag',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

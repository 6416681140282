<template>
  <div class="feed-item">
    <div class="feed-item__line">
      <div class="feed-item__circle">
        <component :is="iconComponent" class="feed-item__circle-icon"/>
      </div>
    </div>

    <div class="feed-item__data">
      <div class="feed-item__head">
        <div class="feed-item__photo">
          <user-photo :user="item.member" size="sm" :show-online="false"/>
        </div>

        <div class="feed-item__author">
          {{ item.member.first_name }} {{ item.member.last_name }}
        </div>
        <div class="feed-item__date">
          {{ formattedDate.label }}
        </div>
      </div>

      <div class="feed-item__content">
        <div class="feed-item__action">
          {{ $t(`feed.types.${item.type}`) }}

          <span class="feed-item__stage" v-if="item.type === 'task.stage_changed'">
            {{ newStage }}
          </span>
        </div>

        <div class="feed-item__payload" v-if="item.type === 'task.score_changed'">
          <clock-icon class="feed-item__score-icon"/>
          <template v-if="item.payload.old">
            {{ formattedScore(item.payload.old) }}
            <right-arrow-icon class="feed-item__arrow"/>
          </template>
          {{ formattedScore(item.payload.new) }}
        </div>

        <div
          class="feed-item__task"
          @click="openTask"
          v-if="item.task"
        >
          <div class="feed-item__task-number">
            {{ taskNumber }}
          </div>
          <div class="feed-item__task-name">
            {{ item.task.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ClockIcon from '@/components/icons/ClockIcon.vue'
  import CommentsIcon from '@/components/icons/CommentsIcon.vue'
  import FireIcon from '@/components/icons/FireIcon.vue'
  import Flag from '@/components/icons/Flag.vue'
  import ForwardIcon from '@/components/icons/ForwardIcon.vue'
  import PauseNarrowIcon from '@/components/icons/PauseNarrowIcon.vue'
  import PlayOutlineIcon from '@/components/icons/PlayOutlineIcon.vue'
  import RightArrowIcon from '@/components/icons/RightArrowIcon.vue'
  import TargetIcon from '@/components/icons/TargetIcon.vue'
  import UserPhoto from '@/components/ui/UserPhoto.vue'
  import { getPastDate, scoreToLabel } from '@/services/scoring'

  export default {
    name: 'FeedItem',

    components: {
      ClockIcon, CommentsIcon, FireIcon, Flag, ForwardIcon, PauseNarrowIcon, PlayOutlineIcon, RightArrowIcon, TargetIcon, UserPhoto
    },

    props: {
      item: {
        type: Object,
        required: true,
      }
    },

    computed: {
      ...mapGetters(['workspace', 'stages']),

      taskNumber () {
        return this.workspace.task_prefix
            ? `#${this.workspace.task_prefix}-${this.item.task.number}`
            : `#${this.item.task.number}`
      },

      iconComponent () {
        switch (this.item.type) {
          case 'task.comment.added':
            return 'comments-icon'

          case 'task.progress.started':
            return 'play-outline-icon'

          case 'task.progress.paused':
            return 'pause-narrow-icon'

          case 'task.score_changed':
            return 'clock-icon'

          case 'task.assigned':
            return 'target-icon'

          case 'task.progress.overdue':
            return 'fire-icon'

          case 'task.stage_changed':
            return 'forward-icon'
        }

        return 'flag'
      },

      formattedDate () {
        return getPastDate(this.item.createdAt)
      },

      actionTitle () {
        if (this.item.type === 'task.stage_changed') {
          const stage = this.stages.find(item => item.id === this.item.payload.new)

          return this.$t(`feed.types.${this.item.type}`, { stage: stage.name })
        }

        return this.$t(`feed.types.${this.item.type}`)
      },

      newStage () {
        if (this.item.type === 'task.stage_changed') {
          const stage = this.stages.find(item => item.id === this.item.payload.new)

          return stage ? stage.name : ''
        }

        return ''
      }
    },

    methods: {
      openTask () {
        this.$emit('open-task', this.item.task.id)
      },

      formattedScore (score) {
        return scoreToLabel(score)
      }
    }
  }
</script>
<style lang="sass">
  .feed-list
    max-height: 550px
    overflow: auto

    .feed-item:last-child
      .feed-item__line:after
        display: none

  .feed-item
    display: flex

    &__line
      position: relative
      width: 24px

      &:after
        content: ""
        position: absolute
        top: 0
        bottom: 0
        width: 2px
        background: var(--color-grey-hover)
        left: 50%
        transform: translateX(-50%)

    &__circle
      width: 24px
      height: 24px
      border-radius: 12px
      background: var(--color-blue-dots)
      display: flex
      align-items: center
      justify-content: center
      position: absolute
      z-index: 100

      &-icon
        width: 16px
        height: 16px

      .path-fill
        fill: #ffffff

      .path-stroke
        stroke: #ffffff

    &__data
      margin-left: 8px
      flex: 1

    &__head
      align-items: center
      display: flex
      font-size: 13px

    &__author
      font-weight: 500
      margin-left: 8px

    &__date
      color: var(--text-light-color)
      margin-left: 8px

    &__action
      display: flex
      margin-top: 4px

    &__stage
      color: var(--primary-color)
      font-weight: 500
      margin-left: 4px

    &__content
      font-size: 13px
      margin-left: 32px
      padding-bottom: 16px

    &__task
      cursor: pointer
      align-items: center
      display: inline-flex
      margin-top: 8px
      border: 1px solid var(--color-blue-hover)
      background: var(--color-grey-bg)
      border-radius: 4px
      padding: 4px 8px
      color: var(--primary-color)

      &:hover
        color: var(--primary-color-hover)

        .feed-item__task-number
          color: var(--text-primary-color)

      &-number
        color: var(--text-light-color)
        margin-right: 4px

      &-name
        font-weight: 500

    &__payload
      align-items: center
      display: flex
      margin-top: 8px
      color: var(--text-light-color)

    &__arrow
      width: 16px
      height: 16px
      margin: 0 6px

    &__score-icon
      width: 14px
      height: 14px
      margin-right: 6px

      .path-stroke
        stroke: var(--text-light-color)
</style>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.00008 1.16699C3.78358 1.16699 1.16675 3.78383 1.16675 7.00033C1.16675 10.2168 3.78358 12.8337 7.00008 12.8337C10.2166 12.8337 12.8334 10.2168 12.8334 7.00033C12.8334 3.78383 10.2166 1.16699 7.00008 1.16699ZM7.00008 11.667C4.427 11.667 2.33341 9.57341 2.33341 7.00033C2.33341 4.42724 4.427 2.33366 7.00008 2.33366C9.57317 2.33366 11.6667 4.42724 11.6667 7.00033C11.6667 9.57341 9.57317 11.667 7.00008 11.667Z" fill="#5B6372"/>
    <path d="M8.65033 8.65002C8.4371 8.86255 8.18526 9.03243 7.90833 9.15052C7.40975 9.36101 6.85354 9.39174 6.33481 9.23745C5.81608 9.08316 5.36707 8.75344 5.06458 8.30469L4.09741 8.95744C4.34967 9.32968 4.67059 9.65041 5.043 9.90244C5.42196 10.1587 5.84775 10.3377 6.296 10.4292C6.76079 10.524 7.23995 10.524 7.70475 10.4292C8.15295 10.3376 8.57872 10.1586 8.95775 9.90244C9.14033 9.77877 9.31475 9.63527 9.47458 9.47602C9.63325 9.31794 9.77791 9.14294 9.90275 8.95744L8.93558 8.30469C8.85174 8.42864 8.75622 8.54428 8.65033 8.65002V8.65002Z" fill="#5B6372"/>
    <path d="M4.95825 7C5.4415 7 5.83325 6.60825 5.83325 6.125C5.83325 5.64175 5.4415 5.25 4.95825 5.25C4.475 5.25 4.08325 5.64175 4.08325 6.125C4.08325 6.60825 4.475 7 4.95825 7Z" fill="#5B6372"/>
    <path d="M9.03766 6.99183C9.51866 6.99183 9.90858 6.60191 9.90858 6.12092C9.90858 5.63992 9.51866 5.25 9.03766 5.25C8.55667 5.25 8.16675 5.63992 8.16675 6.12092C8.16675 6.60191 8.55667 6.99183 9.03766 6.99183Z" fill="#5B6372"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'SmileIcon',

    mixins: [iconMixin],

    props: {
      viewBox: {
        type: String,
        default: '0 0 12 12'
      }
    },

    data () {
      return {
        defaultBackground: '#416AF7',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

<template>
  <div class="messenger">
    <div class="messenger__channels">
      <div class="messenger__channels-content">
        <div class="messenger__channels-title">
          <div class="messenger__channels-title-text">
            {{ $t('messenger.messages') }}
          </div>

          <el-tooltip
            popper-class="sidebar-menu__tooltip"
            effect="dark"
            :content="$t('messenger.createNewChat')"
            placement="right"
            transition="sw-slide-right"
          >
            <ui-button ui-class="light size_md" @click="isNewChannelOpened = true">
              <plus background="#18A0FC"/>
            </ui-button>
          </el-tooltip>
        </div>

        <channel-list v-if="isChannelsLoaded"/>
      </div>
    </div>

    <router-view v-if="isChannelsLoaded"></router-view>

    <new-channel-modal
      v-if="isNewChannelOpened"
      @close="isNewChannelOpened = false"
    />
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex"
import NewChannelModal from "@/components/messenger/NewChannelModal"
import Plus from "@/components/icons/Plus"
import UiButton from 'scorework-ui/src/components/UiButton'
import ChannelList from "@/components/messenger/ChannelList"

const { mapGetters: messengerGetters } = createNamespacedHelpers('messenger')

export default {
  name: 'MessengerLayout',

  components: { ChannelList, NewChannelModal, UiButton, Plus },

  data () {
    return {
      isNewChannelOpened: false
    }
  },

  computed: {
    ...messengerGetters(['isChannelsLoaded'])
  }
}
</script>

import apiService from '@/services/api';
import { sortTasks, transformTask, transformTaskItem } from '@/transformers/tasks';
import { sortByField } from '@/utils/arrays';

export const namespaced = true

export const state = {
  membersTasks: {},

  filters: {
    sort: 'estimatedFinishAt_desc',
    spheres: []
  }
}

export const mutations = {
  SET_ALL_MEMBER_TASKS (state, membersTasks) {
    state.membersTasks = membersTasks.reduce((res, { member_id, tasks }) => {
      sortTasks(tasks)
      res[member_id] = tasks
      return res
    }, {})
  },

  REFRESH_MEMBER_TASKS (state, { memberId, tasks }) {
    const memberTasks = tasks.reduce((newTasks, task) => {
      const foundIndex = newTasks.findIndex(item => item.id === task.id)
      if (foundIndex !== -1) {
        newTasks.splice(foundIndex, 1, task)
      } else {
        newTasks.push(task)
      }

      return newTasks
    }, state.membersTasks[memberId] || [])

    sortTasks(memberTasks)

    state.membersTasks = {
      ...state.membersTasks,
      [memberId]: memberTasks,
    }
  },

  REFRESH_TASK (state, { memberId, task }) {
    const memberTasks = state.membersTasks[memberId] || []

    state.membersTasks = {
      ...state.membersTasks,
      [memberId]: memberTasks.map(item => {
        if (item.id !== task.id) {
          return item
        }

        const { name, target, target_id, sphere, sphere_id } = task

        return {
          ...item,
          name, target, target_id, sphere, sphere_id,
        }
      })
    }
  },

  REMOVE_TASK (state, taskId) {
    state.membersTasks = Object.keys(state.membersTasks).reduce((membersTasks, memberId) => {
      membersTasks[memberId] = state.membersTasks.filter(task => task.id !== taskId)
      return membersTasks
    }, {})
  },

  SET_FILTERS (state, filters) {
    state.filters = filters
  },

  SET_TASK_DRAGGED (state, { taskId, assigneeId, stageId }) {
    state.membersTasks = { ...state.membersTasks, [assigneeId]: state.membersTasks[assigneeId].map(task => {
      task.dragged  = taskId === task.id
      task.muted    = task.stage_id !== stageId

      return task
    })}
  },

  SET_TASK_UNDRAGGED (state, { assigneeId }) {
    state.membersTasks = { ...state.membersTasks, [assigneeId]: state.membersTasks[assigneeId].map(task => {
        task.dragged  = false
        task.muted    = false

        return task
      })}
  },

}

export const actions = {
  async fetchWorkload ({ commit, rootGetters }) {
    const { project } = rootGetters
    const { data } = await apiService.get(`projects/${project.code}/workload`)

    const performFunc = transformTaskItem(rootGetters)
    commit('SET_ALL_MEMBER_TASKS', data.map(item => ({ ...item, tasks: item.tasks.map(performFunc) })))
  },

  refreshTask ({ commit, rootGetters }, { task }) {
    const transformedTask = transformTask(task, rootGetters)
    if (!transformedTask.state.assignee.member_id) {
      return
    }

    commit('REFRESH_TASK', {
      task: transformedTask,
      memberId: transformedTask.state.assignee.member_id
    })
  },

  refreshTasks ({ commit, rootGetters }, { tasks }) {
    const performFunc = transformTaskItem(rootGetters)

    const membersTasks = tasks.reduce((res, task) => {
      if (!task.state.assignee_id) {
        return res
      }

      if (!res[task.state.assignee_id]) {
        res[task.state.assignee_id] = []
      }

      res[task.state.assignee_id].push(performFunc(task))

      return res
    }, {})

    Object.keys(membersTasks).forEach(memberId => {
      commit('REFRESH_MEMBER_TASKS', { memberId, tasks: membersTasks[memberId] })
    })
  },

  removeTask ({ commit }, { taskId }) {
    commit('REMOVE_TASK', taskId)
  },

  setFilters ({ commit }, filters) {
    commit('SET_FILTERS', filters)
  },

  dragTask ({ commit }, { taskId, assigneeId, stageId }) {
    commit('SET_TASK_DRAGGED', { taskId, assigneeId, stageId })
  },

  endDragTask ({ commit }, { assigneeId }) {
    commit('SET_TASK_UNDRAGGED', { assigneeId })
  },

  sortTask ({ rootGetters }, { taskId, targetTaskId, position }) {
    const { project } = rootGetters
    return apiService.post(`projects/${project.code}/tasks/${taskId}/sort`, {
      target_task_id: targetTaskId,
      position,
    })
  },
}

export const getters = {
  membersTasks: ({ membersTasks }) => membersTasks,

  filters: ({ filters }) => filters,

  filteredMembers: ({ membersTasks, filters }, getters, rootState, { activeMembers }) => {
    const { sort, spheres } = filters
    const [sortField, sortDirection] = sort.split('_')

    const sortedMembers = Object.keys(membersTasks)
      .filter(memberId => {
        if (spheres.length === 0) {
          return true
        }

        const member = activeMembers.find(item => item.member_id === Number(memberId))
        if (!member) {
          return false
        }

        return member.spheres.findIndex(sphere => spheres.indexOf(sphere.id) !== -1) !== -1
      })
      .map(memberId => {
        const tasks = membersTasks[memberId]

        const estimatedFinishAt = tasks.length > 0
          ? tasks[tasks.length - 1].estimated_finish_at
          : '0'

        return {
          memberId: Number(memberId),
          estimatedFinishAt,
          tasksCount: tasks.length,
        }
      })

    sortedMembers.sort(sortByField(sortField, sortDirection))

    return sortedMembers.map(({ memberId }) => memberId)
  }
}

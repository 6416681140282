<template>
  <div class="workload-member-tasks">
    <transition-group name="zoom" tag="div" v-draggable-list="draggableList" style="position: relative" appear>
      <board-task-item
        v-for="(task, index) in tasks"
        :key="task.id"
        :task="task"
        :editable="true"
        :index="index"
        :draggable="true"
        :drag-list="draggableList.name"
        @open="onOpenTask(task.id)"
        @create="onCreateAfterTask"
      />
    </transition-group>

    <div class="workload-member-tasks__empty" v-if="tasks.length === 0">
      <ui-button ui-class="light" @click="onCreateTask">
        <plus class="mr-2"/> {{ $t('workload.createNewTask') }}
      </ui-button>
    </div>
  </div>
</template>
<script>
  import UiButton from 'scorework-ui/src/components/UiButton'

  import BoardTaskItem from '@/components/tasks/BoardTaskItem'
  import Plus from '@/components/icons/Plus'
  import { sortByField } from '@/utils/arrays'
  import { createNamespacedHelpers } from 'vuex';

  const { mapActions: workloadActions } = createNamespacedHelpers('workload')
  const trashHolder = document.createElement('div')

  export default {
    name: 'WorkloadMemberTasks',

    components: { BoardTaskItem, UiButton, Plus },

    props: {
      member: {
        type: Object,
        required: true,
      },

      tasks: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        draggableList: {
          name: `member-tasks-${this.member.member_id}`,
          callbacks: {
            onDragEnd:    this.onDragEnd,
            onDragStart:  this.onDragStart,
            onAfterItem:  this.onAfterItem,
            onBeforeItem: this.onBeforeItem,
            onLeaveList:  this.onLeaveList
          },
          options: {
            hideElement: true,
            findUnderlyingEl: this.findUnderlyingEl,
            externalDropLists: []
          }
        },
      }
    },

    methods: {
      ...workloadActions(['dragTask', 'endDragTask', 'sortTask']),

      onDragStart (listEl, el, item) {
        if (el) {
          this.setupHolder(el)
          el.after(trashHolder)
        } else {
          this.isActiveDropZone = true
        }

        this.dragTask({ taskId: item.id, assigneeId: item.state.assignee_id, stageId: item.stage_id })
      },

      onDragEnd (listEl, item, targetItem, position) {
        this.clearDraggingChanges(listEl)

        if (targetItem && targetItem.id !== item.id) {
          this.sortTask({ taskId: item.id, assigneeId: item.state.assignee_id, targetTaskId: targetItem.id, position })
        }
      },

      onLeaveList (listEl) {
        this.clearDraggingChanges(listEl)
      },

      onAfterItem (listEl, targetEl) {
        if (!listEl.contains(trashHolder)) {
          this.setupHolder(targetEl)
        }

        listEl.insertBefore(trashHolder, targetEl.nextSibling)
      },

      onBeforeItem (listEl, targetEl) {
        if (!listEl.contains(trashHolder)) {
          this.setupHolder(targetEl)
        }

        listEl.insertBefore(trashHolder, targetEl)
      },

      clearDraggingChanges (listEl) {
        if (listEl.contains(trashHolder)) {
          listEl.removeChild(trashHolder)
        }

        this.endDragTask({ assigneeId: this.member.member_id })
        this.isActiveDropZone = false
      },

      findUnderlyingEl (underlyingItem, tasks, position) {
        if (underlyingItem.item.muted) {

          const item = underlyingItem.item

          const direction = position === 'before' ? 'desc' : 'asc'
          const sortFunc = sortByField('task_date', direction)

          const unMutedItems = tasks.filter(task => !task.item.muted)
              .sort((a, b) => sortFunc(a.item, b.item))

          //console.log(position, unMutedItems.map(t => t.item.id), unMutedItems.map(t => t.item.dragged))
          if (unMutedItems.length === 0) {
            return {
              item: underlyingItem,
              position
            }
          }

          const foundItem = unMutedItems.find(task => {
            if (position === 'before') {
              return task.item.task_date <= item.task_date
            }

            return task.item.task_date >= item.task_date
          })

          if (!foundItem) {
            return {
              item: unMutedItems.pop(),
              position: position
            }
          }

          //console.log(position, [foundItem.item.id, foundItem.item.task_date].join(', '), unMutedItems.map(t => [t.item.id, t.item.task_date].join(', ')))

          if (foundItem.item.task_date === item.task_date) {
            if (position === 'after' && item.id < foundItem.item.id) {
              position = 'before'
            } else if (position === 'before' && item.id > foundItem.item.id) {
              position = 'after'
            }
          }

          return {
            item: foundItem,
            position: position === 'before' ? 'after' : 'before'
          }
        }

        return {
          item: underlyingItem,
          position
        }
      },

      setupHolder (el) {
        trashHolder.className = 'board-task-item-trash-holder'
        trashHolder.style.height = el.querySelector('.board-task-item__block').offsetHeight + 'px'
      },

      onOpenTask (id) {
        this.$emit('open-task', id)
      },

      onCreateAfterTask (task) {
        this.$emit('create-after', task)
      },

      onCreateTask () {
        this.$emit('create')
      }
    }
  }
</script>
<style lang="sass">
  .workload-member-tasks
    padding: 18px
    flex: 1
    overflow: auto

    &__empty
      align-items: center
      display: flex
      height: 110px
      justify-content: center

      &-text
        margin-bottom: 12px
</style>

<template>
  <label class="ui-switch" :class="switchClasses">
    <input type="checkbox" v-model="val" @change="onChange" class="ui-switch__input"/>
    <div class="ui-switch__circle"></div>
    <div class="ui-switch__field-false">
      <slot name="false-icon"></slot>
    </div>
    <div class="ui-switch__field-true">
      <slot name="true-icon"></slot>
    </div>
  </label>
</template>
<script>
export default {
  name: 'UiSwitch',

  props: {
    value: {
      required: true
    },

    trueValue: {
      default: true
    },

    falseValue: {
      default: false
    }
  },

  data () {
    return {
      val: this.value === this.trueValue
    }
  },

  computed: {
    switchClasses () {
      return {
        'ui-switch_active': this.val
      }
    }
  },

  methods: {
    onChange () {
      this.$emit('input', this.val ? this.trueValue : this.falseValue)
    }
  }
}
</script>
<style lang="sass">
.ui-switch
  display: flex
  justify-content: space-between
  align-items: center
  height: 28px
  background: var(--grey-light-color-60)
  border-radius: 14px
  width: 48px
  cursor: pointer
  position: relative
  transition: all ease-in .2s

  &_active
    background: var(--primary-color)

    .ui-switch__circle
      left: 100%
      margin-left: -24px

  &__circle
    background: #FFFFFF
    width: 20px
    height: 20px
    border-radius: 10px
    position: absolute
    left: 4px
    top: 4px
    transition: all .2s ease-in
    box-shadow: 0px 1px 4px rgba(34, 49, 85, 0.15)

  &__input
    display: none

  &__field-false
    flex: 1
    display: flex
    justify-content: flex-end
    padding-right: 4px
    box-sizing: border-box

  &__field-true
    flex: 1
    display: flex
    justify-content: flex-start
    padding-left: 4px
    box-sizing: border-box

  &__icon
    .path-fill
      fill: var(--text-light-color)
</style>

<template>
  <div>
    <div class="settings-head">
      <div class="settings-title">
        {{ $t('stages.workspaceStages') }}
      </div>
      <div class="settings-description" v-html="$t('stages.description')"></div>
    </div>

    <div class="stage-levels">
      <div class="stage-level">
        <div class="stage-level-col stage-level-col_default">
          <stage-item
            :stage="initStage"
            :sphere-id="0"
            :hint-content="$t('stages.initialStageHint')"
            @update="onUpdateStage"
          />

          <div v-if="intermediateStages.length > 1" class="stage-level-edge stage-level-edge_after stage-level-edge_no-bottom">
            <div class="stage-level-edge__line"></div>
          </div>
          <div v-else class="stage-level-empty-edge"></div>

        </div>
      </div>

      <div class="stage-level-delim" :style="delimStyle"></div>

      <div class="stage-level intermediate-stages">
        <div
          class="stage-level-col"
          v-for="(stageSphere, idx) in intermediateStages"
          :key="stageSphere.sphereId"
        >
          <div class="stage-level-edge stage-level-edge_before">
            <div class="stage-level-edge__line"></div>
          </div>

          <stage-sphere-item
            :stage-sphere="stageSphere"
            :init-stage="initStage"
            :number="idx"
            @update="onUpdateStage"
            @create="onCreateStage"
            @delete="onDeleteStage"
          />
          <div class="stage-level-edge stage-level-edge_after stage-level-edge_flex">
            <div class="stage-level-edge__line"></div>
          </div>

        </div>

        <div class="new-stage-block-wrap" v-if="availableSpheres.length > 0">
          <div class="new-stage-block">
            <div class="new-stage-block__descr">
              {{ $t('stages.newStagesDescription') }}
            </div>

            <ui-button ui-class="block" @click="onCreateStageForNewSphere">{{ $t('stages.addStages') }}</ui-button>
          </div>
        </div>
      </div>

      <div class="stage-level-delim" :style="delimStyle"></div>

      <div class="stage-level">
        <div class="stage-level-col stage-level-col_default">
          <div v-if="intermediateStages.length > 1" class="stage-level-edge stage-level-edge_before stage-level-edge_no-top">
            <div class="stage-level-edge__line"></div>
          </div>
          <div v-else class="stage-level-empty-edge"></div>

          <div class="stage-item">
            <div class="stage-item__label">
              <div class="stage-item__title">
                {{ $t('stages.completed') }}
              </div>

              <el-tooltip class="item" effect="dark" placement="right" :manual="true" v-model="showFinishedHint">
                <div slot="content" v-html="$t('stages.finishedHint')"></div>
                <div class="stage-item__hint" @click="showFinishedHint = !showFinishedHint">
                  <info-icon/>
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>

    <stage-modal-form
      :id="stageForm.id"
      :default-values="stageForm.defaultValues"
      :is-sphere-select="stageForm.isSphereSelect"
      v-if="showStageForm"
      @close="showStageForm = false"
    />

    <stage-delete-modal
      :stage="deletingStage"
      v-if="showDeleteForm"
      @close="showDeleteForm = false"
    />
  </div>
</template>
<script>
import UiButton from 'scorework-ui/src/components/UiButton'

import InfoIcon from "@/components/icons/InfoIcon"
import StageSphereItem from "../../components/settings/StageSphereItem"
import StageItem from "../../components/settings/StageItem"
import StageModalForm from "../../components/stages/StageModalForm"
import StageDeleteModal from "../../components/stages/StageDeleteModal"

import { createNamespacedHelpers, mapGetters } from "vuex"
import pageTitleMixin from "@/views/mixins/pageTitleMixin"
import authUserMixin from "@/mixins/authUserMixin"

const { mapGetters: stagesGetters, mapActions: stagesActions } = createNamespacedHelpers('stages')

export default {
  name: 'StageSettings',

  components: { StageSphereItem, StageItem, StageModalForm, InfoIcon, UiButton, StageDeleteModal },

  mixins: [pageTitleMixin, authUserMixin],

  data () {
    return {
      showStageForm: false,
      stageForm: {
        id: 0,
        defaultValues: {},
        isSphereSelect: false
      },

      showDeleteForm: false,
      deletingStage: null,
      showFinishedHint: false
    }
  },

  computed: {
    ...mapGetters(['workspace', 'spheres', 'project']),
    ...stagesGetters(['initStage', 'intermediateStages']),

    pageTitle () {
      return {
        message: 'pageTitles.settings.stages',
        params: { workspace: this.workspace.name }
      }
    },

    delimStyle () {
      return {
        width: (284 + 24) * (this.intermediateStages.length - 1) + 'px'
      }
    },

    availableSpheres () {
      const usedSpheres = this.intermediateStages.map(item => item.sphereId)

      return this.spheres.filter(item => !item.is_child && usedSpheres.indexOf(item.id) === -1)
    }
  },

  methods: {
    ...stagesActions(['fetchStages']),

    onUpdateStage ({ id }) {
      this.stageForm.id = id
      this.stageForm.isSphereSelect = false
      this.showStageForm = true
    },

    onCreateStage (defaultValues) {
      this.stageForm.id = 0
      this.stageForm.isSphereSelect = false
      this.stageForm.defaultValues = defaultValues

      this.showStageForm = true
    },

    onCreateStageForNewSphere () {
      this.stageForm.id = 0
      this.stageForm.isSphereSelect = true
      this.stageForm.defaultValues = { previous_stage_id: this.initStage.id }

      this.showStageForm = true
    },

    onDeleteStage (stage) {
      this.deletingStage = stage
      this.showDeleteForm = true
    }
  },

  created () {
    if (!this.isProjectAdmin) {
      this.$router.push({ name: 'workspace.member.settings' , params: { project: this.project.code} })
      return
    }

    this.fetchStages({})
  }
}
</script>
<style lang="sass">
.settings-head
  display: flex
  flex-flow: column

  .settings-title
    align-self: flex-start
    position: sticky
    left: 24px


.settings-description
  color: var(--text-light-color)
  font-size: 16px
  align-self: flex-start
  margin-bottom: 36px
  position: sticky
  left: 24px
  line-height: 1.5
  max-width: 700px

  p
    margin: 8px 0

.stage-level
  display: flex
  flex-flow: row nowrap
  align-items: flex-start

.stage-level-col
  margin-right: 24px
  width: 284px
  box-sizing: border-box
  display: flex
  flex-flow: column
  align-self: stretch

  &_default
    position: sticky
    left: 24px

.stage-level-edge
  min-height: 30px
  width: 100%
  position: relative

  &_after
    margin-top: 20px

  &_no-top
    &::before
      display: none

  &_before
    margin-bottom: 20px

  &_no-bottom
    &::after
      display: none

  &_flex
    flex: 1 auto

  &::after, &::before
    content: ""
    position: absolute
    background: var(--grey-light-color-100)
    width: 5px
    height: 5px
    left: 50%

  &::before
    top: 0
    transform: translateX(-2px) translateY(-3px) rotate(45deg)

  &::after
    bottom: 0
    transform: translateX(-2px) translateY(3px) rotate(45deg)

  &__line
    position: absolute
    top: 0
    bottom: 0
    left: 50%
    width: 1px
    background: var(--grey-light-color-100)

.stage-level-empty-edge
  height: 20px

.stage-level-delim
  height: 1px
  background: var(--grey-light-color-100)
  margin-left: 142px
  width: 0

.new-stage-block-wrap
  padding-right: 24px

.dark
  .new-stage-block
    background: var(--block-bg-color2)

.new-stage-block
  margin-top: 50px
  padding: 24px 12px
  background: var(--grey-light-color-24)
  border-radius: 16px
  box-sizing: border-box
  width: 284px
  flex: 1 0 284px

  &__descr
    padding-bottom: 24px
    font-size: 14px
    color: var(--grey-color-100)
    text-align: center

.stage-levels
  width: 100%
  padding-bottom: 24px

.intermediate-stages
  .stage-level-col
    flex: 0 0 284px
</style>

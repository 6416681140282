import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import Document from '@tiptap/extension-document'

export const SingleDocument = Document.extend({
  name: 'doc',
  topNode: true,
  content: 'block',
})

export const EnterHandler = Extension.create({
  name: 'enterHandler',
  addProseMirrorPlugins () {
    const handler = this.options.handler
    return [
        new Plugin({
          key: new PluginKey('enterHandler'),
          props: {
            handleKeyDown (view, e) {
              if (e.key === 'Enter' && !e.shiftKey) {
                handler()

                return true
              }

              return false
            }
          }
        })
    ]
  }
})

<template>
  <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.64152 4.91412H1.14513C1.0458 4.61206 0.995373 4.29606 0.995768 3.97809V3.4832C0.995768 2.5594 1.36275 1.67343 2.01597 1.0202C2.6692 0.366979 3.55516 0 4.47896 0H5.97461C6.76689 0 7.52672 0.314733 8.08695 0.874961C8.64718 1.43519 8.96191 2.19502 8.96191 2.9873C8.96191 3.2514 8.857 3.50468 8.67026 3.69142C8.48351 3.87816 8.23024 3.98307 7.96614 3.98307C7.70205 3.98307 7.44877 3.87816 7.26203 3.69142C7.07529 3.50468 6.97038 3.2514 6.97038 2.9873C6.97038 2.72321 6.86546 2.46993 6.67872 2.28319C6.49198 2.09645 6.2387 1.99154 5.97461 1.99154H4.47896C3.65447 1.99154 2.9873 2.6587 2.9873 3.4832V3.97809C2.98717 4.18238 3.04987 4.38177 3.1669 4.54921C3.28393 4.71665 3.44963 4.84404 3.64152 4.91412V4.91412ZM8.80558 7.00523C8.90714 7.30495 8.96191 7.62659 8.96191 7.96116V8.48693C8.96191 9.41865 8.59179 10.3122 7.93296 10.971C7.27413 11.6299 6.38057 12 5.44884 12H3.98307C3.19079 12 2.43096 11.6853 1.87073 11.125C1.3105 10.5648 0.995768 9.80498 0.995768 9.0127V8.96191C0.995768 8.69782 1.10068 8.44454 1.28742 8.2578C1.47416 8.07105 1.72744 7.96614 1.99154 7.96614C2.25563 7.96614 2.50891 8.07105 2.69565 8.2578C2.88239 8.44454 2.9873 8.69782 2.9873 8.96191V9.0117C2.9873 9.27579 3.09222 9.52907 3.27896 9.71581C3.4657 9.90256 3.71898 10.0075 3.98307 10.0075H5.44884C6.28927 10.0075 6.97038 9.32736 6.97038 8.48693V7.96116C6.97038 7.74571 6.90049 7.53607 6.77122 7.3637C6.64195 7.19134 6.46026 7.06555 6.25342 7.00523H8.80558ZM0.497884 5.48668H9.4598C9.59184 5.48668 9.71848 5.53914 9.81185 5.63251C9.90522 5.72588 9.95768 5.85252 9.95768 5.98457C9.95768 6.11661 9.90522 6.24325 9.81185 6.33662C9.71848 6.42999 9.59184 6.48245 9.4598 6.48245H0.497884C0.365837 6.48245 0.239198 6.42999 0.145827 6.33662C0.0524556 6.24325 0 6.11661 0 5.98457C0 5.85252 0.0524556 5.72588 0.145827 5.63251C0.239198 5.53914 0.365837 5.48668 0.497884 5.48668V5.48668Z" fill="#333840"/>
  </svg>
</template>
<script>
import iconMixin from '../iconMixin'

export default {
  name: 'StrokeIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

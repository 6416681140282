<template>
  <div class="message-form messages-wrapper">
    <div class="message-form__input">
      <editor-content :editor="editor" class="message-form-input"/>
      <suggestion-popover :suggestion="suggestion" @select="selectSuggestion" ref="suggestions"/>

      <div class="message-form__options">
        <el-popover placement="top" popper-class="sw-popover sw-popover_no-border">
          <picker
            :data="emojiIndex"
            :per-line="9"
            :native="true"
            :emoji-size="25"
            set="messenger"
            title="Pick your emoji..."
            color="#638FFF"
            emoji="point_up"
            @select="emj => editor.commands.emoji({ id: emj.id, label: emj.native })"
          />
          <button class="message-form__option-btn" slot="reference">
            <smile-icon class="message-form__option-icon"/>
          </button>
        </el-popover>
      </div>
    </div>
    <ui-button class="message-form__btn" @click="onSendMessage"><send-icon/></ui-button>
    <div class="message-form__typing-members" v-show="typingMembersText.length > 0">
      {{ typingMembersText }}
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { Editor, EditorContent } from '@tiptap/vue-2'
import HardBreak from '@tiptap/extension-hard-break'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import { throttle } from 'throttle-debounce'

import { SingleDocument, EnterHandler } from "@/components/ui/textEditor/marks"
import withSuggestions from "@/components/ui/textEditor/withSuggestions"
import withEmojis from "@/components/ui/textEditor/withEmojis"

import UiButton from 'scorework-ui/src/components/UiButton'
import SendIcon from "@/components/icons/SendIcon"
import SmileIcon from "@/components/icons/SmileIcon"

const { mapGetters: messengerGetters } = createNamespacedHelpers('messenger')
const { mapActions: messengerActions } = createNamespacedHelpers('messenger')

export default {
  name: 'MessageForm',

  components: { EditorContent, SendIcon, SmileIcon, UiButton },

  mixins: [withEmojis, withSuggestions],

  props: {
    channel: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      message: '',
      editor: new Editor({
        useBuiltInExtensions: false,
        extensions: [
          SingleDocument,
          Text,
          Paragraph,
          HardBreak,
          EnterHandler.configure({
            handler: () => this.onSendMessage()
          }),
          // new Emoji({
          //   matcher: {
          //     char: ":",
          //   },
          //   mentionClass: 'emoji',
          //   items: () => [],
          //   onEnter: props => this.onSuggestionEnter('emoji', props),
          //   onChange: props => this.onEmojiListChange(props),
          //   onExit: () => this.onSuggestionExit(),
          //   onKeyDown: props => this.onSuggestionKeyDown(props),
          //   onFilter: (_, query) => query ? this.emojiIndex.search(query).slice(0, 5) : [],
          // })
        ],
        emptyDocument: {
          type: 'doc',
          content: [{
            'type': 'paragraph'
          }]
        },
        content: '',
        onUpdate: () => {
          this.onTypeMessage()
        },
      }),
      emojiIndex: this.newEmojiIndex(null, {
        exclude: ['symbols', 'objects']
      }),
      throttleMessageTyping: throttle(1000, (data) => {
        this.typeMessage(data);
      }),
      typingMembersText: '',
    }
  },

  computed: {
    ...mapGetters(['authUser', 'members', 'membersByIds']),
    ...messengerGetters(['typingMembers', 'helpdeskAuthor']),
    suggestionEl () {
      return this.$refs.suggestions.$el
    }
  },

  watch: {
    typingMembers(members) {
      this.typingMembersText = this.getTypingMembersText(members)
    },
  },

  methods: {
    ...messengerActions(['createMessage', 'typeMessage']),

    transformMessage (htmlText) {
      let message = htmlText.replace(/<br ?\/?>/ig, "#br#")

      const div = document.createElement("div")
      div.innerHTML = message

      message = div.innerText || ""
      message = message.replace(/#br#/ig, "\n")

      return message
    },

    getTypingMembersText(memberIds) {
      let result = '';

      const memberNames = memberIds.reduce((result, memberId) => {
        let member;
        if (memberId === -1) {
          member = this.helpdeskAuthor
        } else {
          member = this.members.find(item => item.member_id === memberId)
        }

        if (!member) {
          return result
        }

        result.push(`${member.first_name} ${member.last_name}`)
        return result
      }, [])

      if (memberNames.length > 0) {
        result = `${memberNames.join(', ')} ${memberIds.length > 1 ? this.$t('messenger.areTyping') : this.$t('messenger.isTyping')}`
      }

      return result
    },

    onTypeMessage() {
      this.throttleMessageTyping({ channel: this.channel, memberId: this.authUser.member_id })
    },

    onSendMessage () {
      const payload = this.transformMessage(this.editor.getHTML()).trim()

      if (!payload) {
        return
      }

      const message = {
        type: 'text',
        payload
      }

      this.createMessage({ channel: this.channel, message: { message } })
      this.editor.commands.clearContent(true)
    }
  },

  beforeDestroy() {
    this.editor.destroy()
  }
}
</script>
<style lang="sass">
.message-form__typing-members
  position: absolute
  color: var(--text-light-color)
  font-size: 12px
  padding-left: 8px
  padding-bottom: 4px
  bottom: 0
  max-width: 848px
  margin: 0 auto
</style>

<template>
  <el-dialog
      :title="modalTitle"
      :visible="true"
      width="480px"
      @close="onClose"
  >
    <div v-if="!isLoading">
      <ui-form :model="form" :rules="rules" :submit-fn="submitFn" :error-callback="onFormErrors" ref="form">
        <div class="form-control" v-if="isSphereSelect">
          <form-dropdown
            :options="availableSpheres"
            key-field="id"
            :value="form.sphere_id"
            :multiple="false"
            prop="sphere_id"
            reference-class="sphere-members-selector"
            popover-class="ui-dropdown_no-border"
            :width-by-popover="true"
            :label="$t('stages.sphere')"
            @input="onSphereChange"
          >
            <div class="stage-form__sphere-selected">
              {{ selectedSphere.name }}
            </div>

            <template v-slot:option="{ option }">
              <div class="dropdown-sphere-item">
                {{ option.name }}
              </div>
            </template>
          </form-dropdown>
        </div>

        <div class="form-control">
          <text-field
              prop="name"
              v-model="form.name"
              :label="$t('stages.stageName')"
              :focused="true"
          />
        </div>

        <div class="form-control">
          <text-field
            type="number"
            prop="default_score"
            v-model.number="form.default_score"
            :label="$t('stages.taskScoreByDefaultInMinutes')"
          />
        </div>

        <form-dropdown
            :options="activeMembers"
            key-field="member_id"
            :value="form.notified_members"
            :multiple="true"
            prop="notified_members"
            reference-class="sphere-members-selector"
            popover-class="ui-dropdown_no-border"
            :width-by-popover="true"
            :label="$t('stages.membersToBeNotifiedOfNewTasks')"
            @input="onChangeMembers"
        >
          <div class="stage-member-selector" v-if="form.notified_members.length">
            <div v-for="member in form.notified_members" :key="member.member_id" class="member-badge">
              <user-photo :user="member" class="sphere-member-item"/>
              {{ member.first_name + ' ' + member.last_name }}
            </div>
          </div>

          <div class="stage-member-selector" v-else>
            {{ $t('stages.doNotNotifyAnyone') }}
          </div>

          <template v-slot:option="{ option }">
            <div class="task-assignee">
              <user-photo :user="option" class="task-assignee__photo"/>
              <div class="task-assignee__title">
                {{ option.first_name }} {{ option.last_name }}
              </div>
            </div>
          </template>
        </form-dropdown>

        <div class="mt-4">
          <ui-button @click.prevent="onSubmit" ui-class="block">{{ $t('common.save') }}</ui-button>
        </div>
      </ui-form>
    </div>
  </el-dialog>
</template>
<script>
  import UiForm from 'scorework-ui/src/components/form/UiForm'
  import TextField from 'scorework-ui/src/components/TextField'
  import UiButton from 'scorework-ui/src/components/UiButton'
  import UserPhoto from "@/components/ui/UserPhoto"
  import FormDropdown from "@/components/ui/FormDropdown"

  import { createNamespacedHelpers, mapGetters } from "vuex"
  import {required} from "vuelidate/lib/validators";
  const { mapGetters: stagesGetters, mapActions: stagesActions } = createNamespacedHelpers('stages')
  const { mapGetters: spheresGetters, mapActions: spheresActions } = createNamespacedHelpers('spheres')

  export default {
    name: 'StageModalForm',

    components: { UiForm, TextField, UiButton, UserPhoto, FormDropdown },

    props: {
      id: {
        type: Number,
        required: true
      },

      defaultValues: {
        type: Object,
        default: () => ({})
      },

      isSphereSelect: {
        type: Boolean,
        default: false
      }
    },

    data () {
      const rules = {
        name: {
          rules: { required },
          messages: {
            required: this.$t('settings.nameIsRequired')
          }
        }
      }

      if (this.isSphereSelect) {
        rules['sphere_id'] = {
          rules: { required },
          messages: {
            required: this.$t('stages.sphereIsRequired')
          }
        }
      }

      return {
        isLoading: false,

        isSphereError: false,

        form: {},

        rules
      }
    },

    computed: {
      ...stagesGetters(['stages', 'activeSpheres']),
      ...spheresGetters(['spheres']),
      ...mapGetters(['activeMembers']),

      modalTitle () {
        return !this.id ? this.$t('stages.newStage') : this.$t('stages.editStage')
      },

      availableSpheres () {
        return this.spheres.filter(item => this.activeSpheres.indexOf(item.id) === -1)
      },

      selectedSphere () {
        const sphere = this.spheres.find(item => item.id === this.form.sphere_id)

        return sphere || {id: null, name: 'Не выбрана'}
      }
    },

    methods: {
      ...stagesActions(['fetchStage', 'updateStage', 'createStage']),
      ...spheresActions(['fetchSpheres']),

      submitFn () {
        const saveHandler = this.id ? 'updateStage' : 'createStage'

        const form = {
          ...this.form,
          notified_members: this.form.notified_members.map(item => item.member_id)
        }

        this[saveHandler](form)
          .catch(err => {
            if (err.response && err.response.status === 422) {
              console.log(err.response)
              this.$refs.form.validate()
            } else {
              console.log(err)
            }
          })
          .then(() => {
            this.$notify({
              title: this.$t('common.saved'),
              message: this.id ? this.$t('stages.stageUpdated') : this.$t('stages.stageAdded'),
              position: 'bottom-right',
              type: 'success'
            })
            this.$emit('close')
          })

      },

      onSubmit () {
        this.$refs.form.submit()
      },

      onClose () {
        this.$emit('close')
      },

      onSphereChange (sphere) {
        this.isSphereError = false
        this.form.sphere_id = sphere.id
      },

      loadStage () {
        return this.fetchStage({ id: this.id, defaultValues: this.defaultValues })
          .then(stage => {
            this.form = stage
          })
      },

      onFormErrors (model) {
        if (model.sphere_id.$error) {
          this.isSphereError = true
        }
      },

      onChangeMembers (val) {
        this.form.notified_members = val
      }
    },

    created () {
      this.isLoading = true
      const promises = [this.loadStage()]

      if (this.isSphereSelect) {
        promises.push(this.fetchSpheres({}))
      }

      Promise.all(promises)
        .finally(() => {
          this.isLoading = false
        })
    }
  }
</script>
<style lang="sass">
.switch-control
  display: flex
  align-items: center

  .ui-switch
    margin-right: 12px

.sphere-members-selector
  .form-dropdown__value
    min-height: 32px

.member-badge
  display: flex
  align-items: center

.stage-member-selector
  display: flex
  flex-flow: row wrap
  padding-top: 8px

  .member-badge
    margin-right: 12px
    margin-bottom: 4px

    &:last-child
      margin-right: 0

</style>

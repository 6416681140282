import VueI18n from "vue-i18n"
import Vue from 'vue'
import messages from "@/translations"

const LANGUAGE_KEY = 'sw_language'
const DEFAULT_LANG = 'ru'

Vue.use(VueI18n)

class LanguageService {
  constructor() {
    this.i18n = new VueI18n({
      locale: this.getLanguage(),
      pluralizationRules: {
        /**
         * @param choice {number} индекс выбора, переданный в $tc: `$tc('path.to.rule', choiceIndex)`
         * @param choicesLength {number} общее количество доступных вариантов
         * @returns финальный индекс для выбора соответственного варианта слова
         */
        'ru': function(choice, choicesLength) {
          // this === VueI18n экземпляра, так что свойство locale также существует здесь

          if (choice === 0) {
            return 0;
          }

          if (choice % 1 > 0) {
            return 2;
          }

          const teen = choice > 10 && choice < 20;
          const endsWithOne = choice % 10 === 1;

          if (choicesLength < 4) {
            return (!teen && endsWithOne) ? 1 : 2;
          }
          if (!teen && endsWithOne) {
            return 1;
          }
          if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
            return 2;
          }

          return (choicesLength < 4) ? 2 : 3;
        }
      },
      messages
    })

    this.pageTitle = {}
  }

  getLanguage () {
    return localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANG
  }

  setLanguage (lang) {
    localStorage.setItem(LANGUAGE_KEY, lang)
    this.i18n.locale = lang
    this.refreshPageTitle()
  }

  setPageTitle (message, params = {}) {
    this.pageTitle = {
      message, params
    }

    document.title = this.i18n.t(message, params)
  }

  refreshPageTitle () {
    const { message, params = {} } = this.pageTitle
    if (message) {
      document.title = this.i18n.t(message, params)
    }
  }
}

const languageService = new LanguageService()

export default languageService

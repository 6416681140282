<template>
  <div class="sphere-badge" :class="sphereClasses">
    {{ sphere.name }}
    <span class="sphere-badge__close" @click="onClose" v-if="closeable">
      <thin-close-icon/>
    </span>
  </div>
</template>
<script>
import ThinCloseIcon from '@/components/icons/ThinCloseIcon'

export default {
  name: 'SphereBadge',

  components: { ThinCloseIcon },

  props: {
    sphere: {
      type: Object,
      required: true,
    },

    closeable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    sphereClasses () {
      const classes = []

      if (this.sphere.color) {
        classes.push(`sphere-badge_${this.sphere.color}`)
      }

      return classes.join(' ')
    }
  },

  methods: {
    onClose () {
      this.$emit('close', this.sphere)
    }
  }
}
</script>
<style lang="sass">
.sphere-badge
  align-items: center
  background: var(--color-grey-bg)
  border-radius: 4px
  color: var(--color-grey-text)
  display: flex
  font-size: 12px
  height: 22px
  padding: 0 8px
  cursor: default

  &_grey
    color: var(--color-grey-text)
    background-color: var(--color-grey-bg)

  &_blue
    color: var(--color-blue-text)
    background-color: var(--color-blue-bg)

  &_lavender
    color: var(--color-lavender-text)
    background-color: var(--color-lavender-bg)

  &_purple
    color: var(--color-purple-text)
    background-color: var(--color-purple-bg)

  &_fuchsia
    color: var(--color-fuchsia-text)
    background-color: var(--color-fuchsia-bg)

  &_red
    color: var(--color-red-text)
    background-color: var(--color-red-bg)

  &_yellow
    color: var(--color-yellow-text)
    background-color: var(--color-yellow-bg)

  &_orange
    color: var(--color-orange-text)
    background-color: var(--color-orange-bg)

  &_green
    color: var(--color-green-text)
    background-color: var(--color-green-bg)

  &_aquamarine
    color: var(--color-aquamarine-text)
    background-color: var(--color-aquamarine-bg)

  &__close
    cursor: pointer
    margin-left: 6px
    width: 16px
    height: 16px

    svg
      width: 16px
      height: 16px

    .path-stroke
      stroke: var(--text-primary-color)
</style>

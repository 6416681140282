<template>
  <div class="task-state-status">
    <div class="task-state-status__square" :class="squareClasses">
      <component :is="progressStatusIcon" class="task-state-status__icon"/>
    </div>

    <div class="task-state-status__title">
      {{ $t(`tasks.statuses.${stateStatus}`) }}
    </div>
  </div>
</template>
<script>
  import taskStateMixin from "@/components/tasks/mixins/taskStateMixin";

  export default {
    name: 'TaskStateStatus',

    mixins: [taskStateMixin],

    props: {
      task: {
        type: Object,
        required: true,
      }
    },

    computed: {
      squareClasses () {
        const classes = []
        classes.push(this.progressStatus)

        return classes
      }
    }
  }
</script>
<style lang="sass">
  @mixin colored-square($status, $color)
    &.#{$status}
      background: var(--color-#{$color}-hover)

      .path-fill
        fill: var(--color-#{$color}-text)

      .path-stroke
        stroke: var(--color-#{$color}-text)

  .task-state-status
    display: flex
    align-items: center
    column-gap: 8px

    &__square
      width: 24px
      height: 24px
      border-radius: 8px
      display: flex
      align-items: center
      justify-content: center

      @include colored-square(open, grey)
      @include colored-square(in_progress, aquamarine)
      @include colored-square(paused, yellow)
      @include colored-square(finished, purple)
      @include colored-square(overdue, red)

    &__title
      font-size: 13px
      color: var(--text-primary-color)
      cursor: default

</style>

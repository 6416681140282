import DraggableList from "./draggableList"

class DragManager {
  constructor () {
    this.lists = {}
  }

  createList (el, name, listItem) {
    const list = this.getOrCreateList(name)
    list.el = el
    list.listItem = listItem

    return list
  }

  removeList (name) {
    delete this.lists[name]
  }

  getOrCreateList (name) {
    if (!this.lists[name]) {
      this.lists[name] = new DraggableList(name)
    }

    return this.lists[name]
  }

  setListParams (name, callbacks, params) {
    const draggableList = this.getList(name)

    if (!draggableList) {
      return
    }

    draggableList.setCallbacks(callbacks)

    if (params.externalDropLists) {
      params.dragManager = this
    }

    draggableList.setParams(params)
  }

  addItem (listName, el, item, dragSelector) {
    const draggableList = this.getOrCreateList(listName)

    draggableList.addItem(el, item, dragSelector)
  }

  updateItem (listName, el, item) {
    const draggableList = this.getList(listName)

    if (!draggableList) {
      return
    }

    draggableList.updateItem(el, item)
  }

  setupClasses (listName, el) {
    const draggableList = this.getList(listName)
    draggableList.setupClasses(el)
  }

  removeItem (listName, el, item) {
    const draggableList = this.getList(listName)

    if (!draggableList) {
      return
    }

    draggableList.removeItem(el, item)
  }

  getList (name) {
    return this.lists[name]
  }
}

const dragManager = new DragManager()

export default dragManager

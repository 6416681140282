<template>
  <div class="task-score">
    <clock-icon class="task-score__icon"/> {{ formattedValue }}
  </div>
</template>
<script>
import ClockIcon from "@/components/icons/ClockIcon"

import { getScoreObject, getScoreLabel } from '@/services/scoring'

import { mapGetters } from 'vuex'

export default {
  name: 'ScoreItem',

  components: { ClockIcon },

  props: {
    value: {
      required: true
    },

    emptyLabel: {
      type: String,
      default: 'tasks.noScore'
    }
  },

  computed: {
    ...mapGetters(['workspace']),

    formattedValue() {
      if (!this.value) {
        return this.$t(this.emptyLabel)
      }

      return getScoreLabel(this.parsedValue)
    },

    parsedValue() {
      return getScoreObject(this.value)
    }
  }
}
</script>

<template>
  <div class="progress-data">
    <div class="progress-data__info">
      <el-tooltip
          effect="dark"
          :content="$t('goalsProgress.estimatedAt')"
          placement="top"
          transition="sw-slide-top"
      >
        <div class="progress-data__date">
          <clock-icon class="workload-member__metrics-icon"/>
          {{ estimatedFinishAt }}
        </div>
      </el-tooltip>

      <div class="progress-data__count">
        {{ finishedCount }} / {{ totalCount }}
      </div>
    </div>

    <progress-bar :progress="progressPercent"/>

  </div>
</template>
<script>
  import ClockIcon from '@/components/icons/ClockIcon'
  import ProgressBar from '@/components/ui/ProgressBar'

  export default {
    name: 'ProgressData',

    components: { ClockIcon, ProgressBar },

    props: {
      estimatedFinishAt: {
        type: String,
        required: true,
      },

      finishedCount: {
        type: Number,
        required: true,
      },

      totalCount: {
        type: Number,
        required: true,
      }
    },

    computed: {
      progressPercent () {
        return Math.round(this.finishedCount / this.totalCount * 100)
      },
    }
  }
</script>
<style lang="sass">
  .progress-data
    &__info
      align-items: center
      display: flex
      justify-content: space-between
      margin-bottom: 6px

    &__date
      align-items: center
      display: flex
      font-size: 12px
      color: var(--text-light-color)

    &__count
      font-size: 12px
      color: var(--text-light-color)
</style>

<template>
  <div>
    <div class="settings-title">
      {{ $t('settings.rate.title') }}
    </div>

    <rate-view/>
  </div>
</template>
<script>
  import RateView from '@/components/settings/RateView'
  import authUserMixin from "@/mixins/authUserMixin"

  export default {
    name: 'RateSettings',

    components: { RateView },

    mixins: [authUserMixin],

    created () {
      if (!this.isOwner) {
        const redirectRouteName = this.isAdmin ? 'workspace.settings.general' : 'workspace.member.settings'
        this.$router.push({ name: redirectRouteName , params: { project: this.project.code} })
      }
    }
  }
</script>

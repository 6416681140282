<template>
  <el-popover
    placement="bottom-start"
    trigger="manual"
    :value="isOpened"
    ref="popper"
    :visible-arrow="false"
    transition="sw-slide-top"
    popper-class="ui-dropdown ui-dropdown_round"
    @show="onShow"
    @after-leave="onClose"
  >
    <div slot="reference">
      <div class="team-member-spheres" :class="referenceClasses" @click="isOpened = !isOpened">
        <sphere-badge
            v-for="sphere in selectedSpheres"
            :key="sphere.id"
            :sphere="sphere"
        />

        <div class="team-member-spheres__empty" v-if="selectedSpheres.length === 0">
          {{ $t('pages.noSpheresSelected') }}
        </div>
      </div>
    </div>

    <div class="spheres-dp-wrapper">
      <div
          v-for="sphere in spheres"
          :key="sphere.id"
          class="team-member-sphere"
          :class="itemClasses(sphere)"
          @click.prevent="(e) => toggleItem(e, sphere)"
      >
        <el-checkbox :value="isSelected(sphere)">
          {{ sphere.name }}
        </el-checkbox>
      </div>

      <div class="mt-2">
        <ui-button ui-class="size_md block" @click="onSubmit">{{ $t('common.save') }}</ui-button>
      </div>
    </div>
  </el-popover>
</template>
<script>
  import {mapGetters} from "vuex"

  import UiButton from 'scorework-ui/src/components/UiButton'
  import SphereBadge from "@/components/ui/SphereBadge"

  export default {
    name: 'SphereSelector',

    components: { SphereBadge, UiButton },

    props: {
      value: {
        type: Array,
        required: true,
      }
    },

    data () {
      return {
        isOpened: false,
        selected: this.value.map((item) => item.id),
      }
    },

    computed: {
      ...mapGetters(['spheres']),

      selectedSpheres () {
        return this.spheres.filter((item) => this.value.find((sphere) => sphere.id === item.id))
      },

      referenceClasses () {
        const classes = []

        if (this.isOpened) {
          classes.push('team-member-spheres_opened')
        }

        return classes
      },
    },

    watch: {
      value (newValue) {
        this.selected = newValue.map((item) => item.id)
      }
    },

    methods: {
      onShow () {
        this.isOpened = true
      },

      onClose () {
        this.isOpened = false
        this.selected = this.value.map((item) => item.id)
      },

      itemClasses (sphere) {
        const classes = [];

        if (sphere.parent_id) {
          classes.push('team-member-sphere_inner')
        }

        return classes
      },

      isSelected (sphere) {
        return this.selected.indexOf(sphere.id) !== -1
      },

      toggleItem (e, sphere) {
        const idx = this.selected.indexOf(sphere.id)
        if (idx === -1) {
          this.selected.push(sphere.id)
          return
        }

        this.selected.splice(idx, 1)
      },

      onSubmit () {
        this.$emit('submit', this.selected)
        this.isOpened = false
      },

      onDocumentClickHandler (e) {
        if (!this.isOpened) {
          return
        }

        const popper = this.$refs.popper.$el

        if (!popper || popper.contains(e.target)) {
          return;
        }

        this.isOpened = false
      }
    },

    created () {
      document.addEventListener('click', this.onDocumentClickHandler)
    },

    destroyed () {
      document.removeEventListener('click', this.onDocumentClickHandler)
    }
  }
</script>
<style lang="sass">
  .team-member-spheres
    display: flex
    flex-flow: row wrap
    gap: 6px
    cursor: pointer
    padding: 8px 8px 8px 0
    border-radius: 6px
    border: 1px solid transparent
    transition: all ease-in .2s

    &:hover, &_opened
      padding-left: 8px
      border-color: var(--block-border-bg)
      box-shadow: var(--block-shadow)

    &__empty
      font-size: 13px
      color: var(--text-light-color)

  .spheres-dp-wrapper
    padding: 12px

  .team-member-sphere
    padding: 4px 8px
    border-radius: 4px

    &:hover
      background: var(--grey-light-color-24)

    &_inner
      padding-left: 16px
</style>

<template>
    <svg :width="iconWidth" :height="iconHeight" :viewBox="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 3L4.5 9L1.5 6" :stroke="backgroundColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="path-stroke"/>
    </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'CheckIcon',

    mixins: [iconMixin],

    props: {
      viewBox: {
        type: String,
        default: '0 0 12 12'
      }
    },

    data () {
      return {
        defaultBackground: '#416AF7',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

<template>
  <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 1L1 9L9 17" stroke="#5B6372" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="path-stroke"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'ChevronLeft',

    mixins: [iconMixin],

    props: {
      viewBox: {
        type: String,
        default: '0 0 12 12'
      }
    },

    data () {
      return {
        defaultBackground: '#2C3E50',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

const pagesSocket = (store, onConnect) => {
  let wasDisconnected = false

  const listeners = {
    'connect': () => {
      if (wasDisconnected) {
        onConnect()
      }
    },

    'disconnect': () => {
      wasDisconnected = true
    },

    'pages.section.updated': ({ project, parent, pages }) => {
      store.dispatch('pages/refreshSection', { project, parent, pages })
    },

    'pages.updated': ({ page }) => {
      store.dispatch('pages/refreshPage', { page })
    },
  }

  return {
    subscribe (socket) {
      Object.keys(listeners).forEach(eventName => {
        socket.on(eventName, listeners[eventName])
      })
    },

    unsubscribe (socket) {
      Object.keys(listeners).forEach(eventName => {
        socket.off(eventName, listeners[eventName])
      })
    }
  }
}

export default pagesSocket


<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.8099 8.76514C15.1249 5.65014 12.1699 3.76514 8.89986 3.76514C5.62986 3.76514 2.66986 5.65014 0.999863 8.76514L0.859863 9.00014L0.989863 9.24014C2.67486 12.3551 5.62986 14.2401 8.89986 14.2401C12.1699 14.2401 15.1299 12.3801 16.8099 9.24014L16.9399 9.00014L16.8099 8.76514ZM8.89986 13.2151C6.08486 13.2151 3.49986 11.6451 1.99986 9.00014C3.49986 6.35514 6.08486 4.78514 8.89986 4.78514C11.7149 4.78514 14.2699 6.36014 15.7949 9.00014C14.2699 11.6451 11.7099 13.2151 8.89986 13.2151V13.2151Z" fill="#27272E" class="path-fill"/>
    <path d="M9.04511 5.58505C8.36717 5.59 7.7059 5.79574 7.14482 6.17629C6.58374 6.55684 6.14802 7.09513 5.89269 7.72317C5.63736 8.35121 5.57387 9.04084 5.71025 9.70494C5.84663 10.369 6.17675 10.9778 6.65892 11.4544C7.1411 11.931 7.75369 12.254 8.41933 12.3827C9.08498 12.5113 9.77381 12.4398 10.3988 12.1772C11.0239 11.9145 11.557 11.4726 11.931 10.9071C12.305 10.3416 12.5031 9.678 12.5001 9.00005C12.4981 8.54877 12.4071 8.10231 12.2323 7.68626C12.0575 7.27021 11.8023 6.89275 11.4814 6.5755C11.1604 6.25826 10.78 6.00747 10.3619 5.83751C9.94387 5.66755 9.49638 5.58176 9.04511 5.58505V5.58505ZM9.04511 11.4451C8.56623 11.4401 8.0995 11.2938 7.70351 11.0245C7.30753 10.7551 6.99996 10.3748 6.81943 9.93122C6.6389 9.48766 6.59346 9.00063 6.68881 8.53133C6.78417 8.06202 7.01607 7.63135 7.35539 7.29341C7.69472 6.95547 8.12633 6.72533 8.59602 6.63189C9.06572 6.53844 9.55255 6.58587 9.99537 6.76821C10.4382 6.95055 10.8173 7.25967 11.085 7.65675C11.3527 8.05383 11.4971 8.52116 11.5001 9.00005C11.5014 9.32235 11.4388 9.64171 11.3158 9.93961C11.1927 10.2375 11.0118 10.508 10.7834 10.7354C10.555 10.9629 10.2838 11.1427 9.98541 11.2645C9.68701 11.3863 9.3674 11.4477 9.04511 11.4451V11.4451Z" fill="#27272E" class="path-fill"/>
  </svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'EyeIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

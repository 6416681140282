<template>
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6398 7.24831C10.5777 7.22046 10.506 7.21894 10.4426 7.24406H10.445C7.81733 8.27374 4.80606 7.09039 3.71918 4.60098C3.1679 3.33832 3.20093 1.91447 3.81023 0.675973C3.86747 0.561318 3.81575 0.424416 3.69472 0.370189C3.6326 0.342344 3.56091 0.340822 3.49752 0.365941C0.61703 1.48694 -0.758871 4.60787 0.424372 7.33676C1.60762 10.0656 4.90193 11.3691 7.78246 10.2482C9.09621 9.73689 10.1579 8.77479 10.7554 7.55409C10.8126 7.43944 10.7608 7.30254 10.6398 7.24831Z" fill="#5B6372"/>
  </svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'NightIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

<template>
  <div class="sw-editor" :class="{ 'sw-editor_active': editor.isFocused }">
    <bubble-menu :editor="editor" :tippy-options="{ placement: 'top' }" :should-show="shouldShowBubble" class="sw-editor__bubble sw-editor__bubble_active" ref="bubble">
      <button
          class="sw-editor__bubble-item"
          :class="{ 'sw-editor__bubble-item_active': editor.isActive('bold') }"
          @click="editor.chain().focus().toggleBold().run()"
      >
        <bold-icon/>
      </button>

      <button
          class="sw-editor__bubble-item"
          :class="{ 'sw-editor__bubble-item_active': editor.isActive('underline') }"
          @click="editor.chain().focus().toggleUnderline().run()"
      >
        <underline-icon/>
      </button>

      <button
          class="sw-editor__bubble-item"
          :class="{ 'sw-editor__bubble-item_active': editor.isActive('strike') }"
          @click="editor.chain().focus().toggleStrike().run()"
      >
        <stroke-icon/>
      </button>

      <button
          class="sw-editor__bubble-item"
          :class="{ 'sw-editor__bubble-item_active': editor.isActive('italic') }"
          @click="editor.chain().focus().toggleItalic().run()"
      >
        <italic-icon/>
      </button>
    </bubble-menu>


    <editor-content class="sw-editor__content sw-pros-mirror" :editor="editor" />

    <div
        class="sw-editor__menu"
        :class="{ 'sw-editor__menu_active': isMenuActive }"
    >
      <div class="sw-editor__menu-list sw-editor__menu-list_left">
        <button
            class="sw-editor__menu-item"
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        >
          <h1-icon class="sw-editor__menu-icon"/>
        </button>

        <button
            class="sw-editor__menu-item"
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        >
          <h2-icon class="sw-editor__menu-icon"/>
        </button>

        <button
            class="sw-editor__menu-item"
            :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        >
          <h3-icon class="sw-editor__menu-icon"/>
        </button>

        <button
            class="sw-editor__menu-item"
            :class="{ 'is-active': editor.isActive('bulletList') }"
            @click="editor.chain().focus().toggleBulletList().run()"
        >
          <ul-icon class="sw-editor__menu-icon"/>
        </button>

        <button
            class="sw-editor__menu-item"
            :class="{ 'is-active': editor.isActive('codeBlock') }"
            @click="editor.chain().focus().toggleCodeBlock().run()"
        >
          <code-icon class="sw-editor__menu-icon"/>
        </button>

        <button
            class="sw-editor__menu-item"
            @click="onUploadImage"
        >
          <image-icon class="sw-editor__menu-icon"/>
        </button>

        <button
            class="sw-editor__menu-item"
            @click="onAttachClick"
            v-if="withAttaches"
        >
          <attach-icon class="sw-editor__menu-icon"/>
        </button>

<!--        <el-popover-->
<!--            placement="right"-->
<!--            trigger="click"-->
<!--            popper-class="sw-popover task-state__options-drop"-->
<!--            transition="sw-slide-right"-->
<!--            @show="showMenu = true" @hide="showMenu = false"-->
<!--        >-->
<!--          <div class="ui-dropdown__option-list">-->

<!--          </div>-->
<!--          <button-->
<!--              class="sw-editor__menu-item"-->
<!--              slot="reference"-->
<!--          >-->
<!--            <more-icon class="sw-editor__menu-icon"/>-->
<!--          </button>-->
<!--        </el-popover>-->
      </div>

      <div class="sw-editor__menu-list sw-editor__menu-list_right">
        <el-popover placement="top" popper-class="sw-popover sw-popover_no-border" @show="showMenu = true" @hide="showMenu = false">
          <picker
              :data="emojiIndex"
              :per-line="9"
              :native="true"
              :emoji-size="25"
              title="Pick your emoji..."
              color="#638FFF"
              emoji="point_up"
              @select="emj => editor.chain().focus().insertContent({ type: 'emoji', attrs: { id: emj.id, label: emj.native }}).run()"
          />
          <button
              class="sw-editor__menu-item"
              slot="reference"
          >
            <smile-icon class="sw-editor__menu-icon" style="width:18px; height: 18px;"/>
          </button>
        </el-popover>
        <slot name="right-bar"></slot>
      </div>
    </div>
    <input type="file" ref="file" style="visibility: hidden; display: none;" accept="image/*" @change="onImageChange"/>
    <suggestion-popover :suggestion="suggestion" @select="selectSuggestion" ref="suggestions"/>
    <overlay-gallery :src="gallery.src" v-if="gallery.open" @close="gallery.open = false"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import Mention from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
import Emoji from "@/components/ui/textEditor/emoji"

import CodeIcon from "@/components/icons/editor/CodeIcon"
import UlIcon from "@/components/icons/editor/UlIcon"
import ImageIcon from "@/components/icons/editor/ImageIcon"
import UnderlineIcon from "@/components/icons/editor/UnderlineIcon"
import BoldIcon from "@/components/icons/editor/BoldIcon"
import StrokeIcon from "@/components/icons/editor/StrokeIcon"
import ItalicIcon from "@/components/icons/editor/ItalicIcon"
import H1Icon from "@/components/icons/editor/H1Icon"
import H2Icon from "@/components/icons/editor/H2Icon"
import H3Icon from "@/components/icons/editor/H3Icon"
import SmileIcon from "@/components/icons/SmileIcon"
import withSuggestions from "@/components/ui/textEditor/withSuggestions"
import withEmojis from "@/components/ui/textEditor/withEmojis"
import editorMixin from '@/components/ui/mixins/editorMixin'
import {isTextSelection} from "@tiptap/core";
import suggestion from "@/components/ui/textEditor/suggestion"
import MemberList from "@/components/ui/textEditor/MemberList"
import AttachIcon from "@/components/icons/AttachIcon";

export default {
  name: 'TextEditor',

  components: {
    AttachIcon,
    EditorContent, BubbleMenu,
    CodeIcon, UlIcon, ImageIcon, UnderlineIcon, BoldIcon, StrokeIcon, ItalicIcon,
    H1Icon, H2Icon, H3Icon, SmileIcon
  },

  mixins: [withSuggestions, withEmojis, editorMixin],

  props: {
    value: {
      required: true
    },

    placeholder: {
      type: String,
      default: ''
    },

    extensions: {
      type: Array,
      default: () => []
    },

    contentType: {
      type: String,
      default: 'json'
    },

    withAttaches: {
      type: Boolean,
      default: false,
    }
  },

  data () {
    const editor = new Editor({
      extensions:  [
        ...this.extensions,
        ...this.getCommonExtensions({
          useHistory: true,
        }),
        // SideMenu.configure({
        //   setForceShow: () => {
        //     this.forceShow = true
        //   }
        // }),
        //UniqueID,
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          placeholder: this.placeholder,
          showOnlyWhenEditable: true,
          showOnlyCurrent: true,
        }),
        Emoji,
        Mention.configure({
          suggestion: suggestion({
            getMembers: () => this.getMembers(),
            getLabel: (member) => `${member.first_name} ${member.last_name}`,
            idField: 'member_id',
            listComponent: MemberList,
          })
        }),
      ],
      onUpdate: ({ editor }) => {
        this.$emit('input', { getJSON: () => editor.getJSON(), getHTML: () => editor.getHTML() })
      },
      content: this.value,
    })

    editor.on('create', ({ editor }) => {
      this.listenImageOpening(editor)
    })

    return {
      editor,
      emojiIndex: this.newEmojiIndex(),
      showMenu: false,
      forceShow: false,
    }
  },

  computed: {
    ...mapGetters(['activeMembers']),

    isMenuActive () {
      return this.editor.isFocused || this.showMenu
    },

    suggestionEl () {
      return this.$refs.suggestions.$el
    }
  },

  methods: {
    ...mapActions(['uploadImage']),

    onUploadImage () {
      this.$refs.file.click()
    },

    onImageChange () {
      const file = this.$refs.file.files[0]

      const formData = new FormData()
      formData.append('image', file)

      this.uploadImage({ formData, progressCallback: e => this.onFileProgress(e) })
        .then(image => {
          const { url, thumbnails = {} } = image
          const previewUrl = thumbnails.sm || url

          this.editor.chain().focus().insertContent({
            type: 'image',
            attrs: {
              src: previewUrl,
              fullSrc: url,
            }
          }).run()
        })
    },

    onFileProgress () {

    },

    shouldShowBubble ({ view, state, from, to })  {
      const { doc, selection } = state
      const { empty } = selection

      const isImage = selection?.node?.type.name === 'image'

      // Sometime check for `empty` is not enough.
      // Doubleclick an empty paragraph returns a node size of 2.
      // So we check also for an empty text size.
      const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(state.selection)

      // When clicking on a element inside the bubble menu the editor "blur" event
      // is called and the bubble menu item is focussed. In this case we should
      // consider the menu as part of the editor and keep showing the menu
      const isChildOfMenu = this.$refs.bubble.$el.contains(document.activeElement)

      const hasEditorFocus = view.hasFocus() || isChildOfMenu

      if (!hasEditorFocus || empty || isImage|| isEmptyTextBlock || !this.editor.isEditable) {
        return false
      }

      return true
    },

    getMembers () {
      return this.activeMembers
    },

    onAttachClick () {
      this.$emit('attach-file')
    }
  },

  mounted() {
    //this.listenImageOpening(this.editor)
    this.$emit('init', { editor: this.editor })
  },

  beforeDestroy() {
    this.editor.destroy()
  }
}
</script>

<template>
  <div class="board-task-item" :class="taskClasses" v-draggable-item="draggableItem">
    <div class="board-task-item__block" :class="blockClasses">
      <div class="board-task-item__line">
        <div>
          <el-tooltip
            effect="dark"
            :content="$t('tasks.numberIsCopied')"
            placement="top"
            transition="sw-slide-top"
            :manual="true"
            :value="showNumberTooltip"
          >
            <div class="board-task-item__number" @click="onCopyNumber">
              {{ taskNumber }}
            </div>
          </el-tooltip>
        </div>

        <div class="board-task-item__info">
          <div class="sphere-badge">
            {{ stage.name }}
          </div>

          <sphere-badge
            class="ml-2"
            :sphere="task.sphere"
            v-if="withFields.includes('sphere') && task.sphere"
          />

          <user-photo
            class="ml-2"
            :user="taskState.assignee"
            :show-online="false"
            v-if="withFields.includes('assignee')"
          />
        </div>
      </div>

      <div class="board-task-item__title" @click="onOpenTask">
        {{ task.name }}
      </div>

      <div class="board-task-item__line">
        <div class="board-task-item__status">
          <el-tooltip
            effect="dark"
            :content="$t('tasks.statuses.' + taskStatus)"
            placement="top"
            transition="sw-slide-top"
          >
            <div class="task-item__status" :class="statusClasses">
              <component :is="statusIcon"></component>
            </div>
          </el-tooltip>

          <div class="board-task-item__status-details">
            <el-tooltip
              effect="dark"
              :content="$t(estimatedLabelTooltip)"
              placement="top"
              transition="sw-slide-top"
            >
              <div class="board-task-item__status-label" :class="statusLabelClasses">
                {{ getEstimatedTimeLabel }}
              </div>
            </el-tooltip>

            <div class="board-task-item__progress" v-if="['in_progress', 'paused'].indexOf(taskStatus) !== -1">
              <progress-bar :progress="progressPercent"/>
            </div>
          </div>
        </div>

        <div class="board-task-item__scores">
          <div class="board-task-item__score" v-if="!editable">
            <clock-icon class="board-task-item__score-icon"/>
            {{ formattedScore }}
          </div>
          <el-popover
              placement="bottom"
              ref="scorePopper"
              trigger="click"
              popper-class="sw-popover task-item-popover"
              @show="isScoreSelectorOpen = true"
              @hide="isScoreSelectorOpen = false"
              :visible-arrow="true"
              v-else
          >
            <score-selector
                :value="task.score"
                @input="onScoreChange"
                v-if="isScoreSelectorOpen"
            />
            <div class="board-task-item__score" slot="reference">
              <clock-icon class="board-task-item__score-icon"/>
              {{ formattedScore }}
            </div>
          </el-popover>

          <div class="board-task-item__score ml-3" v-if="withFields.includes('points') && task.points">
            <points-icon class="board-task-item__score-icon"/>
            {{ $tc('tasks.pointsLabel', task.points, { points: task.points }) }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="board-task-item__gap"
      @mouseover="showAddButton = true"
      @mouseleave="showAddButton = false"
    >
      <a href="#" @click.prevent="onAddTask">
        <round-plus class="task-list-span__btn" v-if="withAddButton && showAddButton"/>
      </a>
    </div>
  </div>
</template>
<script>
  import { createNamespacedHelpers, mapGetters } from 'vuex'

  import ProgressBar from '@/components/ui/ProgressBar'
  import ScoreSelector from '@/components/tasks/components/ScoreSelector'
  import SphereBadge from '@/components/ui/SphereBadge.vue'
  import PointsIcon from '@/components/icons/PointsIcon.vue'
  import RoundPlus from '@/components/icons/RoundPlus'
  import UserPhoto from '@/components/ui/UserPhoto'
  import taskNumber from '@/components/tasks/mixins/taskNumber'
  import progressMixin from '@/components/tasks/mixins/progressMixin'
  import taskItemMixin from '@/components/tasks/mixins/taskItemMixin'

  const { mapActions: taskActions } = createNamespacedHelpers('task')

  export default {
    name: 'BoardTaskItem',

    components: { PointsIcon, ProgressBar, RoundPlus, ScoreSelector, SphereBadge, UserPhoto },

    mixins: [taskNumber, taskItemMixin, progressMixin],

    props: {
      task: {
        type: Object,
        required: true,
      },

      editable: {
        type: Boolean,
        default: false,
      },

      draggable: {
        type: Boolean,
        default: false,
      },

      dragList: {
        type: String,
        default: ''
      },

      withFields: {
        type: Array,
        default: () => [],
      },

      withAddButton: {
        type: Boolean,
        default: true,
      }
    },

    data () {
      return {
        isScoreSelectorOpen: false,
        showAddButton: false
      }
    },

    computed: {
      ...mapGetters(['stages', 'workspace']),

      draggableItem () {
        return {
          list: this.dragList,
          item: this.task,
          dragSelector: '.board-task-item__block',
          skip: !this.draggable
        }
      },

      taskClasses () {
        const classes = []

        if (this.task.muted) {
          classes.push('board-task-item_muted')
        }

        return classes
      },

      stage () {
        return this.stages.find(stage => stage.id === this.task.stage_id)
      },

      blockClasses () {
        const classes = []
        classes.push(`board-task-item__block_${this.taskStatus}`)

        return classes
      }
    },

    methods: {
      ...taskActions(['updateTaskField']),

      onScoreChange (score) {
        this.doTaskFieldUpdate('score', score)
          .then(() => {
            this.$refs.scorePopper.doClose()
          })
      },

      doTaskFieldUpdate (field, value) {
        return this.updateTaskField({
          id: this.task.id,
          field, value
        })
      },
    }
  }
</script>
<style lang="sass">
  .board-task-item
    &_muted
      opacity: 0.4

    &__block
      padding: 12px
      border-radius: 8px
      position: relative
      background: var(--block-bg-color)
      box-sizing: border-box
      box-shadow: var(--block-shadow)
      overflow: hidden
      border: 1px solid transparent

      &_overdue
        border-color: var(--color-red-dots)

      &_in_progress
        border-color: var(--color-green-text)

      &_in_progress
        .ui-progress-bar__fill
          background: var(--color-aquamarine-text)

    &__line
      align-items: center
      display: flex
      justify-content: space-between

    &__number
      color: var(--text-light-color)
      cursor: pointer
      font-size: 12px

    &__info
      align-items: center
      display: flex

    &__title
      cursor: pointer
      margin: 6px 0 12px
      font-size: 14px
      line-height: 20px
      max-height: 40px
      font-weight: 500
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-line-clamp: 2
      line-clamp: 2
      -webkit-box-orient: vertical

    &__progress
      width: 135px
      margin-top: 4px

    &__score
      align-items: center
      cursor: pointer
      display: flex
      font-size: 12px
      color: var(--text-light-color)

    &__score-icon
      margin-right: 6px
      width: 16px
      height: 16px

      .path-stroke
        stroke: var(--text-light-color)

    &__status
      align-items: center
      display: flex

      &-details
        margin-left: 8px

      &-label
        color: var(--text-light-color)
        font-size: 12px

    &__scores
      align-items: center
      display: flex

    &__gap
      height: 12px
      position: relative

</style>

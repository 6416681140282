<template>
  <div class="ui-dropdown__container">
    <el-popover
      :placement="placement"
      trigger="manual"
      :popper-class="popperClass"
      ref="popper"
      :value="isOpenValue"
      :visible-arrow="false"
      transition="sw-slide-top"
      :width="width"
      @input="(val) => isOpen = val"
      @show="onShowPopover"
      @hide="onHidePopover"
    >
      <text-field
        prop="searchText"
        v-if="filterable"
        :label="label"
        class="filter-input"
        v-model="searchText"
        autocomplete="off"
      />
      <div class="ui-dropdown__option-list" ref="list">
        <div
          v-for="option in filteredOptions"
          :key="optionKey(option)"
          :class="getOptionClasses(option)"
          @click="onClickOption(option, $event)"
        >
            <slot
              name="option"
              :option="option"
              :selected="isSelected(option)"
              :searchText="searchText"
            />
        </div>
        <div class="empty-options-list" v-if="options.length === 0">
          {{ $t('ui.nothingFound') }}
        </div>
      </div>

      <div slot="reference" :class="referenceClasses" @click="onClickReference" ref="reference">
        <slot></slot>
      </div>
    </el-popover>
  </div>
</template>
<script>
import TextField from 'scorework-ui/src/components/TextField'

export default {
  name: 'UiDropdown',

  components: {
    TextField,
  },

  props: {
    options: {
      type: Array,
      default: () => []
    },

    value: {
      required: true
    },

    label: {
      type: String,
    },

    filterable: {
      type: Boolean,
      default: false
    },

    multiple: {
      type: Boolean,
      default: false
    },

    keyField: {
      type: String,
      default: 'id'
    },

    popoverClass: {
      type: String,
      default: ''
    },

    referenceClass: {
      type: String,
      default: ''
    },

    placement: {
      type: String,
      default: 'bottom'
    },

    widthByReference: {
      type: Boolean,
      default: false
    },

    widthByPopover: {
      type: Boolean,
      default: true
    },

    clickFn: {
      type: Function
    },

    compareField: {
      type: Function,
    },

    disabled: {
      type: Boolean,
      default: false,
    }
  },

  data () {
    const tempValue = this.multiple ? [...this.value] : []

    return {
      tempValue,
      width: '150px',
      isOpen: false,
      searchText: '',
    }
  },

  computed: {
    isOpenValue () {
      if (this.disabled) {
        return false
      }

      return this.isOpen
    },

    popperClass () {
      let classes = 'ui-dropdown ' + this.popoverClass

      return classes
    },

    referenceClasses () {
      const classes = ['ui-dropdown__reference']

      if (this.referenceClass) {
        classes.push(this.referenceClass)
      }

      if (this.isOpen) {
        classes.push('ui-dropdown__reference_opened')
        if (this.referenceClass) {
          classes.push(`${this.referenceClass}_opened`)
        }
      }

      return classes
    },

    filteredOptions () {
      if (!this.filterable) {
        return this.options
      }

      return this.options.filter(option => this.compareField(option).toLowerCase().includes(this.searchText.toLowerCase()))
    },
  },

  methods: {
    getOptionClasses (option) {
      if (this.multiple) {
        return {
          'ui-dropdown__option-multiple': true,
          'ui-dropdown__option-multiple_active': this.isSelected(option),
        }
      }

      return {
        'ui-dropdown__option': true,
        'ui-dropdown__option_active': this.isSelected(option),
      }
    },

    onClickOption (option, event) {
      if (event) {
        event.preventDefault()
      }
      if (this.clickFn && false === this.clickFn(option)) {
        return
      }

      if (!this.multiple) {
        this.$emit('input', option)
        this.closeDropdown()
      } else {
        if (!this.isSelected(option)) {
          this.tempValue.push(option)
        } else {
          const index = this.tempValue.indexOf(option)
          this.tempValue.splice(index, 1)
        }

        this.$emit('input', this.tempValue)
        this.$nextTick(() => {
          this.$refs.popper.updatePopper()
        })
      }
    },

    isSelected (option) {
      if (this.multiple) {
        if (this.keyField) {
          return this.tempValue.findIndex(item => {
            if (item instanceof Object) {
              return option[this.keyField] === item[this.keyField]
            }

            return option[this.keyField] === item
          }) !== -1
        }

        return this.tempValue.indexOf(option) !== -1
      }

      if (this.keyField) {
        if (this.value instanceof Object) {
          return option[this.keyField] === this.value[this.keyField]
        }

        return option[this.keyField] === this.value
      }

      return option === this.value
    },

    optionKey (option) {
      if (this.keyField) {
        return option[this.keyField]
      }

      return option
    },

    onClickReference () {
      if (this.widthByReference) {
        this.width = this.$refs.reference.offsetWidth
      }

      this.isOpen = !this.isOpen
    },

    closeDropdown() {
      this.$refs.popper.doClose()
      this.isOpen = false
    },

    onDocumentClickHandler (e) {
      if (!this.isOpen) {
        return
      }

      const reference = this.$refs.reference
      const popper = this.$refs.popper.$el

      if (!this.$el || !reference || this.$el.contains(e.target) || reference.contains(e.target) || !popper || popper.contains(e.target)) {
        return;
      }
      this.closeDropdown()
    },

    onShowPopover () {
      this.$nextTick(() => {
        if (this.widthByReference) {
          this.width = this.$refs.reference.offsetWidth
        }

        if (this.widthByPopover) {
          if (this.$refs.list.clientWidth > this.$refs.reference.clientWidth) {
            this.$refs.reference.style.width = this.$refs.list.clientWidth + 'px'
          } else {
            this.width = this.$refs.reference.clientWidth + 'px'
          }
        }

        this.$refs.popper.updatePopper()
      })
    },

    onHidePopover () {
      this.searchText =''
      this.$emit('filter-change', '')
      if (this.widthByPopover) {
        this.$refs.reference.style.width = null
      }
    }
  },

  watch: {
    value: function () {
      if (this.multiple === false) {
        return
      }

      this.tempValue = this.value.map(val => {
        if (val instanceof Object) {
          return val
        }

        return this.options.find(option => {
          if (this.keyField) {
            return option[this.keyField] === val
          }

          return option === val
        })
      })
    }
  },

  created () {
    document.addEventListener('click', this.onDocumentClickHandler)
  },

  destroyed () {
    document.removeEventListener('click', this.onDocumentClickHandler)
  }
}
</script>
<style lang="sass">
  .ui-dropdown_multiple
    border-radius: 8px!important
    margin-top: 8px!important

  .filter-input
    margin: 8px
    min-width: 284px

    .text-field
      padding: 0 8px 0 16px
      border-radius: 12px

  .empty-options-list
    line-height: 44px
    text-align: center
</style>

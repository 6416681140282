<template>
  <div
    class="message-item__bubble"
    :class="{'message-item__bubble_own': isOwn }"
  >
    <div class="message-item__bubble-content">
      <template v-for="(row, index) in messageRows">
        <br v-if="index > 0" :key="index"/>
        <template v-for="(part, partIndex) in row">
          <template v-if="part.type === 'text'">{{ part.content }}</template>
          <span v-if="part.type === 'emoji'" class="emoji" data-mention-id="any" :key="index + 'p' + partIndex">{{ part.content }}</span>
        </template>
      </template>
    </div>
    <div class="message-item__date">
      {{ message.time }}
    </div>
  </div>
</template>
<script>
const emojiRegexp = /([\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}])/ug
export default {
  name: 'MessageBubble',

  props: {
    message: {
      type: Object,
      required: true
    },

    channel: {
      type: Object,
      required: true
    },

    isOwn: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    messageRows () {
      //let message = this.message.message.replace(emojiRegexp, '<span class="emoji" data-mention-id="any">$1</span>')
      const message = this.message.message
      const payload = message.payload

      if (!payload) {
        return [{ type: 'text', content: ''}]
      }

      const rows = payload.split('\n').map(row => {
        const rowItems = []
        let res, previousIndex = 0

        while ((res = emojiRegexp.exec(row)) !== null) {
          const textLength = res.index - previousIndex
          rowItems.push({ type: 'text', content: row.substr(previousIndex, textLength)})
          rowItems.push({ type: 'emoji', content: res[0] })

          previousIndex = res.index + 2
        }

        if (previousIndex < row.length) {
          rowItems.push({type: 'text', content: row.substr(previousIndex)})
        }

        return rowItems
      })

      return rows
    }
  },

  methods: {
    onScroll ({ scrollTop, boxOffset }) {
      const offsetTop = this.$el.offsetTop - boxOffset.top
      const height    = this.$el.offsetHeight

      const isVisible = scrollTop <= offsetTop && scrollTop + boxOffset.height >= offsetTop + height

      //console.log(this.message.id, isVisible)

      return isVisible
    },

    scrollTo () {
      this.$emit('scroll-to', {
        offsetTop: this.$el.offsetTop + this.$el.offsetHeight
      })
    }
  },

  mounted () {
    if (this.message.unseen) {

      this.$emit('unseen', {
        offsetTop: this.$el.offsetTop,
        id: this.message.id,
        date: this.message.date,
        seenCallback: this.onScroll
      })
    }

    if (this.channel.last_seen_id === this.message.id || this.message.new) {
      this.scrollTo()
    }
  }
}
</script>

<template>
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-stroke" d="M11.1665 13.544C11.8299 13.7366 12.5173 13.834 13.2081 13.8333C14.3191 13.8349 15.4156 13.5816 16.4133 13.0929C16.4428 12.3958 16.2443 11.708 15.8477 11.1339C15.4512 10.5598 14.8783 10.1306 14.2159 9.91138C13.5535 9.69216 12.8377 9.69486 12.1769 9.91908C11.5162 10.1433 10.9465 10.5768 10.5543 11.1539M11.1665 13.544V13.5417C11.1665 12.676 10.944 11.8617 10.5543 11.1539M11.1665 13.544V13.6264C9.66961 14.5279 7.95472 15.0029 6.20735 15C4.39435 15 2.69802 14.4983 1.2498 13.6264L1.24902 13.5417C1.24843 12.4407 1.61428 11.3708 2.2889 10.5008C2.96352 9.63068 3.90853 9.00988 4.97496 8.73624C6.04138 8.46259 7.16855 8.55166 8.17876 8.9894C9.18897 9.42714 10.0248 10.1887 10.5543 11.1539M8.83313 3.625C8.83313 4.32119 8.55657 4.98887 8.06428 5.48115C7.572 5.97343 6.90432 6.24999 6.20813 6.24999C5.51194 6.24999 4.84426 5.97343 4.35198 5.48115C3.85969 4.98887 3.58313 4.32119 3.58313 3.625C3.58313 2.9288 3.85969 2.26113 4.35198 1.76884C4.84426 1.27656 5.51194 1 6.20813 1C6.90432 1 7.572 1.27656 8.06428 1.76884C8.55657 2.26113 8.83313 2.9288 8.83313 3.625ZM15.2498 5.375C15.2498 5.91648 15.0347 6.43578 14.6518 6.81867C14.2689 7.20156 13.7496 7.41666 13.2081 7.41666C12.6666 7.41666 12.1473 7.20156 11.7644 6.81867C11.3816 6.43578 11.1665 5.91648 11.1665 5.375C11.1665 4.83351 11.3816 4.31421 11.7644 3.93132C12.1473 3.54843 12.6666 3.33333 13.2081 3.33333C13.7496 3.33333 14.2689 3.54843 14.6518 3.93132C15.0347 4.31421 15.2498 4.83351 15.2498 5.375Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'SmallUsersIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#FFFFFF',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

<template>
  <div class="invitation-item">
    <div class="invitation-item__content">
      <user-photo :user="member"/>
      <div class="invitation-item__title">
        <div class="invitation-item__fullname">{{ fullName }}</div>
        <div class="invitation-item__email">{{ description }}</div>
      </div>
    </div>

    <ui-button ui-class="plain" class="invitation-item__delete" @click="onRemove">
      <close-icon/>
    </ui-button>
  </div>
</template>

<script>
import UiButton from 'scorework-ui/src/components/UiButton'
import UserPhoto from "../ui/UserPhoto"
import CloseIcon from "../icons/CloseIcon"

export default {
  name: 'InviteMembers',

  components: { UiButton, UserPhoto, CloseIcon },

  props: {
    member: {
      type: Object,
      required: true,
    }
  },

  computed: {
    fullName () {
      if (this.member.member_id) {
        return this.member.full_name
      }

      if (this.member.first_name) {
        return `${this.member.first_name} ${ this.member.last_name}`
      }

      return this.member.email
    },

    description () {
      return this.member.member_id ? this.member.email : this.$t('settings.members.newUser')
    }
  },

  methods: {
    onRemove () {
      this.$emit('remove')
    }
  }
}
</script>
<style lang="sass">
.invitation-item
  display: flex
  flex-flow: row nowrap
  justify-content: space-between
  align-items: center
  padding: 8px 6px
  border-bottom: 0.5px solid var(--grey-light-color-60)

  &__content
    display: flex
    flex-flow: row nowrap

  &__title
    margin-left: 12px

  &__fullname
    margin-bottom: 2px

  &__email
    color: var(--grey-color-100)

  &__delete
    padding: 0
    width: 16px
</style>

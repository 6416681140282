<template>
  <div class="pages-content">
    <div class="pages-content__header">
      <div class="pages-content__header-main">
        <div class="pages-crumbs">
          <div class="pages-crumbs-item">
            <div class="pages-crumbs-item__label">
              <dashboard-icon class="pages-crumbs-item__home"/>

              {{ title }}
            </div>
          </div>
        </div>
      </div>

      <div class="pages-content__header-additional">

      </div>
    </div>

    <div class="pages-content__wrapper">
      <div class="page-dashboard">
        <recently-updated-pages :project-code="projectCode"/>

      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import RecentlyUpdatedPages from '@/components/pages/RecentlyUpdatedPages.vue'
  import DashboardIcon from '@/components/icons/DashboardIcon';

  export default {
    name: 'PageDashboard',

    components: {DashboardIcon, RecentlyUpdatedPages },

    props: {
      projectCode: {
        type: String,
      }
    },

    data () {
      return {

      }
    },

    computed: {
      ...mapGetters(['authUser', 'projects']),

      title () {
        if (this.projectCode === 'common') {
          return this.$t('knowledge.commonPages')
        }

        const project = this.projects.find((item) => item.code === this.projectCode)

        return project ? project.name : this.$t('knowledge.title')
      }
    },

    methods: {
      onAttachFile () {

      },

      onEditorInit ({ editor }) {
        this.editor = editor
      },

      onContentChange ({ getJSON, getHTML }) {
        console.log(JSON.stringify(getJSON()))
        console.log(getHTML())
      }
    }
  }
</script>
<style lang="sass">
.page-content
  flex: 1
  height: 100%
  display: flex
  flex-flow: column

  &__header
    display: flex
    justify-content: space-between
    padding: 20px 20px

    &-title
      font-size: 20px
      font-weight: 500

  &__wrapper
    flex: 1
    overflow: scroll

.page-dashboard
  margin: 0 auto
  width: 680px

</style>

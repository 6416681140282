<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <ellipse cx="20" cy="12.5" rx="11" ry="11.5" class="path-stroke" stroke="#6283FF" stroke-width="2"/>
  <circle cx="12.5" cy="26.5" r="11.5" class="path-stroke" stroke="#6283FF" stroke-width="2"/>
  <circle cx="27.5" cy="26.5" r="11.5" class="path-stroke" stroke="#6283FF" stroke-width="2"/>
</svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'SpheresIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#8B92AB',
      defaultWidth: 14,
      defaultHeight: 14
    }
  }
}
</script>

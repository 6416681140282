<template>
  <div class="db-active-task">
    <div class="db-active-task__main">
      <div class="db-active-task__line">
        <div>
          <el-tooltip
              effect="dark"
              :content="$t('tasks.numberIsCopied')"
              placement="top"
              transition="sw-slide-top"
              :manual="true"
              :value="showNumberTooltip"
          >
            <div class="db-active-task__number" @click="onCopyNumber">
              {{ taskNumber }}
            </div>
          </el-tooltip>
        </div>

        <div class="db-active-task__params">
          <div class="sphere-badge">
            {{ stage.name }}
          </div>
        </div>
      </div>

      <div class="db-active-task__title" @click="onOpenTask">
        {{ task.name }}
      </div>

      <div class="db-active-task__line">
        <div class="db-active-task__params db-active-task__author">
          от
          <div class="db-active-task__author-name">
            <user-photo
                :user="task.creator"
                size="xs"
                :show-online="false"
                class="db-active-task__author-photo"
            />
            {{ task.creator.first_name }} {{ task.creator.last_name }}
          </div>
        </div>

        <div class="db-active-task__params">
          <div class="db-active-task__params-item db-active-task__score">
            <clock-icon class="db-active-task__score-icon"/>
            {{ formattedScore }}
          </div>

          <div class="db-active-task__params-item db-active-task__score" v-if="task.points">
            <points-icon class="db-active-task__score-icon"/>
            {{ $tc('tasks.pointsLabel', task.points, { points: task.points }) }}
          </div>
        </div>
      </div>
    </div>

    <div class="db-active-task__footer" :class="footerClasses">
      <div class="db-active-task__progress">
        <circle-progress
          :diameter="54"
          :stroke-width="5"
          :inner-stroke-width="5"
          :completed-steps="timeSpent > task.score ? task.score : timeSpent"
          :total-steps="task.score"
          :class="circleClasses"
        >
          <div v-if="taskStatus === 'overdue'">
            <fire-icon class="db-active-task__progress-icon"/>
          </div>
          <div class="db-active-task__percent" v-else>
            {{ progressPercent }}%
          </div>
        </circle-progress>

        <div class="db-active-task__progress-data">
          <div class="db-active-task__progress-spent">
            {{ spentTimeLabel }}
          </div>

          <el-tooltip
              effect="dark"
              :content="$t(estimatedLabelTooltip)"
              placement="top"
              transition="sw-slide-top"
          >
          <div class="db-active-task__progress-date">
            {{ getEstimatedTimeLabel }}
          </div>
          </el-tooltip>
        </div>
      </div>

      <div class="db-active-task__actions">
        <ui-loading-button
          ui-class="size_md"
          :loading="processingAction"
          v-if="task.status === 'in_progress'"
          @click.prevent="onChangeProgress('complete', true)"
        >
          {{ $t('tasks.actions.finish') }}
        </ui-loading-button>

        <ui-loading-button
          ui-class="size_md"
          @click.prevent="onChangeProgress('start')"
          v-if="task.status === 'open'"
          :loading="processingAction"
        >
          {{ $t('tasks.actions.start') }}
        </ui-loading-button>

        <el-tooltip
          effect="dark"
          :content="$t('tasks.actions.pause')"
          placement="top"
          transition="sw-slide-top"
          v-if="task.status === 'in_progress'"
        >
          <ui-button
            ui-class="size_md light icon"
            class="ml-2"
            @click.prevent="onChangeProgress('pause')"
          >
            <pause-icon class="task-card-controls__btn-icon"/>
          </ui-button>
        </el-tooltip>
      </div>
    </div>

    <confirm-task-complete-modal
      :is-processing="taskComplete.isProcessing"
      :stages="taskComplete.stages"
      :assignee="taskComplete.assignee"
      v-if="taskComplete.showConfirmation"
      @close="taskComplete.showConfirmation = false"
      @submit="onConfirmComplete"
    />
  </div>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'

import CircleProgress from '@/components/ui/CircleProgress.vue'
import PauseIcon from '@/components/icons/PauseIcon.vue'
import PointsIcon from '@/components/icons/PointsIcon.vue'
import UserPhoto from '@/components/ui/UserPhoto'
import taskNumber from '@/components/tasks/mixins/taskNumber'
import UiButton from 'scorework-ui/src/components/UiButton'
import UiLoadingButton from '@/components/ui/UiLoadingButton.vue'
import progressMixin from '@/components/tasks/mixins/progressMixin'
import taskItemMixin from '@/components/tasks/mixins/taskItemMixin'
import { scoreToLabel } from '@/services/scoring'
import taskCompleteMixin from '@/components/tasks/mixins/taskCompleteMixin'

const { mapActions: tasksActions } = createNamespacedHelpers('tasks')
const { mapActions: dashboardActions } = createNamespacedHelpers('dashboard')

export default {
  name: 'BoardTaskItem',

  components: { CircleProgress, PauseIcon, PointsIcon, UiButton, UiLoadingButton, UserPhoto },

  mixins: [taskNumber, taskItemMixin, taskCompleteMixin, progressMixin],

  props: {
    task: {
      type: Object,
      required: true,
    }
  },

  data () {
    return {
      isScoreSelectorOpen: false,
      showAddButton: false,
      processingAction: false,
    }
  },

  computed: {
    ...mapGetters(['stages', 'workspace']),

    stage () {
      return this.stages.find(stage => stage.id === this.task.stage_id)
    },

    timeSpent () {
      return this.task.status === 'in_progress' && this.taskState.processing
          ? this.taskState.processing.processed_minutes
          : this.task.actual_progress
    },

    spentTimeLabel () {
      if (this.task.status === 'open') {
        return this.$t('tasks.progress.idle')
      }

      return scoreToLabel(this.timeSpent)
    },

    circleClasses () {
      const classes = []
      if (this.taskStatus === 'overdue') {
        classes.push('circle_overdue')
      }

      return classes
    },

    footerClasses () {
      const classes = []

      if (this.taskStatus === 'overdue') {
        classes.push('db-active-task__footer_overdue')
      }

      return classes
    }
  },

  methods: {
    ...dashboardActions(['refreshTask']),
    ...tasksActions(['changeProgress']),

    onChangeProgress (action, confirmed = false, params = {}) {
      this.processingAction = true
      return this.changeProgress({ taskId: this.task.id, action, confirmed, ...params })
        .then((data) => {
          const currentAssignee = this.task.state.assignee || {}
          this.checkNeedConfirmation(data, { ...currentAssignee })
        })
        .finally(() => {
          this.processingAction = false
        })
    },
  }
}
</script>
<style lang="sass">
  .db-active-task
    border-radius: 12px
    background: var(--block-bg-color)
    box-sizing: border-box
    box-shadow: var(--block-shadow)

    &__main
      padding: 16px

    &__line
      align-items: center
      display: flex
      justify-content: space-between

    &__number
      color: var(--text-light-color)
      cursor: pointer
      font-size: 13px

    &__params
      align-items: center
      display: flex

      &-item
        display: flex
        align-items: center
        margin-right: 12px

        &:last-child
          margin-right: 0

    &__title
      cursor: pointer
      margin: 12px 0 18px
      font-size: 14px
      line-height: 20px
      max-height: 40px
      font-weight: 500
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-line-clamp: 2
      line-clamp: 2
      -webkit-box-orient: vertical

    &__score
      color: var(--text-light-color)
      cursor: default
      font-size: 12px

      &-icon
        margin-right: 6px
        width: 16px
        height: 16px

        .path-stroke
          stroke: var(--text-light-color)

        .path-fill
          fill: var(--text-light-color)

    &__author
      font-size: 12px
      color: var(--text-light-color)

      &-photo
        margin-right: 6px

      &-name
        align-items: center
        color: var(--text-primary-color)
        display: flex
        margin-left: 6px

    &__footer
      align-items: center
      background: var(--color-grey-bg)
      border-radius: 0 0 12px 12px
      display: flex
      justify-content: space-between
      padding: 16px

      &_overdue
        .radial-progress-bar
          &__circle-back
            stroke: var(--alert-light-color)

          &__circle
            stroke: var(--alert-color)

        .db-active-task__progress-spent
          color: var(--alert-color)

    &__percent
      color: var(--primary-color)
      font-size: 12px
      font-weight: 500

    &__progress
      align-items: center
      display: flex
      cursor: default

      &-data
        margin-left: 12px

      &-spent
        color: var(--primary-color)
        font-size: 13px
        font-weight: 500
        margin-bottom: 6px

      &-date
        color: var(--text-light-color)
        font-size: 12px

    &__actions
      align-items: center
      display: flex
      justify-content: flex-end

    &__progress-icon
      width: 18px
      height: 18px

</style>

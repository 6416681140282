<template>
  <editor-content
    class="ui-magic-field"
    :editor="editor"
  />
</template>
<script>
  import { Editor, EditorContent } from '@tiptap/vue-2'
  import Placeholder from '@tiptap/extension-placeholder'
  import Paragraph from "@tiptap/extension-paragraph"
  import Text from '@tiptap/extension-text'
  import { SingleDocument} from "@/components/ui/textEditor/marks"

  export default {
    name: 'UiMagicField',

    components: {
      EditorContent,
    },

    props: {
      value: {
        required: true,
        type: String
      },

      placeholder: {
        type: String,
        default: ''
      },

      focused: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        initialValue: this.value,
        editor: null,
      }
    },

    watch: {
      value () {
        if (this.editor.isFocused) {
          return
        }

        this.editor.commands.setContent(this.value)
      }
    },

    mounted() {
      this.editor = new Editor({
        extensions: [
          SingleDocument,
          Paragraph,
          Text,
          Placeholder.configure({
            emptyEditorClass: 'is-editor-empty',
            emptyNodeClass: 'is-empty',
            placeholder: this.placeholder,
            showOnlyWhenEditable: true,
            showOnlyCurrent: true,
          }),
        ],
        onUpdate: ({ editor }) => {
          this.$emit('input', editor.getHTML().replace(/(<([^>]+)>)/gi, ''))
        },
        content: this.value,
      })
    },

    beforeDestroy() {
      if (this.editor) {
        this.editor.destroy()
      }
    },
  }
</script>
<style lang="sass">
  .ui-magic-field
    .ProseMirror
      &:focus, &:active
        outline: none

      p
        margin: 0

      p.is-editor-empty:first-child::before
        content: attr(data-placeholder)
        float: left
        color: var(--grey-color-100)
        pointer-events: none
        height: 0
</style>

<template>
  <div class="goals-progress">
    <div class="goals-progress__header">
      <div class="goals-progress__title">
        {{ $t('layout.goals') }}
      </div>

      <goal-filters/>
    </div>
    <div class="goals-progress__columns">
      <goal-column
        v-for="goal in goals"
        :key="goal.id"
        :goal="goal"
        :tasks="getGoalTasks(goal.id)"
        :finished="getGoalFinishedTasks(goal.id)"
        @create-task="onCreateTask"
        @open-task="onOpenTask"
        @goal-edit="onGoalEdit"
        @goal-complete="onGoalComplete"
      />
    </div>

    <ModalTaskCard
      v-if="modalTask.id !== null"
      :task-id="modalTask.id"
      :member-id="modalTask.memberId"
      :stage-id="modalTask.stageId"
      :after-task-id="modalTask.afterTaskId"
      :sphere-id="modalTask.sphereId"
      :target-id="modalTask.targetId"
      @close="onCloseTask"
    />

    <target-modal v-if="editGoalId" :target-id="editGoalId" @close="editGoalId = null"/>
    <confirm-finish-target-modal
      :target="goalToComplete"
      v-if="goalToComplete"
      @submit="doGoalComplete"
      @close="goalToComplete = null"
    />
  </div>
</template>
<script>
  import { createNamespacedHelpers, mapActions, mapGetters } from 'vuex'

  import GoalFilters from '@/components/goalsProgress/GoalFilters'
  import GoalColumn from '@/components/goalsProgress/GoalColumn'
  import TargetModal from '@/components/targets/TargetModal'
  import ConfirmFinishTargetModal from '@/components/targets/ConfirmFinishTargetModal'
  import withModalTask from '@/components/tasks/mixins/withModalTask'
  import socketService from '@/services/socket'
  import goalsProgressSocket from '@/store/socket/goalsProgress'

  const { mapActions: goalsProgressActions, mapGetters: goalsProgressGetters } = createNamespacedHelpers('goalsProgress')

  export default {
    name: 'GoalsProgress',

    components: { GoalFilters, GoalColumn, TargetModal, ConfirmFinishTargetModal },

    mixins: [withModalTask],

    data () {
      return {
        socket: null,
        editGoalId: null,
        goalToComplete: null,
      }
    },

    computed: {
      ...mapGetters(['baseStage']),
      ...goalsProgressGetters(['goals', 'goalTasks', 'finishedTasks']),
    },

    methods: {
      ...goalsProgressActions(['fetchGoalsProgress']),
      ...mapActions(['finishTarget']),

      getGoalTasks (goalId) {
        return this.goalTasks[goalId] || []
      },

      getGoalFinishedTasks (goalId) {
        return this.finishedTasks[goalId] || []
      },

      onCreateTask ({ stageId = null, ...fields }) {
        this.modalTask = {
          ...this.modalTask,
          ...fields,
          id: 0,
          stageId: stageId || this.baseStage.id,
        }

        this.changeRoutePath()
      },

      onGoalEdit (goalId) {
        this.editGoalId = goalId
      },

      onGoalComplete (goal) {
        this.goalToComplete = goal
      },

      doGoalComplete () {
        this.finishTarget({ id: this.goalToComplete.id })
          .then(() => {
            this.$notify({
              title: this.$t('goals.goalClosed'),
              message: this.$t('goals.goalWasSuccessfullyClosed'),
              position: 'bottom-right',
              type: 'success'
            })
          })
          .finally(() => this.goalToComplete = null)
      },
    },

    created () {
      this.fetchGoalsProgress()
      this.socket = goalsProgressSocket(this.$store, this.$router, () => this.fetchGoalsProgress())
      this.socket.subscribe(socketService.socket)
    },

    beforeDestroy () {
      this.socket.unsubscribe(socketService.socket)
    }
  }
</script>
<style lang="sass">
  .goals-progress
    box-sizing: border-box
    display: flex
    flex-flow: column nowrap
    width: 100%

    &__header
      align-items: center
      display: flex
      justify-content: space-between
      background: var(--block-bg-color)
      padding: 18px 24px
      box-shadow: var(--block-shadow)

    &__title
      font-size: 22px
      font-weight: 500

    &__filters
      margin-top: 24px

    &__columns
      display: flex
      flex: 1
      padding: 0px 12px
      overflow-x: auto
      overflow-y: hidden
      overscroll-behavior-x: contain

      .goal-column:last-child
        .goal-column__spheres
          border-right: 0
</style>

<template>
<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="path-fill" d="M8 4.05838V1.49938C8.00032 1.30176 8.05913 1.10865 8.16902 0.944396C8.27891 0.780141 8.43496 0.652089 8.6175 0.576377C8.80004 0.500664 9.00091 0.480679 9.1948 0.51894C9.38868 0.557201 9.5669 0.651996 9.707 0.791378L16 6.99938L9.707 13.2064C9.61435 13.2996 9.50419 13.3735 9.38285 13.424C9.26152 13.4744 9.13141 13.5004 9 13.5004C8.86859 13.5004 8.73848 13.4744 8.61715 13.424C8.49581 13.3735 8.38565 13.2996 8.293 13.2064C8.20009 13.1136 8.12639 13.0034 8.07611 12.882C8.02584 12.7607 7.99997 12.6307 8 12.4994V10.0104C5.25 10.0784 2.245 10.5764 0 13.9994V12.9994C0 8.36638 3.5 4.55638 8 4.05838Z" fill="black"/>
</svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'ForwardIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

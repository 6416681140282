<template>
  <ui-form :model="form" :rules="rules" :submit-fn="submitFn" ref="form">
    <div class="workspace-form">
      <div class="workspace-form__data">
        <div class="form-group">
          <div class="form-control">
            <text-field
                prop="name"
                v-model="form.name"
                :label="$t('settings.general.workspaceName')"
                class="ui-text-field"
                @focus="e => onFocusInput('name', e)"
                :focused="true"
            />
          </div>

          <div class="form-control" v-if="showCurrencies">
            <form-dropdown
                :options="currencies"
                :value="form.currency_id"
                :multiple="false"
                prop="currency_id"
                popover-class="ui-dropdown_no-border"
                :label="$t('settings.general.currency')"
                @input="onCurrencyChange"
            >
              <template v-slot:default>
                <div class="form-dropdown__selected">
                  {{ $t(`currencies.${form.currency_id}`) }}
                  <chevron-down class="form-dropdown__chevron"/>
                </div>
              </template>

              <template v-slot:option="{ option }">
                <div class="dropdown-sphere-item">
                  {{ $t(`currencies.${option.id}`) }}
                </div>
              </template>
            </form-dropdown>
          </div>
        </div>

        <div class="workspace-form__submit">
          <ui-button @click.prevent="onSubmit" ui-class="block">{{ $t(submitTitle) }}</ui-button>
        </div>
      </div>

      <div class="workspace-form__photo">
        <logo-uploader :model="workspace" @change="onLogoChanged"/>
      </div>
    </div>
  </ui-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import {required } from "vuelidate/lib/validators"
import { handleAsyncErrors } from 'scorework-ui/src/components/form/helpers'
import UiForm from 'scorework-ui/src/components/form/UiForm'
import TextField from 'scorework-ui/src/components/TextField'
import UiButton from 'scorework-ui/src/components/UiButton'

import ChevronDown from "@/components/icons/ChevronDown"
import FormDropdown from "@/components/ui/FormDropdown"

import LogoUploader from "@/components/settings/LogoUploader"

export default {
  name: "GeneralSettings",

  components: {
    UiForm, TextField, UiButton, LogoUploader,
    FormDropdown, ChevronDown,
  },

  props: {
    workspace: {
      type: Object,
      required: true
    },

    submitTitle: {
      type: String,
      default: 'common.save'
    },

    withPrefix: {
      type: Boolean,
      default: true
    },

    showCurrencies: {
      type: Boolean,
      default: true,
    }
  },

  data () {
    return {
      form: {
        name: '',
        currency_id: 'RUB',
      },
      rules: {
        name: {
          rules: { required },
          messages: {
            required: this.$t('settings.general.errors.name.required')
          }
        }
      },
      currencies: [
        { id: 'RUB' },
        { id: 'USD' },
        { id: 'EUR' },
        { id: 'GBP' },
      ]
    }
  },

  computed: {
    ...mapGetters(['authUser']),
  },

  methods: {
    ...mapActions(['updateWorkspace', 'updateWorkspaceLogo']),

    submitFn () {
      console.log(this.form.name)
      this.updateWorkspace(this.form)
        .catch(handleAsyncErrors(this.rules, () => this.$refs.form.validate()))
        .then(() => {
          this.$emit('submit')
        })
    },

    onSubmit () {
      this.$refs.form.submit()
    },

    onFocusInput (name) {
      this.$emit('focus-field', name)
    },

    onCurrencyChange (currency) {
      this.form.currency_id = currency.id
    },

    async onLogoChanged (file) {
      const formData = new FormData()
      formData.append('image', file)

      try {
        await this.updateWorkspaceLogo(formData)
        this.$notify({
          title: this.$t('common.changesSaved'),
          message: this.$t('settings.general.notifications.logo'),
          position: 'bottom-right',
          type: 'success'
        })
      } catch (e) {
        this.$notify({
          title: this.$t('common.error'),
          message: this.$t('settings.members.notifications.failed'),
          position: 'bottom-right',
          type: 'error'
        })
      }
    }
  },

  created () {
    const {
      name,
      currency_id,
    } = this.workspace

    this.form = {
      ...this.form,
      name, currency_id,
    }
  }
}
</script>

<template>
<div class="sw-task-card">
  <div class="sw-task-card__head">
    <div class="sw-task-card__head-content sw-task-card__wrapper">
      <div class="sw-task-card__head-info">
        <div class="sw-task-card__number">
          <el-skeleton-item variant="text" style="width: 200px; height: 20px;"/>
        </div>
      </div>

      <div class="sw-task-card__head-actions">
        <el-skeleton-item variant="text" style="width: 20px; height: 20px;"/>
        <el-skeleton-item variant="text" style="width: 20px; height: 20px;" class="ml-2"/>
      </div>
    </div>
  </div>
  <div class="sw-task-card__body">
    <div class="sw-task-card__section sw-task-card__section_bordered">
      <div class="sw-task-card__wrapper">
        <el-skeleton-item variant="text" style="width: 70%; height: 30px; margin-bottom: 16px;"/>

        <div class="sw-task-card__state">
          <div class="sw-task-card__stage-line">
            <el-skeleton-item variant="text" style="height: 30px; width: 150px;"/>
          </div>

          <div class="sw-task-card__badges">
            <el-skeleton-item variant="text" style="height: 30px; width: 120px;"/>

            <el-skeleton-item variant="text" style="height: 30px; width: 100px;"/>

          </div>
        </div>
      </div>
    </div>

    <div class="sw-task-card__section">
      <div class="sw-task-card__wrapper">
        <div class="sw-task-card__params">
          <el-skeleton-item variant="text" style="height: 30px; width: 150px;"/>

          <el-skeleton-item variant="text" style="height: 30px; width: 120px;"/>

          <el-skeleton-item variant="text" style="height: 30px; width: 100px;"/>
        </div>

        <div class="task-state">
          <div class="sw-task-progress">
            <div class="sw-task-progress__content">
              <div class="sw-task-progress__status">
                <div class="task-state-status">
                  <div class="task-state-status__square">
                    <el-skeleton-item variant="text" style="height: 24px; width: 24px;"/>
                  </div>

                  <div class="task-state-status__title">
                    <el-skeleton-item variant="text" style="height: 20px; width: 120px;"/>
                  </div>
                </div>


              </div>

              <div class="sw-task-progress__time">
                <el-skeleton-item variant="text" style="height: 20px; width: 150px;"/>
              </div>
            </div>

            <div class="sw-task-progress__bar sw-task-progress__bar_in_progress"></div>

            <div class="sw-task-progress__stats">
              <div class="sw-task-progress__stats-time">
                <div class="task-processed-percent">
                  <el-skeleton-item variant="text" style="height: 20px; width: 20px;"/>

                  <el-skeleton-item variant="text" style="height: 20px; width: 20px;"/>
                </div>

                <el-skeleton-item variant="text" style="height: 20px; width: 100px;"/>

              </div>
            </div>
          </div>

          <div class="task-card-controls">
            <div class="task-card-controls__btn-set">
              <el-skeleton-item variant="text" style="height: 32px; width: 32px;" class="mr-2"/>
              <el-skeleton-item variant="text" style="height: 32px; width: 32px;"/>
            </div>
            <div class="task-card-controls__btn-set">
              <el-skeleton-item variant="text" style="height: 32px; width: 100px;"/>
            </div>
          </div>
        </div>

        <div class="sw-task-card__description" style="margin-top: 24px;">
          <el-skeleton-item variant="text" style="height: 18px; width: 100%;" class="mb-1"/>
          <el-skeleton-item variant="text" style="height: 18px; width: 100%;" class="mb-1"/>
          <el-skeleton-item variant="text" style="height: 18px; width: 80%;" class="mb-1"/>
          <el-skeleton-item variant="text" style="height: 18px; width: 60%;" class="mb-1"/>
          <el-skeleton-item variant="text" style="height: 18px; width: 80%;" class="mb-1"/>
          <el-skeleton-item variant="text" style="height: 18px; width: 40%;" class="mb-1"/>
        </div>

        <div class="ui-tabs task-timeline-tabs" style="margin-top: 24px;">
          <el-skeleton-item variant="text" style="height: 24px; width: 120px;"/>
          <el-skeleton-item variant="text" style="height: 24px; width: 120px;"/>
        </div>

        <el-skeleton-item variant="text" style="height: 72px; width: 100%;" class="mt-2"/>

        <div class="task-timeline">
          <div class="timeline-item" v-for="i in 3" :key="i">
            <div class="timeline-item__line">
              <div class="timeline-item__circle">
                <el-skeleton-item variant="text" style="height: 24px; width: 24px;"/>
              </div>
            </div>

            <div class="timeline-item__data">
              <div class="timeline-item__head">
                <el-skeleton-item variant="text" style="height: 20px; width: 20px;"/>


                <div class="timeline-item__action">
                  <span class="timeline-item__author" style="height: 20px;">
                     <el-skeleton-item variant="text" style="height: 20px; width: 120px;"/>
                  </span>

                  <el-skeleton-item variant="text" style="height: 20px; width: 100px;"/>
                </div>

                <div class="timeline-item__date" style="height: 20px;">
                  <el-skeleton-item variant="text" style="height: 20px; width: 100px;"/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'TaskCardSkeleton',

  props: {
    taskId: {
      type: Number
    }
  }
}
</script>

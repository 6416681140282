import {
  ProgressStatusOverdue,
  StatusInProgress,
  StatusFinished,
  StatusOpen,
  StatusPaused
} from "@/helpers/tasks";
import ClockIcon from "@/components/icons/ClockIcon"
import FinishedIcon from "@/components/icons/FinishedIcon"
import FireIcon from "@/components/icons/FireIcon"
import Flag from "@/components/icons/Flag"
import PauseNarrowIcon from "@/components/icons/PauseNarrowIcon"
import PlayOutlineIcon from "@/components/icons/PlayOutlineIcon"
import SandclockIcon from "@/components/icons/SandclockIcon"

const progressStatusIcons = {
  [StatusOpen]: 'sandclock-icon',
  [StatusPaused]: 'pause-narrow-icon',
  [StatusInProgress]: 'play-outline-icon',
  [StatusFinished]: 'finished-icon',
  [ProgressStatusOverdue]: 'fire-icon',
}

const timeStatusIcons = {
  [StatusOpen]: 'sandclock-icon',
  [StatusPaused]: 'sandclock-icon',
  [StatusInProgress]: 'flag',
  [StatusFinished]: 'flag',
  [ProgressStatusOverdue]: 'fire-icon',
}

export default {
  components: { ClockIcon, FinishedIcon, FireIcon, Flag, PauseNarrowIcon, PlayOutlineIcon, SandclockIcon },

  computed: {
    stateStatus () {
      if (this.task.status === StatusOpen && this.task.startedAt) {
        return StatusPaused
      }

      return this.task.status
    },

    progressStatus () {
      return this.stateStatus === StatusInProgress && this.isOverdue
        ? ProgressStatusOverdue
        : this.stateStatus
    },

    progressStatusIcon () {
      return progressStatusIcons[this.progressStatus]
    },

    timeStatusIcon () {
      return timeStatusIcons[this.progressStatus]
    },

    isOverdue () {
      if (!this.task.score) {
        return false
      }

      return this.processedTime > this.task.score
    },

    isFinished () {
      return this.task.status === StatusFinished
    },

    processedTime () {
      return this.task.processing
        ? this.task.processing.processed_minutes
        : this.task.actual_progress
    },

    timeLabelTooltip () {
      switch (this.stateStatus) {
        case StatusOpen:
        case StatusPaused:
          return 'tasks.progress.beProcessedAt'

        case StatusInProgress:
          if (!this.task.estimatedFinishAt) {
            return 'tasks.progress.estimatedFinishAtUnknown'
          }

          if (!this.task.score) {
            return 'tasks.progress.spentTime'
          }

          return 'tasks.progress.estimatedFinishAt'

        case StatusFinished:
          return 'tasks.progress.finishedAt'
      }

      return 'tasks.progress.noScore'
    },
  }
}

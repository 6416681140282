<template>
  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" d="M1.77778 1.22287C1.77778 0.987125 1.68413 0.761033 1.51743 0.594334C1.35073 0.427635 1.12464 0.333984 0.888889 0.333984C0.653141 0.333984 0.427048 0.427635 0.260349 0.594334C0.0936505 0.761033 0 0.987125 0 1.22287V11.8895C0 12.1253 0.0936505 12.3514 0.260349 12.5181C0.427048 12.6848 0.653141 12.7784 0.888889 12.7784C1.12464 12.7784 1.35073 12.6848 1.51743 12.5181C1.68413 12.3514 1.77778 12.1253 1.77778 11.8895V1.22287ZM8.62489 10.9562C8.70753 10.8737 8.7731 10.7756 8.81783 10.6677C8.86256 10.5598 8.88559 10.4441 8.88559 10.3273C8.88559 10.2105 8.86256 10.0948 8.81783 9.98693C8.7731 9.87902 8.70753 9.78098 8.62489 9.69843L6.37067 7.4451H15.1111C15.3469 7.4451 15.573 7.35144 15.7397 7.18475C15.9064 7.01805 16 6.79195 16 6.55621C16 6.32046 15.9064 6.09437 15.7397 5.92767C15.573 5.76097 15.3469 5.66732 15.1111 5.66732H6.37067L8.62489 3.4131C8.79156 3.2463 8.88515 3.02013 8.88507 2.78434C8.88499 2.54854 8.79124 2.32244 8.62444 2.15576C8.45765 1.98909 8.23148 1.8955 7.99569 1.89558C7.75989 1.89567 7.53379 1.98941 7.36711 2.15621L3.59644 5.92776C3.4298 6.09445 3.33619 6.3205 3.33619 6.55621C3.33619 6.79191 3.4298 7.01796 3.59644 7.18465L7.36711 10.9562C7.44967 11.0389 7.5477 11.1044 7.65561 11.1491C7.76352 11.1939 7.87919 11.2169 7.996 11.2169C8.11281 11.2169 8.22848 11.1939 8.33639 11.1491C8.4443 11.1044 8.54234 11.0389 8.62489 10.9562Z" fill="#27272E"/>
  </svg>
</template>
<script>
import iconMixin from '../iconMixin'

export default {
  name: 'ArrowLeftLineIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

<template>
  <div class="backlog-list backlog-list_padding">
    <transition-group name="zoom" tag="div" v-draggable-list="draggableList" v-if="!isLoading" appear>
      <StatusTaskItem
        v-for="(task, index) in backlogTasks"
        :key="task.id"
        :task="task"
        status="backlog"
        :index="index"
        @open="onOpenTask"
        @create="onCreateTask"
      />
      <div v-if="backlogTasks.length === 0" class="backlog-empty" :class="emptyClasses" key="no-items">
        <div class="backlog-empty__title">
          {{ $t('tasks.emptyBacklog') }}
        </div>
      </div>
    </transition-group>
  </div>
</template>
<script>
import StatusTaskItem from "@/components/tasks/StatusTaskItem"
import { createNamespacedHelpers } from "vuex"

const { mapActions: tasksActions, mapGetters: tasksGetters } = createNamespacedHelpers('tasks')
const trashHolder = document.createElement('div')

export default {
  name: 'Backlog',

  components: { StatusTaskItem },

  data () {
    return {
      draggableList: {
        name: `backlog-tasks`,
        callbacks: {
          onDragEnd:    this.onDragEnd,
          onDragStart:  this.onDragStart,
          onAfterItem:  this.onAfterItem,
          onBeforeItem: this.onBeforeItem,
          onLeaveList:  this.onLeaveList
        },
        options: {
          hideElement: true,
          externalDropLists: ['stage-tasks']
        }
      },

      isActiveDropZone: false,
      isLoading: false
    }
  },

  computed: {
    ...tasksGetters(['backlogTasks']),

    emptyClasses () {
      const classes = []

      if (this.isActiveDropZone) {
        classes.push(`backlog-empty_dropped`)
      }

      return classes
    }
  },

  methods: {
    ...tasksActions(['fetchBacklogTasks', 'sortTask', 'moveTaskToStatus']),

    loadTasks () {
      this.isLoading = true

      this.fetchBacklogTasks().finally(() => {
        this.isLoading = false
      })
    },

    // eslint-disable-next-line
    onDragStart (listEl, el, item) {
      if (el) {
        this.setupHolder(el)
        el.after(trashHolder)
      } else {
        if (this.backlogTasks.length === 0) {
          this.isActiveDropZone = true
        }
      }
    },

    onDragEnd (listEl, item, targetItem, position) {
      this.clearDraggingChanges(listEl)
      if (targetItem && targetItem.id !== item.id) {
        this.sortTask({ taskId: item.id, assigneeId: item.state.assignee_id, targetTaskId: targetItem.id, position })
      }

      if (this.backlogTasks.length === 0 && item.stage_id) {
        this.moveTaskToStatus({ taskId: item.id, assigneeId: item.state.assignee_id, status: 'backlog' })
      }
    },

    onAfterItem (listEl, targetEl) {
      if (!listEl.contains(trashHolder)) {
        this.setupHolder(targetEl)
      } else {
        listEl.removeChild(trashHolder)
      }
      listEl.insertBefore(trashHolder, targetEl.nextSibling)
    },

    onBeforeItem (listEl, targetEl) {
      if (!listEl.contains(trashHolder)) {
        this.setupHolder(targetEl)
      } else {
        listEl.removeChild(trashHolder)
      }
      listEl.insertBefore(trashHolder, targetEl)
    },

    onLeaveList (listEl) {
      this.clearDraggingChanges(listEl)
    },

    clearDraggingChanges (listEl) {
      if (listEl.contains(trashHolder)) {
        listEl.removeChild(trashHolder)
      }

      this.isActiveDropZone = false
    },

    setupHolder (el) {
      trashHolder.className = 'status-task-item-trash-holder'
      trashHolder.style.height = el.querySelector('.status-task-item__block').offsetHeight + 'px'
    },

    onOpenTask (data) {
      this.$emit('open-task', data)
    },

    onCreateTask (data) {
      this.$emit('create-task', data)
    }
  },

  created () {
    this.loadTasks()
  }
}
</script>
<style lang="sass">
.backlog-list
  width: 100%
  margin: 0 auto
  max-width: 840px
  box-sizing: border-box

  &_padding
    padding: 0 30px
</style>

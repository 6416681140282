<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="path-fill" fill-rule="evenodd" clip-rule="evenodd" d="M11.4902 34.5237C11.4884 34.5219 11.4866 34.5202 11.4848 34.5184L4.53554 27.5691L10.0156 22.089L10.0296 22.075L10.0431 22.0604L16.2346 15.357L14.7654 14L8.58738 20.6888L2.42052 26.8557C2.41841 26.8578 2.41631 26.8598 2.41421 26.8619L0.292893 28.9833C-0.0976314 29.3738 -0.0976317 30.007 0.292893 30.3975L9.48528 39.5899C9.87581 39.9804 10.509 39.9804 10.8995 39.5899L13.0208 37.4685C13.2534 37.2359 13.3475 36.9172 13.303 36.6149L23.9709 33.606C25.4306 33.1943 26.3277 31.7287 26.0302 30.2415C25.7228 28.7045 24.2512 27.6888 22.7051 27.9465L21.4235 28.1601L21.4002 28.1639L21.3771 28.1689L20.4257 28.3741L21.2071 27.5927L24.7071 24.0927L23.2929 22.6785L19.7929 26.1785L17.7929 28.1785L17.0816 28.8898L17.7971 29.5968L18.567 30.3577L18.9518 30.7381L19.4807 30.624L21.7757 30.129L23.0339 29.9192C23.5152 29.839 23.9734 30.1552 24.0691 30.6337C24.1617 31.0967 23.8824 31.553 23.428 31.6811L12.4634 34.7737C12.1175 34.8713 11.746 34.7757 11.4902 34.5237ZM10.0798 35.9418C10.0767 35.9387 10.0737 35.9356 10.0706 35.9326L3.12132 28.9833L2.41421 29.6904L10.1924 37.4685L10.8995 36.7614L10.0798 35.9418Z" fill="#6283FF"/>
  <path class="path-fill" fill-rule="evenodd" clip-rule="evenodd" d="M28.5143 34.6326L35.4636 27.6834L29.3657 21.5855L27.8244 20.0442L29.2386 18.63L30.7799 20.1713L37.582 26.9734C37.583 26.9743 37.5839 26.9753 37.5849 26.9762L38.292 27.6834L39.7062 29.0976C40.0967 29.4881 40.0967 30.1213 39.7062 30.5118L30.5138 39.7042C30.1233 40.0947 29.4901 40.0947 29.0996 39.7042L26.9783 37.5828C26.7456 37.3502 26.6516 37.0315 26.6961 36.7292L20.9067 35.0963L19.66 34.812L20.1045 32.8621L21.376 33.1519L21.4007 33.1576L21.4252 33.1645L27.5357 34.888C27.8841 34.9862 28.2583 34.8886 28.5143 34.6326ZM36.8778 29.0976L29.9285 36.0468L29.0996 36.8757L29.8067 37.5828L37.5849 29.8047L36.8778 29.0976Z" fill="#6283FF"/>
  <path class="path-stroke" d="M19.5879 16V23.5M19.5879 23.5V23.5C19.5879 24.3284 20.2595 25 21.0879 25V25C21.9163 25 22.5879 24.3284 22.5879 23.5V16V22C22.5879 22.8284 23.2595 23.5 24.0879 23.5V23.5C24.9163 23.5 25.5879 22.8284 25.5879 22V16V20C25.5879 20.8284 26.2595 21.5 27.0879 21.5V21.5C27.9163 21.5 28.5879 20.8284 28.5879 20V4L17.7602 4C17.0363 4 16.3689 4.39118 16.0152 5.02279L10.4488 14.9627C9.96293 15.8303 10.2265 16.9257 11.0539 17.4773V17.4773C11.909 18.0474 13.0622 17.836 13.6595 16.9998L15.0879 15H16.5879V22.805C16.5879 23.5073 17.0659 24.1195 17.7472 24.2898V24.2898C18.2688 24.4202 18.8205 24.2674 19.2007 23.8872L19.5879 23.5Z" stroke="#6283FF" stroke-width="2"/>
  <mask class="path-fill" id="path-4-inside-1" fill="white">
    <rect x="14.5879" width="15" height="5" rx="1"/>
  </mask>
  <rect class="path-stroke" x="14.5879" width="15" height="5" rx="1" stroke="#6283FF" stroke-width="4" mask="url(#path-4-inside-1)"/>
</svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'MembersIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#8B92AB',
      defaultWidth: 14,
      defaultHeight: 14
    }
  }
}
</script>

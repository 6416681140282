import { render, staticRenderFns } from "./WorkspaceLogo.vue?vue&type=template&id=94b6e620"
import script from "./WorkspaceLogo.vue?vue&type=script&lang=js"
export * from "./WorkspaceLogo.vue?vue&type=script&lang=js"
import style0 from "./WorkspaceLogo.vue?vue&type=style&index=0&id=94b6e620&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
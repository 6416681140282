<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M11.4828 6.52148C11.25 6.52148 11.0613 6.71019 11.0613 6.94303V14.9102C11.0613 15.1429 11.25 15.3318 11.4828 15.3318C11.7157 15.3318 11.9044 15.1429 11.9044 14.9102V6.94303C11.9044 6.71019 11.7157 6.52148 11.4828 6.52148Z" fill="#8B92AB"/>
      <path d="M6.50846 6.52148C6.27562 6.52148 6.08691 6.71019 6.08691 6.94303V14.9102C6.08691 15.1429 6.27562 15.3318 6.50846 15.3318C6.7413 15.3318 6.93001 15.1429 6.93001 14.9102V6.94303C6.93001 6.71019 6.7413 6.52148 6.50846 6.52148Z" fill="#8B92AB"/>
      <path d="M2.88329 5.35877V15.7447C2.88329 16.3586 3.10839 16.9351 3.50161 17.3487C3.89302 17.7635 4.43773 17.999 5.00781 18H12.9836C13.5538 17.999 14.0985 17.7635 14.4898 17.3487C14.883 16.9351 15.1081 16.3586 15.1081 15.7447V5.35877C15.8897 5.15129 16.3963 4.39614 16.2917 3.59405C16.187 2.79213 15.5038 2.19225 14.6949 2.19209H12.5367V1.66516C12.5391 1.22204 12.3639 0.796544 12.0502 0.483514C11.7365 0.170649 11.3104 -0.00356726 10.8673 5.53875e-05H7.12409C6.68098 -0.00356726 6.25482 0.170649 5.94113 0.483514C5.62744 0.796544 5.45224 1.22204 5.45471 1.66516V2.19209H3.29644C2.4876 2.19225 1.8044 2.79213 1.69967 3.59405C1.59511 4.39614 2.10162 5.15129 2.88329 5.35877ZM12.9836 17.1569H5.00781C4.28707 17.1569 3.72638 16.5378 3.72638 15.7447V5.39582H14.265V15.7447C14.265 16.5378 13.7043 17.1569 12.9836 17.1569ZM6.2978 1.66516C6.295 1.44566 6.38128 1.23439 6.53706 1.07944C6.69267 0.924489 6.90443 0.839357 7.12409 0.843144H10.8673C11.0869 0.839357 11.2987 0.924489 11.4543 1.07944C11.6101 1.23423 11.6964 1.44566 11.6936 1.66516V2.19209H6.2978V1.66516ZM3.29644 3.03517H14.6949C15.114 3.03517 15.4537 3.37488 15.4537 3.79395C15.4537 4.21303 15.114 4.55273 14.6949 4.55273H3.29644C2.87736 4.55273 2.53766 4.21303 2.53766 3.79395C2.53766 3.37488 2.87736 3.03517 3.29644 3.03517Z" fill="#8B92AB"/>
      <path d="M8.99576 6.52148C8.76292 6.52148 8.57422 6.71019 8.57422 6.94303V14.9102C8.57422 15.1429 8.76292 15.3318 8.99576 15.3318C9.22859 15.3318 9.4173 15.1429 9.4173 14.9102V6.94303C9.4173 6.71019 9.22859 6.52148 8.99576 6.52148Z" fill="#8B92AB"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="18" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'DeleteIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 10,
        defaultHeight: 10
      }
    }
  }
</script>

<template>
    <svg :width="iconWidth" :height="iconHeight" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect :width="iconWidth" :height="iconHeight" rx="11" :fill="circleBackground" :fill-opacity="circleOpacity"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.375 10.375V6.625C10.375 6.27982 10.6548 6 11 6C11.3452 6 11.625 6.27982 11.625 6.625V10.375H15.375C15.7202 10.375 16 10.6548 16 11C16 11.3452 15.7202 11.625 15.375 11.625H11.625V15.375C11.625 15.7202 11.3452 16 11 16C10.6548 16 10.375 15.7202 10.375 15.375V11.625H6.625C6.27982 11.625 6 11.3452 6 11C6 10.6548 6.27982 10.375 6.625 10.375H10.375Z" :fill="backgroundColor"/>
    </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'RoundPlus',

    mixins: [iconMixin],

    props: {
      circleBackground: {
        type: String,
        default: '#416AF7'
      },

      circleOpacity: {
        type: Number,
        default: 0.1
      }
    },

    data () {
      return {
        defaultBackground: '#416AF7',
        defaultWidth: 22,
        defaultHeight: 22
      }
    },

    computed: {
      plusWidth () {
        return this.iconWidth / 2
      },

      plusHeight () {
        return this.iconHeight / 2
      }
    }
  }
</script>

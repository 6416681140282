<template>
  <div class="form-control">
    <form-dropdown
        :options="sprintDurationOptions"
        :value="sprintDurationWeeks"
        :multiple="false"
        prop="sprintDurationWeeks"
        popover-class="ui-dropdown_no-border"
        :width-by-popover="true"
        :label="$t('settings.general.sprintDuration')"
        @input="onSprintDurationChange"
    >
      <template v-slot:default>
        <div class="form-dropdown__selected">
          {{ selectedSprintDuration }}
          <chevron-down class="form-dropdown__chevron"/>
        </div>
      </template>

      <template v-slot:option="{ option }">
        <div class="dropdown-sphere-item">
          {{ option.label }}
        </div>
      </template>
    </form-dropdown>
  </div>
</template>

<script>
import FormDropdown from "@/components/ui/FormDropdown";
import ChevronDown from "@/components/icons/ChevronDown";

export default {
  name: 'SprintDurationDropdown',

  components: { FormDropdown, ChevronDown },

  props: {
    value: {
      type: Number,
      required: true
    },
  },

  data () {
    return {
      sprintDurationWeeks: 2,
      sprintDurationOptions: [
        {value: 1, label: `1 ${this.$tc(`dateTime.week`, 1)}`},
        {value: 2, label: `2 ${this.$tc(`dateTime.week`, 2)}`},
        {value: 3, label: `3 ${this.$tc(`dateTime.week`, 3)}`},
        {value: 4, label: `4 ${this.$tc(`dateTime.week`, 4)}`},
      ]
    }
  },

  computed: {
    selectedSprintDuration () {
      const sprintDuration = this.sprintDurationOptions.find(item => item.value === this.sprintDurationWeeks)

      return sprintDuration.label
    },
  },

  methods: {
    onSprintDurationChange (sprintDurationWeeks) {
      this.sprintDurationWeeks = sprintDurationWeeks.value
      this.$emit('input', sprintDurationWeeks)
    },
  },

  created () {
    this.sprintDurationWeeks = this.value
  }
}
</script>

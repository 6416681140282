<template>
  <div class="fx fx_center">
    <workspace-logo :workspace="model" size="lg" class="upload-photo">
      <template slot="hover_content">
        <label class="upload-hover">
          <upload-icon class="upload-hover__icon"/>
          <div>{{ $t('settings.upload') }}</div>
          <input type="file" accept="image/*" ref="file" @change="onFileChange" class="upload-hover__input"/>
        </label>
      </template>
    </workspace-logo>
  </div>
</template>
<script>
import WorkspaceLogo from "@/components/ui/WorkspaceLogo"
import UploadIcon from "@/components/icons/UploadIcon"

export default {
  name: 'LogoUploader',

  components: { WorkspaceLogo, UploadIcon },

  props: {
    model: {
      type: Object,
      required: true,
    }
  },

  data () {
    return {
      file: null
    }
  },

  methods: {
    async onFileChange () {
      this.file = this.$refs.file.files[0]

      this.$emit('change', this.file)
    }
  }
}
</script>

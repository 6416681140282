<template>
  <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-stroke" d="M7.65245 1.09788C6.57412 0.881326 5.45448 1.02636 4.46692 1.51053C3.47937 1.9947 2.67901 2.79098 2.1898 3.77605C1.70059 4.76112 1.54984 5.88001 1.76089 6.95943C1.97193 8.03885 2.533 9.01857 3.3572 9.74684C4.1814 10.4751 5.22273 10.9113 6.31992 10.9879C7.41711 11.0644 8.50893 10.7771 9.42629 10.1703C10.3436 9.56359 11.0353 8.67129 11.3943 7.63165C11.7532 6.592 11.7593 5.46302 11.4116 4.41955" stroke="#A3A9BD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path class="path-stroke" d="M12.3438 10.9033C13.4232 11.121 14.5444 10.9762 15.5331 10.4914C16.5219 10.0067 17.3229 9.20904 17.812 8.22239C18.301 7.23574 18.4506 6.11524 18.2376 5.03484C18.0246 3.95445 17.4609 2.97458 16.634 2.24736C15.8071 1.52014 14.7632 1.08624 13.6645 1.01302C12.5657 0.939802 11.4735 1.23136 10.5574 1.84243C9.64136 2.4535 8.95262 3.3499 8.59816 4.39248C8.24369 5.43506 8.24331 6.5655 8.5971 7.60832" stroke="#A3A9BD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'SphereIcon',

    mixins: [iconMixin],

    props: {
      viewBox: {
        type: String,
        default: '0 0 12 12'
      }
    },

    data () {
      return {
        defaultBackground: '#2C3E50',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

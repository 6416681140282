import Image from '@tiptap/extension-image'
import { Plugin, PluginKey } from '@tiptap/pm/state'

const ContentImage = Image.extend({
  addOptions () {
    return {
      ...this.parent?.(),
      uploadFunc: () => {},
    }
  },

  addAttributes () {
    return {
      ...this.parent?.(),
      fullSrc: {
        default: null,
        parseHTML: (el) => el.getAttribute('data-color'),
        renderHTML: (attributes) => {
          return {
            'data-src': attributes.fullSrc,
          }
        }
      }
    }
  },

  addProseMirrorPlugins() {
    const uploadFunc = this.options.uploadFunc

    return [
      new Plugin({
        key: new PluginKey('image'),
        props: {
          handlePaste(view, e) {
            const items = (e.clipboardData || e.originalEvent.clipboardData).items;
            Array.from(items).forEach(async (item) => {
              if (item.type.indexOf("image") !== 0) {
                return
              }

              e.preventDefault();
              const { schema } = view.state
              const image = item.getAsFile()

              const { url, previewUrl } = await uploadFunc(image)
              const node = schema.nodes.image.create({
                src: previewUrl,
                fullSrc: url
              })

              const transaction = view.state.tr.replaceSelectionWith(node)
              view.dispatch(transaction)
            })

            return false
          },

          handleDOMEvents: {
            drop (view, event) {
              const hasFiles = event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length;

              if (!hasFiles) {
                return;
              }

              const images = Array.from(event.dataTransfer.files).filter(file => /image/i.test(file.type));

              if (images.length === 0) {
                return;
              }

              event.preventDefault();
              const {
                schema
              } = view.state;
              const coordinates = view.posAtCoords({
                left: event.clientX,
                top: event.clientY
              });
              images.forEach(async image => {
                const { url, previewUrl } = await uploadFunc(image)
                const node = schema.nodes.image.create({
                  src: previewUrl,
                  fullSrc: url
                })
                const transaction = view.state.tr.insert(coordinates.pos, node)
                view.dispatch(transaction)
              });
            }
          },

        },
      }),
    ]
  },
})

export default ContentImage

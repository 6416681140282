export default function debounce(func, wait, immediate = false) {
  let timeout;

  return function () {
    const context = this
    const args = arguments

    const later = function () {
      timeout = null
      if (!immediate) {
        func.apply(context, args)
      }
    }

    clearTimeout(timeout)

    if (!immediate) {
      timeout = setTimeout(later, wait)
    } else {
      func.apply(context, args)
    }
  }
}

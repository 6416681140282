<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" d="M14.2222 13.3333C14.2222 13.5691 14.3159 13.7952 14.4826 13.9619C14.6493 14.1286 14.8754 14.2222 15.1111 14.2222C15.3469 14.2222 15.573 14.1286 15.7397 13.9619C15.9064 13.7952 16 13.5691 16 13.3333V0.888889C16 0.653141 15.9064 0.427049 15.7397 0.26035C15.573 0.0936507 15.3469 0 15.1111 0C14.8754 0 14.6493 0.0936507 14.4826 0.26035C14.3159 0.427049 14.2222 0.653141 14.2222 0.888889V13.3333ZM7.37511 2.71111C7.29247 2.79367 7.2269 2.8917 7.18217 2.99961C7.13744 3.10752 7.11441 3.22319 7.11441 3.34C7.11441 3.45681 7.13744 3.57248 7.18217 3.68039C7.2269 3.7883 7.29247 3.88634 7.37511 3.96889L9.62933 6.22222H0.888889C0.653141 6.22222 0.427048 6.31587 0.260349 6.48257C0.0936505 6.64927 0 6.87536 0 7.11111C0 7.34686 0.0936505 7.57295 0.260349 7.73965C0.427048 7.90635 0.653141 8 0.888889 8H9.62933L7.37511 10.2542C7.20844 10.421 7.11485 10.6472 7.11493 10.883C7.11501 11.1188 7.20876 11.3449 7.37556 11.5116C7.54235 11.6782 7.76852 11.7718 8.00431 11.7717C8.24011 11.7717 8.46622 11.6779 8.63289 11.5111L12.4036 7.73956C12.5702 7.57286 12.6638 7.34681 12.6638 7.11111C12.6638 6.87541 12.5702 6.64936 12.4036 6.48267L8.63289 2.71111C8.55034 2.62847 8.4523 2.5629 8.34439 2.51817C8.23648 2.47344 8.12081 2.45041 8.004 2.45041C7.88719 2.45041 7.77152 2.47344 7.66361 2.51817C7.5557 2.5629 7.45766 2.62847 7.37511 2.71111Z" fill="#27272E"/>
  </svg>
</template>
<script>
import iconMixin from '../iconMixin'

export default {
  name: 'ArrowRightLineIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

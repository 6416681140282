<template>
  <div class="settings-layout">
    <div class="settings-layout__menu">
      <div class="settings-menu">
        <template v-if="isAdmin">
          <div class="settings-menu__title">
            {{ $t('settings.menu.workspace.title') }}
          </div>

          <div class="settings-menu__items">
            <div
              v-for="item in activeWorkspaceItems"
              :key="item.name"
              @click="onClickItem(item)"
              class="settings-menu__item"
              :class="menuItemClass(item)"
            >
              {{ $t(`settings.menu.${item.label}`) }}
            </div>
          </div>
        </template>

        <template v-if="isProjectAdmin">
          <div class="settings-menu__title">
            {{ project.name }}
          </div>

          <div class="settings-menu__items">
            <div
              v-for="item in items"
              :key="item.name"
              @click="onClickItem(item)"
              class="settings-menu__item"
              :class="menuItemClass(item)"
            >
              {{ $t(`settings.menu.${item.label}`) }}
            </div>
          </div>
        </template>

        <div class="settings-menu__title">
          {{ $t('settings.menu.profile.title') }}
        </div>

        <div class="settings-menu__items">
          <div
            v-for="item in profileItems"
            :key="item.name"
            @click="onClickItem(item)"
            class="settings-menu__item"
            :class="menuItemClass(item)"
          >
            {{ $t(`settings.menu.${item.label}`) }}
          </div>
        </div>
      </div>
    </div>

    <div class="settings-layout__page">
      <router-view class="settings-layout__page-content" />
    </div>
  </div>
</template>
<script>
import authUserMixin from "@/mixins/authUserMixin"
import {mapGetters} from "vuex";

export default {
  name: 'SettingsLayout',

  mixins: [authUserMixin],

  data () {
    return {
      workspaceItems: [
        { label: 'workspace.settings', name: 'workspace.settings.general', isVisible: () => this.isAdmin },
        { label: 'workspace.rate', name: 'workspace.settings.rate', isVisible: () => this.isOwner },
        { label: 'workspace.team', name: 'workspace.settings.members', isVisible: () => this.isAdmin },
      ],

      items: [
        { label: 'project.settings', name: 'project.settings.general' },
        { label: 'project.spheres', name: 'project.settings.spheres' },
        { label: 'project.stages', name: 'project.settings.stages' },
        { label: 'project.team', name: 'project.settings.members' },
      ],

      profileItems: [
        { label: 'profile.settings', name: 'workspace.member.settings' },
        { label: 'profile.notifications', name: 'project.settings.notifications' },
      ]
    }
  },

  computed: {
    ...mapGetters(['project']),

    activeWorkspaceItems () {
      return this.workspaceItems.filter((item) => item.isVisible())
    }
  },

  methods: {
    onClickItem (item) {
      this.$router.push({ name: item.name, params: { project: this.project.code } })
    },

    menuItemClass (item) {
      const classes = []

      if (item.name === this.$router.currentRoute.name) {
        classes.push('settings-menu__item_active')
      }

      return classes
    }
  }
}
</script>

<template>
  <el-popover
    placement="bottom"
    trigger="hover"
    popper-class="sw-popover"
    transition="sw-slide-top"
    :visible-arrow="false"
    :open-delay="150"
    :close-delay="100"
  >
    <div class="dp-task-stats">
      <div class="dp-task-stats__title">
        {{ $t('taskStats.prediction.averageSpentTime') }}
      </div>

      <div class="dp-task-stats__list">
        <div class="dp-task-stats__row">
          <div class="dp-task-stats__name">
            {{ $t('taskStats.prediction.spentTimeByTeam') }}
          </div>

          <div class="dp-task-stats__value">
            {{ avgTeamSpentTime || 'Нет информации' }}
          </div>
        </div>

        <div class="dp-task-stats__row" v-if="avgMemberSpentTime">
          <div class="dp-task-stats__name">
            {{ $t('taskStats.prediction.spentTimeByMember') }}
          </div>

          <div class="dp-task-stats__value">
            {{ avgMemberSpentTime }}
          </div>
        </div>
      </div>
    </div>
    <div slot="reference" class="dp-task-stats__reference">
      {{ $t('taskStats.prediction.taskStatsLink') }}
    </div>
  </el-popover>
</template>
<script>
  export default {
    name: 'TaskStatsDropdown',

    props: {
      avgMemberSpentTime: {
        required: true,
      },

      avgTeamSpentTime: {
        required: true,
      },
    }
  }
</script>
<style lang="sass">
  .dp-task-stats
    color: var(--text-primary-color)
    font-size: 12px
    min-width: 200px
    padding: 12px

    &__reference
      cursor: pointer
      font-size: 12px
      color: var(--primary-color)
      text-decoration: underline

    &__list
      margin-top: 16px

      .dp-task-stats__row:last-child
        margin-bottom: 0

    &__row
      display: flex
      justify-content: space-between
      margin-bottom: 12px

    &__name
      color: var(--text-light-color)

    &__value
      font-size: 13px
      font-weight: 500
</style>

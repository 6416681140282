<template>
  <div v-if="logoSrc" :class="logoClasses" @click="$emit('click')">
    <img :src="logoSrc" class="workspace-logo__img">
    <slot name="hover_content"></slot>
  </div>
  <div v-else :class="logoClasses">
    {{ this.shortName }}
    <slot name="hover_content"></slot>
  </div>
</template>
<script>
  export default {
    name: 'WorkspaceLogo',

    props: {
      workspace: {
        type: Object,
        required: true
      },

      size: {
        type: String,
        default: 'sm'
      }
    },

    computed: {
      logoSrc () {
        switch (this.size) {
          case 'sx':
          case 'sm':
          case 'md':
            return this.workspace.logo_sm

          case 'mdx':
            return this.workspace.logo_lg

          case 'lg':
            return this.workspace.logo_lg
        }

        return this.workspace.logo_lg
      },

      shortName () {
        return this.workspace.name.substring(0, 2).toUpperCase()
      },

      logoClasses () {
        const baseClass = 'workspace-logo'
        const classes = [baseClass, `${baseClass}_size_${this.size}`]

        if (!this.logoSrc) {
          classes.push(`${baseClass}_empty`)
        }

        return classes
      }
    }
  }
</script>
<style lang="sass">
.workspace-logo
  &_size_xs, &_size_xs &__img
    width: 24px
    height: 24px
    border-radius: 8px

  &_size_sm, &_size_sm &__img
    width: 32px
    height: 32px
    border-radius: 8px

  &_size_md, &_size_md &__img
    width: 36px
    height: 36px
    border-radius: 8px

  &_size_lg, &_size_lg &__img
    width: 100px
    height: 100px
    border-radius: 34px

  &_size_mdx, &_size_mdx &__img
    width: 80px
    height: 80px
    border-radius: 26px

  &_empty
    color: #ffffff
    display: flex
    justify-content: center
    align-items: center
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(171,102,255,1) 0%, rgba(116,182,247,1) 90% )
    font-weight: 500

.workspace-logo_size_xs.workspace-logo_empty
  font-size: 12px

.workspace-logo_size_sm.workspace-logo_empty
    font-size: 14px

.workspace-logo_size_md.workspace-logo_empty
  font-size: 16px
  font-weight: 600

.workspace-logo_size_mdx.workspace-logo_empty
  font-size: 26px

.workspace-logo_size_lg.workspace-logo_empty
    font-size: 28px

.dark
  .workspace-logo
    &_empty
      background-color: #FF3CAC
      background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%)
</style>

<template>
  <svg width="239" height="180" viewBox="0 0 239 180" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M228.191 180H10.6731C4.80244 180 0 175.198 0 169.327V10.6731C0 4.80244 4.80244 0 10.6731 0H228.191C234.061 0 238.864 4.80244 238.864 10.6731V169.327C238.864 175.198 234.061 180 228.191 180Z" fill="#A7ADFF"/>
    <path d="M221.518 164.987H16.3138C10.4431 164.987 5.64062 160.185 5.64062 154.314V18.5025C5.64062 12.6318 10.4431 7.82935 16.3138 7.82935H221.518C227.388 7.82935 232.191 12.6318 232.191 18.5025V154.314C232.193 160.183 227.391 164.987 221.518 164.987Z" fill="#E3E5FC"/>
    <path d="M132.049 171.791H115.296C114.888 171.791 114.556 171.456 114.556 171.05V171.05C114.556 170.642 114.89 170.31 115.296 170.31H132.049C132.457 170.31 132.79 170.644 132.79 171.05V171.05C132.79 171.456 132.455 171.791 132.049 171.791Z" fill="#E3E5FC"/>
    <path d="M12.7427 6.04636C13.663 6.04636 14.4092 5.30025 14.4092 4.37987C14.4092 3.45949 13.663 2.71338 12.7427 2.71338C11.8223 2.71338 11.0762 3.45949 11.0762 4.37987C11.0762 5.30025 11.8223 6.04636 12.7427 6.04636Z" fill="#E3E5FC"/>
    <path d="M19.9194 6.04636C20.8398 6.04636 21.5859 5.30025 21.5859 4.37987C21.5859 3.45949 20.8398 2.71338 19.9194 2.71338C18.999 2.71338 18.2529 3.45949 18.2529 4.37987C18.2529 5.30025 18.999 6.04636 19.9194 6.04636Z" fill="white"/>
    <path d="M27.1597 6.04636C28.08 6.04636 28.8261 5.30025 28.8261 4.37987C28.8261 3.45949 28.08 2.71338 27.1597 2.71338C26.2393 2.71338 25.4932 3.45949 25.4932 4.37987C25.4932 5.30025 26.2393 6.04636 27.1597 6.04636Z" fill="#4B369D"/>
    <path d="M200.634 98.869H160.013C158.924 98.869 158.031 97.9764 158.031 96.8868V96.8868C158.031 95.7971 158.924 94.9045 160.013 94.9045H200.634C201.723 94.9045 202.616 95.7971 202.616 96.8868V96.8868C202.613 97.9764 201.723 98.869 200.634 98.869Z" fill="white"/>
    <path d="M200.634 107.942H160.013C158.924 107.942 158.031 107.049 158.031 105.96V105.96C158.031 104.87 158.924 103.978 160.013 103.978H200.634C201.723 103.978 202.616 104.87 202.616 105.96V105.96C202.613 107.052 201.723 107.942 200.634 107.942Z" fill="white"/>
    <path d="M200.634 117.018H160.013C158.924 117.018 158.031 116.125 158.031 115.035V115.035C158.031 113.946 158.924 113.053 160.013 113.053H200.634C201.723 113.053 202.616 113.946 202.616 115.035V115.035C202.613 116.125 201.723 117.018 200.634 117.018Z" fill="white"/>
    <path d="M200.634 127.247H160.013C158.924 127.247 158.031 126.354 158.031 125.265V125.265C158.031 124.175 158.924 123.282 160.013 123.282H200.634C201.723 123.282 202.616 124.175 202.616 125.265V125.265C202.613 126.357 201.723 127.247 200.634 127.247Z" fill="white"/>
    <path d="M200.634 136.322H160.013C158.924 136.322 158.031 135.43 158.031 134.34V134.34C158.031 133.251 158.924 132.358 160.013 132.358H200.634C201.723 132.358 202.616 133.251 202.616 134.34V134.34C202.613 135.43 201.723 136.322 200.634 136.322Z" fill="white"/>
    <path d="M200.634 145.398H160.013C158.924 145.398 158.031 144.505 158.031 143.416V143.416C158.031 142.326 158.924 141.433 160.013 141.433H200.634C201.723 141.433 202.616 142.326 202.616 143.416V143.416C202.613 144.505 201.723 145.398 200.634 145.398Z" fill="white"/>
    <path d="M36.1692 75.4145H120.187C126.058 75.4145 130.86 70.6121 130.86 64.7414V34.3789C130.86 28.5082 126.058 23.7058 120.187 23.7058H36.1692C30.2985 23.7058 25.4961 28.5082 25.4961 34.3789V64.7414C25.4937 70.6121 30.2962 75.4145 36.1692 75.4145Z" fill="white"/>
    <path d="M27.7846 145.424V145.424C26.6878 145.424 25.7881 144.527 25.7881 143.428V97.5231C25.7881 96.4263 26.6854 95.5266 27.7846 95.5266V95.5266C28.8813 95.5266 29.781 96.424 29.781 97.5231V143.428C29.781 144.527 28.8813 145.424 27.7846 145.424Z" fill="#D7384E"/>
    <path d="M37.3441 145.424V145.424C36.2474 145.424 35.3477 144.527 35.3477 143.428V122.943C35.3477 121.846 36.245 120.947 37.3441 120.947V120.947C38.4409 120.947 39.3406 121.844 39.3406 122.943V143.428C39.3406 144.527 38.4409 145.424 37.3441 145.424Z" fill="#D7384E"/>
    <path d="M46.9037 145.424V145.424C45.8069 145.424 44.9072 144.526 44.9072 143.427V103.405C44.9072 102.309 45.8046 101.409 46.9037 101.409V101.409C48.0004 101.409 48.9002 102.306 48.9002 103.405V143.427C48.8978 144.526 48.0004 145.424 46.9037 145.424Z" fill="#D7384E"/>
    <path d="M56.4613 145.424V145.424C55.3646 145.424 54.4648 144.527 54.4648 143.428V128.721C54.4648 127.624 55.3622 126.725 56.4613 126.725V126.725C57.5581 126.725 58.4578 127.622 58.4578 128.721V143.428C58.4578 144.527 57.5604 145.424 56.4613 145.424Z" fill="#D7384E"/>
    <path d="M66.0209 145.424V145.424C64.9241 145.424 64.0244 144.527 64.0244 143.427V118.637C64.0244 117.54 64.9218 116.64 66.0209 116.64V116.64C67.1176 116.64 68.0173 117.537 68.0173 118.637V143.427C68.0173 144.527 67.12 145.424 66.0209 145.424Z" fill="#D7384E"/>
    <path d="M75.5804 145.424V145.424C74.4837 145.424 73.584 144.526 73.584 143.427V136.082C73.584 134.986 74.4813 134.086 75.5804 134.086V134.086C76.6772 134.086 77.5769 134.983 77.5769 136.082V143.427C77.5769 144.526 76.6772 145.424 75.5804 145.424Z" fill="#D7384E"/>
    <path d="M39.1696 59.7491C44.8077 67.5095 55.6684 69.2306 63.4263 63.5925L43.0106 35.4924C35.2527 41.1281 33.5316 51.9888 39.1696 59.7491Z" fill="#A7ADFF"/>
    <path d="M44.8379 34.1629L54.1911 47.0367L67.3142 37.503C61.9918 30.4738 52.0048 28.9569 44.8379 34.1629Z" fill="#A7ADFF"/>
    <path d="M73.4102 36.4039C73.3248 36.2852 73.2251 36.1808 73.1372 36.0645L55.5156 48.8646L68.5864 66.8566C78.329 59.7775 80.4869 46.1441 73.4102 36.4039Z" fill="#FDC510"/>
    <path d="M88.165 37.0854V37.0854C88.165 35.9246 89.1146 34.9727 90.2778 34.9727H118.611C119.771 34.9727 120.723 35.9222 120.723 37.0854V37.0854C120.723 38.2463 119.774 39.1982 118.611 39.1982H90.2778C89.1146 39.1982 88.165 38.2463 88.165 37.0854Z" fill="#FDC510"/>
    <path d="M88.165 47.2031V47.2031C88.165 46.0423 89.1146 45.0903 90.2778 45.0903H99.8851C101.046 45.0903 101.998 46.0399 101.998 47.2031V47.2031C101.998 48.364 101.048 49.3159 99.8851 49.3159H90.2778C89.1146 49.3159 88.165 48.364 88.165 47.2031Z" fill="#A7ADFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M81.375 113.687C81.375 124.192 89.9211 132.738 100.426 132.738C110.93 132.738 119.476 124.192 119.476 113.687C119.476 103.182 110.93 94.6362 100.426 94.6362C89.9211 94.6362 81.375 103.182 81.375 113.687ZM83.1528 113.687C83.1528 104.165 90.9013 96.4166 100.425 96.4166C109.95 96.4166 117.696 104.163 117.696 113.687C117.696 123.211 109.95 130.96 100.425 130.96C90.9013 130.96 83.1528 123.211 83.1528 113.687Z" fill="#978CFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M87.6035 113.687C87.6035 120.759 93.3555 126.511 100.427 126.511C107.499 126.511 113.251 120.759 113.251 113.687C113.251 106.615 107.499 100.863 100.427 100.863C93.3555 100.863 87.6035 106.615 87.6035 113.687ZM89.3813 113.687C89.3813 107.598 94.338 102.643 100.427 102.643C106.519 102.643 111.471 107.598 111.471 113.687C111.471 119.778 106.516 124.733 100.427 124.733C94.3357 124.733 89.3813 119.776 89.3813 113.687Z" fill="#978CFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M93.0723 113.687C93.0723 117.742 96.372 121.042 100.427 121.042C104.481 121.042 107.781 117.742 107.781 113.687C107.781 109.633 104.484 106.333 100.427 106.333C96.3696 106.333 93.0723 109.633 93.0723 113.687ZM94.8524 113.687C94.8524 110.613 97.3521 108.113 100.426 108.113C103.501 108.113 106 110.613 106 113.687C106 116.761 103.501 119.261 100.426 119.261C97.3521 119.261 94.8524 116.761 94.8524 113.687Z" fill="#978CFF"/>
    <path d="M99.4648 115.284L99.669 110.757C99.6927 110.266 100.103 109.889 100.597 109.908C101.089 109.929 101.468 110.347 101.447 110.836L101.39 112.089L130.888 94.7595C131.313 94.5103 131.857 94.6527 132.106 95.0753C132.355 95.4978 132.213 96.0438 131.79 96.2931L99.4648 115.284Z" fill="#FDC510"/>
    <path d="M103.465 115.458H100.426C99.935 115.458 99.5361 115.059 99.5361 114.567C99.5361 114.076 99.935 113.677 100.426 113.677H103.465C103.956 113.677 104.355 114.076 104.355 114.567C104.355 115.059 103.956 115.458 103.465 115.458Z" fill="#FDC510"/>
    <path d="M154.45 75.716C154.305 75.716 154.158 75.6804 154.023 75.6068C153.591 75.3694 153.434 74.8305 153.671 74.3985L160.973 61.1022L166.806 66.6833L170.695 60.5158L174.106 62.5811L182.265 42.3364L187.951 52.4231L193.301 44.499L197.814 50.035L206.942 33.2253C207.232 32.6911 207.614 32.1071 208.017 31.4875C208.35 30.9772 208.703 30.4359 209.012 29.9089C208.668 30.0584 208.317 30.2222 207.982 30.3789C207.362 30.6685 206.778 30.9415 206.251 31.1196C205.788 31.2763 205.283 31.0246 205.124 30.5593C204.967 30.0941 205.219 29.5884 205.684 29.4317C206.116 29.2869 206.655 29.0353 207.227 28.767C208.362 28.2376 209.532 27.6893 210.569 27.6086L211.835 27.5112L211.495 28.7338C211.165 29.9184 210.322 31.2098 209.506 32.4609C209.121 33.0496 208.758 33.6051 208.504 34.0751L198.111 53.216L193.434 47.4783L187.813 55.8036L182.529 46.4314L174.97 65.1829L171.264 62.9396L167.148 69.4702L161.417 63.9865L155.231 75.2531C155.07 75.5498 154.766 75.716 154.45 75.716Z" fill="#5A54FF"/>
    <path d="M211.158 33.8307C210.666 33.8307 210.268 33.4319 210.268 32.9405V28.4918C210.268 28.0004 210.666 27.6016 211.158 27.6016C211.649 27.6016 212.048 28.0004 212.048 28.4918V32.9405C212.048 33.4319 211.649 33.8307 211.158 33.8307Z" fill="#5A54FF"/>
    <path d="M153.562 80.9031V80.9031C153.073 80.9031 152.672 80.5019 152.672 80.0129V23.2335C152.672 22.7445 153.073 22.3433 153.562 22.3433V22.3433C154.051 22.3433 154.452 22.7445 154.452 23.2335V80.0152C154.452 80.5043 154.051 80.9031 153.562 80.9031Z" fill="#A7ADFF"/>
    <path d="M148.152 75.7159V75.7159C148.152 75.2269 148.554 74.8257 149.043 74.8257H205.824C206.313 74.8257 206.715 75.2269 206.715 75.7159V75.7159C206.715 76.2049 206.313 76.6061 205.824 76.6061H149.04C148.551 76.6037 148.152 76.2049 148.152 75.7159Z" fill="#A7ADFF"/>
    <path d="M186.512 36.3234H169.983C168.352 36.3234 167.018 34.9892 167.018 33.3583V33.3583C167.018 31.7275 168.352 30.3933 169.983 30.3933H186.512C188.143 30.3933 189.477 31.7275 189.477 33.3583V33.3583C189.477 34.9892 188.143 36.3234 186.512 36.3234Z" fill="#D7384E"/>
    <rect x="197.74" y="49.3157" width="0.667071" height="0.89022" fill="#4B369D"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M197.74 37.5957H198.407V35.9221H197.74V37.5957ZM198.407 40.943H197.74V39.2694H198.407V40.943ZM197.74 44.2926H198.407V42.619H197.74V44.2926ZM197.74 47.6422H198.407V45.9686H197.74V47.6422Z" fill="#4B369D"/>
    <path d="M198.407 34.246H197.74V33.6905H197.185V33.0234H198.407V34.246Z" fill="#4B369D"/>
    <rect x="192.639" y="33.0234" width="2.27421" height="0.667071" fill="#4B369D"/>
    <rect x="189.477" y="33.0234" width="0.89022" height="0.667071" fill="#4B369D"/>
    <path d="M198.485 54.1777H197.664C196.553 54.1777 195.644 53.2685 195.644 52.1575V51.3361C195.644 50.2251 196.553 49.3159 197.664 49.3159H198.485C199.596 49.3159 200.505 50.2251 200.505 51.3361V52.1575C200.505 53.2685 199.596 54.1777 198.485 54.1777Z" fill="#D7384E"/>
  </svg>
</template>
<script>
  import iconMixin from '@/components/icons/iconMixin'

  export default {
    name: 'EmptyReport',

    mixins: [iconMixin],

    props: {
      viewBox: {
        type: String,
        default: '0 0 12 12'
      }
    },

    data () {
      return {
        defaultBackground: '#2C3E50',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

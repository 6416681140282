<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-stroke" d="M20.5 8H3.5M6 21H18C19.6569 21 21 19.6569 21 18V8C21 6.34315 19.6569 5 18 5H6C4.34315 5 3 6.34315 3 8V18C3 19.6569 4.34315 21 6 21Z" stroke="#636478" stroke-width="1.4" stroke-linecap="round"/>
    <path class="path-stroke" d="M7.5 5V2.75" stroke="#636478" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path class="path-stroke" d="M16.5 5V2.75" stroke="#636478" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path class="path-stroke" d="M8.25 17.5H6.75M8.25 14.5H6.75M8.25 11.5H6.75M12.75 17.5H11.25M12.75 14.5H11.25M12.75 11.5H11.25M17.25 17.5H15.75M17.25 14.5H15.75M17.25 11.5H15.75" stroke="#636478" stroke-width="1.4" stroke-linecap="round"/>
  </svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'SprintsIcon',

  mixins: [iconMixin],

  props: {
    viewBox: {
      type: String,
      default: '0 0 12 12'
    }
  },

  data () {
    return {
      defaultBackground: '#416AF7',
      defaultWidth: 12,
      defaultHeight: 12
    }
  }
}
</script>

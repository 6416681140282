<template>
  <div class="settings-page">
    <div class="settings-title">
      {{ $t('settings.notifications.title') }}
    </div>

    <div class="profile-notification-form">
      <div class="settings-description">
        {{ $t('settings.notifications.description') }}
      </div>

      <div class="profile-notifications">
        <div
            v-for="(option, i) in options"
            :key="i"
            class="profile-notification-item"
        >
          <div>
            {{ $t(`settings.notifications.options.${option}`) }}
          </div>
          <ui-switch
              :value="notifications & Math.pow(2, i) ? Math.pow(2, i) : -1"
              :true-value="Math.pow(2, i)"
              :false-value="0"
              @input="(val) => onChangeOption(i, val)"
          />
        </div>
      </div>

      <ui-loading-button :loading="isSaving" @click="onSubmit">
        {{ $t('settings.notifications.saveBtn') }}
      </ui-loading-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import UiSwitch from "@/components/ui/UiSwitch"
import UiLoadingButton from "@/components/ui/UiLoadingButton"
import pageTitleMixin from "@/views/mixins/pageTitleMixin";

const options = [
    'task.assigned',
    'task.comment_created',
    'task.finished',
    'task.idle',
    'task.overdue',
    'task.stage_changed',
    'task.finished',
];

export default {
  name: 'UserSettings',

  components: {
    UiLoadingButton, UiSwitch
  },

  mixins: [pageTitleMixin],

  data () {
    return {
      options,
      notifications: 0,
      isSaving: false,
    }
  },

  computed: {
    ...mapGetters(['workspace', 'projectAuthMember']),

    pageTitle () {
      return {
        message: 'pageTitles.settings.notifications',
        params: { workspace: this.workspace.name }
      }
    },

    selectedLanguage () {
      const lang = this.languages.find(item => item.id === this.form.lang)

      return lang.name
    }
  },

  methods: {
    ...mapActions(['updateProjectMemberProfile']),

    onChangeOption (i, val) {
      if (!val) {
        this.notifications = this.notifications ^ Math.pow(2, i)
        return
      }

      this.notifications = this.notifications | val
    },

    async onSubmit () {
      this.isSaving = true
      try {
        const { project } = await this.updateProjectMemberProfile({ notifications: this.notifications })
        this.notifications = project.notifications

        this.$notify({
          title: this.$t('common.saved'),
          message: this.$t('settings.notifications.notify.success'),
          position: 'bottom-right',
          type: 'success'
        })
      } catch(e) {
        this.$notify({
          title: this.$t('common.error'),
          message: this.$t('settings.notifications.notify.failed'),
          position: 'bottom-right',
          type: 'error'
        })
      } finally {
        this.isSaving = false
      }
    },
  },

  created () {
    this.notifications = this.projectAuthMember.project.notifications
  }
}
</script>
<style lang="sass">
  .profile-notification-form
    max-width: 600px
    margin-bottom: 24px

  .profile-notifications
    margin-bottom: 24px
    border-radius: 12px
    box-shadow: var(--block-shadow)
    background: var(--block-bg-color)

    .profile-notification-item
      &:first-child
        border-radius: 12px 12px 0 0
      &:last-child
        border-radius: 0 0 12px 12px

  .profile-notification-item
    display: flex
    justify-content: space-between
    align-items: center
    padding: 12px
    cursor: default
    transition: all ease-in .2s

    &:hover
      background: var(--grey-light-color-40)
</style>

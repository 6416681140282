<template>
  <el-popover
    placement="bottom"
    trigger="click"
    popper-class="ui-dropdown ui-dropdown_round"
    :visible-arrow="false"
    :close-delay="200"
    ref="popper"
    @show="isOpen = true"
    @hide="isOpen = false"
  >
    <div class="rate-selector">
      <div class="rate-selector__descr">
        {{ $t('settings.members.hourlyRateLabel') }}
      </div>
      <text-field
        :hide-label="true"
        :append-span="workspace.currency_id"
        type="number"
        v-model="inputValue"
        prop="hourly_rate"
        class="rate-selector__input"
      />

      <div class="mt-2">
        <ui-button @click.prevent="onSubmit" ui-class="size_md block">{{ $t('common.save') }}</ui-button>
      </div>
    </div>
    <div
      slot="reference"
      ref="reference"
      class="ui-dropdown__reference"
      :class="referenceClasses"
    >
      {{ value ? formattedValue : $t('members.emptyHourlyRate') }}
    </div>
  </el-popover>
</template>
<script>
  import { mapGetters } from 'vuex'
  import TextField from 'scorework-ui/src/components/TextField'
  import UiButton from 'scorework-ui/src/components/UiButton'
  import { formatMoney } from '@/utils/money'

  export default {
    name: 'HourlyRateDropdown',

    components: { TextField, UiButton },

    props: {
      value: {
        required: true,
      }
    },

    data () {
      return {
        inputValue: this.value || '',
        isOpen: false,
      }
    },

    computed: {
      ...mapGetters(['authUser', 'workspace']),

      referenceClasses () {
        const classes = []

        if (this.isOpen) {
          classes.push('ui-dropdown__reference_hover')
        }

        return classes
      },

      formattedValue () {
        return formatMoney(this.authUser.lang, this.value, this.workspace.currency_id)
      }
    },

    watch: {
      value (val) {
        this.inputValue = val
      }
    },

    methods: {
      onSubmit () {
        this.$emit('submit', this.inputValue)
        this.$refs.popper.doClose()
      }
    }
  }
</script>
<style lang="sass">
  .rate-selector
    padding: 18px

    &__descr
      font-size: 12px
      margin-bottom: 8px

    &__input
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0

      input[type=number]
        -moz-appearance: textfield

</style>

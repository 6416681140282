const workloadSocket = (store, router, onConnect) => {
  let wasDisconnected = false

  const listeners = {
    'connect': () => {
      if (wasDisconnected) {
        onConnect()
      }
    },

    'disconnect': () => {
      wasDisconnected = true
    },

    'tasks.updated': ({ task }) => {
      store.dispatch('workload/refreshTask', { task })
    },

    'tasks.recalculated': ({ tasks, status = null }) => {
      if (status) {
        return
      }

      store.dispatch('workload/refreshTasks', { tasks, full: false })
    },

    'tasks.removed': ({ task_id, status = null }) => {
      if (status) {
        return
      }

      store.dispatch('workload/removeTask', { taskId: task_id })
    }
  }

  return {
    subscribe (socket) {
      Object.keys(listeners).forEach(eventName => {
        socket.on(eventName, listeners[eventName])
      })
    },

    unsubscribe (socket) {
      Object.keys(listeners).forEach(eventName => {
        socket.off(eventName, listeners[eventName])
      })
    }
  }
}

export default workloadSocket


<template>
  <div class="task-comment">
    <div class="task-comment__head">
      <user-photo :user="comment.member" size="xs"/>

      <div class="task-comment__author">
        {{ authorName }}
      </div>

      <div class="task-comment__date">
        {{ formattedDate }}
      </div>

      <div class="task-comment__actions" v-if="comment.member.member_id === authUser.member_id && !isUpdating">
        <a href="#" @click.prevent="onUpdateClick">{{ $t('common.edit') }}</a>
        <a href="#" @click.prevent="showDeleteConfirmation = true">{{ $t('common.delete') }}</a>
      </div>
    </div>
    <div class="task-comment__content">

      <div class="task-comment__text">
        <template v-if="isUpdating">
          <text-editor
            class="sw-editor_active sw-editor_show-panel"
            :value="comment.text"
            :placeholder="$t('tasks.enterTheComment')"
            @init="onEditorInit"
            v-if="isUpdating"
          >
          </text-editor>

          <div class="task-comment__update-actions">
            <ui-button  ui-class="size_md plain" @click="$emit('updated')">
              {{ $t('common.cancel') }}
            </ui-button>
            <ui-loading-button
              ui-class="size_md"
              class="sw-task-card__save-btn"
              :loading="updateProcessing"
              @click="onSave"
            >
              {{ $t('common.save') }}
            </ui-loading-button>
          </div>
        </template>

        <editor-content :editor="editor" class="sw-pros-mirror" v-if="!isUpdating"/>
        <overlay-gallery :src="gallery.src" v-if="gallery.open" @close="gallery.open = false"/>
      </div>

      <div class="task-comment__updated" v-if="comment.created_at !== comment.updated_at">
        {{ $t('comments.updatedAt') }} {{ formattedUpdatedAt }}
      </div>
    </div>

    <confirmation-modal
      :description="$t('comments.deleteDescription')"
      :title="$t('comments.deleteTitle')"
      :submitTitle="$t('common.delete')"
      width="400px"
      :is-processing="deleteProcessing"
      v-if="showDeleteConfirmation"
      @close="showDeleteConfirmation = false"
      @confirm="onDeleteConfirm"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { Editor, EditorContent } from '@tiptap/vue-2'
import Mention from "@tiptap/extension-mention"

import UiButton from 'scorework-ui/src/components/UiButton'

import ConfirmationModal from '@/components/ui/ConfirmationModal'
import UiLoadingButton from '@/components/ui/UiLoadingButton'
import UserPhoto from "@/components/ui/UserPhoto"
import TextEditor from '@/components/ui/TextEditor';

import { getPastDate } from "@/services/scoring"
import editorMixin from '@/components/ui/mixins/editorMixin'
import Emoji from "@/components/ui/textEditor/emoji"

export default {
  name: 'TaskCommentItem',

  components: { ConfirmationModal, EditorContent, TextEditor, UiButton, UiLoadingButton, UserPhoto },

  mixins: [editorMixin],

  props: {
    comment: {
      type: Object,
      required: true
    }
  },

  data () {
    const { diff, label } = getPastDate(this.comment.createdAt)
    this.setTimeUpdateInterval(diff)

    return {
      formattedDate: label,
      editor: this.buildEditor(false),
      updateEditor: null,
      updateProcessing: false,
      showDeleteConfirmation: false,
      deleteProcessing: false
    }
  },

  computed: {
    ...mapGetters(['authUser']),
    ...mapGetters('task', ['updatingComment']),

    authorName () {
      let fullName = this.comment.member.first_name

      if (this.comment.member.last_name) {
        fullName += ' ' + this.comment.member.last_name
      }

      return fullName
    },

    formattedUpdatedAt () {
      const { label } = getPastDate(this.comment.updatedAt)
      return label
    },

    isUpdating () {
      return this.comment.id === this.updatingComment
    }
  },

  watch: {
    comment: function() {
      this.editor.commands.setContent(this.comment.text)
    }
  },

  methods: {
    ...mapActions('task', ['deleteComment', 'updateComment', 'setUpdatingComment']),

    updateFormattedDate () {
      const { diff, label } = getPastDate(this.comment.createdAt)
      this.formattedDate = label

      this.setTimeUpdateInterval(diff)
    },

    setTimeUpdateInterval (diff) {
      if (diff.days > 0 || diff.hours >= 8) {
        return
      }

      let periodTime = 60
      if (diff.hours >= 1) {
        periodTime *= 60
      }

      setTimeout(() => this.updateFormattedDate(), periodTime)
    },

    buildEditor (isUpdating) {
      return new Editor({
        editable: isUpdating,
        extensions: [
          ...this.getCommonExtensions({
            useHistory: true,
          }),
          Emoji,
          Mention,
        ],
        onInit: (editor) => {
          this.listenImageOpening(editor)
        },
        content: this.comment.text
      })
    },

    onUpdateClick () {
      this.setUpdatingComment(this.comment.id)
    },

    onEditorInit ({ editor }) {
      this.updateEditor = editor
    },

    async onSave () {
      const text = JSON.stringify({
        json: this.updateEditor.getJSON(),
        html: this.updateEditor.getHTML()
      })

      this.updateProcessing = true
      try {
        await this.updateComment({
          taskId: this.comment.task_id,
          commentId: this.comment.id,
          text
        })
        this.$emit('updated')
      } catch (e) {
        this.$notify({
          title: this.$t('common.error'),
          message: this.$t('comments.error.updateTitle'),
          position: 'bottom-right',
          type: 'error'
        })
      } finally {
        this.updateProcessing = false
      }
    },

    async onDeleteConfirm () {
      this.deleteProcessing = true
      try {
        await this.deleteComment({
          taskId: this.comment.task_id,
          commentId: this.comment.id,
        })
        this.deleteProcessing = false
        this.showDeleteConfirmation = false
        this.$notify({
          title: this.$t('common.success'),
          message: this.$t('comments.deleted'),
          position: 'bottom-right',
          type: 'success'
        })
      } catch (e) {
        this.$notify({
          title: this.$t('common.error'),
          message: this.$t('comments.error.updateTitle'),
          position: 'bottom-right',
          type: 'error'
        })
      }
    }
  },

  beforeDestroy () {
    this.editor.destroy()
  }
}
</script>

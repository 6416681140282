import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import {defineNodeByCursor} from '@/components/ui/textEditor/helpers'
import { EventBus } from '@/services/eventBus'

export const HoverExtension = Extension.create({
  name: 'hover',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('hover'),
        props: {
          handleDOMEvents: {
            mouseover(view, e) {
              const posAtCoords = view.posAtCoords({ left: e.clientX, top: e.clientY })
              if (!posAtCoords) {
                return
              }

              const res = defineNodeByCursor(view, posAtCoords)

              EventBus.$emit('editorNodeActive', res)
            }
          }
        },
      }),
    ]
  },
})

<template>
  <node-view-wrapper class="image-block">
    <div>
      <img v-bind="node.attrs" ref="resizableImg"/>
    </div>
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2'

export default {
  name: 'ImageBlock',

  components: {
    NodeViewWrapper,
  },

  props: nodeViewProps,

  data() {
    return {

    }
  },

  methods: {
  }
}
</script>

<template>
  <div class="stage-item" v-draggable-item="{ list: 'stages-' + sphereId, item: stage, dragSelector: '.stage-item__drag' }">
    <div class="stage-item__content">
      <div class="stage-item__drag" v-if="!stage.is_base_stage">
        <draggable-icon/>
      </div>

      <div class="stage-item__label">
        <div class="stage-item__title">
          {{ stage.name }}
        </div>

        <el-tooltip class="item" effect="dark" placement="right" :manual="true" v-model="showHint">
          <div slot="content" v-html="hintContent"></div>
          <div class="stage-item__hint" @click="showHint = !showHint" v-if="hintContent">
            <info-icon/>
          </div>
        </el-tooltip>
      </div>
    </div>

    <div class="stage-item__controls">
      <el-tooltip class="item" effect="dark" :content="$t('settings.editStage')" placement="top">
        <div class="stage-item__control" @click="onUpdate">
          <settings-icon class="stage-item__control-icon stage-item__edit-icon"/>
        </div>
      </el-tooltip>

      <el-tooltip v-if="!stage.is_base_stage" class="item" effect="dark" :content="$t('common.delete')" placement="top">
        <div class="stage-item__control" @click="onDelete">
          <close-icon class="stage-item__control-icon stage-item__control-icon_close"/>
        </div>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
import DraggableIcon from "../icons/DraggableIcon"
import InfoIcon from "@/components/icons/InfoIcon"
import CloseIcon from "../icons/CloseIcon"
import SettingsIcon from "@/components/icons/SettingsIcon"

import {createNamespacedHelpers} from "vuex"

const { mapActions: stagesActions } = createNamespacedHelpers('stages')

export default {
  name: 'StageItem',

  props: {
    stage: {
      type: Object,
      required: true
    },

    sphereId: {
      type: Number,
      required: true
    },

    isFocused: {
      type: Boolean,
      default: false
    },

    hintContent: {
      type: String,
      default: ''
    }
  },

  components: { CloseIcon, InfoIcon, DraggableIcon, SettingsIcon },

  data () {
    return {
      showHint: false
    }
  },

  methods: {
    ...stagesActions(['deleteStage']),

    onDelete () {
      this.$emit('delete', this.stage)
    },

    onUpdate () {
      this.$emit('update', this.stage)
    }
  }
}
</script>
<style lang="sass">
.stage-item
  padding: 0 12px
  display: flex
  justify-content: space-between
  border-radius: 12px
  background: var(--color-grey-bg)
  margin-bottom: 8px
  transition: background-color ease-in .2s
  box-sizing: border-box
  height: 52px
  align-items: center

  &:hover
    background: var(--color-grey-hover)

    .stage-item__controls
      opacity: 1

  &__content
    display: flex
    align-items: center

  &__drag
    margin-right: 8px

    .path-fill
      fill: var(--grey-color-60)

  &__label
    display: flex
    align-items: center

  &__hint
    height: 14px
    margin-left: 4px
    cursor: pointer

    &:hover
      .path-fill
        fill: var(--text-primary-color)

    .path-fill
      transition: all ease-in .2s
      fill: var(--grey-color-100)

  &__controls
    display: flex
    opacity: 0
    transition: opacity ease-in .2s
    align-items: center

  &__edit-icon
    width: 16px
    height: 16px
    margin-right: 8px

  &__control-icon
    cursor: pointer

    &_close
      width: 20px

    path
      transition: all ease-in .2s

    &:hover
      .path-fill
        fill: var(--text-primary-color)

      .path-stroke
        stroke: var(--text-primary-color)

    .path-fill
      fill: var(--grey-color-100)

    .path-stroke
      stroke: var(--grey-color-100)


  &.draggable-item_before
    border-top: 2px solid var(--primary-color)

  &.draggable-item_after
    border-bottom: 2px solid var(--primary-color)

</style>

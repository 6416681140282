<template>
  <div class="ui-block">
    <div class="team-block">
      <div
        v-for="member in activeMembers"
        :key="member.member_id"
        class="team-member-item"
      >
        <div class="team-member-item__photo">
          <member-popover
            :member="member"
          >
            <user-photo :user="member" size="md"/>
          </member-popover>
        </div>

        <div class="team-member-item__content">
          <div class="team-member-item__name">
            {{ member.first_name }} {{ member.last_name }}
          </div>

          <div class="team-member-item__spheres">
            <sphere-badge
              v-for="sphere in member.spheres"
              :key="sphere.id"
              :sphere="sphere"
            />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  import MemberPopover from '@/components/members/MemberPopover.vue'
  import SphereBadge from '@/components/ui/SphereBadge.vue'
  import UserPhoto from '@/components/ui/UserPhoto.vue'

  export default {
    name: 'Team',

    components: { MemberPopover, SphereBadge, UserPhoto },

    computed: {
      ...mapGetters(['activeMembers']),
    }
  }
</script>
<style lang="sass">
  .team-block
    .team-member-item
      &:first-child
        padding-top: 0

      &:last-child
        border-bottom: 0
        padding-bottom: 0

  .team-member-item
    display: flex
    border-bottom: 1px solid var(--color-grey-hover)
    padding: 16px 0

    &__photo
      margin-right: 12px

    &__content
      flex: 1
      cursor: default

    &__name
      font-weight: 500

    &__spheres
      margin-top: 8px
      display: flex

      .sphere-badge
        margin-right: 6px

        &:last-child
          margin-right: 0
</style>

<template>
  <div>
    <ui-tabs :tabs="tabs" key-field="name" :value="selectedTab" @input="onTabChange" class="task-timeline-tabs">
      <template v-slot:default="{ tab }">
        {{ tab.label }}
      </template>
    </ui-tabs>

    <task-comment-form :task-id="taskId"/>

    <div class="task-timeline">
      <timeline-item
          v-for="item in displayedItems"
          :key="item.id"
          :item="item"
      />
    </div>
  </div>
</template>
<script>
  import TimelineItem from "@/components/tasks/components/TimelineItem"
  import UiTabs from "@/components/ui/UiTabs"
  import TaskCommentForm from "@/components/tasks/components/TaskCommentForm";
  import {mapGetters} from "vuex";

  export default {
    name: 'TaskTimeline',

    components: {TaskCommentForm, TimelineItem, UiTabs },

    props: {
      taskId: {
        type: Number,
        required: true,
      },

      items: {
        type: Array,
        required: true,
      }
    },

    data () {
      return {
        tabs: [
          { label: this.$t('tasks.timeline'), name: 'timeline' },
          { label: this.$t('tasks.comments'), name: 'comments' },
        ],

        selectedTab: 'timeline',
      }
    },

    computed: {
      ...mapGetters('task', ['timeline']),

      filter () {
        if (this.selectedTab === 'comments') {
          return 'task.comment.added'
        }

        return ''
      },

      displayedItems () {
        if (!this.filter) {
          return this.timeline
        }

        return this.timeline.filter((item) => item.type === this.filter)
      }
    },

    methods: {
      onTabChange (tab) {
        this.selectedTab = tab
      }
    }
  }
</script>
<style lang="sass">
.task-timeline-tabs
  column-gap: 16px

  .ui-tab
    font-size: 14px
    font-weight: 500
    color: var(--text-light-color)
    padding: 4px 0

    &_active
      color: var(--text-primary-color)

    &:after
      background: var(--text-primary-color)

.task-timeline
  margin-top: 24px
  .timeline-item:last-child
    .timeline-item__line:after
      display: none
</style>

<template>
  <ui-form :model="form" :rules="rules" :submit-fn="submitFn" ref="form">
    <div class="form-control">
      <text-field
          prop="email"
          v-model="form.email"
          :label="$t('auth.enterYourEmail')"
          :focused="true"
      />
    </div>

    <div class="form-control">
      <text-field
          prop="password"
          type="password"
          v-model="form.password"
          :label="$t('auth.enterYourPassword')"
      />
    </div>

    <div class="form-control auth-forget-block">
      <a :href="forgetPasswordUrl" class="auth-forget-link">{{ $t('auth.forgetPassword') }}</a>
    </div>

    <div class="form-control">
      <ui-loading-button
        @click.prevent="onSubmit"
        ui-class="block"
        class="auth-submit-btn"
        :loading="isProcessing"
      >
        {{ $t('auth.singIn') }}
      </ui-loading-button>
    </div>
  </ui-form>
</template>
<script>
import UiForm from 'scorework-ui/src/components/form/UiForm'
import TextField from 'scorework-ui/src/components/TextField'
import UiLoadingButton from '@/components/ui/UiLoadingButton'
import { asyncError } from 'scorework-ui/src/components/form/rules'

import { mapActions } from 'vuex'

import { required } from 'vuelidate/lib/validators'
import {getBaseDomain} from "@/helpers/domain";

export default {
  name: 'LoginForm',

  components: { TextField, UiLoadingButton, UiForm },

  props: {
    email: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isProcessing: false,

      form: {
        email: this.email,
        password: ''
      },

      rules: {
        email: {
          rules: { required },
          messages: {
            required: 'Необходимо указать email'
          }
        },

        password: {
          rules: { required },
          messages: {
            required: 'Необходимо указать пароль'
          }
        }
      }
    }
  },

  computed: {
    forgetPasswordUrl () {
      return `${getBaseDomain()}/forget`
    }
  },

  methods: {
    ...mapActions(['login']),

    submitFn () {
      this.isProcessing = true
      this.login(this.form)
        .then(() => {
          this.$emit('login')
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.rules.email.rules = { ...this.rules.email.rules, asyncError }
            this.rules.email.messages = { ...this.rules.email.messages, asyncError: 'Неверный логин или пароль' }
            this.$refs.form.validate()
          }
        })
        .finally(() => {
          this.isProcessing = false
        })
    },

    onSubmit () {
      this.$refs.form.submit()
    }
  }
}
</script>
<style lang="sass">
.auth-forget-block
  display: flex
  justify-content: flex-end

.auth-submit-btn.ui-button
  font-size: 16px
  font-weight: 500
  height: 48px

  &_secondary
    display: flex
    justify-content: center
    align-items: center
    border: 1px solid var(--block-border-bg)
    background: var(--block-bg-color)
    color: var(--text-primary-color)

    &:hover, &:active
      background: var(--grey-light-color-24)
      border: 1px solid var(--block-border-bg)
</style>

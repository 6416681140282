import apiService from "../../services/api"
import { transformDateToObject } from "@/services/scoring"
import { transformTask } from "@/transformers/tasks"
import {createFeedTransformer} from "@/transformers/feeds"
import draftTask from '@/services/draftTask'

function nullableTarget() {
  return { id: null, name: 'tasks.noGoal' }
}

function nullableSphere() {
  return { id: null, name: 'tasks.noSphere' }
}

function nullableAssignee () {
  return { member_id: null, first_name: 'tasks.notSelected' }
}


function nullableTask(afterTaskId, defaultState, member) {
  return {
    name: '',
    description: '',
    status: '',
    creator: member,
    target: nullableTarget(),
    state: {
      stage: { id: null },
      assignee: nullableAssignee(),
      score: null,
      ...defaultState
    },
    tags: [],
    files: [],
    after_task_id: afterTaskId,
    points: null,
    score: null,
    sphere: nullableSphere(),
    transitions: []
  }
}

const taskFieldsResolver = {
  target: 'target_id',
  assignee: 'assignee_id',
  stage: 'stage_id',
  sphere: 'sphere_id'
}

export const namespaced = true

export const state = {
  isInitInProgress: false,

  task: {},

  comments: [],

  timeline: [],

  taskError: 0,

  updatingComment: 0
}

export const mutations = {
  SET_TASK (state, task) {
    state.task = task
  },

  UPDATE_TASK_STATE (state, taskState) {
    if (taskState.id) {
      state.task.states = state.task.states.map(item => {

        if (item.id !== taskState.id) {
          return item
        }

        return { ...item, ...taskState }
      })
    } else {
      state.task.state = { ...state.task.state, ...taskState }
    }
  },

  UPDATE_TASK_FIELD (state, { field, value }) {
    state.task = { ...state.task, [field]: value }
  },

  ADD_FILE (state, file) {
    state.task.files.push(file)
  },

  REMOVE_FILE (state, uuid) {
    state.task.files.splice(state.task.files.findIndex(item => item.uuid === uuid), 1)
  },

  SET_COMMENTS (state, comments) {
    state.comments = comments
  },

  SET_TIMELINE (state, timeline) {
    state.timeline = timeline
  },

  PREPEND_COMMENT (state, comment) {
    if (state.comments.find((item) => item.id === comment.id)) {
      return
    }

    state.comments.unshift(comment)
  },

  UPDATE_COMMENT (state, comment) {
    state.comments = state.comments.map(item => {
      if (item.id !== comment.id) {
        return item
      }

      return comment
    })
  },

  DELETE_COMMENT (state, { id }) {
    state.comments = state.comments.filter(item => item.id !== id)
    state.timeline = state.timeline.filter((item) => item.payload.comment_id !== id)
  },

  REFRESH_TASK (state, task) {
    if (state.task.id !== task.id) {
      return
    }

    state.task = task
  },

  SET_TASK_ERROR (state, error) {
    state.taskError = error
  },

  PREPEND_TIMELINE (state, feed) {
    if (state.timeline.find((item) => item.id === feed.id)) {
      return
    }

    state.timeline = [feed, ...state.timeline]
  },

  SET_UPDATING_COMMENT (state, id) {
    state.updatingComment = id
  }
}

export const actions = {
  fetchTask ({ commit, dispatch, rootGetters }, { taskId, afterTaskId = 0, defaultState = {} }) {
    commit('SET_TASK_ERROR', 0)
    commit('SET_UPDATING_COMMENT', 0)

    const { authUser, project } = rootGetters

    if (!taskId) {
      const draft = draftTask.get()
      if (draft) {
        dispatch('setTask', draft)
      } else {
        dispatch('setTask', nullableTask(afterTaskId, defaultState, authUser))
      }

      return Promise.resolve()
    }

    return apiService.get(`projects/${project.code}/tasks/${taskId}`)
      .then(({ data }) => {
        dispatch('setTask', data)

        return data
      })
      .catch(({ response = null }) => {
        const error = response?.status || 500
        commit('SET_TASK_ERROR', error)
      })
  },

  setTask ({ commit, rootGetters }, task) {
    const { comments = null, timeline = [], ...taskData } = task

    commit('SET_TASK', transformTask(taskData, rootGetters))

    if (comments) {
      commit('SET_COMMENTS', comments.map(transformComment))
    }

    const feedPerformFunc = createFeedTransformer(rootGetters)
    commit('SET_TIMELINE', timeline.map(feedPerformFunc))
  },

  createTask ({ rootGetters }, { state, target, sphere, afterTaskId, files, ...data }) {
    const {
      stage, assignee, ...stateData
    } = state

    stateData.stage_id = stage.id || null
    stateData.assignee_id = assignee.member_id

    data.state = stateData
    data.target_id = target.id
    data.sphere_id = sphere.id
    data.files = files.map(file => file.uuid)

    if (!data.description) {
      data.description = JSON.stringify({
        json: null,
        html: ''
      })
    }

    if (afterTaskId) {
      data.after_task_id = afterTaskId
    }

    const { project } = rootGetters
    return apiService.post(`projects/${project.code}/tasks`, data)
      .then(({ data }) => {
        draftTask.delete()
        return data
      })
  },

  updateTaskState ({ commit, dispatch }, { id, state }) {
    commit('UPDATE_TASK_STATE', state)

    if (!id) {
      return Promise.resolve()
    }

    const updatedData = Object.keys(state).reduce((res, fieldName) => {

      const fieldValue        = state[fieldName]
      const resolvedFieldName = taskFieldsResolver[fieldName]

      if (resolvedFieldName) {
        res[resolvedFieldName] = fieldValue[resolvedFieldName === 'assignee_id' ? 'member_id' : 'id']
      } else {
        res[fieldName] = fieldValue
      }

      return res
    }, {})


    return dispatch('updateTask', { id, onUpdated: () => ({}), state: updatedData })
  },

  updateTaskField ({ commit, dispatch }, { id, field, value }) {
    commit('UPDATE_TASK_FIELD', { field, value })

    if (!id) {
      return Promise.resolve()
    }

    const updatedData = {}
    const resolvedFieldName = taskFieldsResolver[field]

    if (resolvedFieldName) {
      updatedData[resolvedFieldName] = value.id
    } else {
      updatedData[field] = value
    }

    return dispatch('updateTask', { id, ...updatedData })
  },

  setTaskField ({ commit }, { field, value }) {
    commit('UPDATE_TASK_FIELD', { field, value })
  },

  updateTask ({ rootGetters }, { id, ...data }) {
    const { project } = rootGetters
    return apiService.patch(`projects/${project.code}/tasks/${id}`, data)
      .then(({ data }) => {
        return data
      })
  },

  async uploadAttachment ({ commit, dispatch }, { id, formData, progressCallback = () => '' }) {
    const { data } = await apiService.uploadFile(`files`, formData, progressCallback)

    if (id !== 0) {
      await dispatch('addAttachment', {taskId: id, uuid: data.uuid})
      commit('ADD_FILE', data)
    } else {
      commit('ADD_FILE', data)
    }

    return data
  },

  downloadFile (ctx, { uuid }) {
    return apiService.downloadFile(`/files/${uuid}`)
      .then(response => {
        let blob = new Blob([response.data]),
          url = window.URL.createObjectURL(blob)

        const disposition = response.headers['content-disposition']
        let filename = ''

        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }


        var fileLink = document.createElement('a');

        fileLink.href = url;
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      })
  },

  deleteAttachment ({ commit }, { uuid }) {
    return apiService.delete(`files/${uuid}`)
      .then(() => {
        commit('REMOVE_FILE', uuid)
      })
  },

  async addAttachment ({ rootGetters }, { taskId, uuid }) {
    const { project } = rootGetters

    const { data } = await apiService.post(`projects/${project.code}/tasks/${taskId}/attachments`, { uuid })
    return data
  },

  addComment ({ commit, rootGetters }, { taskId, text }) {
    const { project } = rootGetters

    return apiService.post(`projects/${project.code}/tasks/${taskId}/comments`, { text })
      .then(({ data }) => {
        commit('PREPEND_COMMENT', transformComment(data))

        return data
      })
  },

  deleteComment ({ commit, rootGetters }, { taskId, commentId }) {
    const { project } = rootGetters

    return apiService.delete(`projects/${project.code}/tasks/${taskId}/comments/${commentId}`)
      .then(({ data }) => {
        commit('DELETE_COMMENT', { id: commentId })

        return data
      })
  },

  async updateComment ({ commit, rootGetters }, { taskId, commentId, text })
  {
    const { project } = rootGetters

    try {
      const { data } = await apiService.put(`projects/${project.code}/tasks/${taskId}/comments/${commentId}`, { text })
      commit('UPDATE_COMMENT', transformComment(data))

      return data
    } finally {
      commit('SET_UPDATING_COMMENT', 0)
    }
  },

  refreshTask ({ commit, rootGetters }, { task }) {
    commit('REFRESH_TASK', transformTask(task, rootGetters))
  },

  prependComment ({ commit }, comment) {
    commit('PREPEND_COMMENT', transformComment(comment))
  },

  prependTimeline ({ commit, rootGetters }, item) {
    const feedPerformFunc = createFeedTransformer(rootGetters)

    commit('PREPEND_TIMELINE', feedPerformFunc(item))
  },

  setUpdatingComment( { commit }, commentId) {
    commit('SET_UPDATING_COMMENT', commentId)
  }
}

export const getters = {
  task: ({ task }) => task,

  comments: ({ comments }) => comments,

  timeline: ({ timeline }) => timeline,

  taskError: ({ taskError }) => taskError,

  updatingComment: ({ updatingComment }) => updatingComment,
}

function transformComment(item) {
  item.createdAt = transformDateToObject(item.created_at)
  item.updatedAt = transformDateToObject(item.updated_at)

  return item
}

<template>
  <div>
    <div class="mb-2" v-if="multiProject">
      <ui-dropdown
          :options="allProjects"
          :value="selectedProjects"
          :multiple="true"
          key-field="code"
          reference-class="targets-dropdown targets-dropdown__last"
          popover-class="ui-dropdown_multiple"
          @input="onProjectSelected"
          placement="bottom-start"
          :width-by-reference="true"
      >
        <template v-slot:default>
          <div class="targets__selected">
            {{ selectedProjects.length === 0 ? $t('settings.members.projectsLabel') : `${$t('settings.members.selectedProjects')} ${selectedProjects.length}`}}
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="task-spheres__chevron-icon"><path d="M1.66492 0.103516L5.99992 4.43852L10.3349 0.103516L11.6666 1.44463L5.99992 7.11129L0.333252 1.44463L1.66492 0.103516Z" fill="#2F3750" class="path-fill"></path></svg>
          </div>
        </template>
        <template v-slot:option="{ option, selected }">
          <el-checkbox :value="selected">
            <div class="checkbox-with-photo">
              <workspace-logo :workspace="option" size="xs"/>
              <div>{{ option.name }}</div>
            </div>
          </el-checkbox>
        </template>
      </ui-dropdown>
      <transition name="input-error-anim">
        <div class="text-field__error" v-if="projectsRequiredError">
          {{ $t('settings.members.projectsError') }}
        </div>
      </transition>
    </div>
    <ui-tabs :tabs="sourceTabs" v-model="selectedTab" variant="medium">
      <template v-slot:default="{ tab }">
        {{ tab.name }}
      </template>
    </ui-tabs>

    <div class="mt-4">
      <ui-dropdown
          v-if="!isNomineesFetching && selectedTab === 'internal'"
          :options="invitationNominees"
          :value="existingMemberIds"
          :filterable="true"
          :multiple="true"
          key-field="member_id"
          reference-class="targets-dropdown targets-dropdown__last"
          popover-class="ui-dropdown_multiple"
          @input="onMemberSelected"
          placement="bottom-start"
          :width-by-reference="true"
          :compare-field="(item) => `${item.first_name} ${item.last_name}`"
      >
        <template v-slot:default>
          <div class="targets__selected">
            {{ existingMemberIds.length === 0 ? $t('settings.members.membersLabel') : `${$t('settings.members.selected')} ${existingMemberIds.length}`}}
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="task-spheres__chevron-icon"><path d="M1.66492 0.103516L5.99992 4.43852L10.3349 0.103516L11.6666 1.44463L5.99992 7.11129L0.333252 1.44463L1.66492 0.103516Z" fill="#2F3750" class="path-fill"></path></svg>
          </div>
        </template>
        <template v-slot:option="{ option, selected }">
          <el-checkbox :value="selected">
            <div class="checkbox-with-photo">
              <user-photo :user="option" size="sm"/>
              <div>{{ option.full_name }}</div>
            </div>
          </el-checkbox>
        </template>
      </ui-dropdown>

      <div v-if="selectedTab === 'external'">
        <el-tooltip :content="$t('settings.members.emailError')" placement="top" :manual="true" v-model="showErrors">
          <ui-select
              :value="form.email"
              :selected="foundMember"
              :search-fn="search"
              :check-entered-data-fn="val => validateEmail(val)"
              :no-data-text="$t('settings.members.noUsers')"
              :label="$t('settings.members.enterEmail')"
              @input="onEmailInput"
              @select="onExistedMemberSelect"
          >
            <template v-slot:option="{ option, showMatches }">
              <div class="find-member-item">
                <user-photo :user="option"/>
                <div class="find-member-item__title">
                  <div class="find-member-item__email" v-html="showMatches(option.email)"></div>
                  <div class="find-member-item__fullname">
                    {{ option.first_name }} {{ option.last_name }}
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:selected-item="{ value, removeSelected }">
              <div class="selected-member">
                <div class="selected-member__content">
                  <user-photo :user="value"/>
                  <div class="selected-member__title">
                    <div class="selected-member__fullname">{{ value.first_name }} {{ value.last_name}}</div>
                    <div class="selected-member__email">{{ value.email }}</div>
                  </div>
                </div>

                <ui-button ui-class="plain" class="selected-member__delete" @click="onRemoveMember(removeSelected)">
                  <close-icon background="#2F3750"/>
                </ui-button>
              </div>
            </template>
          </ui-select>
        </el-tooltip>

        <div class="mt-2">
          {{ $t('settings.members.availableNewMembersTitle') }} {{ $tc('settings.members.availableNewMembersCount', availableCount, { count: availableCount }) }}
        </div>

        <div class="form-control mt-4">
          <ui-button @click.prevent="onAddMember" ui-class="block" :disabled="isMemberAddingDisabled">{{ $t('settings.members.addMember') }}</ui-button>
        </div>
      </div>
    </div>

    <template v-if="newMembers.length || existingMembers.length">
      <div class="invitation-title">{{ $t('settings.members.addedMembers')}}</div>

      <div class="invitation-list">
        <invited-member-item
            v-for="(member, index) in newMembers"
            :key="member.email"
            :member="member"
            @remove="() => onRemoveNewAddedMember(index)"
        />

        <invited-member-item
            v-for="member in existingMembers"
            :key="member.member_id"
            :member="member"
            @remove="() => onRemoveExistingAddedMember(member)"
        />
      </div>
    </template>

    <div class="mt-4">
      <div class="form-control" v-if="newMembers.length || existingMembers.length">
        <ui-loading-button
          :loading="isProcessing"
          @click.prevent="onSendInvitations"
          ui-class="block"
        >
          {{ $t('settings.members.sendInvitations') }}
        </ui-loading-button>
      </div>

      <div class="form-control mt-2">
        <ui-button @click.prevent="onCancel" ui-class="block plain">{{ $t(cancelTitle) }}</ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"

import UiButton from 'scorework-ui/src/components/UiButton'
import UiSelect from "../ui/UiSelect"
import UserPhoto from "../ui/UserPhoto"
import CloseIcon from "../icons/CloseIcon"
import UiDropdown from "@/components/tasks/UiDropdown"
import UiTabs from "@/components/ui/UiTabs"
import InvitedMemberItem from "@/components/settings/InvitedMemberItem"
import UiLoadingButton from "@/components/ui/UiLoadingButton"
import WorkspaceLogo from "@/components/ui/WorkspaceLogo"

const nullableForm = function () {
  return {
    id: null,
    email: '',
    first_name: '',
    last_name: '',
    photo_sm: ''
  }
}

export default {
  name: 'InviteMembers',

  components: {
    UiButton, UiLoadingButton, UiSelect, UiTabs, InvitedMemberItem, UserPhoto, UiDropdown, CloseIcon,
    WorkspaceLogo,
  },

  props: {
    cancelTitle: {
      type: String,
      default: 'common.cancel'
    },

    multiProject: {
      type: Boolean,
      default: false,
    }
  },

  data () {
    return {
      tabs: [
        {
          id: 'internal',
          name: 'Из других проектов',
        },
        {
          id: 'external',
          name: 'Пригласить новых пользователей',
        },
      ],
      selectedTab: 'internal',
      form: nullableForm(),
      foundMember: null,
      existingMembers: [],
      newMembers: [],
      showErrors: false,
      isNomineesFetching: false,
      isProcessing: false,
      selectedProjects: [],
      projectsRequiredError: false,
    }
  },

  computed: {
    ...mapGetters(['invitationNominees', 'features', 'allProjects']),

    isMemberAddingDisabled () {
      return !this.form.email || this.availableCount === 0
    },

    existingMemberIds () {
      return this.existingMembers.map((item) => item.member_id)
    },

    sourceTabs () {
      return this.tabs.filter((item) => {
        return item.id !== 'internal' || this.invitationNominees.length > 0
      })
    },

    availableCount () {
      const membersFeature = this.features.find((item) => item.code === 'members_count')

      return membersFeature
          ? membersFeature.capacity - membersFeature.used - this.newMembers.length
          : 0
    }
  },

  methods: {
    ...mapActions([
        'findUsers', 'inviteMembers', 'fetchInvitationNominees', 'fetchFeatures', 'setUpgradePlanModal',
        'fetchAllProjects',
    ]),
    ...mapActions('workspaceMembers', ['inviteWorkspaceMembers']),

    onSubmit () {
      this.$router.push({ name: 'setupMembers' })
    },

    search (val) {
      return this.findUsers(val)
    },

    validateEmail (val) {
      const emailRegex = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
      const isValid = emailRegex.test(val)

      if (false === isValid) {
        this.showErrors = true
      }

      return isValid
    },

    onEmailInput (val) {
      if (this.showErrors) {
        this.showErrors = false
      }

      this.form.email = val
    },

    onAddMember () {
      if (!this.validateEmail(this.form.email)) {
        return
      }

      this.newMembers.push(this.form)
      this.form = nullableForm()
      this.foundMember = null
    },

    onExistedMemberSelect (member) {
      this.showErrors = false

      this.foundMember = member

      if (!member) {
        this.form = nullableForm()
        return
      }

      this.form = {...member}
    },

    onRemoveMember (removeFn) {
      removeFn()
    },

    onRemoveNewAddedMember (index) {
      this.newMembers.splice(index, 1)
    },

    onRemoveExistingAddedMember (member) {
      this.existingMembers = this.existingMembers.filter((item) => item.member_id !== member.member_id)
    },

    async doInviteMembers () {
      if (this.multiProject) {
        return await this.inviteWorkspaceMembers({
          new_members: this.newMembers,
          members: this.existingMembers.map((item) => item.member_id),
          projectCodes: this.selectedProjects.map((item) => item.code),
        })
      }

      return await this.inviteMembers({
        new_members: this.newMembers,
        members: this.existingMembers.map((item) => item.member_id),
      })
    },

    async onSendInvitations () {
      if (this.multiProject && this.selectedProjects.length === 0) {
        this.projectsRequiredError = true
        return
      }

      try {
        this.isProcessing = true
        await this.doInviteMembers()

        this.$emit('submit')
      } catch(e) {
        this.$notify({
          title: this.$t('common.error'),
          message: this.$t('settings.members.notifications.inviteFailed'),
          position: 'bottom-right',
          type: 'error'
        })
      } finally {
        this.isProcessing = false
      }
    },

    onCancel () {
      this.$emit('cancel')
    },

    onMemberSelected (members) {
      this.existingMembers = members
    },

    onProjectSelected (projects) {
      this.selectedProjects = projects
      this.projectsRequiredError = false
    },

    async loadNominees () {
      this.isNomineesFetching = true
      try {
        await this.fetchInvitationNominees()
        if (this.invitationNominees.length === 0) {
          this.selectedTab = 'external'
        }
      } finally {
        this.isNomineesFetching = false
      }
    },

    async loadFeatures () {
      await this.fetchFeatures()
      if (this.availableCount === 0) {
        this.setUpgradePlanModal({
          show: true,
          feature: 'members_count',
        })
      }
    }
  },

  async created () {
    await Promise.all([
        this.loadNominees(),
        this.loadFeatures(),
        ...this.multiProject ? [this.fetchAllProjects()] : [],
    ])
  }
}
</script>
<style lang="sass">
  .invite-nominee-item
    display: flex
    column-gap: 12px
    align-items: center

    &__content

    &__extra
      color: var(--text-light-color)
      margin-top: 6px

  .invite-form-label
    color: var(--text-light-color)
    margin-bottom: 8px
</style>

<template>
  <div>
    <el-popover
      :placement="placement"
      trigger="manual"
      :popper-class="popperClass"
      ref="popper"
      v-model="isOpen"
      :visible-arrow="false"
      :width="width"
      @show="onShowPopover"
      @hide="onHidePopover"
    >
      <div class="form-dropdown__option-list ui-dropdown__option-list" ref="list">
        <div
          v-for="option in options"
          :key="optionKey(option)"
          :class="{ 'ui-dropdown__option': true, 'ui-dropdown__option_active': isSelected(option) }"
          @click="onClickOption(option)"
        >
          <slot name="option" :option="option" :is-selected="isSelected(option)"></slot>
        </div>
      </div>

      <div :class="referenceClasses" slot="reference" @click="onClickReference" ref="reference">
        <div class="form-dropdown__label" v-if="label">{{ label }}</div>
        <div class="form-dropdown__value">
          <slot></slot>
        </div>
      </div>
    </el-popover>
    <transition-group name="input-error-anim" tag="div" appear>
      <div class="text-field__error" v-for="(error, index) in errorMessages" :key="index">
        {{ error }}
      </div>
    </transition-group>
  </div>
</template>
<script>
import FormControlMixin from 'scorework-ui/src/components/mixins/FormControlMixin'

export default {
  name: 'FormDropdown',

  mixins: [FormControlMixin],

  props: {
    options: {
      type: Array,
      default: () => []
    },

    value: {
      required: true
    },

    multiple: {
      type: Boolean,
      default: false
    },

    keyField: {
      type: String,
      default: 'id'
    },

    popoverClass: {
      type: String,
      default: ''
    },

    referenceClass: {
      type: String,
      default: ''
    },

    placement: {
      type: String,
      default: 'bottom'
    },

    clickFn: {
      type: Function
    },

    label: {
      type: String,
      default: ''
    },

    prop: {
      type: String,
      required: true
    }
  },

  data () {
    const tempValue = this.multiple
      ? [...this.value]
      : []

    return {
      tempValue,
      width: '150px',
      isOpen: false
    }
  },

  computed: {
    popperClass () {
      let classes = 'form-dropdown-popper ui-dropdown ' + this.popoverClass

      return classes
    },

    referenceClasses () {
      let classes = 'form-dropdown'

      if (this.referenceClass) {
        classes += ' ' + this.referenceClass
      }

      if (this.isOpen) {
        classes += ' form-dropdown_opened'
      }

      if (this.isError) {
        classes += ' form-dropdown_invalid'
      }

      return classes
    }
  },

  methods: {
    onClickOption (option) {
      if (this.clickFn && false === this.clickFn(option)) {
        return
      }

      if (this.validator.$reset) {
        this.validator.$reset()
      }

      if (!this.multiple) {
        if (this.isSelected(option)) {
          return
        }

        this.$emit('input', option)
        this.closeDropdown()
      } else {
        if (!this.isSelected(option)) {
          this.tempValue.push(option)
        } else {
          const index = this.tempValue.indexOf(option)
          this.tempValue.splice(index, 1)
        }

        this.$emit('input', this.tempValue)
        this.$nextTick(() => {
          this.$refs.popper.updatePopper()
        })
      }
    },

    isSelected (option) {
      if (this.multiple) {
        if (this.keyField) {
          return this.tempValue.findIndex(item => option[this.keyField] === item[this.keyField]) !== -1
        }

        return this.tempValue.indexOf(option) !== -1
      }

      if (this.keyField) {
        if (this.value instanceof Object) {
          return option[this.keyField] === this.value[this.keyField]
        }

        return option[this.keyField] === this.value
      }

      return option === this.value
    },

    optionKey (option) {
      if (this.keyField) {
        return option[this.keyField]
      }

      return option
    },

    onClickReference () {
      this.width = this.$refs.reference.offsetWidth
      this.isOpen = !this.isOpen
    },

    closeDropdown() {
      this.$refs.popper.doClose()
      this.isOpen = false
    },

    onDocumentClickHandler (e) {
      if (!this.isOpen) {
        return
      }

      const reference = this.$refs.reference
      const popper = this.$refs.popper.$el

      if (!this.$el || !reference || this.$el.contains(e.target) || reference.contains(e.target) || !popper || popper.contains(e.target)) {
        return;
      }
      this.closeDropdown()
    },

    onShowPopover () {
      this.$nextTick(() => {
        this.width = this.$refs.reference.offsetWidth

        if (this.$refs.list.clientWidth > this.$refs.reference.clientWidth) {
          this.$refs.reference.style.width = this.$refs.list.clientWidth + 'px'
        } else {
          this.width = this.$refs.reference.clientWidth + 'px'
        }

        this.$refs.popper.updatePopper()
      })
    },

    onHidePopover () {
      this.$refs.reference.style.width = null
    }
  },

  created () {
    document.addEventListener('click', this.onDocumentClickHandler)
  },

  destroyed () {
    document.removeEventListener('click', this.onDocumentClickHandler)
  },

  beforeDestroy() {
    if (this.validator.$reset) {
      this.validator.$reset()
    }
  }
}
</script>
<style lang="sass">
.form-dropdown
  border: 1px solid var(--grey-light-color-100)
  background: var(--block-bg-color)
  border-radius: 8px
  padding: 6px 16px
  transition: all ease-in .2s
  box-sizing: border-box
  cursor: pointer

  &:hover
    .form-dropdown__label
      color: var(--primary-color)

  &_opened
    border-radius: 8px 8px 0 0

    .form-dropdown__chevron
      transform: rotate(180deg)

  &_invalid
    border-color: var(--alert-color)

    .form-dropdown__label
      color: var(--alert-color)

  &__label
    color: var(--text-light-color)
    font-size: 12px
    transition: all ease-in .2s

  &__value
    height: 32px
    display: flex
    align-items: center

  &__selected
    display: flex
    justify-content: space-between
    width: 100%
    align-items: center

  &__chevron
    transition: all ease-in .2s

  &__option-list
    max-height: 186px
    overflow: auto

.el-popover.form-dropdown-popper
  &[x-placement^=bottom]
    margin-top: -2px
    border: 1px solid var(--grey-light-color-100)
    border-top: 0
    box-sizing: border-box
</style>

const draftKey = 'newTaskDraft'

const draftTask = {
  save (task) {
    const { description, ...draft } = task

    if (typeof description === 'object') {
      draft.description = description
    }

    if (typeof description === 'string' && description) {
      const { json } = JSON.parse(description)
      draft.description = json
    }

    localStorage.setItem(draftKey, JSON.stringify(draft))
  },

  delete () {
    localStorage.removeItem(draftKey)
  },

  get () {
    const rawTask = localStorage.getItem(draftKey)
    if (!rawTask) {
      return null
    }

    return JSON.parse(rawTask)
  }
}

export default draftTask

import { mergeAttributes, Node } from '@tiptap/core'

export const Emoji = Node.create({
    name: 'emoji',

    group: 'inline',

    inline: true,

    selectable: false,

    atom: true,

    addOptions () {
        return {
            HTMLAttributes: {},
            renderLabel({ node }) {
                return node.attrs.label
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: `span[class="${this.name}"]`,
            },
        ]
    },

    addAttributes() {
        return {
            id: {
                default: null,
                parseHTML: element => element.getAttribute('data-mention-id'),
                renderHTML: attributes => {
                    if (!attributes.id) {
                        return {}
                    }

                    return {
                        'data-mention-id': attributes.id,
                    }
                },
            },

            label: {
                default: null,
                parseHTML: element => element.innerText,
                renderHTML: () => {
                    return {}
                },
            },
        }
    },

    renderHTML({ node, HTMLAttributes }) {
        return [
            'span',
            mergeAttributes({ class: this.name }, this.options.HTMLAttributes, HTMLAttributes),
            this.options.renderLabel({ node }),
        ]
    },

    renderText({ node }) {
        return this.options.renderLabel({ node })
    }
})

export default Emoji
const workspaceSocket = (store) => socket => {

  const listeners = {
    'connect': () => {
      socket.emit('init', getInitData())
    },

    'projects.updated': ({ project }) => {
      store.dispatch('refreshProjectInList', { project })
    },

    'tasks.active.refresh': ({ task }) => {
      store.dispatch('setActiveTask', { task })
    },

    'notifications.added': ({ unseen_count, notification }) => {
      store.dispatch('setUnseenNotifications', unseen_count)
      store.dispatch('notifications/prependNotification', notification)
    },

    'workspaces.updated': ({ workspace }) => {
      store.dispatch('refreshWorkspace', workspace)
    },

    'workspaces.features.updated': ({ feature }) => {
      store.dispatch('refreshFeature', { feature })
    },

    'workspace.subscription.update': ({ workspace, features }) => {
      const { rate_plan_code, subscription_status, subscription_expired_at } = workspace

      store.dispatch('refreshWorkspace', { rate_plan_code, subscription_status, subscription_expired_at })
      store.dispatch('setFeatures', features)
    },
  }

  Object.keys(listeners).forEach(eventName => {
    socket.on(eventName, listeners[eventName])
  })

  function getInitData () {
    const { member_id: memberId }     = store.getters.authUser
    const { global_id: workspaceId }  = store.getters['workspace']

    return {
      workspaceId, memberId
    }
  }
}

export default workspaceSocket


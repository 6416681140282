<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" class="path-fill" clip-rule="evenodd" d="M11.0295 1.41527C10.4352 1.41527 9.86529 1.6391 9.44509 2.03751L2.58635 8.54067C1.88607 9.20465 1.49265 10.1052 1.49265 11.0442C1.49265 11.9832 1.88607 12.8838 2.58635 13.5477C3.28664 14.2117 4.23643 14.5847 5.22678 14.5847C6.21713 14.5847 7.16692 14.2117 7.8672 13.5477L14.7259 7.04458C15.0174 6.76823 15.4899 6.76823 15.7814 7.04458C16.0729 7.32093 16.0729 7.76898 15.7814 8.04532L8.92267 14.5485C7.94245 15.4779 6.613 16 5.22678 16C3.84055 16 2.5111 15.4779 1.53089 14.5485C0.550677 13.6191 0 12.3586 0 11.0442C0 9.72984 0.550677 8.46932 1.53089 7.53992L8.38963 1.03677C9.08976 0.372936 10.0393 0 11.0295 0C12.0196 0 12.9692 0.372936 13.6693 1.03677C14.3694 1.7006 14.7628 2.60095 14.7628 3.53974C14.7628 4.47854 14.3694 5.37889 13.6693 6.04272L6.8031 12.5459C6.38305 12.9441 5.81335 13.1679 5.21931 13.1679C4.62528 13.1679 4.05557 12.9441 3.63553 12.5459C3.21548 12.1476 2.9795 11.6074 2.9795 11.0442C2.9795 10.481 3.21548 9.9408 3.63553 9.54253L9.97215 3.5415C10.2638 3.26531 10.7363 3.26557 11.0276 3.54209C11.3189 3.8186 11.3186 4.26665 11.027 4.54283L4.69099 10.5433C4.55107 10.6761 4.47216 10.8565 4.47216 11.0442C4.47216 11.2321 4.55087 11.4123 4.69099 11.5451C4.83111 11.678 5.02116 11.7526 5.21931 11.7526C5.41747 11.7526 5.60751 11.678 5.74763 11.5451L12.6138 5.04198C13.0339 4.64359 13.2701 4.10306 13.2701 3.53974C13.2701 2.9763 13.034 2.43593 12.6138 2.03751C12.1936 1.6391 11.6237 1.41527 11.0295 1.41527Z" :fill="backgroundColor"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'AttachIcon',

    mixins: [iconMixin],

    props: {
      viewBox: {
        type: String,
        default: '0 0 12 12'
      }
    },

    data () {
      return {
        defaultBackground: '#2C3E50',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

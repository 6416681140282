<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" d="M8.33268 11.3333L13.2493 6.41667C13.4021 6.26389 13.5966 6.1875 13.8327 6.1875C14.0688 6.1875 14.2632 6.26389 14.416 6.41667C14.5688 6.56944 14.6452 6.76389 14.6452 7C14.6452 7.23611 14.5688 7.43056 14.416 7.58333L8.91602 13.0833C8.74935 13.25 8.5549 13.3333 8.33268 13.3333C8.11046 13.3333 7.91602 13.25 7.74935 13.0833L5.58268 10.9167C5.4299 10.7639 5.35352 10.5694 5.35352 10.3333C5.35352 10.0972 5.4299 9.90278 5.58268 9.75C5.73546 9.59722 5.9299 9.52083 6.16602 9.52083C6.40213 9.52083 6.59657 9.59722 6.74935 9.75L8.33268 11.3333Z" fill="#5B6372"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'FinishedIcon',

    mixins: [iconMixin],

    props: {
      viewBox: {
        type: String,
        default: '0 0 12 12'
      }
    },

    data () {
      return {
        defaultBackground: '#416AF7',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="path-fill" fill-rule="evenodd" clip-rule="evenodd" d="M8.00016 2.0835C4.73248 2.0835 2.0835 4.73248 2.0835 8.00016C2.0835 11.2678 4.73248 13.9168 8.00016 13.9168C11.2678 13.9168 13.9168 11.2678 13.9168 8.00016C13.9168 4.73248 11.2678 2.0835 8.00016 2.0835ZM0.583496 8.00016C0.583496 3.90405 3.90405 0.583496 8.00016 0.583496C12.0963 0.583496 15.4168 3.90405 15.4168 8.00016C15.4168 12.0963 12.0963 15.4168 8.00016 15.4168C3.90405 15.4168 0.583496 12.0963 0.583496 8.00016ZM8 7.25C8.41421 7.25 8.75 7.58579 8.75 8V10.6667C8.75 11.0809 8.41421 11.4167 8 11.4167C7.58579 11.4167 7.25 11.0809 7.25 10.6667V8C7.25 7.58579 7.58579 7.25 8 7.25ZM8 4.5835C7.58579 4.5835 7.25 4.91928 7.25 5.3335C7.25 5.74771 7.58579 6.0835 8 6.0835H8.00667C8.42088 6.0835 8.75667 5.74771 8.75667 5.3335C8.75667 4.91928 8.42088 4.5835 8.00667 4.5835H8Z" fill="#999FAE"/>
</svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'InfoIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 10,
        defaultHeight: 10
      }
    }
  }
</script>

import apiService from "@/services/api"
import { transformDateToObject } from "@/services/scoring"

export const namespaced = true

export const state = {
  notifications: []
}

export const mutations = {
  SET_NOTIFICATIONS (state, data) {
    state.notifications = data.map(transformNotification)
  },

  PREPEND_NOTIFICATION (state, item) {
    state.notifications.unshift(transformNotification(item))
  },

  MARK_AS_SEEN (state, id) {
    state.notifications = state.notifications.map(item => {
      if (item.id === id) {
        item.unseen = false
      }

      return item
    })
  },

  MARK_ALL_AS_SEEN (state) {
    state.notifications = state.notifications.map(item => {
      item.unseen = false
      return item
    })
  }
}

export const actions = {
  fetchNotifications ({ commit }) {
    return apiService.get(`notifications`)
      .then(({ data }) => {
        commit('SET_NOTIFICATIONS', data)
      })
  },

  markAsRead ({ commit, dispatch }, { id }) {
    return apiService.post(`notifications/${id}`)
      .then(({ data }) => {
        const { unseen_notifications } = data

        dispatch('setUnseenNotifications', unseen_notifications, { root: true })
        commit('MARK_AS_SEEN', id)
      })
  },

  markAllAsSeen ({ commit, dispatch }) {
    return apiService.post(`notifications/seen`)
      .then(() => {
        dispatch('setUnseenNotifications', 0, { root: true })
        commit('MARK_ALL_AS_SEEN')
      })
  },

  prependNotification ({ commit }, item) {
    item.unseen = true
    commit('PREPEND_NOTIFICATION', item)
  }
}

export const getters = {
  notifications: ({ notifications }) => notifications
}

function transformNotification (item) {
  item.dateObj = transformDateToObject(item.date)
  return item
}
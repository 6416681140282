<template>
  <div class="status-task-item" @dblclick="onOpenTask" v-draggable-item="{ list: listName, item: task, dragSelector: '.status-task-item__block' }">
    <div class="status-task-item__block">
      <div class="status-task-item__target" :class="{['target-label_' + task.target.color]: true}" v-if="task.target"></div>

      <div class="status-task-item__content">
        <div class="status-task-item__title">
          <div class="status-task-item__number">
            {{ taskNumber }}
          </div>
          <a href="#" class="status-task-item__title-link" @click="onOpenTask">{{ task.name }}</a>
        </div>

        <div class="status-task-item__info">
          <sphere-badge class="task-item__sphere-badge" :sphere="task.sphere" v-if="task.sphere"/>

          <div class="status-task-item__prop" v-if="task.comments_count">
            <comments-icon class="status-task-item__prop-icon"/> {{ task.comments_count }}
          </div>

          <div class="status-task-item__prop" v-if="task.score">
            <clock-icon class="status-task-item__prop-icon"/> {{ formattedScore }}
          </div>

          <user-photo :user="taskState.assignee" :show-online="false"/>
        </div>
      </div>
    </div>

    <div class="task-list-span" @mouseover="showAddButton = true" @mouseleave="showAddButton = false">
      <a href="#" @click.prevent="onAddTask">
        <round-plus class="task-list-span__btn" v-if="showAddButton"/>
      </a>
    </div>
  </div>
</template>
<script>
  import taskItemMixin from "@/components/tasks/mixins/taskItemMixin"
  import CommentsIcon from "@/components/icons/CommentsIcon"
  import SphereBadge from "@/components/ui/SphereBadge"

  export default {
    name: 'StatusTaskItem',

    components: { CommentsIcon, SphereBadge },

    mixins: [taskItemMixin],

    props: {
      status: {
        type: String,
        required: true
      },

      task: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        listName: `${this.status}-tasks`
      }
    }
  }
</script>

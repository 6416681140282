<template>
<el-dialog
  custom-class="tour-dialog"
  :visible="true"
  :show-close="false"
  width="400px"
>
  <div class="tour-started">
    <div class="tour-started__bg"></div>
    <div class="tour-started__title">
      {{ $t('tour.started.title') }}
    </div>
    <div class="tour-started__description" v-html="$t('tour.started.description')"></div>
    <div class="tour-started__actions">
      <ui-button ui-class="block" @click="onConfirm">{{ $t('tour.started.confirm') }}</ui-button>
      <ui-button ui-class="block plain" class="tour-started__skip" @click="onSkip">{{ $t('tour.started.skip') }}</ui-button>
    </div>
  </div>
</el-dialog>
</template>
<script>
import UiButton from 'scorework-ui/src/components/UiButton'

export default {
  name: 'TourStarted',

  components: { UiButton },

  methods: {
    onConfirm () {
      this.$emit('start')
    },

    onSkip () {
      this.$emit('skip')
    }
  }
}
</script>
<style lang="sass">
.el-dialog.tour-dialog
  .el-dialog__header
    padding: 0

  .el-dialog__body
    padding: 32px

.tour-started
  display: flex
  flex-flow: column
  align-items: center

  &__bg
    background: url("../../assets/images/tour_start.png")
    width: 200px
    height: 167px
    background-size: cover

  &__title
    font-size: 20px
    color: var(--text-primary-color)
    font-weight: 500
    margin: 24px 0px
    text-align: center

  &__description
    font-size: 16px
    line-height: 24px
    color: var(--text-light-color)
    text-align: center
    width: 320px

    p
      margin: 0
      padding: 0 0 12px

  &__actions
    padding-top: 12px
    width: 200px

    .ui-button
      height: 44px
      font-size: 16px
      font-weight: 500

  &__skip
    margin-top: 8px
</style>

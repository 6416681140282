<template>
<div class="member-schedule">
  <div class="member-schedule__days">
    <div
        v-for="(weekDay, number) in weekDays"
        :key="number"
        :class="weekDayClasses(number)"
    >
      {{ $t(weekDay.short) }}
    </div>
  </div>
  <div class="member-schedule__time">
    <clock-icon class="member-schedule__time-icon"/>
    {{ schedule.work_hours.start }}
    -
    {{ schedule.work_hours.end }}
  </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import ClockIcon from "@/components/icons/ClockIcon"
import { weekDays } from "@/services/scoring"

export default {
  name: 'MemberSchedule',

  components: { ClockIcon },

  props: {
    schedule: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      weekDays
    }
  },

  computed: {
    ...mapGetters(['authUser'])
  },

  methods: {
    weekDayClasses (day) {
      const classes = ['member-schedule__day']

      if (this.schedule.days.indexOf(Number(day)) !== -1) {
        classes.push('member-schedule__day_active')
      }

      return classes
    }
  }
}
</script>
<style lang="sass">
.member-schedule
  display: flex
  align-items: center

  &__days
    display: flex
    margin-right: 16px

  &__day
    width: 24px
    height: 24px
    border-radius: 8px
    font-size: 10px
    font-weight: 500
    background: var(--grey-light-color-40)
    color: var(--grey-color-100)
    display: flex
    align-items: center
    justify-content: center
    margin-right: 2px

    &_active
      background: var(--primary-light-color)
      color: var(--text-primary-color)

  &__time
    color: var(--text-primary-color)
    font-size: 12px
    display: flex
    align-items: center

  &__time-icon
    width: 14px
    height: 14px
    margin-right: 6px

    .path-stroke
      stroke: var(--text-light-color)
</style>
import { posToDOMRect } from '@tiptap/core'
import tippy from 'tippy.js'
import { VueRenderer } from '@tiptap/vue-2'
import FloatingNodeMenu from '@/components/ui/textEditor/floatingMenu/FloatingNodeMenu'
import {floatingMenuPluginKey} from '@/components/ui/textEditor/floatingMenu/plugin';

export const FloatingMenuView = ({
  editor,
  tippyOptions = {},
}) => {
  let preventHide = false
  let tippyInstance = null

  const shouldShow = ({ view, state }) => {
    const { selection } = state
    const { $anchor, empty } = selection

    const isParagraph = $anchor.parent.type.name === 'paragraph'
    //const isRootDepth = $anchor.depth === 1

    const isEmptyTextBlock = $anchor.parent.isTextblock && !$anchor.parent.type.spec.code && $anchor.parent.textContent === '/'

    if (
      !view.hasFocus()
      || !empty
      || !isParagraph
      || !isEmptyTextBlock
      || !editor.isEditable
    ) {
      return false
    }

    return true
  }

  const element = new VueRenderer(FloatingNodeMenu, {
    parent: this,
    propsData: {
      editor,
      forceShow: false,
    },
    editor: editor,
    forceShow: false,
  })

  const mousedownHandler = () => {
    preventHide = true
  }

  const focusHandler = () => {
    // we use `setTimeout` to make sure `selection` is already updated
    setTimeout(() => update(editor.view))
  }

  const blurHandler = ({ event }) => {
    if (preventHide) {
      preventHide = false

      return
    }

    if (event?.relatedTarget && element.ref.$el.parentNode?.contains(event.relatedTarget)) {
      return
    }

    hide()
  }

  const tippyBlurHandler = (event) => {
    blurHandler({ event })
  }

  const onKeyDown = ({ event: e }) => {
    if (!tippyInstance?.state?.isShown) {
      return
    }

    return element.ref.onKeyDown(e)
  };

  const createTooltip = () => {
    const { element: editorElement } = editor.options
    const editorIsAttached = !!editorElement.parentElement

    if (tippyInstance || !editorIsAttached) {
      return
    }

    tippyInstance = tippy(editorElement, {
      duration: 0,
      getReferenceClientRect: null,
      content: element.ref.$el,
      interactive: true,
      trigger: 'manual',
      placement: 'right',
      hideOnClick: 'toggle',
      ...tippyOptions,
    })

    // maybe we have to hide tippy on its own blur event as well
    if (tippyInstance.popper.firstChild) {
      tippyInstance.popper.firstChild.addEventListener('blur', tippyBlurHandler)
    }

    //tippyInstance.reference.addEventListener('keydown', onKeyDown)

  }

  const update = (view, oldState = undefined) => {
    const { state } = view
    const { doc, selection } = state
    const { from, to } = selection

    const pluginState = floatingMenuPluginKey.getState(state)

    if (oldState) {
      const oldPluginState = floatingMenuPluginKey.getState(oldState)

      const isSame = oldState.doc.eq(doc) && oldState.selection.eq(selection) && pluginState.forceShow === oldPluginState.forceShow

      if (isSame) {
        return
      }
    }

    createTooltip()

    const shouldDisplay = shouldShow({
      editor,
      view,
      state,
      oldState,
    }) || pluginState.forceShow

    if (!shouldDisplay) {
      hide()
      return
    }

    tippyInstance?.setProps({
      getReferenceClientRect:
        tippyOptions?.getReferenceClientRect || (() => {
          const node = editor.view.nodeDOM(from)
          // eslint-disable-next-line
          return node && Object.hasOwnProperty('getBoundingClientRect') ? node.getBoundingClientRect() : posToDOMRect(view, from, to)
        }),
    })

    show()
  }

  const show = () => {
    tippyInstance?.show()
  }

  const hide = () => {
    tippyInstance?.hide()
  }

  const destroy = () => {
    if (!tippyInstance) {
      return;
    }
    if (tippyInstance?.popper.firstChild) {
      tippyInstance.popper.firstChild.removeEventListener(
        'blur',
        tippyBlurHandler,
      )
    }

    tippyInstance?.reference.removeEventListener('keydown', onKeyDown)
    tippyInstance?.destroy()
    element.ref.$el.removeEventListener('mousedown', mousedownHandler, { capture: true })
    editor.off('focus', focusHandler)
    editor.off('blur', blurHandler)
  }

  element.ref.$el.addEventListener('mousedown', mousedownHandler, { capture: true })
  editor.on('focus', focusHandler)
  editor.on('blur', blurHandler)

  // Detaches menu content from its current parent
  //element.ref.$el.remove()
  //element.ref.$el.style.visibility = 'visible'

  return {
    update,
    destroy,
    onKeyDown,
    tippy: () => tippyInstance,
  }
}

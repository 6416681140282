<template>
  <el-dialog
    :title="$t('settings.members.inviteMembersTitle')"
    :visible="true"
    width="35%"
    @close="onClose"
  >
    <div>
      <invite-members
        :cancel-title="$t('common.close')"
        :multi-project="multiProject"
        @submit="onSubmit"
        @cancel="onClose"
      />
    </div>
  </el-dialog>
</template>
<script>
import InviteMembers from "@/components/settings/InviteMembers"

export default {
  name: 'AddMemberModal',

  components: { InviteMembers },

  props: {
    multiProject: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    onClose () {
      this.$emit('close')
    },

    onSubmit () {
      this.$notify({
        title: this.$t('settings.members.successTitle'),
        message: this.$t('settings.members.successDescription'),
        position: 'bottom-right',
        type: 'success'
      })

      this.$emit('submit')
    }
  }
}
</script>

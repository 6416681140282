<template>
  <svg :width="iconWidth" :height="iconHeight" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.62481 15.1248C11.0835 15.1248 12.4824 14.5453 13.5139 13.5139C14.5453 12.4824 15.1248 11.0835 15.1248 9.62481C15.1248 8.16612 14.5453 6.76717 13.5139 5.73572C12.4824 4.70427 11.0835 4.12481 9.62481 4.12481C8.16612 4.12481 6.76717 4.70427 5.73572 5.73572C4.70427 6.76717 4.12481 8.16612 4.12481 9.62481C4.12481 11.0835 4.70427 12.4824 5.73572 13.5139C6.76717 14.5453 8.16612 15.1248 9.62481 15.1248ZM15.4181 14.122L18.6998 17.4036C18.7873 17.4883 18.8571 17.5895 18.905 17.7013C18.953 17.8132 18.9782 17.9335 18.9792 18.0552C18.9801 18.1769 18.9568 18.2976 18.9107 18.4102C18.8645 18.5229 18.7964 18.6252 18.7102 18.7112C18.6241 18.7972 18.5217 18.8652 18.409 18.9112C18.2963 18.9572 18.1756 18.9803 18.0539 18.9792C17.9322 18.978 17.8119 18.9526 17.7001 18.9045C17.5883 18.8564 17.4872 18.7865 17.4027 18.6989L14.1211 15.4172C12.6475 16.5611 10.7934 17.1004 8.93617 16.9253C7.07896 16.7503 5.35828 15.874 4.1244 14.4749C2.89052 13.0759 2.23619 11.2592 2.29462 9.39466C2.35305 7.53014 3.11984 5.75796 4.4389 4.4389C5.75796 3.11984 7.53014 2.35305 9.39466 2.29462C11.2592 2.23619 13.0759 2.89052 14.4749 4.1244C15.874 5.35828 16.7503 7.07896 16.9253 8.93617C17.1004 10.7934 16.5611 12.6475 15.4172 14.1211L15.4181 14.122Z" :fill="backgroundColor"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'Search',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 22,
        defaultHeight: 22
      }
    }
  }
</script>

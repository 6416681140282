<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle class="path-stroke" cx="7" cy="7" r="6" stroke="#969FA8" stroke-width="1.3"/>
    <path class="path-stroke" d="M7 3.79175V7.00008L8.75 8.75008" stroke="#969FA8" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'ClockIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 10,
        defaultHeight: 10
      }
    }
  }
</script>

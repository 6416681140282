<template>
  <el-dialog
    :title="$t('messenger.newChat')"
    :visible="true"
    width="30%"
    :close-on-click-modal="false"
    @close="onClose"
  >
    <div>

      <div>
        <div class="channel-form__label">
          {{ $t('layout.members') }}
        </div>
        <ui-dropdown
          :label="$t('messenger.searchChatMember')"
          :options="memberList"
          :value="channel.members"
          :multiple="true"
          :width-by-reference="true"
          key-field="member_id"
          @input="onMemberChange"
          @filter-change="(value) => filterText = value"
          :widthByPopover="false"
          placement="bottom-start"
          popover-class="ui-dropdown_multiple"
          reference-class="ui-dropdown__reference-members"
        >
          <template>
            <div v-if="!channel.members.length">
              {{ $t('messenger.chooseChatMembers') }}
            </div>
            <div v-else class="channel-members-picker">
              <task-assignee
                v-for="member in channel.members"
                class="channel-members-picker__item"
                :key="member.member_id"
                :user="member"
              />
            </div>
          </template>

          <template v-slot:option="{ option, selected }">
            <el-checkbox :value="selected">  
              <task-assignee :user="option" :filterText="filterText" />
            </el-checkbox>
          </template>
        </ui-dropdown>
      </div>

      <div class="mt-4">
        <ui-button @click.prevent="onSubmit">{{ $t('messenger.goToChat') }}</ui-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from "vuex"

import UiButton from 'scorework-ui/src/components/UiButton'
import TaskAssignee from "@/components/tasks/components/TaskAssignee"
import UiDropdown from "@/components/tasks/UiDropdown"

const { mapActions: messengerActions } = createNamespacedHelpers('messenger')

export default {
  name: 'NewChannelModal',

  components: { TaskAssignee, UiDropdown, UiButton },

  data () {
    return {
      channel: {
        members: []
      },
      filterText: '',
    }
  },

  computed: {
    ...mapGetters(['authUser', 'activeMembers']),

    memberList () {
      if (!this.filterText.length) {
        return this.activeMembers.filter(member => member.member_id !== this.authUser.member_id)
      }

      return this.activeMembers.filter(member => member.member_id !== this.authUser.member_id
        && `${member.first_name} ${member.last_name}`.toLowerCase().includes(this.filterText.toLowerCase()))
    },
  },

  methods: {
    ...messengerActions(['createChannel']),

    onClose () {
      this.$emit('close')
    },

    onMemberChange (val) {
      this.channel.members = val
    },

    onSubmit () {
      const type = this.channel.members.length === 1 ? 'direct' : 'group'

      const channelMembers = this.channel.members.map(member => member.member_id)
      channelMembers.unshift(this.authUser.member_id)

      this.createChannel({
        type,
        members: channelMembers,
        name: ''
      }).then(channel => {
        this.$router.push({ name: 'messenger.channel', params: { channelId: channel.id } })
        this.onClose()
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="settings-title">
      {{ $t('spheres.workspaceSpheres') }}

      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('spheres.addSphere')"
        placement="top"
      >
        <div class="nsphere-item__control nsphere-item__control_add" @click="onCreateSphere">
          <plus class="nsphere-item__control-icon"/>
        </div>
      </el-tooltip>
    </div>

    <div class="setup-spheres__content">
      <spheres-list :spheres="spheres" />

      <div class="mt-4">
        <ui-button @click="onSave">
          {{ $t('common.save') }}
        </ui-button>
      </div>
    </div>
  </div>
</template>
<script>
import UiButton from 'scorework-ui/src/components/UiButton'
import SpheresList from "../../components/setup/SpheresList"
import Plus from "../../components/icons/Plus"
import { createNamespacedHelpers, mapActions, mapGetters } from "vuex"
import pageTitleMixin from "@/views/mixins/pageTitleMixin"
import authUserMixin from "@/mixins/authUserMixin"

const { mapGetters: spheresGetters, mapActions: spheresActions } = createNamespacedHelpers('spheres')

export default {
  name: 'SphereSettings',

  components: { UiButton, SpheresList, Plus },

  mixins: [pageTitleMixin, authUserMixin],

  data () {
    return {
      activeTab: null
    }
  },

  computed: {
    ...mapGetters(['workspace', 'project']),
    ...spheresGetters(['spheres']),

    pageTitle () {
      return {
        message: 'pageTitles.settings.spheres',
        params: { workspace: this.workspace.name }
      }
    }
  },

  methods: {
    ...spheresActions(['fetchSpheres', 'saveSpheres', 'createSphere']),
    ...mapActions(['setSpheres']),

    onCreateSphere () {
      this.createSphere({ parentSphere: null })
    },

    onSave () {
      this.saveSpheres({ spheres: this.spheres })
        .then(spheres => {
          this.setSpheres(spheres)

          this.$notify({
            title: this.$t('common.saved'),
            message: this.$t('spheres.spheresSavedSuccessfully'),
            position: 'bottom-right',
            type: 'success'
          })
        })
    }
  },

  created () {
    if (!this.isProjectAdmin) {
      this.$router.push({ name: 'workspace.member.settings' , params: { project: this.project.code} })
      return
    }

    this.fetchSpheres({})
  }
}
</script>

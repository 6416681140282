<template>
  <div>
    <el-popover
        placement="bottom"
        trigger="manual"
        ref="popper"
        popper-class="period-sprints__popper"
        v-model="isOpen"
        :visible-arrow="false"
        :width="width"
        @show="onShowPopover"
    >
      <div class="period-sprints">
        <div class="period-sprints__list" ref="list">
          <div
              v-for="sprint in sprints"
              :key="sprint.id"
              class="period-sprints__item"
              :class="itemClasses(sprint)"
              @click="onSelectSprint(sprint)"
              @mouseenter="() => onMouseEnter(sprint)"
              @mouseleave="() => onMouseLeave(sprint)"
          >
            <div class="period-sprints__item-name">
              {{ sprint.name }}
            </div>

            <div class="period-sprints__item-dates">
              {{ sprint.duration }}
            </div>
          </div>
        </div>

        <div class="period-sprints__control">
          <div class="period-sprints__selected">
            <div class="period-sprints__selected-item">
              <div class="period-sprints__selected-item-name">
                {{ startSprint ? startSprint.name : $t('report.notSelectedSprint') }}
              </div>

              <div class="period-sprints__selected-item-date">
                {{ startSprint ? startSprint.startedAt : '-' }}
              </div>
            </div>

            <div class="period-sprints__selected-item-delim">
              &mdash;
            </div>

            <div class="period-sprints__selected-item">
              <div class="period-sprints__selected-item-name">
                {{ endSprint ? endSprint.name : $t('report.notSelectedSprint') }}
              </div>

              <div class="period-sprints__selected-item-date">
                {{ endSprint ? endSprint.finishedAt : '-' }}
              </div>
            </div>
          </div>

          <ui-button ui-class="size_md" @click="onSubmit">
            {{ $t('common.select') }}
          </ui-button>
        </div>
      </div>

      <div class="period-sprints__reference"  slot="reference" @click="onClickReference" ref="reference">
        <div class="period-sprints__reference-name">
          {{ displayedName }}
        </div>

        <div class="period-sprints__reference-dates">
          {{ displayedDuration }}
        </div>
      </div>
    </el-popover>
  </div>
</template>
<script>
import UiButton from 'scorework-ui/src/components/UiButton'
import clickOutsideMixin from "@/mixins/clickOutsideMixin";

export default {
  name: 'PeriodSprintsSelector',

  components: { UiButton },

  mixins: [clickOutsideMixin],

  props: {
    value: {
      required: true,
      type: Array,
    },

    sprints: {
      required: true,
      type: Array,
    }
  },

  data () {
    return {
      hoveredItem: null,
      isOpen: false,
      width: null,
      selectedSprints: [],
    }
  },

  computed: {
    startSprint () {
      const sprintId = this.selectedSprints.reduce((min, id) => id < min || !min ? id : min, 0)

      return this.sprints.find((item) => item.id === sprintId)
    },

    endSprint () {
      const sprintId = this.selectedSprints.reduce((max, id) => id > max || !max ? id : max, 0)

      return this.sprints.find((item) => item.id === sprintId)
    },

    displayedName () {
      if (this.value.length === 0) {
        return this.$t('report.selectSprint')
      }

      const sprintNames =this.value.map((sprintId) => {
        const sprint = this.sprints.find((item) => item.id === sprintId)
        return sprint ? sprint.name : null
      }).filter((item) => item)

      return sprintNames.join(' - ')
    },

    displayedDuration () {
      if (this.value.length === 0) {
        return ''
      }

      const sprints = this.value.map((sprintId) => this.sprints.find((item) => item.id === sprintId)).filter((item) => item)
      const dates = [
        sprints[0].startedAt,
        sprints[sprints.length > 1 ? 1 : 0].finishedAt
      ]

      return dates.join(' - ')
    }
  },

  methods: {
    onMouseEnter (item) {
      if (this.selectedSprints.length === 0) {
        return
      }

      if (this.selectedSprints.length === 1 && this.selectedSprints[0] !== item.id) {
        this.hoveredItem = item.id
      }
    },

    onMouseLeave () {
      this.hoveredItem = null
    },

    onClickReference () {
      this.width = this.$refs.reference.offsetWidth

      if (!this.isOpen) {
        this.selectedSprints = this.value
      }

      this.isOpen = !this.isOpen
    },

    onSelectSprint (item) {
      if (this.selectedSprints.length < 2) {
        this.selectedSprints = [...this.selectedSprints, item.id]
        return
      }

      this.selectedSprints = [item.id]
    },

    onShowPopover () {
      this.$nextTick(() => {
        this.width = 500

        if (this.$refs.list.clientWidth > this.$refs.reference.clientWidth) {
          this.$refs.reference.style.width = this.$refs.list.clientWidth + 'px'
        } else {
          this.width = this.$refs.reference.clientWidth + 'px'
        }

        this.$refs.popper.updatePopper()
      })
    },

    itemClasses (item) {
      const classes = []

      if (this.selectedSprints.includes(item.id)) {
        classes.push('period-sprints__item_selected')
      }

      if (this.hoveredItem === item.id) {
        classes.push('period-sprints__item_second')
      }

      if (this.selectedSprints.length === 1 && this.hoveredItem) {
        const start = Math.min(this.selectedSprints[0], this.hoveredItem)
        const end = Math.max(this.selectedSprints[0], this.hoveredItem)

        if (item.id > start && item.id < end) {
          classes.push('period-sprints__item_gap')
        }
      }

      if (this.selectedSprints.length === 2) {
        const start = Math.min(...this.selectedSprints)
        const end = Math.max(...this.selectedSprints)

        if (item.id > start && item.id < end) {
          classes.push('period-sprints__item_gap')
        }
      }

      return classes
    },

    onSubmit () {
      this.selectedSprints.sort()
      this.$emit('input', this.selectedSprints)
      this.isOpen = false
    }
  }
}
</script>
<style lang="sass">
  .period-sprints
    background: var(--block-bg-color)
    border-radius: 6px

    &__popper
      box-sizing: border-box
      padding: 0
      border-color: var(--grey-light-color-40)
      background: var(--block-bg-color)
      border-radius: 6px

      &.el-popper[x-placement^=bottom]
        margin-top: 4px

      &.el-popper[x-placement^=bottom]
        margin-bottom: 4px

    &__reference
      padding: 12px
      border-radius: 6px
      background: var(--block-bg-color)
      border: 1px solid var(--block-border-bg)
      transition: all ease-in .2s
      cursor: pointer
      width: 480px
      display: flex
      align-items: center
      justify-content: space-between

      &:hover
        background: var(--grey-light-color-40)
        border-color: var(--grey-light-color-40)

      &-name

      &-dates
        color: var(--text-light-color)
        font-size: 13px

    &__list
      padding: 4px 0
      max-height: 300px
      overflow: auto

    &__item
      cursor: pointer
      display: flex
      align-items: center
      justify-content: space-between
      padding: 8px 12px
      transition: all ease-in .2s

      &:hover
        background: var(--light-grey-color-24)

      &_selected, &_selected:hover, &_second, &_second:hover
        color: #ffffff
        background: var(--primary-color)

        .period-sprints__item-dates
          color: #ffffff

      &_gap, &_gap:hover
        background: var(--primary-light-color)

      &-name
        font-weight: 500
        display: flex
        column-gap: 8px
        align-items: center

      &-dates
        color: var(--text-light-color)

    &__control
      display: flex
      justify-content: space-between
      align-items: center
      padding: 12px
      border-top: 1px solid var(--grey-light-color-40)
      column-gap: 20px

    &__selected
      display: flex
      align-items: center
      column-gap: 20px
      flex: 1

      &-item
        &-name
          font-size: 13px
          font-weight: 500

        &-date
          color: var(--text-light-color)


</style>

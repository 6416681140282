<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
            <path class="path-fill" d="M12.0231 6.72549L2.35618 0.0586617C2.25384 -0.0116733 2.12117 -0.0190068 2.01183 0.0379946C1.90216 0.0956626 1.8335 0.208999 1.8335 0.333002V13.6667C1.8335 13.7907 1.90216 13.9043 2.01183 13.962C2.0605 13.9873 2.11384 14 2.16684 14C2.23317 14 2.29917 13.98 2.35618 13.941L12.0231 7.27417C12.1134 7.21183 12.1671 7.1095 12.1671 6.99983C12.1671 6.89016 12.1131 6.78782 12.0231 6.72549Z" fill="#416AF7"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="14" height="14" fill="white"/>
            </clipPath>
        </defs>
    </svg>

</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'PlayIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 10,
        defaultHeight: 10
      }
    }
  }
</script>

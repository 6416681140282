export default {
  data () {
    return {
      showNumberTooltip: false,
    }
  },

  computed: {
    taskNumber () {
      return this.workspace.task_prefix
        ? `#${this.workspace.task_prefix}-${this.task.number}`
        : `#${this.task.number}`
    }
  },

  methods: {
    onCopyNumber () {
      const fakeEl = document.createElement('textarea')
      fakeEl.value = this.taskNumber
      fakeEl.setAttribute('readonly', '')
      fakeEl.style.position = 'absolute'
      fakeEl.style.left = '-9999px'
      document.body.appendChild(fakeEl)
      fakeEl.select()

      document.execCommand('copy')
      document.body.removeChild(fakeEl)

      this.showNumberTooltip = true

      setTimeout(() => {
        this.showNumberTooltip = false
      }, 1500)
    }
  }
}

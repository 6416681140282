<template>
<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="path-fill" fill-rule="evenodd" clip-rule="evenodd" d="M2.65 0.65C2.65 0.291015 2.35898 0 2 0C1.64101 0 1.35 0.291015 1.35 0.65V1.00039H1C0.447715 1.00039 0 1.44811 0 2.00039V11.0004C0 11.5527 0.447716 12.0004 1 12.0004H13C13.5523 12.0004 14 11.5527 14 11.0004V2.00039C14 1.44811 13.5523 1.00039 13 1.00039H12.65V0.650391C12.65 0.291406 12.359 0.000390649 12 0.000390649C11.641 0.000390649 11.35 0.291406 11.35 0.650391V1.00039H2.65V0.65ZM1.3 10.7004V5.15039H12.7V10.7004H1.3ZM12.7 3.85039H1.3V2.30039H12.7V3.85039Z" fill="#5B6372"/>
</svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'ScheduleIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#8B92AB',
        defaultWidth: 16,
        defaultHeight: 18
      }
    }
  }
</script>

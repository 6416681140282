import apiService from '@/services/api'
import { transformTaskItem } from '@/transformers/tasks';

export const namespaced = true

export const state = {
  filters: {
    sprint_id: [],
    sphere_id: null,
    target_id: [],
    assignee_id: []
  },
  isLoading: false,
  tasks: [],
  meta: {
    current_page: 1
  },

  sprints: [],
}

export const mutations = {
  SET_IS_LOADING (state, value) {
    state.isLoading = value
  },

  SET_TASKS (state, tasks) {
    state.tasks = tasks
  },

  SET_META (state, meta) {
    state.meta = meta
  },

  SET_FILTERS (state, filters) {
    state.filters = filters
  },

  SET_PAGE (state, page) {
    state.meta = {
      ...state.meta,
      current_page: page,
    }
  },

  SET_SPRINTS (state, list) {
    state.sprints = list
  }
}

export const actions = {
  async fetchFinishedTasks ({ commit, rootGetters, state }) {
    const { sprint_id, sphere_id, target_id, assignee_id } = state.filters
    const params = {
      page: state.meta.current_page,
    }

    if (sprint_id.length > 0) {
      params['sprints'] = sprint_id.join(',')
    }

    if (sphere_id) {
      params['spheres'] = sphere_id
    }

    if (target_id.length > 0) {
      params['targets'] = target_id.join(',')
    }

    if (assignee_id.length > 0) {
      params['assignees'] = assignee_id.join(',')
    }

    commit('SET_IS_LOADING', true)
    const { project } = rootGetters
    try {
      const { data: resData } = await apiService.get(`projects/${project.code}/tasks/finished`, params)
      const { data: tasks, meta } = resData

      const performFunc = transformTaskItem(rootGetters)
      commit('SET_TASKS', tasks.map(performFunc))
      commit('SET_META', meta)
    } finally {
      commit('SET_IS_LOADING', false)
    }
  },

  changeFilters ({ commit, dispatch }, filters) {
    commit('SET_FILTERS', filters)
    dispatch('fetchFinishedTasks')
  },

  changePage ({ commit, dispatch }, page) {
    commit('SET_PAGE', page)
    dispatch('fetchFinishedTasks')
  },

  async fetchSprints ({ commit, rootGetters }) {
    const { project } = rootGetters
    const { data } = await apiService.get(`projects/${project.code}/sprints/last`)
    commit('SET_SPRINTS', data)

    return data
  }
}

export const getters = {
  filters: ({ filters }) => filters,

  isLoading: ({ isLoading }) => isLoading,

  tasks: ({ tasks }) => tasks,

  meta: ({ meta }) => meta,

  sprints: ({ sprints }) => sprints,
}

<template>
  <div class="layout blur-area" ref="layout">
    <sidebar ref="sidebar"/>

    <div class="layout__content">
      <div class="task-page">
        <div class="task-page__tasks">
          <div class="task-wrapper__header">
            <div class="task-wrapper__content">
              <div class="tasks-header">
                <div class="tasks-header__left" :class="{'tour-highlighted': currentStep === 'spheres' }" ref="tasksSpheres">
                  <div class="tasks-header__title">
                    {{ $t('tasks.title') }}
                  </div>

                  <task-spheres :spheres="spheres" :value="taskFilter.sphere_id" popper-class="ui-dropdown_no-border"/>
                </div>

                <div class="tasks-header__right">
                  <div class="fx fx_end">
                    <ui-button ui-class="light">
                      <plus class="icon-round-plus" background="#638FFF"/> {{ $t('tasks.newTaskBtn') }}
                    </ui-button>
                  </div>
                </div>
              </div>

              <div class="tasks-header">
                <div class="tasks-header__left" :class="{'tour-highlighted': currentStep === 'members' }" ref="tasksMembers">
                  <div class="member-short-list">
                    <member-popover
                      v-for="member in activeMembers"
                      :key="member.member_id"
                      :member="member"
                      :mock-chat-action="true"
                    >
                      <member-short-item
                        :member="member"
                        :active="false"
                      />
                    </member-popover>
                  </div>

                  <el-tooltip class="item" effect="dark" :content="$t('settings.inviteMembers')" placement="top">
                    <ui-button class="add-member-btn" ui-class="light">
                      <plus class="add-member-btn__icon"/>
                    </ui-button>
                  </el-tooltip>
                </div>

                <div class="tasks-header__right">
                  <task-search/>
                </div>
              </div>

              <ui-tabs :tabs="stages" :value="currentStage.id" class="task-stages" :class="{'tour-highlighted': currentStep === 'stages' }" ref="tasksStages">
                <template v-slot:default="{ tab }">
                  {{ tab.name }} <span>{{ stageCounts[tab.id] }}</span>
                </template>
              </ui-tabs>
            </div>
          </div>

          <div class="task-wrapper__list scrollable" :class="{'tour-highlighted': ['tasks', 'tasksDate', 'tasksProgress'].indexOf(currentStep) !== -1 }" ref="tasksFlow">
            <div class="task-list">
              <transition-group name="zoom" tag="div" appear>
                <StageTaskItem
                  v-for="(task, index) in tasks"
                  :key="task.id"
                  :task="task"
                  :index="index"
                  :allow-create="false"
                  :draggable="false"
                  :editable="false"
                />
              </transition-group>
            </div>
          </div>
        </div>

        <div class="task-page__sidebar">
          <transition name="slide">
            <router-view :key="sidebarRouteKey"></router-view>
          </transition>

          <div class="task-page__sidebar-wrapper">
            <div class="task-page__sidebar-header">

              <ui-tabs :tabs="rightMenu" key-field="name" :value="selectedMenuItem" @input="onRightMenuChange" class="right-sidebar-menu">
                <template v-slot:default="{ tab }">
                  {{ tab.label }}
                </template>
              </ui-tabs>
            </div>

            <div class="task-page__sidebar-content">
              <targets :targets="targets" :targets-loaded="targetsLoaded" :is-tour="true" v-if="selectedMenuItem === 'targets'" ref="targets"/>
              <tour-backlog v-if="selectedMenuItem === 'backlog'" ref="backlog"/>
              <archive v-if="selectedMenuItem === 'archive'"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tour-step
      :active="isActive"
      :placement="currentStepData.placement"
      :reference="tourReference"
      :step="currentStepData"
      ref="tourStep"
      @next="onNextStep"
      @previous="onPreviousStep"
      @skip="onSkip"
      v-if="currentStep"
    />
    <tour-started v-if="currentStep === ''" @start="onStartTour" @skip="onSkip"/>
    <tour-completed :show="currentStep === 'complete'" @submit="onCompleteTour"/>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapActions, mapGetters } from "vuex"
import { DateTime } from 'luxon'

import pageTitleMixin from "@/views/mixins/pageTitleMixin"

import Archive from "@/components/tasks/Archive"
import UiTabs from "@/components/ui/UiTabs"
import MemberShortItem from "@/components/tasks/MemberShortItem"
import MemberPopover from "@/components/members/MemberPopover"
import Plus from '@/components/icons/Plus'
import UiButton from 'scorework-ui/src/components/UiButton'
import Sidebar from "@/components/layouts/components/Sidebar"
import StageTaskItem from "@/components/tasks/StageTaskItem"
import Targets from "@/components/targets/Targets"
import TaskSearch from "@/components/tasks/TaskSearch"
import TaskSpheres from "@/components/tasks/TaskSpheres"
import TourBacklog from "@/components/tour/TourBacklog"
import TourCompleted from "@/components/tour/TourCompleted"
import TourStarted from "@/components/tour/TourStarted"
import TourStep from "@/components/tour/TourStep"

const { mapActions: tasksActions, mapGetters: tasksGetters } = createNamespacedHelpers('tasks')
const { mapActions: tourActions, mapGetters: tourGetters } = createNamespacedHelpers('tour')

const SelectedMenuKey = 'tasks_menu_item'

export default {
  name: 'TaskListPage',

  components: {
    Archive, TourBacklog, StageTaskItem, TourCompleted, UiTabs, MemberPopover, MemberShortItem, TaskSpheres,
    Plus, Sidebar, UiButton, Targets, TaskSearch, TourStarted, TourStep
  },

  mixins: [pageTitleMixin],

  props: {
    stageId: {
      type: Number,
      default: 0
    },

    sphereId: {
      type: Number,
      default: 0
    },

    assigneeIds: {
      type: Array,
      default: () => []
    }
  },

  data () {
    const selectedMenuItem = localStorage.getItem(SelectedMenuKey)

    const activeTaskHandler = () => {
      const comp = this
      let mockEl = null

      return {
        onDisplay (refEl) {
          mockEl = refEl.cloneNode(true)
          mockEl.classList.add('tour-highlighted')

          const rect = refEl.getBoundingClientRect()
          mockEl.style.width  = rect.width + 'px'
          mockEl.style.height = rect.height + 'px'
          mockEl.style.left   = rect.left + 'px'
          mockEl.style.top    = rect.top + 'px'
          comp.$refs.layout.append(mockEl)
        },

        onHide () {
          comp.$refs.layout.removeChild(mockEl)
        }
      }
    }

    const targetsHandler = () => {
      const comp = this

      return {
        onDisplay (refEl) {
          refEl.classList.add('tour-highlighted')
          comp.$refs.layout.querySelector('.right-sidebar-menu .ui-tab_active').classList.add('tour-highlighted')
        },

        onHide (refEl) {
          refEl.classList.remove('tour-highlighted')
          comp.$refs.layout.querySelector('.right-sidebar-menu .ui-tab_active').classList.remove('tour-highlighted')
        }
      }
    }

    const backlogHandler = () => {
      const comp = this
      return {
        onDisplay (refEl) {
          comp.selectedMenuItem = 'backlog'
          refEl.classList.add('tour-highlighted')
          comp.$refs.layout.querySelector('.right-sidebar-menu .ui-tab_active').classList.add('tour-highlighted')
        },

        onHide (refEl) {
          refEl.classList.remove('tour-highlighted')
          comp.$refs.layout.querySelector('.right-sidebar-menu .ui-tab_active').classList.remove('tour-highlighted')
        }
      }
    }

    return {
      rightMenu: [
        { label: this.$t('goals.title'), name: 'targets' },
        { label: this.$t('tasks.backlog'), name: 'backlog' },
        { label: this.$t('tasks.archive'), name: 'archive' }
      ],

      selectedMenuItem: selectedMenuItem || 'targets',
      isLoading: false,
      targetsLoaded: true,
      modalTargetId: 0,
      isActive: false,
      tourReference: {},
      tasks: [],
      targets: [],
      stepActions: {
        spheres: {
          onDisplay (refEl) {
            const dropdown = refEl.querySelector('.ui-dropdown__reference')
            dropdown.addEventListener('transitionend', function transitionHandler() {
              dropdown.removeEventListener('transitionend', transitionHandler)
              dropdown.click()
            })
            setTimeout(() => dropdown.classList.add('ui-dropdown__reference_hover'), 500)
          },

          onHide (refEl) {
            const dropdown = refEl.querySelector('.ui-dropdown__reference')
            dropdown.click()
          }
        },

        tasksDate: {
          onDisplay (refEl) {
            const dateLabels = refEl.querySelectorAll('.task-item__estimated-date')

            dateLabels.forEach(dateLabel => {
              dateLabel.classList.add('tour-sub-highlighted')
            })
          },

          onHide (refEl) {
            const dateLabels = refEl.querySelectorAll('.task-item__estimated-date')

            dateLabels.forEach(dateLabel => {
              dateLabel.classList.remove('tour-sub-highlighted')
            })
          }
        },

        tasksProgress: {
          onDisplay (refEl) {
            const progressBars = refEl.querySelectorAll('.task-item__progress-bar')

            progressBars.forEach(dateLabel => {
              dateLabel.classList.add('tour-sub-highlighted')
            })
          },

          onHide (refEl) {
            const progressBars = refEl.querySelectorAll('.task-item__progress-bar')

            progressBars.forEach(dateLabel => {
              dateLabel.classList.remove('tour-sub-highlighted')
            })
          }
        },

        activeTask: activeTaskHandler(),

        targets: targetsHandler(),

        backlog: backlogHandler()
      }
    }
  },

  computed: {
    ...mapGetters([
      'authUser',
      'spheres',
      'stages',
      'workspace',
      'project',
    ]),
    ...tasksGetters(['stageTasks', 'currentStage', 'taskFilter']),
    ...tourGetters(['stepList', 'currentStep', 'currentStepData']),

    activeMembers () {
      const members = [this.authUser]
      members.push({
        email: 'rick.sanchez@sw.io',
        first_name: 'Rick',
        last_name: 'Sanchez',
        member_id: 7,
        role: 'member',
        status: 'active',
        work_schedule: [
          { days: [1, 2, 3, 4, 5], work_hours: { start: '09:00', end: '17:00'}}
        ]
      })

      return members
    },

    stageCounts () {
      return this.stages.reduce((res, stage, idx) => {
        res[stage.id] = idx ? 0 : this.tasks.length

        return res
      }, {})
    },

    pageTitle () {
      return {
        message: 'pageTitles.tasks', params: { workspace: this.workspace.name }
      }
    },

    sidebarRouteKey () {
      if (Object.prototype.hasOwnProperty.call(this.$route.params, 'taskId')) {
        return `tasks:${this.$route.params.taskId}`
      }

      if (Object.prototype.hasOwnProperty.call(this.$route.params, 'targetId')) {
        return `targets:${this.$route.params.targetId}`
      }

      return ''
    }
  },

  methods: {
    ...mapActions(['setIsTour', 'setActiveTask']),
    ...tasksActions(['changeTaskFilter']),
    ...tourActions(['setCurrentStep', 'startTour', 'skipTour']),

    onRightMenuChange (name) {
      this.selectedMenuItem = name
      localStorage.setItem(SelectedMenuKey, name)
    },

    onNextStep () {
      const stepName  = this.currentStep
      const idx       = this.stepList.indexOf(stepName)

      const newStepName = idx >= this.stepList.length - 1
        ? 'complete'
        : this.stepList[idx + 1]

      this.isActive = false
      this.displayTourStep(newStepName, stepName)
    },

    onPreviousStep () {
      const stepName  = this.currentStep
      const idx       = this.stepList.indexOf(stepName)

      if (idx < 1) {
        return
      }

      const newStepName = this.stepList[idx - 1]
      this.isActive = false
      this.displayTourStep(newStepName, stepName)
    },

    displayTourStep (step, oldStep) {
      setTimeout(() => {
        const oldStepAction = this.stepActions[oldStep]
        const stepAction = this.stepActions[step]

        if (oldStepAction) {
          oldStepAction.onHide(this.tourReference)
        }

        this.setCurrentStep(step)
        this.updateTourReference()

        if (stepAction) {
          stepAction.onDisplay(this.tourReference)
        }

        this.isActive = true
      }, 450)

      if (step === 'targets') {
        this.selectedMenuItem = 'targets'
      }

      if (step === 'backlog') {
        this.selectedMenuItem = 'backlog'
      }
    },

    updateTourReference () {
      if (!this.currentStepData.el) {
        return
      }
      const stepEl = this.currentStepData.el

      const ref = this.$refs[stepEl.ref]
      const refEl = typeof ref === 'object' && ref.$el
          ? ref.$el
          : ref

      this.tourReference = stepEl.selector
        ? refEl.querySelector(stepEl.selector)
        : refEl
    },

    onStartTour () {
      this.startTour()
      this.displayTourStep(this.currentStep, '')
    },

    onSkip () {
      this.skipTour({ step: this.currentStep })
        .then(() => {
          this.goToTasks()
        })
    },

    onCompleteTour () {
      this.goToTasks()
    },

    goToTasks () {
      this.$router.push({ name: 'project.tasks', params: { project: this.project.code } })
    }
  },

  created () {
    if (this.authUser.tour_status) {
      this.goToTasks()
      return
    }

    const filter = {
      stage_id: this.stages[0].id
    }

    if (this.stageId) {
      filter.stage_id = this.stageId
    }

    if (this.sphereId) {
      filter.sphere_id = this.sphereId
    }

    if (this.assigneeIds.length > 0) {
      filter.assignee_ids = this.assigneeIds
    }

    this.changeTaskFilter({ filter, updateTasks: false })

    this.tasks.push({
      id: 1,
      name: this.$t('tour.fakeTasks.flowName1'),
      number: 1,
      status: 'in_progress',
      score: 240,
      state: {
        id: 1,
        assignee_id: this.authUser.member_id,
        assignee: this.authUser,
        score_status: 'estimated',
        estimatedFinishAt: DateTime.local().plus({ minutes: 60 }),
        processing: {
          percent: 75,
          processed_minutes: 180,
          state: 'in_progress'
        },
        progresses: []
      }
    }, {
      id: 2,
      name: this.$t('tour.fakeTasks.flowName2'),
      number: 4,
      status: 'in_progress',
      score: 60,
      state: {
        assignee_id: this.activeMembers[1].member_id,
        assignee: this.activeMembers[1],
        score_status: 'estimated',
        estimatedFinishAt: DateTime.local().plus({ hours: -1 }),
        processing: {
          percent: 100,
          processed_minutes: 100,
          state: 'overdue'
        }
      }
    }, {
      id: 3,
      name: this.$t('tour.fakeTasks.flowName3'),
      number: 7,
      status: 'open',
      score: 150,
      startedAt: DateTime.local().minus({ hours: 24 }),
      state: {
        assignee_id: this.authUser.member_id,
        assignee: this.authUser,
        score_status: 'estimated',
        beProcessedAt: DateTime.local().plus({ minutes: 75 })
      }
    }, {
      id: 4,
      name: this.$t('tour.fakeTasks.flowName4'),
      number: 5,
      status: 'open',
      score: 30,
      state: {
        assignee_id: this.authUser.member_id,
        assignee: this.authUser,
        score_status: 'estimated',
        beProcessedAt: DateTime.local().plus({ minutes: 250 })
      }
    })

    this.targets.push({
      id: 1,
      name: this.$t('tour.fakeTargets.name1'),
      color: 'aquamarine',
      status: 'active',
      total_tasks: 4,
      finished_tasks: 0,
      members: [this.authUser],
      estimatedFinishAt: DateTime.local().plus({ minutes: 125 })
    })

    const { state, ...rest } = this.tasks[0]
    this.setActiveTask({
      task: {
        ...rest,
        states: [state],
        state_id: 1,
        transitions: []
      }
    })

    this.setIsTour(true)
  },

  mounted () {
    if (this.currentStep !== '') {
      setTimeout(() => {
        this.updateTourReference()
        this.displayTourStep(this.currentStep, '')
      }, 1000)
    }
  },

  beforeDestroy () {
    this.setActiveTask({ task: {} })
    this.setIsTour(false)
  }
}
</script>
<style lang="sass">
.task-item.tour-highlighted
  background: var(--bg-color)

.task-item__estimated-date.tour-sub-highlighted
  border-radius: 6px
  background: var(--primary-light-color)
  box-shadow: 0 0 0px 2px var(--primary-color)
  padding: 4px 8px

.task-item__progress-bar.tour-sub-highlighted
  height: 8px
  border-radius: 4px

  .task-item__progress-bar-percent
    height: 8px
    border-radius: 4px

.sb-active-task.tour-highlighted
  position: absolute
  z-index: 2000

.targets.tour-highlighted, .backlog-list.tour-highlighted
  transform: translate(0)

.right-sidebar-menu .ui-tab.tour-highlighted
  transform: translateX(-8px)
  padding: 8px

  &::after
    display: none
</style>

<template>
  <task-item
    class="sprint-popover-task"
    :index="0"
    :task="task"
    :display-stage="false"
  />
</template>
<script>
import TaskItem from "@/components/tasks/TaskItem"

export default {
  name: 'SprintTaskPopover',

  components: { TaskItem },

  props: {
    task: {
      type: Object,
      required: true
    },

    show: {
      type: Boolean,
      required: true
    }
  }
}
</script>
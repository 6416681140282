<template>
  <div class="task-assignee">
    <user-photo :user="user" size="sm" class="task-assignee__photo"/>
    <div v-html="assigneeName" class="task-assignee__title" />
  </div>
</template>
<script>
  import UserPhoto from "../../ui/UserPhoto"

  export default {
    name: 'TaskAssignee',

    components: { UserPhoto },

    props: {
      user: {
        type: Object,
        required: true
      },

      filterText: {
        type: String,
        default: '',
      },
    },

    computed: {
      assigneeName () {
        if (!this.user.member_id) {
          return this.$t('tasks.notSelected')
        }
        const fullName = `${this.user.first_name} ${this.user.last_name}`;

        if (!this.filterText) {
          return fullName
        }

        return fullName.replace(new RegExp(`(${this.filterText})`, 'gi'), `<b>$1</b>`)
      }
    },
  }
</script>
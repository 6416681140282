<template>
  <div class="sidebar" :class="{ 'sidebar_expanded': isExpanded }">
    <div class="sidebar__content">
<!--      <el-popover-->
<!--          placement="right-start"-->
<!--          trigger="hover"-->
<!--          :visible-arrow="false"-->
<!--          popper-class="plan-popover"-->
<!--          transition="sw-slide-right"-->
<!--      >-->
<!--        <div>-->
<!--          <div class="plan-popover__header">-->
<!--            Project-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="sidebar__logo" slot="reference">-->
<!--          <div class="sidebar__logo-icon">-->
<!--            <logo-icon/>-->
<!--          </div>-->
<!--          <div class="sidebar__logo-title">-->
<!--            <logo-title-icon/>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-popover>-->
      <workspace-menu/>

      <rate-plan-badge/>

      <sidebar-menu class="sidebar__menu" :current-route="currentRoute" @change="onRouteChange"/>

      <sidebar-chats/>

      <active-task v-if="activeTask.id"/>
    </div>

    <div class="sidebar__bottom">
      <div class="sidebar-menu sidebar__menu sidebar__menu_no-border">
        <notification-count @show-notifications="onOpenNotifications"/>
        <div class="mb-2">
          <theme-switcher class="sidebar__mode-switcher"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationCount from "@/components/layouts/components/NotificationCount"
import SidebarMenu from './SidebarMenu'
import SidebarChats from "@/components/layouts/components/SidebarChats"
import ActiveTask from "@/components/layouts/components/ActiveTask"
import WorkspaceMenu from "@/components/layouts/components/WorkspaceMenu"
import ThemeSwitcher from "@/components/layouts/components/ThemeSwitcher"
import RatePlanBadge from "@/components/layouts/components/RatePlanBadge"
import {mapGetters} from "vuex";

export default {
  name: 'Sidebar',

  components: { ActiveTask, NotificationCount, RatePlanBadge, SidebarMenu, WorkspaceMenu, SidebarChats, ThemeSwitcher },

  data () {
    return {
      isExpanded: false,
      currentRoute: this.$router.currentRoute.name
    }
  },

  computed: {
    ...mapGetters(['activeTask']),
  },

  methods: {
    onRouteChange () {
      this.currentRoute = this.$router.currentRoute.name
    },

    onOpenNotifications () {
      this.$emit('show-notifications')
    }
  }
}
</script>

<template>
  <div class="dashboard">
    <div class="dashboard__header">
      <div class="dashboard__title">
        {{ workspace.name }}
      </div>

      <div>
        <ui-button ui-class="size_md" @click="onCreateTask">
          {{ $t('tasks.createTask') }}
        </ui-button>
      </div>
    </div>

    <div class="dashboard__grid dashboard-grid">
      <div class="dashboard-grid__first">
        <div class="dashboard-grid__item-title">
          {{ $t('dashboard.titles.activeTask') }}
        </div>

        <el-skeleton :loading="isLoadingTasks" animated>
          <template slot="template">
            <skeleton-active-task/>
            <div class="dashboard-grid__item-cell">
              <div class="dashboard-grid__item-title">
                {{ $t('dashboard.titles.upcomingTasks') }}
              </div>

              <skeleton-board-task-item v-for="i in 3" :key="i"/>
            </div>
          </template>
          <template>
            <template v-if="activeTask">
              <active-task
                  :task="activeTask"
                  :index="0"
                  @open="({ id }) => onOpenTask(id)"
              />

              <div class="dashboard-grid__item-cell">
                <div class="dashboard-grid__item-title">
                  {{ $t('dashboard.titles.upcomingTasks') }}
                </div>

                <div v-if="upcomingTasks.length > 0">
                  <div class="db-tasks-panel fx fx_space-between">
                    <div class="db-tasks-panel__item">
                      {{ $tc('workload.tasksCount', myTasks.length - 1, { tasks: myTasks.length - 1 }) }}
                    </div>

                    <div class="db-tasks-panel__item">
                      <el-tooltip
                        effect="dark"
                        :content="$t('workload.tasksUntil')"
                        placement="top"
                        transition="sw-slide-top"
                      >
                        <div class="workload-member__metrics-value">
                          <clock-icon class="workload-member__metrics-icon"/>
                          {{ estimatedFinishAt }}
                        </div>
                      </el-tooltip>
                    </div>
                  </div>

                  <board-task-item
                      v-for="(item, index) in upcomingTasks"
                      :key="item.id"
                      :task="item"
                      :index="index"
                      :with-fields="['points', 'sphere']"
                      :with-add-button="false"
                      @open="({ id }) => onOpenTask(id)"
                      class="db-task-item"
                  />

                  <div class="fx fx_center" v-if="myTasks.length > 4">
                    <ui-button ui-class="light size_md" @click="showAllTasks = !showAllTasks">
                      {{ $t(showAllTasks ? 'dashboard.tasks.hideOthers' : 'dashboard.tasks.showOthers') }}
                    </ui-button>
                  </div>

                </div>
                <div class="ui-block" v-else>
                  <div class="dashboard-no-tasks">
                    {{ $t('dashboard.tasks.noUpcomingTasks') }}

                    <ui-button ui-class="light size_md" class="mt-4" @click="() => onCreateTask({ memberId: authUser.member_id })">
                      {{ $t('tasks.createTask') }}
                    </ui-button>
                  </div>
                </div>
              </div>
            </template>

            <div class="db-active-task" v-else>
              <div class="db-active-task__main">
                <div class="dashboard-no-tasks">
                  {{ $t('dashboard.tasks.emptyTasks') }}

                  <ui-button ui-class="light size_md" class="mt-4" @click="() => onCreateTask({ memberId: authUser.member_id })">
                    {{ $t('tasks.createTask') }}
                  </ui-button>
                </div>
              </div>
            </div>
          </template>
        </el-skeleton>
      </div>

      <div class="dashboard-grid__sprint">
        <div class="dashboard-grid__item-title">
          {{ $t('dashboard.titles.sprint') }}
        </div>
        <active-sprint key="1"/>

        <div class="dashboard-grid__item-cell">
          <div class="dashboard-grid__item-title">
            {{ $t('dashboard.titles.feed') }}
          </div>

          <Feed @open-task="onOpenTask"/>
        </div>
      </div>

      <div class="dashboard-grid__third">
        <div class="dashboard-grid__item-title">
          {{ $t('dashboard.titles.team') }}
        </div>

        <team/>
      </div>

      <modal-task-card
          v-if="modalTask.id !== null"
          :task-id="modalTask.id"
          :member-id="modalTask.memberId"
          :stage-id="modalTask.stageId"
          :after-task-id="modalTask.afterTaskId"
          :sphere-id="modalTask.sphereId"
          :target-id="modalTask.targetId"
          @close="onCloseTask"
      />
    </div>
  </div>
</template>
<script>
  import { createNamespacedHelpers, mapGetters } from 'vuex'
  import ActiveTask from '@/components/dashboard/ActiveTask.vue'
  import ActiveSprint from '@/components/dashboard/ActiveSprint.vue'
  import BoardTaskItem from '@/components/tasks/BoardTaskItem.vue'
  import ClockIcon from "@/components/icons/ClockIcon.vue"
  import Feed from '@/components/dashboard/Feed.vue'
  import SkeletonActiveTask from '@/components/dashboard/SkeletonActiveTask.vue'
  import SkeletonBoardTaskItem from '@/components/tasks/SkeletonBoardTaskItem.vue'
  import Team from '@/components/dashboard/Team.vue'
  import socketService from '@/services/socket'
  import dashboardSocket from '@/store/socket/dashboard'
  import withModalTask from '@/components/tasks/mixins/withModalTask';
  import UiButton from 'scorework-ui/src/components/UiButton'
  import { lcFirst } from "@/utils/dataTypes";
  import { getEstimatedTime } from "@/services/scoring";

  const { mapActions: dashboardActions, mapGetters: dashboardGetters } = createNamespacedHelpers('dashboard')
  let dashboardSocketHandler = null

  export default {
    name: 'MemberDashboard',

    components: { ActiveTask, ActiveSprint, BoardTaskItem, ClockIcon, Feed, SkeletonActiveTask, SkeletonBoardTaskItem, Team, UiButton },

    mixins: [withModalTask],

    data () {
      return {
        isLoadingTasks: false,
        showAllTasks: false
      }
    },

    computed: {
      ...mapGetters(['workspace', 'baseStage', 'authUser']),
      ...dashboardGetters(['myTasks']),

      activeTask () {
        return this.myTasks.length > 0
          ? this.myTasks[0]
          : null
      },

      upcomingTasks () {
        return this.showAllTasks
          ? this.myTasks.slice(1)
          : this.myTasks.slice(1, 4)
      },

      estimatedFinishAt () {
        const lastTask = this.myTasks[this.myTasks.length - 1]
        return lcFirst(getEstimatedTime(lastTask.estimatedFinishAt))
      }
    },

    methods: {
      ...dashboardActions(['fetchMyTasks']),

      onCreateTask ({ memberId = null }) {
        this.modalTask = {
          ...this.modalTask,
          id: 0,
          stageId: this.baseStage.id,
          afterTaskId: null,
          memberId,
        }

        this.changeRoutePath()
      }
    },

    async created () {
      dashboardSocketHandler = dashboardSocket(this.$store, this.$router, () => null)
      dashboardSocketHandler.subscribe(socketService.socket)

      this.isLoadingTasks = true
      try {
        await this.fetchMyTasks()
      } finally  {
        this.isLoadingTasks = false
      }
    }
  }
</script>
<style lang="sass">
  .dashboard
    display: flex
    flex-flow: column
    height: 100%
    width: 100%

    &__header
      height: 64px
      background: var(--block-bg-color)
      box-shadow: var(--block-shadow)
      position: relative
      z-index: 100
      display: flex
      align-items: center
      justify-content: space-between
      box-sizing: border-box
      padding: 0 24px

    &__title
      font-size: 22px
      font-weight: 500

    &__grid
      flex: 1
      overflow: auto

  .dashboard-grid
    padding: 24px
    display: grid
    grid-template-columns: repeat(12, 1fr)
    align-content: start
    justify-items: stretch
    column-gap: 24px
    row-gap: 24px
    width: 100%
    box-sizing: border-box

    &__item
      width: 450px

      &-title
        font-size: 18px
        font-weight: 600
        margin-bottom: 12px
        padding: 0 16px

      &-cell
        margin-top: 24px

    &__first
      grid-column: 1 / 5

    &__sprint
      grid-column: 5 / 9

    &__upcoming-tasks
      grid-column: 1 / 5
      grid-row-start: 2
      align-self: start

    &__feed
      grid-row-start: 2
      grid-column: 5 / 9

    &__third
      grid-column: 9 / 13

  .dashboard-no-tasks
    color: var(--text-light-color)
    display: flex
    flex-flow: column
    align-items: center
    padding: 16px 0

  .db-no-upcoming-tasks
    color: var(--text-light-color)
    padding: 0 16px

  .db-tasks-panel
    padding: 0 16px
    margin-bottom: 12px

    &__item
      font-size: 12px
      color: var(--text-light-color)
</style>

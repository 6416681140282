<template>
  <div class="ui-tabs" :class="tabsClass">
    <div
      v-for="tab in tabs"
      :key="tab[keyField]"
      class="ui-tab"
      :class="{ 'ui-tab_active': value === tab[keyField] }"
      @click="onSelect(tab)"
    >
      <slot :tab="tab">{{ tab[titleField] }}</slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'UiTabs',

    props: {
      value: {
        required: true
      },

      tabs: {
        type: Array
      },

      keyField: {
        type: String,
        default: 'id'
      },

      titleField: {
        type: String,
        default: 'name'
      },

      variant: {
        type: String,
        default: ''
      }
    },

    computed: {
      tabsClass () {
        return this.variant ? `ui-tabs_${this.variant}` : ''
      }
    },

    methods: {
      onSelect (tab) {
        this.$emit('input', tab[this.keyField])
      }
    }
  }
</script>
<style lang="sass">
.ui-tabs
  display: flex
  flex-flow: row nowrap

.ui-tab
  position: relative
  padding: 12px 12px
  color: var(--text-light-color)
  cursor: pointer

  &:focus
    outline: none

  &:after
    content: ""
    background: var(--primary-color)
    height: 2px
    position: absolute
    bottom: 0
    transition: .16s all 0.025s

    left: 100%
    right: 0

  &_active
    color: var(--text-primary-color)

    &:after
      left: 0
      right: 0

    & ~ .ui-tab::after
      left: 0
      right: 100%

.ui-tabs_medium
  column-gap: 24px

  .ui-tab
    font-size: 13px
    text-transform: uppercase
    font-weight: 600
    padding: 12px 0 6px
    color: var(--text-light-color)
    transition: .16s color 0.025s

    &:after
      background: var(--text-primary-color)
      left: 50%
      width: 0

    &_active
      color: var(--text-primary-color)

      &:after
        left: 0
        width: 100%

      & ~ .ui-tab::after
        left: 50%
        width: 0

    &:hover
      color: var(--text-primary-color)
</style>

<template>
<div :class="itemClasses" v-if="displayStatus">
  <pause-icon v-if="status === 'paused'" class="task-status__icon"/>
  {{ $t('tasks.statuses.' + status) }}
</div>
</template>
<script>
import PauseIcon from "@/components/icons/PauseIcon"
export default {
  name: 'TaskStatus',

  components: { PauseIcon },

  props: {
    task: {
      type: Object,
      required: true
    },

    acceptedStatuses: {
      type: Array,
      default: () => ['open', 'paused', 'in_progress', 'finished']
    }
  },

  computed: {
    itemClasses () {
      const classes = ['task-status']
      classes.push(`task-status_${this.status}`)

      return classes
    },

    status () {
      if (this.task.status === 'open' && this.task.startedAt) {
        return 'paused'
      }

      return this.task.status
    },

    displayStatus () {
      return this.task.id && this.acceptedStatuses.indexOf(this.status) !== -1
    }
  }
}
</script>
<style lang="sass">
.task-status
  align-items: center
  border-radius: 6px
  box-sizing: border-box
  display: flex
  font-size: 12px
  font-weight: 500
  height: 32px
  padding: 0px 12px
  width: auto

  &_small
    border-radius: 4px
    height: 22px
    padding: 0 6px

  &::before
    border-radius: 3px
    content: ""
    height: 6px
    margin-right: 8px
    width: 6px

  &_in_progress
    background: var(--color-green-bg)
    color: var(--color-green-text)

    &::before
      background: var(--color-green-text)

  &_open
    background: var(--color-yellow-hover)
    color: var(--color-yellow-text)

    &::before
      background: var(--color-yellow-text)

  &_finished
    background: var(--color-red-hover)
    color: var(--color-red-text)

    &::before
      background: var(--color-red-text)

  &_paused
    background: var(--color-grey-hover)
    color: var(--color-grey-text)

    &::before
      display: none

    .task-status__icon
      height: auto
      margin-right: 6px
      width: 11px

      .path-fill
        fill: var(--color-grey-text)
</style>

import { Plugin, PluginKey } from '@tiptap/pm/state'
import { FloatingMenuView } from '@/components/ui/textEditor/floatingMenu/floatingMenuView'

export const floatingMenuPluginKey = new PluginKey('floatingMenu')

export const FloatingMenuPlugin = (options) => {
  const renderer = FloatingMenuView({ ...options })

  return new Plugin({
    key: floatingMenuPluginKey,
    view: () => renderer,
    state: {
      init() {
        return {
          forceShow: false,
        }
      },

      apply(tr, state) {

        //console.log('tr', tr, { from: tr.curSelection.from, to: tr.curSelection.to, }, { from: tr.selection.from, to: tr.selection.to, })
        if (tr.docChanged && tr.curSelectionFor) {
          const { parent } = tr.curSelection.$from
          if (parent.textContent !== '' && parent.textContent !== '/') {
            return {
              ...state,
              forceShow: false,
            }
          }
        }

        const pluginState = tr.getMeta(floatingMenuPluginKey)

        if (pluginState) {
          return pluginState
        }

        if (tr.meta.blur) {
          const { event: blurEvent } = tr.meta.blur

          if (renderer.tippy().popper.contains(blurEvent.relatedTarget)) {
            return state
          }
        }

        if (state.forceShow && !tr.isGeneric && tr.selectionSet) {
          return {
            ...state,
            forceShow: false,
          }
        }

        return state
      },
    },
    props: {
      handleKeyDown(view, event) {
        return renderer?.onKeyDown?.({ view, event }) || false
      },
    }
  })
}

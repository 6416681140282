<template>
<div class="sprint-detail">
  <div class="sprint-overall">
    <div class="sprint-overall__main">
      <div class="sprint-overall__title">
        {{ sprint.data.name }}
      </div>
      <div class="sprint-overall__dates">
        {{ sprintDateLabel }}
      </div>
    </div>

    <div class="sprint-overall__counters">
      <div class="sprint-overall__counter">
        <div class="sprint-overall__percent-circle">
          <circle-progress
            :diameter="64"
            :stroke-width="4"
            :inner-stroke-width="4"
            :completed-steps="sprint.overall.tasks_finished_count"
            :total-steps="sprint.overall.tasks_planned_count"
          >
            <div class="sprint-overall__percent">
              {{ completePercent }}%
            </div>
          </circle-progress>
        </div>

        <div class="sprint-counter">
          <div class="sprint-counter__label">
            Прогресс спринта
          </div>
          <div class="sprint-counter__value">
            {{ sprint.overall.tasks_finished_count }} {{ $t('goals.outOf') }} {{ sprint.overall.tasks_planned_count }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sprint-detail__content">
    <div class="sprint-detail__graph">
      <sprint-graph :sprint="sprint"/>
    </div>

    <div class="sprint-detail__members">
      <div class="sprint-detail__member-list">
        <div
          class="sprint-detail__member-item"
          v-for="sprintMember in sprint.members"
          :key="sprintMember.member.member_id"
        >
          <sprint-member
              :is-current-sprint="true"
              :sprint-member="sprintMember"
          />
        </div>
      </div>

    </div>
  </div>
</div>
</template>
<script>
import CircleProgress from "@/components/ui/CircleProgress"
import SprintGraph from "@/components/sprints/SprintGraph"
import SprintMember from "@/components/sprints/SprintMember"
import { transformDateToObject } from "@/services/scoring"

export default {
  name: 'SprintDetail',

  components: { CircleProgress, SprintGraph, SprintMember },

  props: {
    sprint: {
      type: Object,
      required: true
    }
  },

  computed: {
    sprintDateLabel () {
      const startedAt = transformDateToObject(this.sprint.data.started_at)
      const finishedAt = transformDateToObject(this.sprint.data.finished_at)

      return `${startedAt.toFormat('dd LLLL')} - ${finishedAt.toFormat('dd LLLL')}`
    },

    completePercent () {
      return Math.round(this.sprint.overall.tasks_finished_count / this.sprint.overall.tasks_planned_count * 100)
    }
  },

  methods: {
    isActiveMember () {
      return true
    },

    onClickMember () {

    }
  }
}
</script>
<style lang="sass">
.sprint-detail
  &__header
    background: var(--block-bg-color)
    border-radius: 16px
    display: flex
    padding: 24px

  &__content
    display: flex
    padding-top: 24px

  &__graph
    background: var(--block-bg-color)
    border-radius: 16px
    box-sizing: border-box
    flex: 0 50%
    padding: 24px 32px

  &__members
    box-sizing: border-box
    flex: 0 50%
    padding-left: 32px

  &__member-list
    display: flex
    flex-flow: row wrap
    margin: -8px -8px 0

  &__member-item
    box-sizing: border-box
    flex: 0 50%
    padding: 8px

.sprint-overall
  background: var(--block-bg-color)
  border-radius: 16px
  display: flex
  padding: 24px

  &__main
    border-right: 1px solid var(--grey-light-color-60)
    box-sizing: border-box
    flex: 1
    padding-right: 32px

  &__title
    color: var(--text-primary-color)
    font-size: 20px
    font-weight: 500

  &__dates
    color: var(--text-light-color)
    font-size: 14px
    padding-top: 8px

  &__counters
    align-items: center
    box-sizing: border-box
    display: flex
    flex: 1
    padding-left: 32px

  &__counter
    align-items: center
    display: flex
    flex: 1

  &__percent-circle
    margin-right: 16px

  &__percent
    color: var(--primary-color)
    font-weight: 600

.sprint-counter
  &__label
    color: var(--text-light-color)
    font-size: 14px

  &__value
    color: var(--primary-color)
    font-size: 16px
    font-weight: 600
    padding-top: 8px
</style>
export function sortByField(field, direction = 'asc') {
  const sortDirection = direction === 'asc'
    ? -1
    : 1

  return (a, b) => {
    const aVal = getObjectFieldValue(a, field)
    const bVal = getObjectFieldValue(b, field)

    if (aVal < bVal) {
      return sortDirection
    }

    if (bVal < aVal) {
      return sortDirection * -1
    }

    return 0
  }
}

export function sortByMultipleFields(...sortFuncs) {
  return (a, b) => {
    for (let i = 0; i < sortFuncs.length; i++) {
      const res = sortFuncs[i](a, b)

      if (res !== 0) {
        return res
      }
    }

    return 0
  }
}

export function sortNullables(field, direction = 'asc') {
  const sortDirection = direction === 'asc'
    ? -1
    : 1

  return (a, b) => {
    const aVal = getObjectFieldValue(a, field) === null ? 0 : 1
    const bVal = getObjectFieldValue(b, field) === null ? 0 : 1

    if (aVal < bVal) {
      return sortDirection
    }

    if (bVal < aVal) {
      return sortDirection * -1
    }

    return 0
  }
}

function getObjectFieldValue(obj, field) {
  let res = obj
  field.split('.').forEach(fieldName => {
    res = res[fieldName]
  })

  return res
}
<template>
<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" class="path-fill" d="M8.79289 1H7V0H9.5H10V0.5V1V1.20711L9.85355 1.35355L8.20711 3H10V4H7.5H7V3.5V3V2.79289L7.14645 2.64645L8.79289 1ZM4.75 4H4V3H5.5H6V3.5V3.83334V4L5.9 4.13334L5.25 5H6V6H4.5H4V5.5V5.16667V5L4.1 4.86667L4.75 4ZM7.71536 8.65898C7.98976 8.65898 8.25902 8.63702 8.5215 8.59475C7.61682 9.46582 6.38688 10.0014 5.03184 10.0014C2.25283 10.0014 0 7.7486 0 4.96959C0 2.46498 1.82991 0.387762 4.2257 0.00197564C3.27501 0.91735 2.68352 2.20317 2.68352 3.62714C2.68352 6.40615 4.93635 8.65898 7.71536 8.65898Z" fill="white"/>
</svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'SleepIcon',

  mixins: [iconMixin],

  props: {
    viewBox: {
      type: String,
      default: '0 0 12 12'
    }
  },

  data () {
    return {
      defaultBackground: '#416AF7',
      defaultWidth: 12,
      defaultHeight: 12
    }
  }
}
</script>

import { Extension } from '@tiptap/core'
import { FloatingMenuPlugin } from '@/components/ui/textEditor/floatingMenu/plugin'

const FloatingMenuExtension = Extension.create({
  addKeyboardShortcuts() {
    return {

    }
  },

  addProseMirrorPlugins() {
    return [
      FloatingMenuPlugin({
        editor: this.editor,
        tippyOptions: { duration: 100, placement: 'bottom-start' },
      }),
    ]
  },
})

export default FloatingMenuExtension

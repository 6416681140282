<template>
  <el-dialog
    :title="$t('tasks.moveTaskToTheNextStage')"
    :visible="true"
    width="45%"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="onClose"
  >
    <div>
      <div class="common-desc mb-4">
        {{ $t('tasks.youCanSelectNextStageAndAssigneeForTask') }}
      </div>

      <div class="sw-task-card__params">
        <ui-dropdown
            :options="stages"
            :value="form.stage"
            reference-class="common-dropdown blue"
            @input="onStageChange"
            popover-class="ui-dropdown-ex"
            placement="bottom-start"
            :width-by-popover="false"
        >
          <div class="common-dropdown__title">
            {{ form.stage.name }}
          </div>

          <template v-slot:option="{ option }">
            {{ option.name }}
          </template>
        </ui-dropdown>

        <ui-dropdown
            :options="assigneesList"
            :value="form.assignee"
            key-field="member_id"
            @input="onAssigneeChange"
            popover-class="ui-dropdown-ex"
            placement="bottom-start"
            reference-class="common-dropdown"
            :widthByPopover="false"
            :filterable="true"
            :label="$t('archive.AssigneeName')"
            :compare-field="(item) => `${item.first_name} ${item.last_name}`"
        >
          <div class="common-dropdown__icon">
            <user-photo :user="form.assignee" size="xs" :show-online="false"/>
          </div>
          <div class="common-dropdown__title">
            {{ form.assignee.member_id ? form.assignee.full_name : $t('tasks.noAssignee') }}
          </div>

          <template v-slot:option="{ option }">
            <task-assignee :user="option"/>
          </template>
        </ui-dropdown>
      </div>

      <div class="task-card__subhead mt-4 mb-2">
        <description-icon class="task-card__subhead-icon task-card__subhead-icon_description"/>
        {{ $t('tasks.comment') }}
      </div>

      <text-editor
        :placeholder="$t('tasks.commentForNextAssignee')"
        :value="comment"
        @input="onCommentInput"
      />

      <div class="fx fx_end mt-4">
        <ui-button @click.prevent="onClose" class="btn-light-grey mr-2" ui-class="size_md">{{ $t('common.proceed') }}</ui-button>
        <ui-loading-button
          @click.prevent="onSubmit"
          ui-class="size_md"
          :loading="isProcessing"
        >
          {{ $t('tasks.finishTask') }}
        </ui-loading-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import TaskAssignee from "@/components/tasks/components/TaskAssignee"
import TextEditor from "@/components/ui/TextEditor"
import DescriptionIcon from "@/components/icons/DescriptionIcon"
import UiButton from 'scorework-ui/src/components/UiButton'
import UiDropdown from "@/components/tasks/UiDropdown"
import UserPhoto from "@/components/ui/UserPhoto"
import confirmationMixin from '@/components/tasks/mixins/confirmationMixin';

export default {
  name: 'ConfirmTaskCompleteModal',

  components: { DescriptionIcon, TaskAssignee, TextEditor, UiButton, UiDropdown, UserPhoto },

  mixins: [confirmationMixin],

  props: {
    stages: {
      type: Array,
      required: true
    },

    assignee: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    const assignee = this.assignee.member_id
      ? this.assignee
      : {
        member_id: null,
        first_name: this.$t('tasks.notSelected')
      }

    return {
      form: {
        assignee,
        stage: this.stages[0]
      },
      comment: null,
      commentGetters: {
        getJSON: () => null,
        getHTML: () => ''
      }
    }
  },

  computed: {
    ...mapGetters(['activeMembers']),

    assigneesList () {
      return [{
        member_id: null,
        first_name: 'Не выбран'
      }, ...this.activeMembers]
    }
  },

  methods: {
    onClose () {
      this.$emit('close')
    },

    onAssigneeChange (val) {
      this.form.assignee = val
    },

    onStageChange (val) {
      this.form.stage = val
    },

    onSubmit () {
      const { stage, assignee } = this.form
      const { getJSON, getHTML } = this.commentGetters

      const comment = JSON.stringify({
        json: getJSON(),
        html: getHTML()
      })

      this.$emit('submit', { stageId: stage.id, memberId: assignee.member_id, comment })
    },

    onCommentInput (getters) {
      this.commentGetters = getters
    }
  }
}
</script>

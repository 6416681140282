<template>
  <div class="channel-photo">
    <div class="sidebar-chat__unseen" v-if="showUnseen && channel.unseen_count">
      {{ channel.unseen_count }}
    </div>
    <template v-if="!isHelpdesk(channel)">
      <template v-if="channel.type === 'direct'">
        <user-photo :user="getMember(channel.target_member_id)" :size="photoSize"/>
      </template>
      <template v-if="channel.type === 'group'">
        <div class="channel-photo__block" :class="multiChannelClasses">
          <users-icon/>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="channel-photo__block channel-photo__block_helpdesk">
        <logo-icon/>
      </div>
    </template>
  </div>
</template>
<script>
import channelHelpers from "@/components/messenger/channelHelpers"
import LogoIcon from "@/components/icons/LogoIcon"
import UserPhoto from "@/components/ui/UserPhoto"
import UsersIcon from "@/components/icons/UsersIcon"

export default {
  name: 'ChannelPhoto',

  mixins: [channelHelpers],

  components: { LogoIcon, UsersIcon, UserPhoto },

  props: {
    channel: {
      type: Object,
      required: true
    },

    photoSize: {
      type: String,
      default: 'xmd'
    },

    showUnseen: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    multiChannelClasses () {
      const styleId = this.channel.member_id % 6 + 1
      return [`channel-photo__block_style-${styleId}`]
    }
  }
}
</script>
<style lang="sass">
.channel-photo
  &__block
    cursor: pointer
    width: 44px
    height: 44px
    border-radius: 16px
    display: flex
    align-items: center
    justify-content: center

    &_helpdesk
      background: var(--block-bg-color)

    &_style-1
      color: #005DF2
      background: #E3EEFF

      svg path
        stroke: #005DF2

    &_style-2
      color: #6952DC
      background: #EDEAFD
      svg path
        stroke: #6952DC

    &_style-3
      color: #25C685
      background: #D4F5E9
      svg path
        stroke: #25C685

    &_style-4
      color: #FFBC25
      background: #FEF3D9
      svg path
        stroke: #FFBC25

    &_style-5
      color: #FF8A34
      background: #FFEFE3
      svg path
        stroke: #FF8A34

    &_style-6
      color: #FF464F
      background: #FFE5E7
      svg path
        stroke: #FF464F
</style>
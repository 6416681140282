<template>
  <editor-popover :editor="editor" :tippy-options="{ placement: 'top' }" @created="popoverCreated">
    <div class="ui-dropdown ui-dropdown-ex ui-dropdown_round">
      <div class="xui-list" style="min-width: 220px;">
        <div class="xui-list-section">
          {{ $t('editor.table.columns') }}
        </div>
        <div class="xui-list-item" @mousedown="editor.chain().focus().addColumnBefore().run()">
          <arrow-left-line-icon class="xui-list-item-icon"/>
          {{ $t('editor.table.addColumnBefore') }}
        </div>
        <div class="xui-list-item" @click="editor.chain().focus().addColumnAfter().run()">
          <arrow-right-line-icon class="xui-list-item-icon"/>
          {{ $t('editor.table.addColumnAfter') }}
        </div>

        <div class="xui-list-item" @click="editor.chain().focus().deleteColumn().run()">
          <close-icon class="xui-list-item-icon"/>
          {{ $t('editor.table.deleteColumn') }}
        </div>

        <div class="xui-list-section">
          {{ $t('editor.table.rows') }}
        </div>

        <div class="xui-list-item" @click="editor.chain().focus().addRowBefore().run()">
          <arrow-up-line-icon class="xui-list-item-icon"/>
          {{ $t('editor.table.addRowBefore') }}
        </div>
        <div class="xui-list-item" @click="editor.chain().focus().addRowAfter().run()">
          <arrow-down-line-icon class="xui-list-item-icon"/>
          {{ $t('editor.table.addRowAfter') }}
        </div>

        <div class="xui-list-item" @click="editor.chain().focus().deleteRow().run()">
          <close-icon class="xui-list-item-icon"/>
          {{ $t('editor.table.deleteRow') }}
        </div>
      </div>
    </div>
  </editor-popover>
</template>
<script>
  import ArrowLeftLineIcon from '@/components/icons/editor/ArrowLeftLineIcon'
  import CloseIcon from '@/components/icons/CloseIcon'
  import ArrowRightLineIcon from '@/components/icons/editor/ArrowRightLineIcon'
  import ArrowUpLineIcon from '@/components/icons/editor/ArrowUpLineIcon'
  import ArrowDownLineIcon from '@/components/icons/editor/ArrowDownLineIcon'
  import EditorPopover from '@/components/ui/textEditor/popovers/EditorPopover'

  export default {
    name: 'TableCellPopover',

    components: {
      EditorPopover, ArrowDownLineIcon, ArrowUpLineIcon, ArrowRightLineIcon, CloseIcon, ArrowLeftLineIcon
    },

    props: {
      editor: {
        type: Object,
        required: true,
      }
    },

    methods: {
      popoverCreated (popover) {
        this.$emit('created', popover)
      }
    }
  }
</script>

<template>
  <div class="status-task-item status-task-item_finished" @dblclick="onOpenTask">
    <div class="status-task-item__block">
      <div class="status-task-item__target" :class="{['target-label_' + task.target.color]: true}" v-if="task.target"></div>

      <div class="status-task-item__content">
        <div class="status-task-item__title">
          <div class="status-task-item__number">
            {{ taskNumber }}
          </div>
          <a href="#" class="status-task-item__title-link" @click="onOpenTask">{{ task.name }}</a>
        </div>

        <div class="status-task-item__info">
          <sphere-badge class="task-item__sphere-badge" :sphere="task.sphere" v-if="task.sphere"/>
        </div>
      </div>
      <div class="status-task-item__extra">
        <div class="status-task-item__extra-left">
        <div class="status-task-item__date">
            <flag class="status-task-item__date-icon"/>
            {{ finishedAt }}
          </div>

          <div class="status-task-item__sprints">
            <div
              v-for="sprint in task.sprints"
              :key="sprint.id"
              class="task-item__sphere"
            >
              {{ getSprintName(sprint) }}
            </div>
          </div>
        </div>

        <div class="status-task-item__members">
          <member-popover
            v-for="member in members"
            :key="member.member_id"
            :member="member"
            @assign="onCreateTaskForMember"
          >
          <user-photo
            :user="member"
            :show-online="false"
          />
          </member-popover>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import taskItemMixin from "@/components/tasks/mixins/taskItemMixin"
import Flag from "@/components/icons/Flag"
import MemberPopover from "@/components/members/MemberPopover"
import {getPastDate} from "@/services/scoring";
import SphereBadge from "@/components/ui/SphereBadge"

export default {
  name: 'FinishedTaskItem',

  components: { SphereBadge, Flag, MemberPopover },

  mixins: [taskItemMixin],

  props: {
    task: {
      type: Object,
      required: true
    }
  },

  data () {
    const { label } = getPastDate(this.task.finishedAt)

    return {
      finishedAt: label
    }
  },

  computed: {
    members () {
      return this.task.states.reduce((members, item) => {
        if (item.assignee.member_id && members.indexOf(item.assignee) === -1) {
          members.push(item.assignee)
        }

        return members
      }, [])
    }
  },

  methods: {
    onCreateTaskForMember (data) {
      this.$emit('create-task', data)
    },

    getSprintName (sprint) {
      if (sprint.name) {
        return sprint.name
      }

      return this.$t('sprints.sprintNumber', { number: sprint.number })
    }
  }
}
</script>

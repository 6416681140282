<template>
  <div>
    <div class="task-files">
      <file-item
        v-for="file in task.files"
        :key="file.uuid"
        :file="file"
        :task-id="taskId"
        @show="onShowPicture"
      />
    </div>
    <overlay-gallery
      :list="imageFiles"
      :active-pic="gallery.activePic"
      v-if="gallery.open"
      @close="gallery.open = false"
    />
  </div>
</template>
<script>
  import FileItem from '@/components/tasks/components/FileItem'
  import OverlayGallery from '@/components/ui/OverlayGallery'

  export default {
    name: 'TaskFiles',

    components: { FileItem, OverlayGallery },

    props: {
      taskId: {
        type: Number,
        default: 0
      },

      task: {
        required: true
      }
    },

    data () {
      return {
        gallery: {
          open: false,
          activePic: '',
        }
      }
    },

    computed: {
      imageFiles () {
        return this.task.files.filter((file) => file.preview_url)
      }
    },

    methods: {
      onShowPicture ({ uuid }) {
        this.gallery.activePic = uuid
        this.gallery.open = true
      }
    }
  }
</script>

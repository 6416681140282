import apiService from "@/services/api";

export default function () {
  const state = {
    subscription: {},
  }

  const mutations = {
    SET_SUBSCRIPTION (state, data) {
      state.subscription = data
    }
  }

  const actions = {
    async fetchSubscription ({ commit }) {
      const { data } = await apiService.get('workspace/subscription')
      commit('SET_SUBSCRIPTION', data)
    }
  }

  const getters = {
    subscription: ({ subscription }) => subscription,
  }

  return {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  }
}
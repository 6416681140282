<template>
<div
  class="channel-item"
  :class="{ 'channel-item_active': isActive }"
  @click="onChannelClick(channel)"
>
  <channel-photo :channel="channel" class="channel-item__photo"/>

  <div class="channel-item__content">
    <div class="channel-item__row">
      <div class="channel-item__title">
        {{ channelTitle(channel) }}
      </div>

      <div class="channel-item__date">
        {{ formattedDate(channel) }}
      </div>
    </div>

    <div class="channel-item__row channel-item__row_second">
      <div class="channel-item__message">

        <template v-if="channel.last_message">
            <span v-if="channel.last_message.member_id === authUser.member_id" class="channel-item__your-prefix">
              {{ $t('messenger.you') }}:
            </span>
          {{ channel.last_message.message.payload }}
        </template>
        <template v-else>
          {{ $t('messenger.noLastMessage') }}
        </template>

        </div>

        <div class="channel-item__unseen" v-if="channel.unseen_count">
          {{ channel.unseen_count }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import channelHelpers from "@/components/messenger/channelHelpers"
import ChannelPhoto from "@/components/messenger/ChannelPhoto"
import { channelLastMessageDate } from "@/services/scoring"

export default {
  name: 'ChannelItem',

  components: { ChannelPhoto },

  mixins: [channelHelpers],

  props: {
    channel: {
      type: Object,
      required: true
    },

    isActive: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    formattedDate (channel) {
      return channelLastMessageDate(channel.updatedAt)
    },

    onChannelClick (channel) {
      this.$emit('select', channel)
    },

    multiChannelClasses (channel) {
      const styleId = channel.member_id % 6 + 1

      return [`channel-item__multi-photo_style-${styleId}`]
    }
  },

  created () {
  }
}
</script>
<style lang="sass">
.channel-item
  display: flex
  flex-flow: row nowrap
  padding: 12px 16px
  background: var(--block-bg-color)
  transition: background .1s ease-in
  cursor: pointer

  &:hover
    background: var(--primary-light-color)

  &_active
    background: var(--primary-color)
    color: #FFFFFF

    &:hover
      background: var(--primary-color)

    .channel-item__title
      color: #FFFFFF

    .channel-item__date
      color: #FFFFFF

    .channel-item__message
      color: #FFFFFF

    .channel-item__your-prefix
      color: #FFFFFF

    .channel-item__unseen
      color: var(--primary-color)
      background: #FFFFFF

  &__photo
    margin-right: 12px

  &__content
    flex: 1
    overflow: hidden

  &__row
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: center

    &_second
      padding-top: 8px

  &__title
    color:  var(--text-primary-color)
    font-size: 14px
    font-weight: 500

  &__date
    color: var(--text-light-color)
    font-size: 11px

  &__message
    color:  var(--text-light-color)
    font-size: 12px
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  &__your-prefix
    color: var(--text-primary-color)

  &__unseen
    background: var(--primary-color)
    color: #FFFFFF
    border-radius: 4px
    padding: 2px 6px
    font-size: 12px
    font-weight: 600
</style>

import { DateTime } from 'luxon'
import languageService from './language'


export const MinScoreValue = 30
export const MaxScoreValue = 14400 // 30 раб. дней

export const weekDays = {
  1: { name: 'ui.monday', short: 'ui.mo', withTime: 'ui.mondayTime' },
  2: { name: 'ui.tuesday', short: 'ui.tu', withTime: 'ui.tuesdayTime'},
  3: { name: 'ui.wednesday', short: 'ui.we', withTime: 'ui.wednesdayTime'},
  4: { name: 'ui.thursday', short: 'ui.th', withTime: 'ui.thursdayTime'},
  5: { name: 'ui.friday', short: 'ui.fr', withTime: 'ui.fridayTime'},
  6: { name: 'ui.saturday', short: 'ui.sa', withTime: 'ui.saturdayTime'},
  7: { name: 'ui.sunday', short: 'ui.su', withTime: 'ui.sundayTime'},
}

const dayMapping = {
  '1': languageService.i18n.t(`ui.monday`),
  '2': languageService.i18n.t(`ui.tuesday`),
  '3': languageService.i18n.t(`ui.wednesday`),
  '4': languageService.i18n.t(`ui.thursday`),
  '5': languageService.i18n.t(`ui.friday`),
  '6': languageService.i18n.t(`ui.saturday`),
  '7': languageService.i18n.t(`ui.sunday`)
}

const shortDayMapping = {
  '1': languageService.i18n.t(`ui.mo`),
  '2': languageService.i18n.t(`ui.tu`),
  '3': languageService.i18n.t(`ui.we`),
  '4': languageService.i18n.t(`ui.th`),
  '5': languageService.i18n.t(`ui.fr`),
  '6': languageService.i18n.t(`ui.sa`),
  '7': languageService.i18n.t(`ui.su`)
}

export const getPastDate = function (date) {
  const now = DateTime.local()
  let targetDate = DateTime.fromObject(date.toObject())

  targetDate = targetDate.setLocale('en')
  const diff = now.diff(targetDate, ['days', 'hours', 'minutes', 'months', 'years'])
  const thisYear = diff.months === 0 && diff.years === 0
  const sameDay = thisYear && diff.days === 0 && now.hour >= diff.hours

  if (diff.days >= 7 || diff.months !== 0 || diff.years > 0) {
    return {
      diff,
      label: `${targetDate.day} ${languageService.i18n.t(`dateTime.months.${targetDate.monthLong}`)} ${targetDate.year}`
    }
  }

  if (thisYear && ((diff.days === 1 && now.hour > diff.hours) || (diff.days === 0 && now.hour < diff.hours))) {
    return {
      diff,
      label: `${languageService.i18n.t(`dateTime.yesterdayAt`)} ${targetDate.toLocaleString(DateTime.TIME_24_SIMPLE)}`
    }
  }

  if (diff.days > 0 && diff.days < 7 && thisYear) {
    let daysAgo = diff.days
    if (now.hour <= diff.hours) {
      daysAgo++
    }

    return {
      diff,
      label: languageService.i18n.tc('dateTime.daysAgo', daysAgo, { n: daysAgo })
    }
  }

  if (sameDay) {
    if (diff.hours < 1) {
      if (diff.minutes <= 1) {
        return {
          diff,
          label: languageService.i18n.t(`dateTime.just`)
        }
      }

      let minutes = Math.round(diff.minutes)
      return {
        diff,
        label: `${minutes} ${languageService.i18n.tc(`dateTime.minute`, minutes)} ${languageService.i18n.t(`dateTime.ago`)}`
      }
    }

    if (diff.hours < 20) {
      return {
        diff,
        label: diff.hours + ` ${languageService.i18n.tc(`dateTime.hour`, diff.hours)} ${languageService.i18n.t(`dateTime.ago`)}`
      }
    }

    return {
      diff,
      label: `${languageService.i18n.t(`dateTime.todayAt`)} ${targetDate.toLocaleString(DateTime.TIME_24_SIMPLE)}`
    }
  }

  return {
    diff,
    label: Math.round(diff.minutes / 60) + ` ${languageService.i18n.tc(`dateTime.hour`, Math.round(diff.minutes / 60))} ${languageService.i18n.t(`dateTime.ago`)}`
  }
}

export const getEstimatedTime = function (finishedAt) {
  if (!finishedAt) {
    return languageService.i18n.t('tasks.progress.estimatedFinishAtUnknown')
  }

  const now = DateTime.local()
  const targetDate = DateTime.fromObject(finishedAt.toObject())

  const diff = targetDate.diff(now, ['days', 'hours', 'minutes'])

  if (diff.days === 0) {
    if (diff.hours < 1) {
      return languageService.i18n.t(`dateTime.lessThenAnHour`)
    }

    if (diff.hours < 8) {
      return `${languageService.i18n.t(`dateTime.after`)} ` + diff.hours + ` ${languageService.i18n.tc(`dateTime.hour`, diff.hours)}`
    }

    if (targetDate.weekday !== now.weekday) {
      return `${languageService.i18n.t(`dateTime.tomorrowAt`)} ${targetDate.toLocaleString(DateTime.TIME_24_SIMPLE)}`
    }

    return `${languageService.i18n.t(`dateTime.todayAt`)} ${targetDate.toLocaleString(DateTime.TIME_24_SIMPLE)}`
  }

  if (diff.days < 7) {
    const dayNumber = targetDate.weekday
    return languageService.i18n.t(weekDays[dayNumber].withTime, { time: targetDate.toLocaleString(DateTime.TIME_24_SIMPLE) })
  }

  if (diff.days >= 7) {
    return `${targetDate.day} ${targetDate.monthLong}`
  }

  return `${languageService.i18n.t(`dateTime.after`)} ` + Math.round(diff.minutes / 60) + ` ${languageService.i18n.tc(`dateTime.hour`, Math.round(diff.minutes / 60))}`
}

export const setupWorkHours = function ({ start, end }) {
  return {
    start: toLocalWorkTime(start),
    end: toLocalWorkTime(end)
  }
}

export const workHoursToUtc = function ({ start, end }) {
  return {
    start: toUtcWorkTime(start),
    end: toUtcWorkTime(end)
  }
}

function toLocalWorkTime (time) {
  const timeParts = time.split(':')

  const dt = DateTime.utc()
    .set({ hour: Number(timeParts[0]), minute: Number(timeParts[1]) })
    .setZone('local')

  return dt.toLocaleString(DateTime.TIME_24_SIMPLE)
}

export function toUtcWorkTime (time) {
  const timeParts = time.split(':')

  const dt = DateTime.local()
    .set({ hour: Number(timeParts[0]), minute: Number(timeParts[1]) })
    .setZone('utc')

  return dt.toLocaleString(DateTime.TIME_24_SIMPLE)
}

export function scoreToLabel(score) {
  return getScoreLabel(getScoreObject(score))
}

export function getScoreObject (score, dailyMinutes = 0) {
  let restMinutes = score
  let days = 0

  if (dailyMinutes) {
    restMinutes = score % dailyMinutes
    days = (score - restMinutes) / dailyMinutes
  }

  const minutes = restMinutes % 60
  const hours = (restMinutes - minutes) / 60

  return { days, hours, minutes }
}

export function getScoreLabel (scoreObject) {
  const resultParts = []

  if (scoreObject.days) {
    resultParts.push(`${scoreObject.days} ${languageService.i18n.t('dateTime.short.days')}`)
  }

  if (scoreObject.hours) {
    resultParts.push(`${scoreObject.hours} ${languageService.i18n.t('dateTime.short.hours')}`)
  }

  if (scoreObject.minutes) {
    resultParts.push(`${scoreObject.minutes} ${languageService.i18n.t('dateTime.short.minutes')}`)
  }

  return resultParts.join(' ')
}

export const channelLastMessageDate = function (updatedAt) {
  const now = DateTime.local()
  const cDate = DateTime.fromSeconds(updatedAt.toSeconds())

  const day = 60 * 60 * 24
  const week = day * 7

  const nowTs   = Math.round(now.toSeconds())
  const dateTs  = Math.round(cDate.toSeconds())

  if (nowTs - day < dateTs) {
    return cDate.toFormat('T')
  }

  if (nowTs - week < dateTs) {
    return shortDayMapping[cDate.toFormat("c")]
  }

  return cDate.toFormat('DD')
}

export const getMessageDateAnchor = function (date) {
  const now = DateTime.local().startOf('day')

  const day = 60 * 60 * 24

  const nowTs   = Math.round(now.toSeconds())
  const dateTs  = Math.round(date.toSeconds())

  if (nowTs < dateTs) {
    return languageService.i18n.t('ui.today')
  }

  if (nowTs - day < dateTs - day) {
    return languageService.i18n.t('ui.yesterday')
  }

  for (let i = 2; i < 7; i++) {
    const offset = day * i
    if (nowTs - offset < dateTs) {
      return dayMapping[date.toFormat("c")]
    }
  }

  return date.toFormat('dd.LL.yyyy')
}

export function transformDateToObject(stringDate, format = 'yyyy-MM-dd HH:mm:ss') {
  return stringDate
    ? DateTime.fromFormat(stringDate, format, { zone: 'UTC' }).setZone('local').setLocale("ru")
    : null
}

export function buildTimezoneOffsetString() {
  const offset = new Date().getTimezoneOffset()
  const hours = Math.abs(Math.ceil(offset / 60))
  const minutes = Math.abs(offset) - hours * 60

  let result = offset > 0 ? '-' : '+'
  result += hours
  result += minutes < 10 ? '0' + minutes : minutes

  return result
}

export function getMemberWorkingState(workSchedule) {
  const now = DateTime.local()
  const { start, end } = workSchedule.work_hours

  const startTime = start.split(':')
  const endTime   = end.split(':')

  let startDate = DateTime.fromObject({
    year: now.year,
    month: now.month,
    day: now.day,
    hour: Number(startTime[0]),
    minute: Number(startTime[1]),
    zone: 'local'
  })

  let endDate = DateTime.fromObject({
    year: now.year,
    month: now.month,
    day: now.day,
    hour: Number(endTime[0]),
    minute: Number(endTime[1]),
    zone: 'local'
  })

  if (start > end) {
    const nowTime = now.hour * 100 + now.minute
    if (nowTime > Number(endTime)) {
      endDate = endDate.plus({ days: 1 })
    } else {
      startDate = startDate.plus({ days: -1 })
    }
  }

  if (workSchedule.days.indexOf(startDate.weekday) === -1) {
    return {
      isWorking: false
    }
  }

  const scheduleState = {
    isWorking: now >= startDate && now <= endDate
  }

  if (scheduleState.isWorking) {
    const diff = endDate.diff(now, ['days', 'hours', 'minutes'])
    scheduleState.remain = {
      hours: diff.hours,
      minutes: Math.floor(diff.minutes)
    }
  }

  return scheduleState
}

<template>
  <div class="ui-progress-bar">
    <div class="ui-progress-bar__fill" :style="'width: ' + (progress > 100 ? 100 : progress) + '%'"></div>
  </div>
</template>
<script>
  export default {
    name: 'ProgressBar',

    props: {
      progress: {
        type: Number,
        required: true
      }
    }
  }
</script>
<style lang="sass">
  .ui-progress-bar
    width: 100%
    height: 4px
    border-radius: 2px
    background: var(--color-grey-hover)
    position: relative
    transition: all ease-in .2s

    &__fill
      position: absolute
      background: var(--primary-color)
      left: 0
      top: 0
      height: 4px
      border-radius: 2px
      transition: all ease-in .2s
</style>

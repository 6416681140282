<template>
  <div class="blank-layout">
    <div class="blank-layout__header header">
      <router-link to="/" class="header-logo">
        <logo-icon class="header-logo__icon"/>Scorework
      </router-link>
    </div>

    <div class="blank-layout__view">
      <div class="blank-layout__content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import LogoIcon from "@/components/icons/LogoIcon"
export default {
  name: 'AuthLayout',

  components: { LogoIcon },

  created () {
  }
}
</script>
<style lang="sass">
.blank-layout
  height: 100%
  width: 1200px
  display: flex
  flex-flow: column nowrap
  align-items: center
  margin: 0 auto

  &__header
    padding: 32px 0
    width: 100%

  &__view
    flex: 1
    display: flex
    width: 100%
    justify-content: center

    &_authorized
      margin-top: 24px
      align-items: flex-start

      .blank-layout__content
        width: 650px

  &__logo
    margin-bottom: 20px

.header-logo
  font-size: 30px
  font-weight: 600
  color: var(--text-primary-color)
  text-decoration: none
  display: flex
  align-items: center

  &__icon
    margin-right: 20px
</style>

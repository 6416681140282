<template>
  <svg width="309" height="224" viewBox="0 0 309 224" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M182.032 224C233.085 224 274.472 222.014 274.472 219.565C274.472 217.115 233.085 215.13 182.032 215.13C130.979 215.13 89.5923 217.115 89.5923 219.565C89.5923 222.014 130.979 224 182.032 224Z" fill="#E3E5FC"/>
    <path d="M301.417 22.1901C301.417 16.331 296.624 11.5381 290.765 11.5381H73.6784C67.8193 11.5381 63.0264 16.331 63.0264 22.1901V46.413H301.419V22.1901H301.417Z" fill="#A7ADFF"/>
    <rect x="63.0234" y="46.413" width="238.393" height="144.769" fill="#E3E5FC"/>
    <path d="M107.446 92.191H86.2203C83.1285 92.191 80.5981 89.6607 80.5981 86.5688V67.3711C80.5981 64.2792 83.1285 61.7489 86.2203 61.7489H107.446C110.538 61.7489 113.068 64.2792 113.068 67.3711V86.5688C113.068 89.6607 110.538 92.191 107.446 92.191Z" fill="white"/>
    <path d="M164.106 92.191H142.88C139.789 92.191 137.258 89.6607 137.258 86.5688V67.3711C137.258 64.2792 139.789 61.7489 142.88 61.7489H164.106C167.198 61.7489 169.728 64.2792 169.728 67.3711V86.5688C169.728 89.6607 167.198 92.191 164.106 92.191Z" fill="white"/>
    <path d="M220.766 92.191H199.54C196.448 92.191 193.918 89.6607 193.918 86.5688V67.3711C193.918 64.2792 196.448 61.7489 199.54 61.7489H220.766C223.858 61.7489 226.388 64.2792 226.388 67.3711V86.5688C226.388 89.6607 223.858 92.191 220.766 92.191Z" fill="white"/>
    <path d="M277.426 92.191H256.2C253.108 92.191 250.578 89.6607 250.578 86.5688V67.3711C250.578 64.2792 253.108 61.7489 256.2 61.7489H277.426C280.517 61.7489 283.048 64.2792 283.048 67.3711V86.5688C283.048 89.6607 280.517 92.191 277.426 92.191Z" fill="white"/>
    <path d="M107.446 133.131H86.2203C83.1285 133.131 80.5981 130.601 80.5981 127.509V108.311C80.5981 105.219 83.1285 102.689 86.2203 102.689H107.446C110.538 102.689 113.068 105.219 113.068 108.311V127.509C113.068 130.601 110.538 133.131 107.446 133.131Z" fill="white"/>
    <path d="M164.106 133.131H142.88C139.789 133.131 137.258 130.601 137.258 127.509V108.311C137.258 105.219 139.789 102.689 142.88 102.689H164.106C167.198 102.689 169.728 105.219 169.728 108.311V127.509C169.728 130.601 167.198 133.131 164.106 133.131Z" fill="white"/>
    <path d="M220.766 133.131H199.54C196.448 133.131 193.918 130.601 193.918 127.509V108.311C193.918 105.219 196.448 102.689 199.54 102.689H220.766C223.858 102.689 226.388 105.219 226.388 108.311V127.509C226.388 130.601 223.858 133.131 220.766 133.131Z" fill="white"/>
    <path d="M277.426 133.131H256.2C253.108 133.131 250.578 130.601 250.578 127.509V108.311C250.578 105.219 253.108 102.689 256.2 102.689H277.426C280.517 102.689 283.048 105.219 283.048 108.311V127.509C283.048 130.601 280.517 133.131 277.426 133.131Z" fill="white"/>
    <path d="M107.446 174.071H86.2203C83.1285 174.071 80.5981 171.541 80.5981 168.449V149.251C80.5981 146.159 83.1285 143.629 86.2203 143.629H107.446C110.538 143.629 113.068 146.159 113.068 149.251V168.449C113.068 171.541 110.538 174.071 107.446 174.071Z" fill="white"/>
    <path d="M164.106 174.071H142.88C139.789 174.071 137.258 171.541 137.258 168.449V149.251C137.258 146.159 139.789 143.629 142.88 143.629H164.106C167.198 143.629 169.728 146.159 169.728 149.251V168.449C169.728 171.541 167.198 174.071 164.106 174.071Z" fill="white"/>
    <path d="M220.766 174.071H199.54C196.448 174.071 193.918 171.541 193.918 168.449V149.251C193.918 146.159 196.448 143.629 199.54 143.629H220.766C223.858 143.629 226.388 146.159 226.388 149.251V168.449C226.388 171.541 223.858 174.071 220.766 174.071Z" fill="white"/>
    <path d="M277.426 174.071H256.2C253.108 174.071 250.578 171.541 250.578 168.449V149.251C250.578 146.159 253.108 143.629 256.2 143.629H277.426C280.517 143.629 283.048 146.159 283.048 149.251V168.449C283.048 171.541 280.517 174.071 277.426 174.071Z" fill="white"/>
    <path d="M117.104 35.5311C121.5 35.5311 125.064 31.9671 125.064 27.5706C125.064 23.1741 121.5 19.61 117.104 19.61C112.707 19.61 109.143 23.1741 109.143 27.5706C109.143 31.9671 112.707 35.5311 117.104 35.5311Z" fill="#5A54FF"/>
    <path d="M117.416 31.0818V31.0818C115.002 31.0818 113.026 29.1058 113.026 26.6916V4.39016C113.026 1.97593 115.002 0 117.416 0V0C119.83 0 121.806 1.97593 121.806 4.39016V26.6916C121.806 29.1058 119.83 31.0818 117.416 31.0818Z" fill="#F44E4E"/>
    <path d="M160.365 35.5311C164.762 35.5311 168.326 31.9671 168.326 27.5706C168.326 23.1741 164.762 19.61 160.365 19.61C155.969 19.61 152.405 23.1741 152.405 27.5706C152.405 31.9671 155.969 35.5311 160.365 35.5311Z" fill="#5A54FF"/>
    <path d="M160.678 31.0818V31.0818C158.264 31.0818 156.288 29.1058 156.288 26.6916V4.39016C156.288 1.97593 158.264 0 160.678 0V0C163.092 0 165.068 1.97593 165.068 4.39016V26.6916C165.068 29.1058 163.092 31.0818 160.678 31.0818Z" fill="#F44E4E"/>
    <path d="M203.628 35.5311C208.024 35.5311 211.588 31.9671 211.588 27.5706C211.588 23.1741 208.024 19.61 203.628 19.61C199.231 19.61 195.667 23.1741 195.667 27.5706C195.667 31.9671 199.231 35.5311 203.628 35.5311Z" fill="#5A54FF"/>
    <path d="M203.94 31.0818V31.0818C201.526 31.0818 199.55 29.1058 199.55 26.6916V4.39016C199.55 1.97593 201.526 0 203.94 0V0C206.354 0 208.33 1.97593 208.33 4.39016V26.6916C208.33 29.1058 206.354 31.0818 203.94 31.0818Z" fill="#F44E4E"/>
    <path d="M246.889 35.5311C251.286 35.5311 254.85 31.9671 254.85 27.5706C254.85 23.1741 251.286 19.61 246.889 19.61C242.493 19.61 238.929 23.1741 238.929 27.5706C238.929 31.9671 242.493 35.5311 246.889 35.5311Z" fill="#5A54FF"/>
    <path d="M247.202 31.0818V31.0818C244.787 31.0818 242.812 29.1058 242.812 26.6916V4.39016C242.812 1.97593 244.787 0 247.202 0V0C249.616 0 251.592 1.97593 251.592 4.39016V26.6916C251.592 29.1058 249.616 31.0818 247.202 31.0818Z" fill="#F44E4E"/>
    <path d="M93.2716 126.58H93.2739L93.2716 126.578V126.58Z" fill="#F44E4E"/>
    <path d="M108.835 111.017C108.425 110.607 107.754 110.607 107.344 111.017L94.0176 124.344L95.5078 125.834L108.835 112.507C109.245 112.097 109.245 111.427 108.835 111.017Z" fill="#F44E4E"/>
    <path d="M93.2714 125.09L94.0154 124.344L87.9881 118.316C87.5782 117.906 86.9077 117.906 86.4978 118.316C86.088 118.726 86.088 119.397 86.4978 119.807L93.2714 126.58C92.8639 126.17 92.8639 125.5 93.2714 125.09Z" fill="#F44E4E"/>
    <path d="M93.2713 125.09C92.8614 125.5 92.8614 126.17 93.2713 126.58V126.58C93.6811 126.99 94.3516 126.99 94.7615 126.58L95.5054 125.836L94.0152 124.346L93.2713 125.09Z" fill="#F44E4E"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M205.601 166.788C205.849 167.037 206.176 167.16 206.503 167.16C206.83 167.16 207.157 167.037 207.401 166.786L221.474 152.713C221.972 152.215 221.972 151.407 221.474 150.91C220.977 150.412 220.169 150.415 219.671 150.91L206.501 164.08L200.63 158.209C200.151 157.728 199.306 157.728 198.827 158.209C198.588 158.451 198.455 158.768 198.455 159.112C198.455 159.456 198.588 159.775 198.827 160.015L205.601 166.788ZM199.145 158.524C199.301 158.368 199.51 158.283 199.732 158.283C199.955 158.283 200.163 158.37 200.322 158.524L206.506 164.708L219.991 151.225C220.316 150.903 220.844 150.9 221.169 151.225C221.491 151.547 221.491 152.075 221.169 152.4L207.096 166.473C206.771 166.795 206.243 166.798 205.918 166.473L199.145 159.699C198.991 159.543 198.903 159.335 198.903 159.112C198.903 158.889 198.988 158.681 199.145 158.524Z" fill="#F44E4E"/>
    <path d="M164.663 69.1883C164.253 68.7784 163.583 68.7784 163.173 69.1883L149.846 82.5151L151.336 84.0054L164.663 70.6785C165.071 70.2686 165.071 69.5981 164.663 69.1883Z" fill="#F44E4E"/>
    <path d="M149.1 83.2614L149.843 82.5151L143.816 76.4878C143.406 76.078 142.736 76.078 142.326 76.4878C141.916 76.8977 141.916 77.5682 142.326 77.9781L149.1 84.7517C148.69 84.3418 148.69 83.6713 149.1 83.2614Z" fill="#F44E4E"/>
    <path d="M149.099 83.2614C148.69 83.6713 148.69 84.3418 149.099 84.7516V84.7516C149.509 85.1615 150.18 85.1615 150.59 84.7516L151.334 84.0077L149.843 82.5175L149.099 83.2614Z" fill="#F44E4E"/>
    <path d="M164.542 110.318H142.447C141.75 110.318 141.182 109.749 141.182 109.053V109.053C141.182 108.356 141.75 107.787 142.447 107.787H164.542C165.239 107.787 165.807 108.356 165.807 109.053V109.053C165.807 109.749 165.239 110.318 164.542 110.318Z" fill="#E3E5FC"/>
    <path d="M164.542 116.464H142.447C141.75 116.464 141.182 115.895 141.182 115.198V115.198C141.182 114.502 141.75 113.933 142.447 113.933H164.542C165.239 113.933 165.807 114.502 165.807 115.198V115.198C165.807 115.895 165.239 116.464 164.542 116.464Z" fill="#E3E5FC"/>
    <path d="M164.542 122.609H142.447C141.75 122.609 141.182 122.041 141.182 121.344V121.344C141.182 120.648 141.75 120.079 142.447 120.079H164.542C165.239 120.079 165.807 120.648 165.807 121.344V121.344C165.807 122.041 165.239 122.609 164.542 122.609Z" fill="#E3E5FC"/>
    <path d="M164.542 128.755H142.447C141.75 128.755 141.182 128.187 141.182 127.49V127.49C141.182 126.793 141.75 126.225 142.447 126.225H164.542C165.239 126.225 165.807 126.793 165.807 127.49V127.49C165.807 128.187 165.239 128.755 164.542 128.755Z" fill="#E3E5FC"/>
    <path d="M107.882 151.583H85.7871C85.0906 151.583 84.522 151.014 84.522 150.317V150.317C84.522 149.621 85.0906 149.052 85.7871 149.052H107.882C108.579 149.052 109.148 149.621 109.148 150.317V150.317C109.148 151.014 108.577 151.583 107.882 151.583Z" fill="#E3E5FC"/>
    <path d="M107.882 157.728H85.7871C85.0906 157.728 84.522 157.16 84.522 156.463V156.463C84.522 155.766 85.0906 155.198 85.7871 155.198H107.882C108.579 155.198 109.148 155.766 109.148 156.463V156.463C109.148 157.16 108.577 157.728 107.882 157.728Z" fill="#E3E5FC"/>
    <path d="M107.882 163.874H85.7871C85.0906 163.874 84.522 163.305 84.522 162.609V162.609C84.522 161.912 85.0906 161.344 85.7871 161.344H107.882C108.579 161.344 109.148 161.912 109.148 162.609V162.609C109.148 163.305 108.577 163.874 107.882 163.874Z" fill="#E3E5FC"/>
    <path d="M107.882 170.022H85.7871C85.0906 170.022 84.522 169.454 84.522 168.757V168.757C84.522 168.061 85.0906 167.492 85.7871 167.492H107.882C108.579 167.492 109.148 168.061 109.148 168.757V168.757C109.148 169.451 108.577 170.022 107.882 170.022Z" fill="#E3E5FC"/>
    <path d="M278.21 69.0154H256.115C255.418 69.0154 254.85 68.4468 254.85 67.7503V67.7503C254.85 67.0537 255.418 66.4851 256.115 66.4851H278.21C278.907 66.4851 279.475 67.0537 279.475 67.7503V67.7503C279.473 68.4468 278.904 69.0154 278.21 69.0154Z" fill="#E3E5FC"/>
    <path d="M278.21 75.1611H256.115C255.418 75.1611 254.85 74.5924 254.85 73.8959V73.8959C254.85 73.1993 255.418 72.6307 256.115 72.6307H278.21C278.907 72.6307 279.475 73.1993 279.475 73.8959V73.8959C279.473 74.5924 278.904 75.1611 278.21 75.1611Z" fill="#E3E5FC"/>
    <path d="M278.21 81.3068H256.115C255.418 81.3068 254.85 80.7382 254.85 80.0417V80.0417C254.85 79.3451 255.418 78.7765 256.115 78.7765H278.21C278.907 78.7765 279.475 79.3451 279.475 80.0417V80.0417C279.473 80.7382 278.904 81.3068 278.21 81.3068Z" fill="#E3E5FC"/>
    <path d="M278.21 87.4524H256.115C255.418 87.4524 254.85 86.8838 254.85 86.1873V86.1873C254.85 85.4907 255.418 84.9221 256.115 84.9221H278.21C278.907 84.9221 279.475 85.4907 279.475 86.1873V86.1873C279.473 86.8838 278.904 87.4524 278.21 87.4524Z" fill="#E3E5FC"/>
    <path d="M221.202 69.0154H199.106C198.41 69.0154 197.841 68.4468 197.841 67.7503V67.7503C197.841 67.0537 198.41 66.4851 199.106 66.4851H221.202C221.898 66.4851 222.467 67.0537 222.467 67.7503V67.7503C222.467 68.4468 221.898 69.0154 221.202 69.0154Z" fill="#E3E5FC"/>
    <path d="M221.202 75.1611H199.106C198.41 75.1611 197.841 74.5924 197.841 73.8959V73.8959C197.841 73.1993 198.41 72.6307 199.106 72.6307H221.202C221.898 72.6307 222.467 73.1993 222.467 73.8959V73.8959C222.467 74.5924 221.898 75.1611 221.202 75.1611Z" fill="#E3E5FC"/>
    <path d="M221.202 81.3068H199.106C198.41 81.3068 197.841 80.7382 197.841 80.0417V80.0417C197.841 79.3451 198.41 78.7765 199.106 78.7765H221.202C221.898 78.7765 222.467 79.3451 222.467 80.0417V80.0417C222.467 80.7382 221.898 81.3068 221.202 81.3068Z" fill="#E3E5FC"/>
    <path d="M221.202 87.4524H199.106C198.41 87.4524 197.841 86.8838 197.841 86.1873V86.1873C197.841 85.4907 198.41 84.9221 199.106 84.9221H221.202C221.898 84.9221 222.467 85.4907 222.467 86.1873V86.1873C222.467 86.8838 221.898 87.4524 221.202 87.4524Z" fill="#E3E5FC"/>
    <path d="M221.202 110.055H199.106C198.41 110.055 197.841 109.486 197.841 108.79V108.79C197.841 108.093 198.41 107.525 199.106 107.525H221.202C221.898 107.525 222.467 108.093 222.467 108.79V108.79C222.467 109.486 221.898 110.055 221.202 110.055Z" fill="#E3E5FC"/>
    <path d="M221.202 116.201H199.106C198.41 116.201 197.841 115.632 197.841 114.935V114.935C197.841 114.239 198.41 113.67 199.106 113.67H221.202C221.898 113.67 222.467 114.239 222.467 114.935V114.935C222.467 115.632 221.898 116.201 221.202 116.201Z" fill="#E3E5FC"/>
    <path d="M221.202 122.346H199.106C198.41 122.346 197.841 121.778 197.841 121.081V121.081C197.841 120.385 198.41 119.816 199.106 119.816H221.202C221.898 119.816 222.467 120.385 222.467 121.081V121.081C222.467 121.778 221.898 122.346 221.202 122.346Z" fill="#E3E5FC"/>
    <path d="M221.202 128.492H199.106C198.41 128.492 197.841 127.924 197.841 127.227V127.227C197.841 126.53 198.41 125.962 199.106 125.962H221.202C221.898 125.962 222.467 126.53 222.467 127.227V127.227C222.467 127.924 221.898 128.492 221.202 128.492Z" fill="#E3E5FC"/>
    <path d="M278.21 109.955H256.115C255.418 109.955 254.85 109.387 254.85 108.69V108.69C254.85 107.994 255.418 107.425 256.115 107.425H278.21C278.907 107.425 279.475 107.994 279.475 108.69V108.69C279.473 109.387 278.904 109.955 278.21 109.955Z" fill="#E3E5FC"/>
    <path d="M278.21 116.101H256.115C255.418 116.101 254.85 115.533 254.85 114.836V114.836C254.85 114.14 255.418 113.571 256.115 113.571H278.21C278.907 113.571 279.475 114.14 279.475 114.836V114.836C279.473 115.533 278.904 116.101 278.21 116.101Z" fill="#E3E5FC"/>
    <path d="M278.21 122.247H256.115C255.418 122.247 254.85 121.678 254.85 120.982V120.982C254.85 120.285 255.418 119.717 256.115 119.717H278.21C278.907 119.717 279.475 120.285 279.475 120.982V120.982C279.473 121.678 278.904 122.247 278.21 122.247Z" fill="#E3E5FC"/>
    <path d="M278.21 128.393H256.115C255.418 128.393 254.85 127.824 254.85 127.127V127.127C254.85 126.431 255.418 125.862 256.115 125.862H278.21C278.907 125.862 279.475 126.431 279.475 127.127V127.127C279.473 127.824 278.904 128.393 278.21 128.393Z" fill="#E3E5FC"/>
    <path d="M278.21 150.895H256.115C255.418 150.895 254.85 150.327 254.85 149.63V149.63C254.85 148.934 255.418 148.365 256.115 148.365H278.21C278.907 148.365 279.475 148.934 279.475 149.63V149.63C279.473 150.327 278.904 150.895 278.21 150.895Z" fill="#E3E5FC"/>
    <path d="M278.21 157.041H256.115C255.418 157.041 254.85 156.473 254.85 155.776V155.776C254.85 155.079 255.418 154.511 256.115 154.511H278.21C278.907 154.511 279.475 155.079 279.475 155.776V155.776C279.473 156.473 278.904 157.041 278.21 157.041Z" fill="#E3E5FC"/>
    <path d="M278.21 163.187H256.115C255.418 163.187 254.85 162.618 254.85 161.922V161.922C254.85 161.225 255.418 160.657 256.115 160.657H278.21C278.907 160.657 279.475 161.225 279.475 161.922V161.922C279.473 162.618 278.904 163.187 278.21 163.187Z" fill="#E3E5FC"/>
    <path d="M278.21 169.333H256.115C255.418 169.333 254.85 168.764 254.85 168.068V168.068C254.85 167.371 255.418 166.802 256.115 166.802H278.21C278.907 166.802 279.475 167.371 279.475 168.068V168.068C279.473 168.764 278.904 169.333 278.21 169.333Z" fill="#E3E5FC"/>
    <path d="M164.542 150.895H142.447C141.75 150.895 141.182 150.327 141.182 149.63V149.63C141.182 148.934 141.75 148.365 142.447 148.365H164.542C165.239 148.365 165.807 148.934 165.807 149.63V149.63C165.807 150.327 165.239 150.895 164.542 150.895Z" fill="#E3E5FC"/>
    <path d="M164.542 157.041H142.447C141.75 157.041 141.182 156.473 141.182 155.776V155.776C141.182 155.079 141.75 154.511 142.447 154.511H164.542C165.239 154.511 165.807 155.079 165.807 155.776V155.776C165.807 156.473 165.239 157.041 164.542 157.041Z" fill="#E3E5FC"/>
    <path d="M164.542 163.187H142.447C141.75 163.187 141.182 162.618 141.182 161.922V161.922C141.182 161.225 141.75 160.657 142.447 160.657H164.542C165.239 160.657 165.807 161.225 165.807 161.922V161.922C165.807 162.618 165.239 163.187 164.542 163.187Z" fill="#E3E5FC"/>
    <path d="M164.542 169.333H142.447C141.75 169.333 141.182 168.764 141.182 168.068V168.068C141.182 167.371 141.75 166.802 142.447 166.802H164.542C165.239 166.802 165.807 167.371 165.807 168.068V168.068C165.807 168.764 165.239 169.333 164.542 169.333Z" fill="#E3E5FC"/>
    <path d="M107.882 68.3283H85.7871C85.0906 68.3283 84.522 67.7597 84.522 67.0631V67.0631C84.522 66.3666 85.0906 65.798 85.7871 65.798H107.882C108.579 65.798 109.148 66.3666 109.148 67.0631V67.0631C109.148 67.7597 108.577 68.3283 107.882 68.3283Z" fill="#E3E5FC"/>
    <path d="M107.882 74.4739H85.7871C85.0906 74.4739 84.522 73.9053 84.522 73.2088V73.2088C84.522 72.5122 85.0906 71.9436 85.7871 71.9436H107.882C108.579 71.9436 109.148 72.5122 109.148 73.2088V73.2088C109.148 73.9053 108.577 74.4739 107.882 74.4739Z" fill="#E3E5FC"/>
    <path d="M107.882 80.6197H85.7871C85.0906 80.6197 84.522 80.0511 84.522 79.3545V79.3545C84.522 78.658 85.0906 78.0894 85.7871 78.0894H107.882C108.579 78.0894 109.148 78.658 109.148 79.3545V79.3545C109.148 80.0511 108.577 80.6197 107.882 80.6197Z" fill="#E3E5FC"/>
    <path d="M107.882 86.7654H85.7871C85.0906 86.7654 84.522 86.1968 84.522 85.5003V85.5003C84.522 84.8037 85.0906 84.2351 85.7871 84.2351H107.882C108.579 84.2351 109.148 84.8037 109.148 85.5003V85.5003C109.148 86.1968 108.577 86.7654 107.882 86.7654Z" fill="#E3E5FC"/>
    <path d="M255.16 127.639C254.639 127.118 253.786 127.118 253.267 127.639L236.325 144.581L238.218 146.474L255.16 129.532C255.681 129.011 255.681 128.158 255.16 127.639Z" fill="#F44E4E"/>
    <path d="M235.379 145.527L236.327 144.579L228.665 136.917C228.144 136.396 227.291 136.396 226.772 136.917C226.251 137.438 226.251 138.291 226.772 138.81L235.382 147.42C234.858 146.899 234.858 146.048 235.379 145.527Z" fill="#F44E4E"/>
    <path d="M235.38 145.527C234.858 146.048 234.858 146.901 235.38 147.42V147.42C235.901 147.941 236.754 147.941 237.273 147.42L238.22 146.472L236.327 144.579L235.38 145.527Z" fill="#F44E4E"/>
    <path d="M77.7036 167.324H38.5429C33.8163 167.324 29.9497 163.457 29.9497 158.73V158.73C29.9497 154.004 33.8163 150.137 38.5429 150.137H77.7036C82.4302 150.137 86.2968 154.004 86.2968 158.73V158.73C86.2968 163.457 82.4302 167.324 77.7036 167.324Z" fill="#A7ADFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M34.2852 158.73C34.2852 162.332 37.2159 165.262 40.8171 165.262C44.4183 165.262 47.349 162.332 47.349 158.73C47.349 155.129 44.4183 152.198 40.8171 152.198C37.2159 152.198 34.2852 155.129 34.2852 158.73ZM35.1731 158.73C35.1731 155.62 37.7057 153.087 40.8165 153.087C43.9297 153.087 46.46 155.62 46.46 158.73C46.46 161.841 43.9273 164.374 40.8165 164.374C37.7034 164.374 35.1731 161.841 35.1731 158.73Z" fill="white"/>
    <rect x="40.1987" y="161.818" width="0.888456" height="1.7011" fill="white"/>
    <rect x="40.1987" y="153.964" width="0.888456" height="1.7011" fill="white"/>
    <rect x="40.52" y="158.394" width="4.75006" height="0.888416" transform="rotate(-40.2054 40.52 158.394)" fill="white"/>
    <path d="M47.7539 142.826H8.59315C3.86656 142.826 0 138.96 0 134.233V134.233C0 129.506 3.86656 125.64 8.59315 125.64H47.7539C52.4805 125.64 56.3471 129.506 56.3471 134.233V134.233C56.3471 138.957 52.4805 142.826 47.7539 142.826Z" fill="#E3E5FC"/>
    <path d="M19.3164 129.39C19.108 129.182 18.7692 129.182 18.563 129.39L11.8179 136.135L12.5713 136.889L19.3164 130.144C19.5249 129.937 19.5249 129.599 19.3164 129.39Z" fill="#A7ADFF"/>
    <path d="M11.4411 136.512L11.8178 136.135L8.76622 133.084C8.55773 132.875 8.21894 132.875 8.01281 133.084C7.80432 133.292 7.80432 133.631 8.01281 133.837L11.4411 137.265C11.2349 137.059 11.2349 136.72 11.4411 136.512Z" fill="#A7ADFF"/>
    <path d="M11.4415 136.512C11.233 136.72 11.233 137.059 11.4415 137.265V137.265C11.65 137.474 11.9888 137.474 12.1949 137.265L12.5716 136.888L11.8182 136.135L11.4415 136.512Z" fill="#A7ADFF"/>
    <path d="M277.331 151.049C277.331 151.049 271.732 160.92 262.71 158.448C253.688 155.977 247.431 142.968 244.737 144.416C242.044 145.863 235.602 143.629 242.802 141.61C246.107 140.684 246.602 140.824 246.981 141.096C251.968 144.676 257.785 150.241 260.832 152.852C264.111 155.662 268.205 153.639 271.95 151.53C275.696 149.424 289.968 144.949 277.331 151.049Z" fill="#EAC2AB"/>
    <path d="M283.394 132.57L284.681 141.99C286.955 142.658 289.338 143.935 291.734 143.932C291.625 142.186 289.914 140.187 289.128 138.538C288.175 136.538 290.506 132.003 290.613 129.76C290.89 123.896 283.394 132.57 283.394 132.57Z" fill="#D4A79A"/>
    <path d="M281.31 118.359C280.165 118.051 276.893 119.278 276.36 120.337C275.069 122.898 276.048 125.879 275.74 128.888C275.533 130.906 276.974 135.036 278.54 136.671C280.857 139.094 283.776 135.818 285.527 133.794C287.996 130.942 289.071 127.149 288.735 123.391C288.614 122.041 288.247 120.617 287.237 119.712C286.264 118.84 284.892 118.615 283.598 118.425C282.793 118.309 282.094 118.57 281.31 118.359Z" fill="#EAC2AB"/>
    <path d="M214.812 203.125L208.029 210.872L210.387 213.405L216.983 204.902C216.985 204.902 215.897 203.333 214.812 203.125Z" fill="#EAC2AB"/>
    <path d="M192.975 206.338C192.975 206.338 194.967 208.517 199.474 211.441C203.98 214.364 209.126 217.411 210.462 217.238C211.798 217.063 215.658 211.474 214.094 210.614C212.528 209.756 210.678 211.687 209.024 208.152C207.607 205.124 203.928 207.354 199.332 205.968C194.735 204.582 192.875 204.018 192.975 206.338Z" fill="#FDC510"/>
    <path d="M237.813 178.402C237.097 178.397 236.358 178.405 235.702 178.686C234.972 178.999 234.425 179.613 233.899 180.208C230.139 184.451 226.251 188.58 222.242 192.589C218.347 196.482 214.26 200.315 209.339 202.791C209.747 203.582 210.638 203.971 211.446 204.345C214.77 205.885 217.743 208.174 220.081 210.993C220.299 208.816 221.313 206.729 222.887 205.21C224.01 204.125 225.384 203.343 226.694 202.497C232.6 198.69 237.455 193.456 243.177 189.379C243.432 189.196 243.698 189.012 244.001 188.926C244.399 188.813 244.823 188.879 245.233 188.945C254.2 190.381 263.275 191.156 272.356 191.265C274.455 191.291 277.075 190.928 277.739 188.936C280.421 180.885 252.552 179.153 248.955 178.869C245.418 178.589 241.343 177.938 237.813 178.402Z" fill="#4B369D"/>
    <path d="M283.372 140.322C279.691 141.715 275.566 146.453 274.246 148.406C270.223 154.359 272.699 159.865 266.785 168.518C266.217 169.352 266.409 170.565 266.778 171.503C267.323 172.884 268.84 173.569 270.195 174.176C275.516 176.559 280.454 179.795 284.761 183.728C287.184 172.036 288.72 160.159 289.35 148.235C289.452 146.318 289.516 144.319 288.732 142.546C287.533 139.836 285.528 139.504 283.372 140.322Z" fill="#F44E4E"/>
    <path d="M290.295 145.742C292.262 150.49 290.774 156.056 291.622 161.18C292.47 166.305 292.259 171.318 293.453 176.386C294.228 179.677 294.877 182.991 295.368 186.327C295.403 186.576 295.448 186.841 295.631 187.028C295.835 187.237 296.161 187.296 296.465 187.346C299.294 187.803 304.224 187.694 305.937 190.075C303.063 191.222 299.403 190.817 296.496 189.836C297.014 190.364 297.533 190.89 298.052 191.419C296.879 191.987 295.415 191.473 294.498 190.549C293.581 189.623 293.091 188.372 292.624 187.154C290.035 180.411 287.239 173.763 286.156 166.57C285.545 162.502 285.562 158.358 284.678 154.331C284.405 153.085 284.045 151.841 284.059 150.571C284.086 148.531 285.142 146.519 286.872 145.221C287.215 144.963 287.597 144.726 288.033 144.638C288.829 144.475 289.663 144.847 290.21 145.396C290.757 145.946 291.07 146.652 291.359 147.344" fill="#EAC2AB"/>
    <path d="M240.487 196.726L239.404 206.965L242.79 207.678L243.285 196.927C243.285 196.929 241.492 196.273 240.487 196.726Z" fill="#EAC2AB"/>
    <path d="M226.471 212.396C226.471 212.396 229.008 212.957 233.73 212.782C238.452 212.606 243.7 212.199 244.572 211.396C245.444 210.595 245.396 204.617 243.835 204.79C242.274 204.963 241.918 207.292 238.94 205.582C236.388 204.118 234.867 207.584 230.859 208.91C226.85 210.242 225.225 210.775 226.471 212.396Z" fill="#FDC510"/>
    <path d="M283.775 173.68C285.102 174.213 286.462 174.945 287.123 176.215C287.502 176.945 287.611 177.784 287.67 178.606C287.907 181.807 287.386 185.325 285.081 187.559C283.953 188.654 282.498 189.341 281.037 189.919C277.575 191.286 273.761 192.143 270.131 191.317C266.141 190.407 262.869 187.583 260.133 184.538C257.809 181.951 255.738 179.132 253.964 176.139C252.971 174.467 251.902 172.595 250.052 171.998C248.597 176.407 249.659 181.224 249.156 185.839C248.872 188.459 248.081 191.004 247.82 193.627C247.56 196.249 247.905 199.095 249.607 201.109C248.178 202.464 245.894 201.919 243.961 201.559C241.549 201.111 239.059 201.104 236.645 201.533C236.031 201.642 235.327 201.758 234.846 201.362C234.451 201.038 234.354 200.469 234.375 199.955C234.453 198.228 235.548 196.733 236.107 195.096C236.765 193.162 236.661 191.07 236.763 189.03C237.097 182.231 239.765 175.692 239.973 168.887C240.033 166.937 239.89 164.964 240.293 163.057C240.696 161.149 241.764 159.247 243.548 158.46C245.638 157.541 248.448 157.676 250.173 159.169C251.234 160.088 262.317 167.207 271.865 171.754C275.692 173.578 279.847 172.1 283.775 173.68Z" fill="#5A54FF"/>
    <path d="M306.105 160.953C305.693 160.927 305.307 160.844 305.039 160.552C304.574 160.045 304.731 159.256 304.645 158.574C304.458 157.077 303.236 155.989 303.148 154.452C303.046 152.661 304.127 151.026 303.56 149.201C302.989 147.363 301.438 145.588 300.184 144.179C299.154 143.018 297.706 142.101 297.033 140.658C296.401 139.3 296.898 137.782 297.111 136.389C297.344 134.861 296.656 134.067 296.521 132.615C296.429 131.629 296.962 130.707 296.874 129.729C297.01 129.134 297.135 128.518 297.227 127.921C297.625 125.393 295.121 124.019 293.875 122.299C292.572 120.498 293.847 117.38 292.34 115.253C290.331 112.417 286.087 112.024 282.943 112.647C280.629 113.106 279.643 114.549 277.968 115.98C277.255 116.589 276.236 116.71 275.431 117.153C274.246 117.802 273.464 118.79 272.905 120.065C272.763 120.387 272.687 120.747 272.699 121.095C272.711 121.429 272.801 121.754 272.997 122.024C273.578 122.832 274.791 122.853 275.736 122.538C279.548 121.268 284.746 121.489 284.86 126.798C284.91 129.065 283.91 131.221 283.623 133.453C283.304 135.936 286.469 137.012 287.443 138.86C288.39 140.656 289.037 143.001 289.011 145.055C289.011 145.055 287.409 147.815 287.566 150.81C287.689 153.182 289.182 153.175 290.447 154.035C291.224 154.563 290.788 156.807 290.982 157.596C291.357 159.117 292.61 160.124 293.105 161.578C293.638 163.149 293.577 164.168 295.109 165.222C298.343 167.449 302.696 166.909 305.832 164.765C306.56 164.267 308.289 163.068 308.187 161.988C308.071 160.792 307.027 161.014 306.105 160.953Z" fill="#4B369D"/>
  </svg>
</template>
<script>
  import iconMixin from '@/components/icons/iconMixin'

  export default {
    name: 'EmptySprint',

    mixins: [iconMixin],

    props: {
      viewBox: {
        type: String,
        default: '0 0 12 12'
      }
    },

    data () {
      return {
        defaultBackground: '#2C3E50',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

<template>
  <task-item
    :task="task"
    :index="index"
    v-draggable-item="draggableItem"
    :editable="editable"
    :class="taskClasses"
    :open-by-click="true"
    :display-stage="false"
    :with-add-button="allowCreate"
    @open="onOpenTask"
    @create="onAddTask"
  />
</template>
<script>
  import TaskItem from "@/components/tasks/TaskItem"

  export default {
    name: 'TaskStageItem',

    components: { TaskItem },

    props: {
      task: {
        type: Object,
        required: true
      },

      index: {
        type: Number,
        required: true
      },

      allowCreate: {
        type: Boolean,
        default: true
      },

      editable: {
        type: Boolean,
        default: true
      },

      draggable: {
        type: Boolean,
        default: true
      }
    },

    data () {
      return {
        isDragging: false,
        showAddButton: false
      }
    },

    computed: {
      draggableItem () {
        return {
          list: `stage-tasks`,
          item: this.task,
          dragSelector: '.task-item__block',
          skip: !this.draggable
        }
      },

      taskClasses () {
        const classes = []

        if (this.task.dragged) {
          classes.push('task-item_dragged')
        }

        if (this.task.muted) {
          classes.push('task-item_muted')
        }

        return classes
      }
    },

    methods: {
      onOpenTask () {
        this.$emit('open', this.task)
      },

      onAddTask () {
        this.$emit('create', this.task)
      }
    }
  }
</script>

<template>
  <div class="task-spheres">
    <ui-dropdown
      :options="sphereList"
      :value="value"
      :multiple="multiple"
      :width-by-reference="widthByReference"
      :width-by-popover="false"
      :popover-class="popperClass"
      :reference-class="dropdownClass"
      @input="onSphereChange"
      :placement="placement"
      :click-fn="onChangeOption"
    >
      <template v-slot:default>
        <slot :selected-sphere="selectedSphere">
          <div class="task-spheres__selected">
            {{ selectedSphere.name }}
            <chevron-down class="task-spheres__chevron-icon"/>
          </div>
        </slot>
      </template>

      <template v-slot:option="{ option }">
        <div :class="optionClasses(option)">
          {{ option.name }}
        </div>
      </template>
    </ui-dropdown>
  </div>
</template>
<script>
import UiDropdown from "./UiDropdown"
import ChevronDown from "../icons/ChevronDown"

export default {
  name: "TaskSpheres",

  components: { UiDropdown, ChevronDown },

  props: {
    spheres: {
      type: Array,
      required: true
    },

    value: {
      required: true
    },

    nullableTitle: {
      type: String,
      default: 'tasks.allTasks'
    },

    parentsActive: {
      type: Boolean,
      default: true
    },

    acceptNull: {
      type: Boolean,
      default: true
    },

    multiple: {
      type: Boolean,
      default: false
    },

    widthByReference: {
      type: Boolean,
      default: true
    },

    popperClass: {
      type: String,
      default: ''
    },

    dropdownClass: {
      type: String,
      default: ''
    },

    placement: {
      type: String,
      default: 'bottom'
    },

    uiStyle: {
      type: String,
      default: 'slide'
    }
  },

  data () {
    const sphereList = [...this.spheres]

    if (this.acceptNull) {
      sphereList.unshift({
        id: null,
        name: this.$t(this.nullableTitle)
      })
    }

    const tempValue = this.multiple
      ? [...this.value]
      : []

    return {
      sphereList,
      tempValue
    }
  },

  computed: {
    selectedSphere () {
      if (!this.acceptNull && this.value === null) {
        return {
          id: null,
          name: this.$t(this.nullableTitle)
        }
      }

      if (this.multiple) {
        return this.sphereList.filter(item => this.tempValue.find(sphere => sphere.id === item.id))
      }

      return this.sphereList.find(item => item.id === this.value)
    }
  },

  methods: {
    optionClasses (option) {
      const classes = { 'dropdown-sphere-item': true }

      if (option.parent_id) {
        classes['ml-2'] = true
      }

      if (false === this.isAvailable(option)) {
        classes['dropdown-sphere-item_inactive'] = true
      }

      return classes
    },

    isAvailable (sphere) {
      return this.parentsActive || !sphere.is_parent
    },

    onSphereChange (val) {
      if (false === this.isAvailable(val)) {
        return
      }

      if (this.multiple) {
        this.tempValue = val
      }

      this.$emit('input', val)
    },

    onChangeOption (sphere) {
      return this.isAvailable(sphere)
    }
  }
}
</script>
<style lang="sass">
  .task-spheres
    &__selected
      font-size: 22px
      color: var(--text-primary-color)
      cursor: pointer
      font-weight: 500
      display: flex
      align-items: center

    &__reference-bordered
      border: 1px solid transparent

      &.ui-dropdown__reference:hover
        border: 1px solid var(--block-border-bg)
        background: var(--block-bg-color)

    &__chevron-icon
      margin-left: 10px
      transition: all .2s ease-in
      transform: translate(0px, 2px)

      path
        fill: var(--text-primary-color)

    &__item
      padding: 6px 12px
      font-size: 16px

  .ui-dropdown__reference_opened
    .task-spheres__chevron-icon
      transform: rotate(-180deg) translate(0px, 0px)

  .ui-dropdown__option
    .dropdown-sphere-item_inactive
      color: var(--grey-color-100)
      cursor: default


  @media (max-width: 1280px)
    .task-spheres
      &__selected
        font-size: 20px

</style>

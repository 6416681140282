<template>
<el-dialog
  custom-class="tour-dialog"
  :visible="show"
  :show-close="false"
  width="400px"
>
  <div class="tour-started tour-started_finished">
    <div class="tour-started__bg-complete"></div>
    <div class="tour-started__title">
      {{ $t('tour.completed.title') }}
    </div>
    <div class="tour-started__description" v-html="$t('tour.completed.description')"></div>

    <div class="tour-emojis">
      <el-tooltip
        v-for="item in scores"
        :key="item.value"
        effect="dark"
        :content="$t(item.tooltip)"
        placement="top"
        transition="sw-slide-top"
        :hide-after="0"
        :open-delay="200"
      >
        <button
          class="tour-emoji-item"
          :class="{'tour-emoji-item_active': score === item.value}"
          @click="setScore(item.value)"
        >
          {{ item.label }}
        </button>
      </el-tooltip>
    </div>

    <transition name="input-error-anim">
      <div class="text-field__error" v-if="emojiError">
        {{ $t('tour.completed.emojiError') }}
      </div>
    </transition>

    <div class="tour-feedback">
      <textarea v-model="feedback" class="tour-feedback__field" :placeholder="$t('tour.completed.feedbackPlaceholder')"></textarea>
    </div>

    <div class="tour-started__actions">
      <ui-button ui-class="block" @click="onConfirm">{{ $t('tour.completed.confirm') }}</ui-button>
    </div>
  </div>
</el-dialog>
</template>
<script>
import { createNamespacedHelpers } from "vuex"
import UiButton from 'scorework-ui/src/components/UiButton'

const { mapActions: tourActions } = createNamespacedHelpers('tour')

export default {
  name: 'TourCompleted',

  components: { UiButton },

  props: {
    show: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      emojiError: false,
      feedback: '',
      score: 0,
      scores: [
        { label: '😡', tooltip: 'tour.completed.feedback.worst', value: 1 },
        { label: '🙁', tooltip: 'tour.completed.feedback.bad', value: 2 },
        { label: '😐', tooltip: 'tour.completed.feedback.ok', value: 3 },
        { label: '😀', tooltip: 'tour.completed.feedback.good', value: 4 },
        { label: '😍', tooltip: 'tour.completed.feedback.perfect', value: 5 },
      ]
    }
  },

  methods: {
    ...tourActions(['completeTour']),

    onConfirm () {
      if (!this.score) {
        this.emojiError = true
        return
      }

      this.completeTour({
        last_step: 'complete',
        score: this.score,
        feedback: this.feedback
      }).then(() => {
        this.$emit('submit')
      })
    },

    setScore (val) {
      this.score = val
      this.emojiError = false
    }
  }
}
</script>
<style lang="sass">
.tour-started_finished
  .tour-started__title
    margin-top: 0

  .tour-started__description
    width: 340px

.tour-started
  &__bg-complete
    background: url("../../assets/images/tour_complete.png")
    width: 250px
    height: 100px
    background-size: cover
    margin-bottom: 24px

.tour-emojis
  display: flex
  align-items: center
  justify-content: space-around
  width: 340px
  margin-top: 12px

.tour-emoji-item
  background: none
  border: 0
  font-size: 30px
  display: flex
  align-items: center
  justify-content: center
  width: 42px
  height: 42px
  border-radius: 21px
  cursor: pointer
  transition: all ease-in .2s
  opacity: 0.66

  &:hover, &_active
    font-size: 40px
    opacity: 1

.tour-feedback
  margin: 24px 0 12px
  width: 340px

  &__field
    background: var(--block-bg-color)
    border: 1px solid var(--grey-light-color-100)
    border-radius: 16px
    box-sizing: border-box
    color: var(--text-primary-color)
    font-family: 'Inter', Helvetica, Arial, sans-serif
    font-size: 14px
    height: 130px
    line-height: 21px
    padding: 16px
    resize: none
    transition: all ease-in .2s
    width: 100%

    &:hover, &:focus
      box-shadow: 0 0 0px 3px rgba(101, 158, 255, 0.16)

    &:focus-visible
      outline: none

    &::placeholder
      color: var(--grey-color-100)

</style>
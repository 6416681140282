<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="path-fill" fill-rule="evenodd" clip-rule="evenodd" d="M2 0.75C1.58579 0.75 1.25 1.08579 1.25 1.5C1.25 1.91421 1.58579 2.25 2 2.25H2.04669C2.21711 3.60138 2.82695 4.63888 3.42383 5.35514C3.6345 5.60794 3.84507 5.82277 4.03708 6C3.84507 6.17723 3.6345 6.39206 3.42383 6.64486C2.82695 7.36112 2.21711 8.39862 2.04669 9.75H2C1.58579 9.75 1.25 10.0858 1.25 10.5C1.25 10.9142 1.58579 11.25 2 11.25H2.75H9.25H10C10.4142 11.25 10.75 10.9142 10.75 10.5C10.75 10.0858 10.4142 9.75 10 9.75H9.95331C9.78289 8.39862 9.17305 7.36112 8.57617 6.64486C8.3655 6.39206 8.15493 6.17723 7.96292 6C8.15493 5.82277 8.3655 5.60794 8.57617 5.35514C9.17305 4.63888 9.78289 3.60138 9.95331 2.25H10C10.4142 2.25 10.75 1.91421 10.75 1.5C10.75 1.08579 10.4142 0.75 10 0.75H9.25H2.75H2ZM3.98118 3.5C3.7943 3.13521 3.64329 2.71881 3.56302 2.25H8.43699C8.35671 2.71881 8.2057 3.13521 8.01882 3.5H3.98118ZM5.80903 6.5C5.76014 6.55468 5.70285 6.60259 5.63836 6.64162L5.63774 6.64201L5.62621 6.64928C5.61427 6.65693 5.59405 6.67013 5.56672 6.68886C5.51201 6.72638 5.42931 6.78577 5.3279 6.8669C5.12427 7.02981 4.85007 7.27646 4.57617 7.60514C4.14191 8.12624 3.71898 8.83919 3.56302 9.75H8.43699C8.28102 8.83919 7.85809 8.12624 7.42383 7.60514C7.14993 7.27646 6.87573 7.02981 6.6721 6.8669C6.57069 6.78577 6.48799 6.72638 6.43328 6.68886C6.40596 6.67013 6.38573 6.65693 6.37379 6.64928L6.36226 6.64201L6.36235 6.64205C6.36258 6.64219 6.3628 6.64232 6.36303 6.64246L6.36313 6.64252L6.3635 6.64274C6.36335 6.64265 6.36319 6.64255 6.36303 6.64246L6.36236 6.64205L6.36235 6.64205C6.36211 6.64191 6.36188 6.64176 6.36164 6.64162C6.36161 6.6416 6.36158 6.64159 6.36156 6.64157C6.2971 6.60255 6.23984 6.55466 6.19097 6.5H5.80903Z" :fill="backgroundColor"/>
    </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'SandclockIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 10,
        defaultHeight: 10
      }
    }
  }
</script>

import tippy from 'tippy.js'

export const createPopoverInstance = ({
  editor,
  element,
  tippyOptions = {},
  container = null,
}) => {
  let preventHide = false
  let tippyInstance = null
  let isShown = false
  let referenceEl = null

  const mousedownHandler = () => {
    preventHide = true
  }

  const focusHandler = () => {
    // we use `setTimeout` to make sure `selection` is already updated
   // setTimeout(() => update(editor.view))
  }

  const blurHandler = ({ event }) => {
    if (preventHide) {
      preventHide = false
      return
    }

    if (event?.relatedTarget && element.parentNode?.contains(event.relatedTarget)) {
      return
    }

    hide()
  }

  const tippyBlurHandler = (event) => {
    blurHandler({ event })
  }

  const createPopover = () => {
    const { element: editorElement } = editor.options
    const editorIsAttached = !!editorElement.parentElement

    if (tippyInstance || !editorIsAttached) {
      return
    }

    tippyInstance = tippy(container || editorElement, {
      duration: 0,
      getReferenceClientRect: null,
      content: element,
      interactive: true,
      trigger: 'manual',
      placement: 'right',
      hideOnClick: 'toggle',
      ...tippyOptions,
      onHidden () {
        isShown = false
      },
      onShown () {
        isShown = true
      },
      onClickOutside () {
      }
    })

    // maybe we have to hide tippy on its own blur event as well
    if (tippyInstance.popper.firstChild) {
      tippyInstance.popper.firstChild.addEventListener('blur', tippyBlurHandler)
    }
  }

  const show = (el) => {
    referenceEl = el

    if (!tippyInstance) {
      createPopover()
    }

    tippyInstance?.setProps({
      getReferenceClientRect:
        tippyOptions?.getReferenceClientRect || (() => {
          return el.getBoundingClientRect()
        }),
    })

    tippyInstance?.show()

    return true
  }

  const hide = (el = null) => {
    if (el && el !== referenceEl) {
      return
    }

    tippyInstance?.hide()
    return false
  }

  const toggle = (el) => {
    return isShown && el === referenceEl ? hide(el) : show(el)
  }

  const destroy = () => {
    if (tippyInstance?.popper.firstChild) {
      tippyInstance.popper.firstChild.removeEventListener(
        'blur',
        tippyBlurHandler,
      )
    }

    tippyInstance?.destroy()
    element.removeEventListener('mousedown', mousedownHandler, { capture: true })
    editor.off('focus', focusHandler)
    editor.off('blur', blurHandler)
  }

  element.addEventListener('mousedown', mousedownHandler, { capture: true })
  editor.on('focus', focusHandler)
  editor.on('blur', blurHandler)

  // Detaches menu content from its current parent
  element.remove()
  element.style.visibility = 'visible'

  createPopover()

  return {
    show,
    hide,
    destroy,
    isShown: () => tippyInstance?.state.isShown,
    toggle,
  }
}

<template>
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-fill" d="M11.6667 1.55556C11.8729 1.55556 12.0708 1.47361 12.2166 1.32775C12.3625 1.18189 12.4444 0.984057 12.4444 0.777778C12.4444 0.571498 12.3625 0.373667 12.2166 0.227806C12.0708 0.0819442 11.8729 0 11.6667 0H0.777778C0.571498 0 0.373668 0.0819442 0.227806 0.227806C0.0819444 0.373667 0 0.571498 0 0.777778C0 0.984057 0.0819444 1.18189 0.227806 1.32775C0.373668 1.47361 0.571498 1.55556 0.777778 1.55556H11.6667ZM2.37222 7.54678C2.44446 7.61909 2.53024 7.67646 2.62466 7.7156C2.71908 7.75474 2.82029 7.77489 2.9225 7.77489C3.02471 7.77489 3.12592 7.75474 3.22034 7.7156C3.31476 7.67646 3.40054 7.61909 3.47278 7.54678L5.44444 5.57433V13.2222C5.44444 13.4285 5.52639 13.6263 5.67225 13.7722C5.81811 13.9181 6.01594 14 6.22222 14C6.4285 14 6.62633 13.9181 6.77219 13.7722C6.91806 13.6263 7 13.4285 7 13.2222V5.57433L8.97244 7.54678C9.11839 7.69262 9.31629 7.77451 9.52261 7.77444C9.72893 7.77436 9.92677 7.69233 10.0726 7.54639C10.2185 7.40045 10.3003 7.20255 10.3003 6.99623C10.3002 6.7899 10.2182 6.59206 10.0722 6.44622L6.77211 3.14689C6.62626 3.00108 6.42846 2.91917 6.22222 2.91917C6.01598 2.91917 5.81819 3.00108 5.67233 3.14689L2.37222 6.44622C2.29991 6.51846 2.24254 6.60424 2.2034 6.69866C2.16426 6.79308 2.14411 6.89429 2.14411 6.9965C2.14411 7.09871 2.16426 7.19992 2.2034 7.29434C2.24254 7.38876 2.29991 7.47454 2.37222 7.54678Z" fill="#27272E"/>
  </svg>
</template>
<script>
import iconMixin from '../iconMixin'

export default {
  name: 'ArrowUpLineIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#9498B3',
      defaultWidth: 10,
      defaultHeight: 10
    }
  }
}
</script>

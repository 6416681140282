<template>
  <node-view-wrapper class="tableWrapper">
    <div :class="isFocused ? 'focused' : 'non-focused'" ref="reference">
      <table>
        <colgroup>
          <col v-for="(width, i) in colsWidth" :key="i" :style="{ width }"/>
        </colgroup>
        <div data-node-view-content style="display: contents;"/>
      </table>
    </div>
  </node-view-wrapper>
</template>
<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2'

export default {
  name: 'TableView',

  components: { NodeViewWrapper },

  props: nodeViewProps,

  data() {
    return {
      popover: null,
    }
  },

  watch: {
    isFocused (val) {
      return val ? this.popover.toggle(this.$refs.reference) : this.popover.hide(this.$refs.reference)
    }
  },

  computed: {
    colsWidth () {
      const cols = []

      const row = this.node.firstChild

      for (let i = 0, col = 0; i < row.childCount; i += 1) {
        const { colspan, colwidth } = row.child(i).attrs

        for (let j = 0; j < colspan; j += 1, col += 1) {
          const hasWidth = colwidth && colwidth[j]
          const cssWidth = hasWidth ? `${hasWidth}px` : ''

          cols.push(cssWidth)
        }
      }

      return cols
    },

    isFocused () {
      for (const dec of this.decorations) {
        if (dec.type.attrs.class === 'has-focus') {
          return true
        }
      }

      return false
    },
  },

  mounted () {
    this.popover = this.extension.options.getPopover()
  }
}
</script>

<template>
    <svg :width="iconWidth" :height="iconHeight" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.375 4.375V0.625C4.375 0.279822 4.65482 0 5 0C5.34518 0 5.625 0.279822 5.625 0.625V4.375H9.375C9.72018 4.375 10 4.65482 10 5C10 5.34518 9.72018 5.625 9.375 5.625H5.625V9.375C5.625 9.72018 5.34518 10 5 10C4.65482 10 4.375 9.72018 4.375 9.375V5.625H0.625C0.279822 5.625 0 5.34518 0 5C0 4.65482 0.279822 4.375 0.625 4.375H4.375Z" :fill="backgroundColor" class="path-fill"/>
    </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'Plus',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 10,
        defaultHeight: 10
      }
    }
  }
</script>

import { transformDateToObject } from '@/services/scoring';

export const createFeedTransformer = ({ members }) => (item) => {
  const transformed = {
    ...item,
    member: members.find(member => member.member_id === item.member_id),
    createdAt: transformDateToObject(item.created_at)
  }

  if (!transformed.payload) {
    transformed.payload = {}
  }

  if (item.type === 'task.assigned') {
    transformed.assignee = members.find((member) => member.member_id === item.payload.new)
  }

  return transformed
}

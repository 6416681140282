<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99966 1.33301C4.31776 1.33301 1.33301 4.31776 1.33301 7.99966C1.33301 11.6816 4.31776 14.6663 7.99966 14.6663C11.6816 14.6663 14.6663 11.6816 14.6663 7.99966C14.6663 4.31776 11.6816 1.33301 7.99966 1.33301ZM7.99966 13.4597C4.9842 13.4597 2.53966 11.0151 2.53966 7.99966C2.53966 4.9842 4.9842 2.53966 7.99966 2.53966C11.0136 2.54332 13.456 4.9857 13.4597 7.99966C13.4597 11.0151 11.0151 13.4597 7.99966 13.4597Z" fill="#8B92AB" class="path-fill"/>
    <path d="M6 7.33301H0V8.66637H6V7.33301Z" fill="#8B92AB" class="path-fill"/>
    <path d="M16 7.33301H10V8.66637H16V7.33301Z" fill="#8B92AB" class="path-fill"/>
    <path d="M8.66637 10H7.33301V16H8.66637V10Z" fill="#8B92AB" class="path-fill"/>
    <path d="M8.66637 0H7.33301V6H8.66637V0Z" fill="#8B92AB" class="path-fill"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'TargetIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#8B92AB',
        defaultWidth: 16,
        defaultHeight: 16
      }
    }
  }
</script>

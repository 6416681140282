import { createPopper } from "@popperjs/core"
import SuggestionPopover from "@/components/ui/textEditor/SuggestionPopover"

const nullableSuggestionObj = () => ({
  query: null,
  range: null,
  list: [],
  navigatedIndex: 0,
  insert: () => {},
  type: ''
})

export default {
  components: { SuggestionPopover },

  data () {
    return {
      suggestion: nullableSuggestionObj(),
      suggestPopover: null
    }
  },

  methods: {
    selectSuggestion (attrs) {
      const { insert, range } = this.suggestion
      insert({ range, attrs })

      this.destroySuggestPopover()
      this.editor.focus()
    },

    onSuggestionEnter (type, { items, query, range, command, decorationNode }) {
      this.suggestion = {
        ...this.suggestion,
        insert: command,
        list: items,
        range, query, type
      }

      if (query) {
        this.renderSuggestPopover(decorationNode)
      }
    },

    onSuggestionChange (type, { items, query, range, decorationNode }) {
      this.suggestion = {
        ...this.suggestion,
        list: items,
        navigatedIndex: 0,
        query,
        range
      }
      this.renderSuggestPopover(decorationNode)
    },

    onSuggestionExit () {
      this.suggestion = nullableSuggestionObj()
      this.destroySuggestPopover()
    },

    renderSuggestPopover (el) {
      if (this.suggestPopover) {
        return
      }

      console.log('da')
      this.suggestPopover = createPopper(el, this.suggestionEl, {
        placement: 'top'
      })
    },

    destroySuggestPopover () {
      if (this.suggestPopover) {
        this.suggestPopover.destroy()
        this.suggestPopover = null
      }
    },

    onSuggestionKeyDown ({ event }) {
      if (event.key === 'ArrowUp') {
        this.suggestionUpHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.suggestionDownHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.suggestionEnterHandler()
        return true
      }

      return false
    },

    suggestionUpHandler() {
      this.suggestion.navigatedIndex = ((this.suggestion.navigatedIndex + this.suggestion.list.length) - 1) % this.suggestion.list.length
    },

    suggestionDownHandler() {
      this.suggestion.navigatedIndex = (this.suggestion.navigatedIndex + 1) % this.suggestion.list.length
    },

    suggestionEnterHandler() {
      const { list, navigatedIndex } = this.suggestion
      const item = list[navigatedIndex]

      if (item) {
        this.selectSuggestion(item)
      }
    },
  }
}

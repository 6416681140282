<template>
  <el-tooltip
      popper-class="sidebar-menu__tooltip"
      effect="dark"
      :content="$t('layout.notifications')"
      placement="right"
      transition="sw-slide-right"
      :hide-after="0"
      :open-delay="200"
  >
    <div
        class="sidebar-menu__item"
        @click="onOpenNotifications"
    >
      <div class="sidebar-menu__item-counter" v-if="unseenNotifications">
        {{ unseenNotifications }}
      </div>
      <div class="sidebar-menu__item-icon">
        <bell/>
      </div>
    </div>
  </el-tooltip>
</template>
<script>
import { mapGetters } from 'vuex'
import Bell from "@/components/icons/Bell"

export default {
  name: 'NotificationCount',

  components: { Bell },

  computed: {
    ...mapGetters(['unseenNotifications'])
  },

  methods: {
    onOpenNotifications () {
      this.$emit('show-notifications')
    }
  }
}
</script>

<template>
  <el-dialog
    title="Сохранить черновик?"
    :visible="true"
    width="40%"
    :close-on-click-modal="false"
    :append-to-body="true"
    @close="onCancel"
  >
    <div>
      <div class="common-desc mb-4">
        Хотите ли вы сохранить черновик этой задачи, чтобы закончить ее описание позже?
        <br/>Если нет, то введенная информация будет утрачена.
      </div>

      <div class="fx fx_end fx_gap-x-2 mt-4">
        <ui-loading-button
            @click.prevent="onDelete"
            ui-class="size_md light"
            :loading="isProcessing"
        >
          Не сохранять
        </ui-loading-button>

        <ui-loading-button
            @click.prevent="onSave"
            ui-class="size_md"
            :loading="isProcessing"
        >
          Сохранить черновик
        </ui-loading-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import confirmationMixin from '@/components/tasks/mixins/confirmationMixin';

export default {
  name: 'SaveDraftModal',

  mixins: [confirmationMixin],

  data () {
    return {

    }
  },

  methods: {
    onCancel () {
      this.$emit('cancel')
    },

    onDelete () {
      this.$emit('delete')
    },

    onSave () {
      this.$emit('save')
    }
  }
}
</script>

<template>
  <div class="sw-popover">
    <template v-if="items.length">
      <div class="ui-dropdown__option-list">
        <div
          :class="{ 'is-selected': index === selectedIndex }"
          v-for="(item, index) in items"
          :key="item[idField]"
          @click="selectItem(index)"
          class="ui-dropdown__option"
        >
          <slot name="option" :item="item">
            {{ getLabel(item) }}
          </slot>
        </div>
      </div>
    </template>
    <div v-else class="suggestion-list__item is-empty">
      {{ $t('ui.noUsersFound') }}
    </div>
  </div>
</template>

<script>
import mentionList from '@/components/ui/textEditor/mentionList'

export default {
  name: 'MentionList',

  mixins: [mentionList]
}
</script>
import apiService from "@/services/api"
import {memberTransformer} from "@/transformers/members";

export const namespaced = true

export const state = {
  members: [],
}

export const mutations = {
  SET_MEMBERS (state, data) {
    state.members = data
  },

  SET_MEMBER (state, member) {
    state.members = state.members.map(item => {
      if (item.member_id !== member.member_id) {
        return item
      }

      return { ...item, ...member }
    }).filter(member => member.status !== 'inactive')
  },

  SET_PARTIAL_MEMBERS (state, members) {
    const updatedMemberIds = []

    const updatedMembers = state.members.map((member) => {
      const item = members.find((item) => item.member_id === member.member_id)
      if (item) {
        updatedMemberIds.push(item.member_id)

        return item
      }

      return member
    })

    updatedMembers.push(...members.filter((item) => !updatedMemberIds.includes(item.member_id)))

    state.members = updatedMembers
  },
}

export const actions = {
  async fetchWorkspaceMembers ({ commit }) {
    try {
      const { data } = await apiService.get(`members`)
      commit('SET_MEMBERS', data.map(memberTransformer))
    } catch ({ response }) {
      console.log(response)
    }
  },

  async updateMember ({ commit }, { memberId, data: memberData }) {
    const { data } = await apiService.patch(`members/${memberId}`, memberData)
    commit('SET_MEMBER', memberTransformer(data))
    return data
  },

  async removeMember (ctx, { memberId }) {
    const { data } = await apiService.delete(`members/${memberId}`)
    return data
  },

  async inviteWorkspaceMembers ({ commit }, { members, new_members = [], projectCodes }) {
    const { data } = await apiService.post(`members`, { members, new_members, project_codes: projectCodes })
    commit('SET_PARTIAL_MEMBERS', data.map(memberTransformer))

    return data
  },
}

export const getters = {
  members: ({ members }) => members,

  activeMembers: ({ members }) => members.filter(member => member.status === 'active'),

  invitedMembers: ({ members }) => members.filter(member => member.status === 'invited'),
}

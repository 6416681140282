<template>
  <ui-form :model="form" :rules="rules" :submit-fn="submitFn" ref="form">
    <div class="form-control">
      <text-field
          prop="email"
          v-model="form.email"
          :label="$t('auth.enterYourEmail')"
          :focused="true"
      />
    </div>

    <div class="form-control form-control_inline">
      <div class="form-control__item">
        <text-field
            prop="first_name"
            v-model="form.first_name"
            :label="$t('auth.yourFirstName')"
        />
      </div>

      <div class="form-control__item">
        <text-field
            prop="last_name"
            v-model="form.last_name"
            :label="$t('auth.yourLastName')"
        />
      </div>
    </div>

    <div class="form-control form-control_inline">
      <div class="form-control__item mr-2">
        <text-field
            prop="password"
            type="password"
            v-model="form.password"
            :label="$t('auth.enterYourPassword')"
        />
      </div>
      <div class="form-control__item">
        <text-field
            prop="password_confirmation"
            type="password"
            v-model="form.password_confirmation"
            :label="$t('auth.enterYourPasswordAgain')"
        />
      </div>
    </div>

    <div class="form-control mt-4">
      <ui-button @click.prevent="onSubmit" ui-class="block" :disabled="isSubmitted" class="auth-submit-btn">{{ submitTitle }}</ui-button>
    </div>
  </ui-form>
</template>
<script>
import { mapActions } from 'vuex'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

import UiForm from 'scorework-ui/src/components/form/UiForm'
import TextField from 'scorework-ui/src/components/TextField'
import UiButton from 'scorework-ui/src/components/UiButton'
import { handleAsyncErrors } from 'scorework-ui/src/components/form/helpers'

export default {
  name: 'RegisterForm',

  components: { TextField, UiButton, UiForm },

  props: {
    code: {
      type: String,
      required: true
    },

    presetValues: {
      type: Object,
      default: () => ({})
    },

    submitTitle: {
      type: String,
      default: 'auth.createAccount'
    }
  },

  data () {
    return {
      isSubmitted: false,

      form: {
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        password_confirmation: '',
        invitation_code: this.code,
        spheres: [],
        ...this.presetValues
      },

      rules: {
        email: {
          rules: { required },
          messages: {
            required: this.$t('auth.emailIsRequired')
          }
        },

        first_name: {
          rules: { required },
          messages: {
            required: this.$t('auth.firstNameIsRequired')
          }
        },

        last_name: {
          rules: { required },
          messages: {
            required: this.$t('auth.lastNameIsRequired')
          }
        },

        password: {
          rules: { required, minLength: minLength(6) },
          messages: {
            required: this.$t('auth.passwordIsRequired'),
            minLength: this.$t('auth.passwordMustBeAtLeast6Characters')
          }
        },

        password_confirmation: {
          rules: { required, minLength: minLength(6), sameAsPassword: sameAs('password') },
          messages: {
            required: this.$t('auth.enterConfirmation'),
            minLength: this.$t('auth.passwordMustBeAtLeast6Characters'),
            sameAsPassword: this.$t('auth.confirmationDoesNotMatch')
          }
        }
      }
    }
  },

  methods: {
    ...mapActions(['register', 'fetchInit']),

    submitFn () {
      this.isSubmitted = true

      this.register(this.form)
          .then(() => {
            this.fetchInit({})
            this.$router.push({ name: 'onboarding.spheres' })
          })
          .catch(handleAsyncErrors(this.rules, () => this.$refs.form.validate()))
          .finally(() => {
            this.isSubmitted = false
          })
    },

    onSubmit () {
      this.$refs.form.submit()
    }
  }
}
</script>
<style lang="sass">
.block_auth
  .block__body
    padding: 24px 32px

.form-control_inline
  display: flex
  flex-flow: row nowrap

  .form-control__item
    flex: 1
    margin-right: 24px

    &:last-child
      margin-right: 0
</style>

export const defineNodeByCursor = (view, { pos, inside }, currentPos = null) => {
  // Мы в margin-зоне
  if (inside === -1) {
    // Если та же позиция, то выходим
    if (currentPos === pos) {
      return {
        changed: false,
        pos,
        node: null,
      }
    }

    const node = view.nodeDOM(pos)

    return {
      changed: true,
      pos: node ? pos : null,
      node,
    }
  }

  // Мы находимся внутри элемента, что уже подсвечен
  if (inside === currentPos) {
    return {
      changed: false,
    }
  }

  let node = view.nodeDOM(inside)
  node = getRootChildFromDom(node, view.dom)
  if (!node) {
    return {
      changed: true,
      pos: null,
      node,
    }
  }

  const nodePos = view.posAtDOM(node)

  return {
    changed: true,
    pos: nodePos === inside + 1 ? inside : nodePos,
    node,
  }
}

const getRootChildFromDom = (node, root) => {
  let curNode = node
  while (curNode.parentNode !== root) {
    curNode = curNode.parentNode
  }

  return curNode
}

<template>
  <div class="pages-crumbs">
    <router-link class="pages-crumbs-item pages-crumbs-item_link" :to="rootUrl">
      <div class="pages-crumbs-item__label">
        <dashboard-icon class="pages-crumbs-item__home"/>

        {{ projectName }}
      </div>
    </router-link>

    <div class="pages-crumbs-div">
      /
    </div>

    <template v-if="innerParents.length > 0">
      <el-popover
        placement="bottom"
        trigger="click"
      >
        <div class="ui-dropdown__option-list">
          <router-link
            v-for="parent in innerParents"
            :key="parent.code"
            class="pages-crumbs-item pages-crumbs-item_link"
            :to="{ name: 'pages.detail', params: { project: parent.project, code: parent.code} }"
          >
            <div class="pages-crumbs-item__label">
              {{ parent.icon.value }} {{ parent.name }}
            </div>
          </router-link>
        </div>

        <div class="pages-crumbs-item" slot="reference">
          <div class="pages-crumbs-item__label">
            ...
          </div>
        </div>
      </el-popover>


      <div class="pages-crumbs-div">
        /
      </div>
    </template>

    <template v-if="firstParent">
      <router-link class="pages-crumbs-item pages-crumbs-item_link" :to="{ name: 'pages.detail', params: { project: firstParent.project, code: firstParent.code} }">
        <div class="pages-crumbs-item__label">
          {{ firstParent.icon.value }} {{ firstParent.name }}
        </div>
      </router-link>

      <div class="pages-crumbs-div">
        /
      </div>
    </template>

    <div class="pages-crumbs-item">
      <div class="pages-crumbs-item__label">
        {{ page.icon.value }} {{ page.name || 'Без названия' }}
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardIcon from '@/components/icons/DashboardIcon';
  import breadcrumbs from '@/components/pages/mixins/breadcrumbs';

  export default {
    name: 'Breadcrumbs',

    components: {DashboardIcon},

    mixins: [breadcrumbs],

    props: {
      page: {
        type: Object,
        required: true,
      },

      isLoading: {
        type: Boolean,
        required: true,
      }
    }
  }
</script>

<template>
  <el-skeleton :loading="isLoading" animated>
    <template slot="template">
      <div class="ui-block db-active-sprint">
        <div class="db-active-sprint__dates">
          <el-skeleton-item variant="text" style="width: 150px; height: 20px;"/>
        </div>

        <div class="db-active-sprint__stats">
          <div
            class="db-active-sprint__stat"
            v-for="i in 2"
            :key="i"
          >
            <el-skeleton-item variant="circle" style="width: 54px; height: 54px;"/>

            <div class="db-active-sprint__stat-data">
              <div class="db-active-sprint__stat-title">
                <el-skeleton-item variant="text" style="width: 100px; height: 16px;"/>
              </div>
              <div class="db-active-sprint__stat-numbers">
                <el-skeleton-item variant="text" style="width: 60px; height: 16px;"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template>
      <div class="ui-block db-active-sprint" v-if="activeSprint">
        <div class="db-active-sprint__dates">
          {{ sprintDateLabel }}
        </div>

        <div class="db-active-sprint__stats">
          <div class="db-active-sprint__stat">
            <circle-progress
                :diameter="54"
                :stroke-width="5"
                :inner-stroke-width="5"
                :completed-steps="activeSprint.overall.tasks_finished_count"
                :total-steps="activeSprint.overall.tasks_total_count"
            >
              <div class="db-active-task__percent">
                {{ overallPercent }}%
              </div>
            </circle-progress>
            <div class="db-active-sprint__stat-data">
              <div class="db-active-sprint__stat-title">
                {{ $t('dashboard.sprint.overallProgress') }}
              </div>
              <div class="db-active-sprint__stat-numbers">
                {{ activeSprint.overall.tasks_finished_count }}
                {{ $t('goals.outOf') }}
                {{ $tc('sprints.tasksCount', activeSprint.overall.tasks_total_count, { tasks: activeSprint.overall.tasks_total_count }) }}
              </div>
            </div>
          </div>

          <div class="db-active-sprint__stat" v-if="sprintMember">
            <circle-progress
                :diameter="54"
                :stroke-width="5"
                :inner-stroke-width="5"
                :completed-steps="sprintMember.tasks_finished_count"
                :total-steps="sprintMember.tasks_total_count"
            >
              <div class="db-active-task__percent">
                {{ memberPercent }}%
              </div>
            </circle-progress>
            <div class="db-active-sprint__stat-data">
              <div class="db-active-sprint__stat-title">
                {{ $t('dashboard.sprint.memberProgress') }}
              </div>
              <div class="db-active-sprint__stat-numbers">
                {{ sprintMember.tasks_finished_count }}
                {{ $t('goals.outOf') }}
                {{ $tc('sprints.tasksCount', sprintMember.tasks_total_count, { tasks: sprintMember.tasks_total_count }) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </el-skeleton>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'

import CircleProgress from '@/components/ui/CircleProgress.vue'
import { transformDateToObject } from '@/services/scoring';

const { mapActions: dashboardActions, mapGetters: dashboardGetters } = createNamespacedHelpers('dashboard')

export default {
  name: 'ActiveSprint',

  components: { CircleProgress },

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    ...dashboardGetters(['activeSprint']),

    sprintDateLabel () {
      const startedAt = transformDateToObject(this.activeSprint.data.started_at)
      const finishedAt = transformDateToObject(this.activeSprint.data.finished_at)

      return `${startedAt.toFormat('dd LLLL')} - ${finishedAt.toFormat('dd LLLL')}`
    },

    overallPercent () {
      const overall = this.activeSprint.overall
      return this.getPercent(overall)
    },

    sprintMember () {
      return this.activeSprint.members[0]
    },

    memberPercent () {
      return this.getPercent(this.sprintMember)
    }
  },

  methods: {
    ...dashboardActions(['fetchActiveSprint']),

    getPercent ({ tasks_finished_count, tasks_total_count}) {
      return Math.round(tasks_finished_count / tasks_total_count * 100)
    }
  },

  async created () {
    this.isLoading = true
    try {
      await this.fetchActiveSprint()
    } finally {
      this.isLoading = false
    }
  }
}
</script>
<style lang="sass">
  .db-active-sprint
    &__dates
      color: var(--text-light-color)
      margin-bottom: 20px

    &__stats
      display: flex
      justify-content: space-between

    &__stat
      align-items: center
      display: flex
      flex: 1

      &-data
        margin-left: 12px

      &-title
        font-size: 13px
        font-weight: 500

      &-numbers
        color: var(--primary-color)
        margin-top: 8px
        font-size: 13px
        font-weight: 500

</style>

<template>
  <el-dialog
    :title="$t(dialogTitle)"
    :visible="true"
    width="700px"
    @close="onClose"
  >
    <div class="target-form" v-if="isLoaded">
      <target-form :form="target" @submit="onClose"/>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from "vuex"

import TargetForm from "@/components/targets/TargetForm"

export default {
  name: 'TargetModal',

  components: { TargetForm },

  props: {
    targetId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      target: {
        name: '',
        color: 'red',
        description: ''
      },
      isLoaded: false
    }
  },

  computed: {
    dialogTitle () {
      return this.targetId
        ? 'goals.updateTitle'
        : 'goals.createTitle'
    }
  },

  methods: {
    ...mapActions(['fetchTarget']),

    onClose () {
      this.$emit('close')
    }
  },

  created () {
    this.isLoaded = false
    this.fetchTarget({ id: this.targetId })
      .then(target => {
        this.target = target
      })
      .finally(() => {
        this.isLoaded = true
      })
  }
}
</script>
<style lang="sass">
.target-form
  &__title
    display: flex

    .text-field-wrapper
      flex: 1

  &__btn.ui-button
    height: 48px
    padding: 0 36px
    font-size: 16px

  &__description.sw-editor
    border: 1px solid var(--block-border-bg)

</style>

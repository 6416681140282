const THEME_KEY = 'sw_theme'
const DEFAULT_THEME = 'light'

class ThemeService {
  applyTheme () {
    const theme = this.getTheme()

    document.querySelector('#app').className = theme
    document.querySelector('body').className = theme
  }

  getTheme () {
    return localStorage.getItem(THEME_KEY) || DEFAULT_THEME
  }

  setTheme (theme) {
    localStorage.setItem(THEME_KEY, theme)
  }
}

const themeService = new ThemeService()

export default themeService
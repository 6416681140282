<template>
  <ui-switch :value="authUser.theme" @input="onModeChange" false-value="light" true-value="dark" class="dark-mode-switcher">
    <template slot="false-icon">
      <day-icon class="ui-switch__icon"/>
    </template>
    <template slot="true-icon">
      <night-icon class="ui-switch__icon"/>
    </template>
  </ui-switch>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import DayIcon from "@/components/icons/DayIcon"
import NightIcon from "@/components/icons/NightIcon"
import UiSwitch from "@/components/ui/UiSwitch"

import themeService from "@/services/theme"

export default {
  name: 'ThemeSwitcher',

  components: { DayIcon, NightIcon, UiSwitch },

  computed: {
    ...mapGetters(['authUser'])
  },

  methods: {
    ...mapActions(['updateMemberProfile']),

    onModeChange (val) {
      this.updateMemberProfile({ theme: val })
        .then(() => {
          themeService.setTheme(val)
          themeService.applyTheme()
        })
    }
  }
}
</script>

import { transformDateToObject } from "@/services/scoring"
import { sortByField, sortByMultipleFields, sortNullables } from '@/utils/arrays';

function nullableTarget() {
  return { id: null, name: 'Без таргета' }
}

function nullableSphere() {
  return { id: null, name: 'Нет сферы' }
}

function nullableAssignee () {
  return { member_id: null, first_name: 'Не выбран' }
}

const stateDateTransformFields = {
  startedAt:          'started_at',
  finishedAt:         'finished_at',
  beProcessedAt:      'be_processed_at',
  estimatedFinishAt:  'estimated_finish_at'
}

const progressDateTransformFields = {
  startedAt:          'started_at',
  finishedAt:         'finished_at'
}

export function transformTask (task, { spheres }) {
  const { sphere, target, ...rest } = task

  let transformedSphere = sphere
  if (sphere) {
    const workspaceSphere = spheres.find((item) => item.id === sphere.id)
    if (workspaceSphere) {
      transformedSphere = {
        ...transformedSphere,
        color: workspaceSphere.color
      }
    }
  }

  const result = {
    ...rest,
    sphere: transformedSphere ? { ...transformedSphere } : nullableSphere(),
    target: target ? { ...target } : nullableTarget(),
  }

  if (result.id) {
    result.createdAt = transformDateToObject(result.created_at)
    result.startedAt = transformDateToObject(result.started_at)
    result.estimatedFinishAt = transformDateToObject(result.estimated_finish_at)
    result.finishedAt = transformDateToObject(result.finished_at)

    result.states = result.states.map(taskState => {
      const { assignee, stage, progresses, ...rest } = taskState

      const item = {
        ...rest,
        assignee: assignee ? { ...assignee } : nullableAssignee(),
        stage: stage ? { ...stage } : {
          id: 0,
          name: 'Backlog'
        },
        progresses: progresses.map(progress => {
          const item = {
            ...progress
          }

          Object.keys(progressDateTransformFields).forEach(field => {
            const originField = stateDateTransformFields[field]
            item[field] = transformDateToObject(item[originField])
          })

          return item
        })
      }

      // Преобразуем даты
      Object.keys(stateDateTransformFields).forEach(field => {
        const originField = stateDateTransformFields[field]
        item[field] = transformDateToObject(item[originField])
      })

      return item
    })

    result.transitions = result.transitions.map(transition => {
      const item = { ...transition }
      item.transitedAt = transformDateToObject(item.transited_at)

      return item
    })

    result.state = result.states.find(item => item.id === result.state_id)
  }

  return result
}

export function transformTaskItem({ members, spheres, stages }) {
  return function(item) {
    const creator = members.find(member => member.member_id === item.creator_id)
    item.creator = creator || {}

    if (item.state) {
      const assignee = members.find(member => member.member_id === item.state.assignee_id)
      item.state.assignee = assignee || {}
    }

    if (item.states) {
      item.states = item.states.map(state => {
        const assignee = members.find(member => member.member_id === state.assignee_id)
        state.assignee = assignee || {}

        return state
      })

      if (!item.state) {
        item.state = item.states.find(state => state.id === item.state_id)
      }
    }

    if (item.stage_id) {
      const { state } = item
      state.stage = stages.find(stage => stage.id === item.stage_id)
    }

    if (item.sphere) {
      const sphere = spheres.find((sphere) => sphere.id === item.sphere.id)
      if (sphere) {
        item.sphere = { ...item.sphere, color: sphere.color }
      }
    }

    item.muted = false

    return TransformTaskItemDates(item)
  }
}

export function TransformTaskItemDates(item) {
  const { state } = item

  item.estimatedFinishAt = transformDateToObject(item.estimated_finish_at)
  item.startedAt = transformDateToObject(item.started_at)
  item.finishedAt = transformDateToObject(item.finished_at)

  if (state.stage) {
    if (state.progress_started_at) {
      state.startedAt = transformDateToObject(state.progress_started_at)
    }

    if (state.be_processed_at) {
      state.beProcessedAt = transformDateToObject(state.be_processed_at)
    }

    if (state.estimated_finish_at) {
      state.estimatedFinishAt = transformDateToObject(state.estimated_finish_at)
    }
  }

  if (state.finished_at) {
    state.finishedAt = transformDateToObject(state.finished_at)
  }

  return item
}

export function sortTasks(tasks) {
  tasks.sort(sortByMultipleFields(sortNullables('state.assignee_id', 'desc'), sortByField('task_date'), sortNullables('score', 'desc'), sortByField('id')))
}

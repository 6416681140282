<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.59535 5.27796L11.4419 2.40356V12.7291C11.4419 13.0374 11.6918 13.2873 12 13.2873C12.3083 13.2873 12.5582 13.0374 12.5582 12.7291V2.4035L15.4047 5.27791C15.6315 5.46502 15.9593 5.46502 16.1861 5.27791C16.4023 5.05143 16.4023 4.69509 16.1861 4.46861L12.3908 0.645324C12.1683 0.44635 11.8318 0.44635 11.6093 0.645324L7.81399 4.46861C7.59781 4.69509 7.59781 5.05143 7.81399 5.27791C8.03641 5.47694 8.37288 5.47694 8.59535 5.27796Z" fill="white"/>
        <path d="M23.9163 13.6526L20.7628 6.84327C20.6782 6.63644 20.4834 6.49575 20.2605 6.48047H17.5814C17.2731 6.48047 17.0233 6.73035 17.0233 7.03861C17.0233 7.34687 17.2731 7.59675 17.5814 7.59675H19.8977L22.493 13.4572H19.3116C17.8692 13.4501 16.5574 14.2918 15.9628 15.6061C15.5322 16.51 14.6199 17.0856 13.6186 17.0851H10.3814C9.38012 17.0856 8.46789 16.51 8.03719 15.6061C7.44262 14.2918 6.13079 13.4501 4.68835 13.4572H1.56278L4.63253 7.59675H6.41859C6.72685 7.59675 6.97673 7.34687 6.97673 7.03861C6.97673 6.73035 6.72685 6.48047 6.41859 6.48047H4.29767C4.0831 6.49639 3.89256 6.62341 3.79535 6.81533L0.111639 13.5968C0.0507983 13.6785 0.012339 13.7747 0 13.8758V20.8526C0 22.3875 1.33956 23.5038 2.87441 23.5038H21.1256C22.6605 23.5038 24 22.3875 24 20.8526V13.8759C23.9846 13.7974 23.9563 13.7219 23.9163 13.6526ZM22.8837 20.8526C22.8837 21.7735 22.0465 22.3875 21.1256 22.3875H2.87441C1.95347 22.3875 1.11628 21.7735 1.11628 20.8526V14.5735H4.68835C5.68963 14.5731 6.60186 15.1487 7.03255 16.0526C7.62712 17.3669 8.93896 18.2086 10.3814 18.2015H13.6186C15.061 18.2086 16.3729 17.3669 16.9674 16.0526C17.3981 15.1487 18.3104 14.5731 19.3116 14.5735H22.8837V20.8526Z" fill="white"/>
    </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'UploadIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#FFFFFF',
        defaultWidth: 12,
        defaultHeight: 12
      }
    }
  }
</script>

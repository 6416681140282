<template>
<div class="task-card-controls" v-if="task.id">
  <div class="task-card-controls__btn-set">
    <el-tooltip effect="dark" :content="$t('tasks.actions.returnToPreviousState')" placement="top" transition="sw-slide-top" v-if="canBeReturned">
      <ui-button ui-class="size_md light icon" @click="onReturnBack">
        <return-icon class="task-card-controls__btn-icon"/>
      </ui-button>
    </el-tooltip>

    <el-tooltip effect="dark" :content="$t('tasks.actions.pause')" placement="top" transition="sw-slide-top" v-if="task.status === 'in_progress'">
      <ui-button ui-class="size_md light icon" @click="onChangeProgress('pause')">
        <pause-icon class="task-card-controls__btn-icon"/>
      </ui-button>
    </el-tooltip>

    <el-tooltip effect="dark" :content="$t('tasks.actions.moveForward')" placement="top" transition="sw-slide-top" v-if="task.status === 'in_progress'">
      <ui-button ui-class="size_md light icon" @click="onChangeProgress('complete')">
        <forward-icon class="task-card-controls__btn-icon"/>
      </ui-button>
    </el-tooltip>

    <ui-loading-button
      @click.prevent="onChangeProgress('start')"
      class="task-card-controls__start-btn"
      ui-class="size_md"
      :loading="processingAction === 'start'"
      v-if="task.status === 'open'"
    >
      <play-icon class="task-card-controls__btn-icon mr-2" v-if="processingAction !== 'start'"/> {{ $t('tasks.actions.start') }}
    </ui-loading-button>
  </div>

  <div class="task-card-controls__btn-set">
    <ui-loading-button
        @click="onFinishTask"
        ui-class="size_md"
        :loading="processingAction === 'complete'"
        v-if="task.status !== 'finished'"
    >
      {{ $t('tasks.actions.finish') }}
    </ui-loading-button>

    <ui-button ui-class="size_md light icon" v-if="false">
      <more-horizontal-icon/>
    </ui-button>
  </div>

  <confirm-task-complete-modal
    :is-processing="taskComplete.isProcessing"
    :stages="taskComplete.stages"
    :assignee="taskComplete.assignee"
    v-if="taskComplete.showConfirmation"
    @close="taskComplete.showConfirmation = false"
    @submit="onConfirmComplete"
  />

  <confirm-task-return-modal
    :is-processing="confirmationProcessing"
    v-if="showReturnConfirmation"
    @close="showReturnConfirmation = false"
    @submit="onConfirmReturn"
  />

  <confirm-task-start-modal
    :task="oldActiveTask"
    :is-processing="confirmationProcessing"
    v-if="showConfirmation"
    @close="showConfirmation = false"
    @submit="onConfirmationSubmit"
  />
</div>
</template>
<script>
import {createNamespacedHelpers, mapActions} from "vuex"

import ConfirmTaskReturnModal from "@/components/tasks/components/ConfirmTaskReturnModal"
import ConfirmTaskStartModal from '@/components/tasks/components/ConfirmTaskStartModal';
import UiButton from 'scorework-ui/src/components/UiButton'
import ForwardIcon from "@/components/icons/ForwardIcon"
import MoreHorizontalIcon from "@/components/icons/MoreHorizontalIcon"
import PauseIcon from "@/components/icons/PauseIcon"
import PlayIcon from "@/components/icons/PlayIcon"
import ReturnIcon from "@/components/icons/ReturnIcon"
import UiLoadingButton from '@/components/ui/UiLoadingButton'
import taskCompleteMixin from '@/components/tasks/mixins/taskCompleteMixin';

const { mapActions: taskActions } = createNamespacedHelpers('task')
const { mapActions: tasksActions, mapGetters: tasksGetters } = createNamespacedHelpers('tasks')

export default {
  name: 'TaskCardControls',

  components: {
    ConfirmTaskReturnModal, ConfirmTaskStartModal, ForwardIcon,
    MoreHorizontalIcon, PauseIcon, PlayIcon, ReturnIcon, UiButton, UiLoadingButton
  },

  mixins: [taskCompleteMixin],

  props: {
    task: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      showReturnConfirmation: false,
      showConfirmation: false,
      confirmationProcessing: false,
      processingAction: null
    }
  },

  computed: {
    ...tasksGetters(['oldActiveTask']),

    canBeReturned () {
      return this.task.status === 'finished' || (this.task.state.stage && this.task.state.stage.id && !this.task.state.stage.is_base_stage)
    }
  },

  methods: {
    ...mapActions(['setOldActiveTask']),
    ...tasksActions(['changeProgress']),
    ...taskActions(['setTask', 'fetchTask', 'setOldActiveTask']),

    onFinishTask () {
      return this.onChangeProgress('complete', true)
    },

    onChangeProgress (action, confirmed = false, params = {}) {
      this.processingAction = action
      return this.changeProgress({ taskId: this.task.id, action, confirmed, ...params })
          .then(data => {
            const currentAssignee = this.task.state.assignee || {}
            if (this.checkNeedConfirmation(data, { ...currentAssignee })) {
              return
            }

            if (data.code === 'NEED_CONFIRMATION') {
              const { task = null } = data

              if (task !== null) {
                this.setOldActiveTask(task)
                this.showConfirmation = true
              }

              return
            }

            const { task } = data

            if (action === 'pause' || action === 'start') {
              this.setTask(task)
            } else {
              this.fetchTask({ taskId: this.task.id })
                .then(() => {
                  this.$emit('complete')
                })
            }
          })
          .finally(() => {
            this.processingAction = null
          })
    },

    onReturnBack () {
      this.showReturnConfirmation = true
    },

    onConfirmReturn ({ comment }) {
      this.confirmationProcessing = true
      return this.changeProgress({ taskId: this.task.id, action: 'return', confirmed: false, comment })
        .then(() => {
          this.fetchTask({ taskId: this.task.id })
            .then(() => {
              this.$emit('complete')
            })
        })
        .finally(() => {
          this.confirmationProcessing = false
          this.showReturnConfirmation = false
        })
    },

    async onConfirmationSubmit () {
      this.confirmationProcessing = true
      await this.onChangeProgress('start', true)
      this.confirmationProcessing = false
      this.showConfirmation = false
      this.setOldActiveTask(null)
    },
  }
}
</script>
<style lang="sass">
.task-card-controls
  align-items: center
  background: var(--grey-light-color-24)
  border-radius: 0 0 16px 16px
  display: flex
  justify-content: space-between
  padding: 16px 20px

  &__btn-set
    align-items: center
    display: flex

    .ui-button
      margin-right: 8px
      overflow: hidden

      &_icon
        align-items: center
        display: flex
        justify-content: center
        padding: 0
        width: 32px

      &:last-child
        margin-right: 0

.dark
  .task-card-controls
    background: var(--block-bg-color2)

</style>

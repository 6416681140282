<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.0625 11L2.42264 3.04914C2.25285 2.22594 3.11277 1.57324 3.85998 1.95817L22.2217 11.4173C22.9543 11.7947 22.9416 12.8465 22.2 13.206L3.85835 22.099C3.10628 22.4636 2.26529 21.7995 2.44563 20.9834L4.0625 13.6667L14.7368 12.3333L4.0625 11Z" fill="#DDE3ED"/>
  </svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'SendLetterIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 10,
        defaultHeight: 10
      }
    }
  }
</script>

const NodeViewWrapper = {
  props: {
    as: {
      type: String,
      default: 'div',
    },
    attrs: {
      type: Object | null,
      default: () => ({})
    }
  },

  inject: ['onDragStart', 'decorationClasses'],

  render(createElement) {
    return createElement(
      this.as,
      {
        class: this.decorationClasses.value,
        style: {
          whiteSpace: 'normal',
        },
        attrs: {
          ...this.attrs,
          'data-node-view-wrapper': '',
        },
        on: {
          dragstart: this.onDragStart,
        },
      },
      this.$slots.default,
    )
  },
}

export default NodeViewWrapper

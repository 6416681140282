<template>
<el-popover
    placement="right"
    trigger="hover"
    :visible-arrow="false"
    :popper-class="popperClasses"
    transition="sw-slide-right"
    @show="onPopoverShow"
>
  <div>
    <div class="active-task__header">
      <template v-if="workingState.isWorking">
        <div class="active-task__schedule-label">
          {{ $t('tasks.widget.remainTime') }}
        </div>
        <div class="active-task__schedule-remain">
          {{ workingState.remain.hours }}
          <span class="active-task__schedule-span">{{ $t('dateTime.short.hours') }}</span> :
          {{ workingState.remain.minutes }}
          <span class="active-task__schedule-span">{{ $t('dateTime.short.minutes') }}</span>
        </div>
      </template>
      <template v-else>
        <div class="off-schedule-state">
          <div class="off-schedule-state__icon">
            <sleep-icon/>
          </div>

          <div class="off-schedule-state__content">
            <div class="off-schedule-state__title">
              {{ $t('tasks.offSchedule.title') }}
            </div>
            <div class="off-schedule-state__descr">
              {{ $t('tasks.offSchedule.description') }}
            </div>
          </div>
        </div>

      </template>
    </div>

    <div class="active-task__body">
      <div class="active-task__body-head" @click="onTitleClick">
        <div class="active-task__number">
          {{ taskNumber }}
        </div>

        <div class="active-task__score">
          <clock-icon class="task-item__score-icon"/>
          {{ formattedScore }}
        </div>
      </div>

      <div class="active-task__title" @click="onTitleClick">
        {{ activeTask.name }}
      </div>

      <div class="active-task__footer">
        <div class="active-task__actions">
          <el-tooltip effect="dark" :content="$t(mainAction.label)" placement="top" transition="sw-slide-top">
            <ui-button class="btn-icon mr-2" ui-class="size_sm" @click="onClickActionBtn">
              <component :is="mainAction.icon" class="active-task__control-btn"/>
            </ui-button>
          </el-tooltip>

          <ui-button ui-class="light size_sm" @click="onCompleteClick">
            {{ $t('layout.complete') }}
          </ui-button>
        </div>

        <div class="active-task__status">
          <div class="task-item__status active-task__status-icon" :class="statusClasses">
            <component :is="statusIcon"/>
          </div>
          {{ statusLabel }}
        </div>
      </div>
    </div>
  </div>

  <div class="sb-active-task" slot="reference">
    <div class="sb-active-task__title">
      {{ $t(taskLabel) }}
    </div>

    <a
        :class="elementClasses('sb-active-task__number')"
        v-on:click="onTitleClick"
    >
      {{ taskNumber }}
    </a>

    <div class="sb-active-task__wrapper">
      <div class="sb-active-task__block" :class="blockClasses" @click="onClickActionBtn">
        <div class="sb-active-task__state" :class="taskStateClasses" v-if="showState">
          <fire-icon v-if="processing.state === 'overdue' && workingState.isWorking"/>
          <sleep-icon v-if="!workingState.isWorking"/>

          <svg height="18" width="18" viewBox="0 0 18 18" :class="circleClasses" v-if="workingState.isWorking && processing.state !== 'overdue' && processing.percent > 0">
            <circle
              cx="9"
              cy="9"
              r="4.5"
              stroke="#07E7A4"
              stroke-width="9"
              :stroke-dasharray="circumference"
              :stroke-dashoffset="strokeDashOffset"
              fill="transparent"
            />
          </svg>
        </div>
        <div class="sb-active-task__actions">
          <div class="sb-active-task__control">
            <component :is="mainAction.icon"/>
          </div>
        </div>
      </div>
    </div>

    <confirm-task-complete-modal
      :is-processing="taskComplete.isProcessing"
      :stages="taskComplete.stages"
      :assignee="taskComplete.assignee"
      v-if="taskComplete.showConfirmation"
      @close="taskComplete.showConfirmation = false"
      @submit="onConfirmComplete"
    />
  </div>
</el-popover>
</template>
<script>
import {createNamespacedHelpers, mapActions, mapGetters} from "vuex"

import PlayOutlineIcon from '@/components/icons/PlayOutlineIcon'
import PauseNarrowIcon from '@/components/icons/PauseNarrowIcon'
import ClockIcon from '@/components/icons/ClockIcon'
import PauseIcon from "@/components/icons/PauseIcon"
import FireIcon from "@/components/icons/FireIcon"
import Flag from "@/components/icons/Flag"
import SandclockIcon from "@/components/icons/SandclockIcon"
import SleepIcon from "@/components/icons/SleepIcon"
import PlayIcon from "@/components/icons/PlayIcon"
import UiButton from 'scorework-ui/src/components/UiButton'
import { getScoreLabel, getScoreObject, getMemberWorkingState } from '@/services/scoring'
import taskCompleteMixin from '@/components/tasks/mixins/taskCompleteMixin'

const { mapActions: tasksActions } = createNamespacedHelpers('tasks')

export default {
  name: 'ActiveTask',

  components: { PlayOutlineIcon, PauseNarrowIcon, ClockIcon, FireIcon, Flag, PauseIcon, PlayIcon, SandclockIcon, SleepIcon, UiButton },

  mixins: [taskCompleteMixin],

  data () {
    return {
      isLoaded: true,
      workingState: {
        isWorking: false
      },
      processing: {
        timeout: 0,
        percent: 0,
        state: 'open'
      },
      stateInterval: 0,
      sleepSince: Date.now()
    }
  },

  computed: {
    ...mapGetters(['authUser', 'workspace', 'isTour', 'activeTask']),

    taskNumber () {
      if (this.workspace.task_prefix) {
        return `#${this.workspace.task_prefix}-${this.activeTask.number}`
      }

      return `#${this.activeTask.number}`
    },

    popperClasses () {
      let classes = [ 'active-task' ];

      if (this.activeTask.processing && this.activeTask.processing.state === 'overdue') {
        classes.push('active-task_overdue')
      }

      return classes.join(' ')
    },

    blockClasses () {
      const classes = []
      const processing = this.activeTask.processing || {}
      if (processing.state === 'overdue') {
        classes.push('sb-active-task__block_overdue')
      }

      if (!this.workingState.isWorking) {
        classes.push('sb-active-task__block_sleep')
      }

      return classes
    },

    circleClasses () {
      const classes = ['sb-active-task__state-circle']
      if (this.activeTask.status !== 'in_progress' && this.activeTask.actual_progress) {
        classes.push('sb-active-task__state-circle_idle')
      }

      return classes
    },

    mainAction () {
      if (this.activeTask.status !== 'in_progress') {
        return {
          icon: 'play-icon',
          label: 'tasks.actions.start'
        }
      }

      return {
        icon: 'pause-icon',
        label: 'tasks.actions.pause'
      }
    },

    statusIcon () {
      if (this.activeTask.status === 'in_progress') {
        if (this.activeTask.processing?.state === 'overdue') {
          return 'fire-icon'
        }
        return 'play-outline-icon'
      }

      if (this.activeTask.status === 'open' && this.activeTask.startedAt) {
        return 'pause-narrow-icon'
      }

      return 'sandclock-icon'
    },

    statusLabel () {
      if (this.activeTask.status === 'open' && this.activeTask.startedAt) {
        return this.$t('tasks.widget.status.onPause')
      }

      if (this.activeTask.status === 'in_progress') {
        if (this.activeTask.processing?.state === 'overdue') {
          const overdueMinutes = this.activeTask.processing.processed_minutes - this.activeTask.score
          const scoreObject = getScoreObject(overdueMinutes, this.workspace.daily_minutes)

          return getScoreLabel(scoreObject)
        }

        return this.$t('tasks.widget.status.inProgress')
      }

      return this.$t('tasks.widget.status.open')
    },

    circumference () {
      return 2 * Math.PI * 4.5
    },

    strokeDashOffset () {
      const strokeDiff = this.processing.percent / 100 * this.circumference
      return this.circumference - strokeDiff
    },

    showState () {
      if (this.activeTask.status === 'open' && this.activeTask.actual_progress === 0) {
        return false
      }

      return true
    },

    taskStateClasses () {
      const classes = []
      if (this.processing.state === 'overdue') {
        classes.push('sb-active-task__state_overdue')
      }

      return classes
    },

    taskStatus () {
      if (this.activeTask.status === 'in_progress' && this.processing?.state === 'overdue') {
        return 'overdue'
      }

      if (this.activeTask.status === 'open' && this.activeTask.startedAt) {
        return 'paused'
      }

      return this.activeTask.status
    },

    taskLabel () {
      if (this.activeTask.status === 'in_progress') {
        return 'tasks.widget.status.inProgress'
      }

      if (this.activeTask.status === 'open' && this.activeTask.actual_progress > 0) {
        return 'tasks.widget.status.onPause'
      }

      return 'tasks.widget.status.open'
    },

    parsedScore () {
      const minutes = this.activeTask.score % 60
      const hours = (this.activeTask.score - minutes) / 60

      return { hours, minutes }
    },

    formattedScore () {
      if (!this.activeTask.score) {
        return this.$t('tasks.noScore')
      }

      const result = []

      if (this.parsedScore.hours > 0) {
        result.push(`${this.parsedScore.hours} ${this.$t('sprints.h')}.`)
      }

      if (this.parsedScore.minutes) {
        result.push(`${this.parsedScore.minutes} ${this.$t('sprints.min')}.`)
      }

      return result.join(' ')
    },

    statusClasses () {
      const classes = [`task-item__status_${this.taskStatus}`]

      return classes
    },
  },

  methods: {
    ...mapActions(['fetchActiveTask']),
    ...tasksActions(['changeProgress']),

    elementClasses (baseClass) {
      const classes = {
        [baseClass]: true
      }

      if (this.activeTask.processing) {
         classes[`${baseClass}_overdue`] = this.activeTask.processing.state === 'overdue'
      }

      return classes
    },

    onTitleClick () {
      this.$router.push({
        name: 'tasks.detail',
        params: { taskId:
          this.activeTask.id,
          stageId: this.activeTask.stage_id,
        },
      })
    },

    onClickActionBtn () {
      const action = this.activeTask.status === 'in_progress'
        ? 'pause'
        : 'start'

      this.onChangeProgress(action, false)
    },

    onCompleteClick () {
      this.isLoaded = false
      this.onChangeProgress('complete', false)
        .then(() => {
          this.isLoaded = true
        })
    },

    onChangeProgress (action, confirmed, params = {}) {
      if (this.isTour) {
        return
      }

      const dataParams = {
        taskId: this.activeTask.id,
        confirmed,
        action,
        ...params
      }

      return this.changeProgress(dataParams)
        .then(data => {
          // Может быть подтверждение при завершении
          const currentAssignee = this.activeTask.state.assignee || {}
          this.checkNeedConfirmation(data, { ...currentAssignee })
        })
    },

    onPopoverShow () {
      this.workingState = getMemberWorkingState(this.authUser.work_schedule[0])
    },

    watchTaskProgress () {
      if (!this.workingState.isWorking) {
        return
      }

      let piece = Math.floor(this.activeTask.score / 100)
      if (piece === 0) {
        piece = 1
      }

      this.processing.timeout = setTimeout(() => {
        this.processing.percent++
        if (this.processing.percent < 100) {
          this.watchTaskProgress()
        }
      }, 1000 * 60 * piece)
    },

    watchWorkingState () {
      const oldState = this.workingState.isWorking
      this.workingState = getMemberWorkingState(this.authUser.work_schedule[0])

      // День закончился, отметили время
      if (oldState && this.workingState.isWorking) {
        this.sleepSince = Date.now()
      }

      // Вернулись из сна, запускаем таймер на отслеживание прогресса
      if (!oldState && this.workingState.isWorking) {
        const sleepTime = (Date.now() - this.sleepSince) / 1000

        if (sleepTime >= 15 * 60) {
          this.fetchActiveTask()
          .then(() => {
            this.calcProcessing()
          })
        } else {
          this.calcProcessing()
        }
      }
    },

    calcProcessing () {
      if (this.activeTask.status === 'in_progress') {
        this.processing.percent = this.activeTask.processing.percent
        this.processing.state = this.activeTask.processing.state

        this.watchTaskProgress()
      } else {
        if (this.activeTask.actual_progress > this.activeTask.score) {
          this.processing.percent = 100
          this.processing.state = 'overdue'
        } else {
          this.processing.percent = Math.floor(this.activeTask.actual_progress / this.activeTask.score * 100)
        }
      }
    },

    initTask () {
      if (this.stateInterval) {
        clearInterval(this.stateInterval)
      }

      if (!this.activeTask.id) {
        return
      }

      this.watchWorkingState()
      this.stateInterval = setInterval(() => {
        this.watchWorkingState()
      }, 1000 * 60)
    }
  },

  watch: {
    activeTask: function () {
      if (this.processing.timeout) {
        clearTimeout(this.processing.timeout)
      }

      this.initTask()
    }
  },

  mounted() {
    this.initTask()
  },

  beforeDestroy() {
    if (this.processing.timeout) {
      clearTimeout(this.processing.timeout)
    }

    if (this.stateInterval) {
      clearInterval(this.stateInterval)
    }
  }
}
</script>
<style lang="sass">
.el-popper[x-placement^=right].active-task
  margin-left: 6px

  .popper__arrow
    border-right-color: var(--primary-color)

    &::after
      border-right-color: var(--primary-color)

.active-task
  background: var(--block-bg-color)
  border: 0
  border-radius: 12px
  box-sizing: border-box
  padding: 0
  text-align: left
  width: 320px
  word-break: break-word

  &__score
    align-items: center
    display: flex
    font-size: 12px

  &__title-link
    color: var(--primary-color)
    font-size: 12px

    &:hover
      cursor: pointer
      text-decoration: underline

  &__header
    background: var(--primary-light-color)
    border-radius: 12px 12px 0 0
    font-size: 12px
    display: flex
    justify-content: space-between
    align-items: center
    padding: 16px

  &__schedule-label
    color: var(--text-primary-color)
    flex: 1
    margin-right: 20px
    word-wrap: normal

  &__schedule-remain
    background: var(--primary-light-color)
    border-radius: 8px
    color: var(--text-primary-color)
    font-size: 16px
    font-weight: 600
    padding: 4px 8px

  &__schedule-span
    color: var(--text-light-color)
    font-size: 12px
    font-weight: 400

  &__body
    padding: 16px

  &__body-head
    cursor: pointer
    align-items: center
    display: flex
    justify-content: space-between

  &__number
    color: var(--text-light-color)
    font-size: 12px
    cursor: pointer

  &__title
    cursor: pointer
    color: var(--text-primary-color)
    font-size: 14px
    font-weight: 500
    padding: 8px 0 16px
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden

  &__footer
    display: flex
    justify-content: space-between
    align-items: center

  &__actions
    display: flex
    align-items: center

  &__status
    cursor: default
    display: flex
    align-items: center
    color: var(--text-light-color)
    font-size: 12px

  &__control-btn
    path
      fill: #FFFFFF

  &__status-icon
    margin-right: 6px

  &__btn
    height: 30px
    background: #4979F4
    box-sizing: border-box
    padding: 0 12px
    transition: 0.2s all
    border-radius: 4px
    color: #FFFFFF
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden
    cursor: pointer

    &_icon
      width: 30px
      padding: 0

.dark
  .active-task
    &__btn
      background: #6a94ff

.off-schedule-state
  align-items: center
  display: flex

  &__icon
    align-items: center
    background: #355093
    border-radius: 12px
    display: flex
    height: 24px
    justify-content: center
    margin-right: 12px
    width: 24px

  &__content
    flex: 1

  &__title
    color: var(--text-primary-color)
    font-size: 13px
    font-weight: 500

  &__descr
    color: var(--text-light-color)
    font-size: 13px
    margin-top: 2px
</style>

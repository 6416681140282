export default {
  props: {
    background: {
      type: String
    },

    width: {
      type: String
    },

    height: {
      type: String
    }
  },

  data () {
    return {
      defaultBackground: '#9498B2',
      defaultWidth: 12,
      defaultHeight: 12
    }
  },

  computed: {
    backgroundColor () {
      return this.background || this.defaultBackground
    },

    iconWidth () {
      return this.width || this.defaultWidth
    },

    iconHeight () {
      return this.height || this.defaultHeight
    },
  }
}

import { mapGetters } from 'vuex'
import {isAdmin, isOwner, RoleAdmin, RoleOwner} from '@/helpers/userRoles'

export default {
    computed: {
        ...mapGetters(['authUser', 'project']),

        roleOwner () {
            return RoleOwner
        },

        roleAdmin () {
            return RoleAdmin
        },

        isAdmin () {
            return isAdmin(this.authUser)
        },

        isOwner () {
            return isOwner(this.authUser)
        },

        isProjectAdmin () {
            const projectData = this.authUser.projects.find(({ project }) => this.project.code === project)

            return this.isAdmin || projectData?.role === RoleAdmin || projectData?.role === RoleOwner
        }
    },

    methods: {
        checkIsProjectAdmin (code) {
            const projectData = this.authUser.projects.find(({ project }) => code === project)

            return this.isAdmin || projectData?.role === RoleAdmin || projectData?.role === RoleOwner
        }
    }
}

<template>
  <div class="page-menu-item" v-draggable-item="{ list: listName, item }">
    <div class="page-menu-item__row" :style="rowStyles">
      <pages-menu-btn :ui-class="arrowClasses" @click="toggleExtend">
        <chevron-right-icon/>
      </pages-menu-btn>
      <router-link class="page-menu-item__name" :to="{ name: 'pages.detail', params: { project: project.code, code: item.code} }">
        {{ getPageIcon(item.icon) }} {{ item.name }}
      </router-link>

      <div class="page-menu-item__options">
        <el-popover
          placement="bottom"
          trigger="click"
          :visible-arrow="false"
          transition="sw-slide-up"
          popper-class="ui-dropdown xui-popover ui-dropdown_round"
          v-if="item.project ? checkIsProjectAdmin(item.project) : isAdmin"
        >
          <div class="xui-list editor-options-list">
            <div class="xui-list-item xui-list-item_sm" @click="() => onDeletePage(item.code)">
              <delete-icon class="xui-list-item-icon" />
              Удалить страницу
            </div>
          </div>
          <pages-menu-btn ui-class="plus" slot="reference">
            <more-horizontal-icon />
          </pages-menu-btn>
        </el-popover>

        <pages-menu-btn ui-class="plus" @click="() => onCreatePage(item.code)">
          <plus />
        </pages-menu-btn>
      </div>
    </div>

    <div class="page-menu-item__children" v-if="showChild && !isLoadingSubPages">
      <page-menu-item
        v-for="childItem in childrenPages"
        :item="childItem"
        :key="childItem.code"
        :level="level + 1"
        @create="({ parent }) => onCreatePage(parent)"
      />
      <div class="page-menu-item__row page-menu-item__row_empty" :style="noChildrenStyles" v-if="childrenPages.length === 0">
        Нет страниц
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import ChevronRightIcon from '@/components/icons/ChevronRightIcon'
  import DeleteIcon from '@/components/icons/DeleteIcon'
  import MoreHorizontalIcon from '@/components/icons/MoreHorizontalIcon'
  import Plus from '@/components/icons/Plus'
  import PagesMenuBtn from '@/components/pages/PagesMenuBtn'
  import authUserMixin from '@/mixins/authUserMixin'

  export default {
    name: 'PageMenuItem',

    components: { PagesMenuBtn, ChevronRightIcon, DeleteIcon, MoreHorizontalIcon, Plus },

    mixins: [authUserMixin],

    props: {
      item: {
        type: Object,
        required: true,
      },

      level: {
        type: Number,
        default: 0,
      }
    },

    data () {
      return {
        listName: `project-pages-${this.item.project || 'common'}`,
        showChild: false,
        isLoadingSubPages: false,
      }
    },

    computed: {
      ...mapGetters(['project']),
      ...mapGetters('pages', ['subPages']),

      arrowClasses () {
        const classes = ['arrow']
        if (this.showChild) {
          classes.push('arrow_extended')
        }

        return classes.join(' ')
      },

      rowStyles () {
        return {
          paddingLeft: (12 + this.level * 16) + 'px',
        }
      },

      noChildrenStyles () {
        return {
          paddingLeft: (12 + (this.level + 1) * 16) + 'px',
        }
      },

      childrenPages () {
        return this.subPages(this.item.code)
      }
    },

    methods: {
      ...mapActions('pages', ['fetchSubPages']),

      async toggleExtend () {
        this.showChild = !this.showChild
        if (!this.showChild) {
          return
        }

        this.isLoadingSubPages = true
        await this.fetchSubPages({ parent: this.item.code })
        this.isLoadingSubPages = false
      },

      getPageIcon (icon) {
        return icon.value
      },

      onCreatePage (code) {
        this.$emit('create', { parent: code })
      },

      onDeletePage (code) {
        this.$emit('delete', code)
      }
    }
  }
</script>
<style lang="sass">
.page-menu-item
  &.draggable-item__ghost
    border: 1px solid var(--grey-light-color-40)
    background: var(--block-bg-color)
    opacity: 0.5
    border-radius: 4px

  &.draggable-item_inside
    .page-menu-item__row, .page-menu-item__row:hover
      background: var(--primary-light-color)

  &.draggable-item_before, &.draggable-item_after
    position: relative
    .page-menu-item__row, .page-menu-item__row:hover
      background: transparent

  &.draggable-item_before
    &:after
      content: " "
      position: absolute
      height: 3px
      top: 0
      width: 100%
      background: var(--primary-light-color)

  &.draggable-item_after
    &:after
      content: " "
      position: absolute
      height: 3px
      bottom: -3px
      width: 100%
      background: var(--primary-light-color)

  &__row
    align-items: center
    display: flex
    column-gap: 4px
    padding: 4px 12px
    border-radius: 4px
    font-size: 13px
    color: var(--text-light-color)
    transition: all .2s ease-in

    &:hover
      background: var(--grey-light-color-40)

      .pages-menu-btn
        display: flex

    &_empty
      color: var(--grey-color-100)
      min-height: 22px

      &:hover
        background: transparent

  &__name
    flex: 1
    display: inline-block
    cursor: pointer
    color: var(--text-light-color)
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
    text-decoration: none

  &__options
    display: flex
    align-items: center
</style>

<template>
  <div class="archive backlog-list">
    <div class="archive-content">
      <div class="archive-filters">
        <div class="archive-filters__row">
          <ui-dropdown
            :options="sprintList"
            :value="sprintList.filter(item => filters.sprint_id.includes(item.id))"
            :filterable="true"
            :multiple="true"
            :label="$t('archive.sprintName')"
            reference-class="targets-dropdown targets-dropdown__first"
            popover-class="ui-dropdown_multiple"
            @input="(val) => onFilterChange('sprint_id', val.map(item => item.id))"
            key-field="id"
            :widthByPopover="false"
            placement="bottom-start"
            :compare-field="(item) => item.name"
          >
            <template v-slot:default>
              <div class="targets__selected">
                {{ currentSprintsFilterText() }}
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="task-spheres__chevron-icon"><path d="M1.66492 0.103516L5.99992 4.43852L10.3349 0.103516L11.6666 1.44463L5.99992 7.11129L0.333252 1.44463L1.66492 0.103516Z" fill="#2F3750" class="path-fill"></path></svg>
              </div>
            </template>

            <template v-slot:option="{ option, selected, searchText }">
              <el-checkbox :value="selected">
                <div v-html="filterText(option.name, searchText)" />
                <div class="archive-sprint-date">{{ option.duration }}</div>
              </el-checkbox>
            </template>
          </ui-dropdown>
          <ui-dropdown
            :options="targets"
            :value="targets.filter(item => filters.target_id.includes(item.id))"
            :label="$t('archive.targetName')"
            :filterable="true"
            :multiple="true"
            key-field="id"
            reference-class="targets-dropdown"
            popover-class="ui-dropdown_multiple"
            @input="(val) => onFilterChange('target_id', val.map(item => item.id))"
            :widthByPopover="false"
            placement="bottom-end"
            :compare-field="(item) => item.name"
          >
            <template v-slot:default>
              <div class="targets__selected">
                {{ currentTargetsFilterText() }}
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="task-spheres__chevron-icon"><path d="M1.66492 0.103516L5.99992 4.43852L10.3349 0.103516L11.6666 1.44463L5.99992 7.11129L0.333252 1.44463L1.66492 0.103516Z" fill="#2F3750" class="path-fill"></path></svg>
              </div>
            </template>
            <template v-slot:option="{ option, selected, searchText }">
              <el-checkbox :value="selected">
                <div v-html="filterText(option.name, searchText)" />
              </el-checkbox>
            </template>
          </ui-dropdown>
          <task-spheres
              :spheres="spheres"
              :value="currentSphereFilter.id"
              @input="(val) => onFilterChange('sphere_id', val.id)"
              dropdown-class="targets-dropdown"
              popper-class="ui-dropdown_multiple"
          />
          <ui-dropdown
            :options="activeMembers"
            :value="activeMembers.filter(item => filters.assignee_id.includes(item.member_id))"
            :filterable="true"
            :multiple="true"
            key-field="member_id"
            reference-class="targets-dropdown targets-dropdown__last"
            popover-class="ui-dropdown_multiple"
            @input="(val) => onFilterChange('assignee_id', val.map(item => item.member_id))"
            placement="bottom-start"
            :label="$t('archive.AssigneeName')"
            :widthByPopover="false"
            :compare-field="(item) => `${item.first_name} ${item.last_name}`"
          >
            <template v-slot:default>
              <div class="targets__selected">
                {{ currentMembersFilterText() }}
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="task-spheres__chevron-icon"><path d="M1.66492 0.103516L5.99992 4.43852L10.3349 0.103516L11.6666 1.44463L5.99992 7.11129L0.333252 1.44463L1.66492 0.103516Z" fill="#2F3750" class="path-fill"></path></svg>
              </div>
            </template>
            <template v-slot:option="{ option, selected, searchText }">
              <el-checkbox :value="selected">
                <div class="checkbox-with-photo">
                  <user-photo :user="option" size="sm"/>
                  <div v-html="filterText(`${option.first_name} ${option.last_name}`, searchText)" />
                </div>
              </el-checkbox>
            </template>
          </ui-dropdown>
        </div>
      </div>

      <el-skeleton :loading="isLoading" animated class="archive-list">
        <template slot="template">
          <div
            v-for="i in skeletonCount"
            class="status-task-item"
            :key="i"
          >
            <div class="status-task-item__block">
              <div class="fx fx_space-between">
                <div class="fx" style="flex: 1;">
                  <el-skeleton-item variant="text" style="width: 35px; height: 16px;" class="mr-2"/>
                  <el-skeleton-item variant="text" style="width: 45%; height: 16px;"/>
                </div>
                <el-skeleton-item variant="text" style="width: 55px; height: 16px;"/>
              </div>

              <div class="fx fx_space-between mt-2">
                <div class="fx" style="flex: 1;">
                  <el-skeleton-item variant="text" style="width: 140px; height: 16px;" class="mr-2"/>
                  <el-skeleton-item variant="text" style="width: 60px; height: 16px;"/>
                </div>
                <el-skeleton-item variant="text" style="width: 24px; height: 24px;"/>
              </div>
            </div>
          </div>

          <div class="archive-footer fx">
            <template v-if="!meta.total">
              <el-skeleton-item variant="text" style="width: 28px; height: 28px;" class="mr-2"/>
              <el-skeleton-item variant="text" style="width: 28px; height: 28px;" class="mr-2"/>
              <el-skeleton-item variant="text" style="width: 28px; height: 28px;" class="mr-2"/>
              <el-skeleton-item variant="text" style="width: 28px; height: 28px;"/>
            </template>
            <el-pagination
              v-else
              layout="prev, pager, next"
              :total="meta.total"
              :page-size="meta.per_page"
              :page-count="meta.last_page"
              :current-page="meta.current_page"
              @current-change="onChangePage"
            />
          </div>
        </template>
        <template>
          <finished-task-item
            v-for="(task, index) in tasks"
            :key="task.id"
            :task="task"
            :index="index"
            @open="onOpenTask"
            @create-task="onCreateTask"
          />
          <div v-if="tasks.length === 0" class="backlog-empty" :class="emptyClasses" key="no-items">
            <div class="backlog-empty__title">
              {{ $t('tasks.emptyArchive') }}
            </div>
          </div>
          <div class="archive-footer">
            <el-pagination
              layout="prev, pager, next"
              :total="meta.total"
              :page-size="meta.per_page"
              :page-count="meta.last_page"
              :current-page="meta.current_page"
              @current-change="onChangePage"
            />
          </div>
        </template>
      </el-skeleton>
    </div>
  </div>
</template>
<script>
import FinishedTaskItem from "@/components/tasks/FinishedTaskItem"
import UiDropdown from "@/components/tasks/UiDropdown"
import TaskSpheres from "@/components/tasks/TaskSpheres"
import UserPhoto from "@/components/ui/UserPhoto"
import { mapActions, mapGetters } from "vuex"
import { transformDateToObject } from "@/services/scoring";

export default {
  name: 'Archive',

  components: {
    FinishedTaskItem,
    UiDropdown,
    TaskSpheres,
    UserPhoto,
  },

  data () {
    return {
      isActiveDropZone: false,
    }
  },

  computed: {
    ...mapGetters([
      'spheres',
      'stages',
      'activeMembers',
      'workspace',
    ]),

    ...mapGetters('archive', ['filters', 'isLoading', 'meta', 'sprints', 'tasks']),
    ...mapGetters(['targets']),

    emptyClasses () {
      const classes = []

      if (this.isActiveDropZone) {
        classes.push(`backlog-empty_dropped`)
      }

      return classes
    },

    currentSphereFilter () {
      return {
        id: this.filters.sphere_id,
      }
    },

    skeletonCount () {
      return this.tasks.length > 0
        ? this.tasks.length
        : 7
    },

    sprintList () {
      return this.sprints.map((item) => {
        const startDate = transformDateToObject(item.started_at, 'yyyy-MM-dd')
        const finishedDate = transformDateToObject(item.finished_at, 'yyyy-MM-dd')

        return {
          ...item,
          name: this.$t('sprints.sprintNumber', { number: item.number }),
          duration: `${startDate.day} ${startDate.monthShort} - ${finishedDate.day} ${finishedDate.monthShort}`
        }
      })
    }
  },

  methods: {
    ...mapActions('archive', ['changeFilters', 'changePage', 'fetchFinishedTasks', 'fetchSprints']),

    filterText (text, filter) {
      return filter.length > 0 ? text.replace(new RegExp(`(${filter})`, 'gi'), '<b>$1</b>') : text
    },

    onOpenTask (data) {
      this.$emit('open-task', data)
    },

    onCreateTask (data) {
      this.$emit('create-task', data)
    },

    onFilterChange (field, value) {
      this.changeFilters({
        ...this.filters,
        [field]: value,
      })
    },

    currentMembersFilterText () {
      return this.filters.assignee_id.length > 0
        ? this.$t('tasks.membersCount', { count: this.filters.assignee_id.length })
        : this.$t('tasks.allMembers')
    },

    currentSprintsFilterText () {
      return this.filters.sprint_id.length > 0
        ? this.$t('tasks.sprintsCount', { count: this.filters.sprint_id.length })
        : this.$t('tasks.allSprints')
    },

    currentTargetsFilterText () {
      return this.filters.target_id.length > 0
        ? this.$t('tasks.goalsCount', { count: this.filters.target_id.length })
        : this.$t('tasks.allGoals')
    },

    onChangePage(page) {
      this.changePage(page)
    }
  },

  created () {
    Promise.all([
      this.fetchSprints(),
      this.fetchFinishedTasks()
    ])
  }
}
</script>
<style lang="sass">
.archive
  height: 100%

.archive-content
  display: flex
  flex-direction: column
  height: 100%

.archive-footer
  padding-top: 18px
  width: 100%
  max-width: 780px
  margin: 0 auto

.filter-separator-container
  background: var(--light-grey-color-24)

.el-pagination
  white-space: nowrap
  padding: 0
  color: #303133
  font-weight: 700

  .is-background .el-pager li:not(.disabled).active
      background-color: var(--primary-color)

.archive-filters
  flex-direction: column
  align-items: flex-start
  padding: 0 30px 18px
  width: 100%
  box-sizing: border-box

  &__row
    background: var(--light-grey-color-24)
    border-radius: 8px
    display: flex
    align-items: center

    & > div
      flex: 1

    .targets-dropdown
      border-radius: 0

    & .ui-dropdown__reference
      &.targets-dropdown
        background: none
        position: relative

        &:after
          content: ""
          position: absolute
          right: 0
          top: 50%
          border-right: 1px solid var(--grey-light-color-60)
          height: 24px
          margin-left: -1px
          transform: translateY(-50%)
          transition: all .2s ease-in

        &:hover, .ui-dropdown__reference_opened
          border-radius: 8px
          background: var(--light-grey-color-24)

          &:after
            opacity: 0

        &.ui-dropdown__reference.targets-dropdown.ui-dropdown__reference_opened
          border-radius: 8px
          background: var(--light-grey-color-24)

        &__first
          border-radius: 8px 0 0 8px !important

        &__last
          border-radius: 0 8px 8px 0 !important
          &:after
            display: none

    &:not(:first-child)
      padding-top: 12px
      align-self: flex-start

  .task-spheres__selected
    font-size: 14px
    font-weight: 400
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%

.archive-list
  flex: 1
  overflow: auto
  padding: 0px 30px 24px

.archive-sprint-date
  font-size: 12px
  color: var(--grey-color-100)
</style>

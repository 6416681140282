<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.00195C6 3.44967 5.55228 3.00195 5 3.00195C4.44772 3.00195 4 3.44967 4 4.00195V11.9968C4 12.5491 4.44772 12.9968 5 12.9968C5.55228 12.9968 6 12.5491 6 11.9968V4.00195ZM12 4.00195C12 3.44967 11.5523 3.00195 11 3.00195C10.4477 3.00195 10 3.44967 10 4.00195V11.9968C10 12.5491 10.4477 12.9968 11 12.9968C11.5523 12.9968 12 12.5491 12 11.9968V4.00195Z" class="path-fill" fill="#27272E"/>
</svg>
</template>
<script>
  import iconMixin from './iconMixin'

  export default {
    name: 'PauseNarrowIcon',

    mixins: [iconMixin],

    data () {
      return {
        defaultBackground: '#9498B3',
        defaultWidth: 10,
        defaultHeight: 10
      }
    }
  }
</script>

<template>
  <el-popover
    placement="right-start"
    trigger="hover"
    :visible-arrow="false"
    popper-class="projects-popover"
    transition="sw-slide-right"
    ref="popover"
  >
    <div>
      <div class="ws-menu-head">
        <div class="ws-menu-head__title">
          {{ $t('workspaceMenu.profile') }}
        </div>

        <div class="ws-menu-head__controls">
          <el-tooltip
              popper-class="tiny-tooltip"
              effect="dark"
              :content="$t('layout.settings')"
              placement="bottom"
              transition="sw-slide-bottom"
              :hide-after="0"
              :open-delay="200"
          >
            <div class="ws-menu-head__icon" @click="onUserSettingsClick">
              <settings-icon style="width: 15px; height: 15px"/>
            </div>
          </el-tooltip>

          <el-tooltip
              popper-class="tiny-tooltip"
              effect="dark"
              :content="$t('layout.logout')"
              placement="bottom"
              transition="sw-slide-bottom"
              :hide-after="0"
              :open-delay="200"
          >
            <div class="ws-menu-head__icon" @click="onLogoutClick">
              <logout-icon style="width: 14px; height: 14px"/>
            </div>
          </el-tooltip>
        </div>
      </div>

      <div class="sidebar-user">
        <user-photo :user="authUser" size="chat"/>

        <div class="sidebar-user__title">
          <div class="sidebar-user__name">
            {{ authUser.first_name }} {{ authUser.last_name }}
          </div>

          <div class="sidebar-user__email">
            {{ authUser.email }}
          </div>
        </div>
      </div>

      <div class="ws-menu-head">
        <div class="ws-menu-head__title">
          {{ $t('workspaceMenu.workspace') }}
        </div>

        <div class="ws-menu-head__controls" v-if="isAdmin">
          <el-tooltip
              popper-class="tiny-tooltip"
              effect="dark"
              :content="$t('layout.settings')"
              placement="bottom"
              transition="sw-slide-bottom"
              :hide-after="0"
              :open-delay="200"
          >
            <div class="ws-menu-head__icon" @click="onEditWorkspace">
              <settings-icon style="width: 15px; height: 15px"/>
            </div>
          </el-tooltip>
        </div>
      </div>

      <div class="sidebar-user">
        <workspace-logo :workspace="workspace"/>

        <div class="sidebar-user__title">
          <div class="sidebar-user__name">
            {{ workspace.name }}
          </div>

          <div class="sidebar-user__email" style="color: var(--primary-color);">
            {{ $t('settings.rate.plan') }} "{{ $t(`settings.rate.ratePlans.${workspace.rate_plan_code}`) }}"
          </div>
        </div>
      </div>

      <div class="ws-menu-head ws-menu-head_full">
        <div class="ws-menu-head__title">
          {{ $t('workspaceMenu.memberProjects') }}
        </div>

        <div class="ws-menu-head__controls">
          {{ projects.length }} / {{ projectsFeature.capacity }}
        </div>
      </div>

      <div class="sidebar-projects">
        <div
          v-for="projectItem in projects"
          :key="projectItem.code"
          class="ws-menu-item sidebar-project-item"
          @click="() => switchProject(projectItem.code)"
        >
          <workspace-logo :workspace="projectItem"/>

          <div class="sidebar-project-item__content">
            <div class="sidebar-project-item__title">
              <div class="sidebar-project-item__name">
                {{ projectItem.name }}
              </div>

              <div class="sidebar-project-item__info">
                {{ $tc('workspaceMenu.projectMemberCount', projectItem.members_count, { count: projectItem.members_count }) }}
              </div>
            </div>

            <check-icon
              v-if="projectItem.code === project.code"
              width="14"
              height="14"
              class="sidebar-project-item__check"
            />
          </div>
        </div>
      </div>

      <div style="padding: 12px;" v-if="isAdmin">
        <ui-loading-button ui-class="size_md block" @click="onCreateProject" :loading="isProjectCreateProcessing">
          <plus class="mr-2"/> {{ $t('projects.createBtn') }}
        </ui-loading-button>
      </div>

    </div>
    <div class="sidebar__logo" slot="reference">
      <div class="sidebar__logo-icon" v-if="!project">
        <logo-icon/>
      </div>
      <div class="sidebar__project">
        <workspace-logo class="sidebar__project-logo" :workspace="project" size="md"/>
      </div>

    </div>
  </el-popover>
</template>
<script>
  import { mapActions, mapGetters } from "vuex"
  import CheckIcon from "@/components/icons/CheckIcon"
  import LogoutIcon from "@/components/icons/LogoutIcon"
  import SettingsIcon from "@/components/icons/SettingsIcon"
  import LogoIcon from "@/components/icons/LogoIcon"
  import Plus from "@/components/icons/Plus"
  import WorkspaceLogo from "@/components/ui/WorkspaceLogo"
  import UserPhoto from "@/components/ui/UserPhoto"
  import authUserMixin from "@/mixins/authUserMixin"
  import UiLoadingButton from "@/components/ui/UiLoadingButton";

  export default {
    name: 'WorkspaceMenu',

    components: {
      UiLoadingButton, UserPhoto, CheckIcon, LogoIcon, LogoutIcon, SettingsIcon, Plus, WorkspaceLogo
    },

    mixins: [authUserMixin],

    data () {
      return {
        isProjectCreateProcessing: false,
      }
    },

    computed: {
      ...mapGetters(['authUser', 'projects', 'project', 'workspace', 'features']),

      projectsFeature () {
        return this.features.find((item) => item.code === 'projects_count')
      }
    },

    methods: {
      ...mapActions(['logout', 'initProject', 'fetchFeatures', 'setUpgradePlanModal']),

      onUserSettingsClick () {
        this.$refs.popover.doClose()
        this.$router.push({ name: 'workspace.member.settings', params: { project: this.project.code } })
      },

      onLogoutClick () {
        this.logout()
        this.$router.push({ name: 'login' })
      },

      async onCreateProject () {
        await this.fetchFeatures()
        if (this.projectsFeature.capacity <= this.projectsFeature.used) {
          this.setUpgradePlanModal({
            show: true,
            feature: 'projects_count',
          })
          return
        }

        this.$router.push({ name: 'projects.create' })
      },

      onEditWorkspace () {
        this.$refs.popover.doClose()
        this.$router.push({ name: 'workspace.settings.general', params: { project: this.project.code } })
      },

      async switchProject (projectCode) {
        if (projectCode === this.project.code) {
          return
        }

        await this.initProject({ projectCode })
        this.$refs.popover.doClose()
        this.$router.push({ name: 'project.tasks', params: { project: projectCode }})
      }
    }
  }
</script>
<style lang="sass">
  .ws-menu-head
    color: var(--text-light-color)
    font-size: 12px
    font-weight: 500
    text-transform: uppercase
    padding: 12px 12px 4px
    display: flex
    justify-content: space-between
    align-items: center

    &_full
      padding-bottom: 12px

    &__title
      cursor: default

    &__controls
      align-items: center
      column-gap: 8px
      display: flex

    &__count
      text-transform: none

    &__icon
      cursor: pointer

      .path-stroke
        stroke: var(--grey-color-100)
        transition: all .2s ease-in

      .path-fill
        fill: var(--text-light-color)
        transition: all .2s ease-in

      &:hover
        .path-stroke
          stroke: var(--text-primary-color)

        .path-fill
          fill: var(--text-primary-color)

  .ws-menu-item
    padding: 8px 12px
    border-bottom: 1px solid var(--delimiter-color)
    cursor: pointer
    transition: all ease-in .2s

    &:hover
      background: var(--menu-item-color-hover)

  .projects-popover
    background: var(--block-light-bg-color)
    border: 0
    border-radius: 12px
    box-sizing: border-box
    padding: 0
    text-align: left
    width: 320px
    word-break: break-word
    color: var(--text-primary-color)
    overflow: hidden

    &.el-popper[x-placement^=right]
      margin-top: -12px

  .sidebar-projects

  .sidebar-project-item
    display: flex
    align-items: center
    column-gap: 12px

    &__content
      flex: 1
      display: flex
      justify-content: space-between
      align-items: center

    &__info
      color: var(--text-light-color)
      font-size: 12px

    &__name
      font-weight: 500

    &__check
      width: 16px
      height: 16px

      .path-stroke
        stroke: var(--primary-color-hover)
        stroke-width: 1.5

  .sidebar-user
    display: flex
    column-gap: 12px
    padding: 12px
    border-bottom: 1px solid var(--delimiter-color)

    &__title
      cursor: default

    &__name
      font-weight: 500

    &__email
      font-size: 12px
      color: var(--text-light-color)

  .sidebar-user-menu
    &__item
      font-size: 13px

    .sb-menu-item:last-child
      border-bottom: 0

  .sidebar__project
    min-width: 40px
    min-height: 40px
    z-index: 20
    display: flex
    justify-content: center
    align-items: center
    border-radius: 8px
    transition: all ease-in .2s

    &:hover
      background: var(--sidebar-item-hover-bg)

    &-logo
      cursor: pointer

</style>

<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle class="path-stroke" cx="20" cy="5" r="4" stroke="#6283FF" stroke-width="2"/>
  <circle class="path-fill" cx="20" cy="5" r="1" fill="#6283FF"/>
  <circle class="path-stroke" cx="20" cy="35" r="4" stroke="#6283FF" stroke-width="2"/>
  <path class="path-stroke" d="M16.8333 5H8.49998C4.35786 5 1 8.35786 1 12.5V12.5C1 16.6421 4.35786 20 8.49999 20H31.5C35.6421 20 39 23.3578 39 27.5V27.5C39 31.6421 35.6421 35 31.5 35H23.1667" stroke="#6283FF" stroke-width="2"/>
  <path class="path-fill" fill-rule="evenodd" clip-rule="evenodd" d="M3.47539 30L7.43291 34.3243L8.05079 34.9994L7.43291 35.6745L3.47539 39.9988L2 38.6485L5.33964 34.9994L2 31.3503L3.47539 30ZM8.42266 30L12.3802 34.3243L12.9981 34.9994L12.3802 35.6745L8.42266 39.9988L6.94727 38.6485L10.2869 34.9994L6.94727 31.3503L8.42266 30Z" fill="#6283FF"/>
  <path class="path-stroke" d="M28 15V10M28 10V2H38L35.5 6L38 10H28Z" stroke="#6283FF" stroke-width="2"/>
</svg>
</template>
<script>
import iconMixin from './iconMixin'

export default {
  name: 'StagesIcon',

  mixins: [iconMixin],

  data () {
    return {
      defaultBackground: '#8B92AB',
      defaultWidth: 14,
      defaultHeight: 14
    }
  }
}
</script>

<template>
  <div class="task-state">
    <task-progress :task="task"/>
    <task-card-controls :task="task"/>
  </div>
</template>
<script>
  import TaskCardControls from "@/components/tasks/components/TaskCardControls"
  import TaskProgress from "@/components/tasks/components/TaskProgress"

  export default {
    name: 'TaskState',

    components: { TaskCardControls, TaskProgress },

    props: {
      task: {
        type: Object,
        required: true,
      }
    }
  }
</script>
<style lang="sass">
  .task-state
    margin-bottom: 24px
    box-sizing: border-box
    box-shadow: var(--block-shadow)
    border-radius: 12px
    background: var(--block-light-bg-color)

  @media (max-width: 1280px)
    .task-state
      border-radius: 10px
</style>

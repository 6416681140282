<template>
  <div class="sphere-column">
    <div class="sphere-column__header">
      <div class="sphere-column__title">
        {{ sphere.name }}
      </div>

      <progress-data
        class="sphere-column__progress"
        :total-count="totalTasks"
        :finished-count="finishedCount"
        :estimated-finish-at="estimatedFinishAt"
      />
    </div>

    <goal-sphere-tasks
      :tasks="tasks"
      @create-task="onCreateTask"
      @open-task="onOpenTask"
      class="sphere-column__tasks"
    />
  </div>
</template>
<script>
  import ProgressData from '@/components/goalsProgress/ProgressData'
  import GoalSphereTasks from '@/components/goalsProgress/GoalSphereTasks'
  import { lcFirst } from '@/utils/dataTypes';
  import { getEstimatedTime } from '@/services/scoring';

  export default {
    name: 'GoalSphereColumn',

    components: { GoalSphereTasks, ProgressData },

    props: {
      sphere: {
        type: Object,
        required: true,
      },

      tasks: {
        type: Array,
        required: true,
      },

      finishedCount: {
        type: Number,
        required: true,
      }
    },

    computed: {
      totalTasks () {
        return this.tasks.length + this.finishedCount
      },

      estimatedFinishAt () {
        let label = ''
        if (this.tasks.length === 0) {
          label = this.$t('tasks.unknown')
        } else {
          const lastTask = this.tasks[this.tasks.length - 1]
          label = getEstimatedTime(lastTask.estimatedFinishAt)
        }

        return lcFirst(label)
      },
    },

    methods: {
      onCreateTask (fields) {
        this.$emit('create-task', { ...fields, sphereId: this.sphere.id || null })
      },

      onOpenTask (id) {
        this.$emit('open-task', id)
      }
    }
  }
</script>
<style lang="sass">
  .sphere-column
    display: flex
    flex-flow: column
    flex: 0 0 324px
    box-sizing: border-box

    &__header
      padding: 0 12px
      margin-bottom: 8px

    &__title
      font-weight: 500
      margin-bottom: 12px

    &__tasks
      padding: 12px 12px
      flex: 1
      overflow: auto
</style>

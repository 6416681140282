<template>
  <div class="layout">
    <sidebar @show-notifications="showNotifications = !showNotifications"/>

    <div class="layout__content">
      <transition name="slide-left">
        <notification-modal v-if="showNotifications" @close="showNotifications = false"/>
      </transition>
      <router-view></router-view>
      <upgrade-plan-modal v-if="upgradePlanModal.show"/>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import Sidebar from "@/components/layouts/components/Sidebar"
  import NotificationModal from "@/components/notifications/NotificationModal"
  import UpgradePlanModal from "@/components/workspace/UpgradePlanModal";

  export default {
    name: 'MainLayout',

    components: {UpgradePlanModal, NotificationModal, Sidebar },

    data () {
      return {
        selectedMenu: 'tasks',
        showNotifications: false
      }
    },

    methods: {
      goToPage ({ name }) {
        this.$router.push({ name })
      }
    },

    computed: {
      ...mapGetters(['authUser', 'upgradePlanModal'])
    }
  }
</script>

<template>
  <div class="ui-block">
    <el-skeleton :loading="isLoading" animated>
      <template slot="template">
        <div class="feed-list">
          <skeleton-feed-item
            v-for="i in 7"
            :key="i"
          />
        </div>
      </template>
      <template>
        <div class="feed-list" v-if="memberFeed.length">
          <feed-item
            v-for="item in memberFeed"
            :key="item.id"
            :item="item"
            @open-task="onOpenTask"
          />
        </div>
        <div v-else>
          {{ $t('feed.noItems') }}
        </div>
      </template>
    </el-skeleton>
  </div>
</template>
<script>
  import { createNamespacedHelpers } from 'vuex'
  import FeedItem from '@/components/dashboard/FeedItem.vue'
  import SkeletonFeedItem from '@/components/dashboard/SkeletonFeedItem.vue'

  const { mapActions: dashboardActions, mapGetters: dashboardGetters } = createNamespacedHelpers('dashboard')

  export default {
    name: 'Feed',

    components: { FeedItem, SkeletonFeedItem },

    data () {
      return {
        isLoading: false
      }
    },

    computed: {
      ...dashboardGetters(['memberFeed'])
    },

    methods: {
      ...dashboardActions(['fetchMemberFeed']),

      onOpenTask (id) {
        this.$emit('open-task', id)
      }
    },

    async created () {
      this.isLoading = true
      try {
        await this.fetchMemberFeed()
      } finally {
        this.isLoading = false
      }
    }
  }
</script>
